import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';
import { ERegExpTypes } from 'constants/RegExpTypes';

const useUserFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup
    .object({
      email: yup
        .string()
        .email(t('addUser.email.validation.incorrect'))
        .required(t('addUser.email.validation.required'))
        .max(64, t('general.field.validation.max64Characters'))
        .trim(),
      firstName: yup
        .string()
        .required(t('general.field.validation.required'))
        .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
        .trim(),
      lastName: yup
        .string()
        .required(t('general.field.validation.required'))
        .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
        .trim(),
      phoneNumberCodeDictionaryUniqueId: yup
        .string()
        .required(t('general.field.validation.required'))
        .trim(),
      phoneNumberValue: yup
        .string()
        .required(t('general.field.validation.required'))
        .matches(
          new RegExp(ERegExpTypes.onlyDigits),
          t('general.field.validation.valueIsNotCorrect'),
        )
        .max(15, t('general.field.validation.maxNumberCharacters').replace('{0}', '15'))
        .min(5, t('general.field.validation.minNumberCharacters').replace('{0}', '5'))
        .trim(),
      formRoleIds: yup.object(),
      formPrefixGroupIds: yup.object(),
    })
    .test('roleTest', '', (obj) => {
      const anyChoosen = Object.keys(obj.formRoleIds).some(
        (key) => obj?.formRoleIds?.[key] === true,
      );
      if (anyChoosen) {
        return true;
      }
      return new yup.ValidationError(
        t('general.field.validation.required'),
        null,
        'formRoleIds',
      );
    })
    .test('prefixTest', '', (obj) => {
      const anyChoosen = Object.keys(obj.formPrefixGroupIds).some(
        (key) => obj?.formPrefixGroupIds?.[key] === true,
      );
      if (anyChoosen) {
        return true;
      }
      return new yup.ValidationError(
        t('addUser.formPrefixGroupIds.validation.required'),
        null,
        'formPrefixGroupIds',
      );
    });

  return { schema };
};

export default useUserFormSchema;
