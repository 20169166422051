import { EActionTypes } from 'state/actions/auth';
import { IReducerAction } from 'models/context';
import { EAlertVariants, IAlert } from 'components/atoms/Alert';
import { IUser } from 'models/authContext';

interface IState {
  accessToken: string | null;
  refreshToken: string | null;
  authMessage: IAlert;
  isLoginLoading: boolean;
  isLogOutLoading: boolean;
  user: IUser | null;
}

export const initialState: IState = {
  accessToken: null,
  refreshToken: null,
  authMessage: {
    text: '',
    variant: EAlertVariants.success,
  },
  isLoginLoading: false,
  isLogOutLoading: false,
  user: null,
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case EActionTypes.LOG_IN: {
      return {
        ...state,
        isLoginLoading: true,
      };
    }
    case EActionTypes.LOG_IN_SUCCESS: {
      const { accessToken, refreshToken } = payload;
      return {
        ...state,
        accessToken,
        refreshToken,
        authMessage: {
          text: '',
          variant: EAlertVariants.success,
        },
        isLoginLoading: false,
      };
    }
    case EActionTypes.LOG_IN_FAILURE: {
      return {
        ...state,
        authMessage: payload?.authMessage || state.authMessage,
        isLoginLoading: false,
      };
    }
    case EActionTypes.LOG_OUT: {
      return {
        ...state,
        isLogOutLoading: true,
        accessToken: null,
        refreshToken: null,
      };
    }
    case EActionTypes.LOG_OUT_SUCCESS: {
      return {
        ...state,
        isLogOutLoading: false,
        accessToken: null,
        refreshToken: null,
        authMessage: payload.authMessage,
      };
    }
    case EActionTypes.LOG_OUT_FAILURE: {
      return {
        ...state,
        isLogOutLoading: false,
        accessToken: null,
        refreshToken: null,
      };
    }
    case EActionTypes.CLEAR_AUTH_ERRORS: {
      return {
        ...state,
        authMessage: { text: '', variant: EAlertVariants.success },
      };
    }
    case EActionTypes.CHANGE_USER: {
      return {
        ...state,
        user: payload,
      };
    }
    default:
      return state;
  }
};
