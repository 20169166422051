import { Typography } from '@mui/material';
import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { StyledEmptyNotificationWrapper } from 'pages/NotificationsPage/EmptyNotificationResult/styles';
import Grid from '@mui/material/Grid';

const EmptyNotificationResult = () => {
  const { t } = useTranslations();

  return (
    <Grid container>
      <StyledEmptyNotificationWrapper>
        <Typography fontSize="1rem">{t('emptyNotificationResult.label')}</Typography>
      </StyledEmptyNotificationWrapper>
    </Grid>
  );
};

export { EmptyNotificationResult };
