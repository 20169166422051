import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { RequestHeaderKeyTypes, RequestHeaderValueTypes } from 'constants/RequestHeaderTypes';
import {
  ITransactionDetails,
  ITransactionDetailsCommentsQuery,
  ITransactionDetailsCommentsResponse,
  ITransactionDetailsFirearmsResponse,
  ITransactionResponse,
  ISearchFormValues,
  ITransactionDetailsAncillaryResponse,
  ILastTransactionUniqueId,
} from 'models/transaction';
import { EPerPages, ISortOptions } from 'models/table';
import { queryString, queryStringWithSort } from 'helpers/request';
import {
  ICancelTransactionForGroupParameters,
  ICancelFirearmTransactionParameters,
  ICancelAncillaryTransactionParameters,
} from 'pages/TransactionsPage/hooks';
import { IAttachment, ICommentInTransactionBody } from 'models/form';
import { ILegalEntityCommentData } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage';
import { IDictionary } from 'models/dictionary';
import { downscaleAttachmentIfRequired } from 'utils/image';
import { EMIMETypes } from 'constants/MIMETypes';
import { EFileType } from 'constants/FileType';
import { clearObject } from 'helpers/objects';

const getType = (fileType: string) => {
  switch (fileType) {
    case EMIMETypes.JPG:
      return EFileType.JPG;
    case EMIMETypes.JPEG:
      return EFileType.JPEG;
    case EMIMETypes.PNG:
      return EFileType.PNG;
    case EMIMETypes.PDF:
      return EFileType.PDF;
    case EMIMETypes.XLSX:
      return EFileType.XLSX;
    default:
      return '';
  }
};

const createInitialTransaction = async (body: any) => {
  const { attachments, request } = body;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };
  const formData = new FormData();

  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (attachments) {
    const files = attachments
      .filter((attachment: IAttachment) => attachment.file !== null)
      .map((file: IAttachment) => ({
        file: file.file,
        attachmentName: file.attachmentName,
      }));

    const filesDownscaled = await Promise.all(
      files.map(async (file: any) => ({
        attachmentName: file.attachmentName,
        file: await downscaleAttachmentIfRequired(file.file),
      })),
    );

    for (let i = 0; i < filesDownscaled.length; i++) {
      formData.append(
        'attachments',
        filesDownscaled[i].file,
        `${filesDownscaled[i].attachmentName}.${getType(filesDownscaled[i].file.type)}`,
      );
    }
  }

  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_INITIAL_TRANSACTION(),
    formData,
    ...(attachments ? [config] : []),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createAncillaryInitialTransaction = async (body: any) => {
  const { attachments, request } = body;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };

  const formData = new FormData();
  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (attachments) {
    const files = attachments
      .filter((attachment: IAttachment) => attachment.file !== null)
      .map((file: IAttachment) => ({
        file: file.file,
        attachmentName: file.attachmentName,
      }));

    const filesDownscaled = await Promise.all(
      files.map(async (file: any) => ({
        attachmentName: file.attachmentName,
        file: await downscaleAttachmentIfRequired(file.file),
      })),
    );

    for (let i = 0; i < filesDownscaled.length; i++) {
      formData.append(
        'attachments',
        filesDownscaled[i].file,
        `${filesDownscaled[i].attachmentName}.${getType(filesDownscaled[i].file.type)}`,
      );
    }
  }

  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_ANCILLARY_INITIAL_TRANSACTION(),
    formData,
    ...(attachments ? [config] : []),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getPossibleTransactionType = async (
  id: string,
  legalType?: string,
): Promise<IDictionary[] | null> => {
  let legal;
  if (legalType) {
    legal = legalType === 'organisation' ? 'ORGANIZATION' : legalType.toUpperCase();
  }
  const query = queryString({
    firearmId: id,
    legalType: legal,
  });
  const { data } = await apiClient.get<TResponse<IDictionary[] | null>>(
    `${ApiEndpoints.GET_POSSIBLE_TRANSACTION_TYPE()}?${query}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getPossibleTransactionTypeForGroup = async (
  id: string,
  legalType: string,
): Promise<IDictionary[] | null> => {
  const query = queryString({
    groupId: id,
    legalType: legalType === 'organisation' ? 'ORGANIZATION' : legalType.toUpperCase(),
  });
  const { data } = await apiClient.get<TResponse<IDictionary[] | null>>(
    `${ApiEndpoints.GET_POSSIBLE_TRANSACTION_TYPE_FOR_GROUP()}?${query}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getPossibleTransactionTypeForAncillaryGroup = async (
  id: string,
  legalType: string,
): Promise<IDictionary[] | null> => {
  const query = queryString({
    groupId: id,
    legalType: legalType === 'organisation' ? 'ORGANIZATION' : legalType.toUpperCase(),
  });
  const { data } = await apiClient.get<TResponse<IDictionary[] | null>>(
    `${ApiEndpoints.GET_ANCILLARY_GROUP_TRANSACTION_TYPES()}?${query}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getTransactionDetails = async (id: string): Promise<ITransactionDetails | null> => {
  const { data } = await apiClient.get<TResponse<ITransactionDetails>>(
    ApiEndpoints.GET_TRANSACTION_DETAILS(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getTransactionDetailsFirearms = async (
  id: string,
  page: number,
  size: EPerPages,
  sort: ISortOptions | null | undefined,
) => {
  const preparedQuery = queryStringWithSort({ page: page - 1, size }, sort);

  const { data } = await apiClient.get<TResponse<ITransactionDetailsFirearmsResponse>>(
    `${ApiEndpoints.GET_TRANSACTION_DETAILS_FIREARMS(id)}?${preparedQuery}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getTransactionDetailsAncillaries = async (
  id: string,
  page: number,
  size: EPerPages,
  sort: ISortOptions | null | undefined,
) => {
  const preparedQuery = queryStringWithSort({ page: page - 1, size }, sort);

  const { data } = await apiClient.get<TResponse<ITransactionDetailsAncillaryResponse>>(
    `${ApiEndpoints.GET_TRANSACTION_DETAILS_ANCILLARIES(id)}?${preparedQuery}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getTransactionDetailsComments = async (
  id: string,
  page: number,
  size: EPerPages,
  queryParams: Partial<ITransactionDetailsCommentsQuery> = {},
) => {
  const preparedQuery = queryString({ page: page - 1, size, ...queryParams });

  const { data } = await apiClient.get<TResponse<ITransactionDetailsCommentsResponse>>(
    `${ApiEndpoints.GET_TRANSACTION_DETAILS_COMMENTS(id)}?${preparedQuery}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const cancelFirearmTransaction = async ({
  uniqueId,
  firearmUniqueId,
}: ICancelFirearmTransactionParameters) => {
  const { data } = await apiClient.delete(
    `${ApiEndpoints.CANCEL_FIREARM_TRANSACTION(uniqueId)}?firearmUniqueId=${firearmUniqueId}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const cancelAncillaryTransaction = async ({
  uniqueId,
  ancillaryUniqueId,
}: ICancelAncillaryTransactionParameters) => {
  const { data } = await apiClient.delete(
    `${ApiEndpoints.CANCEL_ANCILLARY_TRANSACTION(
      uniqueId,
    )}?ancillaryUniqueId=${ancillaryUniqueId}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const cancelTransactionForGroup = async ({
  uniqueId,
  groupUniqueId,
}: ICancelTransactionForGroupParameters) => {
  const { data } = await apiClient.delete(
    `${ApiEndpoints.CANCEL_TRANSACTION_FOR_FIREARM_GROUP(
      uniqueId,
    )}?groupUniqueId=${groupUniqueId}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const cancelTransactionForAncillaryGroup = async ({
  uniqueId,
  groupUniqueId,
}: ICancelTransactionForGroupParameters) => {
  const { data } = await apiClient.delete(
    `${ApiEndpoints.CANCEL_TRANSACTION_FOR_ANCILLARY_GROUP(
      uniqueId,
    )}?groupUniqueId=${groupUniqueId}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createCommentInTransaction = async (id: string, body: ICommentInTransactionBody) => {
  const { data } = await apiClient.post(ApiEndpoints.CREATE_COMMENT_IN_TRANSACTION(id), body);

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getCommentInTransaction = async (
  id: string,
  commentId: string,
): Promise<ILegalEntityCommentData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_COMMENT_IN_TRANSACTION(id, commentId));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getTransactions = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const tQueryString = queryStringWithSort({ page: page - 1, size }, sort);

  delete query.preset;

  try {
    const { data } = await apiClient.post<TResponse<ITransactionResponse>>(
      `${ApiEndpoints.GET_TRANSACTION()}?${tQueryString}`,
      clearObject(query),
    );

    if (isResponseError(data)) {
      return null;
    }
    return data;
  } catch (error) {
    return null;
  }
};

const getFirearmLastTransaction = async (id: string, withInitial: boolean) => {
  const { data } = await apiClient.get<any>(ApiEndpoints.GET_FIREARM_LAST_TRANSACTION(id), {
    params: {
      withInitial,
    },
  });

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const isGroupTransactionDiscardable = async (id: string) => {
  const { data } = await apiClient.get<any>(ApiEndpoints.IS_GROUP_TRANSACTION_DISCARDABLE(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const isAncillaryGroupTransactionDiscardable = async (id: string) => {
  const { data } = await apiClient.get<any>(
    ApiEndpoints.IS_ANCILLARY_GROUP_TRANSACTION_DISCARDABLE(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillaryLastTransaction = async (id: string, withInitial: boolean) => {
  const { data } = await apiClient.get<any>(ApiEndpoints.GET_ANCILLARY_LAST_TRANSACTION(id), {
    params: {
      withInitial,
    },
  });

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getGroupLastTransaction = async (id: string) => {
  const { data } = await apiClient.get<any>(ApiEndpoints.GET_GROUP_LAST_TRANSACTION(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillaryGroupLastTransaction = async (id: string) => {
  const { data } = await apiClient.get<any>(
    ApiEndpoints.GET_ANCILLARY_GROUP_LAST_TRANSACTION(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const isTransactionLast = async (uniqueId: string) => {
  const { data } = await apiClient.get<TResponse<any>>(
    ApiEndpoints.IS_TRANSACTION_LAST(uniqueId),
  );

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const createMiddleTransactionForGroup = async (body: any) => {
  const { attachments, request } = body;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };
  const formData = new FormData();

  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (attachments) {
    const files = attachments
      .filter((attachment: IAttachment) => attachment.file !== null)
      .map((file: IAttachment) => ({
        file: file.file,
        attachmentName: file.attachmentName,
      }));

    for (let i = 0; i < files.length; i++) {
      formData.append(
        'attachments',
        files[i].file,
        `${files[i].attachmentName}.${getType(files[i].file.type)}`,
      );
    }
  }

  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_MIDDLE_TRANSACTION_FOR_GROUP(),
    formData,
    ...(attachments ? [config] : []),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createMiddleTransactionForAncillaryGroup = async (body: any) => {
  const { attachments, request } = body;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };
  const formData = new FormData();

  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (attachments) {
    const files = attachments
      .filter((attachment: IAttachment) => attachment.file !== null)
      .map((file: IAttachment) => ({
        file: file.file,
        attachmentName: file.attachmentName,
      }));

    for (let i = 0; i < files.length; i++) {
      formData.append(
        'attachments',
        files[i].file,
        `${files[i].attachmentName}.${getType(files[i].file.type)}`,
      );
    }
  }

  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_ANCILLARY_GROUP_MIDDLE_TRANSACTION(),
    formData,
    ...(attachments ? [config] : []),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillaryGroupLastTransactionUniqueId = async (groupId: string) => {
  const { data } = await apiClient.get<ILastTransactionUniqueId>(
    ApiEndpoints.GET_ANCILLARY_GROUP_LAST_TRANSACTION_UNIQUE_ID(groupId),
  );

  return data;
};

const createMiddleTransaction = async (body: any) => {
  const { attachments, request } = body;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };
  const formData = new FormData();

  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (attachments) {
    const files = attachments
      .filter((attachment: IAttachment) => attachment.file !== null)
      .map((file: IAttachment) => ({
        file: file.file,
        attachmentName: file.attachmentName,
      }));

    for (let i = 0; i < files.length; i++) {
      formData.append(
        'attachments',
        files[i].file,
        `${files[i].attachmentName}.${getType(files[i].file.type)}`,
      );
    }
  }

  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_MIDDLE_TRANSACTION(),
    formData,
    ...(attachments ? [config] : []),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getPossibleAncillariesTransactionType = async (
  id: string,
): Promise<IDictionary[] | null> => {
  const query = queryString({
    ancillaryUniqueId: id,
  });
  const { data } = await apiClient.get<TResponse<IDictionary[] | null>>(
    `${ApiEndpoints.GET_POSSIBLE_ANCILLARIES_TRANSACTION_TYPE()}?${query}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createAncillaryMiddleTransaction = async (body: any) => {
  const { attachments, request } = body;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };
  const formData = new FormData();

  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (attachments) {
    const files = attachments
      .filter((attachment: IAttachment) => attachment.file !== null)
      .map((file: IAttachment) => ({
        file: file.file,
        attachmentName: file.attachmentName,
      }));

    for (let i = 0; i < files.length; i++) {
      formData.append(
        'attachments',
        files[i].file,
        `${files[i].attachmentName}.${getType(files[i].file.type)}`,
      );
    }
  }

  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_ANCILLARY_MIDDLE_TRANSACTION(),
    formData,
    ...(attachments ? [config] : []),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createAncillaryGroupMiddleTransaction = async (body: any) => {
  const { attachments, request } = body;

  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };
  const formData = new FormData();

  formData.append(
    'request',
    new Blob([JSON.stringify(request)], {
      type: 'application/json',
    }),
  );

  if (attachments) {
    const files = attachments
      .filter((attachment: IAttachment) => attachment.file !== null)
      .map((file: IAttachment) => ({
        file: file.file,
        attachmentName: file.attachmentName,
      }));

    for (let i = 0; i < files.length; i++) {
      formData.append(
        'attachments',
        files[i].file,
        `${files[i].attachmentName}.${getType(files[i].file.type)}`,
      );
    }
  }

  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_ANCILLARY_GROUP_MIDDLE_TRANSACTION(),
    formData,
    ...(attachments ? [config] : []),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  createInitialTransaction,
  getTransactionDetails,
  getTransactionDetailsFirearms,
  getTransactionDetailsAncillaries,
  getTransactionDetailsComments,
  getPossibleTransactionType,
  getPossibleTransactionTypeForGroup,
  getPossibleTransactionTypeForAncillaryGroup,
  cancelFirearmTransaction,
  cancelAncillaryTransaction,
  cancelTransactionForGroup,
  createCommentInTransaction,
  getCommentInTransaction,
  isTransactionLast,
  getGroupLastTransaction,
  getTransactions,
  getFirearmLastTransaction,
  createMiddleTransactionForGroup,
  createMiddleTransaction,
  createAncillaryInitialTransaction,
  cancelTransactionForAncillaryGroup,
  getPossibleAncillariesTransactionType,
  getAncillaryLastTransaction,
  createAncillaryMiddleTransaction,
  createAncillaryGroupMiddleTransaction,
  getAncillaryGroupLastTransaction,
  createMiddleTransactionForAncillaryGroup,
  getAncillaryGroupLastTransactionUniqueId,
  isGroupTransactionDiscardable,
  isAncillaryGroupTransactionDiscardable,
};
