import React, { useEffect, useState } from 'react';
import {
  StyledChips,
  StyledCloseIcon,
  StyledSidebarWrapper,
  StyledWarningIcon,
  StyledWarningWrapper,
} from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmDataSidebar/style';
import { Box, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { EIconTypes } from 'constants/Icons';
import { colors } from 'theme/colors';
import Icon from 'components/atoms/Icon/Icon';
import SearchFilterStore from 'state/SearchFilterStore';
import { EChipsVariants } from 'components/atoms/Chips';
import Grid from '@mui/material/Grid';
import { EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import { IComponent } from 'models/ancillaries';
import { LEGAL_ID } from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmsLocationMap';
import ComponentDataListItem from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/ComponentDataSidebar/ComponentDataListItem';

export enum ELegalityFilter {
  legal = 'd90f05bf-6baf-4f86-9ca3-9501af6901ea',
  illegal = 'ae5de668-67ac-4650-af26-23dd0cb97c89',
}

const ComponentDataSidebar = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state, getState } = useDictionary();
  const { selectedLanguage } = useLang();
  const [components, setComponents] = useState<IComponent[]>([]);
  const [filteredComponent, setFilteredComponents] = useState<IComponent[]>([]);
  const [chipsData, setChipsData] = useState<{ illegal: number; legal: number }>({
    illegal: 0,
    legal: 0,
  });
  const [filter, setFilter] = useState<ELegalityFilter>();

  useEffect(() => {
    (async function initDictionaries() {
      getState();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);
  const prepareFirearms = (componentsData: IComponent[]) => {
    let illegalCount = 0;
    let legalCount = 0;

    let filteredItems: IComponent[];

    if (filter) {
      filteredItems = componentsData.filter(
        (component) => component.legalityUniqueId === filter,
      );
    } else {
      filteredItems = componentsData;
    }

    filteredItems.map((component) => {
      if (component.legalityUniqueId !== LEGAL_ID) {
        illegalCount++;
      } else {
        legalCount++;
      }
      return false;
    });

    setFilteredComponents(filteredItems);
    setChipsData({ illegal: illegalCount, legal: legalCount });
  };

  document.addEventListener('setComponentsEvent', (data: any) => {
    const componentsData: IComponent[] = data.detail.data as IComponent[];
    setComponents(componentsData);
    prepareFirearms(componentsData);
    setFilter(undefined);
  });

  useEffect(() => {
    prepareFirearms(components);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (!filteredComponent.length) {
    return null;
  }

  return (
    <StyledSidebarWrapper id="components-data-sidebar">
      <StyledCloseIcon
        onClick={() => {
          SearchFilterStore.setComponents([]);
        }}
      >
        <Icon type={EIconTypes.close} color={colors.black} />
      </StyledCloseIcon>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        {t('ancillaries.ancillariesLocation.accessoryLegality')}
      </Typography>
      <Box>
        {!!chipsData.legal && (
          <StyledChips
            text={`${t('ancillaries.ancillariesLocation.legal.label')}: ${chipsData.legal}`}
            variant={EChipsVariants.success}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.legal ? undefined : ELegalityFilter.legal,
              );
            }}
          />
        )}
        {!!chipsData.illegal && (
          <StyledChips
            text={`${t('ancillaries.ancillariesLocation.illegal.label')}: ${
              chipsData.illegal
            }`}
            variant={EChipsVariants.error}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.illegal ? undefined : ELegalityFilter.illegal,
              );
            }}
          />
        )}
      </Box>

      <Grid
        container
        sx={{
          margin: '15px 0',
          paddingTop: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.gray200}`,
          },
        }}
      >
        {filteredComponent.length > 50 && (
          <StyledWarningWrapper>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <StyledWarningIcon>
                  <Icon type={EIconTypes.info} color={colors.white} />
                </StyledWarningIcon>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="caption">
                  {t('ancillaries.ancillariesLocation.component.caption')}
                </Typography>
              </Grid>
            </Grid>
          </StyledWarningWrapper>
        )}

        <Grid item xs={12}>
          <Button
            label={t('ancillaries.ancillariesLocation.showComponents.label')}
            variant={EButtonVariants.contained}
            onClick={() => {
              navigate(`${ERouteLinks.Ancillaries}?expand=components&fromMap=true`);
            }}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>

      {filteredComponent.length <= 50 &&
        filteredComponent.map((component) => (
          <ComponentDataListItem data={component} key={component.uniqueId} state={state} />
        ))}
    </StyledSidebarWrapper>
  );
};

export default ComponentDataSidebar;
