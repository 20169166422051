import styled from '@emotion/styled';
import { colors } from 'theme/colors';

const StyledFooter = styled.div`
  padding: 5px 15px;
  border-top: 1px solid ${colors.primary50};
  height: 32px;
  display: flex;
  justify-content: space-between;

  & span {
    color: ${colors.gray300};
  }
`;

export { StyledFooter };
