import { clearObject } from 'helpers/objects';
import { queryString, queryStringOfSort, queryStringWithSort } from 'helpers/request';
import {
  IAccessoriesTransactionsFormValues,
  IAccessoriesTransactionsResponse,
  IAccessoryResponse,
  IAmmunitionResponse,
  IAmmunitionTransactionsFormValues,
  IAmmunitionTransactionsResponse,
  IAncillariesReferenceResponse,
  IAncillaryResponse,
  IARTAccessoriesFormValues,
  IARTAccessoriesValues,
  IARTAmmunitionResponse,
  IARTComponentResponse,
  IARTComponentsFormValues,
  IComponentResponse,
} from 'models/ancillaries';
import { isResponseError, TResponse } from 'models/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { ISearchAncillariesFormValues, ISearchFirearmsFormValues } from 'models/form';
import { EPerPages, ISortOptions } from 'models/table';
import { apiClient } from 'utils/apiClient';
import { IAmmunitionFormValues } from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAmmunitionSearchForm';
import { IAccessoriesCommentData } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/AccessoriesDeleteCommentPage';
import { IShortTransaction } from 'models/transaction';
import { IAmmunitionCommentData } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/AmmunitionDeleteCommentPage';
import { RequestHeaderKeyTypes, RequestHeaderValueTypes } from 'constants/RequestHeaderTypes';
import { EDateFormats } from 'constants/dates';
import { simpleFormatDate } from 'utils/date';
import { IComponentsCommentData } from 'pages/AncillariesPage/ComponentsPage/DetailComponents/ComponentsDeleteCommentPage';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import {
  ILegalEntityAncillaryGroup,
  ILegalEntityAncillaryGroupAncillaries,
} from 'models/legalEntity';
import { exportTimeout } from 'constants/Export';

const getAncillaries = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchAncillariesFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort(
    {
      page: page - 1,
      size,
    },
    sort,
  );
  const { data } = await apiClient.post<TResponse<IAncillaryResponse>>(
    `${ApiEndpoints.GET_ANCILLARIES()}?${qString}`,
    clearObject(query),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAncillariesForMap = async (query: Partial<ISearchAncillariesFormValues>) => {
  const { data } = await apiClient.post<TResponse<any>>(
    `${ApiEndpoints.GET_ANCILLARIES_FOR_MAP()}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillariesGroupSearch = async (
  legalUniqueId: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchAncillariesFormValues>,
) => {
  const qString = queryString({
    page: page - 1,
    size,
  });
  const { data } = await apiClient.post<TResponse<IAncillaryResponse>>(
    `${ApiEndpoints.GET_ANCILLARIES_GROUP_SEARCH(legalUniqueId)}?${qString}`,
    clearObject(query),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAncillariesByGroupIdSearch = async (
  legalUniqueId: string,
  groupUniqueId: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchAncillariesFormValues>,
) => {
  const qString = queryString({
    page: page - 1,
    size,
  });
  const { data } = await apiClient.post<TResponse<IAncillaryResponse>>(
    `${ApiEndpoints.GET_ANCILLARIES_BY_GROUP_ID_SEARCH(
      legalUniqueId,
      groupUniqueId,
    )}?${qString}`,
    clearObject(query),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAncillariesGroupDetail = async (
  LEId: string,
  groupId: string,
): Promise<ILegalEntityAncillaryGroup | null> => {
  const { data } = await apiClient.get(
    ApiEndpoints.GET_LEGAL_ENTITY_ANCILLARIES_GROUP_DETAIL(LEId, groupId),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillariesFromGroup = async (
  groupId: string,
): Promise<ILegalEntityAncillaryGroupAncillaries | null> => {
  const { data } = await apiClient.get(
    ApiEndpoints.GET_LEGAL_ENTITY_ANCILLARIES_FROM_GROUP(groupId),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillariesAccessories = async (
  type: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchAncillariesFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort(
    {
      page: page - 1,
      size,
    },
    sort,
  );
  const { data } = await apiClient.post<TResponse<IAccessoryResponse>>(
    `${ApiEndpoints.GET_ANCILLARIES()}?${qString}`,
    clearObject(query),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAncillariesAmmunition = async (
  type: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchAncillariesFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort(
    {
      page: page - 1,
      size,
    },
    sort,
  );

  const { data } = await apiClient.post<TResponse<IAmmunitionResponse>>(
    `${ApiEndpoints.GET_ANCILLARIES()}?${qString}`,
    clearObject(query),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAncillariesComponents = async (
  type: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchAncillariesFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort(
    {
      page: page - 1,
      size,
    },
    sort,
  );

  const { data } = await apiClient.post<TResponse<IComponentResponse>>(
    `${ApiEndpoints.GET_ANCILLARIES()}?${qString}`,
    clearObject(query),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAncillariesReferenceAccessory = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchFirearmsFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  const { data } = await apiClient.post<TResponse<IAncillariesReferenceResponse>>(
    `${ApiEndpoints.GET_ANCILLARIES_REFERENCE_TABLE()}?${qString}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getARTAccessory = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARIES_ACCESSORY(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const createARTAccessory = async (body: IARTAccessoriesFormValues) =>
  apiClient.post<TResponse<IARTAccessoriesValues>>(
    ApiEndpoints.CREATE_ANCILLARIES_ACCESSORY(),
    body,
  );

const updateARTAccessory = async (body: IARTAccessoriesFormValues, id: string) =>
  apiClient.put<TResponse<IARTAccessoriesValues>>(
    ApiEndpoints.UPDATE_ANCILLARIES_ACCESSORY(id),
    body,
  );

const updateARTAmmunition = async (body: IAmmunitionFormValues, id: string) =>
  apiClient.put(ApiEndpoints.UPDATE_ANCILLARIES_AMMUNITION(id), body);

const createARTAmmunition = async (body: IAmmunitionFormValues) =>
  apiClient.post(ApiEndpoints.CREATE_AMMUNITION(), body);

const getARTAmmunition = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARIES_AMMUNITION(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAmmunitionReferenceTable = async (
  page: number,
  size: EPerPages,
  query: Partial<IAmmunitionFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size }, sort);
  const { data } = await apiClient.post<TResponse<IARTAmmunitionResponse>>(
    `${ApiEndpoints.GET_AMMUNITION_REFERENCE_TABLE()}?${qString}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getComponentsReferenceTable = async (
  page: number,
  size: EPerPages,
  query: Partial<IARTComponentsFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size }, sort);
  const { data } = await apiClient.post<TResponse<IARTComponentResponse>>(
    `${ApiEndpoints.GET_COMPONENTS_REFERENCE_TABLE()}?${qString}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getARTComponent = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARIES_COMPONENT(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const createARTComponent = async (body: IARTComponentsFormValues) =>
  apiClient.post(ApiEndpoints.CREATE_COMPONENT(), body);

const updateARTComponent = async (body: IARTComponentsFormValues, id: string) =>
  apiClient.put(ApiEndpoints.UPDATE_ANCILLARIES_COMPONENT(id), body);

const getAccessory = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARY(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAmmunition = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARY(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getComponent = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARY(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAncillariesTransactions = async (
  id: string,
  page: number,
  size: EPerPages,
  query: Partial<IAccessoriesTransactionsFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  const { data } = await apiClient.get<TResponse<IAccessoriesTransactionsResponse>>(
    `${ApiEndpoints.GET_ANCILLARY_TRANSATIONS(id)}?${qString}`,
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAmmunitionTransactions = async (
  id: string,
  page: number,
  size: EPerPages,
  query: Partial<IAmmunitionTransactionsFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  const { data } = await apiClient.get<TResponse<IAmmunitionTransactionsResponse>>(
    `${ApiEndpoints.GET_ANCILLARY_TRANSATIONS(id)}?${qString}`,
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAccessoriesComments = async (
  id: string,
  queryParams: {
    withHidden: boolean;
    currentPage: number;
    perPage: EPerPages;
  },
) => {
  const { withHidden, currentPage, perPage } = queryParams;
  const query = queryString({
    withHidden,
    page: currentPage - 1,
    size: perPage,
  });

  const { data } = await apiClient.get(`${ApiEndpoints.GET_ANCILLARY_COMMENTS(id)}?${query}`);

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getComponentsComments = async (
  id: string,
  queryParams: {
    withHidden: boolean;
    currentPage: number;
    perPage: EPerPages;
  },
) => {
  const { withHidden, currentPage, perPage } = queryParams;
  const query = queryString({
    withHidden,
    page: currentPage - 1,
    size: perPage,
  });
  const { data } = await apiClient.get(`${ApiEndpoints.GET_ANCILLARY_COMMENTS(id)}?${query}`);
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAmmunitionComments = async (
  id: string,
  queryParams: {
    withHidden: boolean;
    currentPage: number;
    perPage: EPerPages;
  },
) => {
  const { withHidden, currentPage, perPage } = queryParams;
  const query = queryString({
    withHidden,
    page: currentPage - 1,
    size: perPage,
  });

  const { data } = await apiClient.get(`${ApiEndpoints.GET_ANCILLARY_COMMENTS(id)}?${query}`);

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillaryAttachments = async (
  id: string,
  queryParams: {
    sort: ISortOptions | null | undefined;
    currentPage: number;
    perPage: EPerPages;
  },
) => {
  const { sort, currentPage, perPage } = queryParams;
  const query = queryStringWithSort({ page: currentPage - 1, size: perPage }, sort);

  const { data } = await apiClient.get(
    `${ApiEndpoints.GET_ANCILLARY_ATTACHMENTS(id)}?${query}`,
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAmmunitionComment = async (
  id: string,
  commentId: string,
): Promise<IAmmunitionCommentData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARY_COMMENT(id, commentId));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getComponentsComment = async (
  id: string,
  commentId: string,
): Promise<IComponentsCommentData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARY_COMMENT(id, commentId));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAccessoriesComment = async (
  id: string,
  commentId: string,
): Promise<IAccessoriesCommentData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ANCILLARY_COMMENT(id, commentId));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAttachmentAvailableTransactionTypes = async (
  id: string,
): Promise<IShortTransaction[] | null> => {
  const { data } = await apiClient.get(
    ApiEndpoints.GET_ACCESSORY_ATTACHMENTS_USED_TRANSACTION_TYPES(id),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const disableAncillary = async (id: string) => {
  const { data } = await apiClient.post(ApiEndpoints.DISABLE_ANCILLARY(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

export interface ISearchQuery {
  [key: string]: any;
}

const exportAncillariesCSV = async (
  query: ISearchQuery,
  sort: ISortOptions | null,
): Promise<Blob | null> => {
  const {
    preset,
    catridgeCompositionUniqueId,
    ammunitionTypeUniqueId,
    componentTypeUniqueId,
    componentManufacturerUniqueId,
    capacity,
    ...cleanedQuery
  } = query;

  const sortPart = sort !== null ? `?${queryStringOfSort(sort)}` : '';

  const { data } = await apiClient.post(
    `${ApiEndpoints.EXPORT_ANCILLARY_CSV()}${sortPart}`,
    cleanedQuery,
    {
      timeout: exportTimeout,
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );
  const date = simpleFormatDate(new Date(), EDateFormats.yyyyMMddhhmm);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `ancillaries_${date}.csv`);
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const generateAncillariesListTemplate = async (body: any): Promise<Blob | null> => {
  const { data } = await apiClient.post<Blob>(
    ApiEndpoints.GENERATE_ANCILLARIES_TEMPLATE(),
    body,
  );
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'add ancillaries template.csv');
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const updateAncillaryCustomField = async (
  id: string,
  body: ICustomFieldForm[],
  version: number,
) => {
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_ANCILLARY_CUSTOM_FIELDS(id), body, {
    params: {
      version,
    },
  });

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  getAncillariesAccessories,
  getAncillariesAmmunition,
  getAncillariesByGroupIdSearch,
  getAncillariesGroupSearch,
  getAncillariesGroupDetail,
  getAncillariesReferenceAccessory,
  createARTAccessory,
  getARTAccessory,
  updateARTAccessory,
  getAmmunitionReferenceTable,
  createARTAmmunition,
  getARTAmmunition,
  updateARTAmmunition,
  getComponentsReferenceTable,
  createARTComponent,
  getARTComponent,
  updateARTComponent,
  getAncillaries,
  getAccessory,
  getAmmunition,
  getComponent,
  getAncillariesTransactions,
  getAccessoriesComments,
  getAncillaryAttachments,
  getAccessoriesComment,
  getAttachmentAvailableTransactionTypes,
  getAmmunitionTransactions,
  getAmmunitionComments,
  getAmmunitionComment,
  getAncillariesComponents,
  getComponentsComments,
  getComponentsComment,
  disableAncillary,
  exportAncillariesCSV,
  generateAncillariesListTemplate,
  updateAncillaryCustomField,
  getAncillariesFromGroup,
  getAncillariesForMap,
};
