import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ILegalEntityAddressTableCard {
  data: ITableDataSource;
}

const LegalEntityAddressTableCard = ({ data }: ILegalEntityAddressTableCard) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();

  return (
    <Grid
      container
      sx={{
        backgroundColor: colors.gray100,
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        padding: '20px',
      }}
      rowGap={3}
    >
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.addressTab.addressType.column')}
        </Typography>
        <Chips text={emptyFieldHolder(data.typeUniqueId)} variant={EChipsVariants.primary} />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.addressTab.storageAddress.column')}
        </Typography>
        <Typography variant="body2">
          <Chips
            text={
              data.isDefault
                ? t('general.confirmation.yes.button')
                : t('general.confirmation.no.button')
            }
            variant={data.isDefault ? EChipsVariants.success100 : EChipsVariants.inactive}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.addressTab.address.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.line1)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.addressTab.city.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.city)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.addressTab.stateProvince.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.regionUniqueId)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.addressTab.zipPostalCode.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.postalCode)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.addressTab.gps.column')}
        </Typography>
        <Typography variant="body2">{`${emptyFieldHolder(data.gps)}`}</Typography>
      </Grid>

      {hasPermissions([EPermission.MANAGE_LE, EPermission.DELETE_LE], true) && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
            mt: {
              xs: 0,
            },
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {t('legalEntityDetails.addressTab.action.column')}:{' '}
            </Typography>
          </Grid>

          {hasPermissions([EPermission.MANAGE_LE]) && (
            <Grid item xs={12} sm={3}>
              <Button
                label={t('legalEntityDetails.addressTab.edit.link')}
                variant={EButtonVariants.outlined}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.LegalEntityEditAddress.replace(':id', id as string).replace(
                      ':addressId',
                      data.id as string,
                    )}?type=${type}&expand=address`,
                  )
                }
                size="small"
                fullWidth
              />
            </Grid>
          )}

          {hasPermissions([EPermission.DELETE_LE]) && (
            <Grid item xs={12} sm={3}>
              <Button
                label={t('legalEntityDetails.addressTab.delete.link')}
                variant={EButtonVariants.outlined}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.LegalEntityDeleteAddress.replace(
                      ':id',
                      id as string,
                    ).replace(':addressId', data.id as string)}?type=${type}&expand=address`,
                  )
                }
                size="small"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export { LegalEntityAddressTableCard };
