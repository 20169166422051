import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { IUser } from 'models/user';
import { useDictionary } from 'models/dictionaryContext';
import { useTranslations } from 'hooks/useTranslations';
import { useUsers } from 'pages/UsersPage/hooks';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { getUser } from 'requests/user';
import { getRoles } from 'requests/role';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import Grid from '@mui/material/Grid';
import { StyledPageSubTitle } from 'theme/styles';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EPermission } from 'models/permissions';
import { Typography } from '@mui/material';
import { formatDate } from 'utils/date';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';

export const DeleteUserPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [userDetails, setUserDetails] = useState<IUser | null>();
  const [roleNames, setRoleNames] = useState<string[]>([]);
  const [prefixNames, setPrefixNames] = useState<string[]>([]);
  const { phoneCode, legalEntityPrefix } = useDictionary();
  const { t } = useTranslations();
  const { handleDelete } = useUsers();
  const [isLoading, setIsLoading] = useState(false);
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function getUserDataInit() {
      if (id) {
        const response = await getUser(id);
        if (response) {
          setUserDetails(response);
          if (response.roleIds && response.roleIds.length) {
            const responseRoles = await getRoles(1, 100, {}, null);
            if (responseRoles) {
              const roles = responseRoles.content
                .map((role) => response.roleIds.includes(role.uniqueId) && role.name)
                .filter((row) => !!row);
              setRoleNames(roles as string[]);
            }
          }
          if (response.prefixGroupIds && response.prefixGroupIds.length) {
            if (legalEntityPrefix) {
              const names = legalEntityPrefix
                .map(
                  (prefix) =>
                    response.prefixGroupIds.includes(prefix.uniqueId.toString()) &&
                    prefix.name,
                )
                .filter((row) => !!row);
              setPrefixNames(names as string[]);
            }
          }
        }
      }
    })();
  }, [id]);

  const parsedPhoneCodeDictionary: IParsedDictionary = parseDictionary(phoneCode, 'name');

  const statusChip = () => {
    if (userDetails) {
      if (userDetails.removed) {
        return <Chips text={t('users.removed.label')} variant={EChipsVariants.error200} />;
      }
      if (userDetails.blocked) {
        return <Chips text={t('users.blocked.label')} variant={EChipsVariants.warning100} />;
      }
      return (
        <Chips
          text={userDetails.active ? t('users.active.label') : t('users.inactive.label')}
          variant={userDetails.active ? EChipsVariants.success : EChipsVariants.error}
        />
      );
    }
    return '-';
  };

  const onSubmit = async () => {
    setIsLoading(true);
    await handleDelete(id);
    setIsLoading(false);
    navigate(ERouteLinks.ViewUser.replace(':id', id));
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('user.breadcrumbs.administration'),
            route: ERouteLinks.Administration,
          },
          { label: t('user.breadcrumbs.users'), route: ERouteLinks.Users },
          {
            label: t('user.breadcrumbs.userDetails'),
            route: ERouteLinks.ViewUser.replace(':id', id),
          },
          t('user.delete.header'),
        ]}
      />
      <Grid item xs={12} container rowGap={2} marginBottom={3}>
        <Grid item xs={12}>
          <StyledPageSubTitle variant="h5">{t('user.delete.header')}</StyledPageSubTitle>
        </Grid>

        <Grid item xs={12} style={{ margin: '10px 0 10px' }}>
          <Alert
            text={t('user.editStatus.delete.question')}
            variant={EAlertVariants.warning}
          />
        </Grid>
      </Grid>
      {userDetails && (
        <DetailsTemplate
          pageType={EDetailsPageTypes.userView}
          title={userDetails.email}
          deletePermissions={[EPermission.MANAGE_USERS]}
        >
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.firstName.label')}
            </Typography>
            <Typography variant="body2">{userDetails.firstName}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.lastName.label')}
            </Typography>
            <Typography variant="body2">{userDetails.lastName}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.phoneNumber.label')}
            </Typography>
            <Typography variant="body2">{`${
              userDetails.phoneNumber &&
              parsedPhoneCodeDictionary?.[userDetails.phoneNumber.codeDictionaryUniqueId]
                ? `${
                    parsedPhoneCodeDictionary?.[userDetails.phoneNumber.codeDictionaryUniqueId]
                  }${userDetails.phoneNumber.value}`
                : '-'
            }`}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.lastLogged.label')}
            </Typography>
            <Typography variant="body2">
              {formatDate(userDetails.lastLoginDate, longDateFormat)}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.role.label')}
            </Typography>
            <div>
              {roleNames.map((roleItem) => (
                <Chips key={roleItem} text={roleItem} variant={EChipsVariants.primary} />
              ))}
            </div>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.status.label')}
            </Typography>
            <div>{statusChip()}</div>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.prefix.label')}
            </Typography>
            <div>
              {prefixNames.map((item) => (
                <Chips key={item} text={item} variant={EChipsVariants.primary} />
              ))}
            </div>
          </Grid>
        </DetailsTemplate>
      )}
      <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              fullWidth
              label={t('general.yes.button')}
              id="yes-button"
              variant={EButtonVariants.contained}
              isLoading={isLoading}
              onClick={onSubmit}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              fullWidth
              label={t('general.no.button')}
              id="no-button"
              variant={EButtonVariants.outlined}
              onClick={() => navigate(-1)}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
