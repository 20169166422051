import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useNavigate } from 'react-router-dom';
import { CardList } from 'components/molecules/CardList';
import { Loader } from 'components/atoms/Loader';
import { EButtonVariants } from 'constants/Buttons';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EIconTypes } from 'constants/Icons';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EPerPages, ISortOptions } from 'models/table';
import { Pagination } from 'components/molecules/Pagination';
import {
  mapAccessoriesAttachmentsToDataSource,
  AccessoriesAttachmentsTableColumns,
} from '../helpers';
import { AccessoriesAttachmentsCard } from '../AccessoriesAttachmentsCard';

export enum EAccessoryAttachmentsLabel {
  dateAndTime = 'accessoryDetails.attachments.DateAndTime.column',
  attachmentName = 'accessoryDetails.attachments.attachmentName.column',
  attachmentSize = 'accessoryDetails.attachments.attachmentSize.column',
  transactionType = 'accessoryDetails.attachments.transactionType.column',
  addAttachment = 'accessoryDetails.attachments.addAttachment.button',
}

interface IAccessoriesAttachments {
  idAncillary: string | undefined;
}
const AccessoriesAttachments = ({ idAncillary }: IAccessoriesAttachments) => {
  const { isDesktop } = useThemeBreakpoint();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const {
    getAccessoriesAttachmentsData,
    accessoriesAttachments,
    paginatorAccessoriesAttachments,
  } = useAncillaries();
  const { midDateFormat } = useGlobalProperty();
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  useEffect(() => {
    (async function getDataInit() {
      setIsPaginationLoading(true);
      setIsLoading(true);
      if (idAncillary) {
        await getAccessoriesAttachmentsData(idAncillary, { currentPage, perPage, sort });
      }
      setIsLoading(false);
      setIsPaginationLoading(false);
    })();
  }, [idAncillary, currentPage, perPage, sort]);

  return (
    <div>
      <Grid
        item
        xs={12}
        container
        sx={{ display: 'flex', justifyContent: 'flex-end', margin: '24px 0' }}
      >
        <Button
          variant={EButtonVariants.contained}
          icon={EIconTypes.plus}
          label={t(EAccessoryAttachmentsLabel.addAttachment)}
          onClick={() =>
            idAncillary
              ? navigate(ERouteLinks.AccessoriesAddAttachment.replace(':id', idAncillary))
              : {}
          }
        />
      </Grid>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <div> </div>
          {isDesktop ? (
            <Table
              columns={AccessoriesAttachmentsTableColumns({
                ancillaryId: idAncillary ?? '',
                handleView: (id: string) =>
                  window.open(
                    ERouteLinks.AccessoriesViewAttachment.replace(':id', id).replace(
                      ':ancillaryId',
                      idAncillary ?? '',
                    ),
                    '_blank',
                  ),
                handleDelete: (id: string) =>
                  navigate(
                    ERouteLinks.AccessoriesDeleteAttachment.replace(':id', id).replace(
                      ':ancillaryId',
                      idAncillary ?? '',
                    ),
                    { state: { accessoriesAttachments } },
                  ),
                dateFormat: midDateFormat,
              })}
              dataSource={mapAccessoriesAttachmentsToDataSource(accessoriesAttachments)}
              translationsKeys={{
                noResults: 'ancillaries.accordion.noResults.text',
              }}
              onSort={setSort}
            />
          ) : (
            <CardList
              items={mapAccessoriesAttachmentsToDataSource(accessoriesAttachments)}
              render={(data, index) => (
                <AccessoriesAttachmentsCard
                  data={data}
                  key={index}
                  idAncillary={idAncillary}
                  handleView={(id: string) =>
                    window.open(
                      ERouteLinks.AccessoriesViewAttachment.replace(':id', id).replace(
                        ':ancillaryId',
                        idAncillary ?? '',
                      ),
                      '_blank',
                    )
                  }
                  handleDelete={(id: string) =>
                    navigate(
                      ERouteLinks.AccessoriesDeleteAttachment.replace(':id', id).replace(
                        ':ancillaryId',
                        idAncillary ?? '',
                      ),
                      { state: { accessoriesAttachments } },
                    )
                  }
                />
              )}
            />
          )}
          {paginatorAccessoriesAttachments && (
            <Pagination
              count={paginatorAccessoriesAttachments?.totalElements}
              perPage={perPage}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onChangePerPage={(value) => {
                setPerPage(value);
              }}
              current={currentPage}
              isLoading={isPaginationLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export { AccessoriesAttachments };
