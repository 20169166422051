import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Loader } from 'components/atoms/Loader';
import { getRegisteredFirearm } from 'requests/firearm';
import { RegisteredFirearmDetails } from 'components/organisms/RegisteredFirearmDetails';
import {
  IRegisteredFirearmAttachmentProps,
  IRegisteredFirearmData,
} from 'models/registeredFirearm';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Accordion } from 'components/molecules/Accordion';
import { EPerPages, ISortOptions } from 'models/table';
import { ISearchTransactionFormValues } from 'models/form';
import { TransactionSearchForm } from 'pages/Firearms/TransactionSearchForm';
import { Table } from 'components/molecules/Table';
import {
  firearmAttachmentsTableColumns,
  firearmTransactionsTableColumns,
  mapFirearmAttachmentToDataSource,
  mapFirearmTransactionToDataSource,
} from 'pages/Firearms/helpers';
import { useFirearms } from 'pages/Firearms/hooks';
import { Pagination } from 'components/molecules/Pagination';
import { CardList } from 'components/molecules/CardList';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { FirearmTransactionTableCard } from 'pages/Firearms/FirearmTransactionCard';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Box, Tooltip, Typography } from '@mui/material';
import { CommentAccordion } from 'components/molecules/CommentAccordion';
import { colors } from 'theme/colors';
import { useForm } from 'react-hook-form';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { FirearmAttachmentTableCard } from 'pages/Firearms/FirearmAttachmentTableCard';
import { useTranslations } from 'hooks/useTranslations';
import { StyledButton } from 'pages/Firearms/ViewRegisteredFirearmPage/styles';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { EFirearmState, EFirearmStateId } from 'models/firearm';
import { useAccount } from 'pages/AccountPage/hooks';
import { useAuth } from 'models/authContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { exportFirearmTransactionsCSV } from 'requests/report';
import { isExportAvailable } from 'constants/Export';
import { clearAlertTimeout, EAlertTime } from 'utils/date';

const ViewRegisteredFirearmPage = () => {
  const { id } = useParams<{ id: string }>();
  const { user, getUser } = useAuth();
  const navigate = useNavigate();
  const { selectedLanguage } = useLang();
  const { assignedLegalsToUser, getAssignedSelfLegalsToUserData } = useAccount();
  const [isFirearmLoading, setIsFirearmLoading] = useState<boolean>(false);
  const [isFirearmCommentsLoading, setIsFirearmCommentsLoading] = useState<boolean>(false);
  const [firearmDetailsAllExpanded, setFirearmDetailsAllExpanded] = useState<boolean>(false);
  const [firearm, setFirearm] = useState<IRegisteredFirearmData | null>();
  const { isMobile, isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();

  const [currentPageFT, setCurrentPageFT] = useState<number>(1);
  const [perPageFT, setPerPageFT] = useState<EPerPages>(EPerPages.perPage25);
  const [transactionQuery, setTransactionQuery] = useState<
    Partial<ISearchTransactionFormValues>
  >({});
  const [transactionSort, setTransactionSort] = useState<ISortOptions | null>(null);
  const [currentPageFComments, setCurrentPageFComments] = useState<number>(1);
  const [perPageFComments, setPerPageFComments] = useState<EPerPages>(EPerPages.perPage25);
  const [currentPageFA, setCurrentPageFA] = useState<number>(1);
  const [perPageFA, setPerPageFA] = useState<EPerPages>(EPerPages.perPage25);
  const [attachmentSort, setAttachmentSort] = useState<ISortOptions | null>(null);
  const { setAlert, samePageAlert, transactionAlert, nextPageAlert, clearAlert } = useAlert();
  const { midDateFormat, longDateFormat, getDateFormat } = useGlobalProperty();
  const [isExportLoading, setIsExportloading] = useState(false);
  const [isTransactionPaginationLoading, setIsTransactionPaginationLoading] = useState(false);
  const [isAttachmentPaginationLoading, setIsAttachmentPaginationLoading] = useState(false);
  const [isCommentPaginationLoading, setIsCommentPaginationLoading] = useState(false);
  const {
    discardableFirearmTransactionId,
    firearmTransactions,
    getFirearmTransactionsData,
    firearmComments,
    getFirearmCommentsData,
    paginatorFComments,
    paginatorFT,
    getDiscardableFirearmTransactionId,
    firearmAttachments,
    getFirearmAttachmentsData,
    paginatorFA,
    loadDictionaries,
    transactionType,
  } = useFirearms();

  const { hasPermissions } = usePermission();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isShowHidden: false,
    },
  });

  useEffect(
    () => {
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_NEXT_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_SAME_PAGE_ALERT, EAlertTime.SHORT);
      clearAlertTimeout(clearAlert, ActionTypes.CLEAR_TRANSACTION_ALERT, EAlertTime.SHORT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
      clearAlert(ActionTypes.CLEAR_TRANSACTION_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function getFirearmDataInit() {
      setIsFirearmLoading(true);
      if (id) {
        const response = await getRegisteredFirearm(id);
        if (response) {
          setFirearm(response);
        }
        setIsFirearmLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async function getFirearmsTransactionsDataInit() {
      setIsTransactionPaginationLoading(true);
      await getDiscardableFirearmTransactionId(id as string);

      await getFirearmTransactionsData(
        id as string,
        currentPageFT,
        perPageFT,
        transactionQuery,
        transactionSort,
      );
    })();
    setIsTransactionPaginationLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageFT, perPageFT, transactionQuery, transactionSort]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user && user.userId) {
      getAssignedSelfLegalsToUserData();
    }
  }, [user]);

  const isShowHidden: boolean = watch('isShowHidden');

  useEffect(() => {
    (async function getFirearmsCommentsDataInit() {
      if (id) {
        setIsCommentPaginationLoading(true);
        setIsFirearmCommentsLoading(true);
        await getFirearmCommentsData(id, {
          withHidden: isShowHidden,
          currentPage: currentPageFComments,
          perPage: perPageFComments,
        });

        setIsFirearmCommentsLoading(false);
        setIsCommentPaginationLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isShowHidden, currentPageFComments, perPageFComments]);

  useEffect(() => {
    (async function getFirearmsAttachmentsDataInit() {
      setIsAttachmentPaginationLoading(true);
      await getFirearmAttachmentsData(id as string, currentPageFA, perPageFA, attachmentSort);
      setIsAttachmentPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageFA, perPageFA, attachmentSort]);

  useEffect(() => {
    (async function () {
      await loadDictionaries([EDictionaryTypes.TransactionType]);
    })();
  }, [selectedLanguage]);

  const handleDeleteAttachment = (attachment: IRegisteredFirearmAttachmentProps) => {
    navigate(
      `${ERouteLinks.RegisteredFirearmDeleteAttachment.replace(':id', id as string).replace(
        ':attachmentId',
        attachment.id,
      )}?expand=attachments`,
      { state: { attachment } },
    );
  };

  const handleShowAttachment = (firearmId: string, attachmentId: string) => {
    window.open(
      ERouteLinks.ViewRegisteredFirearmAttachment.replace(':id', firearmId).replace(
        ':attachmentId',
        attachmentId,
      ),
      '_blank',
    );
  };
  const handleToggleExpandAll = () => {
    setFirearmDetailsAllExpanded((prev) => !prev);
  };

  const handleAddTransaction = () => {
    if (firearm?.groupName && firearm.stateUniqueId !== EFirearmStateId.EXPORTED) {
      clearAlert(ActionTypes.SET_SAME_PAGE_ALERT);
      clearAlert(ActionTypes.SET_TRANSACTION_ALERT);
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {} as IAlert);
    } else {
      navigate(`${ERouteLinks.MiddleTransaction.replace(':id', id as string)}`, {
        state: {
          isTransactionForGroup: '0',
          firearm,
        },
      });
    }
  };

  const handleExportFirearmTransactions = async () => {
    if (id && transactionQuery) {
      setIsExportloading(true);
      await exportFirearmTransactionsCSV(id, transactionQuery, transactionSort);
      setIsExportloading(false);
    }
  };

  const groupTransactionForbiddenAlert = (
    <Grid mb={3}>
      <Alert
        variant={EAlertVariants.warning}
        text={t('addTransaction.groupAssigned.alert.text')}
      />
    </Grid>
  );

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.accordion.name'),
            route: ERouteLinks.Firearms,
          },
          t('registeredFirearms.firearmsDetails.header'),
        ]}
      />
      <div>
        {isFirearmLoading ? (
          <Loader isLoading={isFirearmLoading} />
        ) : (
          firearm && (
            <RegisteredFirearmDetails
              firearm={firearm}
              hideDisableButton={firearm.state === EFirearmState.DISABLED}
            />
          )
        )}
      </div>
      {nextPageAlert && (
        <Box mt={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}

      <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <StyledButton
          label={
            firearmDetailsAllExpanded
              ? t('general.collapseAll.label')
              : t('general.expandAll.label')
          }
          onClick={handleToggleExpandAll}
          variant={EButtonVariants.outlined}
        />
      </Grid>
      <Grid container>
        {hasPermissions(
          [EPermission.VIEW_ALL_TRANSACTIONS, EPermission.VIEW_TRANSACTIONS],
          true,
        ) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              id="transactions-accordion"
              title={t('registeredFirearms.firearmsDetails.transactions.accordion.name')}
              expanded={firearmDetailsAllExpanded}
            >
              <TransactionSearchForm
                showSaveFilterButton={false}
                onSubmit={setTransactionQuery}
              />
              {firearm?.stateUniqueId === EFirearmStateId.DISABLED && <Grid sx={{ mb: 2 }} />}
              {hasPermissions([EPermission.ADD_TRANSACTION]) &&
                firearm?.stateUniqueId !== EFirearmStateId.DISABLED && (
                  <Grid
                    item
                    container
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    spacing={1}
                    mt={2}
                    mb={2}
                  >
                    {!isFirearmLoading && (
                      <>
                        {hasPermissions(
                          [EPermission.VIEW_ALL_TRANSACTIONS, EPermission.VIEW_TRANSACTIONS],
                          true,
                        ) && (
                          <Grid item>
                            {isExportAvailable(paginatorFT) ? (
                              <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                                <span>
                                  <Button
                                    id="transactions-export-button"
                                    label={t(
                                      'legalEntities.individual.accordion.export.button',
                                    )}
                                    variant={EButtonVariants.outlined}
                                    onClick={handleExportFirearmTransactions}
                                    isLoading={isExportLoading}
                                    disabled
                                  />
                                </span>
                              </Tooltip>
                            ) : (
                              <Button
                                id="transactions-export-button"
                                label={t('legalEntities.individual.accordion.export.button')}
                                variant={EButtonVariants.outlined}
                                onClick={handleExportFirearmTransactions}
                                isLoading={isExportLoading}
                              />
                            )}
                          </Grid>
                        )}
                        <Grid item>
                          <Button
                            id="add-transaction-button"
                            label={t(
                              'registeredFirearms.firearmsDetails.transactions.accordion.addTransaction,button',
                            )}
                            variant={EButtonVariants.contained}
                            icon={EIconTypes.plus}
                            onClick={handleAddTransaction}
                            fullWidth={!isDesktop}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                )}
              {samePageAlert && groupTransactionForbiddenAlert}
              {transactionAlert && groupTransactionForbiddenAlert}
              {isDesktop ? (
                <Table
                  columns={firearmTransactionsTableColumns({
                    discardableFirearmTransactionId,
                    showDetails: (ownerId, keeperId) => {
                      if (
                        !hasPermissions([EPermission.VIEW_ALL_TRANSACTIONS]) &&
                        hasPermissions([EPermission.VIEW_TRANSACTIONS])
                      ) {
                        const assignedLegalsIds = assignedLegalsToUser.map(
                          (legal) => legal.uniqueId,
                        );
                        if (assignedLegalsIds.length === 0) {
                          return false;
                        }
                        if (
                          !assignedLegalsIds.includes(ownerId) &&
                          !assignedLegalsIds.includes(keeperId)
                        ) {
                          return false;
                        }
                      }
                      return true;
                    },
                    handleView: hasPermissions(
                      [EPermission.VIEW_ALL_TRANSACTIONS, EPermission.VIEW_TRANSACTIONS],
                      true,
                    )
                      ? (transactionId: string) =>
                          navigate(
                            `${ERouteLinks.ViewTransaction.replace(':id', transactionId)}`,
                          )
                      : null,
                    handleDelete:
                      hasPermissions([
                        EPermission.VIEW_ALL_TRANSACTIONS,
                        EPermission.DISCARD_TRANSACTION,
                      ]) ||
                      hasPermissions([
                        EPermission.VIEW_TRANSACTIONS,
                        EPermission.DISCARD_TRANSACTION,
                      ])
                        ? (transactionId: string) =>
                            navigate(
                              ERouteLinks.CancelTransaction.replace(':id', transactionId),
                            )
                        : null,
                    longDateFormat,
                  })}
                  dataSource={mapFirearmTransactionToDataSource(firearmTransactions)}
                  onSort={setTransactionSort}
                  translationsKeys={{
                    noResults: 'registeredFirearms.accordion.noResults.text',
                  }}
                />
              ) : (
                <CardList
                  items={mapFirearmTransactionToDataSource(firearmTransactions)}
                  render={(data, index) => (
                    <FirearmTransactionTableCard
                      discardableFirearmTransactionId={discardableFirearmTransactionId}
                      data={data}
                      key={index}
                    />
                  )}
                />
              )}
              {paginatorFT && (
                <Pagination
                  count={paginatorFT?.totalElements}
                  perPage={perPageFT}
                  onChangePage={(page) => {
                    setCurrentPageFT(page);
                  }}
                  onChangePerPage={(value) => {
                    setPerPageFT(value);
                  }}
                  current={currentPageFT}
                  isLoading={isTransactionPaginationLoading}
                />
              )}
            </Accordion>
          </Grid>
        )}

        {hasPermissions([EPermission.MANAGE_COMMENTS_FIREARMS]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('registeredFirearms.firearmsDetails.comments.accordion.name')}
              expanded={firearmDetailsAllExpanded}
            >
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: { xs: '20px', sm: '16px' } }}
              >
                <Grid item xs={12} sm={6}>
                  <FormCheckbox
                    name="isShowHidden"
                    label={t(
                      'registeredFirearms.firearmsDetails.comments.accordion.showHiddenComments.label',
                    )}
                    control={control}
                    errors={errors}
                    withValidation={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    id="firearms-details-add-comment"
                    label={t(
                      'registeredFirearms.firearmsDetails.comments.accordion.addComment.button',
                    )}
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() => {
                      navigate(
                        `${ERouteLinks.RegisteredFirearmAddComment.replace(
                          ':id',
                          id as string,
                        )}?expand=comments`,
                      );
                    }}
                    fullWidth={isMobile}
                  />
                </Grid>
              </Grid>
              {isFirearmCommentsLoading ? (
                <Loader isLoading={isFirearmCommentsLoading} />
              ) : (
                firearmComments?.map((item, index) => (
                  <Box key={item.uniqueId} sx={{ marginBottom: '8px' }}>
                    <CommentAccordion
                      visibilityButtonId={`firearm-comment-visibility-button-${index}`}
                      dropdownIconId={`firearm-comment-dropdown-${index}`}
                      name="comments"
                      comment={item}
                      deleteLink={ERouteLinks.RegisteredFirearmDeleteComment}
                    />
                  </Box>
                ))
              )}
              {!firearmComments?.length && (
                <Typography
                  variant="subtitle2"
                  mb={2}
                  textAlign="center"
                  color={colors.primary100}
                >
                  {t('registeredFirearms.accordion.noResults.text')}
                </Typography>
              )}
              {paginatorFComments && (
                <Pagination
                  count={paginatorFComments?.totalElements}
                  perPage={perPageFComments}
                  onChangePage={(page) => {
                    setCurrentPageFComments(page);
                  }}
                  onChangePerPage={(value) => {
                    setPerPageFComments(value);
                  }}
                  current={currentPageFComments}
                  isLoading={isCommentPaginationLoading}
                />
              )}
            </Accordion>
          </Grid>
        )}

        {hasPermissions([EPermission.MANAGE_ATTACHMENTS_FIREARMS]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('registeredFirearms.firearmsDetails.attachments.accordion.name')}
              expanded={firearmDetailsAllExpanded}
              name="attachments"
            >
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: { xs: '20px', sm: '16px' } }}
              >
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    id="firearm-details-add-attachment"
                    label={t(
                      'registeredFirearms.firearmsDetails.attachments.accordion.addAttachments.button',
                    )}
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() => {
                      navigate(
                        `${ERouteLinks.RegisteredFirearmAddAttachment.replace(
                          ':id',
                          id as string,
                        )}?expand=attachments`,
                      );
                    }}
                    fullWidth={isMobile}
                  />
                </Grid>
              </Grid>
              {isDesktop ? (
                <Table
                  id="firearm-attachment-table"
                  columns={firearmAttachmentsTableColumns({
                    firearmId: id as string,
                    handleDelete: handleDeleteAttachment,
                    handleShowTransaction: () => {},
                    handleShowAttachment,
                    longDateFormat,
                  })}
                  dataSource={mapFirearmAttachmentToDataSource(
                    firearmAttachments,
                    transactionType,
                    midDateFormat,
                  )}
                  onSort={setAttachmentSort}
                  translationsKeys={{
                    noResults: 'registeredFirearms.accordion.noResults.text',
                  }}
                />
              ) : (
                <CardList
                  items={mapFirearmAttachmentToDataSource(
                    firearmAttachments,
                    transactionType,
                    midDateFormat,
                  )}
                  render={(data, index) => (
                    <FirearmAttachmentTableCard data={data} key={index} />
                  )}
                />
              )}

              {paginatorFA && (
                <Pagination
                  count={paginatorFA?.totalElements}
                  perPage={perPageFA}
                  onChangePage={(page) => {
                    setCurrentPageFA(page);
                  }}
                  onChangePerPage={(value) => {
                    setPerPageFA(value);
                  }}
                  current={currentPageFA}
                  isLoading={isAttachmentPaginationLoading}
                />
              )}
            </Accordion>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export { ViewRegisteredFirearmPage };
