import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  FC_SYSTEM_AUTH_ACCESS_TOKEN,
  FC_SYSTEM_AUTH_REFRESH_TOKEN,
  refreshAuthToken,
} from 'requests/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { ApiEndpoints } from 'models/apiEndpoints';
import { FC_SYSTEM_APP_LANG } from 'requests/language';
import { IDecodedAccessToken, ILoginBanInfo } from 'models/auth';
import jwt_decode from 'jwt-decode';

const REST_API_URL = process.env.REACT_APP_API_URL || 'http://3.72.173.29:8082/api/';
const apiClient: AxiosInstance = axios.create({
  baseURL: REST_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 20000,
});

const AxiosInterceptor = () => {
  const navigate = useNavigate();
  const { setAlert, clearAlert } = useAlert();

  useEffect(() => {
    const reqInterceptor = async (req: AxiosRequestConfig) => {
      const token = localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
      const selectedLanguage = localStorage.getItem(FC_SYSTEM_APP_LANG);
      if (token && req?.headers && req.url !== ApiEndpoints.GET_LICENCE_BASIC_INFO()) {
        req.headers = {
          ...req.headers,
          ...(selectedLanguage ? { 'Accept-Language': selectedLanguage } : {}),
          ...(req.url?.indexOf(ApiEndpoints.REFRESH_AUTH_TOKEN()) === -1
            ? { Authorization: `Bearer ${token}` }
            : {}),
        };
      }

      return req;
    };
    const resInterceptor = async (response: AxiosResponse) => response;

    const errInterceptor = async (error: AxiosError<any>) => {
      const isLoginError = error.request?.responseURL.indexOf(ApiEndpoints.LOG_IN()) !== -1;
      const isRefreshAuthTokenError =
        error.request?.responseURL.indexOf(ApiEndpoints.REFRESH_AUTH_TOKEN()) !== -1;
      const isChangePasswordError =
        error.request?.responseURL.indexOf(ApiEndpoints.ACCOUNT_CHANGE_PASSWORD()) !== -1;
      const isLicenceError =
        error.request?.responseURL.indexOf(ApiEndpoints.GET_LICENCE_BASIC_INFO()) !== -1;
      const isAccessError = error.response?.data.exception === 'AccessDeniedException';

      const authFailure = (text: string) => {
        setAlert(ActionTypes.SET_AUTH_ALERT, {
          text,
          variant: EAlertVariants.error,
        });
        localStorage.removeItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
        localStorage.removeItem(FC_SYSTEM_AUTH_REFRESH_TOKEN);
        navigate(ERouteLinks.Auth);
      };

      if (error.response?.status === 401 && isLoginError) {
        authFailure(error.response?.data.message);
      } else if (
        error.response?.status === 401 &&
        error.response?.data?.exception === 'InsufficientAuthenticationException'
      ) {
        authFailure('Your session has expired and you have been logged out.');
      } else if (error.response?.status === 401) {
        authFailure(
          'Your session has expired and you have been logged out due to inactivity.',
        );
      } else if (
        error.response?.status === 403 &&
        !isLoginError &&
        !isRefreshAuthTokenError &&
        !isChangePasswordError
      ) {
        if (isAccessError) {
          navigate(ERouteLinks.AccessDeniedPage);
        } else {
          const originalRequest = error.config;
          const refreshToken = localStorage.getItem(FC_SYSTEM_AUTH_REFRESH_TOKEN) || '';
          const selectedLanguage = localStorage.getItem(FC_SYSTEM_APP_LANG);
          const currentTimestamp = Date.now();
          try {
            if (
              originalRequest.url === ApiEndpoints.GET_NOTIFICATION_UNREAD_COUNTER() &&
              refreshToken !== ''
            ) {
              const { exp }: IDecodedAccessToken = jwt_decode(refreshToken);
              if (exp * 1000 > currentTimestamp) {
                return;
              }
              // eslint-disable-next-line consistent-return
              return await Promise.reject(error);
            }
            const res = await refreshAuthToken({
              refreshToken,
            });

            if (res?.accessToken && res?.refreshToken) {
              originalRequest.headers = {
                ...originalRequest.headers,
                ...(selectedLanguage ? { 'Accept-Language': selectedLanguage } : {}),
                Authorization: `Bearer ${res?.accessToken}`,
              };
              localStorage.setItem(FC_SYSTEM_AUTH_ACCESS_TOKEN, res.accessToken);
              localStorage.setItem(FC_SYSTEM_AUTH_REFRESH_TOKEN, res.refreshToken);
            }
            // eslint-disable-next-line consistent-return
            return await apiClient(originalRequest);
          } catch (e: any) {
            authFailure(
              'Your session has expired and you have been logged out due to inactivity.',
            );
          }
        }
      } else if (error.response?.status === 403) {
        if (isChangePasswordError) {
          // eslint-disable-next-line consistent-return
          return Promise.reject(error);
        }

        if (isLoginError) {
          const loginBanInfo = error.response.data as ILoginBanInfo;
          if (loginBanInfo.minutes && loginBanInfo.attempts) {
            authFailure(
              'Your account has been blocked. Please contact your system administrator.',
            );
          }
        } else {
          const accessToken = localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
          const refreshToken = localStorage.getItem(FC_SYSTEM_AUTH_REFRESH_TOKEN);

          if (!!accessToken && !!refreshToken) {
            navigate(ERouteLinks.AccessDeniedPage);
          } else {
            authFailure(
              'Your session has expired and you have been logged out due to inactivity.',
            );
          }
        }
        // eslint-disable-next-line no-dupe-else-if
      } else if (error.response?.status === 404 && !isLicenceError) {
        navigate(ERouteLinks.NotFoundPage);
      } else if (error.response?.status === 404 && isLicenceError) {
        navigate(ERouteLinks.StartLicencePage);
      } else if (error.response?.status === 500) {
        navigate(ERouteLinks.ServerErrorPage);
      } else {
        clearAlert(ActionTypes.CLEAR_AUTH_ALERT);
      }
      // eslint-disable-next-line consistent-return
      return Promise.reject(error);
    };

    const interceptorReq = apiClient.interceptors.request.use(reqInterceptor, errInterceptor);
    const interceptorResp = apiClient.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    return () => {
      apiClient.interceptors.response.eject(interceptorResp);
      apiClient.interceptors.response.eject(interceptorReq);
    };
  }, [navigate]);

  return null;
};

export { apiClient, AxiosInterceptor };
