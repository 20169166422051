import { ISearchFilterFirearmRequest } from 'models/searchFilter';
import { clearObject } from 'helpers/objects';
import { ISearchFormValues } from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { IParsedDictionary } from 'helpers/dictionary';

export const mapValuesToFirearmPresetRequest = (
  data: ISearchFormValues,
): ISearchFilterFirearmRequest => {
  const values: ISearchFilterFirearmRequest = {
    fireCycleId: data.fireCycleId,
    stateUniqueId: data.stateUniqueId,
    legalityUniqueId: data.legalityUniqueId,
    make: data.make,
    model: data.model,
    manufacturer: data.manufacturer,
    caliber: data.caliber,
    productCode: data.productCode,
    action: data.action,
    type: data.type,
    ownerUniqueId: data.ownerUniqueId,
    keeperUniqueId: data.keeperUniqueId,
    serialNumber: data.serialNumber,
  };
  return clearObject(values);
};

export const permittedFirearmFilterFields = [
  'fireCycleId',
  'stateUniqueId',
  'legalityUniqueId',
  'make',
  'model',
  'caliber',
  'productCode',
  'action',
  'type',
  'ownerName',
  'keeperName',
  'serialNumber',
];

const excludedFields = ['ownerUniqueId', 'keeperUniqueId'];

export const excludeOwnerAndKeeperIds = (values: { key: string; value: string }[]) =>
  values.filter((item) => !excludedFields.includes(item.key));

export const mapValuesWithDictionariesHelper = (
  values: { key: string; value: string }[],
  dictionaries: { [key: string]: IParsedDictionary | any },
) =>
  values.map((item) => {
    if (dictionaries?.[item.key] && dictionaries[item.key]?.[item.value]) {
      item.value = dictionaries[item.key]?.[item.value];
    }
    return item;
  });
