export enum EIconTypes {
  administration = 'administration',
  administration1 = 'administration1',
  account = 'account',
  ancillaries = 'ancillaries',
  arrowEndRight = 'arrow-end-right',
  arrowLeft = 'arrow-left',
  calendar = 'calendar',
  cancel = 'cancel',
  checkmark = 'checkmark',
  closeSmall = 'close-small',
  close = 'close',
  clear = 'clear',
  copy = 'copy',
  delete = 'delete',
  download = 'download',
  dropdownDown = 'dropdown-down',
  dropdownUp = 'dropdown-up',
  edit = 'edit',
  error = 'error',
  error1 = 'error1',
  filters = 'filters',
  firearm = 'firearm',
  focus1 = 'focus1',
  focus2 = 'focus2',
  home = 'home',
  individuals = 'individuals',
  info = 'info',
  info2 = 'info2',
  left = 'left',
  logout = 'logout',
  minus = 'minus',
  menu = 'menu',
  navigation = 'navigation',
  notification = 'notification',
  notificationOn = 'notification-on',
  organization = 'organization',
  organization2 = 'organizations2',
  pin = 'pin',
  plus = 'plus',
  plus1 = 'plus1',
  reports = 'reports',
  right = 'right',
  search = 'search',
  settings = 'settings',
  smallCheckmark = 'small-checkmark',
  smallDropdownDown = 'small-dropdown-down',
  smallDropdownUp = 'small-dropdown-up',
  sortDown = 'sort-down',
  shutDown = 'shut-down',
  transactions = 'transactions',
  view = 'view',
  hidden = 'hidden',
  fullScreen = 'fullScreen',
  fullScreenExit = 'fullScreenExit',
}
