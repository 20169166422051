import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';

import {
  legalEntityGroupSearchFirearmTableColumns,
  mapLEGroupSearchFirearmToDataSource,
} from 'pages/LegalEntitiesPage/helpers';

import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Table } from 'components/molecules/Table';
import { Pagination } from 'components/molecules/Pagination';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { CardList } from 'components/molecules/CardList';
import { StyledPageTitle } from 'theme/styles';
import { useFirearms } from 'pages/Firearms/hooks';
import { LegalEntityGroupSearchFirearmTableCard } from 'components/organisms/LegalEntityGroupSearchFirearmTableCard';
import { SearchFirearmsForm } from 'components/organisms/SearchFirearmsForm';
import { IFirearm } from 'models/firearm';
import { Box } from '@mui/material';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import {
  mapLegalityActivityMapColor,
  mapLegalityActivityMapHover,
  renderDataColor,
} from 'components/molecules/Table/helpers';

const LegalEntityGroupFirearmSearchPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const queryParams = useQuery();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();

  const { loadDictionaries } = useLegalEntities();
  const { getFirearmsAvailableForGroupData, paginator, firearms } = useFirearms();
  const { isDesktop } = useThemeBreakpoint();

  const type: string | null = queryParams.get('type');
  const groupId: string = queryParams.get('groupId') || '';

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [query, setQuery] = useState<Partial<any>>({});
  const [sort, setSort] = useState<ISortOptions | null>(null);
  const { state }: any = useLocation();
  const [selectedFirearms, setSelectedFirearms] = useState<IFirearm[]>(
    state?.prevSelectedFirearms || [],
  );
  const [areAllFirearmsSelected, setAreAllFirearmsSelected] = useState<boolean>(false);
  const { handleCreateLEGroupFirearm } = useLegalEntities();
  const { setAlert, clearAlert, samePageAlert, legalEntityAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  useEffect(() => {
    (async function init() {
      await loadDictionaries([EDictionaryTypes.Make, EDictionaryTypes.State]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (groupId) {
      query.groupUniqueId = groupId;
    }
    (async function getFirearmsDataInit() {
      setIsPaginationLoading(true);
      await getFirearmsAvailableForGroupData(id, currentPage, perPage, query, sort);
      setIsPaginationLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, query, sort]);

  useEffect(() => {
    if (firearms.length === 0) return;
    let allSelected = true;
    const selectedIds = selectedFirearms.map((selectedFirearm) => selectedFirearm.uniqueId);

    firearms
      .map((firearm) => firearm.uniqueId)
      .forEach((firearmId) => {
        if (!selectedIds.includes(firearmId)) {
          allSelected = false;
        }
      });
    setAreAllFirearmsSelected(allSelected);
  }, [firearms, selectedFirearms]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_LEGAL_ENTITY_ALERT);
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleChangeSelectedFirearms = (firearm: IFirearm) => {
    setSelectedFirearms((prevState: IFirearm[]) =>
      // @ts-ignore
      selectedFirearms.find((item: IFirearm) => item.uniqueId === firearm.uniqueId)
        ? // @ts-ignore
          prevState.filter((item: IFirearm) => item.uniqueId !== firearm.uniqueId)
        : [...prevState, firearm],
    );
  };

  const handleSelectAllFirearms = () => {
    if (areAllFirearmsSelected) {
      const firearmIds = firearms.map((firearm) => firearm.uniqueId);
      const newSelected = selectedFirearms.filter(
        (selectedFirearm) => !firearmIds.includes(selectedFirearm.uniqueId),
      );
      setSelectedFirearms(newSelected);
    } else {
      const newSelected = [...selectedFirearms];
      firearms.forEach((firearm) => {
        if (
          !selectedFirearms
            .map((selectedFirearm) => selectedFirearm.uniqueId)
            .includes(firearm.uniqueId)
        ) {
          newSelected.push(firearm);
        }
      });
      setSelectedFirearms(newSelected);
    }
  };

  const handleAddFirearms = async (firearmIds: string[]) => {
    try {
      await handleCreateLEGroupFirearm(id, groupId, firearmIds);
    } catch (e: any) {
      setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: e.response.data.message,
        variant: EAlertVariants.error,
      });
    }
  };

  const prepareFirearmsParam = (firearmList: any[]) => {
    if (!firearmList || firearmList.length === 0) {
      return '';
    }
    const ids = firearmList.map((frm) => String(frm.id));
    return `&firearms=${ids.join(',')}`;
  };

  const handleAddSelectedFirearms = async () => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    if (!selectedFirearms.length) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('groupDetails.addFirearm.validation.noneSelected'),
        variant: EAlertVariants.warning,
      });
      return;
    }

    const properStates = selectedFirearms.every(
      (selectedFirearm: IFirearm, index: number, array1: IFirearm[]) =>
        array1[0].state === selectedFirearm.state &&
        array1[0].owner === selectedFirearm.owner &&
        array1[0].keeper === selectedFirearm.keeper,
    );

    if (!properStates) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('legalEntityGroup.searchFirearm.pleaseChooseSame'),
        variant: EAlertVariants.error,
      });
      return;
    }

    setIsLoading(true);

    if (state?.isDetailsContext) {
      const firearmIds = selectedFirearms.map((selectedFirearm) => selectedFirearm.uniqueId);
      await handleAddFirearms(firearmIds);
    } else {
      const firearmsParam = prepareFirearmsParam(selectedFirearms);
      navigate(
        `${ERouteLinks.LegalEntityAddGroup.replace(
          ':id',
          id,
        )}?type=${type}&expand=groups${firearmsParam}`,
        {
          state: {
            selectedFirearms,
            groupName: state?.groupName,
          },
        },
      );

      setIsLoading(false);
    }
  };

  const handleCancelButton = () => {
    if (state?.isDetailsContext && !groupId) {
      navigate(
        `${ERouteLinks.LegalEntityAddGroup.replace(':id', id)}?type=${type}&expand=groups`,
      );
    } else if (state?.fromAddPage) {
      navigate(
        `${ERouteLinks.LegalEntityAddGroup.replace(':id', id)}?type=${type}&expand=groups`,
        {
          state: {
            groupName: state?.groupName,
            selectedFirearms: state?.prevSelectedFirearms,
          },
        },
      );
    } else {
      navigate(`${ERouteLinks.ViewLegalEntity.replace(':id', id)}?type=${type}&expand=groups`);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=groups`,
          },
          ...(state?.isDetailsContext
            ? ''
            : [
                {
                  label: t('addGroup.header'),
                  route: `${ERouteLinks.LegalEntityAddGroup.replace(
                    ':id',
                    id,
                  )}?type=${type}&expand=groups`,
                  state: {
                    groupName: state?.groupName,
                    selectedFirearms: state?.prevSelectedFirearms,
                  },
                },
              ]),
          t('searchForLegalEntitysFirearm.label'),
        ]}
      />
      <StyledPageTitle variant="h4" mb={2}>
        {t('searchForLegalEntitysFirearm.label')}
      </StyledPageTitle>
      <Grid container>
        <Grid item xs={12}>
          <SearchFirearmsForm onSubmit={setQuery} />
        </Grid>
        <Grid item xs={12}>
          <Box my={2} sx={{ visibility: legalEntityAlert ? 'visible' : 'hidden' }}>
            <Alert
              text={legalEntityAlert ? legalEntityAlert.text : ''}
              variant={legalEntityAlert ? legalEntityAlert.variant : EAlertVariants.success}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isDesktop ? (
            <Table
              columns={legalEntityGroupSearchFirearmTableColumns(
                (selectedFirearm: IFirearm) => handleChangeSelectedFirearms(selectedFirearm),
                handleSelectAllFirearms,
                areAllFirearmsSelected,
                selectedFirearms,
                (data: ITableDataSource) => renderDataColor(data).stateField,
              )}
              specificRowColor={mapLegalityActivityMapColor}
              specificRowColorHover={mapLegalityActivityMapHover}
              dataSource={mapLEGroupSearchFirearmToDataSource(firearms)}
              onSort={setSort}
            />
          ) : (
            <div>
              <CardList
                items={mapLEGroupSearchFirearmToDataSource(firearms)}
                render={(data, index) => (
                  <LegalEntityGroupSearchFirearmTableCard
                    data={data}
                    key={index}
                    selectAction={(selectedFirearm) =>
                      handleChangeSelectedFirearms(selectedFirearm)
                    }
                    selectedFirearms={selectedFirearms}
                    renderColor={renderDataColor}
                  />
                )}
              />
            </div>
          )}

          {paginator && (
            <Pagination
              count={paginator?.totalElements}
              perPage={perPage}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onChangePerPage={(value) => {
                setPerPage(value);
              }}
              current={currentPage}
              isLoading={isPaginationLoading}
            />
          )}
        </Grid>
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              fullWidth
              id="add-selected-firearms-button"
              label={t('searchForLegalEntitysFirearm.addSelected.button')}
              variant={EButtonVariants.contained}
              sx={{ mr: 1 }}
              isLoading={isLoading}
              onClick={handleAddSelectedFirearms}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Button
              fullWidth
              id="cancel-button"
              label={t('general.cancel.button')}
              variant={EButtonVariants.outlined}
              onClick={handleCancelButton}
            />
          </Grid>
        </Grid>
      </Grid>
      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { LegalEntityGroupFirearmSearchPage };
