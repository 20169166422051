import { useState } from 'react';
import {
  ISearchFormValues,
  ITransaction,
  ITransactionDetails,
  ITransactionDetailsAncillary,
  ITransactionDetailsCommentsQuery,
  ITransactionDetailsFirearm,
} from 'models/transaction';
import {
  cancelAncillaryTransaction,
  cancelFirearmTransaction,
  cancelTransactionForAncillaryGroup,
  cancelTransactionForGroup,
  createCommentInTransaction,
  getTransactionDetails,
  getTransactionDetailsAncillaries,
  getTransactionDetailsComments,
  getTransactionDetailsFirearms,
  getTransactions,
  isTransactionLast,
} from 'requests/transaction';
import { useDictionary } from 'models/dictionaryContext';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { ICommentData } from 'models/comment';
import { ICommentInTransactionBody } from 'models/form';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';
import { deleteComment } from 'requests/comment';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';

export interface ICancelFirearmTransactionParameters {
  uniqueId: string;
  firearmUniqueId: string;
}

export interface ICancelAncillaryTransactionParameters {
  uniqueId: string;
  ancillaryUniqueId: string;
}

export interface ICancelTransactionForGroupParameters {
  uniqueId: string;
  groupUniqueId: string;
}

export const useTransactions = () => {
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const { transactionType, getTransactionType } = useDictionary();
  const { setAlert } = useAlert();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { id: transactionId = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const expand: any = query.get('expand');

  const loadDictionariesForTransactionDetails = async () => {
    getTransactionType();
  };
  const [transactionDetails, setTransactionDetails] = useState<ITransactionDetails | null>(
    null,
  );
  const [transactionDetailsFirearmsContent, setTransactionDetailsFirearmsContent] = useState<
    ITransactionDetailsFirearm[]
  >([]);
  const [transactionDetailsFirearmsPaginator, setTransactionDetailsFirearmsPaginator] =
    useState<IPaginator | null>(null);

  const [transactionDetailsAncillariesContent, setTransactionDetailsAncillariesContent] =
    useState<ITransactionDetailsAncillary[]>([]);
  const [transactionDetailsAncillariesPaginator, setTransactionDetailsAncillariesPaginator] =
    useState<IPaginator | null>(null);

  const [transactionDetailsCommentsContent, setTransactionDetailsCommentsContent] = useState<
    ICommentData[]
  >([]);
  const [transactionDetailsCommentsPaginator, setTransactionDetailsCommentsPaginator] =
    useState<IPaginator | null>(null);
  const [isTransactionDetailsCommentsDataLoading, setTransactionDetailsCommentsDataLoading] =
    useState<boolean>(false);

  const getTransactionsData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    queryTransactions: Partial<ISearchFormValues> = {},
    sort?: ISortOptions | null | undefined,
  ) => {
    const transactionData = await getTransactions(
      currentPage,
      perPage,
      queryTransactions,
      sort,
    );

    if (transactionData) {
      const { content, ...paginatorData } = transactionData;
      setTransactions(content);
      setPaginator(paginatorData);
    } else {
      setTransactions([]);
      setPaginator(null);
    }
  };

  const handleGetTransactionDetailsData = async (id: string) => {
    const transactionDetailsData = await getTransactionDetails(id);

    if (transactionDetailsData) {
      setTransactionDetails(transactionDetailsData);
    } else {
      setTransactionDetails(null);
    }
  };

  const handleGetTransactionDetailsFirearmsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    sort?: ISortOptions | null,
  ) => {
    const transactionDetailsFirearmsData = await getTransactionDetailsFirearms(
      id,
      currentPage,
      perPage,
      sort,
    );

    if (transactionDetailsFirearmsData) {
      const { content, ...paginatorData } = transactionDetailsFirearmsData;
      setTransactionDetailsFirearmsContent(content);
      setTransactionDetailsFirearmsPaginator(paginatorData);
    } else {
      setTransactionDetailsFirearmsContent([]);
      setTransactionDetailsFirearmsPaginator(null);
    }
  };

  const handleGetTransactionDetailsAncillariesData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    sort?: ISortOptions | null,
  ) => {
    const transactionDetailsAncillariesData = await getTransactionDetailsAncillaries(
      id,
      currentPage,
      perPage,
      sort,
    );

    if (transactionDetailsAncillariesData) {
      const { content, ...paginatorData } = transactionDetailsAncillariesData;
      setTransactionDetailsAncillariesContent(content);
      setTransactionDetailsAncillariesPaginator(paginatorData);
    } else {
      setTransactionDetailsAncillariesContent([]);
      setTransactionDetailsAncillariesPaginator(null);
    }
  };

  const handleGetTransactionDetailsCommentsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    queryParams: Partial<ITransactionDetailsCommentsQuery> = {},
  ) => {
    setTransactionDetailsCommentsDataLoading(true);
    const transactionDetailsCommentsData = await getTransactionDetailsComments(
      id,
      currentPage,
      perPage,
      queryParams,
    );

    if (transactionDetailsCommentsData) {
      const { content, ...paginatorData } = transactionDetailsCommentsData;
      setTransactionDetailsCommentsContent(content);
      setTransactionDetailsCommentsPaginator(paginatorData);
    } else {
      setTransactionDetailsCommentsContent([]);
      setTransactionDetailsCommentsPaginator(null);
    }
    setTransactionDetailsCommentsDataLoading(false);
  };

  const handleCancelFirearmTransaction = async ({
    uniqueId,
    firearmUniqueId,
  }: ICancelFirearmTransactionParameters) => {
    const res = await cancelFirearmTransaction({ uniqueId, firearmUniqueId });

    if (res) {
      return res;
    }

    return null;
  };

  const handleCancelAncillaryTransaction = async ({
    uniqueId,
    ancillaryUniqueId,
  }: ICancelAncillaryTransactionParameters) => {
    const res = await cancelAncillaryTransaction({ uniqueId, ancillaryUniqueId });

    if (res) {
      return res;
    }

    return null;
  };

  const checkIfTransactionIsLast = async (uniqueId: string) => isTransactionLast(uniqueId);

  const handleCancelTransactionForGroup = async ({
    uniqueId,
    groupUniqueId,
  }: ICancelTransactionForGroupParameters) => {
    const res = await cancelTransactionForGroup({ uniqueId, groupUniqueId });

    if (res) {
      return res;
    }
    return undefined;
  };

  const handleCancelTransactionForAncillaryGroup = async ({
    uniqueId,
    groupUniqueId,
  }: ICancelTransactionForGroupParameters) => {
    const res = await cancelTransactionForAncillaryGroup({ uniqueId, groupUniqueId });

    if (res) {
      return res;
    }
    return undefined;
  };

  const handleCreateComment = async (id: string, body: ICommentInTransactionBody) => {
    const res = await createCommentInTransaction(id, body);
    if (res) {
      setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
        text: t('transactionDetails.addComment.commentAdded.text'),
      });
      return res;
    }
    return null;
  };

  const handleDeleteComment = async (id: string, isHidden: any) => {
    const response = await deleteComment(id, isHidden);
    if (response) {
      await setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
        text: isHidden
          ? t('transactionDetails.enableComment.commentEnabled.text')
          : t('transactionDetails.disableComment.commentDisabled.text'),
      });
    }
    return response;
  };

  const navigateToDetails = () => {
    navigate(`${ERouteLinks.ViewTransaction.replace(':id', transactionId)}?expand=${expand}`);
  };

  return {
    getTransactionsData,
    transactions,
    paginator,
    handleGetTransactionDetailsData,
    transactionDetails,
    loadDictionariesForTransactionDetails,
    transactionType,
    handleGetTransactionDetailsFirearmsData,
    transactionDetailsFirearmsContent,
    transactionDetailsFirearmsPaginator,
    handleGetTransactionDetailsAncillariesData,
    handleCancelTransactionForAncillaryGroup,
    transactionDetailsAncillariesContent,
    transactionDetailsAncillariesPaginator,
    handleGetTransactionDetailsCommentsData,
    transactionDetailsCommentsContent,
    transactionDetailsCommentsPaginator,
    isTransactionDetailsCommentsDataLoading,
    checkIfTransactionIsLast,
    handleCancelTransaction: handleCancelFirearmTransaction,
    handleCancelTransactionForGroup,
    handleCreateComment,
    handleCancelAncillaryTransaction,
    handleDeleteComment,
    navigateToDetails,
  };
};
