import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { ISearchFilterFirearmRequest } from 'models/searchFilter';
import { createSearchFilterFirearm } from 'requests/searchFilter';

interface ISavePreset {
  type: ESearchFilterKeys | ELegalEntityTypes;
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
  body: ISearchFilterFirearmRequest;
}

export const useSavePreset = () => {
  const savePreset = ({
    type,
    name,
    emailNotificationEnabled,
    notificationEnabled,
    body,
  }: ISavePreset) => {
    if (type === ESearchFilterKeys.firearm) {
      return createSearchFilterFirearm(
        name,
        emailNotificationEnabled,
        notificationEnabled,
        body as ISearchFilterFirearmRequest,
      );
    }
    return null;
  };

  return { savePreset };
};
