import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';

export const StyledSubtitle = styled(Grid)`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${colors.gray300};

  & h6 {
    color: ${colors.gray300};
    text-transform: uppercase;
  }
`;

export const StyledAdvancedSearch = styled(Grid)`
  margin-top: 5px;
  margin-bottom: 15px;
`;
