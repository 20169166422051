import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, PaginationItem, PaginationRenderItemParams, Typography } from '@mui/material';
import { EPerPages, mapToEPerPages } from 'models/table';
import { useForm } from 'react-hook-form';
import { perPages } from 'models/paginator';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useTranslations } from 'hooks/useTranslations';
import { Loader } from 'components/atoms/Loader';
import {
  PaginationPerPage,
  PaginationSummary,
  PaginationWrapper,
  PaginatorPerPageSelect,
  StyledPagination,
} from './styles';

interface IPagination {
  count: number;
  onChangePage: (page: number) => void;
  current: number;
  perPage: EPerPages;
  onChangePerPage: (perPage: EPerPages) => void;
  isLoading: boolean;
  isVisible?: boolean;
}

interface IPaginationForm {
  perPage: string;
}

const Pagination = ({
  count,
  onChangePage,
  current,
  perPage,
  onChangePerPage,
  isLoading,
  isVisible = true,
}: IPagination) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IPaginationForm>({
    defaultValues: { perPage: EPerPages.perPage25.toString() },
  });

  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();

  const watchPerPage: unknown = watch('perPage');

  useEffect(() => {
    onChangePage(1);
    onChangePerPage(mapToEPerPages(watchPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPerPage]);

  const pagesCount = Math.ceil(count / perPage);
  const currentCount = current * perPage;
  const summary = `${(current - 1) * perPage + 1}-${
    currentCount <= count ? currentCount : count
  } ${t('general.of.label')} ${count}`;

  if (!count) {
    return null;
  }

  const isDisabled = (item: PaginationRenderItemParams): boolean =>
    (item.type === 'page' &&
      item.page === pagesCount &&
      pagesCount > 4 &&
      ![current - 1, current + 1].includes(item.page)) ||
    (current === pagesCount && item.type === 'next') ||
    (current === 1 && item.type === 'previous') ||
    (current === 1 && item.type === 'first');

  const renderMobilePaginatorItem = (item: PaginationRenderItemParams) => {
    if (item.page === current || item.page === pagesCount || !(item.type === 'page')) {
      if ((item.page === 2 || item.page === 3) && item.type === 'page') {
        return (
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                paddingTop: '6px !important',
              }}
            >
              <PaginationItem type="start-ellipsis" />
            </Box>
            <PaginationItem {...item} disabled={isDisabled(item)} />
          </Box>
        );
      }
      return <PaginationItem {...item} disabled={isDisabled(item)} />;
    }
    return undefined;
  };

  const renderDesktopPaginationItem = (item: PaginationRenderItemParams) => (
    <PaginationItem {...item} disabled={isDisabled(item)} />
  );

  const renderLoader = () => (
    <Box
      mb={2}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader isLoading={true} />
    </Box>
  );

  const renderPagination = () => (
    <PaginationWrapper container spacing={2}>
      <PaginationPerPage item>
        <PaginatorPerPageSelect
          options={perPages}
          name="perPage"
          label={t('general.perPage.label')}
          setValue={setValue}
          canBeEmpty={false}
          control={control}
          errors={errors}
        />
      </PaginationPerPage>
      <PaginationSummary item xs={2}>
        <Typography variant="subtitle2">{summary}</Typography>
      </PaginationSummary>
      <Grid item sx={{ pr: !isDesktop ? 2 : 0 }}>
        {isDesktop ? (
          <StyledPagination
            count={pagesCount}
            page={current}
            onChange={(event, page) => onChangePage(page)}
            showFirstButton
            renderItem={renderDesktopPaginationItem}
            variant="outlined"
            shape="rounded"
            boundaryCount={1}
            siblingCount={0}
          />
        ) : (
          <StyledPagination
            count={pagesCount}
            page={current}
            onChange={(event, page) => onChangePage(page)}
            showFirstButton
            renderItem={renderMobilePaginatorItem}
            variant="outlined"
            shape="rounded"
            boundaryCount={1}
            siblingCount={0}
          />
        )}
      </Grid>
    </PaginationWrapper>
  );

  return (
    <>
      {isLoading && isVisible && renderLoader()}
      {!isLoading && isVisible && renderPagination()}
    </>
  );
};
export { Pagination };
