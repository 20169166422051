import React, { useEffect } from 'react';
import { ITableDataSource } from 'models/table';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Chips } from 'components/atoms/Chips';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { ITransactionItemType } from 'models/transaction';
import { renderDataColor } from 'components/molecules/Table/helpers';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ITransactionItemCard {
  data: ITableDataSource;
  handleView?: (args: string) => void;
}

const TransactionItemCard = ({ data, handleView }: ITransactionItemCard) => {
  const { t } = useTranslations();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderDataColor(data).border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderDataColor(data).background,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.transactionDate.column')}
        </Typography>
        <Typography variant="body2">{formatDate(data.date, longDateFormat)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.transactionType.column')}
        </Typography>
        <Chips text={data.type} variant={renderDataColor(data).stateField} />
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.state.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all', overflowWrap: 'anywhere' }}>
          <Chips text={data.state} variant={renderDataColor(data).stateField} />
        </Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.owner.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.transactionType.firearmAncillaryType')}
        </Typography>
        <Typography variant="body2">
          {ITransactionItemType[data.transactionItemType as keyof typeof ITransactionItemType]}
        </Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionsList.transactionType.manufacturer')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${renderDataColor(data).border}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('transactionsList.action.column')}
            {':  '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {handleView && (
            <Button
              label={t('transactionsList.details.link')}
              variant={EButtonVariants.outlined}
              onClick={() => handleView(data.transactionId)}
              size="small"
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { TransactionItemCard };
