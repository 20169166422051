import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LegalEntityAncillaryGroupForm } from 'pages/LegalEntitiesPage/LegalEntityAncillaryGroupForm';
import { ILegalEntityAncillaryGroupFormValues } from 'models/form';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Table } from 'components/molecules/Table';
import {
  legalEntityGroupAncillaryTableColumns,
  mapLEGroupAncillaryListToDataSource,
} from 'pages/LegalEntitiesPage/helpers';
import { CardList } from 'components/molecules/CardList';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { LegalEntityAncillaryGroupAncillaryTableCard } from 'pages/LegalEntitiesPage/LegalEntityAncillaryGroupAncillaryTableCard';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { IAncillary } from 'models/ancillaries';
import { useTranslations } from 'hooks/useTranslations';
import {
  renderDataColor,
  renderRowColor,
  renderRowColorHover,
} from 'components/molecules/Table/helpers';

const LegalEntityAddAncillaryGroupPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');

  const { handleCreateAncillaryGroup, navigateToDetails } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const { legalEntityAlert, nextPageAlert, clearAlert, setAlert } = useAlert();
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const [selectedAncillaries, setSelectedAncillaries] = useState<IAncillary[]>([]);
  const [groupName, setGroupName] = useState<string>(routerState?.groupName || '');
  const [ancillaryOpenDetail, setAncillaryOpenDetail] = useState<string>('');
  const { t } = useTranslations();

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (routerState && routerState?.selected) {
      setSelectedAncillaries(routerState.selected);
    }
  }, [routerState]);

  const handleSubmit = async (data: ILegalEntityAncillaryGroupFormValues) => {
    try {
      await handleCreateAncillaryGroup({
        ...data,
        uniqueId: id,
        ...(selectedAncillaries
          ? {
              ancillariesIds: selectedAncillaries.map((item: IAncillary) => item.uniqueId),
            }
          : []),
      });
    } catch (e: any) {
      setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };

  const handleDeleteSelectedAncillary = (ancillaryId: string) => {
    setSelectedAncillaries((prevState) =>
      prevState.filter((item) => item.uniqueId !== ancillaryId),
    );
    routerState.selected = routerState?.selected.filter(
      // @ts-ignore
      (item: IAncillary) => item.uniqueId !== ancillaryId,
    );
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=groups`,
          },
          t('addGroup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addGroup.header')}</StyledPageTitle>

      <FormWrapper>
        <LegalEntityAncillaryGroupForm
          onCancel={navigateToDetails}
          onSubmit={handleSubmit}
          onNameChange={(name: string) => {
            setGroupName(name);
          }}
          initialFormValues={{
            name: groupName,
            uniqueId: id,
            ancillariesIds:
              routerState && routerState.selected && routerState.selected.length
                ? routerState?.selected.filter((ancillary: IAncillary) => ancillary.uniqueId)
                : [],
          }}
        >
          <>
            <StyledPageTitle variant="h4">
              {t('addGroup.ancillaryList.section.label')}
            </StyledPageTitle>
            <Typography variant="body1" sx={{ margin: '20px 0' }}>
              {t('addGroup.ancillaryList.subText.label')}
            </Typography>
            <Grid item xs={12} sm={5}>
              <Button
                id="search-ancillary-button"
                label={t('addGroup.ancillaryList.section.searchAncillary.button')}
                variant={EButtonVariants.outlined}
                fullWidth
                onClick={() =>
                  navigate(
                    `${ERouteLinks.LegalEntityAddAncillaryGroupSearch.replace(
                      ':id',
                      id,
                    )}?type=${type}&expand=ancillary-groups`,
                    {
                      state: {
                        groupName,
                        prevSelectedAncillaries: routerState?.selected,
                      },
                    },
                  )
                }
              />
            </Grid>
            {selectedAncillaries?.length ? (
              <Box sx={{ marginTop: '20px' }}>
                {isDesktop ? (
                  <Table
                    columns={legalEntityGroupAncillaryTableColumns({
                      handleView: (ancillaryId, ancillaryType) =>
                        window.open(
                          ERouteLinks.ViewAncillary.replace(':id', ancillaryId).replace(
                            ':type',
                            ancillaryType,
                          ),
                          '_blank',
                        ),
                      handleDelete: (ancillaryId: string) =>
                        handleDeleteSelectedAncillary(ancillaryId),
                      ancillaryOpenDetail,
                      setAncillaryOpenDetail,
                    })}
                    specificRowColor={renderRowColor}
                    specificRowColorHover={renderRowColorHover}
                    dataSource={mapLEGroupAncillaryListToDataSource(selectedAncillaries)}
                    translationsKeys={{
                      noResults: 'registeredAncillaries.accordion.noResults.text',
                    }}
                  />
                ) : (
                  <CardList
                    items={mapLEGroupAncillaryListToDataSource(selectedAncillaries)}
                    translationsKeys={{
                      noResults: 'legalEntityDetails.ancillary.tab.search.noResult.info',
                    }}
                    render={(data, index) => (
                      <Box mb={2} key={data.id}>
                        <LegalEntityAncillaryGroupAncillaryTableCard
                          data={data}
                          key={index}
                          renderColor={renderDataColor}
                          deleteAction={(ancillaryId: string) =>
                            handleDeleteSelectedAncillary(ancillaryId)
                          }
                        />
                      </Box>
                    )}
                  />
                )}
              </Box>
            ) : null}
          </>
        </LegalEntityAncillaryGroupForm>
      </FormWrapper>
      {legalEntityAlert && (
        <Box mt={2}>
          <Alert text={legalEntityAlert.text} variant={legalEntityAlert.variant} />
        </Box>
      )}
      {nextPageAlert && (
        <Box mt={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { LegalEntityAddAncillaryGroupPage };
