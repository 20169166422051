import { EPerPages, ISortOptions } from 'models/table';
import { queryStringWithSort } from 'helpers/request';
import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import {
  IApiKeyHistoryResponse,
  IApiKeyRequest,
  IApiKeyResponse,
  IApiKeysResponse,
} from 'models/apiKey';

const getApiKeys = async (
  page: number,
  size: EPerPages,
  query: object,
  sort: ISortOptions | null | undefined,
) => {
  const queryString = queryStringWithSort({ page: page - 1, size, ...query }, sort);

  const { data } = await apiClient.get<TResponse<IApiKeysResponse>>(
    `${ApiEndpoints.GET_API_KEYS()}?${queryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createApiKey = async (body: IApiKeyRequest) => {
  await apiClient.post(ApiEndpoints.CREATE_API_KEY(), body);
};

const getApiKey = async (id: string) => {
  const { data } = await apiClient.get<TResponse<IApiKeyResponse>>(
    ApiEndpoints.GET_API_KEY(id),
  );
  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const updateApiKey = async (id: string, body: IApiKeyRequest) => {
  await apiClient.put(ApiEndpoints.UPDATE_API_KEY(id), body);
};

const changeStatusApiKey = async (id: string, status: string) => {
  await apiClient.patch(`${ApiEndpoints.CHANGE_STATUS_API_KEY(id)}?status=${status}`);
};

const getApiKeyHistory = async (id: string) => {
  const { data } = await apiClient.get<TResponse<IApiKeyHistoryResponse>>(
    ApiEndpoints.GET_API_KEY_HISTORY(id),
  );
  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const deleteApiKey = async (id: string) => apiClient.delete(ApiEndpoints.DELETE_API_KEY(id));

const sendApiKeyEmail = async (id: string) =>
  apiClient.post(ApiEndpoints.SEND_EMAIL_API_KEY(id));

export {
  getApiKeys,
  createApiKey,
  getApiKey,
  updateApiKey,
  changeStatusApiKey,
  getApiKeyHistory,
  deleteApiKey,
  sendApiKeyEmail,
};
