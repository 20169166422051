import React, { useEffect, useState } from 'react';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { useAuth } from 'models/authContext';
import {
  StyledLogoutButton,
  StyledMenuProfileItem,
  StyledProfileItem,
} from 'components/molecules/MenuProfileItem/styles';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';

interface IMenuProfileItem {
  open: boolean;
  mobile: boolean;
  close: () => void;
}

const MenuProfileItem = ({ open, mobile, close }: IMenuProfileItem) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { logOut, isLogOutLoading, user, getUser } = useAuth();
  const [userEmail, setUserEmail] = useState<string>('');
  const { isDesktop } = useThemeBreakpoint();

  useEffect(() => {
    if (user === null) {
      getUser();
    }
    if (user && user.email) {
      if (user.email.length > 33) {
        setUserEmail(`${user.email.slice(0, 33)}...`);
      } else {
        setUserEmail(user.email);
      }
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <StyledMenuProfileItem open={open} mobile={mobile}>
        <StyledProfileItem open={open}>
          <div id="name">
            <p>{`${user?.firstName} ${user?.lastName}`}</p>
          </div>
          <div>
            <Button
              variant={EButtonVariants.contained}
              icon={EIconTypes.settings}
              sizePX={open ? EButtonSizesPX.px24 : EButtonSizesPX.px48}
              onClick={() => {
                if (!isDesktop) close();
                navigate(ERouteLinks.Account);
              }}
            />
          </div>
        </StyledProfileItem>
        <div id="email">
          <p>{userEmail}</p>
        </div>
      </StyledMenuProfileItem>

      <StyledLogoutButton
        open={open}
        mobile={mobile}
        label={t('general.logout.button')}
        variant={EButtonVariants.contained}
        icon={EIconTypes.logout}
        onClick={() =>
          logOut(() => {
            navigate(ERouteLinks.Auth);
          })
        }
        isLoading={isLogOutLoading}
      />
    </>
  );
};
export { MenuProfileItem };
