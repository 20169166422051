import React, { createContext, useContext, useMemo, useState } from 'react';

interface DrawerClickedProviderProps {
  children: React.ReactNode;
}

const DrawerClickedContext = createContext<
  | {
      drawerClicked: boolean;
      setDrawerClicked: React.Dispatch<React.SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

export const DrawerProvider: React.FC<DrawerClickedProviderProps> = ({ children }) => {
  const [drawerClicked, setDrawerClicked] = useState<boolean>(false);
  const contextValue = useMemo(
    () => ({ drawerClicked, setDrawerClicked }),
    [drawerClicked, setDrawerClicked],
  );
  return (
    <DrawerClickedContext.Provider value={contextValue}>
      {children}
    </DrawerClickedContext.Provider>
  );
};

export const useDrawerClicked = () => {
  const context = useContext(DrawerClickedContext);
  if (context === undefined) {
    throw new Error('useDrawerClicked must be used within a DrawerClickedProvider');
  }
  return context;
};
