export enum ELoginPartTypes {
  HEADER = 'HEADER',
  SUBHEADER = 'SUBHEADER',
  LOGO_1 = 'LOGO_1',
  LOGO_2 = 'LOGO_2',
  LOGO_3 = 'LOGO_3',
}

export interface ILoginScreenItem {
  contentType: string;
  display: boolean;
  file: { fileContent: string; fileName: string; fileType: string } | null;
  text: string | null;
  type: ELoginPartTypes;
  uniqueId: string;
}
