import React, { useEffect } from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { formatDate } from 'utils/date';
import { ADMINISTRATOR_ROLE_ID } from 'pages/RolesPage/helpers';
import { useGlobalProperty } from 'models/globalPropertyContext';

interface IRoleTableCard {
  data: ITableDataSource;
}

const RoleTableCard = ({ data }: IRoleTableCard) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  return (
    <Grid
      container
      sx={{
        backgroundColor: `${colors.gray100}`,
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('roles.rolesTable.roleName.column')}
        </Typography>
        <Chips text={emptyFieldHolder(data.name)} variant={EChipsVariants.primary} />
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('roles.rolesTable.created.column')}
        </Typography>
        <Typography variant="body2">{formatDate(data.createdDate, longDateFormat)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('roles.rolesTable.description.column')}
        </Typography>
        <Typography variant="body2">{data.description ? data.description : '---'}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('roles.rolesTable.actions.column')}:{' '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Button
            label={t('roles.rolesTable.view.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.ViewRole.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('roles.rolesTable.edit.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.EditRole.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            disabled={data.id === ADMINISTRATOR_ROLE_ID}
            label={t('roles.rolesTable.delete.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.DeleteRole.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { RoleTableCard };
