import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { Pagination } from 'components/molecules/Pagination';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { Loader } from 'components/atoms/Loader';
import { EPerPages } from 'models/table';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { useForm } from 'react-hook-form';
import { CommentAccordion } from 'components/molecules/CommentAccordion';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { Box } from '@mui/system';

export enum EComponentCommentsLabel {
  showHiddenComments = 'componentDetails.comments.showHiddenComments.button',
  addComment = 'componentDetails.comments.addComment.button',
  title = 'componentDetails.comments.title.column',
  addedBy = 'componentDetails.comments.addedBy.column',
  date = 'componentDetails.comments.date.column',
}
interface IComponentsComments {
  id: string | undefined;
}
const ComponentsComments = ({ id }: IComponentsComments) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { componentsComments, paginatorComponentsComments, getComponentsCommentsData } =
    useAncillaries();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [isLoading, setIsLoading] = useState<boolean | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  useEffect(
    () => () => {
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isShowHidden: false,
    },
  });

  const isShowHidden: boolean = watch('isShowHidden');

  useEffect(() => {
    (async function getDataInit() {
      if (id) {
        if (!componentsComments.length) {
          setIsLoading(true);
        }
        setIsPaginationLoading(true);
        await getComponentsCommentsData(id, {
          withHidden: isShowHidden,
          currentPage,
          perPage,
        });

        setIsLoading(false);
        setIsPaginationLoading(false);
      }
    })();
  }, [id, isShowHidden, currentPage, perPage]);

  return (
    <div>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: { xs: '20px', sm: '16px' } }}
          >
            <Grid item xs={12} sm={6}>
              <FormCheckbox
                name="isShowHidden"
                label={t(EComponentCommentsLabel.showHiddenComments)}
                control={control}
                errors={errors}
                withValidation={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                label={t('legalEntityDetails.commentsTab.addComment.button')}
                variant={EButtonVariants.contained}
                icon={EIconTypes.plus}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.ComponentsAddComment.replace(
                      ':id',
                      id as string,
                    )}?expand=comments`,
                  )
                }
              />
            </Grid>
          </Grid>

          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            componentsComments?.map((item: any, index) => (
              <Box key={item.uniqueId} sx={{ marginBottom: '8px' }}>
                <CommentAccordion
                  visibilityButtonId={`ancillary-comment-visibility-button-${index}`}
                  dropdownIconId={`ancillary-comment-dropdown-${index}`}
                  name="comments"
                  comment={item}
                  deleteLink={ERouteLinks.ComponentsDeleteComment}
                />
              </Box>
            ))
          )}

          {paginatorComponentsComments && (
            <Pagination
              count={paginatorComponentsComments?.totalElements}
              perPage={perPage}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onChangePerPage={(value) => {
                setPerPage(value);
              }}
              current={currentPage}
              isLoading={isPaginationLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export { ComponentsComments };
