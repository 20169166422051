import { useDictionary } from 'models/dictionaryContext';
import { ActionTypes } from 'state/actions/alert';
import { ILegalEntityFormValues } from 'pages/LegalEntitiesPage/LegalEntityForm';
import { useAlert } from 'models/alertContext';
import {
  addAncillariesToGroup,
  assignLE,
  createLegalEntity,
  createLegalEntityAddress,
  createLegalEntityAncillaryGroup,
  createLegalEntityComment,
  createLegalEntityContact,
  createLegalEntityGroup,
  createLEGroupFirearm,
  createOrgLegalEntity,
  deleteAssignedLE,
  deleteLegalEntity,
  deleteLegalEntityAddress,
  deleteLegalEntityAncillariesGroup,
  deleteLegalEntityContact,
  deleteLegalEntityGroup,
  deleteOrgLegalEntity,
  getLegalEntities,
  getLegalEntity,
  getLegalEntityAddresses,
  getLegalEntityAncillaries,
  getLegalEntityAncillaryGroup,
  getLegalEntityAncillaryGroupAncillaries,
  getLegalEntityAncillaryGroupTransactionType,
  getLegalEntityComments,
  getLegalEntityContacts,
  getLegalEntityFirearms,
  getLegalEntityGroup,
  getLegalEntityGroupFirearms,
  getLegalEntityGroups,
  getOrgLegalEntity,
  ISearchParams,
  removeLEGroupAncillary,
  removeLEGroupFirearm,
  searchLocationCoordsByAddress,
  transactionAncillaryGroupTypeValidate,
  updateLegalEntity,
  updateLegalEntityAddress,
  updateLegalEntityAncillaryGroup,
  updateLegalEntityContact,
  updateLegalEntityGroup,
  updateOrgLegalEntity,
} from 'requests/legalEntities';
import { useState } from 'react';
import {
  IAssignLE,
  ILegalEntity,
  ILegalEntityAddress,
  ILegalEntityAncillaries,
  ILegalEntityAncillaryGroup,
  ILegalEntityAncillaryGroupAncillaries,
  ILegalEntityContact,
  ILegalEntityFirearm,
  ILegalEntityGroup,
  ILegalEntityGroupResponse,
  ITransactionType,
} from 'models/legalEntity';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { getOrganizationLegalEntities } from 'requests/organizationLegalEntites';
import { IOrganizationLegalEntity } from 'models/organizationLegalEntity';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { IOrgLegalEntityFormValues } from 'pages/LegalEntitiesPage/OrgLegalEntityForm';
import {
  ISearchFormILEValues,
  ISearchFormValues as ISearchFormOLEValues,
} from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { ILegalEntityAddressFormValues } from 'pages/LegalEntitiesPage/LegalEntityAddressForm';
import { ILegalEntityContactFormValues } from 'pages/LegalEntitiesPage/LegalEntityContactForm';
import { ILegalEntityCommentData } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage';
import {
  IFormElementOptionProp,
  ILegalEntityAncillaryGroupFormValues,
  ILegalEntityEditGroupFormValues,
  ILegalEntityGroupFormValues,
  ISearchFirearmFormValues,
  ISearchGroupFormValues,
  ISearchLegalEntityAncillariesFormValues,
  ISearchLegalEntityAncillaryGroupFormValues,
} from 'models/form';
import { deleteComment } from 'requests/comment';
import { ICommentFormValues } from 'components/organisms/CommentForm';
import { createInitialTransaction } from 'requests/transaction';
import { IInitialTransaction } from 'models/transaction';
import { useTranslations } from 'hooks/useTranslations';
import { ESelectLE } from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { EDictionaryTypes } from 'models/dictionary';
import { ECustomFieldObjectType, ICustomFieldByObjectType } from 'models/customField';
import { getCustomFieldByObjectType } from 'requests/customField';
import { EAlertVariants } from 'components/atoms/Alert';
import { getAncillariesFromGroup, getAncillariesGroupDetail } from 'requests/ancillaries';
import { IAncillary } from 'models/ancillaries';

export const useLegalEntities = () => {
  const { t } = useTranslations();
  const [legalEntities, setLegalEntities] = useState<ILegalEntity[]>([]);
  const [legalEntityFirearms, setLegalEntityFirearms] = useState<ILegalEntityFirearm[]>([]);
  const [legalEntityGroupFirearms, setLegalEntityGroupFirearms] = useState<
    ILegalEntityFirearm[]
  >([]);
  const [legalEntityGroups, setLegalEntityGroups] = useState<ILegalEntityGroup[]>([]);
  const [organizationLegalEntity, setOrganizationLegalEntity] = useState<
    IOrganizationLegalEntity[]
  >([]);
  const [legalEntityAncillaries, setLegalEntityAncillaries] = useState<
    ILegalEntityAncillaries[]
  >([]);
  const [legalEntityAncillaryGroups, setLegalEntityAncillaryGroups] = useState<
    ILegalEntityAncillaryGroup[]
  >([]);
  const [ancillaryGroupDetail, setAncillaryGroupDetail] =
    useState<ILegalEntityAncillaryGroup | null>(null);
  const [legalEntityAncillaryGroupAncillaries, setLegalEntityAncillaryGroupAncillaries] =
    useState<IAncillary[]>([]);
  const [paginatorAncillaryGroupAncillaries, setPaginatorAncillaryGroupAncillaries] =
    useState<IPaginator | null>(null);
  const [paginatorILE, setPaginatorILE] = useState<IPaginator | null>(null);
  const [legalEntityAddress, setLegalEntityAddress] = useState<ILegalEntityAddress[]>([]);
  const [paginatorOLE, setPaginatorOLE] = useState<IPaginator | null>(null);
  const [paginatorLEF, setPaginatorLEF] = useState<IPaginator | null>(null);
  const [paginatorLEG, setPaginatorLEG] = useState<IPaginator | null>(null);
  const [paginatorLEGF, setPaginatorLEGF] = useState<IPaginator | null>(null);
  const [paginatorAncillaries, setPaginatorAncillaries] = useState<IPaginator | null>(null);
  const [paginatorAncillaryGroups, setPaginatorAncillaryGroups] = useState<IPaginator | null>(
    null,
  );
  const [legalEntityContacts, setLegalEntityContacts] = useState<ILegalEntityContact[]>([]);
  const [legalEntityComments, setLegalEntityComments] = useState<ILegalEntityCommentData[]>(
    [],
  );
  const [paginatorLEComments, setPaginatorLEComments] = useState<IPaginator | null>(null);
  const [legalEntityGroup, setLegalEntityGroup] = useState<ILegalEntityGroupResponse | null>(
    null,
  );

  const [keeper, setKeeper] = useState<IFormElementOptionProp>();
  const [owner, setOwner] = useState<IFormElementOptionProp>();

  const [customFieldsILE, setCustomFieldsILE] = useState<ICustomFieldByObjectType[]>([]);
  const [customFieldsOLE, setCustomFieldsOLE] = useState<ICustomFieldByObjectType[]>([]);
  const [customFieldsFirearms, setCustomFieldsFirearms] = useState<ICustomFieldByObjectType[]>(
    [],
  );
  const [customFieldsAncillaries, setCustomFieldsAncillaries] = useState<
    ICustomFieldByObjectType[]
  >([]);
  const [customFieldsLoaded, setCustomFieldsLoaded] = useState(false);
  const [ancillaryGrouptransactionType, setAncillaryGrouptransactionType] = useState<
    ITransactionType[]
  >([]);
  const [ancillariesDetail, setAncillariesDetail] =
    useState<ILegalEntityAncillaryGroupAncillaries | null>(null);
  const [transactionTypeIsValid, setTransactionTypeIsValid] = useState<boolean>(true);

  const query = useQuery();
  const type: string | null = query.get('type');
  const expand: any = query.get('expand');
  const {
    gender,
    getGender,
    organizationType,
    getOrganizationType,
    legalVisibility,
    getLegalVisibility,
    legalEntityPrefix,
    getLegalEntityPrefix,
    civilianPrefix,
    getCivilianPrefix,
    militaryPrefix,
    getMilitaryPrefix,
    policePrefix,
    getPolicePrefix,
    make,
    getMake,
    model,
    getModel,
    state,
    getState,
    caliber,
    getCaliber,
    region,
    getRegion,
    country,
    getCountry,
    manufacturerCountry,
    getManufacturerCountry,
    getPhoneCode,
    addressType,
    getAddressType,
    legality,
    getLegality,
    manufacturer,
    getManufacturer,
    level,
    getLevel,
    firearmAction,
    getFirearmAction,
    firearmType,
    getFirearmType,
    transactionType,
    getTransactionType,
    legalClassification,
    getLegalClassification,
    countryAll,
    getCountryAll,
    artAncillaryOptions,
    getArtAncillaryOptions,
    artComponentType,
    getArtComponentType,
    artAccessoryType,
    getArtAccessoryType,
    artAmmunitionType,
    getArtAmmunitionType,
    artAccessoryManufacturerFlashEliminator,
    getArtAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    getArtAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    getArtAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    getArtAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    getArtAccessoryManufacturerSuppressor,
    artAmmunitionManufacturer,
    getArtAmmunitionManufacturer,
    artComponentManufacturer,
    getArtComponentManufacturer,
  } = useDictionary();
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const { id: leUniqueId = '' } = useParams<{ id: string }>();

  const loadDictionaries = async (types: EDictionaryTypes[]) => {
    if (types.includes(EDictionaryTypes.Gender)) {
      getGender();
    }
    if (types.includes(EDictionaryTypes.OrganizationType)) {
      getOrganizationType();
    }
    if (types.includes(EDictionaryTypes.LegalVisibility)) {
      getLegalVisibility();
    }
    if (types.includes(EDictionaryTypes.legalEntityPrefix)) {
      getLegalEntityPrefix();
    }
    if (types.includes(EDictionaryTypes.civilianPrefix)) {
      getCivilianPrefix();
    }
    if (types.includes(EDictionaryTypes.militaryPrefix)) {
      getMilitaryPrefix();
    }
    if (types.includes(EDictionaryTypes.policePrefix)) {
      getPolicePrefix();
    }
    if (types.includes(EDictionaryTypes.Region)) {
      getRegion();
    }
    if (types.includes(EDictionaryTypes.Country)) {
      getCountry();
    }
    if (types.includes(EDictionaryTypes.ManufacturerCountry)) {
      getManufacturerCountry();
    }
    if (types.includes(EDictionaryTypes.PhoneCode)) {
      getPhoneCode();
    }
    if (types.includes(EDictionaryTypes.AddressType)) {
      getAddressType();
    }
    if (types.includes(EDictionaryTypes.Legality)) {
      getLegality();
    }
    if (types.includes(EDictionaryTypes.Manufacturer)) {
      getManufacturer();
    }
    if (types.includes(EDictionaryTypes.Level)) {
      getLevel();
    }
    if (types.includes(EDictionaryTypes.FirearmAction)) {
      getFirearmAction();
    }
    if (types.includes(EDictionaryTypes.FirearmType)) {
      getFirearmType();
    }
    if (types.includes(EDictionaryTypes.TransactionType)) {
      getTransactionType();
    }
    if (types.includes(EDictionaryTypes.LegalClassification)) {
      getLegalClassification();
    }
    if (types.includes(EDictionaryTypes.Make)) {
      getMake();
    }
    if (types.includes(EDictionaryTypes.Model)) {
      getModel();
    }
    if (types.includes(EDictionaryTypes.State)) {
      getState();
    }
    if (types.includes(EDictionaryTypes.Caliber)) {
      getCaliber();
    }
    if (types.includes(EDictionaryTypes.AddressType)) {
      getAddressType();
    }
    if (types.includes(EDictionaryTypes.CountryAll)) {
      getCountryAll();
    }
    if (types.includes(EDictionaryTypes.ArtAncillaryOptions)) {
      getArtAncillaryOptions();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryType)) {
      getArtAccessoryType();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionType)) {
      getArtAmmunitionType();
    }
    if (types.includes(EDictionaryTypes.ArtComponentType)) {
      getArtComponentType();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerFlashEliminator)) {
      getArtAccessoryManufacturerFlashEliminator();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerLaserLightModule)) {
      getArtAccessoryManufacturerLaserLightModule();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerMagazine)) {
      getArtAccessoryManufacturerMagazine();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerOpticalSight)) {
      getArtAccessoryManufacturerOpticalSight();
    }
    if (types.includes(EDictionaryTypes.ArtAccessoryManufacturerSuppressor)) {
      getArtAccessoryManufacturerSuppressor();
    }
    if (types.includes(EDictionaryTypes.ArtAmmunitionManufacturer)) {
      getArtAmmunitionManufacturer();
    }
    if (types.includes(EDictionaryTypes.ArtComponentManufacturer)) {
      getArtComponentManufacturer();
    }
  };

  const getAncillaryGrouptransactionType = async (groupId: string, legalType: string) => {
    const legalEntityGroupData = await getLegalEntityAncillaryGroupTransactionType(
      groupId,
      legalType,
    );
    if (legalEntityGroupData) {
      setAncillaryGrouptransactionType(legalEntityGroupData);
    } else {
      setAncillaryGrouptransactionType([]);
    }
  };

  const transactionTypeValidate = async (groupId: string, typeUniqeId: string) => {
    const response = await transactionAncillaryGroupTypeValidate(groupId, typeUniqeId);
    if (response && response.length) {
      setTransactionTypeIsValid(false);
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: `${t('addTransaction.transactionType.valid')} ${response.join(', ')}`,
        variant: EAlertVariants.error,
      });
    } else {
      setTransactionTypeIsValid(true);
    }
  };

  const handleCreate = async (data: ILegalEntityFormValues) => {
    try {
      const response = await createLegalEntity(data);

      if (response) {
        setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
          text: t('addLegalEntity.save.success.text'),
        });
      }

      return response;
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
      return false;
    }
  };

  const handleEdit = async (legalEntity: ILegalEntityFormValues) => {
    try {
      const response = await updateLegalEntity(legalEntity);
      if (response) {
        setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
          text: t('editLegalEntity.save.success.info'),
        });
      }
      return response;
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
      return false;
    }
  };

  const handleDelete = async (id: string) => {
    const response = await deleteLegalEntity(id);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('deleteLegalEntity.save.success.text'),
      });
    }
    return response;
  };

  const getLegalEntitiesData = async (
    currentPage: number,
    perPage: EPerPages,
    sort: ISortOptions | null,
    queryILE: Partial<ISearchFormILEValues>,
  ) => {
    const legalEntitiesData = await getLegalEntities(currentPage, perPage, sort, queryILE);
    if (legalEntitiesData) {
      const { content, ...paginatorData } = legalEntitiesData;
      setLegalEntities(content);
      setPaginatorILE(paginatorData);
      return legalEntitiesData.content;
    }
    setLegalEntities([]);
    setPaginatorILE(null);
    return [];
  };

  const getLegalEntityFirearmsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    firearmQuery: Partial<ISearchFirearmFormValues> = {},
    firearmSort?: ISortOptions | null,
  ) => {
    const legalEntityFirearmsData = await getLegalEntityFirearms(
      id,
      currentPage,
      perPage,
      firearmQuery,
      firearmSort,
    );
    if (legalEntityFirearmsData) {
      const { content, ...paginatorData } = legalEntityFirearmsData;
      setLegalEntityFirearms(content);
      setPaginatorLEF(paginatorData);
    } else {
      setLegalEntityFirearms([]);
      setPaginatorLEF(null);
    }
  };

  const getLegalEntityAncillariesData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    ancillariesQuery: Partial<ISearchLegalEntityAncillariesFormValues> = {},
    ancillariesSort?: ISortOptions | null,
  ) => {
    const data = await getLegalEntityAncillaries(
      currentPage,
      perPage,
      { ...ancillariesQuery, legalUniqueId: id },
      ancillariesSort,
    );
    if (data) {
      const { content, ...paginatorData } = data;
      setLegalEntityAncillaries(content);
      setPaginatorAncillaries(paginatorData);
    } else {
      setLegalEntityAncillaries([]);
      setPaginatorAncillaries(null);
    }
  };

  const getLegalEntityAncillaryGroupsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    ancillaryGroupQuery: Partial<ISearchLegalEntityAncillaryGroupFormValues> = {},
    ancillaryGroupSort?: ISortOptions | null,
  ) => {
    const data = await getLegalEntityAncillaryGroup(
      id,
      currentPage,
      perPage,
      { ...ancillaryGroupQuery },
      ancillaryGroupSort,
    );
    if (data) {
      const { content, ...paginatorData } = data;
      setLegalEntityAncillaryGroups(content);
      setPaginatorAncillaryGroups(paginatorData);
    } else {
      setLegalEntityAncillaryGroups([]);
      setPaginatorAncillaryGroups(null);
    }
  };

  const getLegalEntityGroupFirearmsData = async (
    leId: string,
    groupId: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    firearmQuery: Partial<ISearchFirearmFormValues> = {},
    firearmSort?: ISortOptions | null,
  ) => {
    const legalEntityGroupFirearmsData = await getLegalEntityGroupFirearms(
      leId,
      groupId,
      currentPage,
      perPage,
      firearmQuery,
      firearmSort,
    );
    if (legalEntityGroupFirearmsData) {
      const { content, ...paginatorData } = legalEntityGroupFirearmsData;
      setLegalEntityGroupFirearms(content);
      setPaginatorLEGF(paginatorData);
    } else {
      setLegalEntityGroupFirearms([]);
      setPaginatorLEGF(null);
    }
  };

  const getOrganizationLegalEntitiesData = async (
    currentPage: number,
    perPage: EPerPages,
    sort: ISortOptions | null,
    queryOLE: Partial<ISearchFormOLEValues>,
  ) => {
    const organizationLegalEntities = await getOrganizationLegalEntities(
      currentPage,
      perPage,
      sort,
      queryOLE,
    );
    if (organizationLegalEntities) {
      const { content, ...paginatorData } = organizationLegalEntities;
      setOrganizationLegalEntity(content);
      setPaginatorOLE(paginatorData);
      return content;
    }
    setOrganizationLegalEntity([]);
    setPaginatorOLE(null);
    return [];
  };

  const getLegalEntityGroupsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    groupQuery: Partial<ISearchGroupFormValues> = {},
    groupSort?: ISortOptions | null,
  ) => {
    const legalEntityGroupsData = await getLegalEntityGroups(
      id,
      currentPage,
      perPage,
      groupQuery,
      groupSort,
    );
    if (legalEntityGroupsData) {
      const { content, ...paginatorData } = legalEntityGroupsData;
      setLegalEntityGroups(content);
      setPaginatorLEG(paginatorData);
    } else {
      setLegalEntityGroups([]);
      setPaginatorLEG(null);
    }
  };

  const getLegalEntityGroupData = async (id: string, groupId: string) => {
    const legalEntityGroupData = await getLegalEntityGroup(id, groupId);
    if (legalEntityGroupData) {
      setLegalEntityGroup(legalEntityGroupData);
    } else {
      setLegalEntityGroup(null);
    }
  };

  const handleOrgLECreate = async (data: IOrgLegalEntityFormValues) => {
    try {
      const response = await createOrgLegalEntity(data);

      if (response) {
        setAlert(ActionTypes.SET_LEGAL_ENTITY_ORG_ALERT, {
          text: t('addLegalEntityOrg.save.success.text'),
        });
      }

      return response;
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
      return false;
    }
  };

  const handleOrgLEEdit = async (legalEntity: IOrgLegalEntityFormValues) => {
    try {
      const response = await updateOrgLegalEntity(legalEntity);
      if (response) {
        setAlert(ActionTypes.SET_LEGAL_ENTITY_ORG_ALERT, {
          text: t('editLegalEntity.save.success.info'),
        });
      }
      return response;
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
      return false;
    }
  };

  const handleOrgLEDelete = async (id: string) => {
    const response = await deleteOrgLegalEntity(id);
    if (response) {
      setAlert(ActionTypes.SET_LEGAL_ENTITY_ORG_ALERT, {
        text: t('deleteLegalEntity.save.success.text'),
      });
    }
    return response;
  };

  const getLegalEntityCommentsData = async (
    id: string,
    queryParams: {
      withHidden: boolean;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const legalEntityCommentsData = await getLegalEntityComments(id, queryParams);
    if (legalEntityCommentsData) {
      const { content, ...paginatorData } = legalEntityCommentsData;

      setLegalEntityComments(content);
      setPaginatorLEComments(paginatorData);
    } else {
      setLegalEntityComments([]);
      setPaginatorLEComments(null);
    }
  };

  const handleCreateComment = async (data: ICommentFormValues) => {
    const response = await createLegalEntityComment(data);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('addComment.save.success.text'),
      });
    }

    return response;
  };

  const navigateToList = () => {
    navigate(`${ERouteLinks.LegalEntities}?expand=${type}`);
  };

  const navigateToDetails = () => {
    navigate(
      `${ERouteLinks.ViewLegalEntity.replace(
        ':id',
        leUniqueId,
      )}?type=${type}&expand=${expand}`,
    );
  };

  const handleDeleteComment = async (id: string, isHidden: any) => {
    const response = await deleteComment(id, isHidden);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: isHidden
          ? t('enableComment.save.success.text')
          : t('disableComment.save.success.text'),
      });
    }
    return response;
  };

  const getLegalEntityAddressesData = async (
    id: string,
    addressSort?: ISortOptions | null,
  ) => {
    const legalEntityAddressesData = await getLegalEntityAddresses(id, addressSort);
    if (legalEntityAddressesData) {
      setLegalEntityAddress(legalEntityAddressesData);
    } else {
      setLegalEntityAddress([]);
    }
  };

  const handleSearchLocationCoordsByAddress = async (
    searchParams: ISearchParams,
    successCallback: (p: any) => void,
    isOnline: boolean = true,
  ) => searchLocationCoordsByAddress(searchParams, (d) => successCallback(d), isOnline);

  const validateLegalEntityAddress = async (
    id: string,
    data: ILegalEntityAddressFormValues,
  ) => {
    const addresses = await getLegalEntityAddresses(id, null);
    if (addresses) {
      const isValidAddress = !addresses.filter(
        (address) =>
          address.latitude === +data.latitude && address.longitude === +data.longitude,
      ).length;
      if (!isValidAddress) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          variant: EAlertVariants.error,
          text: t('addAddress.save.failure.invalid'),
        });
      }
      return isValidAddress;
    }
    return true;
  };

  const handleCreateAddress = async (data: ILegalEntityAddressFormValues) => {
    const response = await createLegalEntityAddress(data);
    if (response) {
      setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('addAddress.save.success.text'),
      });
    }
    return response;
  };

  const handleEditAddress = async (data: ILegalEntityAddressFormValues, addressId: string) => {
    const response = await updateLegalEntityAddress(data, addressId);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('editAddress.save.success.text'),
      });
    }
    return response;
  };

  const handleDeleteAddress = async (id: string, addressId: string) => {
    const response = await deleteLegalEntityAddress(id, addressId);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('deleteAddress.save.success.text'),
      });
    }
    return response;
  };

  const handleCreateContact = async (data: ILegalEntityContactFormValues) => {
    const response = await createLegalEntityContact(data);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('addContact.save.success.text'),
      });
    }

    return response;
  };

  const getLegalEntityContactsData = async (id: string, contactSort?: ISortOptions | null) => {
    const legalEntityContactsData = await getLegalEntityContacts(id, contactSort);
    if (legalEntityContactsData) {
      setLegalEntityContacts(legalEntityContactsData);
    } else {
      setLegalEntityContacts([]);
    }
  };

  const handleEditContact = async (legalEntityId: string, data: ILegalEntityContact) => {
    const response = await updateLegalEntityContact(legalEntityId, data);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('editContact.save.success.text'),
      });
    }
    return response;
  };

  const handleDeleteContact = async (id: string, contactId: string) => {
    const response = await deleteLegalEntityContact(id, contactId);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('deleteContact.save.success.text'),
      });
    }
    return response;
  };

  const handleCreateGroup = async (data: ILegalEntityGroupFormValues) => {
    const response = await createLegalEntityGroup(data);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('addGroup.save.success.text'),
      });
      navigateToDetails();
    }

    return response;
  };

  const handleCreateAncillaryGroup = async (data: ILegalEntityAncillaryGroupFormValues) => {
    const response = await createLegalEntityAncillaryGroup(data);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('addGroup.save.success.text'),
      });
      navigateToDetails();
    }

    return response;
  };

  const handleEditAncillaryGroup = async (
    lEID: string,
    name: string,
    groupId: string,
    returnLink: string,
  ) => {
    const response = await updateLegalEntityAncillaryGroup(lEID, name, groupId);

    if (response) {
      if (returnLink) {
        navigate(returnLink);
      } else {
        navigateToDetails();
      }
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('editGroup.save.success.text'),
      });
    }

    return response;
  };

  const handleAddAncillaryToGroup = async (
    lEID: string,
    uniqueId: string[],
    groupId: string,
  ) => {
    const response = await addAncillariesToGroup(lEID, uniqueId, groupId);

    if (response) {
      navigateToDetails();
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('editGroup.save.success.text'),
      });
    }

    return response;
  };

  const getAncillaryGroupDetail = async (id: string, groupId: string) => {
    const ancillaryGroupData = await getAncillariesGroupDetail(id, groupId);
    if (ancillaryGroupData) {
      setAncillaryGroupDetail(ancillaryGroupData);
    } else {
      setAncillaryGroupDetail(null);
    }
  };

  const getAncillaryGroupAncillariesDetail = async (groupId: string) => {
    const ancillaryGroupData = await getAncillariesFromGroup(groupId);
    if (ancillaryGroupData) {
      setAncillariesDetail(ancillaryGroupData);
    } else {
      setAncillariesDetail(null);
    }
  };

  const getAncillaryGroupAncillariesSearch = async (
    id: string,
    groupId: string,
    sort: ISortOptions | null,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    ancillaryGroupQuery: Partial<ISearchLegalEntityAncillaryGroupFormValues> = {},
  ) => {
    const data = await getLegalEntityAncillaryGroupAncillaries(
      id,
      groupId,
      currentPage,
      perPage,
      { ...ancillaryGroupQuery },
      sort,
    );
    if (data) {
      const { content, ...paginatorData } = data;
      setLegalEntityAncillaryGroupAncillaries(content);
      setPaginatorAncillaryGroupAncillaries(paginatorData);
    } else {
      setLegalEntityAncillaryGroupAncillaries([]);
      setPaginatorAncillaryGroupAncillaries(null);
    }
  };

  const handleDeleteAncillariesGroup = async (id: string, groupId: string) => {
    const response = await deleteLegalEntityAncillariesGroup(id, groupId);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('deleteGroup.save.success.text'),
      });
    }
    return response;
  };

  const handleEditGroup = async (data: ILegalEntityEditGroupFormValues, groupId: string) => {
    const response = await updateLegalEntityGroup(data, groupId);

    if (response) {
      navigateToDetails();
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('editGroup.save.success.text'),
      });
    }

    return response;
  };

  const handleDeleteGroup = async (id: string, groupId: string) => {
    const response = await deleteLegalEntityGroup(id, groupId);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('deleteGroup.save.success.text'),
      });
    }
    return response;
  };

  const handleAssignLE = async (data: IAssignLE) => {
    const response = await assignLE(data);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: 'Legal entity has been assigned successfully.',
      });
    }

    return response;
  };

  const handleDeleteAssignedLE = async (data: IAssignLE) => {
    const response = await deleteAssignedLE(data);

    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: 'The legal entity has been removed from the user.',
      });
    }

    return response;
  };

  const handleRemoveLEGroupFirearm = async (
    leId: string,
    groupId: string,
    firearmId: string,
  ) => {
    const response = await removeLEGroupFirearm(leId, groupId, firearmId);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('groupDetails.removeFirearm.success.text'),
      });
    }

    return response;
  };

  const handleRemoveLEGroupAncillary = async (
    leId: string,
    groupId: string,
    ancillaryId: string,
  ) => {
    const response = await removeLEGroupAncillary(leId, groupId, ancillaryId);
    if (response) {
      await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: t('groupDetails.removeAncillary.success.text'),
      });
    }

    return response;
  };

  const handleCreateLEGroupFirearm = async (
    leId: string,
    groupId: string,
    firearmIds: string[],
  ) => {
    const response = await createLEGroupFirearm(leId, groupId, firearmIds);
    if (response) {
      navigate(
        `${ERouteLinks.ViewLegalEntityGroup.replace(':id', leId).replace(
          ':groupId',
          groupId,
        )}?type=${type}`,
      );
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('firearmGroup.addFirearm.success.message'),
      });
    }

    return response;
  };

  const handleCreateInitialTransaction = async (body: IInitialTransaction) => {
    await createInitialTransaction(body);
  };

  const getLegalEntityForPreset = async (id: string, LEType: ESelectLE) => {
    const result = await Promise.allSettled([getOrgLegalEntity(id), getLegalEntity(id)]);
    let option: IFormElementOptionProp;

    result.map((promise) => {
      if (promise.status === 'fulfilled') {
        if (promise?.value) {
          option = {
            label:
              ('organizationName' in promise.value ? promise.value.organizationName : '') ||
              ('firstName' in promise.value && 'lastName' in promise.value
                ? `${promise.value.firstName} ${promise.value.lastName}`
                : ''),
            value: id,
          };
          if (LEType === ESelectLE.owner) {
            setOwner(option);
          } else {
            setKeeper(option);
          }
        }
      }
      return false;
    });
  };

  const getCustomField = async (
    objectType: ECustomFieldObjectType,
    filterFunc?: (arg: ICustomFieldByObjectType) => boolean,
  ) => {
    setCustomFieldsLoaded(false);
    const result = await getCustomFieldByObjectType(objectType);
    if (result) {
      if (objectType === ECustomFieldObjectType.LE_INDIVIDUAL) {
        setCustomFieldsILE(filterFunc ? result.content.filter(filterFunc) : result.content);
      } else if (objectType === ECustomFieldObjectType.LE_ORGANIZATION) {
        setCustomFieldsOLE(filterFunc ? result.content.filter(filterFunc) : result.content);
      } else if (objectType === ECustomFieldObjectType.FIREARM) {
        setCustomFieldsFirearms(
          filterFunc ? result.content.filter(filterFunc) : result.content,
        );
      } else if (
        [
          ECustomFieldObjectType.AN_ACCESSORY,
          ECustomFieldObjectType.AN_AMMUNITION,
          ECustomFieldObjectType.AN_COMPONENT,
        ].includes(objectType)
      ) {
        setCustomFieldsAncillaries(
          filterFunc ? result.content.filter(filterFunc) : result.content,
        );
      }
      setCustomFieldsLoaded(true);
    }
  };

  const getCustomFields = async (
    objectTypes: ECustomFieldObjectType[],
    filterFunc?: (arg: ICustomFieldByObjectType) => boolean,
  ) => {
    setCustomFieldsLoaded(false);

    await Promise.all(
      objectTypes.map(async (objectType) => {
        const result = await getCustomFieldByObjectType(objectType);
        if (result) {
          if (objectType === ECustomFieldObjectType.LE_INDIVIDUAL) {
            setCustomFieldsILE(
              filterFunc ? result.content.filter(filterFunc) : result.content,
            );
          } else if (objectType === ECustomFieldObjectType.LE_ORGANIZATION) {
            setCustomFieldsOLE(
              filterFunc ? result.content.filter(filterFunc) : result.content,
            );
          } else if (objectType === ECustomFieldObjectType.FIREARM) {
            setCustomFieldsFirearms(
              filterFunc ? result.content.filter(filterFunc) : result.content,
            );
          } else if (
            [
              ECustomFieldObjectType.AN_ACCESSORY,
              ECustomFieldObjectType.AN_AMMUNITION,
              ECustomFieldObjectType.AN_COMPONENT,
            ].includes(objectType)
          ) {
            setCustomFieldsAncillaries(
              filterFunc ? result.content.filter(filterFunc) : result.content,
            );
          }
        }
      }),
    );

    setCustomFieldsLoaded(true);
  };

  return {
    loadDictionaries,
    gender,
    organizationType,
    legalVisibility,
    legalEntityPrefix,
    civilianPrefix,
    militaryPrefix,
    policePrefix,
    make,
    model,
    state,
    caliber,
    artAncillaryOptions,
    artComponentType,
    artAccessoryType,
    artAmmunitionType,
    paginatorILE,
    paginatorOLE,
    paginatorLEF,
    paginatorLEG,
    paginatorLEGF,
    handleCreate,
    getLegalEntitiesData,
    legalEntities,
    getLegalEntityFirearmsData,
    legalEntityFirearms,
    getLegalEntityGroupsData,
    legalEntityGroups,
    legalEntityGroupFirearms,
    getLegalEntityGroupFirearmsData,
    handleEdit,
    handleDelete,
    getOrganizationLegalEntitiesData,
    organizationLegalEntity,
    navigateToList,
    handleOrgLECreate,
    handleOrgLEEdit,
    handleOrgLEDelete,
    handleCreateComment,
    navigateToDetails,
    handleDeleteComment,
    getLegalEntityAddressesData,
    legalEntityAddress,
    region,
    country,
    handleCreateAddress,
    handleSearchLocationCoordsByAddress,
    handleEditAddress,
    handleDeleteAddress,
    getLegalEntityContactsData,
    legalEntityContacts,
    handleCreateContact,
    handleEditContact,
    handleDeleteContact,
    addressType,
    legalEntityComments,
    paginatorLEComments,
    getLegalEntityCommentsData,
    getLegalEntityGroupData,
    legalEntityGroup,
    handleCreateGroup,
    handleEditGroup,
    handleDeleteGroup,
    handleAssignLE,
    handleDeleteAssignedLE,
    legality,
    manufacturer,
    handleRemoveLEGroupFirearm,
    handleCreateLEGroupFirearm,
    firearmAction,
    firearmType,
    level,
    manufacturerCountry,
    transactionType,
    legalClassification,
    handleCreateInitialTransaction,
    getLegalEntityForPreset,
    owner,
    keeper,
    countryAll,
    getCustomField,
    getCustomFields,
    customFieldsILE,
    customFieldsOLE,
    customFieldsFirearms,
    customFieldsAncillaries,
    validateLegalEntityAddress,
    customFieldsLoaded,
    getLegalEntityAncillariesData,
    legalEntityAncillaries,
    legalEntityAncillaryGroups,
    paginatorAncillaries,
    paginatorAncillaryGroups,
    getLegalEntityAncillaryGroupsData,
    handleCreateAncillaryGroup,
    handleEditAncillaryGroup,
    handleAddAncillaryToGroup,
    getAncillaryGroupDetail,
    ancillaryGroupDetail,
    handleDeleteAncillariesGroup,
    getAncillaryGroupAncillariesSearch,
    legalEntityAncillaryGroupAncillaries,
    paginatorAncillaryGroupAncillaries,
    handleRemoveLEGroupAncillary,
    getAncillaryGrouptransactionType,
    ancillaryGrouptransactionType,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    artAmmunitionManufacturer,
    artComponentManufacturer,
    getAncillaryGroupAncillariesDetail,
    ancillariesDetail,
    transactionTypeValidate,
    transactionTypeIsValid,
  };
};
