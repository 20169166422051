import React, { useEffect } from 'react';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { Box, Typography } from '@mui/material';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import FormCustomField from 'components/organisms/FormCustomField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { useLocation, useNavigate } from 'react-router-dom';
import { IAccessoryDetails, IAmmunitionDetails, IComponentDetails } from 'models/ancillaries';
import { useAncillaryCustomFieldFormSchema } from 'pages/AncillariesPage/EditAncillaryPage/AncillaryCustomFieldsForm/schemaValidation';

interface IAncillaryCustomFieldsForm {
  customFields: ICustomFieldByObjectType[];
  ancillary: IAccessoryDetails | IAmmunitionDetails | IComponentDetails | undefined;
  onSubmit: (data: { [key: string]: any }) => void;
  isSubmitLoading: boolean;
}

/**
 *
 * @param customFields
 * @param firearm
 */
const getInitialFormValues = ({
  customFields,
  ancillary,
}: {
  customFields: ICustomFieldByObjectType[];
  ancillary: IAccessoryDetails | IAmmunitionDetails | IComponentDetails | undefined;
}) => {
  const initialFormValues: { [key: string]: any } = {};
  if (ancillary) {
    customFields.map((customField) => {
      const customFieldValue = ancillary?.customFields?.find(
        (cField) => cField.customFieldUniqueId === customField.uniqueId,
      );

      if (customFieldValue) {
        if (customFieldValue.customFieldType === ECustomFieldType.BOOL) {
          initialFormValues[customField.name] = customFieldValue.value ? '1' : '0';
        } else {
          initialFormValues[customField.name] = customFieldValue.value;
        }
      } else {
        initialFormValues[customField.name] = '';
      }

      return false;
    });
  }

  return initialFormValues;
};

/**
 *
 * @param customFields
 * @param ancillary
 * @param onSubmit
 * @param isSubmitLoading
 * @constructor
 */
const AncillaryCustomFieldsForm = ({
  customFields,
  ancillary,
  onSubmit,
  isSubmitLoading,
}: IAncillaryCustomFieldsForm) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { schema } = useAncillaryCustomFieldFormSchema(customFields);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: getInitialFormValues({ customFields, ancillary }),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(getInitialFormValues({ customFields, ancillary }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields, ancillary]);

  const handleCancel = () => {
    if (routerState?.prevPage) {
      navigate(routerState.prevPage);
    } else {
      navigate(-1);
    }
  };

  if (!ancillary) {
    return null;
  }

  return (
    <Box>
      <StyledPageTitle variant="h4" sx={{ margin: '20px 0' }}>
        {t('customFields.header')}
      </StyledPageTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} container columnSpacing={3}>
          {customFields.map((customField) => (
            <Grid item xs={12} lg={3} key={customField.uniqueId}>
              <FormCustomField
                data={customField}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Grid>
          ))}

          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                variant={EButtonVariants.outlined}
                onClick={handleCancel}
              />
            </Grid>
          </Grid>
          <Grid item mt={2}>
            <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AncillaryCustomFieldsForm;
