import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { ILicenceBasicInfo, ILicenceData } from 'models/licence';

const getLicenceDetail = async () => {
  const { data } = await apiClient.get<TResponse<ILicenceData>>(
    ApiEndpoints.GET_LICENCE_DETAILS(),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const redeemLicenceKey = async (key: any) => {
  const { data } = await apiClient.post(
    ApiEndpoints.REDEEM_LICENCE_KEY(),
    { key },
    {
      timeout: 30000,
    },
  );

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getLicenceBasicInfo = async () => {
  const { data } = await apiClient.get<TResponse<ILicenceBasicInfo>>(
    ApiEndpoints.GET_LICENCE_BASIC_INFO(),
  );

  if (isResponseError(data)) {
    return null;
  }

  if (!data || !Object.prototype.hasOwnProperty.call(data, 'licenceType')) {
    return null;
  }

  return data;
};

export { getLicenceDetail, getLicenceBasicInfo, redeemLicenceKey };
