import { ICommentData } from 'models/comment';
import { IPaginator } from 'models/paginator';
import { IDictionary } from 'models/dictionary';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';
import { ETransactionItemType } from 'constants/TransactionItemType';
import { Asset } from 'pages/TransactionsPage/TransactionSearchForm';

export interface IInitialTransaction {
  request: {
    typeUniqueId: string;
    date: string;
    groupName?: string;
    ownerId: string;
    keeperId: string;
    countryOfImportUniqueId?: string;
    countryOfExportUniqueId?: string;
    latitude?: number;
    longitude?: number;
    address?: string;
    firearms: [
      {
        frtId: string;
        specificationId: string;
        serialNumber: string;
        manufacturedAt: string;
      },
    ];
  };
  attachment?: string;
}

export interface ISearchFormValues {
  preset: string | undefined;
  transactionTypeUniqueId: string;
  transactionTimeFrom: string;
  transactionTimeTo: string;
  createTimeFrom: string;
  createTimeTo: string;
  legalityUniqueId: string;
  expirationTimeFrom: string;
  expirationTimeTo: string;
  ownerUniqueId: string;
  keeperUniqueId: string;
  ownerName: string;
  keeperName: string;
  asset: undefined | Asset;
  [key: string]: any;
}

export interface IShortTransaction {
  transactionUniqueId: string;
  typeUniqueId: string;
  transactionTime: string;
}

export interface ILastTransactionUniqueId {
  transactionUniqueId: string | null;
}

export enum ITransactionItemType {
  FIREARM = 'Firearm',
  ANCILLARY = 'Ancillary',
}

export interface ITransaction {
  transactionUniqueId: string;
  date: string;
  type: string;
  typeUniqueId: string;
  state: string;
  stateUniqueId: string;
  serialNumber: string;
  fireCycleId: string | null;
  owner: string;
  keeper: string;
  make: string;
  model: string;
  manufacturer?: string;
  transactionItemType: ITransactionItemType;
  activityUniqueId: string;
  legality: string;
  legalityUniqueId: string;
}

export interface ITransactionResponse extends IPaginator {
  content: ITransaction[];
}

export interface ITransactionDetails {
  uniqueId: string;
  typeUniqueId: string;
  date: string;
  createdDate: string;
  createdBy: string;
  owner: string;
  ownerId: string;
  ownerType: string;
  keeper: string;
  keeperId: string;
  keeperType: string;
  firearmGroupName: string | null;
  ancillaryGroupName: string | null;
  firearmsWithDifferentMakesOrModels: boolean;
  ancillariesWithDifferentART: boolean;
  ancillaryGroupUniqueId: string | null;
  firearmGroupUniqueId: string | null;
  initial: boolean;
  legalityUniqueId: any;
  expirationDate?: Date;
  countryOfExport?: IDictionary;
  countryOfImport?: IDictionary;
  importer?: string;
  activityUniqueId: string;
  latitude?: string;
  longitude?: string;
  address?: string;
  description?: string;
  transactionItemType: ETransactionItemType;
}

export interface ITransactionDetailsFirearm {
  uniqueId: string;
  serialNumber: string;
  fireCycleId: string;
  make: string;
  model: string;
  action: string;
  type: string;
  caliber: string;
  externalReferenceNumber: string;
  frtId: string;
  frtUniqueId: string;
  specificationId: string;
  manufacturedAt: string;
  customFields?: ILegalEntityCustomField[];
}

export interface ITransactionDetailsAncillary {
  uniqueId: string;
  artUniqueId: string;
  artId: string;
  ancillaryType: string;
  fireCycleId: string;
  serialNumber: string;
  ancillaryDictionaryValue: string;
  externalReferenceNumber: string;
  productionDate: string;
  ammunitionType: string;
  accessoryType: string;
  componentType: string;
  manufacturer: string;
  calibre: string;
  lotNumber: string;
  batchNumber: string;
  ancillarySpecificType: string;
}

export interface ITransactionDetailsAncillaryResponse extends IPaginator {
  content: ITransactionDetailsAncillary[];
}

export interface ITransactionDetailsFirearmsResponse extends IPaginator {
  content: ITransactionDetailsFirearm[];
}

export interface ITransactionDetailsCommentsResponse extends IPaginator {
  content: ICommentData[];
}

export interface ITransactionDetailsCommentsQuery {
  withHidden: boolean;
}
