import { EChipsVariants } from 'components/atoms/Chips';
import { LegalityTypes } from 'constants/LegalityTypes';
import { ActivityTypes } from 'constants/ActivityTypes';

export const defineTransactionStateChipVariant = (
  legalityUniqueId: string,
  activityUniqueId: string,
): EChipsVariants => {
  if (activityUniqueId === ActivityTypes.INACTIVE) {
    return EChipsVariants.inactive;
  }
  if (legalityUniqueId === LegalityTypes.ILLEGAL) {
    return EChipsVariants.error;
  }
  return EChipsVariants.primary;
};
