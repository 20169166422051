import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { FormSelect } from 'components/molecules/FormSelect';
import {
  mapDictionaryToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchFirearmFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { initialFirearmSearchFormValues, ISearchFirearmFormValues } from 'models/form';
import { prepareFilters } from 'helpers/filters';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { EFirearmStateId } from 'models/firearm';

interface ISearchFormValues {
  serialNumber: string;
  externalReferenceNo: string;
  make: string;
  stateUniqueId: string;
}

interface IFirearmSearchForm {
  onSubmit: (arg: ISearchFirearmFormValues) => void;
  isSearchButtonDisabled: boolean;
  isIndividualLE?: boolean;
}

const ancillaryStates: string[] = ['707980d8-8abf-11ee-b9d1-0242ac120002'];

export const filterOutAncillaryStates = (
  dictionary: IDictionary[] | null,
): IDictionary[] | null => {
  const filteredList = dictionary?.filter(
    (value) => !ancillaryStates.includes(value.uniqueId.toString()),
  );
  return filteredList ?? null;
};

const FirearmSearchForm = ({
  onSubmit,
  isSearchButtonDisabled = false,
  isIndividualLE = false,
}: IFirearmSearchForm) => {
  const { make, state, loadDictionaries } = useLegalEntities();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialFirearmSearchFormValues,
  });
  const [values, setValues] = useState<ISearchFirearmFormValues>(
    initialFirearmSearchFormValues,
  );
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFirearmFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialFirearmSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.Make, EDictionaryTypes.State]);
    })();
  }, [selectedLanguage]);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" spacing={2} columns={10}>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormInput
              name="serialNumber"
              label={t(ESearchFirearmFormLabelsTypes.serialNumber)}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormInput
              name="externalReferenceNo"
              label={t(ESearchFirearmFormLabelsTypes.externalReferenceNo)}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              name="make"
              options={mapSpecificDictionaryToOptionProp(make)}
              label={t(ESearchFirearmFormLabelsTypes.make)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(
                filterOutAncillaryStates(state),
                undefined,
                isIndividualLE
                  ? [
                      EFirearmStateId.EXPORTED,
                      EFirearmStateId.RECOVERED,
                      EFirearmStateId.SEIZED,
                    ]
                  : [EFirearmStateId.EXPORTED],
              )}
              name="stateUniqueId"
              canBeEmpty={true}
              setValue={setValue}
              label={t(ESearchFirearmFormLabelsTypes.stateUniqueId)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t('legalEntities.individual.accordion.search.button')}
              disabled={isSearchButtonDisabled}
              type="submit"
              fullWidth={true}
              sx={{ mt: 3.5 }}
            />
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="firearms"
        values={values}
        labels={ESearchFirearmFormLabelsTypes}
        handleDelete={handleResetField}
        saveQueryParams={['type']}
        noPreset
        dictionaries={{
          stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
        }}
        translationKeys={{
          selectedCriteria: 'searchForLegalEntitysFirearm.selectedCriteria.label',
          clearAll: 'searchForLegalEntitiesFirearm.clearAll.link',
        }}
        showSaveFilters={false}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { FirearmSearchForm };
