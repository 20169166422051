import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { IFirearmCommentData } from 'models/registeredFirearm';
import { useFirearms } from 'pages/Firearms/hooks';
import { getFirearmComment } from 'requests/firearm';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Box } from '@mui/system';

const RegisteredFirearmDeleteCommentPage = () => {
  const { id, commentId } = useParams<{ id: string; commentId: string }>();
  const [firearmComment, setFirearmComment] = useState<IFirearmCommentData | null>();
  const { t } = useTranslations();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  const { handleDeleteComment, navigateToDetails } = useFirearms();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  useEffect(() => {
    (async function getFirearmCommentDataInit() {
      if (id && commentId) {
        const response = await getFirearmComment(id, commentId);
        if (response) {
          setFirearmComment(response);
        }
      }
    })();
  }, [id, commentId]);

  useEffect(() => {
    getDateFormat();
  }, []);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (firearmComment) {
        const result = await handleDeleteComment(
          firearmComment.uniqueId,
          firearmComment.hidden,
        );

        if (result.status === 204) {
          navigateToDetails();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  let firearmActionType: string = '';
  if (firearmComment) {
    firearmActionType = firearmComment.hidden
      ? t('enableComment.header')
      : t('disableComment.header');
  }

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.accordion.name'),
            route: ERouteLinks.Firearms,
          },
          {
            label: t('registeredFirearms.firearmsDetails.header'),
            route: `${ERouteLinks.ViewRegisteredFirearm.replace(
              ':id',
              id as string,
            )}?expand=comments`,
          },
          firearmActionType || '',
        ]}
      />
      {firearmComment && (
        <>
          <StyledPageTitle variant="h4">{firearmActionType}</StyledPageTitle>

          <Box my={3}>
            <Alert
              text={
                firearmComment.hidden
                  ? t('enableComment.info.text')
                  : t('disableComment.info.text')
              }
              variant={EAlertVariants.warning}
            />
          </Box>

          <StyledDetailsWrapper container direction="column" gap={3}>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('addComment.title.label')}
              </Typography>
              <Typography variant="body2">{firearmComment.title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('disableComment.comment.label')}
              </Typography>
              <Typography variant="body2">{firearmComment.comment}</Typography>
            </Grid>
            <Grid container rowGap={3}>
              <Grid container item xs={12} lg={6} rowGap={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('disableComment.addedBy.label')}
                  </Typography>
                  <Typography variant="body2">
                    {`${firearmComment.createdByFirstName} ${firearmComment.createdByLastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('disableComment.date.label')}
                  </Typography>
                  <Typography variant="body2">
                    {formatDate(firearmComment.createdDate, longDateFormat)}
                  </Typography>
                </Grid>
              </Grid>
              {firearmComment.hidden && (
                <Grid container item xs={12} lg={6} rowGap={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('enableComment.disabledBy.text')}
                    </Typography>
                    <Typography variant="body2">{`${firearmComment.hiddenByFirstName} ${firearmComment.hiddenByLastName}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('enableComment.date.label')}
                    </Typography>
                    <Typography variant="body2">
                      {formatDate(firearmComment.hiddenAt, longDateFormat)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.yes.button')}
                id="yes-button"
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.no.button')}
                id="no-button"
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { RegisteredFirearmDeleteCommentPage };
