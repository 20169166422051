import { useLang } from 'models/langContext';
import { useState } from 'react';

export const useTranslations = () => {
  const { translations, showKeyTranslations } = useLang();

  const [loadingT, setLoadingT] = useState(!translations || translations.length < 0);

  const t = (key: string) => {
    if (translations.length === 0) {
      return '';
    }
    const translation = translations.find((trans) => trans.key === key);
    if (translation) {
      if (showKeyTranslations) {
        return translation.key;
      }
      return translation.value;
    }
    return key;
  };
  return { t, loadingT, setLoadingT };
};
