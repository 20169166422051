import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFirearms } from 'pages/Firearms/hooks';
import { FirearmsLocationMap } from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmsLocationMap';
import { useLicenceContext } from 'models/licenceContext';
import { Loader } from 'components/atoms/Loader';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

const RegisteredFirearmsMapPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { getFirearmsDataForMap, firearms } = useFirearms();
  const { isOnlineLicence } = useLicenceContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function getFirearmsDataInit() {
      if (routerState?.queryRegisteredFirearms) {
        setIsLoading(true);
        await getFirearmsDataForMap(routerState.queryRegisteredFirearms);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState?.queryRegisteredFirearms]);

  useEffect(() => {
    (async function getFirearmsDataInit() {
      if (routerState?.firearmUniqueId) {
        setIsLoading(true);
        await getFirearmsDataForMap({
          uniqueIds: [routerState.firearmUniqueId],
        });
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState?.firearmUniqueId]);

  useEffect(() => {
    (async function getFirearmsDataInit() {
      if (routerState?.transactionFirearmsUniqueIds) {
        setIsLoading(true);
        await getFirearmsDataForMap({
          uniqueIds: routerState.transactionFirearmsUniqueIds,
        });
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState?.transactionFirearmsUniqueIds]);

  const updateLegality = async (value: string) => {
    if (routerState?.queryRegisteredFirearms) {
      routerState.queryRegisteredFirearms.legalityUniqueId = value;
      await getFirearmsDataForMap(routerState.queryRegisteredFirearms);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={
          routerState.breadcrumbs || [
            { label: t('module.firearm.name'), route: ERouteLinks.Firearms },
            t('registeredFirearms.firearmsLocation.showOnMap.label'),
          ]
        }
      />
      <StyledPageTitle variant="h4">
        {routerState.latitude && routerState.longitude
          ? t('registeredFirearms.transactionLocation.header')
          : t('registeredFirearms.firearmsLocation.header')}
      </StyledPageTitle>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Grid item>
          <Button
            fullWidth
            label={t('registeredFirearms.firearmsLocation.back.button')}
            variant={EButtonVariants.outlined}
            size={EButtonSizes.small}
            icon={EIconTypes.left}
            onClick={
              !routerState.navigateBack
                ? () => {
                    navigate(`${ERouteLinks.Firearms}?expand=registered-firearms`, {
                      state: {
                        showAdvanced: routerState?.showAdvanced,
                        formValues: {
                          ...routerState?.queryRegisteredFirearms,
                          legalityUniqueId: routerState?.legalityUniqueId,
                        },
                        legalityUniqueId: routerState?.legalityUniqueId,
                        options: {
                          ownerOptions: routerState?.options.ownerOptions,
                          keeperOptions: routerState?.options.keeperOptions,
                        },
                      },
                    });
                  }
                : () => navigate(routerState.navigateBack)
            }
          />
        </Grid>
      </Grid>
      {isLoading && !firearms.length && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <div>
          {firearms && firearms.length ? (
            <FirearmsLocationMap
              firearms={firearms}
              latitude={routerState?.latitude}
              longitude={routerState?.longitude}
              legalityUniqueId={routerState?.legalityUniqueId}
              showLegalityBar={routerState?.showLegalityBar}
              updateLegality={updateLegality}
              defaultLegality={routerState?.queryRegisteredFirearms?.legalityUniqueId}
              isOnline={isOnlineLicence()}
            />
          ) : (
            <Grid item mt={2} xs={12}>
              <Alert
                text={t('registeredFirearms.firearmsLocation.noFirearms.text')}
                variant={EAlertVariants.warning}
              />
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

export { RegisteredFirearmsMapPage };
