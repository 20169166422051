import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { Theme } from '@mui/material';

export const StyledHeading = styled.div`
  background-color: ${colors.primary900};
  padding: 10px 20px;
  margin: 40px 0 24px;
  border-radius: 4px;

  & p {
    color: ${colors.white};
    font-weight: bold;
  }

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('lg')} {
      margin: 16px 0 16px;
      };
      `};
`;
