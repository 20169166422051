import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { colors } from 'theme/colors';

export const StyledErrorPageStatus = styled(Typography)`
  font-size: 5rem;
  color: ${colors.primary500};
  margin-bottom: 35px;
`;

export const StyledErrorPageTitle = styled(Typography)`
  font-size: 1.75rem;
  font-weight: 400;
  text-transform: uppercase;
  color: ${colors.primary500};
  margin-bottom: 35px;
`;

export const StyledErrorPageSubtitle = styled(Typography)`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.gray900};
  margin-bottom: 35px;
`;

export const StyledErrorPageWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: row;
`;

interface IStyledLogoWrapper {
  isSmallLogo: boolean;
}

export const StyledLogoWrapper = styled(Box)<IStyledLogoWrapper>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${({ isSmallLogo }: IStyledLogoWrapper) => css`
    align-items: ${isSmallLogo ? 'flex-start' : 'flex-end'};
    margin-right: ${isSmallLogo ? '50px' : '100px'};
    margin-left: ${isSmallLogo ? '0' : '50px'};
  `}}
`;

interface IStyledErrorLabelsWrapper {
  isSmallLogo: boolean;
  isMobile: boolean;
}

export const StyledErrorLabelsWrapper = styled(Box)`
  flex: 1;
  ${({ isSmallLogo, isMobile }: IStyledErrorLabelsWrapper) => {
    let marginLeft = '0';
    if (isSmallLogo) {
      marginLeft = '50px';
    } else if (!isMobile) {
      marginLeft = '100px';
    }
    return css`
      margin-left: ${marginLeft};
      max-width: ${isMobile ? 'auto' : '450px'};
      text-align: ${isMobile ? 'center' : 'start'};
    `;
  }}
`;
