import React, { useEffect, useState } from 'react';
import SearchFilterStore from 'state/SearchFilterStore';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { FormInput } from 'components/molecules/FormInput';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import { useSavePreset } from 'pages/Firearms/SaveFirearmsPresetPage/hooks';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import FormWrapper from 'components/atoms/FormWrapper';
import {
  StyledFilterChipWrapper,
  StyledSelectedFiltersWrapper,
} from 'pages/MyFiltersPage/styles';
import { FilterChip } from 'components/atoms/FilterChip';
import { objectToKeyValueArray } from 'helpers/objects';
import { parseDictionary } from 'helpers/dictionary';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useFirearms } from 'pages/Firearms/hooks';
import {
  excludeOwnerAndKeeperIds,
  mapValuesToFirearmPresetRequest,
  mapValuesWithDictionariesHelper,
  permittedFirearmFilterFields,
} from 'pages/Firearms/SaveFirearmsPresetPage/helpers';
import usePresetFormSchema from 'pages/Firearms/SaveFirearmsPresetPage/schema';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { useLicenceContext } from 'models/licenceContext';
import { ESearchFormFirearmLabels } from '../RegisteredFirearmsSearchForm';

export interface IPresetFormValues {
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
}

const initialFormValues = {
  name: '',
  emailNotificationEnabled: false,
  notificationEnabled: false,
};

const SaveFirearmsPresetPage = () => {
  const { t } = useTranslations();
  const { schema } = usePresetFormSchema();
  const { selectedLanguage } = useLang();

  const {
    loadDictionariesForRegisteredFirearms,
    make,
    model,
    state,
    caliber,
    legality,
    manufacturer,
    country,
    firearmAction,
    firearmType,
  } = useFirearms();

  const [filtersArray, setFiltersArray] = useState<{ key: string; value: string }[]>([]);
  const { type } = useParams<{ type: ESearchFilterKeys }>();
  const [error, setError] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { savePreset } = useSavePreset();
  const { setAlert } = useAlert();
  const { isOnlineLicence } = useLicenceContext();
  const { state: routerState }: any = useLocation();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IPresetFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionariesForRegisteredFirearms([
        EDictionaryTypes.Make,
        EDictionaryTypes.Model,
        EDictionaryTypes.State,
        EDictionaryTypes.Caliber,
        EDictionaryTypes.Legality,
        EDictionaryTypes.Manufacturer,
        EDictionaryTypes.Country,
        EDictionaryTypes.FirearmAction,
        EDictionaryTypes.FirearmType,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const filters = SearchFilterStore.getValue(type as ESearchFilterKeys);
  const dictionaries = SearchFilterStore.getDictionaries(type as ESearchFilterKeys);

  const filtersWithDictionaries = () => {
    if (type === ESearchFilterKeys.firearm) {
      const preparedFilters = mapValuesWithDictionariesHelper(
        objectToKeyValueArray(filters).filter((record) =>
          permittedFirearmFilterFields.includes(record.key),
        ),
        {
          stateUniqueId: parseDictionary(state, 'name'),
          legalityUniqueId: parseDictionary(legality, 'name'),
          make,
          model,
          manufacturer,
          caliber,
          country: parseDictionary(country, 'name'),
          firearmAction,
          firearmType,
          ownerId: dictionaries?.ownerId,
          keeperId: dictionaries?.keeperId,
        },
      );
      setFiltersArray(excludeOwnerAndKeeperIds(preparedFilters));
    }
    return [];
  };

  useEffect(() => {
    if (filters) {
      filtersWithDictionaries();
    }
  }, [state, legality, make, model, manufacturer, caliber, country, firearmAction, type]);

  const getBackRoute = () => navigate(routerState.cancelPath || ERouteLinks.Firearms);

  const labels = ESearchFormFirearmLabels;

  useEffect(() => {
    if (filters === null) {
      getBackRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSubmitForm = async (data: IPresetFormValues) => {
    if (type) {
      setIsSubmitLoading(true);
      const body = mapValuesToFirearmPresetRequest(filters);
      if (body) {
        const response: any = await savePreset({
          name: data.name,
          emailNotificationEnabled: data.emailNotificationEnabled,
          notificationEnabled: data.notificationEnabled,
          type,
          body,
        });

        if (response?.status === 200) {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: `${t('saveMyFilter.successConfirmation.info')}`,
            variant: EAlertVariants.success,
          });
          navigate(`${ERouteLinks.MyFilters}`);
        } else {
          setError(response.response.data.message);
        }
      }
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.breadcrumbs.firearms'),
            route: ERouteLinks.Firearms,
          },
          t('firearms.registeredFirearms.breadcrumbs.saveMyFilter'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('saveMyFilter.header')}</StyledPageTitle>

      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box mb={1}>
                <FormInput
                  name="name"
                  label={`${t('firearms.registeredFirearms.filters.filterName.label')}*`}
                  placeholder={t('firearms.registeredFirearms.filters.filterName.placeholder')}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <StyledSelectedFiltersWrapper container rowGap={1}>
                <Grid item>
                  <Typography variant="body2">{`${t(
                    'registeredFirearms.accordion.selectedCriteria.label',
                  )}*`}</Typography>
                </Grid>
                <StyledFilterChipWrapper item>
                  {filtersArray &&
                    filtersArray.map((item: { key: string; value: string }) => (
                      <FilterChip
                        key={item.key}
                        name={item.key}
                        label={t(labels?.[item.key as keyof typeof labels]) || item.key}
                        values={item.value}
                      />
                    ))}
                </StyledFilterChipWrapper>
              </StyledSelectedFiltersWrapper>
            </Grid>

            <Grid item xs={12} container columnSpacing={3}>
              <Grid item xs={12}>
                <FormCheckbox
                  name="notificationEnabled"
                  label={t('saveMyFilter.notifyMeViaSystem.checkbox.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>
              {isOnlineLicence() && (
                <Grid item xs={12}>
                  <FormCheckbox
                    name="emailNotificationEnabled"
                    label={t('saveMyFilter.notifyMeViaEmail.checkbox.label')}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('saveMyFilter.save.button')}
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  label={t('saveMyFilter.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={() =>
                    navigate(routerState.cancelPath || ERouteLinks.Firearms, {
                      state: { uniqueIds: routerState?.additionalSaveFilterValues.uniqueIds },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item mt={2} xs={12}>
              <Typography variant="caption">
                *{t('saveMyFilter.mandatoryField.text')}
              </Typography>
            </Grid>
            {error && (
              <Grid item xs={12} mt={4}>
                <Alert text={error} variant={EAlertVariants.error} />
              </Grid>
            )}
          </Grid>
        </form>
      </FormWrapper>
    </>
  );
};

export { SaveFirearmsPresetPage };
