import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Box } from '@mui/material';
import { colors } from 'theme/colors';

interface ILoader {
  isLoading: boolean;
}
const Loader = ({ isLoading }: ILoader) => (
  <Box display="flex" justifyContent="center" my={4}>
    <PropagateLoader loading={isLoading} color={colors.primary500} />
  </Box>
);

export { Loader };
