import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import {
  ILegalEntityAddressFormValues,
  LegalEntityAddressForm,
} from 'pages/LegalEntitiesPage/LegalEntityAddressForm';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';

const LegalEntityAddAddressPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { handleCreateAddress, navigateToDetails, validateLegalEntityAddress } =
    useLegalEntities();
  const { t } = useTranslations();

  const handleSubmit = async (data: ILegalEntityAddressFormValues) => {
    const isValid = await validateLegalEntityAddress(id, data);
    if (isValid) {
      const result = await handleCreateAddress(data);
      if (result) {
        navigateToDetails();
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=address`,
          },
          t('addAddress.header'),
        ]}
      />
      <FormWrapper>
        <LegalEntityAddressForm
          onCancel={navigateToDetails}
          onSubmit={handleSubmit}
          formTitle={t('addAddress.header')}
        />
      </FormWrapper>
    </>
  );
};

export { LegalEntityAddAddressPage };
