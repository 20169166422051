import { createContext, useContext } from 'react';
import { ETransactionDictionaryItemTypes, IDictionary } from 'models/dictionary';

export interface IDictionaryContext {
  gender: IDictionary[] | null;
  getGender: () => void;
  organizationType: IDictionary[] | null;
  getOrganizationType: () => void;
  legalVisibility: IDictionary[] | null;
  getLegalVisibility: () => void;
  legalEntityPrefix: IDictionary[] | null;
  getLegalEntityPrefix: () => void;
  civilianPrefix: IDictionary[] | null;
  getCivilianPrefix: () => void;
  militaryPrefix: IDictionary[] | null;
  getMilitaryPrefix: () => void;
  policePrefix: IDictionary[] | null;
  getPolicePrefix: () => void;
  make: string[] | null;
  getMake: () => void;
  model: string[] | null;
  getModel: () => void;
  caliber: string[] | null;
  getCaliber: () => void;
  barrelType: IDictionary[] | null;
  getBarrelType: () => void;
  state: IDictionary[] | null;
  getState: () => void;
  region: IDictionary[] | null;
  getRegion: () => void;
  country: IDictionary[] | null;
  getCountry: () => void;
  phoneCode: IDictionary[] | null;
  getPhoneCode: () => void;
  addressType: IDictionary[] | null;
  getAddressType: () => void;
  legality: IDictionary[] | null;
  getLegality: () => void;
  manufacturer: string[] | null;
  getManufacturer: () => void;
  level: string[] | null;
  getLevel: () => void;
  firearmAction: string[] | null;
  getFirearmAction: () => void;
  firearmType: string[] | null;
  getFirearmType: () => void;
  transactionType: IDictionary[] | null;
  getTransactionType: () => void;
  manufacturerCountry: string[] | null;
  getManufacturerCountry: () => void;
  legalClassification: string[] | null;
  getLegalClassification: () => void;
  transactionInitialTypes: IDictionary[] | null;
  getTransactionInitialTypes: (itemType: ETransactionDictionaryItemTypes) => void;
  transactionMiddleTypes: IDictionary[] | null;
  getTransactionMiddleTypes: (itemType: ETransactionDictionaryItemTypes) => void;
  activity: IDictionary[] | null;
  getActivity: () => void;
  countryAll: IDictionary[] | null;
  getCountryAll: () => void;

  artAncillaryOptions: IDictionary[] | null;
  artAccessoryCalibre: IDictionary[] | null;
  artAccessoryComposition: IDictionary[] | null;
  artAccessoryHostType: IDictionary[] | null;
  artAccessoryLightColour: IDictionary[] | null;
  artAccessoryMagnification: IDictionary[] | null;
  artAccessoryManufacturerFlashEliminator: IDictionary[] | null;
  artAccessoryManufacturerLaserLightModule: IDictionary[] | null;
  artAccessoryManufacturerMagazine: IDictionary[] | null;
  artAccessoryManufacturerOpticalSight: IDictionary[] | null;
  artAccessoryManufacturerSuppressor: IDictionary[] | null;
  artAccessoryReticle: IDictionary[] | null;
  artAccessoryType: IDictionary[] | null;
  artAccessoryUtility: IDictionary[] | null;
  artAccessoryProofHouse: IDictionary[] | null;
  getArtAncillaryOptions: () => void;
  getArtAccessoryCalibre: () => void;
  getArtAccessoryComposition: () => void;
  getArtAccessoryHostType: () => void;
  getArtAccessoryLightColour: () => void;
  getArtAccessoryMagnification: () => void;
  getArtAccessoryManufacturerFlashEliminator: () => void;
  getArtAccessoryManufacturerLaserLightModule: () => void;
  getArtAccessoryManufacturerMagazine: () => void;
  getArtAccessoryManufacturerOpticalSight: () => void;
  getArtAccessoryManufacturerSuppressor: () => void;
  getArtAccessoryReticle: () => void;
  getArtAccessoryType: () => void;
  getArtAccessoryUtility: () => void;
  getArtAccessoryProofHouse: () => void;

  artAmmunitionType: IDictionary[] | null;
  artAmmunitionManufacturer: IDictionary[] | null;
  artAmmunitionFactory: IDictionary[] | null;
  artAmmunitionCalibreGauge: IDictionary[] | null;
  artAmmunitionCartridgeComposition: IDictionary[] | null;
  artAmmunitionColourOfPrimerSealant: IDictionary[] | null;
  artAmmunitionProjectileShape: IDictionary[] | null;
  artAmmunitionFunctionalType: IDictionary[] | null;
  artAmmunitionShotSize: IDictionary[] | null;
  artAmmunitionMunitionType: IDictionary[] | null;
  artAmmunitionMunitionVelocity: IDictionary[] | null;
  getArtAmmunitionType: () => void;
  getArtAmmunitionManufacturer: () => void;
  getArtAmmunitionFactory: () => void;
  getArtAmmunitionCalibreGauge: () => void;
  getArtAmmunitionCartridgeComposition: () => void;
  getArtAmmunitionColourOfPrimerSealant: () => void;
  getArtAmmunitionProjectileShape: () => void;
  getArtAmmunitionFunctionalType: () => void;
  getArtAmmunitionShotSize: () => void;
  getArtAmmunitionMunitionType: () => void;
  getArtAmmunitionMunitionVelocity: () => void;

  artComponentType: IDictionary[] | null;
  artComponentManufacturer: IDictionary[] | null;
  artComponentCalibre: IDictionary[] | null;
  artComponentCapacity: IDictionary[] | null;
  artComposition: IDictionary[] | null;
  artComponentProofHouse: IDictionary[] | null;
  getArtComponentType: () => void;
  getArtComponentManufacturer: () => void;
  getArtComponentCalibre: () => void;
  getArtComponentCapacity: () => void;
  getArtComposition: () => void;
  getArtComponentProofHouse: () => void;

  auditLogModule: IDictionary[] | null;
  auditLogEventType: IDictionary[] | null;
  auditLogEventName: IDictionary[] | null;
  getAuditLogModule: () => void;
  getAuditLogEventType: () => void;
  getAuditLogEventName: () => void;

  apiKeyType: IDictionary[] | null;
  getApiKeyType: () => void;
}

export const DictionaryContext = createContext<IDictionaryContext>({} as IDictionaryContext);

export const useDictionary = (): IDictionaryContext => useContext(DictionaryContext);
