export const DictionaryActionTypes = {
  SET_GENDER: 'SET_GENDER',
  SET_ORGANIZATION_TYPE: 'SET_ORGANIZATION_TYPE',
  SET_LEGAL_VISIBILITY: 'SET_LEGAL_VISIBILITY',
  SET_LEGAL_ENTITY_PREFIX: 'SET_LEGAL_ENTITY_PREFIX',
  SET_MILITARY_PREFIX: 'SET_MILITARY_PREFIX',
  SET_CIVILIAN_PREFIX: 'SET_CIVILIAN_PREFIX',
  SET_POLICE_PREFIX: 'SET_POLICE_PREFIX',
  SET_MAKE: 'SET_MAKE',
  SET_MODEL: 'SET_MODEL',
  SET_CALIBER: 'SET_CALIBER',
  SET_BARREL_TYPE: 'SET_BARREL_TYPE',
  SET_STATE: 'SET_STATE',
  SET_REGION: 'SET_REGION',
  SET_COUNTRY: 'SET_COUNTRY',
  SET_COUNTRY_ALL: 'SET_COUNTRY_ALL',
  SET_PHONE_CODE: 'SET_PHONE_CODE',
  SET_ADDRESS_TYPE: 'SET_ADDRESS_TYPE',
  SET_LEGALITY: 'SET_LEGALITY',
  SET_MANUFACTURER: 'SET_MANUFACTURER',
  SET_COUNTRY_OF_MANUFACTURER: 'SET_COUNTRY_OF_MANUFACTURER',
  SET_LEVEL: 'SET_LEVEL',
  SET_FIREARM_ACTION: 'SET_FIREARM_ACTION',
  SET_FIREARM_TYPE: 'SET_FIREARM_TYPE',
  SET_TRANSACTION_TYPE: 'SET_TRANSACTION_TYPE',
  SET_LEGAL_CLASSIFICATION: 'SET_LEGAL_CLASSIFICATION',
  SET_TRANSACTION_INITIAL_TYPES: 'SET_TRANSACTION_INITIAL_TYPES',
  SET_TRANSACTION_MIDDLE_TYPES: 'SET_TRANSACTION_MIDDLE_TYPES',

  SET_ACTIVITY: 'SET_ACTIVITY',
  SET_ART_ANCILLARY_OPTIONS: 'SET_ART_ANCILLARY_OPTIONS',
  SET_ART_ACCESSORY_CALIBRE: 'SET_ART_ACCESSORY_CALIBRE',
  SET_ART_ACCESSORY_COMPOSITION: 'SET_ART_ACCESSORY_COMPOSITION',
  SET_ART_ACCESSORY_HOST_TYPE: 'SET_ART_ACCESSORY_HOST_TYPE',
  SET_ART_ACCESSORY_LIGHT_COLOUR: 'SET_ART_ACCESSORY_LIGHT_COLOUR',
  SET_ART_ACCESSORY_MAGNIFICATION: 'SET_ART_ACCESSORY_MAGNIFICATION',
  SET_ART_ACCESSORY_MANUFACTURER_FLASH_ELIMINATOR:
    'SET_ART_ACCESSORY_MANUFACTURER_FLASH_ELIMINATOR',
  SET_ART_ACCESSORY_MANUFACTURER_LASER_LIGHT_MODULE:
    'SET_ART_ACCESSORY_MANUFACTURER_LASER_LIGHT_MODULE',
  SET_ART_ACCESSORY_MANUFACTURER_MAGAZINE: 'SET_ART_ACCESSORY_MANUFACTURER_MAGAZINE',
  SET_ART_ACCESSORY_MANUFACTURER_OPTICAL_SIGHT: 'SET_ART_ACCESSORY_MANUFACTURER_OPTICAL_SIGHT',
  SET_ART_ACCESSORY_MANUFACTURER_SUPPRESSOR: 'SET_ART_ACCESSORY_MANUFACTURER_SUPPRESSOR',
  SET_ART_ACCESSORY_PROOF_HOUSE: 'SET_ART_ACCESSORY_PROOF_HOUSE',
  SET_ART_ACCESSORY_RETICLE: 'SET_ART_ACCESSORY_RETICLE',
  SET_ART_ACCESSORY_TYPE: 'SET_ART_ACCESSORY_TYPE',
  SET_ART_ACCESSORY_UTILITY: 'SET_ART_ACCESSORY_UTILITY',

  SET_ART_AMMUNITION_TYPE: 'SET_ART_AMMUNITION_TYPE',
  SET_ART_AMMUNITION_MANUFACTURER: 'SET_ART_AMMUNITION_MANUFACTURER',
  SET_ART_AMMUNITION_FACTORY: 'SET_ART_AMMUNITION_FACTORY',
  SET_ART_AMMUNITION_CALIBRE_GAUGE: 'SET_ART_AMMUNITION_CALIBRE_GAUGE',
  SET_ART_AMMUNITION_CARTRIDGE_COMPOSITION: 'SET_ART_AMMUNITION_CARTRIDGE_COMPOSITION',
  SET_ART_AMMUNITION_COLOUR_OF_PRIMER_SEALANT: 'SET_ART_AMMUNITION_COLOUR_OF_PRIMER_SEALANT',
  SET_ART_AMMUNITION_PROJECTILE_SHAPE: 'SET_ART_AMMUNITION_PROJECTILE_SHAPE',
  SET_ART_AMMUNITION_FUNCTIONAL_TYPE: 'SET_ART_AMMUNITION_FUNCTIONAL_TYPE',
  SET_ART_AMMUNITION_SHOT_SIZE: 'SET_ART_AMMUNITION_SHOT_SIZE',
  SET_ART_AMMUNITION_MUNITION_TYPE: 'SET_ART_AMMUNITION_MUNITION_TYPE',
  SET_ART_AMMUNITION_MUNITION_VELOCITY: 'SET_ART_AMMUNITION_MUNITION_VELOCITY',

  SET_ART_COMPONENT_TYPE: 'SET_ART_COMPONENT_TYPE',
  SET_ART_COMPONENT_MANUFACTURER: 'SET_ART_COMPONENT_MANUFACTURER',
  SET_ART_COMPONENT_CALIBRE: 'SET_ART_COMPONENT_CALIBRE',
  SET_ART_COMPONENT_CAPACITY: 'SET_ART_COMPONENT_CAPACITY',
  SET_ART_COMPONENT_PROOF_HOUSE: 'SET_ART_COMPONENT_PROOF_HOUSE',
  SET_ART_COMPOSITION: 'SET_ART_COMPOSITION',

  SET_AUDIT_LOG_MODULE: 'SET_AUDIT_LOG_MODULE',
  SET_AUDIT_LOG_EVENT_TYPE: 'SET_AUDIT_LOG_EVENT_TYPE',
  SET_AUDIT_LOG_EVENT_NAME: 'SET_AUDIT_LOG_EVENT_NAME',

  SET_API_KEY_TYPE: 'SET_API_KEY_TYPE',
};
