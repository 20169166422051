import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from 'theme/theme';
import { FC_SYSTEM_AUTH_ACCESS_TOKEN, FC_SYSTEM_AUTH_REFRESH_TOKEN } from 'requests/auth';
import { ReCaptchaProvider } from 'state/contexts/recaptcha';
import { LicenceProvider } from 'state/contexts/licence';
import App from './App';
import reportWebVitals from './reportWebVitals';

const originalSetItem = localStorage.setItem;
localStorage.setItem = function (...rest) {
  const event = new Event('itemInserted');
  document.dispatchEvent(event);
  originalSetItem.apply(this, rest);
};

const originalRemoveItem = localStorage.removeItem;
localStorage.removeItem = function (...rest) {
  const event = new Event('itemRemoved');
  document.dispatchEvent(event);
  originalRemoveItem.apply(this, rest);
};

function idleLogout() {
  let timer: ReturnType<typeof setTimeout>;

  function logoutFunction() {
    localStorage.removeItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
    localStorage.removeItem(FC_SYSTEM_AUTH_REFRESH_TOKEN);
  }

  function resetTimer() {
    clearTimeout(timer);
    timer = setTimeout(logoutFunction, 6000000); // 100 min in milliseconds
  }

  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer;
  window.ontouchstart = resetTimer;
  window.ontouchmove = resetTimer;
  window.onclick = resetTimer;
  window.onkeydown = resetTimer;
  window.addEventListener('scroll', resetTimer, true);
}

idleLogout();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LicenceProvider>
        <ReCaptchaProvider>
          <App />
        </ReCaptchaProvider>
      </LicenceProvider>
    </ThemeProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
