import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ILegalEntityGroupFirearmTableCard {
  data: ITableDataSource;
  deleteAction?: (p: string | any) => void;
  renderDataColor: () => any;
}

const LegalEntityGroupFirearmTableCard = ({
  data,
  deleteAction,
  renderDataColor,
}: ILegalEntityGroupFirearmTableCard) => {
  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderDataColor().border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderDataColor().background,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('addGroup.firearmList.section.firearmTable.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('addGroup.firearmList.section.firearmTable.make.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.make)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('addGroup.firearmList.section.firearmTable.model.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.model)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('addGroup.firearmList.section.firearmTable.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.caliber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('addGroup.firearmList.section.firearmTable.state.column')}
        </Typography>
        <Chips text={emptyFieldHolder(data.state)} variant={renderDataColor().stateField} />
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('groupDetails.firearmListSection.action.column')}:{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('addGroup.firearmList.section.firearmTable.remove.link')}
            variant={EButtonVariants.outlined}
            onClick={() => deleteAction && deleteAction(data?.id)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { LegalEntityGroupFirearmTableCard };
