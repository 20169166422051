import styled from '@emotion/styled';
import { Accordion, AccordionDetails, AccordionSummary, css } from '@mui/material';
import { colors } from 'theme/colors';

interface IStyledAccordionDetailsProps {
  isHidden: boolean;
}

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  border: 1px solid ${colors.gray200};
  border-radius: 4px;

  &:before {
    height: 0;
  }
  &.Mui-expanded {
    border: 1px solid ${colors.gray400};
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  padding: 0;
  background-color: ${colors.white};

  & .MuiAccordionSummary-root {
    height: 48px;
  }

  &.Mui-expanded {
    background-color: ${colors.white};

    &.MuiAccordionSummary-root {
      min-height: 48px;
    }

    & .MuiAccordionSummary-content {
      margin: 0 0 10px 0;
    }

    & .MuiAccordionSummary-content h5 {
      color: ${colors.white};
    }

    & .MuiAccordionSummary-expandIconWrapper button {
      background-color: ${colors.primary900};
      border: none;
    }
    & .MuiAccordionSummary-expandIconWrapper button svg {
      color: ${colors.white};
    }
  }
`;

const StyledAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp(prop) {
    return prop !== 'isHidden';
  },
})`
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: ${colors.gray100};
  overflow: hidden;
  ${(props: IStyledAccordionDetailsProps) =>
    props.isHidden &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: ${colors.gray100};
        opacity: 0.7;
      }
    `}
`;

export { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails };
