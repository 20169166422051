import { IPaginator } from 'models/paginator';

export enum EDictionaryTypes {
  Country = 'country',
  Caliber = 'caliber',
  Manufacturer = 'manufacturer',
  FirearmAction = 'firearm_action',
  FirearmType = 'firearm_type',
  Region = 'region',
  Gender = 'gender',
  OrganizationType = 'organization_type',
  LegalVisibility = 'legal_visibility',
  legalEntityPrefix = 'prefix_type',
  civilianPrefix = 'civilian_prefix',
  militaryPrefix = 'military_prefix',
  policePrefix = 'police_prefix',
  PhoneCode = 'phone_code',
  Legality = 'legality',
  Make = 'make',
  Model = 'model',
  State = 'state',
  BarrelType = 'barrel_type',
  Level = 'level',
  AddressType = 'address_type',
  TransactionType = 'transaction_type',
  ManufacturerCountry = 'manufacturer_country',
  LegalClassification = 'legal_classification',
  Activity = 'activity',
  CountryAll = 'country_all',
  ArtAccessoryCalibre = 'art_accessory_calibre',
  ArtaccessoryComposition = 'art_composition',
  ArtAccessoryHostType = 'art_accessory_host_type',
  ArtAccessoryLightColour = 'art_accessory_light_colour',
  ArtAccessoryMagnification = 'art_accessory_magnification',
  ArtAccessoryManufacturerFlashEliminator = 'art_accessory_manufacturer_flash_eliminator',
  ArtAccessoryManufacturerLaserLightModule = 'art_accessory_manufacturer_laser_light_module',
  ArtAccessoryManufacturerMagazine = 'art_accessory_manufacturer_magazine',
  ArtAccessoryManufacturerOpticalSight = 'art_accessory_manufacturer_optical_sight',
  ArtAccessoryManufacturerSuppressor = 'art_accessory_manufacturer_suppressor',
  ArtAccessoryReticle = 'art_accessory_reticle',
  ArtAccessoryType = 'art_accessory_type',
  ArtAccessoryUtility = 'art_accessory_utility',
  ArtAccessoryProofHouse = 'art_accessory_proof_house',
  ArtAncillaryOptions = 'art_ancillary_type',

  ArtAmmunitionType = 'art_ammunition_type',
  ArtAmmunitionManufacturer = 'art_ammunition_manufacturer',
  ArtAmmunitionFactory = 'art_ammunition_factory',
  ArtAmmunitionCalibreGauge = 'art_ammunition_calibre_gauge',
  ArtAmmunitionCartridgeComposition = 'art_ammunition_cartridge_composition',
  ArtAmmunitionColourOfPrimerSealant = 'art_ammunition_colour_of_primer_sealant',
  ArtAmmunitionProjectileShape = 'art_ammunition_projectile_shape',
  ArtAmmunitionFunctionalType = 'art_ammunition_functional_type',
  ArtAmmunitionShotSize = 'art_ammunition_shot_size',
  ArtAmmunitionMunitionType = 'art_ammunition_munition_type',
  ArtAmmunitionMunitionVelocity = 'art_ammunition_munition_velocity',

  ArtComponentType = 'art_component_type',
  ArtComponentManufacturer = 'art_component_manufacturer',
  ArtComponentCalibre = 'art_component_calibre',
  ArtComponentCapacity = 'art_component_capacity',
  ArtComponentProofHouse = 'art_component_proof_house',
  ArtComposition = 'art_composition',

  AuditLogModule = 'module',
  AuditLogEventType = 'auditlog_event_type',
  AuditLogEventName = 'auditlog_event_name',

  ApiKeyType = 'apikey_type',
}

export enum ESpecificDictionaryTypes {
  Make = 'firearm/make',
  Model = 'firearm/model',
  Caliber = 'firearm/caliber',
  Manufacturer = 'firearm/manufacturer',
  FirearmAction = 'firearm/action',
  FirearmType = 'firearm/type',
  Level = 'firearm/level',
  ManufacturerCountry = 'firearm/country',
  LegalClassification = 'firearm/classification',
}

export interface ITranslation {
  uniqueId: string;
  languageUniqueId: string;
  name: string;
  code: string;
}

export interface IDictionary {
  uniqueId: string | number;
  type: string;
  name: string;
  isActive?: string | boolean;
  managedByAdmin?: boolean;
  translatedValues?: boolean;
  translations?: ITranslation[];
}

export interface IDictionaryResponse {
  content: IDictionary[];
}

export interface IDictionaryContentValues {
  uniqueId: string | number;
  name: string;
  isActive: boolean;
  edit?: boolean;
}

export interface IDictionaryValuesData {
  [key: string]: string;
}

export interface IDictionaryValueFormValues {
  isActive: string | boolean;
  translations: IDictionaryValuesData;
}

export interface IDictionaryValueForm {
  initialFormValues: IDictionaryValueFormValues;
  type: EDictionaryTypes | undefined;
  dictionaryLanguages: IDictionaryLanguage[];
  managedByAdmin: boolean;
  translatedValues: boolean;
  onCancel?: () => void;
  onSubmit: (arg: IDictionaryValueFormValues) => void;
  isEdit?: boolean;
}

export interface IDictionaryTab {
  name: string;
  description: string;
  managerByAdmin: boolean;
  translateName: string;
  translatedValues: boolean;
}

export interface IDictionaryValues extends IPaginator {
  content: IDictionaryContentValues[];
}

export interface IDictionaryDetailResponse {
  values?: IDictionaryValues;
  managedByAdmin: boolean;
  translatedType: string;
  type: string;
}

export interface IDictionaryLanguage {
  uniqueId: string;
  code: string;
  defaultLanguage: boolean;
  name: string;
}

export interface IDictionarySearchLike {
  searchValue: string;
}

export interface IDictionariesResponse extends IPaginator {
  content: IDictionaryTab[];
}

export enum ETransactionDictionaryItemTypes {
  COMPONENT = 'COMPONENT',
  FIREARM = 'FIREARM',
  AMMUNITION = 'AMMUNITION',
  ACCESSORY = 'ACCESSORY',
}

export enum EDictionaryPrefixTypes {
  civilianPrefix = 'civilian_prefix',
  militaryPrefix = 'military_prefix',
  policePrefix = 'police_prefix',
}
