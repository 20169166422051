import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useTranslations } from 'hooks/useTranslations';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';

interface IComponentsCard {
  data: ITableDataSource;
  handleEdit?: (args: string) => void;
  handleView?: (args: string) => void;
  renderColor: (data: ITableDataSource) => {
    border: string;
    stateField: EChipsVariants;
    background: string;
  };
}

export enum EComponentsTableLabels {
  serialNumber = 'componentsTable.accordion.serialNumber.column',
  firecycleId = 'componentsTable.accordion.fireCycleId.column',
  manufacturer = 'componentsTable.accordion.manufacturer.column',
  productName = 'componentsTable.accordion.productName.column',
  exhibitNumber = 'componentsTable.accordion.exhibitNumber.column',
  componentType = 'componentsTable.accordion.componentType.column',
  firearmType = 'componentsTable.accordion.firearmType.column',
  owner = 'componentsTable.accordion.owner.column',
  keeper = 'componentsTable.accordion.keeper.column',
  componentState = 'componentsTable.accordion.componentState.column',
  make = 'componentsTable.accordion.make.column',
  model = 'componentsTable.accordion.model.column',
  type = 'componentsTable.accordion.componentType.column',
  state = 'componentsTable.accordion.state.column',
}

const ComponentsCard = ({ data, handleEdit, handleView, renderColor }: IComponentsCard) => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderColor(data).border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderColor(data).background,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.firecycleId)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.serialNumber)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.exhibitNumber)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.exhibitNumber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.type)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ancillarySpecificType)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.manufacturer)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.productName)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.componentProductName)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.firearmType)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.componentFrtType)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.make)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.componentFrtMake)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.model)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.componentFrtModel)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.owner)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ownerName)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.keeper)}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeperName)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentsTableLabels.state)}
        </Typography>
        {data.state ? <Chips text={data.state} variant={renderColor(data).stateField} /> : '-'}
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('general.actions.button')}:{' '}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={9} spacing={1} justifyContent="flex-end">
          {handleEdit && hasPermissions([EPermission.EDIT_ANCILLARY]) && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('accesoriesTable.accordion.edit.button')}
                variant={EButtonVariants.outlined}
                onClick={() => handleEdit(data.id as string)}
                size="small"
                fullWidth
              />
            </Grid>
          )}
          {handleView && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('accesoriesTable.accordion.details.button')}
                variant={EButtonVariants.outlined}
                onClick={() => handleView(data.id as string)}
                size="small"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ComponentsCard };
