import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { css } from '@mui/material';

interface IStyledFormLabel {
  disabled?: boolean;
}

export const StyledFormLabel = styled.label<IStyledFormLabel>`
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 600;
  color: ${colors.gray900};
  letter-spacing: 0.02em;
  ${(props: IStyledFormLabel) =>
    props.disabled &&
    css`
      color: ${colors.gray400};
    `}
`;
