import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledTitleSection = styled(Typography)`
  color: ${colors.primary900};
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  width: 100%;
  border-radius: 4px;
  background-color: ${colors.primary15};
  padding: 13px 24px;
  margin: 24px 0;
`;

export const StyledDivider = styled.div`
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background-color: ${colors.primary25};
`;

export const StyledViewSectionTitle = styled(Typography)`
  padding: 8px 0px 10px 0px;
  text-transform: uppercase;
  color: ${colors.primary500};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
`;
