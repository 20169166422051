import styled from '@emotion/styled';
import { Autocomplete, css } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { colors } from 'theme/colors';

interface IStyledFormControl {
  error: boolean;
}

export const StyledFormControl = styled(FormControl)<IStyledFormControl>`
  width: 100%;

  ${(props: IStyledFormControl) =>
    props.error &&
    css`
      &:hover {
        border-color: ${colors.error600};
      }

      & .MuiOutlinedInput-root fieldset {
        border-color: ${colors.error600};
      }
    `}
`;

interface IStyledAutocomplete {
  disabled?: boolean;
}
export const StyledAutocomplete = styled(Autocomplete)<IStyledAutocomplete>`
  ${(props: IStyledAutocomplete) =>
    props.disabled &&
    css`
      background: ${colors.gray100} !important;
      input {
        color: ${colors.gray300};
        background: ${colors.gray100} !important;
      }
    `}
`;

export const StyledInnerWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 3px;
  height: 32px;
`;
