import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useDateTimeSetupFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    dateFormat: yup.string().required(t('general.field.validation.required')),
  });

  return { schema };
};

export default useDateTimeSetupFormSchema;
