import { styled, Typography, Grid, css } from '@mui/material';
import { colors } from 'theme/colors';

export const ComponentInformationTitle = styled(Typography)`
  padding: 8px 0px 10px 0px;
  text-transform: uppercase;
  color: ${colors.primary500};
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
`;

interface IComponentInformationContainer {
  borderHidden?: boolean;
}
export const ComponentInformationContainer = styled(Grid)`
  border-bottom: 1px solid ${colors.primary25};
  padding-bottom: 15px;
  row-gap: 18px;
  ${(props: IComponentInformationContainer) =>
    props?.borderHidden &&
    css`
      border: none;
    `}
`;
