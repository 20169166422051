export enum EActionTypes {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE',
  GENERATE_PASSWORD = 'GENERATE_PASSWORD',
  GENERATE_PASSWORD_SUCCESS = 'GENERATE_PASSWORD_SUCCESS',
  GENERATE_PASSWORD_FAILURE = 'GENERATE_PASSWORD_FAILURE',
}
