import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

export const useReplaceLicenceFormSchema = () => {
  const { t } = useTranslations();

  const schema = yup.object({
    licenceData: yup.string().required(t('general.field.validation.required')).trim(),
  });

  return { schema };
};
