export const colors = {
  // general
  black: '#000000',

  // primary colors
  primary15: '#F8FAFC',
  primary25: '#E5EEF5',
  primary50: '#CBDCEB',
  primary100: '#A4C1DB',
  primary200: '#7DA7CC',
  primary300: '#568CBC',
  primary400: '#2E72AD',
  primary500: '#07579D',
  primary600: '#064D8B',
  primary700: '#05437A',
  primary800: '#053A68',
  primary900: '#043056',

  // gray
  white: '#FFFFFF',
  gray25: '#FCFDFD',
  gray50: '#F9FAFB',
  gray100: '#F2F4F7',
  gray200: '#E4E7EC',
  gray300: '#D0D5DD',
  gray400: '#98A2B3',
  gray500: '#667085',
  gray600: '#475467',
  gray700: '#344054',
  gray800: '#1D2939',
  gray900: '#101828',

  // error
  error15: '#FFF7F9',
  error25: '#FEEEF2',
  error50: '#FBDFE2',
  error100: '#F9C9CF',
  error200: '#E69396',
  error300: '#D9696E',
  error400: '#E1464C',
  error500: '#E43132',
  error600: '#D52631',
  error700: '#C41C2B',
  error800: '#B71323',
  error900: '#A80019',

  // success
  success15: '#F4FAF9',
  success25: '#E8F5F2',
  success50: '#E0F2ED',
  success100: '#B3DFD2',
  success200: '#83CBB5',
  success300: '#54B599',
  success400: '#32A585',
  success500: '#1A9572',
  success600: '#178866',
  success700: '#127858',
  success800: '#09684a',
  success900: '#004D30',

  // warning
  warning15: '#FFFCF5',
  warning25: '#FFF9EB',
  warning50: '#FFF7E4',
  warning100: '#FFEABB',
  warning200: '#FFDE8F',
  warning300: '#FFD164',
  warning400: '#FFC841',
  warning500: '#FFBF16',
  warning600: '#EEAF15',
  warning700: '#D89B10',
  warning800: '#C1850E',
  warning900: '#9C6107',
};
