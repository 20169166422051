import React from 'react';
import { StyledFormLabel } from './styles';

interface IFormLabel {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

const FormLabel = ({ name, label, disabled, required }: IFormLabel) => (
  <StyledFormLabel htmlFor={name} disabled={disabled}>
    {label}
    {required && !disabled ? '*' : ''}
  </StyledFormLabel>
);

export default FormLabel;
