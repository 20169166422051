import Resizer from 'react-image-file-resizer';
import { EMIMETypes } from 'constants/MIMETypes';
import { EFileType } from 'constants/FileType';

const mapCompressFormat = (type: any) => {
  switch (type) {
    case EMIMETypes.JPG:
    case EMIMETypes.JPEG:
      return EFileType.JPEG.toUpperCase();
    case EMIMETypes.PNG:
      return EFileType.PNG.toUpperCase();
    default:
      return null;
  }
};

export const downscaleAttachmentIfRequired = (attachment: File) => {
  if (
    attachment &&
    [EMIMETypes.JPG, EMIMETypes.JPEG, EMIMETypes.PNG].includes(attachment.type as any)
  ) {
    const compressFormat = mapCompressFormat(attachment.type);
    if (compressFormat) {
      return new Promise((resolve) => {
        Resizer.imageFileResizer(
          attachment,
          1920,
          1080,
          compressFormat,
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          'file',
        );
      });
    }
  }
  return attachment;
};
