import { useTranslations } from 'hooks/useTranslations';
import { StyledNoFiltersWrapper } from 'pages/MyFiltersPage/styles';
import { Typography } from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Grid';

export const NoFiltersDefined = () => {
  const { t } = useTranslations();

  return (
    <Grid item xs={12}>
      <StyledNoFiltersWrapper>
        <Typography fontSize="1rem">{t('noFiltersDefined.label')}</Typography>
      </StyledNoFiltersWrapper>
    </Grid>
  );
};
