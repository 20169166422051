import { ISearchFilterValue } from 'models/notification';
import { parseDictionary } from 'helpers/dictionary';
import { IDictionary } from 'models/dictionary';
import { EAncillaryLabels } from 'pages/AncillariesPage/AncillariesReferenceTablePage/DetailAncillariesReference';

export enum ENotificationFilterType {
  individual = 'individual',
  organization = 'organization',
  firearm = 'firearm',
  transaction = 'transaction',
  ancillaries = 'ancillaries',
}

export enum ENotificationFilterTypeLabels {
  individual = 'notification.filterType.individual.label',
  organization = 'notification.filterType.organisation.label',
  firearm = 'notification.filterType.firearm.label',
  transaction = 'notification.filterType.transaction.label',
}

enum ENotificationIndividualLabels {
  firstname = 'searchForLegalEntity.legalEntityTable.firstName.column',
  lastName = 'searchForLegalEntity.legalEntityTable.lastName.column',
  city = 'searchForLegalEntity.legalEntityTable.city.column',
  birthdayFrom = 'searchForLegalEntity.legalEntityTable.dateOfBirthFrom.column',
  birthdayTo = 'searchForLegalEntity.legalEntityTable.dateOfBirthTo.column',
  visibilityUniqueId = 'searchForLegalEntity.legalEntityTable.visibility.column',
  genderUniqueId = 'searchForLegalEntity.legalEntityTable.gender.column',
  legalEntityType = 'searchForLegalEntity.legalEntityTable.LegalEntityType.column',
}

enum ENotificationOrganisationLabels {
  name = 'searchForLegalEntity.legalEntityTable.name.column',
  organizationTypeUniqueId = 'searchForLegalEntity.legalEntityTable.organisationType.column',
  departmentName = 'searchForLegalEntity.legalEntityTable.departmentName.column',
  city = 'searchForLegalEntity.legalEntityTable.city.column',
  visibilityUniqueId = 'searchForLegalEntity.legalEntityTable.visibility.column',
  legalEntityType = 'searchForLegalEntity.legalEntityTable.LegalEntityType.column',
}

enum ENotificationFirearmLabels {
  serialNumber = 'registeredFirearms.accordion.serialNumber.label',
  fireCycleId = 'registeredFirearms.accordion.fireCycleId.label',
  stateUniqueId = 'registeredFirearms.accordion.state.label',
  legalityUniqueId = 'registeredFirearms.accordion.legality.label',
  make = 'registeredFirearms.accordion.make.label',
  model = 'registeredFirearms.accordion.model.label',
  manufacturer = 'registeredFirearms.accordion.manufacturer.label',
  caliber = 'registeredFirearms.accordion.calibre.label',
  productCode = 'registeredFirearms.accordion.alsoKnownAsProductCode.label',
  action = 'registeredFirearms.accordion.firearmAction.label',
  country = 'registeredFirearms.accordion.country.label',
  type = 'registeredFirearms.accordion.type.label',
  ownerName = 'transactionsList.owner.label',
  keeperName = 'transactionsList.keeper.label',
}

enum ENotificationTransactionLabels {
  firecycleId = 'transactionsList.firecycleID.label',
  serialNumber = 'transactionsList.serialNumber.label',
  make = 'transactionsList.make.label',
  model = 'transactionsList.model.label',
  caliber = 'transactionsList.calibre.label',
  transactionTypeUniqueId = 'transactionsList.transactionType.label',
  stateUniqueId = 'transactionsList.state.label',
  transactionDateFrom = 'transactionsList.transactionDateFrom.label',
  transactionDateTo = 'transactionsList.transactionDateTo.label',
  transactionTimeFrom = 'transactionsList.transactionTimeFrom.label',
  transactionTimeTo = 'transactionsList.transactionTimeTo.label',
  createTimeFrom = 'transactionsList.createTimeFrom.label',
  createTimeTo = 'transactionsList.createTimeTo.label',
  action = 'transactionsList.firearmAction.label',
  legalityUniqueId = 'transactionsList.firearmLegality.label',
  expirationTimeFrom = 'transactionsList.transactionExpiryTimeFrom.label',
  expirationTimeTo = 'transactionsList.transactionExpiryTimeTo.label',
  ownerName = 'transactionsList.owner.label',
  keeperName = 'transactionsList.keeper.label',
  asset = 'transactionsList.asset.label',
}

const excludedSearchCriteria = ['ownerUniqueId', 'keeperUniqueId'];

export const mapFilterTypeToLabel = (filterType: string, t: (key: string) => string) =>
  Object.entries(ENotificationFilterTypeLabels)
    .filter((entry) => entry[0] === filterType.toLowerCase())
    .map((entry) => t(entry[1] as string))[0] || '';

export const prepareNotificationFiltersArray = (
  values: ISearchFilterValue[],
  dictionaries: { [key: string]: IDictionary[] | null } | null,
): ISearchFilterValue[] => {
  if (!values || values.length === 0) {
    return [];
  }
  return values
    .filter((item) => !excludedSearchCriteria.includes(item.key))
    .map((item) => {
      if (dictionaries?.[item.key]) {
        const parsedDict = parseDictionary(dictionaries[item.key]!, 'name');
        item.value = parsedDict[item.value] || item.value;
      }
      return item;
    });
};

export const getLabelsByFilterType = (filterType: string) => {
  switch (filterType.toLowerCase()) {
    case ENotificationFilterType.individual:
      return ENotificationIndividualLabels;
    case ENotificationFilterType.organization:
      return ENotificationOrganisationLabels;
    case ENotificationFilterType.firearm:
      return ENotificationFirearmLabels;
    case ENotificationFilterType.transaction:
      return ENotificationTransactionLabels;
    case ENotificationFilterType.ancillaries:
      return EAncillaryLabels;
    default:
      return {};
  }
};

export const sortFiltersArray = (filters?: ISearchFilterValue[], filterType?: string) => {
  if (!filters || !filterType) {
    return [];
  }
  const labels: { [key: string]: string } = getLabelsByFilterType(filterType);
  const order = Object.keys(labels);
  return filters.sort((a, b) => {
    const index1 = order.findIndex((key) => key === a.key);
    const index2 = order.findIndex((key) => key === b.key);
    return index1 - index2;
  });
};
