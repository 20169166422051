import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { IFirearm } from 'models/firearm';
import { IAccessory, IAmmunition, IAncillary, IComponent } from 'models/ancillaries';

class SearchFilterStore {
  private static instance: SearchFilterStore | undefined;

  public _data: { [key: string]: any } = {};

  public _dictionaries: { [key: string]: any } = {};

  public _fireams: { data: IFirearm[] } = { data: [] };

  public _accessories: { data: IAccessory[] } = { data: [] };

  public _ammunition: { data: IAmmunition[] } = { data: [] };

  public _components: { data: IComponent[] } = { data: [] };

  public _ancillaries: { data: IAncillary[] } = { data: [] };

  constructor() {
    if (!SearchFilterStore.instance) {
      SearchFilterStore.instance = this;
    }
    // eslint-disable-next-line no-constructor-return
    return SearchFilterStore.instance;
  }

  setValue(key: ESearchFilterKeys, value: any) {
    this._data[key] = value;
  }

  getValue(key: ESearchFilterKeys) {
    return this._data?.[key] ? this._data[key] : null;
  }

  clearValue(key: ESearchFilterKeys) {
    this._data[key] = [];
  }

  setDictionaries(key: ESearchFilterKeys, value: any) {
    this._dictionaries[key] = value;
  }

  getDictionaries(key: ESearchFilterKeys) {
    return this._dictionaries?.[key] ? this._dictionaries[key] : null;
  }

  setFirearms(firearms: IFirearm[]) {
    this._fireams.data = firearms;
    const event = new CustomEvent('setFirearmsEvent', {
      detail: { data: this._fireams.data },
    });
    document.dispatchEvent(event);
  }

  getFirearms() {
    return this._fireams.data;
  }

  setAccessories(accessories: IAccessory[]) {
    this._accessories.data = accessories;
    const event = new CustomEvent('setAccessoriesEvent', {
      detail: { data: this._accessories.data },
    });
    document.dispatchEvent(event);
  }

  setComponents(components: IComponent[]) {
    this._components.data = components;
    const event = new CustomEvent('setComponentsEvent', {
      detail: { data: this._components.data },
    });
    document.dispatchEvent(event);
  }

  setAmmunition(ammunitions: IAmmunition[]) {
    this._ammunition.data = ammunitions;
    const event = new CustomEvent('setAmmunitionsEvent', {
      detail: { data: this._ammunition.data },
    });
    document.dispatchEvent(event);
  }

  setAncillaries(ancillaries: IAncillary[]) {
    this._ancillaries.data = ancillaries;
    const event = new CustomEvent('setAncillariesEvent', {
      detail: { data: this._ancillaries.data },
    });
    document.dispatchEvent(event);
  }

  getAccessories() {
    return this._accessories.data;
  }

  getAmmunition() {
    return this._ammunition.data;
  }

  getComponents() {
    return this._components.data;
  }
}

const instance = new SearchFilterStore();
Object.freeze(instance);

export default instance;
