import React, { useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { simpleFormatDate } from 'utils/date';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteAttachmentPage/styles';
import { useAncillaries } from '../../../hooks';

const AmmunitionDeleteAttachmentPage = () => {
  const { id, ancillaryId } = useParams<{ id: string; ancillaryId: string }>();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { longDateFormat } = useGlobalProperty();

  const { handleDeleteAttachment } = useAncillaries();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const navigateToDetails = () =>
    navigate(
      `${ERouteLinks.ViewAmmunition.replace(':id', ancillaryId as string)}?expand=attachments`,
    );

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (ancillaryId && id) {
        const result = await handleDeleteAttachment(ancillaryId as string, id as string);

        if (result.status === 204) {
          navigateToDetails();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.ammunition.name'),
            route: `${ERouteLinks.Ancillaries}?expand=ammunition`,
          },
          {
            label: t('ammunitionDetails.accordion.title.label'),
            route: `${ERouteLinks.ViewAmmunition.replace(
              ':id',
              ancillaryId as string,
            )}?expand=attachments`,
          },
          'Delete Attachment',
        ]}
      />
      {id && (
        <>
          <StyledPageTitle variant="h4">
            {t('ancillary.attachment.delete.header')}
          </StyledPageTitle>

          <Typography variant="body1">{t('ancillary.attachment.delete.text')}</Typography>

          <StyledDetailsWrapper container mt={2} sx={{ width: { lg: '70%' } }}>
            <Grid item xs={12} md={6} sx={{ mb: { xs: 3, md: 0 } }}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAttchment.creationDateAndTime.label')}
              </Typography>
              <Typography variant="body2">
                {simpleFormatDate(state?.ammunitionAttachments[0].createdDate, longDateFormat)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAttchment.attachmentName.label')}
              </Typography>
              <Typography variant="body2">{state?.ammunitionAttachments[0].name}</Typography>
            </Grid>
          </StyledDetailsWrapper>

          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Button
                fullWidth
                label={t('general.yes.button')}
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Button
                fullWidth
                label={t('general.no.button')}
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { AmmunitionDeleteAttachmentPage };
