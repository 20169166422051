import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useCustomFields } from 'pages/CustomFieldsPage/hooks';
import { ICustomField } from 'models/customField';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { EPermission } from 'models/permissions';
import { mapCustomFieldToLabel, mapCustomFieldTypeToLabel } from '../helpers';

export enum ECustomFieldActions {
  Disable = 'disable',
  Enable = 'enable',
  Delete = 'delete',
}

const ToggleCustomFieldPage = () => {
  const [customField, setCustomField] = useState<ICustomField>();
  const { id, toggleType } = useParams<{ id: string; toggleType: string }>();
  const { handleGetCustomField, handleChangeStatus, handleDelete } = useCustomFields();
  const { samePageAlert, clearAlert } = useAlert();
  const { t } = useTranslations();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (id) {
        const result = await handleGetCustomField(id);
        if (result) {
          setCustomField(result);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  let title = '';
  switch (toggleType) {
    case ECustomFieldActions.Disable:
      title = t('customFields.disable.label');
      break;
    case ECustomFieldActions.Enable:
      title = t('customFields.enable.label');
      break;
    case ECustomFieldActions.Delete:
      title = t('customFields.delete.label');
      break;
    default:
      break;
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('customFields.header'),
            route: ERouteLinks.CustomFields,
          },
          `${title} ${t('customFields.subHeader')}`,
        ]}
      />
      {customField && (
        <>
          <StyledPageTitle variant="h4">
            {title} {t('customFields.customFieldLabel')}
          </StyledPageTitle>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {`${t('customField.toggle.areYouSure')} ${title.toLowerCase()} ${t(
              'customField.toggle.theBelowCustomField',
            )}`}
          </Typography>

          <DetailsTemplate
            deletePermissions={[EPermission.MANAGE_CUSTOM_FIELDS]}
            pageType={EDetailsPageTypes.delete}
            title={customField?.name}
          >
            <Grid container>
              <Grid item xs={12} sm={6} lg={3} p={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('customFields.card.objectType.label')}
                </Typography>
                <Typography variant="body2">
                  {t(mapCustomFieldToLabel(customField.objectType))}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('customFields.card.name.label')}
                </Typography>
                <Typography variant="body2">{customField.name}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('customFields.card.type.label')}
                </Typography>
                <Typography variant="body2">
                  {t(mapCustomFieldTypeToLabel(customField.customFieldType))}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('customFields.card.status.label')}
                </Typography>
                <Chips
                  text={
                    customField.enabled
                      ? t('customFields.card.enabled.label')
                      : t('customFields.card.disabled.label')
                  }
                  variant={
                    customField.enabled ? EChipsVariants.success : EChipsVariants.inactive
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('customFields.card.mandatoryField.label')}
                </Typography>
                <Chips
                  text={
                    customField.mandatory
                      ? t('customFields.card.yes.label')
                      : t('customFields.card.no.label')
                  }
                  variant={
                    customField.mandatory ? EChipsVariants.primary : EChipsVariants.inactive
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('customFields.searchCriterion.label')}
                </Typography>
                <Chips
                  text={customField.searchCriteria ? 'YES' : 'No'}
                  variant={
                    customField.searchCriteria
                      ? EChipsVariants.primary
                      : EChipsVariants.inactive
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={2}>
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('customFields.visibleSearchResults.label')}
                </Typography>
                <Chips
                  text={
                    customField.visibleInSearchResults
                      ? t('customFields.card.yes.label')
                      : t('customFields.card.no.label')
                  }
                  variant={
                    customField.visibleInSearchResults
                      ? EChipsVariants.primary
                      : EChipsVariants.inactive
                  }
                />
              </Grid>
            </Grid>
          </DetailsTemplate>

          {toggleType === ECustomFieldActions.Disable && (
            <Box sx={{ mt: 2 }}>
              <Alert text={t('customFields.disable.alert')} variant={EAlertVariants.warning} />
            </Box>
          )}

          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.yes.button')}
                  id="yes-button"
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                  onClick={() =>
                    toggleType === ECustomFieldActions.Delete
                      ? handleDelete(customField?.uniqueId)
                      : handleChangeStatus(customField?.uniqueId, toggleType)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.no.button')}
                  id="no-button"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.CustomFields)}
                />
              </Grid>
            </Grid>
          </Grid>

          {samePageAlert && (
            <Box mt={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default ToggleCustomFieldPage;
