import { EPerPages, ISortOptions } from 'models/table';
import {
  IAuditLogItemResponse,
  IAuditLogResponse,
  IAuditLogSearchFormValues,
} from 'models/auditLog';
import { queryStringWithSort } from 'helpers/request';
import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { clearObject } from 'helpers/objects';

const getAuditLog = async (
  page: number,
  size: EPerPages,
  query: Partial<IAuditLogSearchFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size }, sort);
  const { data } = await apiClient.post<TResponse<IAuditLogResponse>>(
    `${ApiEndpoints.GET_AUDIT_LOG()}?${qString}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAuditLogItem = async (id: string) => {
  const { data } = await apiClient.get<TResponse<IAuditLogItemResponse>>(
    ApiEndpoints.GET_AUDIT_LOG_ITEM(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export { getAuditLog, getAuditLogItem };
