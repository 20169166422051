import styled from '@emotion/styled';
import { Button, css, IconButton } from '@mui/material';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { colors } from 'theme/colors';

interface IStyledButton {
  variant:
    | 'text'
    | 'outlined'
    | 'contained'
    | undefined
    | EButtonVariants.outlinedIconTertiary;
  disabled: boolean;
  size?: 'small' | 'medium' | 'large' | undefined;
  width?: string;
  isLoading?: boolean;
}

interface IStyledIconButton {
  variant:
    | 'text'
    | 'outlined'
    | 'contained'
    | undefined
    | EButtonVariants.outlinedIconTertiary;
  tableButton: boolean;
  sizePX?: EButtonSizesPX;
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    [EButtonVariants.outlinedIconTertiary]: true;
  }
}

export const StyledButton = styled(Button, {
  shouldForwardProp(prop) {
    return prop !== 'isLoading';
  },
})<IStyledButton>`
  font-family: 'NotoSansMedium', sans-serif;
  height: 40px;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background-color: ${colors.white};
  min-width: fit-content;

  ${(props: IStyledButton) =>
    props.isLoading &&
    css`
      pointer-events: none;
    `}

  ${(props: IStyledButton) =>
    props.size === EButtonSizes.small &&
    css`
      height: 32px;
    `}
  ${(props: IStyledButton) =>
    props.variant === EButtonVariants.contained &&
    css`
      background-color: ${colors.primary500};

      & svg {
        color: ${colors.white};
      }

      &:hover {
        background-color: ${colors.primary300};
        box-shadow: 0 0 5px rgba(7, 87, 157, 0.1), 0 0 10px rgba(5, 58, 104, 0.02);
      }

      &:focus {
        background-color: ${colors.primary600};
      }

      &:active {
        background-color: ${colors.primary900};
      }
    `}
  ${(props: IStyledButton) =>
    props.variant === EButtonVariants.contained &&
    props.disabled &&
    css`
      background-color: ${colors.gray200};
      color: ${colors.gray400};

      & svg {
        color: ${colors.gray400};
      }
    `}

  ${(props: IStyledButton) =>
    props.variant === EButtonVariants.outlined &&
    css`
      border-color: ${colors.primary500};
      color: ${colors.primary500};

      & svg {
        color: ${colors.primary500};
      }

      &:hover {
        border-color: ${colors.primary300};
        color: ${colors.primary300};
        box-shadow: 0 0 5px rgba(7, 87, 157, 0.1), 0 0 10px rgba(5, 58, 104, 0.02);

        & svg {
          color: ${colors.primary300};
        }
      }

      &:focus {
        border-color: ${colors.primary600};
        color: ${colors.primary600};

        & svg {
          color: ${colors.primary600};
        }
      }

      &:active {
        border-color: ${colors.primary900};
        color: ${colors.primary900};

        & svg {
          color: ${colors.primary900};
        }
      }
    `}


  ${(props: IStyledButton) =>
    props.variant === EButtonVariants.outlined &&
    props.disabled &&
    css`
      border-color: ${colors.gray400};
      color: ${colors.gray400};

      & svg {
        color: ${colors.gray400};
      }
    `}

  ${(props: IStyledButton) =>
    props.variant === EButtonVariants.text &&
    css`
      color: ${colors.primary500};

      & svg {
        color: ${colors.primary500};
      }

      &:hover {
        background-color: ${colors.primary25};
        color: ${colors.primary300};
        box-shadow: 0 0 5px rgba(7, 87, 157, 0.1), 0 0 10px rgba(5, 58, 104, 0.02);

        & svg {
          color: ${colors.primary300};
        }
      }

      &:focus {
        background-color: ${colors.primary25};
        color: ${colors.primary600};

        & svg {
          color: ${colors.primary600};
        }
      }

      &:active {
        background-color: ${colors.primary50};
        color: ${colors.primary900};

        & svg {
          color: ${colors.primary900};
        }
      }
    `}

  ${(props: IStyledButton) =>
    props.variant === EButtonVariants.text &&
    props.disabled &&
    css`
      color: ${colors.gray400};

      & svg {
        color: ${colors.gray400};
      }
    `}

  ${(props: IStyledButton) =>
    props.width === EButtonSizes.small &&
    css`
      width: ${props.width};
    `}
`;

const calculateIconSize = (sizePX: EButtonSizesPX) => {
  switch (sizePX) {
    case EButtonSizesPX.px24:
      return '14px';
    case EButtonSizesPX.px32:
      return '17px';
    case EButtonSizesPX.px40:
    case EButtonSizesPX.px48:
      return '21px';
    default:
      return '1em';
  }
};
export const StyledIconButton = styled(IconButton, {
  shouldForwardProp(prop) {
    return prop !== 'sizePX' && prop !== 'tableButton';
  },
})<IStyledIconButton>`
  border-radius: 4px;
  background-color: ${colors.white};

  ${(props: IStyledIconButton) =>
    props.sizePX &&
    css`
      width: ${props.sizePX};
      height: ${props.sizePX};

      & svg {
        width: ${calculateIconSize(props.sizePX)};
      }
    `}
  ${(props: IStyledIconButton) =>
    props.variant === EButtonVariants.contained &&
    css`
      background-color: ${colors.primary50};

      &:hover {
        background-color: ${colors.primary25};
        box-shadow: 0 0 5px rgba(7, 87, 157, 0.1), 0 0 10px rgba(5, 58, 104, 0.02);
      }

      &:active {
        background-color: ${colors.primary100};
      }
    `}
  ${(props: IStyledIconButton) =>
    props.variant === EButtonVariants.outlined &&
    css`
      border: 1px solid ${colors.primary50};

      & svg {
        color: ${colors.primary900};
      }

      &:hover {
        background-color: ${colors.primary25};
        box-shadow: 0 0 5px rgba(7, 87, 157, 0.1), 0 0 10px rgba(5, 58, 104, 0.02);
      }

      &:focus {
        background-color: ${colors.primary100};
        border: none;
      }

      &:active {
        background-color: ${colors.primary900};
        border: none;

        & svg {
          color: ${colors.white};
        }
      }
    `}
  ${(props: IStyledIconButton) =>
    props.variant === EButtonVariants.outlined &&
    props.tableButton &&
    css`
      border: 1px solid ${colors.primary50};
      margin-right: 2px;

      & svg {
        color: ${colors.primary900};
      }

      &:hover {
        background-color: ${colors.white};
        border: 1px solid ${colors.primary50};
        box-shadow: 0 0 5px rgba(7, 87, 157, 0.1), 0 0 10px rgba(5, 58, 104, 0.02);
      }

      &:active,
      &:focus {
        background-color: ${colors.white};
        border: 1px solid ${colors.primary500};

        & svg {
          color: ${colors.primary500};
        }
      }
    `}
  ${(props: IStyledIconButton) =>
    props.variant === EButtonVariants.outlinedIconTertiary &&
    css`
      border: 1px solid ${colors.primary500};
    `}
`;
