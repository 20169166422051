/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { Marker, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import { IFirearm } from 'models/firearm';
import { EIconTypes } from 'constants/Icons';
import { EButtonVariants } from 'constants/Buttons';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import MapLegalityDropdown from 'pages/Firearms/RegisteredFirearmsMapPage/MapLegalityDropdown';
import { useDictionary } from 'models/dictionaryContext';
import SearchFilterStore from 'state/SearchFilterStore';
import FirearmDataSidebar from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmDataSidebar';
import { useLang } from 'models/langContext';
import { LegalityTypes } from 'constants/LegalityTypes';
import {
  ButtonFullScreen,
  ButtonZoomIn,
  ButtonZoomOut,
  StyledLocationMapWrapper,
  StyledMapContainer,
} from './style';

const MarkerIcons = {
  Legal: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H20L16 39L12 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#127858"/><g clip-path="url(#clip0_1600_70685)"><path d="M26 8.5V16.0263H20.2471L20.248 16.9737C20.248 19.0674 18.5478 20.7632 16.4485 20.7632H14.8232L14.5224 21.5L15.4217 23.0145C15.72 23.4456 15.6117 24.0358 15.1795 24.3323C15.0209 24.4413 14.8328 24.5 14.64 24.5H6L8.19705 16.9509L6 16.0263L9.79947 8.5H26ZM24.1003 10.3947H11.0343L8.5219 15.0316L10.4074 15.8245L8.38797 22.6053H12.8315L12.5171 21.5L14.5108 14.1316H24.1003V10.3947ZM18.3473 16.0263H16.0733L15.512 18.8684H16.4485C17.4982 18.8684 18.3483 18.0205 18.3483 16.9737L18.3473 16.0263Z" fill="white"/></g><defs><clipPath id="clip0_1600_70685"><rect width="24" height="24" fill="white" transform="translate(4 4)"/></clipPath></defs></svg>',
  )}`,
  LegalHover: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H20L16 39L12 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#32A585"/><g clip-path="url(#clip0_1600_70685)"><path d="M26 8.5V16.0263H20.2471L20.248 16.9737C20.248 19.0674 18.5478 20.7632 16.4485 20.7632H14.8232L14.5224 21.5L15.4217 23.0145C15.72 23.4456 15.6117 24.0358 15.1795 24.3323C15.0209 24.4413 14.8328 24.5 14.64 24.5H6L8.19705 16.9509L6 16.0263L9.79947 8.5H26ZM24.1003 10.3947H11.0343L8.5219 15.0316L10.4074 15.8245L8.38797 22.6053H12.8315L12.5171 21.5L14.5108 14.1316H24.1003V10.3947ZM18.3473 16.0263H16.0733L15.512 18.8684H16.4485C17.4982 18.8684 18.3483 18.0205 18.3483 16.9737L18.3473 16.0263Z" fill="white"/></g><defs><clipPath id="clip0_1600_70685"><rect width="24" height="24" fill="white" transform="translate(4 4)"/></clipPath></defs></svg>',
  )}`,
  Illegal: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H20L16 39L12 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#C41C2B"/><g clip-path="url(#clip0_1765_82664)"><path d="M26 8.5V16.0263H20.2471L20.248 16.9737C20.248 19.0674 18.5478 20.7632 16.4485 20.7632H14.8232L14.5224 21.5L15.4217 23.0145C15.72 23.4456 15.6117 24.0358 15.1795 24.3323C15.0209 24.4413 14.8328 24.5 14.64 24.5H6L8.19705 16.9509L6 16.0263L9.79947 8.5H26ZM24.1003 10.3947H11.0343L8.5219 15.0316L10.4074 15.8245L8.38797 22.6053H12.8315L12.5171 21.5L14.5108 14.1316H24.1003V10.3947ZM18.3473 16.0263H16.0733L15.512 18.8684H16.4485C17.4982 18.8684 18.3483 18.0205 18.3483 16.9737L18.3473 16.0263Z" fill="white"/></g><defs><clipPath id="clip0_1765_82664"><rect width="24" height="24" fill="white" transform="translate(4 4)"/></clipPath></defs></svg>',
  )}`,
  IllegalHover: `data:image/svg+xml;utf8,${encodeURIComponent(
    '<svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H20L16 39L12 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#E1464C"/><g clip-path="url(#clip0_1765_82664)"><path d="M26 8.5V16.0263H20.2471L20.248 16.9737C20.248 19.0674 18.5478 20.7632 16.4485 20.7632H14.8232L14.5224 21.5L15.4217 23.0145C15.72 23.4456 15.6117 24.0358 15.1795 24.3323C15.0209 24.4413 14.8328 24.5 14.64 24.5H6L8.19705 16.9509L6 16.0263L9.79947 8.5H26ZM24.1003 10.3947H11.0343L8.5219 15.0316L10.4074 15.8245L8.38797 22.6053H12.8315L12.5171 21.5L14.5108 14.1316H24.1003V10.3947ZM18.3473 16.0263H16.0733L15.512 18.8684H16.4485C17.4982 18.8684 18.3483 18.0205 18.3483 16.9737L18.3473 16.0263Z" fill="white"/></g><defs><clipPath id="clip0_1765_82664"><rect width="24" height="24" fill="white" transform="translate(4 4)"/></clipPath></defs></svg>',
  )}`,
};

const FirearmIcon = (isLegal: boolean) =>
  L.icon({
    iconUrl: isLegal ? MarkerIcons.Legal : MarkerIcons.Illegal,
    iconSize: [32, 39],
    iconAnchor: [16, 39],
    popupAnchor: [0, 0],
  });

interface IFirearmsLocationMap {
  firearms: IFirearm[];
  showLegalityBar: boolean;
  defaultLegality: string | undefined;
  isOnline: boolean;
  latitude?: number;
  longitude?: number;
  legalityUniqueId?: string;

  updateLegality(id: string): void;
}

export const LEGAL_ID = 'd90f05bf-6baf-4f86-9ca3-9501af6901ea';

const FirearmsLocationMap = ({
  firearms,
  updateLegality,
  defaultLegality = '',
  showLegalityBar = true,
  latitude,
  longitude,
  legalityUniqueId,
  isOnline,
}: IFirearmsLocationMap) => {
  (window as any).xFirearms = firearms; // fix for leaflet event function/no access to props

  const { legality, getLegality } = useDictionary();
  const mapRef = useRef<L.Map | null>(null);
  const [fullScreenState, setFullScreenState] = useState<boolean>(false);
  const legalityRef = useRef('');
  const { selectedLanguage } = useLang();

  const url = isOnline
    ? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    : `${process.env.REACT_APP_MAP_TILE_URL}tile/{z}/{x}/{y}.png`;

  useEffect(() => {
    (async function init() {
      legalityRef.current = defaultLegality;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function initDictionaries() {
      await getLegality();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const ChangeView = () => {
    const map = useMap();
    if (map) {
      mapRef.current = map;
    }
    const bounds: [number, number][] = [];
    if (!latitude && !longitude) {
      firearms.map((firearm) => {
        if (firearm.latitude && firearm.longitude) {
          bounds.push([firearm.latitude, firearm.longitude]);
        }
        return false;
      });
    }
    if (latitude && longitude) {
      bounds.push([latitude, longitude]);
    }
    if (bounds.length) {
      map.fitBounds(bounds);
    }
    if (map.getZoom() > 10) {
      map.setZoom(10);
    }
    return null;
  };

  const zoomMap = (isZoomIn: boolean) => {
    if (mapRef.current) {
      mapRef.current.setZoom(
        isZoomIn ? mapRef.current.getZoom() + 1 : mapRef.current.getZoom() - 1,
      );
    }
  };

  const toggleFullScreen = () => {
    if (mapRef.current) {
      mapRef.current.toggleFullscreen();
      setFullScreenState((prevState) => !prevState);
    }
  };

  const updateCurrentLegality = (value: string) => {
    legalityRef.current = value;
    updateLegality(value);
  };

  const getClusterClassName = () => {
    if (!legalityRef.current) {
      return '';
    }
    return legalityRef.current === LEGAL_ID
      ? 'custom-marker-cluster-legal'
      : 'custom-marker-cluster-illegal';
  };

  const createClusterCustomIcon = (cluster: any) =>
    L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: `custom-marker-cluster ${getClusterClassName()}`,
      iconSize: L.point(56, 56, true),
    });

  const showFirearmDataSidebar = (markers: any[]) => {
    const firearmsIds = markers.map((marker) => marker.options.alt.split(',')[0]);
    const firearmsList = (window as any).xFirearms;
    SearchFilterStore.setFirearms(
      firearmsList.filter((firearm: IFirearm) => firearmsIds.includes(firearm.uniqueId)),
    );
  };

  const showTransactionFirearmsDataSidebar = () => {
    const firearmsList = (window as any).xFirearms;
    SearchFilterStore.setFirearms(firearmsList);
  };

  const markers = useMemo(
    () =>
      firearms.map(
        (firearm) =>
          firearm.latitude &&
          firearm.longitude && (
            <Marker
              key={firearm.uniqueId}
              alt={`${firearm.uniqueId},${firearm.legalityUniqueId}`}
              position={[firearm.latitude, firearm.longitude]}
              icon={FirearmIcon(firearm.legalityUniqueId === LegalityTypes.LEGAL)}
              eventHandlers={{
                click: (e) => {
                  showFirearmDataSidebar([e.target]);
                },
                mouseover: (e) => {
                  e.target.setIcon(
                    L.icon({
                      iconUrl:
                        e.target.options.alt.split(',')[1] === LegalityTypes.LEGAL
                          ? MarkerIcons.LegalHover
                          : MarkerIcons.IllegalHover,
                      iconSize: [32, 39],
                      iconAnchor: [16, 39],
                      popupAnchor: [0, 0],
                    }),
                  );
                },
                mouseout: (e) => {
                  e.target.setIcon(
                    L.icon({
                      iconUrl:
                        e.target.options.alt.split(',')[1] === LegalityTypes.LEGAL
                          ? MarkerIcons.Legal
                          : MarkerIcons.Illegal,
                      iconSize: [32, 39],
                      iconAnchor: [16, 39],
                      popupAnchor: [0, 0],
                    }),
                  );
                },
              }}
            />
          ),
      ),
    [firearms],
  );

  return (
    <StyledLocationMapWrapper>
      <FirearmDataSidebar />
      <StyledMapContainer scrollWheelZoom zoomControl={false}>
        {showLegalityBar && (
          <MapLegalityDropdown
            updateLegality={updateCurrentLegality}
            legality={legality}
            label="registeredFirearms.firearmsLocation.legality.label"
          />
        )}
        <ChangeView />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={url}
        />
        <MarkerClusterGroup
          chunkedLoading
          showCoverageOnHover={false}
          iconCreateFunction={createClusterCustomIcon}
          onClick={(e: any) => {
            showFirearmDataSidebar(e.layer.getAllChildMarkers());
          }}
        >
          {!latitude && markers != null && markers}
          {latitude && longitude && (
            <Marker
              key={`${latitude}-${longitude}`}
              alt={`$${latitude}-${longitude},${legalityUniqueId}`}
              position={[latitude, longitude]}
              icon={FirearmIcon(legalityUniqueId === LEGAL_ID)}
              eventHandlers={{
                click: () => {
                  showTransactionFirearmsDataSidebar();
                },
                mouseover: (e) => {
                  e.target.setIcon(
                    L.icon({
                      iconUrl:
                        e.target.options.alt.split(',')?.[1] === LEGAL_ID
                          ? MarkerIcons.LegalHover
                          : MarkerIcons.IllegalHover,
                      iconSize: [32, 39],
                      iconAnchor: [16, 39],
                      popupAnchor: [0, 0],
                    }),
                  );
                },
                mouseout: (e) => {
                  e.target.setIcon(
                    L.icon({
                      iconUrl:
                        e.target.options.alt.split(',')?.[1] === LEGAL_ID
                          ? MarkerIcons.Legal
                          : MarkerIcons.Illegal,
                      iconSize: [32, 39],
                      iconAnchor: [16, 39],
                      popupAnchor: [0, 0],
                    }),
                  );
                },
              }}
            />
          )}
        </MarkerClusterGroup>
        <ButtonZoomIn
          icon={EIconTypes.plus1}
          variant={EButtonVariants.outlined}
          onClick={() => zoomMap(true)}
        />
        <ButtonZoomOut
          icon={EIconTypes.minus}
          variant={EButtonVariants.outlined}
          onClick={() => zoomMap(false)}
        />
        <ButtonFullScreen
          icon={fullScreenState ? EIconTypes.fullScreenExit : EIconTypes.fullScreen}
          variant={EButtonVariants.outlined}
          onClick={() => toggleFullScreen()}
        />
      </StyledMapContainer>
    </StyledLocationMapWrapper>
  );
};

export { FirearmsLocationMap };
