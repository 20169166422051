import { IRestApiResponseError } from 'models/apiClient';

export const handleRestApiException = (err: IRestApiResponseError) => {
  let errorMessage;

  switch (err.response.status) {
    case 400:
    case 403:
    case 404:
    case 410:
      errorMessage = Array.isArray(err.response.data.errorMessages)
        ? err.response.data.errorMessages[0]
        : err.response.data.message;
      break;
    case 401:
    case 500:
      errorMessage = err.response.data.error;
      break;
    default:
      errorMessage = '';
  }
  return errorMessage;
};
