import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useLang } from 'models/langContext';
import { FC_SYSTEM_APP_LANG } from 'requests/language';
import { useAppLanguageCode } from 'hooks/useAppLanguageCode';
import { ELanguageUniqueId } from 'models/language';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { StyledLanguageSelect, StyledMenuItem } from './styles';

interface ILanguageSelect {
  isVisible?: boolean;
}

const LanguageSelect = ({ isVisible = false }: ILanguageSelect) => {
  const [open, setOpen] = React.useState(false);
  const [country, setCountry] = React.useState('');

  const { languages, changeLanguage, setShowKeyTranslations, showKeyTranslations } = useLang();
  const { appLanguageCode } = useAppLanguageCode();
  const { hasPermissions } = usePermission();

  useEffect(() => {
    const selectedLanguage = localStorage.getItem(FC_SYSTEM_APP_LANG);
    if (showKeyTranslations) {
      setCountry('showTranslationKeys');
    } else if (selectedLanguage) {
      setCountry(selectedLanguage);
    } else if (languages.length) {
      setCountry(appLanguageCode);
    }
  }, [languages, appLanguageCode]);

  const handleChange = (event: any) => {
    setCountry(event.target.value);
    if (event.target.value === 'showTranslationKeys') {
      const selectedLang = languages.find((lang) => lang.uniqueId === ELanguageUniqueId.EN);
      if (selectedLang) {
        changeLanguage(selectedLang);
        localStorage.setItem(FC_SYSTEM_APP_LANG, selectedLang.code);
        setShowKeyTranslations(true);
      }
    } else {
      setShowKeyTranslations(false);
      const selectedLang = languages.find((lang) => lang.code === event.target.value);
      if (selectedLang) {
        changeLanguage(selectedLang);
        localStorage.setItem(FC_SYSTEM_APP_LANG, selectedLang.code);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!isVisible) {
    return null;
  }

  const languagesWithTranslationKeys = [
    ...languages,
    {
      uniqueId: 'showTranslationKeys',
      name: 'Translation Keys',
      code: 'showTranslationKeys',
      defaultLanguage: false,
    },
  ];

  const handleLanguageData = () => {
    if (hasPermissions([EPermission.MANAGE_SETTINGS])) {
      return languagesWithTranslationKeys;
    }
    return languages;
  };

  return (
    <StyledLanguageSelect
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={country}
      name="country"
      onChange={handleChange}
      inputProps={{
        id: 'open-select',
      }}
      MenuProps={{ sx: { marginTop: -1 } }}
    >
      {handleLanguageData().map((option) => (
        <StyledMenuItem value={option.code} key={option.uniqueId}>
          <div>
            <Typography variant="body2">{option.name}</Typography>
          </div>
        </StyledMenuItem>
      ))}
    </StyledLanguageSelect>
  );
};

export { LanguageSelect };
