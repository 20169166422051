import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LegalEntityAncillaryGroupForm } from 'pages/LegalEntitiesPage/LegalEntityAncillaryGroupForm';
import { Box } from '@mui/material';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';

const LegalEntityEditAncillaryGroupPage = () => {
  const { id = '', groupId } = useParams<{ id: string; groupId: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const {
    handleEditAncillaryGroup,
    getAncillaryGroupDetail,
    ancillaryGroupDetail,
    navigateToDetails,
  } = useLegalEntities();
  const { legalEntityAlert, nextPageAlert, clearAlert, setAlert } = useAlert();
  const [groupName, setGroupName] = useState<string>('');
  const { t } = useTranslations();
  const { state: routerState }: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function initDictionaries() {
      await getAncillaryGroupDetail(id, groupId as string);
    })();
  }, [id, groupId]);

  useEffect(() => {
    if (ancillaryGroupDetail) {
      setGroupName(ancillaryGroupDetail.name);
    }
  }, [ancillaryGroupDetail]);

  const handleSubmit = async () => {
    try {
      await handleEditAncillaryGroup(
        id,
        groupName,
        groupId as string,
        routerState?.returnLink ?? '',
      );
    } catch (e: any) {
      setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };
  const handleCancel = () => {
    if (routerState && routerState.returnLink) {
      navigate(routerState.returnLink);
    } else {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: 'individual',
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=groups`,
          },
          t('editGroup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('editGroup.header')}</StyledPageTitle>

      <FormWrapper>
        <LegalEntityAncillaryGroupForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          onNameChange={(name: string) => {
            setGroupName(name);
          }}
          initialFormValues={{
            name: groupName,
            uniqueId: id,
            ancillariesIds: [],
          }}
        />
      </FormWrapper>
      {legalEntityAlert && (
        <Box mt={2}>
          <Alert text={legalEntityAlert.text} variant={legalEntityAlert.variant} />
        </Box>
      )}
      {nextPageAlert && (
        <Box mt={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { LegalEntityEditAncillaryGroupPage };
