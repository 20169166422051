import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React, { PropsWithChildren } from 'react';
import { useLicenceContext } from 'models/licenceContext';

const RECAPTCHA_SITE_KEY =
  process.env.REACT_APP_RECAPTCHA_SITE_KEY || '6LeVuUUpAAAAALkjFqVKYmiFSbzfnhBAjXuuAjnQ';

export const ReCaptchaProvider = ({ children }: PropsWithChildren<{}>) => {
  const { isOnlineLicence } = useLicenceContext();

  if (!isOnlineLicence()) {
    return <div>{children}</div>;
  }

  return (
    <GoogleReCaptchaProvider language="en" reCaptchaKey={RECAPTCHA_SITE_KEY}>
      {children}
    </GoogleReCaptchaProvider>
  );
};
