import React, { useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { Accordion } from 'components/molecules/Accordion';
import { Box } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import AncillariesReferenceTablePage from 'pages/AncillariesPage/AncillariesReferenceTablePage';
import AccessoriesPage from 'pages/AncillariesPage/AccessoriesPage';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import { EChipsVariants } from 'components/atoms/Chips';
import { ILLEGAL_ID, INACTIVE_ID } from 'pages/Firearms';
import AmmunitionPage from './AmmunitionPage';
import ComponentsPage from './ComponentsPage';

const AncillariesPage = () => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const [accessoryAccordionExpanded, setAccessoryAccordionExpanded] = useState<
    boolean | undefined
  >(undefined);
  const [componentsAccordionExpanded, setComponentsAccordionExpanded] = useState<
    boolean | undefined
  >(undefined);

  const renderDataColor = (data: ITableDataSource) => {
    if (data.activityUniqueId === INACTIVE_ID) {
      return {
        background: colors.gray200,
        border: colors.gray400,
        stateField: EChipsVariants.inactive,
      };
    }
    if (data.legalityUniqueId === ILLEGAL_ID) {
      return {
        background: colors.error15,
        border: colors.error100,
        stateField: EChipsVariants.error,
      };
    }
    return {
      background: colors.gray100,
      border: colors.gray200,
      stateField: EChipsVariants.primary,
    };
  };

  return (
    <>
      <Breadcrumbs items={[t('module.ancillaries.name')]} />
      <StyledPageTitle variant="h4">{t('module.ancillaries.name')}</StyledPageTitle>
      <Box mt={2}>
        {hasPermissions(
          [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
          true,
        ) && (
          <div>
            <Accordion
              title={t('ancillaries.accessories.accordion.name')}
              name="accessories"
              key="accessories"
              expanded={accessoryAccordionExpanded}
              onChange={() => () => {
                setAccessoryAccordionExpanded(undefined);
              }}
            >
              <AccessoriesPage
                renderDataColor={renderDataColor}
                setAccessoryAccordionExpanded={setAccessoryAccordionExpanded}
              />
            </Accordion>
            <Accordion
              title={t('ancillaries.ammunition.accordion.name')}
              name="ammunition"
              key="ammunition"
            >
              <AmmunitionPage renderDataColor={renderDataColor} />
            </Accordion>
            <Accordion
              title={t('ancillaries.components.accordion.name')}
              name="components"
              key="components"
              expanded={componentsAccordionExpanded}
              onChange={() => () => {
                setComponentsAccordionExpanded(undefined);
              }}
            >
              <ComponentsPage
                renderDataColor={renderDataColor}
                setComponentsAccordionExpanded={setComponentsAccordionExpanded}
              />
            </Accordion>
          </div>
        )}
        <Accordion
          title={t('ancillaries.ancillariesReferenceTable.accordion.name')}
          name="ancillaries-reference-table"
          key="ancillaries-reference-table"
        >
          <AncillariesReferenceTablePage />
        </Accordion>
      </Box>
    </>
  );
};

export default AncillariesPage;
