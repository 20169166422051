import { IApiClientResponse, isResponseError, TResponse } from 'models/apiClient';
import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { handleRestApiException } from 'utils/handleRestApiException';
import {
  IChangePasswordBodyRequest,
  IChangePasswordResponse,
  IForgotPasswordBodyRequest,
  IForgotPasswordResponse,
  IGeneratePasswordBodyRequest,
  IGeneratePasswordResponse,
} from 'models/password';
import { EActionTypes } from 'state/actions/password';
import { EActionTypes as ELogInActionTypes } from 'state/actions/auth';
import { FC_SYSTEM_AUTH_ACCESS_TOKEN, FC_SYSTEM_AUTH_REFRESH_TOKEN } from 'requests/auth';
import { IAuthResponse, IValidateResetPasswordLinkRequest } from 'models/auth';

const changePasswordRequest = async (
  body: IChangePasswordBodyRequest,
  query: any,
): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.put<TResponse<IChangePasswordResponse>>(
      ApiEndpoints.CHANGE_PASSWORD(`?${query.toString()}`),
      body,
    );
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const changePassword = async (
  dispatch: any,
  body: IChangePasswordBodyRequest,
  query: any,
  successCallback: (res: any) => void,
  failureCallback: (error: string) => void,
): Promise<TResponse<IAuthResponse>> => {
  dispatch({
    type: EActionTypes.CHANGE_PASSWORD,
  });

  const res = await changePasswordRequest(body, query);
  try {
    if (res.success) {
      const { accessToken, refreshToken } = res.data;
      if (accessToken) {
        localStorage.setItem(FC_SYSTEM_AUTH_ACCESS_TOKEN, accessToken);
        localStorage.setItem(FC_SYSTEM_AUTH_REFRESH_TOKEN, refreshToken);
      }
      dispatch({
        type: EActionTypes.CHANGE_PASSWORD_SUCCESS,
      });
      dispatch({
        type: ELogInActionTypes,
        payload: { accessToken, refreshToken },
      });
      successCallback(res.data);
    } else {
      dispatch({
        type: EActionTypes.CHANGE_PASSWORD_FAILURE,
      });
      failureCallback(res.responseError || '');
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

const forgotPasswordRequest = async (
  body: IForgotPasswordBodyRequest,
): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.post<TResponse<IForgotPasswordResponse>>(
      ApiEndpoints.FORGOT_PASSWORD(),
      body,
    );
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const forgotPassword = async (
  dispatch: any,
  body: IForgotPasswordBodyRequest,
  successCallback: () => void,
  failureCallback: () => void,
): Promise<TResponse<IForgotPasswordResponse>> => {
  dispatch({
    type: EActionTypes.FORGOT_PASSWORD,
  });

  const res = await forgotPasswordRequest(body);

  try {
    if (res.success) {
      dispatch({
        type: EActionTypes.FORGOT_PASSWORD_SUCCESS,
        payload: { isForgotPasswordLoading: true },
      });
      successCallback();
    } else {
      dispatch({
        type: EActionTypes.FORGOT_PASSWORD_FAILURE,
      });
      failureCallback();
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

const generatePasswordRequest = async (
  body: IGeneratePasswordBodyRequest,
): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.post<TResponse<IGeneratePasswordResponse>>(
      ApiEndpoints.GENERATE_PASSWORD(),
      body,
    );
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const generatePassword = async (
  dispatch: any,
  body: IGeneratePasswordBodyRequest,
  successCallback: () => void,
): Promise<TResponse<IGeneratePasswordResponse>> => {
  dispatch({
    type: EActionTypes.GENERATE_PASSWORD,
  });

  const res = await generatePasswordRequest(body);
  try {
    if (res.success) {
      dispatch({
        type: EActionTypes.GENERATE_PASSWORD_SUCCESS,
      });
      successCallback();
    } else {
      dispatch({
        type: EActionTypes.GENERATE_PASSWORD_FAILURE,
      });
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

export const validateResetPasswordLink = async (token: IValidateResetPasswordLinkRequest) => {
  const { data } = await apiClient.post<TResponse<any>>(
    `${ApiEndpoints.VALIDATE_RESET_PASSWORD_LINK()}?token=${token}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};
