import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';

export const mapCustomFieldToLabel = (type: string) => {
  switch (type) {
    case ECustomFieldObjectType.LE_INDIVIDUAL:
      return 'customFields.individual.label';
    case ECustomFieldObjectType.LE_ORGANIZATION:
      return 'customFields.organisation.label';
    case ECustomFieldObjectType.FIREARM:
      return 'customFields.firearm.label';
    case ECustomFieldObjectType.TRANSACTION:
      return 'customFields.transaction.label';
    case ECustomFieldObjectType.AN_ACCESSORY:
      return 'customFields.ancillary.accessory.label';
    case ECustomFieldObjectType.AN_AMMUNITION:
      return 'customFields.ancillary.ammunition.label';
    case ECustomFieldObjectType.AN_COMPONENT:
      return 'customFields.ancillary.component.label';

    default:
      return '';
  }
};

export const mapCustomFieldTypeToLabel = (type: string) => {
  switch (type) {
    case ECustomFieldType.TEXT:
      return 'customFields.type.text.label';
    case ECustomFieldType.BOOL:
      return 'customFields.type.boolean.label';
    case ECustomFieldType.DATE:
      return 'customFields.type.date.label';
    default:
      return '';
  }
};
