import { IReducerAction } from 'models/context';
import { EActionTypes } from 'state/actions/password';

interface IState {
  isChangePasswordLoading: boolean;
  isForgotPasswordLoading: boolean;
}

export const initialState: IState = {
  isChangePasswordLoading: false,
  isForgotPasswordLoading: false,
};

export const reducer = (state: IState, { type }: IReducerAction) => {
  switch (type) {
    case EActionTypes.CHANGE_PASSWORD: {
      return {
        ...state,
        isChangePasswordLoading: true,
      };
    }
    case EActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangePasswordLoading: false,
      };
    }
    case EActionTypes.CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        isChangePasswordLoading: false,
      };
    }
    case EActionTypes.FORGOT_PASSWORD: {
      return {
        ...state,
        isForgotPasswordLoading: true,
      };
    }
    case EActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,

        isForgotPasswordLoading: false,
      };
    }
    case EActionTypes.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,

        isForgotPasswordLoading: false,
      };
    }
    default:
      return state;
  }
};
