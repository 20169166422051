import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { IPermission } from 'models/permissions';

export const fetchPermissions = async () => {
  const { data } = await apiClient.get<TResponse<IPermission[]>>(
    ApiEndpoints.GET_PERMISSIONS(),
  );
  if (isResponseError(data)) {
    return [];
  }

  return data;
};
