import React, { useEffect, useRef } from 'react';
import { EPerPages } from 'models/table';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components/atoms/Loader';
import { Pagination } from 'components/molecules/Pagination';
import { Table } from 'components/molecules/Table';
import {
  AncillariesReferenceTableComponentsColumns,
  mapARTComponentsToDataSource,
} from 'pages/AncillariesPage/helpers';
import { ERouteLinks } from 'models/route';
import { EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import { CardList } from 'components/molecules/CardList';
import { AncillariesReferenceComponentsCard } from '../AncillariesReferenceComponentsCard';

interface IAncillariesReferenceComponents {
  setAncillariesReferenceSort: (arg: any) => void;
  perPageAncillariesReference: EPerPages;
  setPerPageAncillariesReference: (arg: any) => void;
  currentPageAncillariesReference: number;
  setCurrentPageAncillariesReference: (arg: any) => void;
  isAncillariesReferenceLoading: boolean | null;
  paginatorAncillariesReference: any;
  ancillariesReference: any[];
  isTransactionPage?: boolean;
  LEId?: string;
  type?: string | null;
  isPaginationLoading: boolean;
  isQueryAncillariesReferenceLoading: boolean;
  isSortLoaded: boolean;
  isPaginationVisible: boolean;
}

const AncillariesReferenceComponents = ({
  setAncillariesReferenceSort,
  perPageAncillariesReference,
  setPerPageAncillariesReference,
  currentPageAncillariesReference,
  setCurrentPageAncillariesReference,
  isAncillariesReferenceLoading,
  paginatorAncillariesReference,
  ancillariesReference,
  isTransactionPage = false,
  LEId,
  type,
  isPaginationLoading,
  isQueryAncillariesReferenceLoading,
  isSortLoaded,
  isPaginationVisible,
}: IAncillariesReferenceComponents) => {
  const { isDesktop } = useThemeBreakpoint();
  const navigate = useNavigate();

  const scrollPosition = useRef(0);

  useEffect(() => {
    if (isSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [isSortLoaded]);

  return (
    <div>
      {isAncillariesReferenceLoading || isQueryAncillariesReferenceLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={AncillariesReferenceTableComponentsColumns({
                isTransactionPage,
                handleViewTransaction: (id: string) =>
                  navigate(
                    `${ERouteLinks.LegalEntityViewAncillary.replace(':leId', LEId ?? '')
                      .replace(':id', id)
                      .replace(
                        ':type',
                        EArtAncillaryTypeName.COMPONENTS.toLowerCase(),
                      )}?type=${type}&expand=ancillaries`,
                  ),
                handleEdit: (id: string) =>
                  navigate(
                    ERouteLinks.EditAncillaries.replace(':id', id).replace(
                      ':type',
                      EArtAncillaryTypeName.COMPONENTS.toLowerCase(),
                    ),
                  ),
                handleSelect: (ancillary: any) =>
                  navigate(
                    `${ERouteLinks.LegalEntityAddAncillary.replace(
                      ':id',
                      LEId ?? '',
                    )}?type=${type}&expand=ancillaries`,
                    {
                      state: {
                        ancillary,
                        prevRoute: 'prevRoute',
                      },
                    },
                  ),
                LEId,
                type,
              })}
              dataSource={mapARTComponentsToDataSource(
                ancillariesReference,
                EArtAncillaryTypeName.COMPONENTS,
              )}
              onSort={(it) => {
                setAncillariesReferenceSort(it);
                scrollPosition.current = window.scrollY;
              }}
              translationsKeys={{
                noResults: 'ancillaries.accordion.noResults.text',
              }}
              isSortLoaded={isSortLoaded}
              customPaddingIfNoData="30px"
            />
          ) : (
            <CardList
              items={mapARTComponentsToDataSource(
                ancillariesReference,
                EArtAncillaryTypeName.COMPONENTS,
              )}
              render={(data, index) => (
                <AncillariesReferenceComponentsCard
                  data={data}
                  key={index}
                  handleView={(id: string) => {
                    navigate(
                      ERouteLinks.ViewAncillaries.replace(':id', id).replace(
                        ':type',
                        EArtAncillaryTypeName.COMPONENTS.toLowerCase(),
                      ),
                    );
                  }}
                  handleEdit={(id: string) => {
                    navigate(
                      ERouteLinks.EditAncillaries.replace(':id', id).replace(
                        ':type',
                        EArtAncillaryTypeName.COMPONENTS.toLowerCase(),
                      ),
                    );
                  }}
                  handleSelect={() =>
                    navigate(
                      `${ERouteLinks.LegalEntityAddAncillary.replace(
                        ':id',
                        LEId ?? '',
                      )}?type=${type}&expand=ancillaries`,
                      {
                        state: {
                          ancillary: data,
                          prevRoute: 'prevRoute',
                        },
                      },
                    )
                  }
                  isTransactionPage={isTransactionPage}
                />
              )}
            />
          )}

          {paginatorAncillariesReference && (
            <Pagination
              count={paginatorAncillariesReference?.totalElements}
              perPage={perPageAncillariesReference}
              onChangePage={(page) => {
                setCurrentPageAncillariesReference(page);
              }}
              onChangePerPage={(value) => {
                setPerPageAncillariesReference(value);
              }}
              current={currentPageAncillariesReference}
              isLoading={isPaginationLoading}
              isVisible={isPaginationVisible && isSortLoaded}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AncillariesReferenceComponents;
