import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { colors } from 'theme/colors';
import UploadDropzoneField from 'components/atoms/UploadDropzoneField';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EIconTypes } from 'constants/Icons';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useReplaceLicenceFormSchema } from 'pages/LicenceDetailsPage/ReplaceLicencePage/schemaValidation';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { useLicence } from 'pages/LicenceDetailsPage/hooks';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Box } from '@mui/system';
import { StyledHeading } from './styles';

interface IReplaceLicenceFormValues {
  uploadedFile?: string;
  licenceData: string;
}

const initialFormValues: IReplaceLicenceFormValues = {
  uploadedFile: '',
  licenceData: '',
};

export const ReplaceLicencePage = () => {
  const { t } = useTranslations();
  const { schema } = useReplaceLicenceFormSchema();
  const [clearFiles, setClearFiles] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { replaceLicenceHandler } = useLicence();
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IReplaceLicenceFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmitForm = async (data: IReplaceLicenceFormValues) => {
    setIsSubmitLoading(true);

    try {
      await replaceLicenceHandler(data.licenceData);
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('licenceReplace.licenceSaved.alert.text'),
        variant: EAlertVariants.success,
      });
      navigate(ERouteLinks.LicenceDetails);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response?.data?.message || t('licenceReplace.licenceSavingProblem.alert.text'),
        variant: EAlertVariants.error,
      });
    }

    setIsSubmitLoading(false);
  };

  const clearAttachment = () => {
    setClearFiles(true);
    setValue('uploadedFile', '');
    setValue('licenceData', '');
  };

  const readFile = (files: any[]) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      setValue('licenceData', event?.target?.result as string);
    });
    reader.readAsText(files[0]);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.administration.name'),
            route: ERouteLinks.Administration,
          },
          {
            label: t('licenceDetails.header'),
            route: ERouteLinks.LicenceDetails,
          },
          t('licenceReplace.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('licenceReplace.header')}</StyledPageTitle>
      <Typography variant="body2" sx={{ marginBottom: '10px', marginTop: '20px' }}>
        {t('licenceReplace.subtitle')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container item rowSpacing={0} columnSpacing={2} lg={12}>
          <StyledHeading>
            <Typography variant="subtitle2" color={colors.gray400}>
              {t('licenceReplace.licenceKey.title')}
            </Typography>
          </StyledHeading>

          <Grid item container rowSpacing={2} lg={8}>
            <Grid item container rowSpacing={0} columnSpacing={2} xs={12}>
              <Grid item xs={12}>
                <UploadDropzoneField
                  onUpload={(files: any) => {
                    readFile(files);
                    setValue('uploadedFile', files[0].name);
                  }}
                  shouldClearFiles={clearFiles}
                  setFilesCleared={setClearFiles}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={2} xs={12} sx={{ alignItems: 'center' }}>
              <Grid item xs="auto" sx={{ width: 'calc(100% - 48px) !important' }}>
                <FormInput
                  name="uploadedFile"
                  label={t('licenceReplace.uploadedFile.title')}
                  control={control}
                  errors={errors}
                  disabled
                />
              </Grid>
              <Grid item xs="auto" sx={{ marginTop: '0px' }}>
                <Button
                  icon={EIconTypes.delete}
                  variant={EButtonVariants.outlined}
                  sizePX={EButtonSizesPX.px32}
                  onClick={() => clearAttachment()}
                />
              </Grid>
            </Grid>

            {errors?.licenceData && (
              <Grid item lg={12} xs={12} style={{ marginTop: '-40px' }}>
                <FormErrorLabel label={errors?.licenceData?.message?.toString() as string} />
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('general.upload.button')}
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={4}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.LicenceDetails)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      {samePageAlert && (
        <Box mt={2} sx={{ marginBottom: '10px', marginTop: '20px' }}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};
export default ReplaceLicencePage;
