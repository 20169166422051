import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { IFRTCalibreFormValues, IFRTDetails, IFRTNotes, IFRTSpecifications } from 'models/frt';
import { IFRTLocalPatchRequest, IFRTLocalUpdateRequest } from 'models/form';

const getFRTDetails = async (id: string) => {
  const { data } = await apiClient.get<TResponse<IFRTDetails>>(
    ApiEndpoints.GET_FRT_DETAILS(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFRTSpecifications = async (id: string) => {
  const { data } = await apiClient.get<TResponse<IFRTSpecifications[]>>(
    ApiEndpoints.GET_FRT_SPECIFICATIONS(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFRTNotes = async (id: string) => {
  const { data } = await apiClient.get<TResponse<IFRTNotes[]>>(ApiEndpoints.GET_FRT_NOTES(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFRTCheckIsUsed = async (id: string) => {
  const { data } = await apiClient.get<TResponse<boolean>>(
    ApiEndpoints.GET_FRT_CHECK_IS_USED(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createFRTLocalSpecification = async (id: string, payload: IFRTCalibreFormValues) => {
  const { data } = await apiClient.post(
    ApiEndpoints.CREATE_FRT_LOCAL_SPECIFICATION(id),
    payload,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const updateFRTLocalSpecification = async (
  id: string,
  specificationId: string,
  payload: IFRTCalibreFormValues,
) => {
  const { data } = await apiClient.put(
    ApiEndpoints.UPDATE_FRT_LOCAL_SPECIFICATION(id, specificationId),
    payload,
  );
  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const addFRTLocal = async (localFRT: IFRTLocalUpdateRequest) => {
  const { data } = await apiClient.post(ApiEndpoints.ADD_FRT_LOCAL(), localFRT);

  return !isResponseError(data);
};

const updateFRTLocal = async (uniqueId: string, localFRT: IFRTLocalUpdateRequest) => {
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_FRT_LOCAL(uniqueId), localFRT);

  return !isResponseError(data);
};

const patchFRTLocal = async (uniqueId: string, localFRT: IFRTLocalPatchRequest) => {
  const { data } = await apiClient.patch(ApiEndpoints.UPDATE_FRT_LOCAL(uniqueId), localFRT);

  return !isResponseError(data);
};

export {
  getFRTDetails,
  getFRTSpecifications,
  getFRTNotes,
  getFRTCheckIsUsed,
  createFRTLocalSpecification,
  updateFRTLocalSpecification,
  addFRTLocal,
  updateFRTLocal,
  patchFRTLocal,
};
