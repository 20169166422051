import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { AttachmentForm } from 'pages/Firearms/AttachmentForm';
import { getAttachmentAvailableTransactionTypes } from 'requests/firearm';
import { useFirearms } from 'pages/Firearms/hooks';
import { IShortTransaction } from 'models/transaction';

const RegisteredFirearmAddAttachmentPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [usedTransactions, setUsedTransactions] = useState<IShortTransaction[]>([]);
  const { t } = useTranslations();
  const { handleAddAttachment } = useFirearms();

  useEffect(() => {
    (async function getAddAttachmentDataInit() {
      if (id) {
        const response = await getAttachmentAvailableTransactionTypes(id);
        if (response) {
          setUsedTransactions(response);
        }
      }
    })();
  }, [id]);

  const handleSubmit = async (data: any) => {
    const result = await handleAddAttachment(id, data);
    if (result) {
      navigate(
        `${ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string)}?expand=attachments`,
      );
    }
  };

  const handleCancel = () => {
    navigate(
      `${ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string)}?expand=attachments`,
    );
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.accordion.name'),
            route: ERouteLinks.Firearms,
          },
          {
            label: t('registeredFirearms.firearmsDetails.header'),
            route: `${ERouteLinks.ViewRegisteredFirearm.replace(
              ':id',
              id as string,
            )}?expand=attachments`,
          },
          t('addAttachment.addAttachment.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addAttachment.addAttachment.header')}</StyledPageTitle>

      <FormWrapper>
        <AttachmentForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          usedTransactions={usedTransactions}
        />
      </FormWrapper>
    </>
  );
};

export { RegisteredFirearmAddAttachmentPage };
