import { useState } from 'react';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import {
  IApiKey,
  IApiKeyHistoryResponse,
  IApiKeyRequest,
  IApiKeyResponse,
} from 'models/apiKey';
import {
  changeStatusApiKey,
  createApiKey,
  deleteApiKey,
  getApiKey,
  getApiKeyHistory,
  getApiKeys,
  sendApiKeyEmail,
  updateApiKey,
} from 'requests/apiKeys';
import { useDictionary } from 'models/dictionaryContext';
import { EDictionaryTypes } from 'models/dictionary';

export const useApiKeys = () => {
  const [apiKeys, setApiKeys] = useState<IApiKey[]>([]);
  const [apiKey, setApiKey] = useState<IApiKeyResponse>();
  const [apiKeyHistory, setApiKeyHistory] = useState<IApiKeyHistoryResponse>();
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const { getApiKeyType, apiKeyType, phoneCode, getPhoneCode } = useDictionary();

  const loadDictionaries = async (types: EDictionaryTypes[]) => {
    if (types.includes(EDictionaryTypes.ApiKeyType)) {
      getApiKeyType();
    }
    if (types.includes(EDictionaryTypes.PhoneCode)) {
      getPhoneCode();
    }
  };

  const getApiKeysData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query = {},
    sort?: ISortOptions | null,
  ) => {
    const data = await getApiKeys(currentPage, perPage, query, sort);
    if (data) {
      const { content, ...paginatorData } = data;
      setApiKeys(content);
      setPaginator(paginatorData);
    } else {
      setApiKeys([]);
      setPaginator(null);
    }
  };

  const createApiKeyHandler = async (body: IApiKeyRequest) => createApiKey(body);

  const getApiKeyHandler = async (id: string) => {
    const data = await getApiKey(id);
    if (data) {
      setApiKey(data);
    }
  };

  const updateApiKeyHandler = async (id: string, body: IApiKeyRequest) =>
    updateApiKey(id, body);

  const changeApiKeyHandler = async (id: string, status: string) =>
    changeStatusApiKey(id, status);

  const getApiKeyHistoryHandler = async (id: string) => {
    const data = await getApiKeyHistory(id);
    if (data) {
      setApiKeyHistory(data);
    }
  };

  const deleteApiKeyHandler = async (id: string) => deleteApiKey(id);

  const sendApiKeyEmailHandler = async (id: string) => sendApiKeyEmail(id);

  return {
    apiKeys,
    paginator,
    getApiKeysData,
    loadDictionaries,
    apiKeyType,
    phoneCode,
    createApiKeyHandler,
    getApiKeyHandler,
    apiKey,
    updateApiKeyHandler,
    changeApiKeyHandler,
    apiKeyHistory,
    getApiKeyHistoryHandler,
    deleteApiKeyHandler,
    sendApiKeyEmailHandler,
  };
};
