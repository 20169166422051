import React from 'react';
import { StyledFormErrorLabel } from './styles';

interface IFormLabel {
  label: string;
}

const FormErrorLabel = ({ label }: IFormLabel) => (
  <StyledFormErrorLabel>{label}</StyledFormErrorLabel>
);

export default FormErrorLabel;
