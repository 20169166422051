import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useCalibreFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    caliber: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('%s', '64'))
      .trim(),
    barrelLength: yup
      .string()
      .required(t('general.field.validation.required'))
      .matches(/^[0-9]+$/, t('general.field.validation.onlyDigits'))
      .max(9, t('general.field.validation.maxDigits').replace('%s', '9'))
      .trim(),
    shots: yup
      .string()
      .required(t('general.field.validation.required'))
      .matches(/^[0-9]+$/, t('general.field.validation.onlyDigits'))
      .max(3, t('general.field.validation.maxDigits').replace('%s', '3'))
      .trim(),
    level: yup.string().required(t('general.field.validation.required')).trim(),
    legalClassification: yup.string().required(t('general.field.validation.required')).trim(),
    barrelType: yup.string().required(t('general.field.validation.required')).trim(),
  });
  return { schema };
};

export default useCalibreFormSchema;
