import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import { useDictionaries } from 'pages/DictionariesPage/hooks';
import { useAlert } from 'models/alertContext';
import { DictionaryViewTableCard } from 'pages/DictionariesPage/ViewDictionaryPage/DictionaryViewTableCard';
import { Alert } from 'components/atoms/Alert';
import { Table } from 'components/molecules/Table';
import { CardList } from 'components/molecules/CardList';
import { Pagination } from 'components/molecules/Pagination';
import { EPerPages, ESortDirectionTypes, ISortOptions } from 'models/table';
import { ActionTypes } from 'state/actions/alert';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { dictionaryViewTableColumns, mapDictionaryViewToDataSource } from '../helpers';

const ViewDictionaryPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { type } = useParams<{ type: EDictionaryTypes }>();
  const { dictionaryAlert, nextPageAlert, clearAlert } = useAlert();
  const { dictionary, dictionaryValues, getDictionaryData, paginator } = useDictionaries();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);
  const [sort, setSort] = useState<ISortOptions>({
    property: 'name',
    direction: ESortDirectionTypes.ASC,
  });
  const { isDesktop } = useThemeBreakpoint();
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  useEffect(() => {
    if (type) {
      (async function getDictionariesDataInit() {
        setIsPaginationLoading(true);
        await getDictionaryData(type, currentPage, perPage, sort);
        setIsPaginationLoading(false);
      })();
    }
  }, [type, currentPage, perPage, sort, selectedLanguage]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_DICTIONARY_ALERT);
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          { label: t('dictionaries.header'), route: ERouteLinks.Dictionaries },
          `${t('dictionary.header')} ${t('dictionaries.dictionariesTable.view.link')}`,
        ]}
      />
      <StyledPageTitle variant="h4">
        {`${t('dictionary.header')}: ${dictionary?.translatedType || ''}`}
      </StyledPageTitle>

      <Grid container justifyContent="end">
        <Grid item xs={12} lg={3} marginBottom={3}>
          {type && dictionary?.managedByAdmin && (
            <Button
              fullWidth
              label={t('dictionary.addValue.button')}
              variant={EButtonVariants.contained}
              onClick={() => navigate(ERouteLinks.AddDictionaryValue.replace(':type', type))}
              sx={{ mt: { xs: 1, md: 3.5 } }}
            />
          )}
        </Grid>
      </Grid>

      {dictionaryAlert && (
        <Alert text={dictionaryAlert.text} variant={dictionaryAlert.variant} />
      )}
      {nextPageAlert && <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />}
      {(dictionaryAlert || nextPageAlert) && <Grid sx={{ mb: 2 }} />}
      {isDesktop ? (
        <Table
          columns={dictionaryViewTableColumns({
            t,
            handleEdit: (idValue: string) =>
              navigate(
                ERouteLinks.EditDictionaryValue.replace(':id', idValue).replace(
                  ':type',
                  type ?? 'null',
                ),
              ),
          })}
          dataSource={mapDictionaryViewToDataSource(dictionaryValues)}
          onSort={setSort}
        />
      ) : (
        <CardList
          items={mapDictionaryViewToDataSource(dictionaryValues)}
          render={(data, index) => (
            <DictionaryViewTableCard data={data} type={type} key={index} />
          )}
        />
      )}

      {paginator && (
        <Pagination
          count={paginator?.totalElements}
          onChangePage={(page) => setCurrentPage(page)}
          current={currentPage}
          perPage={perPage}
          onChangePerPage={(value) => setPerPage(value)}
          isLoading={isPaginationLoading}
        />
      )}
    </>
  );
};

export default ViewDictionaryPage;
