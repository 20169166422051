import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useRolesFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup
    .object({
      name: yup
        .string()
        .required(t('general.field.validation.required'))
        .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
        .trim(),
      description: yup.string().max(1000, 'Max 1000 characters').trim(),
      formPermissionIds: yup.object(),
    })
    .test('permissionTest', '', (obj) => {
      const anyChosen = Object.keys(obj.formPermissionIds).some(
        (key) => obj?.formPermissionIds?.[key] === true,
      );
      if (anyChosen) {
        return true;
      }
      return new yup.ValidationError(
        t('general.field.validation.required'),
        null,
        'formPermissionIds',
      );
    });

  return { schema };
};

export default useRolesFormSchema;
