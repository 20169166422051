import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from 'theme/colors';

export const StyledDictionaryValueFormHeader = styled(Typography)`
  background: ${colors.primary15};
  height: 48px;
  width: 100%;
  font-size: 20px;
  border-radius: 4px;
  padding: 10px 20px;
`;
