import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { getLegalEntityComment } from 'requests/legalEntities';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { StyledDetailsWrapper } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage/styles';
import { ICommentData, ICommentsList } from 'models/comment';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

export interface ILegalEntityCommentsList extends ICommentsList {
  content: ILegalEntityCommentData[];
}

export interface ILegalEntityCommentData extends ICommentData {}

const LegalEntityDeleteCommentPage = () => {
  const { id, commentId } = useParams<{ id: string; commentId: string }>();
  const query = useQuery();
  const [legalEntityComment, setLegalEntityComment] =
    useState<ILegalEntityCommentData | null>();
  const { t } = useTranslations();

  const { handleDeleteComment } = useLegalEntities();
  const { navigateToDetails } = useLegalEntities();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const type: string = query.get('type') || '';
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function getLegalEntityCommentDataInit() {
      if (id && commentId) {
        const response = await getLegalEntityComment(id, commentId);
        if (response) {
          setLegalEntityComment(response);
        }
      }
    })();
  }, [id, commentId]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (legalEntityComment) {
        const result = await handleDeleteComment(
          legalEntityComment.uniqueId,
          legalEntityComment.hidden,
        );

        if (result.status === 204) {
          navigateToDetails();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  let legalEntityActionType: string = '';
  if (legalEntityComment) {
    legalEntityActionType = legalEntityComment.hidden
      ? t('enableComment.header')
      : t('disableComment.header');
  }

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=comments`,
          },
          `${legalEntityActionType ? `${legalEntityActionType}` : ''}`,
        ]}
      />
      {legalEntityComment && (
        <>
          <StyledPageTitle variant="h4">{`${legalEntityActionType}`} </StyledPageTitle>

          <Box my={3}>
            <Alert
              text={
                legalEntityComment.hidden
                  ? t('enableComment.info.text')
                  : t('disableComment.info.text')
              }
              variant={EAlertVariants.warning}
            />
          </Box>

          <StyledDetailsWrapper container direction="column" gap={3}>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('enableComment.title.text')}
              </Typography>
              <Typography variant="body2">{legalEntityComment.title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('enableComment.comment.text')}
              </Typography>
              <Typography variant="body2">{legalEntityComment.comment}</Typography>
            </Grid>
            <Grid container rowGap={3}>
              <Grid container item xs={12} lg={6} rowGap={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('enableComment.addedBy.text')}
                  </Typography>
                  <Typography variant="body2">
                    {`${legalEntityComment.createdByFirstName} ${legalEntityComment.createdByLastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('enableComment.date.text')}
                  </Typography>
                  <Typography variant="body2">
                    {formatDate(legalEntityComment.createdDate, longDateFormat)}
                  </Typography>
                </Grid>
              </Grid>
              {legalEntityComment.hidden && (
                <Grid container item xs={12} lg={6} rowGap={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('enableComment.disabledBy.text')}
                    </Typography>
                    <Typography variant="body2">{`${legalEntityComment.hiddenByFirstName} ${legalEntityComment.hiddenByLastName}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('enableComment.date.text')}
                    </Typography>
                    <Typography variant="body2">
                      {formatDate(legalEntityComment.hiddenAt, longDateFormat)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                id="disable-comment-yes-button"
                label={t('general.yes.button')}
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                id="disable-comment-no-button"
                label={t('general.no.button')}
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { LegalEntityDeleteCommentPage };
