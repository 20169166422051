import {
  ECustomFieldObjectType,
  ICustomField,
  ICustomFieldByObjectTypeResponse,
  ICustomFieldProperties,
  ICustomFieldRequest,
  ICustomFieldsResponse,
  ICustomFieldUpdateRequest,
} from 'models/customField';
import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';

/**
 *
 * @param body
 */
const createCustomField = async (body: ICustomFieldRequest) =>
  apiClient.post<TResponse<ICustomField>>(ApiEndpoints.CREATE_CUSTOM_FIELD(), body);

/**
 *
 * @param id
 * @param body
 */
const updateCustomField = async (id: string, body: ICustomFieldUpdateRequest) =>
  apiClient.put(ApiEndpoints.UPDATE_CUSTOM_FIELD(id), body);

/**
 *
 */
const getAllCustomFields = async () => {
  const { data } = await apiClient.get<TResponse<ICustomFieldsResponse>>(
    ApiEndpoints.GET_CUSTOM_FIELDS(),
  );
  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param id
 */
const getCustomField = async (id: string) => {
  const { data } = await apiClient.get<TResponse<ICustomField>>(
    ApiEndpoints.GET_CUSTOM_FIELD(id),
  );
  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getCustomFieldByObjectType = async (objectType: ECustomFieldObjectType) => {
  const { data } = await apiClient.get<TResponse<ICustomFieldByObjectTypeResponse>>(
    `${ApiEndpoints.GET_CUSTOM_FIELD_BY_OBJECT_TYPE()}?objectType=${objectType}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param id
 * @param status
 */
const changeCustomFieldStatus = async (id: string, status: boolean) => {
  const { data } = await apiClient.patch(
    `${ApiEndpoints.CHANGE_CUSTOM_FIELD_STATUS(id)}?enabled=${status}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param id
 */
const deleteCustomField = async (id: string) =>
  apiClient.delete(`${ApiEndpoints.DELETE_CUSTOM_FIELD(id)}`);

/**
 *
 */
const getCustomFieldProperties = async () => {
  const { data } = await apiClient.get<TResponse<ICustomFieldProperties>>(
    ApiEndpoints.GET_CUSTOM_FIELD_PROPERTIES(),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  createCustomField,
  updateCustomField,
  getAllCustomFields,
  getCustomFieldByObjectType,
  getCustomField,
  changeCustomFieldStatus,
  deleteCustomField,
  getCustomFieldProperties,
};
