import React, { createRef, forwardRef, RefCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementProps } from 'models/form';
import { SxProps } from '@mui/material';
import { IMask } from 'react-imask';
import { StyledMaskedFormInput } from 'components/molecules/MaskedFormInput/styles';
import { getNestedErrorMessage } from 'components/molecules/FormInput/helpers';

interface IFormInput extends IFormElementProps {
  placeholder?: string;
  multiline?: boolean;
  minRows?: number;
  sx?: SxProps;
  className?: string;
  InputProps?: object;
  disabled?: boolean;
  helperText?: string;
  mask: string | RegExp | null;
  arrayName?: string;
  arrayIndex?: number;
  fieldName?: string;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: any;
  placeholder?: string;
  error: boolean;
}

const MaskedTextField = forwardRef<IMask.MaskElement, CustomProps>((props, inputRef) => {
  const { onChange, mask, ...other } = props;
  const ref = createRef();

  return (
    <StyledMaskedFormInput
      {...other}
      inputRef={inputRef as RefCallback<IMask.MaskElement>}
      ref={ref}
      mask={mask}
      onAccept={(value: any) => {
        onChange({ target: { name: other.name, value } });
      }}
    />
  );
});

export const MaskedFormInput = ({
  name,
  label,
  control,
  errors,
  disabled,
  placeholder,
  mask,
  arrayName,
  arrayIndex,
  fieldName,
}: IFormInput) => {
  const [error, setError] = useState<string>('');

  if (typeof mask === 'string') {
    if (mask.startsWith('\\\\\\')) {
      const regMask = mask
        .replaceAll('\\\\\\', '')
        .split(',')
        .map((e) => e.trim());

      const regMaskString = [...new Set(regMask)].join('');

      mask = new RegExp(`^[${regMaskString}]$`);
    } else {
      mask = mask ? mask.replaceAll('\\\\', '\\') : null;
    }
  }

  useEffect(() => {
    let isError = false;
    if (name.includes('.') && !name.includes('[') && Object.keys(errors).length) {
      const keys = name.split('.');
      const errorMessage = getNestedErrorMessage(errors, keys);
      if (keys && errorMessage) {
        isError = true;
        setError(errorMessage);
      }
    } else {
      const errorMessage =
        arrayName && arrayIndex !== undefined && fieldName
          ? errors?.[arrayName]?.[arrayIndex]?.[fieldName]?.message
          : errors[name]?.message;

      isError = true;
      setError(errorMessage);
    }
    if (!isError) {
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, name]);

  return (
    <StyledFormElementWrapper>
      <FormLabel name={name} label={label} disabled={disabled} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MaskedTextField
            mask={mask}
            placeholder={placeholder}
            error={!!error}
            {...field}
            onChange={(e: { target: { name: string; value: string } }) => {
              setError('');
              field.onChange(e.target.value || '');
            }}
          />
        )}
      />
      <FormErrorLabel label={error} />
    </StyledFormElementWrapper>
  );
};
