import { useState } from 'react';
import {
  addFRTLocal,
  createFRTLocalSpecification,
  getFRTCheckIsUsed,
  getFRTDetails,
  getFRTNotes,
  getFRTSpecifications,
  patchFRTLocal,
  updateFRTLocal,
  updateFRTLocalSpecification,
} from 'requests/frt';
import { IFRTCalibreFormValues, IFRTDetails, IFRTNotes, IFRTSpecifications } from 'models/frt';
import {
  IFRTFormValues,
  IFRTLocalNoteObject,
  IFRTLocalPatchRequest,
  IFRTLocalUpdateRequest,
  initialFRTNotesFormValues,
} from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';
import { useDictionary } from 'models/dictionaryContext';
import { EDictionaryTypes } from 'models/dictionary';

export const useFRT = () => {
  const { t } = useTranslations();
  const [FRTDetails, setFRTDetails] = useState<IFRTDetails | null>(null);
  const [FRTCheckIsUsed, setFRTCheckIsUsed] = useState<boolean | null>(null);
  const [FRTSpecifications, setFRTSpecifications] = useState<IFRTSpecifications[]>([]);
  const [FRTNotes, setFRTNotes] = useState<IFRTNotes[]>([]);
  const { setAlert } = useAlert();
  const {
    manufacturerCountry,
    getManufacturerCountry,
    firearmAction,
    getFirearmAction,
    firearmType,
    getFirearmType,
    level,
    getLevel,
    legalClassification,
    getLegalClassification,
    make,
    getMake,
    model,
    getModel,
    manufacturer,
    getManufacturer,
  } = useDictionary();

  const loadDictionaries = async (types: EDictionaryTypes[]) => {
    if (types.includes(EDictionaryTypes.ManufacturerCountry)) {
      getManufacturerCountry();
    }
    if (types.includes(EDictionaryTypes.FirearmAction)) {
      getFirearmAction();
    }
    if (types.includes(EDictionaryTypes.Make)) {
      getMake();
    }
    if (types.includes(EDictionaryTypes.Model)) {
      getModel();
    }
    if (types.includes(EDictionaryTypes.Manufacturer)) {
      getManufacturer();
    }
    if (types.includes(EDictionaryTypes.FirearmType)) {
      getFirearmType();
    }
    if (types.includes(EDictionaryTypes.Level)) {
      getLevel();
    }
    if (types.includes(EDictionaryTypes.LegalClassification)) {
      getLegalClassification();
    }
  };

  const getFRTDetailsData = async (id: string) => {
    const FRTDetailsData = await getFRTDetails(id);

    if (FRTDetailsData) {
      setFRTDetails(FRTDetailsData);
    } else {
      setFRTDetails(null);
    }
  };

  const getFRTSpecificationsData = async (id: string) => {
    const FRTSpecificationsData = await getFRTSpecifications(id);

    if (FRTSpecificationsData) {
      setFRTSpecifications(FRTSpecificationsData);
    } else {
      setFRTSpecifications([]);
    }
  };

  const getFRTNotesData = async (id: string) => {
    const FRTNotesData = await getFRTNotes(id);

    if (FRTNotesData) {
      setFRTNotes(FRTNotesData);
    } else {
      setFRTNotes([]);
    }
  };

  const handleCreate = async (id: string, data: IFRTCalibreFormValues) =>
    createFRTLocalSpecification(id, data);

  const handleUpdate = async (
    id: string,
    specificationId: string,
    data: IFRTCalibreFormValues,
  ) => updateFRTLocalSpecification(id, specificationId, data);

  const prepareFRTFromFRTData = (data: IFRTFormValues): IFRTLocalUpdateRequest => {
    const notes: IFRTLocalNoteObject[] = [];

    Object.getOwnPropertyNames(data).forEach((key) => {
      if (key.length === 3) {
        const note = {
          type: key,
          description: data[key as keyof typeof initialFRTNotesFormValues],
        };
        notes.push(note);
      }
    });
    const localFRT: IFRTLocalUpdateRequest = {
      make: data.make,
      model: data.model,
      action: data.action,
      type: data.type,
      manufacturer: data.manufacturer,
      country: data.country,
      level: data.level,
      legalClassification: data.legalClassification,
      notes,
      productCodes: data.productCodes
        .map((el) => el.productCode)
        .filter((el) => el.trim().length),
    };
    return localFRT;
  };

  const handleAddFRT = async (data: IFRTFormValues) => {
    const localFRT: IFRTLocalUpdateRequest = prepareFRTFromFRTData(data);
    const response = await addFRTLocal(localFRT);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('firearmsReferenceTable.editFirearm.firearmSaved.text'),
      });
    }
    return response;
  };

  const handleEditFRT = async (
    id: string,
    data: IFRTFormValues,
    frtIsUsed: boolean | null,
  ) => {
    const localFRT: IFRTLocalUpdateRequest = prepareFRTFromFRTData(data);
    const localFRTForPatch: IFRTLocalPatchRequest = {
      level: localFRT.level,
      legalClassification: localFRT.legalClassification,
      notes: localFRT.notes,
    };
    const response = frtIsUsed
      ? await patchFRTLocal(id, localFRTForPatch)
      : await updateFRTLocal(id, localFRT);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('firearmsReferenceTable.editFirearm.firearmSaved.text'),
      });
    }
    return response;
  };

  const getFRTCheckIsUsedData = async (id: string) => {
    const FRTCheckIsUsedValue = await getFRTCheckIsUsed(id);

    setFRTCheckIsUsed(FRTCheckIsUsedValue);
  };

  return {
    loadDictionaries,
    make,
    model,
    manufacturer,
    manufacturerCountry,
    firearmAction,
    firearmType,
    level,
    legalClassification,
    getFRTDetailsData,
    FRTDetails,
    getFRTSpecificationsData,
    FRTSpecifications,
    getFRTNotesData,
    FRTNotes,
    getFRTCheckIsUsedData,
    FRTCheckIsUsed,
    handleAddFRT,
    handleEditFRT,
    handleCreate,
    handleUpdate,
  };
};
