import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import LEAccordions, { ELEAccordionsPageTypes } from 'components/organisms/LEAccordions';
import { useTranslations } from 'hooks/useTranslations';

const LegalEntitiesPage = () => {
  const { t } = useTranslations();

  return (
    <>
      <Breadcrumbs items={[t('module.legalEntity.name')]} />
      <StyledPageTitle variant="h4">{t('module.legalEntity.name')}</StyledPageTitle>
      <LEAccordions
        type={ELEAccordionsPageTypes.LegalEntity}
        translationKeys={{
          noResultIndividual:
            'legalEntities.individual.accordion.legalEntityTable.noResults.text',
          noResultOrganization:
            'legalEntities.organization.accordion.legalEntityTable.noResults.text',
        }}
        saveRouterStateAfterClearAll={false}
      />
    </>
  );
};

export default LegalEntitiesPage;
