import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ERouteLinks } from 'models/route';
import { isString } from 'helpers/guards';
import { Link } from 'react-router-dom';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { StyledBreadcrumbs } from './styles';

export interface IBreadcrumbsLink {
  label: string;
  route: ERouteLinks | any;
  state?: object;
}

interface IBreadcrumbs {
  items: (string | IBreadcrumbsLink)[];
}

const Breadcrumbs = ({ items }: IBreadcrumbs) => {
  const { isDesktop } = useThemeBreakpoint();

  return (
    <Stack
      spacing={2}
      sx={{
        maxWidth: isDesktop ? 'calc(100% - 220px)' : '100%',
      }}
    >
      <StyledBreadcrumbs separator="/" aria-label="breadcrumbs">
        {items &&
          items.map((item) =>
            isString(item) ? (
              <Typography variant="button" key={item}>
                {item}
              </Typography>
            ) : (
              <Link key={item.label} to={item.route} state={item.state}>
                {item.label}
              </Link>
            ),
          )}
      </StyledBreadcrumbs>
    </Stack>
  );
};

export default Breadcrumbs;
