import { useState } from 'react';
import {
  EDictionaryTypes,
  IDictionary,
  IDictionaryContentValues,
  IDictionaryDetailResponse,
  IDictionaryLanguage,
  IDictionaryTab,
  IDictionaryValueFormValues,
} from 'models/dictionary';
import {
  createDictionaryValue,
  getDictionaries,
  getDictionaryById,
  getDictionaryDetail,
  getDictionaryTypeLanguages,
  updateDictionaryValue,
} from 'requests/dictionary';
import { useAlert } from 'models/alertContext';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { EAlertVariants } from 'components/atoms/Alert';
import { useTranslations } from 'hooks/useTranslations';
import { ActionTypes } from 'state/actions/alert';
import { IPermission } from 'models/permissions';

export type IParsedPermissions = {
  [module: string]: IPermission[];
};

interface IDictionariesData {
  content: IDictionaryTab[];
  pagination: {
    [key: string]: any;
  };
}

interface IDictionaryData {
  detail: IDictionaryDetailResponse;
  content: IDictionaryContentValues[];
  pagination: {
    [key: string]: any;
  };
}

export const useDictionaries = () => {
  const { t } = useTranslations();
  const [dictionaries, setDictionaries] = useState<IDictionaryTab[]>([]);
  const [dictionaryLanguages, setDictionaryLanguages] = useState<IDictionaryLanguage[]>([]);
  const [dictionaryValues, setDictionaryValues] = useState<IDictionaryContentValues[] | null>(
    [],
  );
  const [dictionaryTypeValue, setDictionaryTypeValue] = useState<IDictionary | undefined>(
    undefined,
  );
  const [dictionary, setDictionary] = useState<IDictionaryDetailResponse | null>(null);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);

  const { setAlert } = useAlert();

  const getDictionariesData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    sort?: ISortOptions | null,
  ): Promise<IDictionariesData | null> => {
    const dictionariesData = await getDictionaries(currentPage, perPage, sort);
    if (dictionariesData) {
      const { content, ...paginatorData } = dictionariesData;
      setDictionaries(content);
      setPaginator(paginatorData);
      return {
        content,
        pagination: {
          ...paginatorData,
        },
      };
    }
    setDictionaries([]);
    setPaginator(null);
    return null;
  };

  const getDictionaryData = async (
    type: EDictionaryTypes,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    sort?: ISortOptions | null,
  ): Promise<IDictionaryData | null> => {
    const dictionaryData = await getDictionaryDetail(type, currentPage, perPage, sort);
    if (dictionaryData) {
      const { values, ...detail } = dictionaryData;
      if (values) {
        const { content, ...paginatorData } = values;
        setDictionary(detail);
        setDictionaryValues(content);
        setPaginator(paginatorData);
        return {
          detail,
          content,
          pagination: {
            ...paginatorData,
          },
        };
      }
    }
    setDictionary(null);
    setPaginator(null);
    return null;
  };

  const getDictionaryTypeLanguagesData = async (
    type: EDictionaryTypes,
  ): Promise<any[] | null> => {
    const dictionariesLanguageData = await getDictionaryTypeLanguages(type);
    if (dictionariesLanguageData) {
      setDictionaryLanguages(dictionariesLanguageData);
      return dictionariesLanguageData;
    }
    setDictionaryLanguages([]);
    return null;
  };

  const getDictionaryTypeValueData = async (id: string): Promise<any[] | null> => {
    const dictionaryTypeValueData = await getDictionaryById(id);
    if (dictionaryTypeValueData) {
      setDictionaryTypeValue(dictionaryTypeValueData);
      return dictionaryTypeValueData;
    }
    setDictionaryTypeValue(undefined);
    return null;
  };

  const handleCreate = async (data: IDictionaryValueFormValues, type: EDictionaryTypes) => {
    const response = await createDictionaryValue(data, type);
    if (response.status === 200) {
      setAlert(ActionTypes.SET_DICTIONARY_ALERT, {
        text: t('addRole.success.text'),
      });
    } else if (response.status === 400) {
      setAlert(ActionTypes.SET_DICTIONARY_ALERT, {
        text: t('addDictionaryValue.valueName.validation.valueNameNotUnique'),
        variant: EAlertVariants.error,
      });
    }

    return response;
  };

  const handleUpdate = async (data: IDictionaryValueFormValues, id: string) => {
    const response = await updateDictionaryValue(data, id);
    if (response.status === 200) {
      setAlert(ActionTypes.SET_DICTIONARY_ALERT, {
        text: t('addDictionaryValue.success.text'),
      });
    } else if (response.status === 400) {
      setAlert(ActionTypes.SET_DICTIONARY_ALERT, {
        text: t('addDictionaryValue.valueName.validation.valueNameNotUnique'),
        variant: EAlertVariants.error,
      });
    }

    return response;
  };

  return {
    alert,
    dictionaries,
    dictionary,
    dictionaryLanguages,
    dictionaryValues,
    dictionaryTypeValue,
    paginator,
    getDictionariesData,
    getDictionaryData,
    handleCreate,
    handleUpdate,
    getDictionaryTypeLanguagesData,
    getDictionaryTypeValueData,
  };
};
