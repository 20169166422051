import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';
import { ERegExpTypes } from 'constants/RegExpTypes';

const useAccountFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    firstName: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    lastName: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    prefix: yup.string().required(t('general.field.validation.required')).trim(),
    phoneNumber: yup
      .string()
      .required(t('general.field.validation.required'))
      .matches(
        new RegExp(ERegExpTypes.onlyDigits),
        t('general.field.validation.valueIsNotCorrect'),
      )
      .max(15, t('general.field.validation.maxNumberCharacters').replace('{0}', '15'))
      .min(5, t('general.field.validation.minNumberCharacters').replace('{0}', '5'))
      .trim(),
  });

  return { schema };
};

export default useAccountFormSchema;
