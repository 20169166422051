import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs, { IBreadcrumbsLink } from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { Accordion } from 'components/molecules/Accordion';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { Typography } from '@mui/material';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { getLegalEntity, getLegalType, getOrgLegalEntity } from 'requests/legalEntities';
import { ILegalEntityData } from 'components/organisms/LegalEntityDetails';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { colors } from 'theme/colors';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EIconTypes } from 'constants/Icons';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { getAncillaryNameById } from 'helpers/ancillary';
import Icon from 'components/atoms/Icon/Icon';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDictionary } from 'models/dictionaryContext';
import { EDictionaryTypes, IDictionary, ITranslation } from 'models/dictionary';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { EMIMETypes } from 'constants/MIMETypes';
import {
  createMiddleTransactionForAncillaryGroup,
  getAncillaryGroupLastTransaction,
  getAncillaryGroupLastTransactionUniqueId,
  getPossibleTransactionTypeForAncillaryGroup,
} from 'requests/transaction';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { formatDate, formatDateTimeToIso } from 'utils/date';
import { FormDateTimePicker } from 'components/molecules/FormDateTimePicker';
import { IAncillaryDetails } from 'models/ancillaries';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useQuery } from 'hooks/useQuery';
import { useScroll } from 'hooks/useScroll';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { StyledIconWrapper, StyledLocationMarkConfirmationMarginBottom } from './styles';

const MAX_FILE_SIZE_IN_MB = 3;

let DISABLED_FOR_TRANSACTION_FORM = [ETransactionTypes.DISABLED];

const TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS = [
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
];

const CHANGE_LE_REQUIRED_TRANSACTION_TYPES = [
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SOLD,
  ETransactionTypes.GIVEN,
  ETransactionTypes.LEASED,
  ETransactionTypes.ASSIGNED_TEMPORARILY,
  ETransactionTypes.ASSIGNED_INDEFINITELY,
  ETransactionTypes.TRANSFERRED,
  ETransactionTypes.RETURNED,
];

export enum LETypes {
  individual = 'INDIVIDUAL',
  organization = 'ORGANIZATION',
}

const filterDisabled = (
  transactions: IDictionary[] | null,
  legalEntityIndividualKeeper: ILegalEntityData | null | undefined,
): IDictionary[] | null => {
  if (transactions === null) {
    return null;
  }
  if (legalEntityIndividualKeeper !== null && legalEntityIndividualKeeper !== undefined) {
    DISABLED_FOR_TRANSACTION_FORM = [ETransactionTypes.DISABLED];
  } else {
    DISABLED_FOR_TRANSACTION_FORM = [ETransactionTypes.DISABLED, ETransactionTypes.GIVEN];
  }
  return transactions.filter(
    (transaction) =>
      !DISABLED_FOR_TRANSACTION_FORM.map((disabled) => disabled.toString()).includes(
        transaction.uniqueId.toString(),
      ),
  );
};

const LegalEntityAddTransactionForAncillaryGroupPage = () => {
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { selectedLanguage } = useLang();
  const { id = '', groupId = '' } = useParams<{
    id: string;
    groupId: string;
  }>();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const query = useQuery();
  const typeFromQuery: string | null = query.get('type');
  const expand: string | null = query.get('expand');
  const { elRef, scrollToError } = useScroll();

  const {
    getAncillaryGroupDetail,
    ancillaryGroupDetail,
    getAncillaryGroupAncillariesDetail,
    ancillariesDetail,
    transactionTypeValidate,
    transactionTypeIsValid,
  } = useLegalEntities();

  useEffect(() => {
    (async () => {
      await getAncillaryGroupDetail(id, groupId as string);
    })();
  }, [id, groupId, selectedLanguage]);

  const { shortDateFormat } = useGlobalProperty();

  const getTypeFromQuery = () => {
    let result = null;
    if (!typeFromQuery) {
      result = null;
    } else if (typeFromQuery === 'organisation') {
      result = 'ORGANIZATION';
    } else {
      result = typeFromQuery.toUpperCase();
    }
    return result;
  };

  const [submitBlocked, setSubmitBlocked] = useState(false);
  const [type, setType] = useState<string | null>(getTypeFromQuery());

  const initialFormValues = {
    typeUniqueId: routerState?.typeUniqueId || '',
    transactionDate: routerState?.transactionDate || '',
    ancillariesQuantity: routerState?.ancillariesQuantity || '',
    serialNumber: routerState?.serialNumber || '',
    externalReferenceNumber: routerState?.externalReferenceNumber || '',
    manufacturedAt: routerState?.manufacturedAt || '',
    countryOfImportUniqueId: routerState?.countryOfImportUniqueId || '',
    importer: routerState?.importer || '',
    countryOfExportUniqueId: routerState?.countryOfExportUniqueId || '',
    expirationDate: routerState?.expirationDate || '',
    attachments: routerState?.attachments?.length ? routerState?.attachments : [],
    legalEntityFullName: routerState?.legalEntityFullName || '',
    legalEntityId: routerState?.legalEntityId || '',
    description: routerState?.description || '',
    address: routerState?.address || '',
  };
  const { t } = useTranslations();

  const schema = yup.object().shape({
    typeUniqueId: yup.string().required(t('javax.validation.constraints.NotNull.message')),
    attachments: yup.array().of(
      yup.object({
        attachmentName: yup
          .string()
          .test('unique', t('general.field.validation.unique'), (value, params) => {
            // @ts-ignore
            const attachments = params?.from[1]?.value.attachments ?? [];
            if (!value) return true;
            if (attachments.filter((r: any) => r.attachmentName === value).length > 1) {
              return params.createError({
                path: params.path,
                message: t('general.field.validation.unique'),
              });
            }
            return true;
          })
          .required(t('general.field.validation.required'))
          .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
          .matches(/^[a-zA-Z0-9_ -]+$/, t('general.field.validation.attachmentName'))
          .trim(),
      }),
    ),
    transactionDate: yup
      .string()
      .required(t('javax.validation.constraints.NotNull.message'))
      .test(
        'date-validation',
        t('legalEntity.validation.maxDate').replace('{0}', '255'),
        (value) => {
          if (typeof value !== 'string') {
            return false;
          }
          const maxValidDate = new Date();
          const passedDate = new Date(value);
          return passedDate.valueOf() <= maxValidDate.valueOf();
        },
      ),
    legalEntityFullName: yup.string().when('typeUniqueId', {
      is: (typeVar: any) => CHANGE_LE_REQUIRED_TRANSACTION_TYPES.includes(typeVar),
      then: (yupSchema) =>
        yupSchema.required(t('javax.validation.constraints.NotNull.message')),
    }),
    countryOfImportUniqueId: yup.string().when('typeUniqueId', {
      is: ETransactionTypes.DECLARED_IMPORTED,
      then: (yupSchema) =>
        yupSchema.required(t('javax.validation.constraints.NotNull.message')),
    }),
    countryOfExportUniqueId: yup.string().when('typeUniqueId', {
      is: ETransactionTypes.EXPORTED,
      then: (yupSchema) =>
        yupSchema.required(t('javax.validation.constraints.NotNull.message')),
    }),
    description: yup
      .string()
      .max(1000, t('general.field.validation.maxCharacters').replace('{0}', '1000'))
      .when('typeUniqueId', {
        is: ETransactionTypes.MODIFIED,
        then: (yupSchema) => yupSchema.required(t('general.field.validation.required')),
      }),
    importer: yup
      .string()
      .max(250, t('general.field.validation.maxCharacters').replace('{0}', '250')),
    address: yup.string(),
    expirationDate: yup.string().when('typeUniqueId', {
      is: (typeVar: any) =>
        [
          ETransactionTypes.ASSIGNED_TEMPORARILY,
          ETransactionTypes.TEST_FIRED,
          ETransactionTypes.LEASED,
        ].includes(typeVar),
      then: (yupSchema) =>
        yupSchema
          .required(t('javax.validation.constraints.NotNull.message'))
          .test(
            'expiration-before-transaction-date-validation',
            t('legalEntity.validation.expirationDateValidation').replace('{0}', '255'),
            (value, context) => {
              if (typeof value !== 'string') {
                return false;
              }
              return (
                new Date(value).valueOf() >= new Date(context.parent.transactionDate).valueOf()
              );
            },
          ),
    }),
  });

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    getValues,
    clearErrors,
  } = useForm({ defaultValues: initialFormValues, resolver: yupResolver(schema) });
  const attachments = useFieldArray({
    control,
    name: 'attachments',
  });

  const [legalEntityIndividualKeeper, setLegalEntityIndividualKeeper] =
    useState<ILegalEntityData | null>();
  const [legalEntityOrganisationKeeper, setLegalEntityOrganisationKeeper] =
    useState<IOrgLegalEntityData | null>();
  const [legalEntityIndividualOwner, setLegalEntityIndividualOwner] =
    useState<ILegalEntityData | null>();
  const [legalEntityOrganisationOwner, setLegalEntityOrganisationOwner] =
    useState<IOrgLegalEntityData | null>();
  const [isErrorLocation, setIsErrorLocation] = useState<boolean>(false);
  const { loadDictionaries, country, getCustomField } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const { transactionMiddleTypes } = useDictionary();
  const [createTransactionError, setCreateTransactionError] = useState<string>('');
  const [possibleTransactionDictionaries, setPossibleTransactionDictionaries] = useState<
    IDictionary[] | null
  >(null);
  const [showCurrentOwnerAndKeeper, setShowCurrentOwnerAndKeeper] = useState(false);
  const [lastTransactionUniqueId, setLastTransactionUniqueId] = useState<string | null>();
  const [isCurrentOwnerLoaded, setIsCurrentOwnerLoaded] = useState(false);
  const [isCurrentKeeperLoaded, setIsCurrentKeeperLoaded] = useState(false);

  const transactionTypeValue = watch('typeUniqueId');
  const isShowLocation = [
    ETransactionTypes.SEIZED,
    ETransactionTypes.RECOVERED,
    ETransactionTypes.STOLEN,
    ETransactionTypes.LOST,
    ETransactionTypes.SPOT_CHECK,
  ].includes(transactionTypeValue);
  const isShowCountryOfImport = [ETransactionTypes.DECLARED_IMPORTED].includes(
    transactionTypeValue,
  );
  const isModificationDescription = [ETransactionTypes.MODIFIED].includes(
    transactionTypeValue,
  );
  const isShowCountryOfExport = transactionTypeValue === ETransactionTypes.EXPORTED;
  const canChangeLegalEntity = [...CHANGE_LE_REQUIRED_TRANSACTION_TYPES].includes(
    transactionTypeValue,
  );
  const canSetNewKeeperOnly = [
    ETransactionTypes.GIVEN,
    ETransactionTypes.LEASED,
    ETransactionTypes.ASSIGNED_TEMPORARILY,
    ETransactionTypes.ASSIGNED_INDEFINITELY,
    ETransactionTypes.REPAIRED_OR_MAINTAINED,
    ETransactionTypes.RETURNED,
  ].includes(transactionTypeValue);
  const isShowExpirationDate = [
    ETransactionTypes.ASSIGNED_TEMPORARILY,
    ETransactionTypes.TEST_FIRED,
    ETransactionTypes.LEASED,
  ].includes(transactionTypeValue);
  const isMapOnlyForOrganization = [
    ETransactionTypes.RECOVERED,
    ETransactionTypes.SURRENDERED,
    ETransactionTypes.SEIZED,
  ].includes(transactionTypeValue);

  const { translations } = useLang();

  useEffect(() => {
    if (ancillariesDetail && !ancillariesDetail?.ancillaries?.length) {
      setAlert(ActionTypes.SET_TRANSACTION_ALERT, {} as IAlert);
      navigate(
        ERouteLinks.ViewLegalEntityGroup.replace(':id', id).replace(':groupId', groupId),
      );
    }
  }, [ancillariesDetail]);

  useEffect(() => {
    if (groupId) {
      getAncillaryGroupAncillariesDetail(groupId);
    }
  }, [groupId]);

  useEffect(() => {
    if (type && id && groupId) {
      getPossibleTransactionTypeForAncillaryGroup(groupId, type).then(
        (possibleTransactions) => {
          setPossibleTransactionDictionaries(possibleTransactions || null);
        },
      );
    }
  }, [id, groupId, type]);

  useEffect(() => {
    if (groupId) {
      getAncillaryGroupLastTransactionUniqueId(groupId).then((result) =>
        setLastTransactionUniqueId(result.transactionUniqueId),
      );
    }
  }, [id, groupId]);

  useEffect(() => {
    if (
      possibleTransactionDictionaries &&
      !possibleTransactionDictionaries?.map((d) => d.uniqueId).includes(transactionTypeValue)
    ) {
      setValue('typeUniqueId', '');
    }
  }, [possibleTransactionDictionaries]);

  useEffect(() => {
    getAncillaryGroupLastTransaction(groupId).then((result) => {
      if (result.ownerId) {
        getLegalType(result.ownerId).then((legalType) => {
          if (legalType) {
            if (legalType!.type === LETypes.organization) {
              getOrgLegalEntity(result.ownerId).then((owner) => {
                setLegalEntityOrganisationOwner(owner);
                setIsCurrentOwnerLoaded(true);
              });
            } else {
              getLegalEntity(result.ownerId).then((owner) => {
                setLegalEntityIndividualOwner(owner);
                setIsCurrentOwnerLoaded(true);
              });
            }
            if (!result.keeperId) {
              setType(legalType.type);
            }
          }
        });
      } else {
        setIsCurrentOwnerLoaded(true);
      }
      if (result.keeperId) {
        getLegalType(result.keeperId).then((legalType) => {
          if (legalType) {
            setType(legalType.type);
            if (legalType!.type === LETypes.organization) {
              getOrgLegalEntity(result.keeperId).then((keeper) => {
                setLegalEntityOrganisationKeeper(keeper);
                setIsCurrentKeeperLoaded(true);
              });
            } else {
              getLegalEntity(result.keeperId).then((keeper) => {
                setLegalEntityIndividualKeeper(keeper);
                setIsCurrentKeeperLoaded(true);
              });
            }
          }
        });
      } else {
        setIsCurrentKeeperLoaded(true);
      }
    });
  }, [id]);

  useEffect(() => {
    (async function initData() {
      await getCustomField(ECustomFieldObjectType.FIREARM);
      await loadDictionaries([EDictionaryTypes.CountryAll, EDictionaryTypes.TransactionType]);
    })();
  }, [selectedLanguage]);

  const filteredTransactionMiddleTypes = useRef<IDictionary[] | null>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<(string | IBreadcrumbsLink)[] | []>([]);

  useEffect(() => {
    if (translations?.length && type?.length) {
      setBreadcrumbs([
        { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
        {
          label:
            type === LETypes.individual
              ? t('legalEntities.individual.accordion.label')
              : t('legalEntities.organization.accordion.label'),
          route: `${ERouteLinks.LegalEntities}?type=${type}&expand=${expand}`,
        },
        {
          label: t('legalEntityDetails.legalEntityDetails.text'),
          route: `${ERouteLinks.ViewLegalEntityAncillaryGroup.replace(':id', id ?? '').replace(
            ':groupId',
            groupId,
          )}?type=${type}&expand=${expand}`,
        },
        {
          label: t('groupDetails.header'),
          route: `${ERouteLinks.ViewLegalEntityAncillaryGroup.replace(':id', id).replace(
            ':groupId',
            groupId,
          )}?type=${type}&expand=${expand}`,
        },
        t('addTransaction.addTransaction.breadcrumb'),
      ]);
    }
  }, [translations, type]);

  useEffect(() => {
    if (transactionMiddleTypes) {
      if (type === LETypes.individual) {
        filteredTransactionMiddleTypes.current = transactionMiddleTypes?.filter(
          ({ uniqueId: id1 }) =>
            !TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.some((id2) => id2 === id1),
        );
      } else if (type === LETypes.organization) {
        filteredTransactionMiddleTypes.current = transactionMiddleTypes;
      }
    }
  }, [type, transactionMiddleTypes]);

  useEffect(() => () => window.history.replaceState({}, document.title), []);

  useEffect(() => {
    setShowCurrentOwnerAndKeeper(
      legalEntityIndividualOwner !== undefined ||
        legalEntityIndividualKeeper !== undefined ||
        legalEntityOrganisationOwner !== undefined ||
        legalEntityOrganisationKeeper !== undefined,
    );
  }, [
    legalEntityIndividualOwner,
    legalEntityIndividualKeeper,
    legalEntityOrganisationOwner,
    legalEntityOrganisationKeeper,
  ]);

  const onSubmit = () => {
    let ownerId: any = null;
    let keeperId: any = null;
    setCreateTransactionError('');
    handleSubmit(async (data) => {
      setIsErrorLocation(false);
      const date = formatDateTimeToIso(data.transactionDate);

      const fullDate = date;
      if (canSetNewKeeperOnly) {
        if (type === ELegalEntityTypes.individual || type === ELegalEntityTypes.organisation) {
          keeperId = routerState?.legalEntityId;
          ownerId =
            legalEntityIndividualOwner?.uniqueId || legalEntityOrganisationOwner?.uniqueId;
        } else {
          keeperId = routerState.legalEntityId;
          ownerId =
            legalEntityIndividualOwner !== undefined && legalEntityIndividualOwner !== null
              ? legalEntityIndividualOwner.uniqueId
              : legalEntityOrganisationOwner!.uniqueId;
        }
      } else if (
        [ETransactionTypes.TEST_FIRED, ETransactionTypes.MODIFIED].includes(
          transactionTypeValue,
        )
      ) {
        keeperId = legalEntityIndividualKeeper
          ? legalEntityIndividualKeeper.uniqueId
          : legalEntityOrganisationKeeper?.uniqueId;
        ownerId = legalEntityIndividualOwner
          ? legalEntityIndividualOwner.uniqueId
          : legalEntityOrganisationOwner?.uniqueId;
      } else if (
        [ETransactionTypes.STOLEN, ETransactionTypes.LOST].includes(transactionTypeValue)
      ) {
        keeperId = null;
        ownerId = legalEntityIndividualOwner
          ? legalEntityIndividualOwner.uniqueId
          : legalEntityOrganisationOwner?.uniqueId;
      } else if (
        [ETransactionTypes.EXPORTED, ETransactionTypes.DESTROYED].includes(
          transactionTypeValue,
        )
      ) {
        keeperId = null;
        ownerId = null;
      } else if (
        [
          ETransactionTypes.SPOT_CHECK,
          ETransactionTypes.MARKED_PENDING,
          ETransactionTypes.MARKED,
        ].includes(transactionTypeValue)
      ) {
        if (legalEntityIndividualKeeper) {
          keeperId = legalEntityIndividualKeeper.uniqueId;
        } else if (legalEntityOrganisationKeeper) {
          keeperId = legalEntityOrganisationKeeper.uniqueId;
        }
        if (legalEntityIndividualOwner) {
          ownerId = legalEntityIndividualOwner.uniqueId;
        } else if (legalEntityOrganisationOwner) {
          ownerId = legalEntityOrganisationOwner.uniqueId;
        }
      } else if (routerState?.type || routerState?.legalEntityId) {
        keeperId = routerState?.legalEntityId;
        ownerId = routerState?.legalEntityId;
      } else if (type === LETypes.individual) {
        keeperId = legalEntityIndividualKeeper
          ? legalEntityIndividualKeeper.uniqueId
          : legalEntityOrganisationKeeper?.uniqueId;
        ownerId = legalEntityIndividualOwner
          ? legalEntityIndividualOwner.uniqueId
          : legalEntityOrganisationOwner?.uniqueId;
      } else if (type === LETypes.organization) {
        keeperId = legalEntityOrganisationKeeper?.uniqueId;
        ownerId = legalEntityOrganisationOwner?.uniqueId;
      }

      if (isShowLocation && !routerState?.coords) {
        setSubmitBlocked(false);
        return setIsErrorLocation(true);
      }

      const body = {
        request: {
          typeUniqueId: data.typeUniqueId,
          keeperId,
          ownerId,
          date: fullDate,
          groupUniqueId: groupId,
          ...(isShowCountryOfImport
            ? {
                countryOfImportUniqueId: data.countryOfImportUniqueId,
                importer: data.importer,
              }
            : {}),
          ...(isShowCountryOfExport
            ? {
                countryOfExportUniqueId: data.countryOfExportUniqueId,
              }
            : {}),
          ...(isShowLocation && routerState?.coords
            ? {
                latitude: routerState?.coords[0],
                longitude: routerState?.coords[1],
                address: routerState?.address,
                city: routerState?.city,
              }
            : {}),
          ...(isModificationDescription ? { description: data.description } : {}),
          ...(isShowExpirationDate
            ? {
                expirationDate: formatDateTimeToIso(data.expirationDate),
              }
            : {}),
          lastTransactionUniqueId,
        },
        ...(data.attachments?.filter((attachment: any) => attachment.file !== null)?.length
          ? { attachments: data.attachments }
          : {}),
      };
      try {
        setSubmitBlocked(true);
        const transaction = await createMiddleTransactionForAncillaryGroup(body);
        if (transaction) {
          setAlert(ActionTypes.SET_TRANSACTION_ALERT, {
            text: t('addTransaction.transactionAdded.text'),
          });
          navigate(
            `${ERouteLinks.ViewTransaction.replace(
              ':id',
              transaction.uniqueId,
            )}?expand=ancillaryDetails`,
          );
        }
      } catch (e: any) {
        setCreateTransactionError(e?.response?.data?.message);
        setSubmitBlocked(false);
      }
      return true;
    })();
  };

  useEffect(() => {
    (async function () {
      scrollToError(errors);
    })();
  }, [errors]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    [],
  );

  const validateFile = (file: File, index: number) => {
    const fileType: any = file.type;
    if (file) {
      if (file.size === 0) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.emptyFile.text'),
        });
        return false;
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileSizeValidation.text'),
        });
        return false;
      }
      if (
        ![
          EMIMETypes.JPG,
          EMIMETypes.JPEG,
          EMIMETypes.PNG,
          EMIMETypes.PDF,
          EMIMETypes.XLSX,
        ].includes(fileType)
      ) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileTypeValidation.text'),
        });
        return false;
      }
    }
    clearErrors('attachments');
    return true;
  };

  const handleUploadFile = (file: File, index: number) => {
    if (validateFile(file, index)) {
      attachments.update(index, {
        file,
      });
    }
  };

  const renderRemoveAttachmentButton = (index: number) => (
    <Grid item xs={1} sx={!isDesktop ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
      <Button
        variant={EButtonVariants.outlined}
        icon={EIconTypes.delete}
        onClick={() => attachments.remove(index)}
        sizePX={EButtonSizesPX.px32}
        sx={{ ...(isDesktop ? { marginLeft: '20px' } : {}), marginTop: '28px' }}
      />
    </Grid>
  );

  const handleChangeTransactionType = async (typeUniqeId: string) => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    if (typeUniqeId && groupId) {
      await transactionTypeValidate(groupId, typeUniqeId);
    }
  };

  const getCustomFieldTranslation = (customFieldTranslation?: ITranslation[]) => {
    if (selectedLanguage && customFieldTranslation) {
      return customFieldTranslation.filter(
        (trans) => trans.languageUniqueId === selectedLanguage?.uniqueId,
      )[0].name;
    }
    return '';
  };

  const renderCustomFieldInputs = (art: IAncillaryDetails) =>
    art?.customFields?.map(
      (customField: any) =>
        customField.value && (
          <Grid item xs={12} sm={6} md={6} lg={3} key={customField.customFieldUniqueId}>
            <FormInput
              label={getCustomFieldTranslation(customField?.translations)}
              name={customField.customFieldName}
              control={control}
              setValue={setValue}
              errors={errors}
              defaultValue={
                customField.customFieldType === ECustomFieldType.DATE
                  ? formatDate(customField.value, shortDateFormat)
                  : customField.value
              }
              disabled
            />
          </Grid>
        ),
    );

  const handleLimitToParam = () => {
    if (isMapOnlyForOrganization) {
      return `?limitTo=${LETypes.organization}`;
    }
    if (transactionTypeValue === ETransactionTypes.GIVEN) {
      return `?limitTo=${LETypes.individual}`;
    }
    if (transactionTypeValue === ETransactionTypes.TRANSFERRED) {
      return `?limitTo=${LETypes.organization}`;
    }
    return '';
  };

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">
          {t('addTransaction.addTransaction.header')}
        </StyledPageTitle>
      </Box>
      <div ref={elRef}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <FormSelect
              options={mapDictionaryToOptionProp(
                filterDisabled(possibleTransactionDictionaries, legalEntityIndividualKeeper),
              )}
              name="typeUniqueId"
              label={t('addTransaction.transactionType.label')}
              setValue={(name: string, value: string) => {
                setValue('typeUniqueId', value);
                if (!value) {
                  handleChangeTransactionType(value);
                }
              }}
              control={control}
              errors={errors}
              onSelect={handleChangeTransactionType}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormDateTimePicker
              name="transactionDate"
              label={t('addTransaction.transactionDate.label')}
              control={control}
              errors={errors}
              currentHour={true}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '0px !important', marginBottom: '20px' }}>
            {samePageAlert && (
              <Box mt={0}>
                <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {showCurrentOwnerAndKeeper && (
              <Accordion
                title={t('addTransaction.currentOwnerAndKeeper.section.name')}
                alwaysExpanded
              >
                <Box mb={3}>
                  <div>
                    <StyledDetailsWrapper container rowGap={3}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.currentOwnerAndKeeper.section.owner.label')}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ancillaryGroupDetail?.owner)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.currentOwnerAndKeeper.section.keeper.label')}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ancillaryGroupDetail?.keeper)}
                        </Typography>
                      </Grid>
                    </StyledDetailsWrapper>
                  </div>
                </Box>
              </Accordion>
            )}
          </Grid>
          {canChangeLegalEntity && (
            <Grid item xs={12}>
              <Accordion
                title={
                  [
                    ETransactionTypes.ASSIGNED_INDEFINITELY,
                    ETransactionTypes.ASSIGNED_TEMPORARILY,
                    ETransactionTypes.GIVEN,
                    ETransactionTypes.RETURNED,
                    ETransactionTypes.LEASED,
                  ].includes(transactionTypeValue)
                    ? t('addTransaction.newKeeper.section.name')
                    : t('addTransaction.newOwnerAndKeeper.section.name')
                }
                alwaysExpanded
              >
                <Grid container mb={5} rowGap={2}>
                  <Grid item xs={12} lg={6}>
                    <FormInput
                      name="legalEntityFullName"
                      label={`${t(
                        'addTransaction.newOwnerAndKeeper.section.legalEntity.label',
                      )}*`}
                      control={control}
                      errors={errors}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3} sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}>
                    <Button
                      fullWidth
                      label={t(
                        'addTransaction.newOwnerAndKeeper.section.searchForLegalEntity.button',
                      )}
                      variant={EButtonVariants.contained}
                      size={EButtonSizes.small}
                      onClick={() =>
                        navigate(`${ERouteLinks.SelectLegalEntity}${handleLimitToParam()}`, {
                          state: {
                            context: 'ancillaryGroupTransactions',
                            ...routerState,
                            ...getValues(),
                            id,
                            groupId,
                            type,
                            breadcrumbs: [
                              ...breadcrumbs,
                              t('transactions.search.selectLegal.label'),
                            ],
                            returnLink: `${ERouteLinks.LegalEntityAddTransactionForAncillaryGroup.replace(
                              ':id',
                              id,
                            ).replace(
                              ':groupId',
                              groupId,
                            )}?type=${type}&expand=ancillaryGroups`,
                          },
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          )}
          <Grid item xs={12}>
            <Accordion
              title={t('addTransaction.ancillaryDetails.section.name')}
              alwaysExpanded
            >
              {ancillariesDetail &&
                !ancillariesDetail.hasDifferentAncillaryTypes &&
                ancillariesDetail?.artUniqueId && (
                  <Grid
                    container
                    sx={{
                      border: `1px solid ${colors.primary25}`,
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}
                    mb={4}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: colors.primary25,
                      }}
                      px={2}
                      py={1}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h4" sx={{ fontWeight: 600 }}>
                          {ancillariesDetail?.artSpecificType}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container p={2} rowSpacing={2} sx={{ alignItems: 'center' }}>
                      <Grid item xs={12} lg={6} container direction="column" padding="4px">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.art.artId.label')}
                        </Typography>
                        <Typography variant="body2">{ancillariesDetail?.artId}</Typography>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Link
                          href={ERouteLinks.ViewAncillaryDetails.replace(
                            ':id',
                            ancillariesDetail?.artUniqueId,
                          ).replace(
                            ':type',
                            getAncillaryNameById(
                              ancillariesDetail?.artTypeUniqueId,
                            )?.toLowerCase() as string,
                          )}
                          underline="none"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            variant={EButtonVariants.contained}
                            size={EButtonSizes.small}
                            label={t('addTransaction.showArtInformation.button')}
                            fullWidth
                          />
                        </Link>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          height: '1px',
                          backgroundColor: colors.primary25,
                          padding: 0,
                          margin: '20px 0 10px',
                        }}
                      />
                      <Grid item xs={12} md={6} lg={3} direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.art.artType.label')}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ancillariesDetail?.artType)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.art.artSpecificType.label')}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ancillariesDetail?.artSpecificType)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.art.calibre.label')}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ancillariesDetail?.calibre)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3} direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t('addTransaction.art.manufacturer.label')}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ancillariesDetail?.manufacturer)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Accordion>
          </Grid>

          {ancillariesDetail && (
            <Grid sx={{ marginBottom: '20px' }}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  letterSpacing: '2%',
                }}
              >
                {t('addTransaction.ancillaryDetails.section.groupName.label')}
              </Typography>
              <Typography sx={{ fontSize: '14px;' }}>{ancillariesDetail.groupName}</Typography>
            </Grid>
          )}
          {ancillariesDetail &&
            ancillariesDetail?.ancillaries.map((ancillary, index) => (
              <Grid
                item
                xs={12}
                container
                columnSpacing={2}
                sx={{ marginBottom: '20px', marginLeft: 0 }}
              >
                <Grid
                  item
                  lg={0.4}
                  sx={{
                    padding: '0 !important',
                    display: 'flex',
                    justifyContent: 'start',
                    ...(!isDesktop ? { marginBottom: '20px' } : {}),
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: '0 !important',
                      marginTop: 4,
                      fontWeight: 'bold',
                    }}
                  >
                    {`${index + 1}.`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  lg={11}
                  columnSpacing={2}
                  sx={{ padding: '0 !important' }}
                >
                  {ancillary?.fireCycleId && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmFireCycleId"
                        label={t('addTransaction.ancillaryDetails.section.firecycleID.label')}
                        control={control}
                        errors={errors}
                        defaultValue={ancillary.fireCycleId}
                        disabled
                      />
                    </Grid>
                  )}
                  {ancillary?.ancillaryType && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="ancillaryType"
                        label={t(
                          'addTransaction.ancillaryDetails.section.ancillaryType.label',
                        )}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={ancillary.ancillaryType}
                        disabled
                      />
                    </Grid>
                  )}
                  {ancillary?.serialNumber && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="ancillarySerialNumber"
                        label={t('addTransaction.ancillaryDetails.section.serialNumber.label')}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={ancillary.serialNumber}
                        disabled
                      />
                    </Grid>
                  )}
                  {ancillary?.externalReferenceNumber && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmExternalReferenceNumber"
                        label={t(
                          'addTransaction.ancillaryDetails.section.externalReferenceNo.label',
                        )}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={ancillary?.externalReferenceNumber}
                        disabled
                      />
                    </Grid>
                  )}
                  {ancillary?.manufacturedAt && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmManufacturedDate"
                        label={t(
                          'addTransaction.ancillaryDetails.section.manufacturedDate.label',
                        )}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={formatDate(ancillary.manufacturedAt, shortDateFormat)}
                        disabled
                      />
                    </Grid>
                  )}
                  {ancillary?.exhibitNumber && (
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <FormInput
                        name="firearmExhibitNumber"
                        label={t(
                          'addTransaction.ancillaryDetails.section.exhibitNumber.label',
                        )}
                        setValue={setValue}
                        control={control}
                        errors={errors}
                        defaultValue={ancillary.exhibitNumber}
                        disabled
                      />
                    </Grid>
                  )}
                  {renderCustomFieldInputs(ancillary)}
                </Grid>
                <Grid lg={0.6} sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    variant={EButtonVariants.outlined}
                    icon={EIconTypes.view}
                    sizePX={EButtonSizesPX.px32}
                    sx={
                      isDesktop
                        ? {
                            marginTop: '28px',
                          }
                        : {
                            marginBottom: '20px',
                          }
                    }
                    onClick={() =>
                      navigate(
                        ERouteLinks.ViewAncillary.replace(':id', ancillary.uniqueId).replace(
                          ':type',
                          ancillary.ancillaryType,
                        ),
                      )
                    }
                  />
                </Grid>
              </Grid>
            ))}

          {isShowLocation && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.location.section.name')} alwaysExpanded>
                <Grid
                  container
                  mb={4}
                  lg={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  columnGap={3}
                >
                  <Grid sx={{ flexBasis: '40%' }}>
                    <FormInput
                      name="address"
                      label={t('addTransaction.location.section.address.text')}
                      control={control}
                      errors={errors}
                      placeholder={routerState?.address || ''}
                      InputProps={{ readOnly: true }}
                      disabled
                      sx={{ minWidth: '272px' }}
                      helperText={t('addTransaction.location.section.address.helperText')}
                    />
                  </Grid>
                  {routerState?.coords && (
                    <Grid item>
                      <StyledLocationMarkConfirmationMarginBottom fullWidth={!isDesktop}>
                        <Typography variant="body1" color={colors.success900}>
                          {t('addTransaction.location.section.locationMarked.text')}
                        </Typography>
                        <StyledIconWrapper>
                          <Icon
                            type={EIconTypes.checkmark}
                            color={colors.white}
                            fontSize={14}
                          />
                        </StyledIconWrapper>
                      </StyledLocationMarkConfirmationMarginBottom>
                    </Grid>
                  )}
                  <Grid>
                    <Button
                      onClick={() =>
                        navigate(
                          `${ERouteLinks.SelectLocation.replace(
                            ':id',
                            id,
                          )}?type=&expand=ancillary`,
                          {
                            state: {
                              ...routerState,
                              ...getValues(),
                              leId: id,
                              returnLink: `${ERouteLinks.LegalEntityAddTransactionForAncillaryGroup.replace(
                                ':id',
                                id,
                              ).replace(
                                ':groupId',
                                groupId,
                              )}?type=${type}&expand=ancillaryGroups`,
                            },
                          },
                        )
                      }
                      fullWidth={!isDesktop}
                      label={t('selectLocationOnMap.page.text')}
                      variant={EButtonVariants.outlined}
                      size={EButtonSizes.small}
                      sx={{ marginTop: '28px' }}
                    />
                  </Grid>
                  {isErrorLocation && (
                    <Grid item xs={12}>
                      <FormErrorLabel
                        label={t(
                          'addTransaction.location.section.selectLocationOnMap.errorLabel',
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </Accordion>
            </Grid>
          )}
          {isShowCountryOfImport && (
            <Grid item xs={12}>
              <Accordion
                title={t('addTransaction.countryOfImport.section.name')}
                alwaysExpanded
              >
                <Grid mb={2} container columnSpacing={2}>
                  <Grid item xs={12} lg={6}>
                    <FormInput
                      name="importer"
                      label={t(
                        'addTransaction.countryOfImport.section.importer.label.validation',
                      )}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormSelect
                      options={mapDictionaryToOptionProp(country)}
                      name="countryOfImportUniqueId"
                      setValue={setValue}
                      label={t(
                        'addTransaction.countryOfImport.section.country.label.validation',
                      )}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          )}
          {isShowCountryOfExport && (
            <Grid item xs={12}>
              <Accordion
                title={t('addTransaction.countryOfExport.section.name')}
                alwaysExpanded
              >
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormSelect
                      options={mapDictionaryToOptionProp(country)}
                      name="countryOfExportUniqueId"
                      label={t('addTransaction.countryOfExport.section.name')}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}
          {isShowExpirationDate && (
            <Grid item xs={12}>
              <Accordion title={t('addTransaction.expiration.section.name')} alwaysExpanded>
                <Box mb={4}>
                  <Grid item xs={12} lg={6}>
                    <FormDatePicker
                      name="expirationDate"
                      label={t(
                        'addTransaction.expiration.section.expirationDate.label.validation',
                      )}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          {isModificationDescription && (
            <Grid item xs={12}>
              <Accordion
                title={t('ancillaries.transaction.modification.label')}
                alwaysExpanded
              >
                <Box mb={4}>
                  <Grid item xs={12} lg={12}>
                    <FormInput
                      name="description"
                      type={EFormInputTypes.textarea}
                      label={`${t('ancillaries.transaction.modification.label')}*`}
                      placeholder={t('ancillaries.transaction.modification.label')}
                      setValue={setValue}
                      control={control}
                      errors={errors}
                      multiline
                      minRows={4}
                    />
                  </Grid>
                </Box>
              </Accordion>
            </Grid>
          )}

          <Grid item xs={12}>
            <Accordion title={t('addTransaction.document.section.name')} alwaysExpanded>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Alert
                        text={t('general.field.availableAttachmentFormats.text')}
                        variant={EAlertVariants.warning}
                      />
                    </Box>
                  </Grid>
                  {attachments?.fields?.map((attachment: any, index) => (
                    <Grid key={attachment.id} item xs={12} container mb={!isDesktop ? 2 : 0}>
                      <Grid item xs={attachments.fields?.length > 1 ? 11 : 12} lg={6}>
                        <FormInput
                          name={`attachments[${index}].attachmentName`}
                          placeholder={attachment?.file?.name ?? ''}
                          label={t('addTransaction.document.section.attachmentName.label')}
                          control={control}
                          errors={errors}
                          arrayName="attachments"
                          arrayIndex={index}
                          fieldName="attachmentName"
                          InputProps={{ disabled: attachment?.file === null }}
                        />
                      </Grid>
                      {!isDesktop && renderRemoveAttachmentButton(index)}
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}
                      >
                        <Button
                          isUploadButton
                          onClick={(files) => handleUploadFile(files[0], index)}
                          fullWidth
                          label={t('addTransaction.document.section.browseFiles.button')}
                          id="add_file-button"
                          icon={EIconTypes.plus}
                          size={EButtonSizes.small}
                          isMultiple
                        />
                        {errors &&
                          errors.attachments &&
                          errors.attachments[index] &&
                          errors.attachments[index].file && (
                            <FormErrorLabel label={errors.attachments[index].file?.message} />
                          )}
                      </Grid>
                      {isDesktop && renderRemoveAttachmentButton(index)}
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={3} sx={{ mt: { xs: 2, lg: 0 } }}>
                    <Button
                      onClick={() =>
                        attachments.append({
                          attachmentName: '',
                          file: null,
                        })
                      }
                      fullWidth
                      label={t('addTransaction.document.section.addFile.button')}
                      id="add_file-button"
                      variant={EButtonVariants.outlined}
                      size={EButtonSizes.small}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Accordion>
          </Grid>
          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  onClick={onSubmit}
                  fullWidth
                  disabled={
                    !transactionTypeIsValid || !isCurrentKeeperLoaded || !isCurrentOwnerLoaded
                  }
                  isLoading={submitBlocked}
                  label={t('addTransaction.save.button')}
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('addTransaction.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(-1)}
                />
              </Grid>
              <Grid xs={12} item>
                <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            {createTransactionError && (
              <Box mb={2}>
                <Alert text={createTransactionError} variant={EAlertVariants.error} />
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LegalEntityAddTransactionForAncillaryGroupPage;
