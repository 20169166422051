import styled from '@emotion/styled';
import { colors } from 'theme/colors';

export const StyledBlock = styled.div`
  display: grid;
  align-content: center;
  background-color: ${colors.gray100};
  padding: 20px;
  height: 120px;
  border-radius: 4px;
`;
