import styled from '@emotion/styled';
import { colors } from 'theme/colors';

export const DropzoneWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 40px 0 40px 0;
  border-radius: 4px;
  border: 1px dashed ${colors.primary200};
  color: ${colors.primary200};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
`;
