import React, { useEffect } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import CustomFieldForm from 'pages/CustomFieldsPage/CustomFieldForm';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { ICustomFieldFormValues } from 'models/customField';
import { useNavigate } from 'react-router-dom';
import { useCustomFields } from 'pages/CustomFieldsPage/hooks';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Box } from '@mui/material';

const AddCustomFieldPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { handleCreateCustomField } = useCustomFields();
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleSubmit = async (data: ICustomFieldFormValues) => {
    try {
      await handleCreateCustomField(data);
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('customField.has.added.successfully'),
        variant: EAlertVariants.success,
      });
      navigate(ERouteLinks.CustomFields);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('customFields.header'),
            route: ERouteLinks.CustomFields,
          },
          t('customFields.add.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('customFields.add.header')}</StyledPageTitle>

      <TableFormWrapper>
        <CustomFieldForm
          onSubmit={handleSubmit}
          onCancel={() => navigate(ERouteLinks.CustomFields)}
        />
      </TableFormWrapper>

      {samePageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export default AddCustomFieldPage;
