import React, { PropsWithChildren } from 'react';
import { StyledSearchWrapper } from './styles';

interface ISearchWrapper extends PropsWithChildren<{}> {}

const SearchWrapper = ({ children }: ISearchWrapper) => (
  <StyledSearchWrapper>{children}</StyledSearchWrapper>
);

export { SearchWrapper };
