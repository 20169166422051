import { ESearchFormTransactionLabels } from 'pages/TransactionsPage/TransactionSearchForm';

export const prepareFilters = (data: any, idKey: string, labelKey: string) => {
  if (!data) {
    return data;
  }

  const result: any = {};
  data.forEach((item: any) => {
    if (
      item &&
      Object.prototype.hasOwnProperty.call(item, idKey) &&
      Object.prototype.hasOwnProperty.call(item, labelKey)
    ) {
      result[item[idKey]] = item[labelKey];
    }
  });
  return result;
};

const excludedTransactionFilterKeys = ['ownerUniqueId', 'keeperUniqueId'];

export const preparePresetTransactionFilters = (
  array: { key: string; value: string }[],
  t: (key: string) => string,
) => {
  array.map((item) => {
    if (item.key === 'ownerName') item.key = t(ESearchFormTransactionLabels.ownerName);
    if (item.key === 'keeperName') item.key = t(ESearchFormTransactionLabels.keeperName);
    return item;
  });
  return array.filter(
    (item) => item.key.length && !excludedTransactionFilterKeys.includes(item.key),
  );
};
