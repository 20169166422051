import { IFormElementOptionProp } from 'models/form';

export interface ISort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface IPaginator {
  pageable: {
    sort: ISort;
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: number;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: ISort;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export const perPages: IFormElementOptionProp[] = [
  { label: '25', value: '25' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];
