import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { useFirearms } from 'pages/Firearms/hooks';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { PDFDocumentWrapper } from 'pages/Firearms/ViewRegisteredFirearmAttachment/styles';
import { Loader } from 'components/atoms/Loader';
import { Box } from '@mui/system';
import { PdfjsWorkerPath } from 'constants/PdfjsWorkerPath';

pdfjs.GlobalWorkerOptions.workerSrc = PdfjsWorkerPath;

export const ViewRegisteredFirearmAttachment = () => {
  const navigate = useNavigate();
  const { handlePreviewAttachment } = useFirearms();
  const { id, attachmentId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [attachmentUrl, setAttachmentUrl] = useState<any>(null);
  const [pagesNumber, setPagesNumber] = useState<number | null>(null);

  useEffect(() => {
    (async function () {
      if (id && attachmentId) {
        setAttachmentUrl(await handlePreviewAttachment(id, attachmentId));
        setIsLoading(false);
      } else navigate(-1);
    })();
  }, []);

  return isLoading ? (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader isLoading={true} />
    </Box>
  ) : (
    <PDFDocumentWrapper>
      {attachmentUrl && (
        <Document
          file={attachmentUrl}
          onLoadSuccess={({ numPages }) => setPagesNumber(numPages)}
          loading={null}
        >
          {
            /* eslint-disable-next-line react/jsx-key */
            [...Array(pagesNumber).keys()].map((page) => (
              <Page pageNumber={page + 1} scale={1.5} loading={null} />
            ))
          }
        </Document>
      )}
    </PDFDocumentWrapper>
  );
};
