import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';

interface IRegisteredFirearmTableCard {
  data: ITableDataSource;
  renderDataColor: () => any;
}

const RegisteredFirearmTableCard = ({
  data,
  renderDataColor,
}: IRegisteredFirearmTableCard) => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderDataColor().border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderDataColor().background,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          FireCycle ID
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('accessoriesTable.accordion.exhibitNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.exhibitNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.make.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.make)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.model.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.model)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.manufacturer.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.caliber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.owner.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('registeredFirearms.accordion.state.column')}
        </Typography>
        {data.state ? (
          <Chips text={emptyFieldHolder(data.state)} variant={renderDataColor().stateField} />
        ) : (
          '-'
        )}
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('registeredFirearms.accordion.actions.column')}:{' '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} container spacing={2} sx={{ justifyContent: 'end' }}>
          {hasPermissions([EPermission.VIEW_FIREARMS]) && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('registeredFirearms.accordion.details.link')}
                variant={EButtonVariants.outlined}
                onClick={() => {
                  navigate(
                    `${ERouteLinks.ViewRegisteredFirearm.replace(':id', data.id.toString())}`,
                  );
                }}
                size="small"
                fullWidth
              />
            </Grid>
          )}

          {hasPermissions([EPermission.MODIFY_FIREARM]) && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('registeredFirearms.accordion.edit.link')}
                variant={EButtonVariants.outlined}
                onClick={() => {
                  navigate(
                    `${ERouteLinks.EditRegisteredFirearm.replace(':id', data.id.toString())}`,
                    {
                      state: {
                        prevPage: `${ERouteLinks.Firearms}`,
                      },
                    },
                  );
                }}
                size="small"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { RegisteredFirearmTableCard };
