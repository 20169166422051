import * as yup from 'yup';
import { ERegExpTypes } from 'constants/RegExpTypes';
import { useTranslations } from 'hooks/useTranslations';

export const useChangePasswordSchema = () => {
  const { t } = useTranslations();

  return yup.object({
    currentPassword: yup
      .string()
      .required(t('general.field.validation.required'))
      .min(8, t('changePasswordPage.validation.passwordNotValid.text'))
      .max(64, t('changePasswordPage.validation.passwordNotValid.text'))
      .matches(
        new RegExp(ERegExpTypes.password, 'g'),
        t('changePasswordPage.validation.passwordNotValid.text'),
      ),

    newPassword: yup
      .string()
      .required(t('general.field.validation.required'))
      .min(8, t('changePasswordPage.validation.passwordNotValid.text'))
      .max(64, t('changePasswordPage.validation.passwordNotValid.text'))
      .matches(
        new RegExp(ERegExpTypes.password, 'g'),
        t('changePasswordPage.validation.passwordNotValid.text'),
      ),

    newPasswordRepeat: yup
      .string()
      .required(t('general.field.validation.required'))
      .oneOf(
        [yup.ref('newPassword'), null],
        t('changePasswordPage.validation.passwordsDifferent.text'),
      ),
  });
};
