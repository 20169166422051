export enum ESearchGroupFormLabelsTypes {
  firearmSerialNumber = 'legalEntityDetails.firearm.accordion.serialNumber.column',
}

export enum ESearchUserFormLabelsTypes {
  email = 'users.email.label',
  firstName = 'users.firstName.label',
  lastName = 'users.lastName.label',
  phoneNumber = 'users.phoneNumber.label',
  status = 'users.status.label',
  role = 'users.role.label',
}

export enum ESearchAncillariesFormLabelsTypes {
  ancillaryUniqueId = 'legalEntityDetails.ancillaries.accordion.ancillary.column',
  ancillaryTypeUniqueId = 'legalEntityDetails.ancillaries.accordion.type.column',
  fireCycleId = 'legalEntityDetails.ancillaries.accordion.firecycleId.column',
  stateUniqueId = 'legalEntityDetails.ancillaries.accordion.state.column',
}

export enum ESearchAncillaryGroupsFormLabelsTypes {
  ancillary = 'legalEntityDetails.ancillaries.accordion.ancillary.column',
}

export enum ESearchFirearmFormLabelsTypes {
  serialNumber = 'legalEntityDetails.firearm.accordion.serialNumber.column',
  externalReferenceNo = 'legalEntityDetails.firearm.accordion.externalReferenceNo.label',
  make = 'legalEntityDetails.firearm.accordion.make.column',
  stateUniqueId = 'legalEntityDetails.firearm.accordion.state.column',
}

export enum ESearchGroupFirearmFormLabelsTypes {
  serialNumber = 'groupDetails.firearmListSection.serialNumber.label',
  make = 'groupDetails.firearmListSection.make.label',
  model = 'groupDetails.firearmListSection.model.label',
  caliber = 'groupDetails.firearmListSection.calibre.label',
}

export enum ESearchGroupAncillaryFormLabelsTypes {
  ancillaryType = 'ancillaries.searchForAncillary.ancillary.filter.label',
  ancillarySpecificTypeUniqueId = 'ancillaries.searchForAncillary.ancillaryType.filter.label',
  firearmType = 'ancillaries.searchForAncillary.firearmType.filter.label',
  serialNumber = 'ancillaries.searchForAncillary.serialNumber.filter.label',
  lotNumber = 'ancillaries.searchForAncillary.lotNumber.filter.label',
  batchNumber = 'ancillaries.searchForAncillary.batchNumber.filter.label',
  manufacturerUniqueId = 'ancillaries.searchForAncillary.manufacturer.filter.label',
}

export enum ESearchFirearmsFormLabelsTypes {
  serialNumber = 'searchForLegalEntitysFirearm.serialNumber.label',
  externalReferenceNumber = 'searchForLegalEntitysFirearm.externalReferenceNo.label',
  stateUniqueId = 'searchForLegalEntitysFirearm.state.label',
  make = 'searchForLegalEntitysFirearm.make.label',
  model = 'searchForLegalEntitysFirearm.model.label',
  legalityUniqueId = 'searchForLegalEntitysFirearm.legality.label',
  manufacturer = 'searchForLegalEntitysFirearm.manufacturer.label',
  caliber = 'searchForLegalEntitysFirearm.calibre.label',
}

export enum ESearchTransactionFormLabelsTypes {
  stateUniqueId = 'registeredFirearms.firearmsDetails.transactions.accordion.state.label',
  transactionDateFrom = 'registeredFirearms.firearmsDetails.transactions.accordion.transactionDateFrom.label',
  transactionDateTo = 'registeredFirearms.firearmsDetails.transactions.accordion.transactionDateTo.label',
}
