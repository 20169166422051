import React, { useEffect } from 'react';
import {
  StyledFormSelect,
  StyledSearchButton,
  StyledSearchInput,
  StyledSearchWrapper,
} from 'pages/DashboardPage/GlobalSearch/styles';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { InputAdornment } from '@mui/material';
import Icon from 'components/atoms/Icon/Icon';
import { EIconTypes } from 'constants/Icons';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { EGlobalSearchCategories } from 'models/dashboard';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { useTranslations } from 'hooks/useTranslations';

interface IGlobalSearchForm {
  category: string | undefined;
  query: string;
}

interface IGlobalSearch {
  query?: string | null;
  category?: string | null;
}

const initialFormValues: IGlobalSearchForm = {
  category: '',
  query: '',
};

const GlobalSearch = ({ query = '', category = '' }: IGlobalSearch) => {
  const navigate = useNavigate();
  const { hasPermissions } = usePermission();
  const { t } = useTranslations();

  const searchCategories = [
    ...(hasPermissions([EPermission.VIEW_ALL_LE, EPermission.VIEW_LE], true)
      ? [
          {
            label: t('dashboard.search.legalEntitiesIndividual.label'),
            value: EGlobalSearchCategories.LegalEntitiesIndividual,
          },
          {
            label: t('dashboard.search.legalEntitiesOrganisation.label'),
            value: EGlobalSearchCategories.LegalEntitiesOrganisation,
          },
        ]
      : []),

    ...(hasPermissions([EPermission.VIEW_ALL_FIREARMS, EPermission.VIEW_FIREARMS], true)
      ? [
          {
            label: t('dashboard.search.firearms.label'),
            value: EGlobalSearchCategories.Firearms,
          },
        ]
      : []),

    ...(hasPermissions(
      [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
      true,
    )
      ? [
          {
            label: t('dashboard.search.accessories.label'),
            value: EGlobalSearchCategories.Accessories,
          },
        ]
      : []),

    ...(hasPermissions(
      [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
      true,
    )
      ? [
          {
            label: t('dashboard.search.ammunition.label'),
            value: EGlobalSearchCategories.Ammunition,
          },
        ]
      : []),

    ...(hasPermissions(
      [EPermission.VIEW_LIMITED_ANCILLARIES, EPermission.VIEW_ALL_ANCILLARIES],
      true,
    )
      ? [
          {
            label: t('dashboard.search.components.label'),
            value: EGlobalSearchCategories.Components,
          },
        ]
      : []),

    ...(hasPermissions(
      [EPermission.VIEW_TRANSACTIONS, EPermission.VIEW_ALL_TRANSACTIONS],
      true,
    )
      ? [
          {
            label: t('dashboard.search.transactions.label'),
            value: EGlobalSearchCategories.Transactions,
          },
        ]
      : []),

    ...(hasPermissions([EPermission.MANAGE_USERS])
      ? [
          {
            label: t('dashboard.search.users.label'),
            value: EGlobalSearchCategories.Users,
          },
        ]
      : []),
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<IGlobalSearchForm>({
    defaultValues: initialFormValues,
  });

  useEffect(() => {
    reset({
      ...initialFormValues,
      ...(query ? { query } : {}),
      ...(category ? { category } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, category]);

  const onSubmit = (values: IGlobalSearchForm) => {
    const queryValue = values?.query.trim();
    navigate(
      `${ERouteLinks.DashboardSearchResultsPage}?query=${encodeURIComponent(queryValue)}${
        values?.category ? `&category=${values.category.trim()}` : ''
      }`,
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledSearchWrapper container>
        <Grid
          container
          item
          xs={12}
          rowSpacing={1}
          sx={{ height: 'fit-content', alignSelf: 'center' }}
        >
          <Grid item lg={3} xs={12}>
            <StyledFormSelect
              options={searchCategories}
              name="category"
              control={control}
              setValue={setValue}
              errors={errors}
              withValidation={false}
              withLabel={false}
            />
          </Grid>
          <Grid item lg={7} xs={12}>
            <StyledSearchInput
              name="query"
              control={control}
              errors={errors}
              setValue={setValue}
              placeholder={`${t('general.search.label')}...`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon type={EIconTypes.search} />
                  </InputAdornment>
                ),
              }}
              withValidation={false}
              withLabel={false}
            />
          </Grid>
          <Grid item lg={2} xs={12}>
            <StyledSearchButton
              type="submit"
              variant={EButtonVariants.contained}
              label={t('general.search.label')}
              fullWidth
            />
          </Grid>
        </Grid>
      </StyledSearchWrapper>
    </form>
  );
};

export default GlobalSearch;
