export interface IApiClientResponse {
  success: boolean | string;
  data: any;
  responseError?: string;
}

export type TResponse<T> = IRestApiError | T;

export interface IRestApiError {
  data: {
    error?: string;
    status: number;
    statusCode: number;
    errorMessages?: Array<string>;
    message?: string;
  };
  uniqueErrorId: string;
  timestamp: Date;
  path: string;
  status: number;
  statusCode: number;
  errorMessages: Array<String>;
}

export interface IRestApiResponseError {
  response: IRestApiError;
  data: any;
  status: number;
}

export const isResponseError = <T>(value: TResponse<T>): value is IRestApiError =>
  // eslint-disable-next-line no-prototype-builtins
  (value as object).hasOwnProperty('error');
