import React, { useRef } from 'react';
import { useMatch } from 'react-router-dom';
import AncillariesReferenceTablePage from 'pages/AncillariesPage/AncillariesReferenceTablePage';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';

const SearchForARTAncillaryPage = () => {
  const { t } = useTranslations();
  const transactionPagePath = useMatch(ERouteLinks.LegalEntityViewAncillary);
  const queryParams = useQuery();
  const type: string | null = queryParams.get('type');

  const breadcrumbs = useRef([
    { label: t('addAncillary.legalEntities.breadcrumb'), route: ERouteLinks.LegalEntities },
    {
      label:
        type === ELegalEntityTypes.individual
          ? t('addAncillary.individual.breadcrumb')
          : t('addAncillary.organisation.breadcrumb'),
      route: `${ERouteLinks.LegalEntities}?expand=${
        type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
      }`,
    },
    {
      label: t('addAncillary.legalEntityDetails.breadcrumb'),
      route: `${ERouteLinks.ViewLegalEntity.replace(
        ':id',
        transactionPagePath?.params?.leId ?? '',
      )}?type=${type}&expand=ancillaries`,
    },
    t('addAncillary.addAncillary.breadcrumb'),
  ]);

  breadcrumbs.current = [
    { label: t('addAncillary.legalEntities.breadcrumb'), route: ERouteLinks.LegalEntities },
    {
      label:
        type === ELegalEntityTypes.individual
          ? t('addAncillary.individual.breadcrumb')
          : t('addAncillary.organisation.breadcrumb'),
      route: `${ERouteLinks.LegalEntities}?expand=${
        type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
      }`,
    },
    {
      label: t('addAncillary.legalEntityDetails.breadcrumb'),
      route: `${ERouteLinks.ViewLegalEntity.replace(
        ':id',
        transactionPagePath?.params?.leId ?? '',
      )}?type=${type}&expand=ancillaries`,
    },
    t('addAncillary.addAncillary.breadcrumb'),
  ];

  return (
    <>
      <Breadcrumbs items={breadcrumbs.current} />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">
          {t('addAncillary.searchForARTAncillary.header')}
        </StyledPageTitle>
      </Box>
      <AncillariesReferenceTablePage />
    </>
  );
};

export default SearchForARTAncillaryPage;
