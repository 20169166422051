import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import LoginBackground from 'assets/images/login-background.png';

const StyledBackgroundSection = styled(Grid)`
  background-image: url(${LoginBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
const StartLicenceTemplate = ({ children }: PropsWithChildren<{}>) => (
  <Grid container justifyContent="center" sx={{ minHeight: '100vh' }}>
    {children}
    <StyledBackgroundSection
      item
      flexDirection="column"
      justifyContent="space-between"
      lg={6}
      sx={{ display: { xs: 'none', xl: 'flex' } }}
      p={10}
    />
  </Grid>
);

export { StartLicenceTemplate };
