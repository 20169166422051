import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useSafePresetSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    name: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    notificationEnabled: yup.boolean().when('emailNotificationEnabled', {
      is: true,
      then: (yupSchema) =>
        yupSchema.oneOf([true], t('saveMyFilter.validation.notifyViaSystemMustBeChecked')),
    }),
  });
  return { schema };
};

export default useSafePresetSchema;
