import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { getLegalEntityGroup } from 'requests/legalEntities';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { StyledDetailsWrapper } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage/styles';
import { ILegalEntityGroupResponse } from 'models/legalEntity';
import { useTranslations } from 'hooks/useTranslations';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

const LegalEntityDeleteGroupPage = () => {
  const { id, groupId } = useParams<{ id: string; groupId: string }>();
  const query = useQuery();
  const [legalEntityGroup, setLegalEntityGroup] = useState<ILegalEntityGroupResponse | null>();
  const { t } = useTranslations();

  const { handleDeleteGroup } = useLegalEntities();
  const { navigateToDetails } = useLegalEntities();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const type: string = query.get('type') || '';

  useEffect(() => {
    (async function getLegalEntityGroupDataInit() {
      if (id && groupId) {
        const response = await getLegalEntityGroup(id, groupId);
        if (response) {
          setLegalEntityGroup(response);
        }
      }
    })();
  }, [id, groupId]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (id && legalEntityGroup) {
        try {
          const result = await handleDeleteGroup(id, legalEntityGroup.uniqueId);
          if (result) {
            navigateToDetails();
          }
        } catch (e: any) {
          console.error(e);
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=groups`,
          },
          t('deleteGroup.header'),
        ]}
      />
      {legalEntityGroup && (
        <>
          <StyledPageTitle variant="h4">{t('deleteGroup.header')}</StyledPageTitle>

          <Box my={3}>
            <Alert text={t('deleteGroup.info.text')} variant={EAlertVariants.warning} />
          </Box>

          <StyledDetailsWrapper container gap={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteGroup.groupName.label')}
              </Typography>
              <Typography variant="body2">{legalEntityGroup.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteGroup.owner.label')}
              </Typography>
              <Typography variant="body2">{legalEntityGroup.owner || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteGroup.keeper.label')}
              </Typography>
              <Typography variant="body2">{legalEntityGroup.keeper || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteGroup.totalFirearmCount.label')}
              </Typography>
              <Typography variant="body2">{legalEntityGroup.firearmAmount}</Typography>
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                id="delete-group-yes-button"
                label={t('general.yes.button')}
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                id="delete-group-no-button"
                label={t('general.no.button')}
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { LegalEntityDeleteGroupPage };
