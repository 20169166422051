import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IRegisteredFirearmData } from 'models/registeredFirearm';
import { getRegisteredFirearm, updateFirearmCustomField } from 'requests/firearm';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useTranslations } from 'hooks/useTranslations';
import { Loader } from 'components/atoms/Loader';
import { RegisteredFirearmDetails } from 'components/organisms/RegisteredFirearmDetails';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import FirearmCustomFieldsForm from 'pages/Firearms/EditRegisteredFirearmPage/FirearmCustomFieldsForm';
import { StyledPageTitle } from 'theme/styles';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';
import { formatDateTimeToIso } from 'utils/date';
import { areCustomFieldsValuesEqual } from 'utils/string';
import { EFirearmStateId } from 'models/firearm';

const EditRegisteredFirearmPage = () => {
  const { t } = useTranslations();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [firearm, setFirearm] = useState<IRegisteredFirearmData | null>();
  const [isFirearmLoading, setIsFirearmLoading] = useState<boolean>(false);
  const { getCustomField, customFieldsFirearms, customFieldsLoaded } = useLegalEntities();
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await getCustomField(ECustomFieldObjectType.FIREARM);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function getFirearmDataInit() {
      setIsFirearmLoading(true);
      if (id) {
        const response = await getRegisteredFirearm(id);
        if (response) {
          if (response.customFields) {
            response.customFields?.map((customField, index) => {
              if (
                customField.customFieldType === ECustomFieldType.BOOL &&
                customField.value === null
              ) {
                delete response?.customFields?.[index];
              }
              return false;
            });
            response.customFields = response.customFields?.filter((item) => item);
          }

          setFirearm(response);
        }
        setIsFirearmLoading(false);
      }
    })();
  }, [id]);

  const onSubmitForm = async (data: { [key: string]: any }) => {
    if (firearm?.version === undefined) {
      return;
    }

    setSubmitLoading(true);
    let customFieldPayload: ICustomFieldForm[] = [];

    Object.keys(data).map((key) => {
      const customField = customFieldsFirearms.find((item) => item.name === key);
      if (customField) {
        const customFieldItem: ICustomFieldForm = {
          customFieldUniqueId: customField.uniqueId,
        };
        if (customField.customFieldType === ECustomFieldType.TEXT) {
          if (data[key] === undefined || data[key] === null) {
            customFieldItem.text = undefined;
          } else if (data[key].trim() === '') {
            customFieldItem.text = undefined;
          } else {
            customFieldItem.text = data[key];
          }
        } else if (customField.customFieldType === ECustomFieldType.BOOL) {
          if (data[key] === '') {
            customFieldItem.bool = null;
          } else {
            customFieldItem.bool = data[key] === '1';
          }
        } else if (customField.customFieldType === ECustomFieldType.DATE) {
          customFieldItem.date = data[key] ? formatDateTimeToIso(data[key]) : null;
        }
        customFieldPayload.push(customFieldItem);
        delete data[key];
      }
      return false;
    });

    if (firearm?.customFields) {
      customFieldPayload = customFieldPayload.filter((item) => {
        const cfItem = firearm?.customFields?.find(
          (cf) => cf.customFieldUniqueId === item.customFieldUniqueId,
        );
        const value = item?.text || item?.date || item?.bool;
        if (cfItem) {
          if (
            item.date !== undefined &&
            item.date !== null &&
            cfItem.value !== undefined &&
            cfItem.value !== null &&
            typeof value !== 'boolean' &&
            cfItem.customFieldType === ECustomFieldType.DATE
          ) {
            const formattedValue = formatDateTimeToIso(value, true);
            const newValue = formattedValue.substring(0, formattedValue.indexOf('T'));
            const oldValue = cfItem.value.substring(0, cfItem.value.indexOf('T'));
            return !areCustomFieldsValuesEqual(newValue, oldValue);
          }
          return !areCustomFieldsValuesEqual(value, cfItem.value);
        }
        return !areCustomFieldsValuesEqual(value, undefined);
      });
    }

    try {
      if (customFieldPayload.length > 0) {
        await updateFirearmCustomField(id as string, customFieldPayload, firearm?.version);
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('customField.has.updated.successfully'),
          variant: EAlertVariants.success,
        });
        navigate(ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string));
      } else {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: t('editCustomFieldData.save.warning.text'),
          variant: EAlertVariants.warning,
        });
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.accordion.name'),
            route: ERouteLinks.Firearms,
          },
          {
            label: t('registeredFirearms.firearmsDetails.header'),
            route: ERouteLinks.ViewRegisteredFirearm.replace(':id', id as string),
          },
        ]}
      />
      <StyledPageTitle variant="h4" sx={{ marginBottom: '20px' }}>
        {t('firearmsReferenceTable.editFirearm.editFirearm.header')}
      </StyledPageTitle>
      <div>
        {isFirearmLoading ? (
          <Loader isLoading={isFirearmLoading} />
        ) : (
          firearm && (
            <>
              <RegisteredFirearmDetails
                firearm={firearm}
                disableActions={true}
                justShowFRT={true}
              />
              {(customFieldsLoaded && !customFieldsFirearms?.length) ||
              firearm.stateUniqueId === EFirearmStateId.DISABLED ? (
                <Box sx={{ margin: '20px 0' }}>
                  <Alert
                    text={t('general.noAvaibleDataToEdit.label')}
                    variant={EAlertVariants.error}
                  />
                </Box>
              ) : (
                <FirearmCustomFieldsForm
                  customFields={customFieldsFirearms}
                  firearm={firearm}
                  onSubmit={onSubmitForm}
                  isSubmitLoading={isSubmitLoading}
                />
              )}

              {samePageAlert && (
                <Box mt={2}>
                  <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
                </Box>
              )}
            </>
          )
        )}
      </div>
    </>
  );
};

export { EditRegisteredFirearmPage };
