import {
  EDictionaryTypes,
  ESpecificDictionaryTypes,
  ETransactionDictionaryItemTypes,
} from 'models/dictionary';

export const ApiEndpoints = {
  LOG_IN: (): string => 'auth/login',
  LOG_OUT: (): string => 'auth/logout',
  REFRESH_AUTH_TOKEN: (): string => 'auth/refresh',
  ACCOUNT_CHANGE_PASSWORD: (): string => 'auth/password',
  GET_ROLES: (): string => 'role',
  CREATE_ROLE: (): string => 'role',
  UPDATE_ROLE: (id: string): string => `role/${id}`,
  GET_ROLE: (id: string): string => `role/${id}`,
  DELETE_ROLE: (id: string): string => `role/${id}`,
  GET_PERMISSIONS: (): string => 'permissions',
  GET_USERS: (): string => 'accounts',
  CREATE_USER: (): string => 'accounts',
  UPDATE_USER: (id: string): string => `accounts/${id}`,
  UPDATE_SELF_ACCOUNT: (): string => 'accounts/self',
  GET_USER: (id: string): string => `accounts/${id}`,
  DELETE_USER: (id: string): string => `accounts/${id}`,
  ACTIVATE_USER: (id: string): string => `accounts/${id}/active`,
  UNBAN_USER: (id: string): string => `auth/unban/${id}`,
  CHANGE_FORGOT_PASSWORD_RECAPTCHA: (query: string): string => `auth/recaptcha${query}`,
  FORGOT_PASSWORD: (): string => 'auth/password/forgot',
  CHANGE_PASSWORD: (queryParam: string): string => `auth/password/change${queryParam}`,
  GENERATE_PASSWORD: (): string => 'auth/password/generate',
  GENERATE_AUTH_CODE: (email: string): string => `auth/${email}/generate-authentication-code`,
  VERIFY_AUTH_CODE: (): string => 'auth/verify',
  GET_TRUSTED_DEVICES: (): string => 'accounts/trusted-device',
  ADD_TRUSTED_DEVICE: (): string => 'accounts/trusted-device',
  FETCH_TRUSTED_DEVICES: (): string => 'accounts/trusted-device',
  DELETE_TRUSTED_DEVICE: (id: string) => `accounts/trusted-device/${id}`,
  CREATE_DICTIONARY_VALUE: (type: EDictionaryTypes): string => `dictionary/${type}`,
  UPDATE_DICTIONARY_VALUE: (id: string): string => `dictionary/${id}`,
  GET_DICTIONARY: (type: EDictionaryTypes): string => `dictionary/${type}/read-all`,
  GET_DICTIONARY_LIKE: (type: EDictionaryTypes): string =>
    `dictionary/${type}/read-all-active/search`,
  GET_DICTIONARY_LANGUAGES: (type: EDictionaryTypes): string => `dictionary/${type}/languages`,
  GET_DICTIONARY_DETAIL: (type: EDictionaryTypes): string => `dictionary/${type}`,
  GET_DICTIONARIES: (): string => 'dictionary',
  GET_DICTIONARY_BY_ID: (id: string): string => `dictionary/${id}/read`,
  GET_SPECIFIC_DICTIONARY: (type: ESpecificDictionaryTypes): string => `dictionary/${type}`,
  GET_LICENCE_DETAILS: (): string => 'licence/details',
  REDEEM_LICENCE_KEY: (): string => 'licence/redeem',
  GET_LICENCE_BASIC_INFO: (): string => 'licence/basic-info',
  GET_INITIAL_TRANSACTION_DICTIONARY: (itemType: ETransactionDictionaryItemTypes) =>
    `dictionary/transaction/initial-types?itemType=${itemType}`,
  GET_MIDDLE_TRANSACTION_DICTIONARY: (itemType: ETransactionDictionaryItemTypes) =>
    `dictionary/transaction/middle-types?itemType=${itemType}`,
  GET_POSSIBLE_TRANSACTION_TYPE: () => 'transaction/middle',
  GET_POSSIBLE_TRANSACTION_TYPE_FOR_GROUP: () => 'transaction/middle-for-group',
  GET_LEGAL_ENTITY: (id: string): string => `individuals/${id}`,
  GET_LEGAL_ENTITIES: (): string => 'individuals/search',
  GET_LEGAL_ENTITY_FIREARMS: (): string => 'legal/firearms/search',
  GET_LEGAL_ENTITY_ANCILLARIES: (): string => 'legal/ancillaries/search',
  GET_LEGAL_ENTITY_ANCILLARY_GROUP: (id: string): string => `/legal/${id}/groups/ancillaries`,
  GET_LEGAL_ENTITY_ANCILLARY_GROUP_ANCILLARIES: (id: string, groupId: string): string =>
    `/legal/${id}/groups/${groupId}/ancillaries`,
  DELETE_LE_GROUP_ANCILLARY: (id: string, groupId: string, ancillaryId: string): string =>
    `/legal/${id}/groups/${groupId}/ancillaries/${ancillaryId}`,
  CREATE_LEGAL_ENTITY_ANCILLARY_GROUP: (id: string): string =>
    `/legal/${id}/groups/ancillaries`,
  DELETE_LEGAL_ENTITY_ANCILLARY_GROUP: (groupId: string, LEId: string): string =>
    `/legal/${LEId}/groups/ancillaries/${groupId}`,
  GET_LEGAL_ENTITY_GROUPS: (id: string): string => `legal/${id}/groups`,
  GET_LEGAL_ENTITY_GROUP: (id: string, groupId: string): string =>
    `legal/${id}/groups/${groupId}`,
  GET_LEGAL_ENTITY_GROUP_FIREARMS: (leId: string, groupId: string): string =>
    `legal/${leId}/groups/${groupId}/firearms`,
  CREATE_LEGAL_ENTITY: (): string => 'individuals',
  UPDATE_LEGAL_ENTITY: (id: string): string => `individuals/${id}`,
  DELETE_LEGAL_ENTITY: (id: string): string => `individuals/${id}`,
  GET_ORGANIZATION_LEGAL_ENTITIES: (): string => 'organizations/search',
  GET_ORG_LEGAL_ENTITY: (id: string): string => `organizations/${id}`,
  CREATE_ORG_LEGAL_ENTITY: (): string => 'organizations',
  UPDATE_ORG_LEGAL_ENTITY: (id: string): string => `organizations/${id}`,
  DELETE_ORG_LEGAL_ENTITY: (id: string): string => `organizations/${id}`,
  CREATE_LEGAL_ENTITY_COMMENT: (id: string): string => `legal/${id}/comments`,
  GET_LEGAL_ENTITY_COMMENTS: (id: string): string => `legal/${id}/comments`,
  GET_LEGAL_TYPE: (id: string): string => `legal/${id}`,
  GET_LEGAL_ENTITY_COMMENT: (id: string, commentId: string): string =>
    `legal/${id}/comments/${commentId}`,
  DELETE_COMMENT: (id: string): string => `comment/${id}/visible`,
  GET_ACCOUNT: (id: string): string => `accounts/${id}`,
  GET_SELF_ACCOUNT: (): string => 'accounts/self',
  GET_LEGAL_ENTITY_ADDRESSES: (id: string): string => `legal/${id}/address`,
  GET_LEGAL_ENTITY_ADDRESS: (id: string, addressId: string): string =>
    `legal/${id}/address/${addressId}`,
  CREATE_LEGAL_ENTITY_ADDRESS: (id: string): string => `legal/${id}/address`,
  SET_LEGAL_ENTITY_DEFAULT_ADDRESS: (id: string, addressId: string): string =>
    `legal/${id}/address/${addressId}/default`,
  SEARCH_LOCATION_COORDS_BY_ADDRESS: (queryParam: string): string =>
    `https://nominatim.openstreetmap.org/search${queryParam}`,
  SEARCH_LOCATION_ADDRESS_BY_COORDS: (latitude: string, longitude: string): string =>
    `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`,
  SEARCH_LOCATION_COORDS_BY_ADDRESS_OFFLINE: (queryParam: string): string =>
    `${process.env.REACT_APP_NOMINATIM_URL}/search${queryParam}`,
  SEARCH_LOCATION_ADDRESS_BY_COORDS_OFFLINE: (latitude: string, longitude: string): string =>
    `${process.env.REACT_APP_NOMINATIM_URL}/reverse?lat=${latitude}&lon=${longitude}&format=json`,
  UPDATE_LEGAL_ENTITY_ADDRESS: (id: string, addressId: string): string =>
    `legal/${id}/address/${addressId}`,
  DELETE_LEGAL_ENTITY_ADDRESS: (id: string, addressId: string): string =>
    `legal/${id}/address/${addressId}`,
  CREATE_LEGAL_ENTITY_CONTACT: (id: string): string => `legal/${id}/contacts`,
  UPDATE_LEGAL_ENTITY_CONTACT: (id: string, contactId: string): string =>
    `legal/${id}/contacts/${contactId}`,
  GET_LEGAL_ENTITY_CONTACTS: (id: string): string => `legal/${id}/contacts`,
  GET_LEGAL_ENTITY_CONTACT: (id: string, commentId: string): string =>
    `legal/${id}/contacts/${commentId}`,
  DELETE_LEGAL_ENTITY_CONTACT: (id: string, contactId: string): string =>
    `legal/${id}/contacts/${contactId}`,
  CREATE_LEGAL_ENTITY_GROUP: (id: string): string => `legal/${id}/groups`,
  UPDATE_LEGAL_ENTITY_ANCILLARY_GROUP: (id: string, groupId: string): string =>
    `legal/${id}/groups/ancillaries/${groupId}`,
  UPDATE_LEGAL_ENTITY_FIREARM_GROUP: (id: string, groupId: string): string =>
    `legal/${id}/groups/${groupId}`,
  UPDATE_LEGAL_ENTITY_ADD_TO_GROUP: (id: string, groupId: string): string =>
    `legal/${id}/groups/${groupId}/ancillaries`,
  DELETE_LEGAL_ENTITY_GROUP: (id: string, groupId: string): string =>
    `legal/${id}/groups/${groupId}`,
  GET_LEGAL_ENTITY_ANCILLARIES_GROUP_DETAIL: (id: string, groupId: string): string =>
    `legal/${id}/groups/ancillaries/${groupId}`,
  GET_LEGAL_ENTITY_ANCILLARIES_FROM_GROUP: (groupId: string): string =>
    `ancillary-group/${groupId}`,
  DELETE_LEGAL_ENTITY_ANCILLARIES_GROUP: (id: string, groupId: string): string =>
    `legal/${id}/groups/ancillaries/${groupId}`,
  ASSIGN_LE: (id: string): string => `legal/${id}/accounts`,
  DELETE_ASSIGN_LE: (id: string): string => `legal/${id}/accounts`,
  GET_FIREARMS: (): string => 'firearm/search',
  GET_FIREARMS_FOR_MAP: (): string => 'firearm/search-for-map',
  GET_FIREARM: (id: string): string => `firearm/${id}`,
  UPDATE_FIREARM_CUSTOM_FIELDS: (id: string): string => `firearm/${id}/custom-field`,
  GET_FRT: (): string => 'FRT',
  GET_FRT_SPECIFICATIONS: (id: string): string => `FRT/${id}/specifications`,
  GET_FRT_NOTES: (id: string): string => `FRT/${id}/notes`,
  GET_FRT_CHECK_IS_USED: (id: string): string => `frt-local/${id}/check-is-used`,
  GET_FRT_DETAILS: (id: string): string => `FRT/${id}`,
  ADD_FRT_LOCAL: (): string => 'frt-local',
  UPDATE_FRT_LOCAL: (frtId: string): string => `frt-local/${frtId}`,
  CREATE_FRT_LOCAL_SPECIFICATION: (id: string) => `/frt-local/${id}/specification`,
  UPDATE_FRT_LOCAL_SPECIFICATION: (id: string, specificationId: string) =>
    `/frt-local/${id}/specification/${specificationId}`,
  GET_FIREARM_TRANSACTIONS: (id: string): string => `firearm/${id}/transactions`,
  DISABLE_FIREARM: (id: string): string => `firearm/${id}/disable`,
  GET_FIREARM_ATTACHMENTS: (id: string): string => `firearm/${id}/attachments`,
  GET_FIREARM_USED_TRANSACTION_TYPES: (id: string): string =>
    `firearm/${id}/used-transaction-types`,
  GET_FIREARM_COMMENTS: (id: string): string => `firearm/${id}/comments`,
  GET_FIREARM_LAST_TRANSACTION_UNIQUE_ID: (firearmUniqueId: string): string =>
    `transaction/${firearmUniqueId}/last-unique-id`,
  GET_GROUP_LAST_TRANSACTION_UNIQUE_ID: (groupUniqueId: string): string =>
    `transaction/${groupUniqueId}/last-unique-id/for-group`,
  GET_FIREARM_COMMENT: (id: string, commentId: string): string =>
    `firearm/${id}/comments/${commentId}`,
  CREATE_FIREARM_COMMENT: (id: string): string => `firearm/${id}/comments`,
  DOWNLOAD_FIREARM_ATTACHMENT: (firearmId: string, attachmentId: string) =>
    `firearm/${firearmId}/attachments/${attachmentId}`,
  ADD_FIREARM_ATTACHMENT: (firearmId: string): string => `firearm/${firearmId}/attachments`,
  ADD_FIREARM_ATTACHMENT_IN_TRANSACTION: (firearmId: string): string =>
    `firearm/${firearmId}/attachment-in-transaction`,
  DELETE_FIREARM_ATTACHMENT: (firearmId: string, attachmentId: string): string =>
    `firearm/${firearmId}/attachments/${attachmentId}`,
  EXPORT_REGISTERED_FIREARMS_CSV: (): string => 'reports/firearm/export',
  EXPORT_FIREARM_TRANSACTIONS_CSV: (firearmId: string): string =>
    `reports/firearm/${firearmId}/transactions/export`,
  DELETE_LE_GROUP_FIREARM: (id: string, groupId: string, firearmId: string): string =>
    `legal/${id}/groups/${groupId}/firearms/${firearmId}`,
  CREATE_LE_GROUP_FIREARM: (id: string, groupId: string): string =>
    `legal/${id}/groups/${groupId}/firearms`,
  GET_LANGUAGES: (): string => 'languages',
  GET_TRANSLATIONS: (): string => 'messages',
  EXPORT_ILE_CSV: (): string => 'reports/individual/export',
  EXPORT_OLE_CSV: (): string => 'reports/organization/export',
  VALIDATE_RESET_PASSWORD_LINK: (): string => 'auth/password/validateLink',
  GET_SEARCH_FILTER: (): string => 'search-filter',
  GET_SEARCH_FILTER_BY_TYPE: (type: string): string => `search-filter/by-type/${type}`,
  GET_SEARCH_FILTER_BY_ID: (id: string): string => `search-filter/${id}`,
  DELETE_SEARCH_FILTER: (id: string): string => `/search-filter/${id}`,
  CREATE_SEARCH_FILTER_INDIVIDUAL: (
    name: string,
    emailNotificationEnabled: boolean,
    notificationEnabled: boolean,
  ): string =>
    `search-filter/individual?name=${name}&emailNotificationEnabled=${emailNotificationEnabled}&notificationEnabled=${notificationEnabled}`,
  CREATE_SEARCH_FILTER_ORGANIZATION: (
    name: string,
    emailNotificationEnabled: boolean,
    notificationEnabled: boolean,
  ): string =>
    `search-filter/organization?name=${name}&emailNotificationEnabled=${emailNotificationEnabled}&notificationEnabled=${notificationEnabled}`,
  CREATE_SEARCH_FILTER_FIREARM: (
    name: string,
    emailNotificationEnabled: boolean,
    notificationEnabled: boolean,
  ): string =>
    `search-filter/firearm?name=${name}&emailNotificationEnabled=${emailNotificationEnabled}&notificationEnabled=${notificationEnabled}`,
  CREATE_SEARCH_FILTER_TRANSACTION: (
    name: string,
    emailNotificationEnabled: boolean,
    notificationEnabled: boolean,
  ): string =>
    `search-filter/transaction?name=${name}&emailNotificationEnabled=${emailNotificationEnabled}&notificationEnabled=${notificationEnabled}`,
  CREATE_SEARCH_FILTER_ACCESSORY: (
    name: string,
    emailNotificationEnabled: boolean,
    notificationEnabled: boolean,
  ): string =>
    `search-filter/ancillaries?name=${name}&emailNotificationEnabled=${emailNotificationEnabled}&notificationEnabled=${notificationEnabled}`,
  TOGGLE_SEARCH_FILTER_NOTIFICATION: (id: string, value: boolean): string =>
    `/search-filter/${id}/toggle-email-notification?value=${value}`,
  GET_FIREARMS_AVAILABLE_FOR_GROUP: (id: string): string =>
    `legal/${id}/groups/firearms/search-to-add`,
  CREATE_INITIAL_TRANSACTION: (): string => 'transaction/initial',
  CREATE_ANCILLARY_INITIAL_TRANSACTION: (): string => 'transaction/ancillary/initial',
  CREATE_MIDDLE_TRANSACTION_FOR_GROUP: (): string => 'transaction/middle-for-group',
  CREATE_MIDDLE_TRANSACTION: (): string => 'transaction/middle',
  GET_GLOBAL_PROPERTIES: (): string => 'global-property',
  GET_TRANSACTION: (): string => 'transaction',
  GET_TRANSACTION_DETAILS: (id: string): string => `transaction/${id}`,
  GET_TRANSACTION_DETAILS_FIREARMS: (id: string): string => `transaction/${id}/firearms`,
  GET_TRANSACTION_DETAILS_ANCILLARIES: (id: string): string => `transaction/${id}/ancillaries`,
  GET_TRANSACTION_DETAILS_COMMENTS: (id: string): string => `transaction/${id}/comments`,
  CANCEL_FIREARM_TRANSACTION: (id: string): string => `transaction/${id}`,
  CANCEL_ANCILLARY_TRANSACTION: (id: string): string => `transaction/ancillary/${id}`,
  CANCEL_TRANSACTION_FOR_FIREARM_GROUP: (id: string): string => `transaction/${id}/for-group`,
  CANCEL_TRANSACTION_FOR_ANCILLARY_GROUP: (id: string): string =>
    `transaction/ancillary/${id}/for-group`,
  CREATE_COMMENT_IN_TRANSACTION: (id: string): string => `transaction/${id}/comments`,
  GET_COMMENT_IN_TRANSACTION: (id: string, commentId: string): string =>
    `transaction/${id}/comments/${commentId}`,
  GET_FIREARM_LAST_TRANSACTION: (id: string) => `transaction/${id}/last`,
  IS_GROUP_TRANSACTION_DISCARDABLE: (id: string) => `transaction/${id}/is-discardable`,
  IS_ANCILLARY_GROUP_TRANSACTION_DISCARDABLE: (id: string) =>
    `transaction/ancillary/${id}/is-discardable`,
  GET_ANCILLARY_LAST_TRANSACTION: (id: string) => `transaction/ancillary/${id}/last`,
  GET_GROUP_LAST_TRANSACTION: (id: string) => `transaction/${id}/last-for-group`,
  GET_ANCILLARY_GROUP_LAST_TRANSACTION: (id: string) =>
    `transaction/ancillary/${id}/last-for-group`,
  GET_FIREARM_GROUP_DETAILS: (id: string) => `/firearm-group/${id}`,
  IS_TRANSACTION_LAST: (id: string) => `transaction/${id}/is-last`,
  GENERATE_FIREARMS_TEMPLATE: () => 'firearm/generate-template',
  GENERATE_ANCILLARIES_TEMPLATE: () => 'ancillaries/generate-template',
  GET_ASSIGNED_LEGALS_IDS_TO_USER: (id: string): string => `accounts/${id}/assigned-legals`,
  GET_ASSIGNED_SELF_LEGALS_IDS_TO_USER: (): string => 'accounts/assigned-self-legals',
  GET_ASSIGNED_LEGALS_TO_USER: (): string => 'legal',
  GET_NOTIFICATIONS: (): string => 'search-filter/notification',
  GET_NOTIFICATION: (id: string): string => `search-filter/notification/${id}`,
  GET_NOTIFICATION_UNREAD_COUNTER: (): string => 'search-filter/notification/unread-count',
  DELETE_NOTIFICATIONS: (query: string): string => `search-filter/notification${query}`,
  MARK_AS_READ_NOTIFICATIONS: (query: string): string => `search-filter/notification${query}`,
  DASHBOARD_FIREARMS_LEGALITY: (): string => 'dashboard/chart/firearms-legality',
  DASHBOARD_ANCILLARIES_TYPES: (): string => 'dashboard/chart/ancillaries',
  DASHBOARD_TRANSACTIONS_TYPES: (): string => 'dashboard/chart/transactions',
  DASHBOARD_LEGAL_ENTITIES: (): string => 'dashboard/chart/legal-entities',
  DASHBOARD_FIREARMS_STATES: (): string => 'dashboard/chart/firearms-states',
  DASHBOARD_GLOBAL_SEARCH: (category: string): string => `dashboard/global-search/${category}`,
  CREATE_CUSTOM_FIELD: (): string => 'custom-field',
  GET_CUSTOM_FIELDS: (): string => 'custom-field/read-all',
  GET_CUSTOM_FIELD: (id: string) => `custom-field/${id}`,
  CHANGE_CUSTOM_FIELD_STATUS: (id: string) => `custom-field/${id}/change-status`,
  DELETE_CUSTOM_FIELD: (id: string) => `custom-field/${id}`,
  GET_CUSTOM_FIELD_PROPERTIES: () => 'custom-field/properties',
  UPDATE_CUSTOM_FIELD: (id: string): string => `custom-field/${id}`,
  GET_CUSTOM_FIELD_BY_OBJECT_TYPE: (): string => 'custom-field/read-all/object-type',
  GET_ANCILLARIES: (): string => 'ancillaries/search',
  GET_ANCILLARIES_FOR_MAP: (): string => 'ancillaries/search-for-map',
  GET_ANCILLARIES_BY_GROUP_ID_SEARCH: (LEId: string, id: string): string =>
    `/legal/${LEId}/groups/ancillaries/search-to-add/by-group/${id}`,
  GET_ANCILLARIES_GROUP_SEARCH: (LEId: string): string =>
    `/legal/${LEId}/groups/ancillaries/search-to-add/by-ancillary`,
  GET_ANCILLARIES_BY_GROUP: (LEId: string, id: string): string =>
    `/legal/${LEId}/groups/${id}/ancillaries`,
  GET_ANCILLARY_GROUP_TRANSACTION_TYPES: (): string =>
    '/transaction/ancillary/middle-for-group',
  GET_ANCILLARY_GROUP_TRANSACTION_VALIDATE_TYPE: (): string =>
    'transaction/ancillary/validate',
  GET_ANCILLARIES_REFERENCE_TABLE: (): string => 'art/accessories/search',
  GET_ANCILLARIES_ACCESSORY: (id: string): string => `/art/accessories/${id}`,
  CREATE_ANCILLARIES_ACCESSORY: (): string => '/art/accessories',
  UPDATE_ANCILLARIES_ACCESSORY: (id: string): string => `/art/accessories/${id}`,
  GET_AMMUNITION_REFERENCE_TABLE: (): string => 'art/ammunitions/search',
  CREATE_AMMUNITION: (): string => '/art/ammunitions',
  GET_ANCILLARIES_AMMUNITION: (id: string): string => `/art/ammunitions/${id}`,
  UPDATE_ANCILLARIES_AMMUNITION: (id: string): string => `/art/ammunitions/${id}`,
  GET_ANCILLARY: (id: string): string => `ancillaries/${id}`,
  GET_ANCILLARY_TRANSATIONS: (id: string): string =>
    `/transaction/ancillary/${id}/transactions`,
  GET_ANCILLARY_COMMENTS: (id: string): string => `ancillaries/comments/${id}`,
  GET_ANCILLARY_COMMENT: (id: string, commentId: string): string =>
    `/ancillaries/comments/${id}/${commentId}`,
  GET_POSSIBLE_ANCILLARIES_TRANSACTION_TYPE: () => 'transaction/middle/ancillary',
  GET_ANCILLARY_GROUP_LAST_TRANSACTION_UNIQUE_ID: (groupUniqueId: string) =>
    `transaction/ancillary/${groupUniqueId}/last-unique-id/for-group`,
  GET_ANCILLARY_LAST_TRANSACTION_UNIQUE_ID: (ancillaryUniqueId: string) =>
    `transaction/ancillary/${ancillaryUniqueId}/last-unique-id`,
  CREATE_ANCILLARY_MIDDLE_TRANSACTION: () => 'transaction/ancillary/middle',
  CREATE_ANCILLARY_GROUP_MIDDLE_TRANSACTION: () => 'transaction/ancillary/middle-for-group',
  DISABLE_ANCILLARY: (id: string) => `transaction/ancillary/${id}/disable`,
  CREATE_ACCESSORY_COMMENT: (id: string): string => `ancillaries/comments/${id}`,
  GET_ANCILLARY_ATTACHMENTS: (id: string): string => `ancillary/${id}/attachments`,
  GET_ACCESSORY_ATTACHMENTS_USED_TRANSACTION_TYPES: (id: string): string =>
    `transaction/ancillary/${id}/used-transaction-types`,
  ADD_ACCESSORY_ATTACHMENTS: (id: string): string => `ancillary/${id}/attachments`,
  ADD_ACCESSORY_ATTACHMENTS_IN_TRANSACTION: (id: string): string =>
    `/transaction/ancillary/${id}/attachment-in-transaction`,
  GET_ANCILLARY_ATTACHMENT: (ancillaryId: string, attachmentId: string): string =>
    `ancillary/${ancillaryId}/attachments/${attachmentId}`,
  DLETE_ANCILLARY_ATTACHMENT: (ancillaryId: string, attachmentId: string): string =>
    `/ancillary/${ancillaryId}/attachments/${attachmentId}`,
  EXPORT_ANCILLARY_CSV: (): string => 'reports/ancillaries/export',
  UPDATE_ANCILLARY_CUSTOM_FIELDS: (id: string): string => `ancillaries/${id}/custom-field`,
  GET_DATE_TIME_SETUP: () => 'global-property/date_format',
  GET_TWO_FA_ENABLED: () => 'global-property/two_fa_enabled',
  GET_TIME_ZONE: () => 'global-property/time_zone',
  GET_MINUTES_IN_BAN: () => 'global-property/minutes_in_ban',
  GET_ATTEMPTS_TO_BLOCK: () => 'global-property/attempts_to_block',
  GET_TRUSTED_DEVICE_OPTIONS: () => 'accounts/trusted-device/options-enabled',
  UPDATE_TWO_FA_ENABLED: () => 'global-property/two-fa-enabled',
  UPDATE_MINUTES_IN_BAN: () => 'global-property/minutes-in-ban',
  UPDATE_ATTEMPTS_TO_BLOCK: () => 'global-property/attempts-to-block',
  UPDATE_TRUSTED_DEVICE_OPTIONS: () => 'accounts/trusted-device/options',
  GET_TRUSTED_DEVICE_OPTIONS_LIST: () => 'accounts/trusted-device/options',
  GET_ENABLED_TRUSTED_DEVICE_OPTIONS_LIST: () => 'accounts/trusted-device/options-enabled',
  UPDATE_DATE_TIME_SETUP: () => 'global-property/date-format',
  GET_COMPONENTS_REFERENCE_TABLE: (): string => 'art/components/search',
  CREATE_COMPONENT: (): string => '/art/components',
  GET_ANCILLARIES_COMPONENT: (id: string): string => `/art/components/${id}`,
  UPDATE_ANCILLARIES_COMPONENT: (id: string): string => `/art/components/${id}`,
  GET_AUDIT_LOG: (): string => '/auditlog/search',
  GET_AUDIT_LOG_ITEM: (id: string) => `/auditlog/${id}`,
  EXPORT_AUDIT_LOG: (): string => '/reports/auditlog/export',
  UPDATE_NOTIFICATION_SETUP: (): string => 'global-property/notification-time-interval',
  UPDATE_IARMS_SETUP: (): string => 'global-property/iarms-raports-enabled',
  UPDATE_ETRACE_SETUP: (): string => 'global-property/etrace-raports-enabled',
  GET_NOTIFICATION_SETUP: (): string => 'global-property/notification_time_interval',
  GET_ETRACE_SETUP: (): string => 'global-property/etrace_reports_enabled',
  GET_IARMS_SETUP: (): string => 'global-property/iarms_reports_enabled',
  GET_API_KEYS: () => '/apikey/read-all',
  CREATE_API_KEY: () => '/apikey',
  GET_API_KEY: (id: string) => `/apikey/${id}`,
  UPDATE_API_KEY: (id: string) => `/apikey/${id}`,
  CHANGE_STATUS_API_KEY: (id: string) => `/apikey/change-status/${id}`,
  GET_API_KEY_HISTORY: (id: string) => `/apikey/${id}/read-history`,
  DELETE_API_KEY: (id: string) => `/apikey/${id}`,
  SEND_EMAIL_API_KEY: (id: string) => `/apikey/${id}/send-email`,
  UPDATE_LOGIN_SCREEN: () => '/login-screen-setup',
  GET_LOGIN_SCREEN: () => '/login-screen-setup/read-all',
};
