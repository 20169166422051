import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';
import { ERegExpTypes } from 'constants/RegExpTypes';

const useApiKeyFormSchema = () => {
  const { t } = useTranslations();

  const schema = yup
    .object({
      type: yup.string().required(t('general.field.validation.required')).trim(),
      username: yup
        .string()
        .max(80, t('general.field.validation.maxCharacters').replace('{0}', '80'))
        .required(t('general.field.validation.required'))
        .trim(),
      description: yup
        .string()
        .max(1000, t('general.field.validation.maxCharacters').replace('{0}', '1000')),
      contactEmail: yup
        .string()
        .email(t('general.field.email.validation.incorrect'))
        .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
        .required(t('general.field.validation.required')),
      firstName: yup
        .string()
        .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
        .required(t('general.field.validation.required'))
        .trim(),
      lastName: yup
        .string()
        .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
        .required(t('general.field.validation.required'))
        .trim(),
      phonePrefix: yup.string().required(t('general.field.validation.required')).trim(),
      phoneNumber: yup
        .string()
        .matches(
          new RegExp(ERegExpTypes.onlyDigits),
          t('general.field.validation.valueIsNotCorrect'),
        )
        .max(15, t('general.field.validation.maxNumberCharacters').replace('{0}', '15'))
        .min(5, t('general.field.validation.minNumberCharacters').replace('{0}', '5'))
        .required(t('general.field.validation.required'))
        .trim(),
    })
    .test('scopeKeysTest', '', (obj) => {
      const scopeKeys = [
        'apikeyLegalEntity',
        'apikeyFirearm',
        'apikeyAncillaryAccessory',
        'apikeyAncillaryAmmunition',
        'apikeyAncillaryComponent',
        'apikeyTransaction',
      ];
      let isChecked = false;

      scopeKeys.map((scopeKey) => {
        const scopeKeyTmp = `${scopeKey}_`;
        Object.keys(obj).map((key) => {
          if (key.includes(scopeKeyTmp) && key.split('_')?.[1]) {
            // @ts-ignore
            if (obj[key] === true) {
              isChecked = true;
            }
          }
          return false;
        });
        return false;
      });

      if (isChecked) {
        return true;
      }

      return new yup.ValidationError(
        'Please check at least one scope item',
        null,
        'scopeKeys',
      );
    });

  return { schema };
};

export default useApiKeyFormSchema;
