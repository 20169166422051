import { EErrorPageType } from 'models/errorPage';

export const mapErrorPageTypeToLabels = (type: EErrorPageType) => {
  switch (type) {
    case EErrorPageType.AccessDenied:
      return {
        status: 403,
        title: 'errorPage.accessDenied.title',
        subtitle: 'errorPage.accessDenied.subtitle',
      };
    case EErrorPageType.NotFound:
      return {
        status: 404,
        title: 'errorPage.notFound.title',
        subtitle: 'errorPage.notFound.subtitle',
      };
    case EErrorPageType.ServerError:
      return {
        status: 500,
        title: 'errorPage.serverError.title',
        subtitle: 'errorPage.serverError.subtitle',
      };
    default:
      return {
        status: '',
        title: '',
        subtitle: '',
      };
  }
};
