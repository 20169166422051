import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';

export const StyledNoFiltersWrapper = styled(Grid)`
  background-color: ${colors.gray100};
  border: 1px solid ${colors.gray200};
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 16px;
`;

export const StyledPresetWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.gray100};
  border: 1px solid ${colors.gray200};
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 16px;
`;

export const StyledSelectedFiltersWrapper = styled(Grid)`
  background-color: ${colors.gray25};
  padding: 8px 16px;
  margin-top: 16px;
  border: 1px solid ${colors.gray50};
  border-radius: 5px;

  & > div:first-of-type {
    margin-right: 10px;
  }

  & > div:nth-of-type(2) {
    margin-top: -2px;
  }
`;

export const StyledFilterChipWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;
