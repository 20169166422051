export const getNestedErrorMessage = (errors: any, keys: string[]): string | undefined => {
  if (keys.length === 0) {
    return undefined;
  }

  const [currentKey, ...remainingKeys] = keys;
  if (remainingKeys.length === 0) {
    return errors[currentKey]?.message;
  }

  if (errors[currentKey] === undefined) {
    return undefined;
  }

  return getNestedErrorMessage(errors[currentKey], remainingKeys);
};
