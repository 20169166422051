import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import LEAccordions, { ELEAccordionsPageTypes } from 'components/organisms/LEAccordions';
import { useLocation, useNavigate } from 'react-router-dom';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useTranslations } from 'hooks/useTranslations';
import { ESelectLE } from '../../Firearms/RegisteredFirearmsSearchForm';

const SelectLegalEntityPage = () => {
  const { state: routerState }: any = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslations();
  let handleSelect: any = () => null;
  if (routerState?.context === 'firearms') {
    handleSelect = (params: any) => {
      let formValues = routerState?.form;
      if (params.type === ESelectLE.keeper) {
        formValues = {
          ...formValues,
          keeperId: params.legalEntity.id,
        };
      } else if (params.type === ESelectLE.owner) {
        formValues = {
          ...formValues,
          ownerId: params.legalEntity.id,
        };
      }
      if (routerState.returnPath) {
        navigate(routerState.returnPath, {
          state: {
            legalityUniqueId: routerState?.legalityUniqueId,
            legalEntity: params.legalEntity,
            type: routerState?.type,
            isFromMap: routerState?.isFromMap,
            formValues,
            uniqueIds: routerState?.uniqueIds,
            options: routerState?.options,
          },
        });
      }
      navigate(`${ERouteLinks.Firearms}${routerState?.query || ''}`, {
        state: {
          legalityUniqueId: routerState?.legalityUniqueId,
          legalEntity: params.legalEntity,
          type: routerState?.type,
          isFromMap: routerState?.isFromMap,
          formValues,
          uniqueIds: routerState?.uniqueIds,
          options: routerState?.options,
        },
      });
    };
  } else if (routerState?.context === 'transactions') {
    handleSelect = (params: any) =>
      navigate(
        routerState.groupId
          ? `${ERouteLinks.LegalEntityAddTransactionForGroup.replace(
              ':id',
              routerState?.id,
            ).replace(':groupId', routerState?.groupId)}?type=${
              routerState?.type
            }&expand=groups${routerState?.query || ''}`
          : `${ERouteLinks.MiddleTransaction.replace(':id', routerState?.id)}${
              routerState?.query || ''
            }`,
        {
          state: {
            ...routerState,
            legalEntityFullName: params.legalEntity?.['individual.firstName']
              ? `${params.legalEntity?.['individual.firstName']} ${params.legalEntity?.['individual.lastName']}`
              : `${params.legalEntity?.['organization.organizationName']}`,
            legalEntityId: params.legalEntity?.id,
            type: params?.['individual.firstName']
              ? ELegalEntityTypes.individual
              : ELegalEntityTypes.organisation,
          },
        },
      );
  } else if (routerState?.context === 'ancillaryTransactions') {
    handleSelect = (params: any) =>
      navigate(
        `${ERouteLinks.AncillariesMiddleTransaction.replace(':id', routerState?.id)}${
          routerState?.query || ''
        }`,
        {
          state: {
            ...routerState,
            legalEntityFullName: params.legalEntity?.['individual.firstName']
              ? `${params.legalEntity?.['individual.firstName']} ${params.legalEntity?.['individual.lastName']}`
              : `${params.legalEntity?.['organization.organizationName']}`,
            legalEntityId: params.legalEntity?.id,
            type: params?.['individual.firstName']
              ? ELegalEntityTypes.individual
              : ELegalEntityTypes.organisation,
          },
        },
      );
  } else if (routerState?.context === 'ancillaryGroupTransactions') {
    handleSelect = (params: any) =>
      navigate(
        routerState.groupId
          ? `${ERouteLinks.LegalEntityAddTransactionForAncillaryGroup.replace(
              ':id',
              routerState?.id,
            ).replace(':groupId', routerState?.groupId)}?type=${
              routerState?.type
            }&expand=ancillaryGroups${routerState?.query || ''}`
          : `${ERouteLinks.MiddleTransaction.replace(':id', routerState?.id)}${
              routerState?.query || ''
            }`,
        {
          state: {
            ...routerState,
            legalEntityFullName: params.legalEntity?.['individual.firstName']
              ? `${params.legalEntity?.['individual.firstName']} ${params.legalEntity?.['individual.lastName']}`
              : `${params.legalEntity?.['organization.organizationName']}`,
            legalEntityId: params.legalEntity?.id,
            type: params?.['individual.firstName']
              ? ELegalEntityTypes.individual
              : ELegalEntityTypes.organisation,
          },
        },
      );
  } else if (routerState?.context === 'searchTransactions') {
    handleSelect = (params: any) => {
      navigate(`${ERouteLinks.Transactions}${routerState?.query || ''}`, {
        state: {
          ...routerState,
          legalEntity: params.legalEntity,
        },
      });
    };
  } else if (routerState?.context === 'searchAncillaries') {
    handleSelect = (params: any) => {
      let formValues = routerState?.form;
      if (params.type === ESelectLE.keeper) {
        formValues = {
          ...formValues,
          uniqueIds: routerState?.uniqueIds,
          accessoriesUniqueIds: routerState?.accessoriesUniqueIds,
          ammunitionUniqueIds: routerState?.ammunitionUniqueIds,
          componentsUniqueIds: routerState?.componentsUniqueIds,
          keeperId: params.legalEntity.id,
          keeperName: undefined,
        };
      } else if (params.type === ESelectLE.owner) {
        formValues = {
          ...formValues,
          uniqueIds: routerState?.uniqueIds,
          accessoriesUniqueIds: routerState?.accessoriesUniqueIds,
          ammunitionUniqueIds: routerState?.ammunitionUniqueIds,
          componentsUniqueIds: routerState?.componentsUniqueIds,
          ownerId: params.legalEntity.id,
          ownerName: undefined,
        };
      }
      if (routerState.returnPath) {
        navigate(routerState.returnPath, {
          state: {
            legalityUniqueId: routerState?.legalityUniqueId,
            legalEntity: params.legalEntity,
            type: routerState?.type,
            module: routerState?.module,
            formValues,
            uniqueIds: routerState?.uniqueIds,
            accessoriesUniqueIds: routerState?.accessoriesUniqueIds,
            ammunitionUniqueIds: routerState?.ammunitionUniqueIds,
            componentsUniqueIds: routerState?.componentsUniqueIds,
            options: routerState?.options,
            ancillaryOption: routerState?.ancillaryOption,
            selectedAncillaries: routerState?.selectedAncillaries,
          },
        });
      } else {
        navigate(`${ERouteLinks.Ancillaries}${routerState?.query || ''}`, {
          state: {
            legalityUniqueId: routerState?.legalityUniqueId,
            legalEntity: params.legalEntity,
            type: routerState?.type,
            module: routerState?.module,
            formValues,
            uniqueIds: routerState?.uniqueIds,
            accessoriesUniqueIds: routerState?.accessoriesUniqueIds,
            ammunitionUniqueIds: routerState?.ammunitionUniqueIds,
            componentsUniqueIds: routerState?.componentsUniqueIds,
            options: routerState?.options,
          },
        });
      }
    };
  }

  return (
    <>
      <Breadcrumbs items={routerState?.breadcrumbs} />
      <StyledPageTitle variant="h4">{t('legals.search.selectLegal.label')}</StyledPageTitle>
      <LEAccordions
        type={ELEAccordionsPageTypes.SelectLegalEntity}
        handleSelect={(params: any) => handleSelect(params)}
        saveRouterStateAfterClearAll={true}
      />
    </>
  );
};

export { SelectLegalEntityPage };
