import React, { PropsWithChildren, useMemo, useState } from 'react';
import { NotificationCounterContext } from 'models/notificationCounterContext';

export const NotificationCounterProvider = ({ children }: PropsWithChildren<{}>) => {
  const [counter, setCounter] = useState<number>(0);

  const value = useMemo(() => ({ counter, setCounter }), [counter, setCounter]);

  return (
    <NotificationCounterContext.Provider value={value}>
      {children}
    </NotificationCounterContext.Provider>
  );
};
