import styled from '@emotion/styled';

const StyledHamburgerMenu = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  height: 56px;
  width: 100%;

  & button {
    margin-top: 24px;
    margin-right: 24px;

    & svg {
      margin-left: 4px;
      margin-top: 3px;
    }
  }
`;

export { StyledHamburgerMenu };
