import { colors } from 'theme/colors';
import { ITableDataSource } from 'models/table';
import { ActivityTypes } from 'constants/ActivityTypes';
import { EChipsVariants } from 'components/atoms/Chips';
import { LegalityTypes } from 'constants/LegalityTypes';

export const renderRowColor = (data: ITableDataSource) => {
  if (data.activityUniqueId === ActivityTypes.INACTIVE) {
    return colors.gray100;
  }
  if (data.legalityUniqueId === LegalityTypes.ILLEGAL) {
    return colors.error15;
  }
  return '';
};

export const renderRowColorHover = (data: ITableDataSource) => {
  if (data.activityUniqueId === ActivityTypes.INACTIVE) {
    return colors.gray200;
  }
  if (data.legalityUniqueId === LegalityTypes.ILLEGAL) {
    return colors.error25;
  }
  return colors.primary15;
};

export const mapLegalityActivityMapColor = (data: ITableDataSource) => {
  if (data.activityUniqueId === ActivityTypes.INACTIVE) {
    return colors.gray100;
  }
  if (data.legalityUniqueId === LegalityTypes.ILLEGAL) {
    return colors.error15;
  }
  return '';
};

export const mapLegalityActivityMapHover = (data: ITableDataSource) => {
  if (data.activityUniqueId === ActivityTypes.INACTIVE) {
    return colors.gray200;
  }
  if (data.legalityUniqueId === LegalityTypes.ILLEGAL) {
    return colors.error25;
  }
  return colors.primary15;
};

export const renderDataColor = (data: ITableDataSource) => {
  if (data) {
    if (data.activityUniqueId === ActivityTypes.INACTIVE) {
      return {
        background: colors.gray200,
        border: colors.gray400,
        stateField: EChipsVariants.inactive,
      };
    }
    if (data.legalityUniqueId === LegalityTypes.ILLEGAL) {
      return {
        background: colors.error15,
        border: colors.error100,
        stateField: EChipsVariants.error,
      };
    }
  }
  return {
    background: colors.gray100,
    border: colors.gray200,
    stateField: EChipsVariants.primary,
  };
};

export const renderDataColorByValues = (
  activityUniqueId: string,
  legalityUniqueId: string,
) => {
  if (activityUniqueId === ActivityTypes.INACTIVE) {
    return {
      background: colors.gray200,
      border: colors.gray400,
      stateField: EChipsVariants.inactive,
    };
  }
  if (legalityUniqueId === LegalityTypes.ILLEGAL) {
    return {
      background: colors.error15,
      border: colors.error100,
      stateField: EChipsVariants.error,
    };
  }
  return {
    background: colors.gray100,
    border: colors.gray200,
    stateField: EChipsVariants.primary,
  };
};
