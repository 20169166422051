import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { IFormElementOptionProp } from 'models/form';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Alert } from 'components/atoms/Alert';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { FormSelect } from 'components/molecules/FormSelect';
import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useAlert } from 'models/alertContext';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { ActionTypes } from 'state/actions/alert';
import useNotificationsSetupFormSchema from './schemaValidation';
import { ENotificationsFrequency, useNotificationsSetup } from './hooks';
import { StyledValueFormHeader } from '../styles';

const notificationsFrequencyOptions: IFormElementOptionProp[] = [
  { value: ENotificationsFrequency.HOURLY, label: 'Hourly' },
  {
    value: ENotificationsFrequency.TWO_TIMES_A_DAY,
    label: '2 times a day (at 12:00 and at 18:00)',
  },
  { value: ENotificationsFrequency.DAILY, label: 'Daily (at 18:00)' },
  { value: ENotificationsFrequency.WEEKLY, label: 'Weekly (on Mondays at 18:00)' },
];

export interface IFormValues {
  notificationsFrequency: ENotificationsFrequency | null;
}

const initialFormValues = {
  notificationsFrequency: null,
};

const NotificationsSetupPage = () => {
  const { t } = useTranslations();
  const { samePageAlert, clearAlert } = useAlert();
  const navigate = useNavigate();
  const { handleUpdate, getNotificationsSetupData, notificationsSetup } =
    useNotificationsSetup();
  const [initialValues, setInitialValues] = useState<IFormValues>(initialFormValues);
  const { schema } = useNotificationsSetupFormSchema();
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  useEffect(() => {
    if (notificationsSetup) {
      setInitialValues(notificationsSetup);
    }
  }, [notificationsSetup]);

  useEffect(() => {
    (async function getDataInit() {
      await getNotificationsSetupData();
    })();
  }, []);

  const onSubmitForm = async (data: IFormValues) => {
    if (data.notificationsFrequency) {
      setSubmitLoading(true);
      await handleUpdate(data);
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          { label: t('systemSettings.header'), route: ERouteLinks.SystemSettings },
          t('notificationsSetup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('notificationsSetup.header')}</StyledPageTitle>

      <TableFormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container>
            <Grid container rowGap={3}>
              <Grid item gap={3} container>
                <Grid item xs={12}>
                  <StyledValueFormHeader>
                    {t('notificationsSetup.notificationsFrequency.header')}
                  </StyledValueFormHeader>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box mb={1}>
                    <FormSelect
                      name="notificationsFrequency"
                      label={t(
                        'notificationsSetup.defineTheFrequencyOfSystemNotifications.label',
                      )}
                      setValue={setValue}
                      options={notificationsFrequencyOptions}
                      canBeEmpty={false}
                      control={control}
                      errors={errors}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    type="submit"
                    fullWidth
                    label={t('general.save.button')}
                    id="save-button"
                    variant={EButtonVariants.contained}
                    isLoading={isSubmitLoading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Button
                    fullWidth
                    label={t('general.cancel.button')}
                    id="cancel-button"
                    variant={EButtonVariants.outlined}
                    onClick={() => navigate(ERouteLinks.SystemSettings)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </TableFormWrapper>

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export default NotificationsSetupPage;
