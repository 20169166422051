import React, { useEffect } from 'react';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate, leftDays } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { StyledPageTitle } from 'theme/styles';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert } from 'components/atoms/Alert';
import { useLicence } from './hooks';

export const LicencePage = () => {
  const { t } = useTranslations();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const { licence, getLicenceData } = useLicence();
  const navigate = useNavigate();
  const { clearAlert, nextPageAlert } = useAlert();

  useEffect(() => {
    getDateFormat();

    (async () => {
      if (!licence) {
        await getLicenceData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.administration.name'),
            route: ERouteLinks.Administration,
          },
          t('licenceDetails.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('licenceDetails.header')}</StyledPageTitle>
      <Typography variant="body2" sx={{ marginBottom: '4px' }}>
        {t('licenceDetails.subtitle')}
      </Typography>

      {nextPageAlert && (
        <Box mt={2} sx={{ marginBottom: '10px', marginTop: '20px' }}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}

      {licence && (
        <DetailsTemplate
          pageType={EDetailsPageTypes.view}
          title={licence.licenceId}
          actionButtons={[
            {
              text: t('licenceDetails.replace.button.label'),
              onClick: () => navigate(ERouteLinks.LicenceReplace),
            },
          ]}
        >
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('licenceDetails.licenceId.label')}
            </Typography>
            <Typography variant="body2">{licence.licenceId}</Typography>
          </Grid>

          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('licenceDetails.licenceType.label')}
            </Typography>
            <Box>
              <Chips text={licence.licenceType} variant={EChipsVariants.primary} />
            </Box>
          </Grid>

          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('licenceDetails.expiryDate.label')}
            </Typography>
            <Typography variant="body2">
              {licence.expiryDate ? formatDate(licence.expiryDate, shortDateFormat) : null}
            </Typography>
            <Box>
              {licence.expiryDate ? (
                <Chips
                  text={`${t('licenceDetails.expires')} ${leftDays(licence.expiryDate, t)}`}
                  variant={EChipsVariants.primary}
                />
              ) : (
                <Chips
                  text={`${t('licenceDetails.perpetual')}`}
                  variant={EChipsVariants.success100}
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('licenceDetails.numberOfUsers.label')}
            </Typography>
            <Typography variant="body2">
              {licence.currentSystemUsers}/<strong>{licence.numberOfUsers || '∞'}</strong>
            </Typography>
          </Grid>
        </DetailsTemplate>
      )}
    </>
  );
};
