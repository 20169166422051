import React, { useEffect } from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { downloadAttachment } from 'requests/attachment';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';

interface IFirearmAttachmentTableCard {
  data: ITableDataSource;
}

const FirearmAttachmentTableCard = ({ data }: IFirearmAttachmentTableCard) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Date and Time
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(formatDate(data.createdDate, longDateFormat))}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Attachment Name
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.name)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Attachment Size [kB]
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.size)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          Keeper
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.transactionType)}</Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('registeredFirearms.firearmsDetails.attachments.accordion.action.column')}:{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            label={t('registeredFirearms.firearmsDetails.attachments.accordion.download.link')}
            variant={EButtonVariants.outlined}
            onClick={() => downloadAttachment(id, data.id as string, data.name, data.type)}
            size="small"
            fullWidth
          />
        </Grid>
        {data.transactionId && (
          <Grid item xs={12} sm={6}>
            <Button
              label={t(
                'registeredFirearms.firearmsDetails.attachments.accordion.details.link',
              )}
              variant={EButtonVariants.outlined}
              onClick={() => {}}
              size="small"
              fullWidth
            />
          </Grid>
        )}
        {!data.transactionId && (
          <Grid item xs={12} sm={6}>
            <Button
              label={t('registeredFirearms.firearmsDetails.attachments.accordion.remove.link')}
              variant={EButtonVariants.outlined}
              onClick={() =>
                navigate(
                  `${ERouteLinks.RegisteredFirearmDeleteAttachment.replace(
                    ':id',
                    id as string,
                  ).replace(':attachmentId', data.id as string)}?expand=attachments`,
                  { state: { attachment: data } },
                )
              }
              size="small"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { FirearmAttachmentTableCard };
