export enum ESearchFilterTypes {
  individual = 'INDIVIDUAL',
  organisation = 'ORGANIZATION',
  firearmInGroup = 'FIREARM_IN_GROUP',
  legalFirearm = 'LEGAL_FIREARM',
  firearm = 'FIREARM',
  transaction = 'TRANSACTION',
  ancillaries = 'ANCILLARIES',
  accessories = 'ACCESSORIES',
  ammunition = 'AMMUNITION',
}

export enum ESearchFilterKeys {
  individual = 'individual',
  organisation = 'organisation',
  firearmInGroup = 'firearmInGroup',
  legalFirearm = 'legalFirearm',
  firearm = 'firearm',
  transaction = 'transaction',
  ancillaries = 'ancillaries',
  accessory = 'accessory',
  ammunition = 'ammunition',
  component = 'component',
}
