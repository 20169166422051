import { ILogoSetupFormValues } from 'pages/SystemSettingsPage/LogoSetupPage/index';
import { getLoginScreenSetupData, updateLoginSetup } from 'requests/loginScreenSetup';
import { ELoginPartTypes, ILoginScreenItem } from 'models/loginScreenSetup';
import { useState } from 'react';
import { downscaleAttachmentIfRequired } from 'utils/image';
import { isObjectEmpty } from 'helpers/objects';

export interface IImageData {
  content: string;
  fileType: string;
}

export const useLogoSetup = () => {
  const [loginScreenData, setLoginScreenData] = useState<ILoginScreenItem[]>();
  const [loginScreenDataParsed, setLoginScreenDataParsed] = useState<
    | {
        [key in ELoginPartTypes]?: string | IImageData;
      }
    | null
  >(null);

  const updateLoginPage = async (
    formValues: ILogoSetupFormValues,
    attachments: { [key: string]: File | null },
  ) => {
    if (!isObjectEmpty(formValues)) {
      if (Object.prototype.hasOwnProperty.call(formValues, 'header')) {
        await updateLoginSetup(ELoginPartTypes.HEADER, formValues.header, null);
      }
      if (Object.prototype.hasOwnProperty.call(formValues, 'subheader')) {
        await updateLoginSetup(ELoginPartTypes.SUBHEADER, formValues.subheader, null);
      }

      if (formValues.uploadedFile1 === '' && attachments[1] === null) {
        await updateLoginSetup(ELoginPartTypes.LOGO_1, '', null);
      }
      if (formValues.uploadedFile2 === '' && attachments[2] === null) {
        await updateLoginSetup(ELoginPartTypes.LOGO_2, '', null);
      }
      if (formValues.uploadedFile3 === '' && attachments[3] === null) {
        await updateLoginSetup(ELoginPartTypes.LOGO_3, '', null);
      }
    }

    const errorList: string[] = [];

    await Promise.all(
      [1, 2, 3]
        .filter((key) => attachments[key] !== null)
        .map(async (key) => {
          const file1: any = await downscaleAttachmentIfRequired(attachments[key] as File);
          try {
            await updateLoginSetup(`LOGO_${key}` as ELoginPartTypes, '', file1 as File);
          } catch (e: any) {
            errorList.push(`uploadedFile${key}`);
          }
        }),
    );

    return errorList;
  };

  const getLoginScreen = async () => {
    try {
      const result = await getLoginScreenSetupData();
      if (result) {
        setLoginScreenData(result);

        const resultParsed: {
          [key in ELoginPartTypes]?: string | IImageData;
        } = {};

        result.map((item) => {
          if ([ELoginPartTypes.HEADER, ELoginPartTypes.SUBHEADER].includes(item.type)) {
            if (item.text) {
              resultParsed[item.type] = item.text;
            }
          } else if (item?.file) {
            resultParsed[item.type] = {
              content: item.file?.fileContent,
              fileType: item.file.fileType,
            };
          }
          return false;
        });

        setLoginScreenDataParsed(resultParsed);

        return true;
      }
    } catch (e: any) {
      console.error(e);
    }

    return false;
  };

  return { updateLoginPage, getLoginScreen, loginScreenData, loginScreenDataParsed };
};
