export interface IPermission {
  uniqueId: string;
  name: string;
  description: string;
  authority: string;
  relateModule: keyof typeof EPermissionRelatedModule;
}

export enum EPermissionRelatedModule {
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  FIREARM = 'FIREARM',
  TRANSACTION = 'TRANSACTION',
  ADMINISTRATION = 'ADMINISTRATION',
  ANCILLARY = 'ANCILLARY',
  DASHBOARD = 'DASHBOARD',
}

export const permissionsGroups = [
  { title: 'roleDetails.legalEntity.section', module: EPermissionRelatedModule.LEGAL_ENTITY },
  { title: 'roleDetails.firearm.section', module: EPermissionRelatedModule.FIREARM },
  {
    title: 'roleDetails.ancillary.section',
    module: EPermissionRelatedModule.ANCILLARY,
  },
  {
    title: 'roleDetails.transaction.section',
    module: EPermissionRelatedModule.TRANSACTION,
  },
  {
    title: 'roleDetails.administration.section',
    module: EPermissionRelatedModule.ADMINISTRATION,
  },
  {
    title: 'roleDetails.dashboard.section',
    module: EPermissionRelatedModule.DASHBOARD,
  },
];

export enum EPermission {
  /** Legal Entity Module  */
  DELETE_LE = 'DELETE_LE',
  MANAGE_COMMENTS_LE = 'MANAGE_COMMENTS_LE',
  EXPORT_LE = 'EXPORT_LE',
  VIEW_ALL_LE = 'VIEW_ALL_LE',
  VIEW_LE = 'VIEW_LE',
  VIEW_ANCILLARIES_LE = 'VIEW_ANCILLARIES_LE',
  VIEW_FIREARMS_LE = 'VIEW_FIREARMS_LE',
  MANAGE_LE = 'MANAGE_LE',
  MANAGE_GROUPS_LE = 'MANAGE_GROUPS_LE',

  /** Firearm Module  */
  VIEW_FIREARMS = 'VIEW_FIREARMS',
  VIEW_ALL_FIREARMS = 'VIEW_ALL_FIREARMS',
  MANAGE_COMMENTS_FIREARMS = 'MANAGE_COMMENTS_FIREARMS',
  MANAGE_ATTACHMENTS_FIREARMS = 'MANAGE_ATTACHMENTS_FIREARMS',
  DISABLE_FIREARM = 'DISABLE_FIREARM',
  MODIFY_FIREARM = 'MODIFY_FIREARM',
  MANAGE_FRT_FIREARM = 'MANAGE_FRT_FIREARM',
  EXPORT_FIREARMS = 'EXPORT_FIREARMS',
  MANAGE_IARMS_EXPORT = 'MANAGE_IARMS_EXPORT',

  /** Transaction Module  */
  ADD_TRANSACTION = 'ADD_TRANSACTION',
  VIEW_TRANSACTIONS = 'VIEW_TRANSACTIONS',
  VIEW_ALL_TRANSACTIONS = 'VIEW_ALL_TRANSACTIONS',
  DISCARD_TRANSACTION = 'DISCARD_TRANSACTION',
  MANAGE_TRANSACTION_COMMENTS = 'MANAGE_TRANSACTION_COMMENTS',

  /** Administration Module  */
  MANAGE_USERS = 'MANAGE_USERS',
  MANAGE_ROLES = 'MANAGE_ROLES',
  MANAGE_CUSTOM_FIELDS = 'MANAGE_CUSTOM_FIELDS',
  MANAGE_SETTINGS = 'MANAGE_SETTINGS',
  VIEW_MODIFY_SYSTEM_PARAMETERS = 'VIEW_MODIFY_SYSTEM_PARAMETERS',
  VIEW_AUDIT_LOG = 'VIEW_AUDIT_LOG',
  EXPORT_AUDIT_LOG = 'EXPORT_AUDIT_LOG',
  MANAGE_DICTIONARIES = 'MANAGE_DICTIONARIES',
  VIEW_AND_MANAGE_LICENCE = 'VIEW_AND_MANAGE_LICENCE',
  MANAGE_API_KEYS = 'MANAGE_API_KEYS',

  /** Ancillaries Module  */
  VIEW_LIMITED_ANCILLARIES = 'VIEW_LIMITED_ANCILLARIES',
  VIEW_ALL_ANCILLARIES = 'VIEW_ALL_ANCILLARIES',
  EDIT_ANCILLARY = 'EDIT_ANCILLARY',
  DISABLE_ANCILLARY = 'DISABLE_ANCILLARY',
  MANAGE_LOCAL_ART = 'MANAGE_LOCAL_ART',
  MANAGE_COMMENTS_ANCILLARY = 'MANAGE_COMMENTS_ANCILLARY',
  MANAGE_ATTACHMENT_ANCILLARY = 'MANAGE_ATTACHMENT_ANCILLARY',
  EXPORT_ANCILLARIES = 'EXPORT_ANCILLARIES',

  /** Dashboard Module  */
  VIEW_DASHBOARD_CHARTS = 'VIEW_DASHBOARD_CHARTS',
}
