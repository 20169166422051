import React, { useEffect, useState } from 'react';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from 'requests/user';
import { IUser } from 'models/user';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useDictionary } from 'models/dictionaryContext';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { useTranslations } from 'hooks/useTranslations';
import { getRoles } from 'requests/role';
import { useUsers } from 'pages/UsersPage/hooks';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EButtonVariants } from 'constants/Buttons';
import { EPermission } from 'models/permissions';
import { StyledPageSubTitle } from 'theme/styles';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

enum EViewUserPageTranslationsKeys {
  editLink = 'userDetails.edit.link',
  deleteLink = 'userDetails.delete.link',
}

enum EViewUserStatus {
  activate = 'activate',
  deactivate = 'deactivate',
  unlock = 'unlock',
}

export const EditUserStatusPage = () => {
  const navigate = useNavigate();
  const { id = '', status } = useParams<{ id: string; status: EViewUserStatus | undefined }>();
  const [userDetails, setUserDetails] = useState<IUser | null>();
  const [roleNames, setRoleNames] = useState<string[]>([]);
  const [prefixNames, setPrefixNames] = useState<string[]>([]);
  const [userActivated, setUserActivated] = useState<boolean>();
  const { phoneCode, legalEntityPrefix } = useDictionary();
  const { t } = useTranslations();
  const { activateUser, unbanUser } = useUsers();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function getUserDataInit() {
      if (id) {
        const response = await getUser(id);
        if (response) {
          setUserDetails(response);
          setUserActivated(response.active);
          if (response.roleIds && response.roleIds.length) {
            const responseRoles = await getRoles(1, 100, {}, null);
            if (responseRoles) {
              const roles = responseRoles.content
                .map((role) => response.roleIds.includes(role.uniqueId) && role.name)
                .filter((row) => !!row);
              setRoleNames(roles as string[]);
            }
          }
          if (response.prefixGroupIds && response.prefixGroupIds.length) {
            if (legalEntityPrefix) {
              const names = legalEntityPrefix
                .map(
                  (prefix) =>
                    response.prefixGroupIds.includes(prefix.uniqueId.toString()) &&
                    prefix.name,
                )
                .filter((row) => !!row);
              setPrefixNames(names as string[]);
            }
          }
        }
      }
    })();
  }, [id]);

  const parsedPhoneCodeDictionary: IParsedDictionary = parseDictionary(phoneCode, 'name');

  const changeActivationUserStatus = async () => {
    if (userDetails) {
      if (userDetails.blocked) {
        await unbanUser(id);
        setUserActivated(true);
      }
      if (!userDetails.blocked) {
        await activateUser(userDetails.uniqueId, !userActivated);
        setUserActivated(!userActivated);
      }
    }
    navigate(ERouteLinks.ViewUser.replace(':id', id));
  };

  if (status && userDetails) {
    return (
      <>
        <Breadcrumbs
          items={[
            {
              label: t('user.breadcrumbs.administration'),
              route: ERouteLinks.Administration,
            },
            { label: t('user.breadcrumbs.users'), route: ERouteLinks.Users },
            {
              label: t('user.breadcrumbs.userDetails'),
              route: ERouteLinks.ViewUser.replace(':id', id),
            },
            t(
              `user.editStatus.${
                userDetails.blocked ? EViewUserStatus.unlock : status
              }.header`,
            ),
          ]}
        />
        <Grid item xs={12} container rowGap={2} marginBottom={3}>
          <Grid item xs={12}>
            <StyledPageSubTitle variant="h5">
              {t(
                `user.editStatus.${
                  userDetails.blocked ? EViewUserStatus.unlock : status
                }.header`,
              )}
            </StyledPageSubTitle>
          </Grid>

          <Grid item xs={12} style={{ margin: '10px 0 10px' }}>
            <Alert
              text={t(
                `user.editStatus.${
                  userDetails.blocked ? EViewUserStatus.unlock : status
                }.question`,
              )}
              variant={EAlertVariants.warning}
            />
          </Grid>
        </Grid>
        {userDetails && (
          <DetailsTemplate
            pageType={EDetailsPageTypes.userView}
            title={userDetails.email}
            deletePermissions={[EPermission.MANAGE_USERS]}
            translationsKeys={EViewUserPageTranslationsKeys}
          >
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.firstName.label')}
              </Typography>
              <Typography variant="body2">{userDetails.firstName}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.lastName.label')}
              </Typography>
              <Typography variant="body2">{userDetails.lastName}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.phoneNumber.label')}
              </Typography>
              <Typography variant="body2">{`${
                userDetails.phoneNumber &&
                parsedPhoneCodeDictionary?.[userDetails.phoneNumber.codeDictionaryUniqueId]
                  ? `${
                      parsedPhoneCodeDictionary?.[
                        userDetails.phoneNumber.codeDictionaryUniqueId
                      ]
                    }${userDetails.phoneNumber.value}`
                  : '-'
              }`}</Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.lastLogged.label')}
              </Typography>
              <Typography variant="body2">
                {formatDate(userDetails.lastLoginDate, longDateFormat)}
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.role.label')}
              </Typography>
              <div>
                {roleNames.map((roleItem) => (
                  <Chips key={roleItem} text={roleItem} variant={EChipsVariants.primary} />
                ))}
              </div>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.status.label')}
              </Typography>
              <div>
                {userDetails.blocked ? (
                  <Chips text={t('users.blocked.label')} variant={EChipsVariants.warning100} />
                ) : (
                  <Chips
                    text={userActivated ? t('users.active.label') : t('users.inactive.label')}
                    variant={userActivated ? EChipsVariants.success : EChipsVariants.error}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('userDetails.prefix.label')}
              </Typography>
              <div>
                {prefixNames.map((item) => (
                  <Chips key={item} text={item} variant={EChipsVariants.primary} />
                ))}
              </div>
            </Grid>
          </DetailsTemplate>
        )}
        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                label={t('general.yes.button')}
                id="yes-button"
                variant={EButtonVariants.contained}
                onClick={changeActivationUserStatus}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                label={t('general.no.button')}
                id="no-button"
                variant={EButtonVariants.outlined}
                onClick={() => navigate(-1)}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
