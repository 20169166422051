import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useParams } from 'react-router-dom';
import { CommentForm, ICommentFormValues } from 'components/organisms/CommentForm';
import { useTranslations } from 'hooks/useTranslations';

const LegalEntityAddCommentPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { handleCreateComment, navigateToDetails } = useLegalEntities();
  const { t } = useTranslations();

  const handleSubmit = async (data: ICommentFormValues) => {
    const result = await handleCreateComment(data);
    if (result) {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=comments`,
          },
          t('addComment.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addComment.header')}</StyledPageTitle>

      <FormWrapper>
        <CommentForm onCancel={navigateToDetails} onSubmit={handleSubmit} />
      </FormWrapper>
    </>
  );
};

export { LegalEntityAddCommentPage };
