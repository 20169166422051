import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { initialAddressFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FormSelect } from 'components/molecules/FormSelect';
import { FormSwitch } from 'components/molecules/FormSwitch';
import { FormInput } from 'components/molecules/FormInput';
import { StyledPageTitle } from 'theme/styles';
import { LocationMap } from 'components/organisms/LocationMap';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { ILegalEntityAddress } from 'models/legalEntity';
import { useDictionary } from 'models/dictionaryContext';
import { useTranslations } from 'hooks/useTranslations';
import { useAddressSchema } from 'pages/LegalEntitiesPage/schemaValidation';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useScroll } from 'hooks/useScroll';
import { useLicenceContext } from 'models/licenceContext';

export interface ILegalEntityAddressFormValues {
  uniqueId: string;
  typeUniqueId: string;
  line1: string;
  line2: string;
  city: string;
  postalCode: string;
  regionUniqueId: string;
  countryUniqueId: string;
  latitude: string;
  longitude: string;
  isDefault: boolean;
}

export interface ILegalEntityAddressForm {
  onCancel?: () => void;
  onSubmit: (arg: ILegalEntityAddressFormValues) => void;
  initialFormValues?: ILegalEntityAddress | null;
  formTitle: string;
}

const LegalEntityAddressForm = ({
  onCancel,
  onSubmit,
  initialFormValues,
  formTitle,
}: ILegalEntityAddressForm) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const {
    loadDictionaries,
    country,
    region,
    handleSearchLocationCoordsByAddress,
    getLegalEntityAddressesData,
  } = useLegalEntities();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { addressSchema } = useAddressSchema();
  const { samePageAlert, clearAlert } = useAlert();
  const { selectedLanguage } = useLang();
  const { elRef, scrollToError } = useScroll();
  const [showWarning, setShowWarning] = useState(false);
  const { isOnlineLicence } = useLicenceContext();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm<ILegalEntityAddress | ILegalEntityAddressFormValues>({
    defaultValues: initialFormValues || initialAddressFormValues,
    resolver: yupResolver(addressSchema),
  });

  const line1: string = watch('line1');
  const city: string = watch('city');
  const postalCode: string = watch('postalCode');
  const countryUniqueId: string = watch('countryUniqueId');
  const regionUniqueId: string = watch('regionUniqueId');
  const latitude: number | string = watch('latitude');
  const longitude: number | string = watch('longitude');

  const defaultZoom: number = parseInt(
    process.env.REACT_APP_MAP_DEFAULT_ZOOM ? process.env.REACT_APP_MAP_DEFAULT_ZOOM : '6',
    10,
  );

  const { isDesktop } = useThemeBreakpoint();
  const { addressType } = useDictionary();

  useEffect(() => {
    reset(initialFormValues || initialAddressFormValues);
  }, [reset, initialFormValues]);

  const onSubmitForm = async (data: any) => {
    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({
        ...initialFormValues,
        ...data,
      });
      setIsSubmitLoading(false);
    } else {
      await onSubmit({ uniqueId: id, ...data });
      setIsSubmitLoading(false);
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.Country,
        EDictionaryTypes.Region,
        EDictionaryTypes.AddressType,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (country && country.length) {
      setValue('countryUniqueId', country[0].uniqueId as string);
    }
  }, [country]);

  useEffect(() => {
    (async function getLegalEntityAddressInit() {
      if (id) {
        await getLegalEntityAddressesData(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const renderAddressLocationMap = (isDesktopThemeBreakpoint = false) => (
    <LocationMap
      coords={[+latitude, +longitude]}
      dimensions={{ width: '100%', height: isDesktopThemeBreakpoint ? '100%' : '300px' }}
      getCoordsCallback={(data: any) => {
        setShowWarning(true);
        clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
        setValue('latitude', data.lat);
        setValue('longitude', data.lng);
      }}
      defaultZoom={defaultZoom}
      isOnline={isOnlineLicence()}
    />
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container columnSpacing={3} ref={elRef}>
        <Grid item xs={12} lg={6} container>
          <Grid item xs={12}>
            <StyledPageTitle variant="h4" mb={2}>
              {formTitle}
            </StyledPageTitle>
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <FormSelect
                name="typeUniqueId"
                options={mapDictionaryToOptionProp(addressType)}
                label={`${t('addAddress.addressType.label')}*`}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormSwitch
                name="isDefault"
                label={`${t('legalEntityDetails.addressTab.storageAddress.column')}?`}
                control={control}
                errors={errors}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormInput
              name="line1"
              label={`${t('addAddress.addressLine1.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              name="line2"
              label={t('addAddress.addressLine2.label')}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <FormInput
                name="city"
                label={`${t('addAddress.cityTown.label')}*`}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                name="postalCode"
                label={`${t('addAddress.zipPostalCode.label')}`}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <FormSelect
                name="countryUniqueId"
                options={mapDictionaryToOptionProp(country)}
                setValue={setValue}
                label={`${t('addAddress.country.label')}*`}
                control={control}
                errors={errors}
                disabled={true}
              />
            </Grid>
            {region && (
              <Grid item xs={12} md={6}>
                <FormSelect
                  name="regionUniqueId"
                  options={mapDictionaryToOptionProp(
                    region!.sort((a, b) => (a.name > b.name ? 1 : -1)),
                  )}
                  setValue={setValue}
                  label={`${t('addAddress.stateProvince.label')}`}
                  control={control}
                  errors={errors}
                />
              </Grid>
            )}
          </Grid>
          <Grid item container mt={2}>
            <StyledPageTitle variant="h4" mb={2}>
              {t('editAddress.locationOnMap.label')}
            </StyledPageTitle>
            <Grid item xs={12} container columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="latitude"
                  label={`${t('addAddress.latitude.label')}*`}
                  setValue={setValue}
                  disabled={true}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  name="longitude"
                  label={`${t('addAddress.longitude.label')}*`}
                  setValue={setValue}
                  disabled={true}
                  control={control}
                  errors={errors}
                />
              </Grid>
              {showWarning && (
                <Grid item xs={12}>
                  <Alert
                    text={t('addAddress.locationManuallyAdded.message')}
                    variant={EAlertVariants.warning}
                  />
                </Grid>
              )}
              {samePageAlert && (
                <Grid item xs={12}>
                  <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
                </Grid>
              )}
              <Grid item xs={12} mb={3} mt={2}>
                <Button
                  fullWidth
                  label={t('editAddress.locateOnMap.button')}
                  variant={EButtonVariants.outlined}
                  onClick={async () => {
                    setShowWarning(false);
                    const state = region?.find(
                      (item) => item.uniqueId === regionUniqueId,
                    )?.name;
                    const countryName = country?.find(
                      (item) => item.uniqueId === countryUniqueId,
                    )?.name;

                    await handleSearchLocationCoordsByAddress(
                      {
                        city,
                        street: line1,
                        postalcode: postalCode,
                        state,
                        country: countryName,
                      },
                      (data) => {
                        setValue('latitude', data[0].lat);
                        setValue('longitude', data[0].lon);
                      },
                      isOnlineLicence(),
                    );
                  }}
                  disabled={(!city && !postalCode) || !countryUniqueId}
                />
              </Grid>
            </Grid>
            {!isDesktop && renderAddressLocationMap(false)}
            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={5}>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('general.save.button')}
                  id="save-button"
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              {onCancel && (
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    label={t('general.cancel.button')}
                    id="cancel-button"
                    variant={EButtonVariants.outlined}
                    onClick={onCancel}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item mt={2}>
              <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {isDesktop && (
          <Grid item xs={12} lg={6}>
            {renderAddressLocationMap(true)}
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export { LegalEntityAddressForm };
