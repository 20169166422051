import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { css, Theme } from '@mui/material';
import Button from 'components/atoms/Button';

interface IOpen {
  open: boolean;
  mobile?: boolean;
}

const StyledProfileItem = styled.div<IOpen>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props: IOpen) =>
    !props.open &&
    css`
      & #name {
        display: none;
      }
      & div:nth-of-type(2) {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
      }
      & button {
        background-color: ${colors.primary25};

        & svg {
          color: ${colors.primary900};
        }
      }
    `}
`;

const StyledMenuProfileItem = styled.div<IOpen>`
  display: grid;
  gap: 5px;
  align-items: center;
  margin: auto auto 0;
  background-color: ${colors.primary25};
  width: 200px;
  border-radius: 5px;
  color: ${colors.gray900};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  padding: 9px 12px;
  #email {
    font-weight: 400;
    font-size: 10px;
  }

  ${(props: IOpen) =>
    props.mobile &&
    css`
      width: 95%;
    `}
  & p {
    margin: 0;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
  }

  & p:nth-of-type(2) {
    word-break: break-all;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }

  & button,
  & button:hover {
    background-color: ${colors.primary700};
    border: none;

    & svg {
      color: ${colors.white};
    }
  }

  ${(props: IOpen) =>
    !props.open &&
    css`
      width: 48px;
      height: 48px;
      padding: 0;

      & div:nth-of-type(2) {
        margin-left: 0;
      }

      & #email {
        display: none;
      }

      & button {
        background-color: ${colors.primary25};

        & svg {
          color: ${colors.primary900};
        }
      }
    `}

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('lg')} {
      & {width: 200px};
      };
      ${(theme as Theme).breakpoints.down('sm')} {
      & {width: 95%};
      };
      `};
`;
const StyledLogoutButton = styled(Button)<IOpen>`
  width: 140px;
  margin: 10px auto 0;

  ${(props: IOpen) =>
    !props.open &&
    css`
      min-width: 48px;
      width: 48px;
      height: 48px;
      padding: 0;

      & span:first-of-type {
        margin-left: 0;
        margin-right: 0;
        padding: 0;
      }

      & span:nth-of-type(2) {
        display: none;
      }
    `}
  ${(props: IOpen) =>
    props.mobile &&
    css`
      width: 95%;
    `}
  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('lg')} {
      & {width: 200px};
      };
      ${(theme as Theme).breakpoints.down('sm')} {
      & {width: 95%};
      };
      `};
`;

export { StyledMenuProfileItem, StyledProfileItem, StyledLogoutButton };
