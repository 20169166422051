import * as React from 'react';
import { PropsWithChildren, useMemo, useState } from 'react';
import { initialState, reducer } from 'state/reducers/lang';
import { LangContext } from 'models/langContext';
import { EActionTypes } from 'state/actions/lang';
import { getLanguages } from 'requests/language';
import { getTranslations } from 'requests/message';
import { ILanguage } from 'models/language';
import { ITranslation } from 'models/dictionary';

export const LangProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [showKeyTranslations, setShowKeyTranslations] = useState<boolean>(false);
  const contextKeyTranslations = useMemo(
    () => ({ showKeyTranslations, setShowKeyTranslations }),
    [showKeyTranslations, setShowKeyTranslations],
  );

  const getLanguagesData = async () => {
    const languagesData = await getLanguages();
    if (languagesData) {
      const { content } = languagesData;
      dispatch({ type: EActionTypes.GET_LANGUAGES, payload: content });
    } else {
      dispatch({ type: EActionTypes.GET_LANGUAGES, payload: [] });
    }
  };

  const getTranslationsData = async (lang: ILanguage | null) => {
    if (lang) {
      const translationsData = await getTranslations(lang.code);
      if (translationsData) {
        dispatch({ type: EActionTypes.GET_TRANSLATIONS, payload: translationsData });
      }
    }
  };

  const changeLanguage = (lang: ILanguage | null) => {
    dispatch({ type: EActionTypes.CHANGE_LANGUAGE, payload: lang });
  };

  const getValidTranslationName = (translations: ITranslation[]): string => {
    const filtered = translations.filter(
      (translation) => translation.languageUniqueId === state.selectedLanguage?.uniqueId,
    );
    if (filtered.length === 1) {
      return filtered[0].name;
    }
    return '-';
  };

  const value = useMemo(
    () => ({
      ...state,
      getLanguagesData,
      getTranslationsData,
      changeLanguage,
      getValidTranslationName,
      ...contextKeyTranslations,
    }),
    [state],
  );

  return <LangContext.Provider value={value}>{children}</LangContext.Provider>;
};
