import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import {
  ILegalEntityAddressFormValues,
  LegalEntityAddressForm,
} from 'pages/LegalEntitiesPage/LegalEntityAddressForm';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useParams } from 'react-router-dom';
import { getLegalEntityAddress } from 'requests/legalEntities';
import { ILegalEntityAddress } from 'models/legalEntity';
import { useTranslations } from 'hooks/useTranslations';

const LegalEntityEditAddressPage = () => {
  const { id = '', addressId = '' } = useParams<{ id: string; addressId: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const [address, setAddress] = useState<ILegalEntityAddress | null>();
  const { handleEditAddress, navigateToDetails } = useLegalEntities();
  const { t } = useTranslations();

  useEffect(() => {
    (async function getLEAddressDataInit() {
      if (id && addressId) {
        const response = await getLegalEntityAddress(id, addressId);
        if (response) {
          setAddress(response);
        }
      }
    })();
  }, [id, addressId]);

  const handleSubmit = async (data: ILegalEntityAddressFormValues) => {
    const result = await handleEditAddress({ ...data, uniqueId: id }, addressId);
    if (result) {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=address`,
          },
          t('editAddress.header'),
        ]}
      />
      {addressId && (
        <FormWrapper>
          <LegalEntityAddressForm
            onCancel={navigateToDetails}
            onSubmit={handleSubmit}
            formTitle={t('editAddress.header')}
            initialFormValues={address}
          />
        </FormWrapper>
      )}
    </>
  );
};

export { LegalEntityEditAddressPage };
