import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementOptionProp, IFormElementProps } from 'models/form';
import { colors } from 'theme/colors';
import { IconButton } from '@mui/material';
import { EIconTypes } from 'constants/Icons';
import Icon from 'components/atoms/Icon/Icon';
import { useTranslations } from 'hooks/useTranslations';
import { StyledFormControl, StyledInnerWrapper, StyledSelect } from './styles';

interface IFormSelect extends IFormElementProps {
  options: IFormElementOptionProp[];
  disabled?: boolean;
  required?: boolean;
  withValidation?: boolean;
  withLabel?: boolean;
  setValue: any;
  setValueAdditionalBehavior?: (value: any) => void;
  className?: string;
  canBeEmpty?: boolean;
  arrayName?: string;
  arrayIndex?: number;
  fieldName?: string;
  onSelect?: (arg: any) => void;
  translate?: boolean;
}

export const FormSelect = ({
  name,
  label,
  options,
  control,
  errors,
  disabled,
  required,
  className,
  setValue,
  setValueAdditionalBehavior,
  arrayName,
  arrayIndex,
  canBeEmpty = true,
  fieldName,
  withValidation = true,
  withLabel = true,
  onSelect,
  translate = false,
}: IFormSelect) => {
  const { t } = useTranslations();
  const error =
    arrayName && arrayIndex !== undefined && fieldName
      ? errors?.[arrayName]?.[arrayIndex]?.[fieldName]?.message
      : errors[name]?.message;

  return (
    <StyledFormElementWrapper className={className}>
      {withLabel && (
        <FormLabel name={name} label={label} disabled={disabled} required={required} />
      )}
      <StyledInnerWrapper>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <StyledFormControl className={className} error={!!errors[name]?.message}>
              <StyledSelect
                {...field}
                displayEmpty
                placeholder={t('formSelect.all.placeholder')}
                labelId={`${name}-label`}
                endAdornment={
                  field.value !== '' &&
                  canBeEmpty &&
                  !disabled && (
                    <IconButton
                      sx={{
                        maxHeight: '32px',
                        maxWidth: '32px',
                      }}
                      onClick={() => {
                        setValue(name, '');
                        if (setValueAdditionalBehavior) {
                          setValueAdditionalBehavior('');
                        }
                      }}
                      id="clear"
                    >
                      <Icon type={EIconTypes.clear} />
                    </IconButton>
                  )
                }
                id={name}
                disabled={disabled}
                error={!!error}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  if (onSelect) {
                    onSelect(e.target.value);
                    if (setValueAdditionalBehavior) {
                      setValueAdditionalBehavior(e.target.value);
                    }
                  }
                }}
                sx={{
                  ...(field.value === '' ? { color: colors.gray300 } : {}),
                  '& .MuiSelect-iconOutlined': {
                    display: canBeEmpty && !disabled && field.value !== '' ? 'none' : '',
                  },
                }}
              >
                <MenuItem
                  disabled={!canBeEmpty}
                  value=""
                  style={{
                    display: 'none',
                  }}
                >
                  {t('formSelect.all.placeholder')}
                </MenuItem>
                {options.map((option: IFormElementOptionProp) => (
                  <MenuItem key={option.value} value={option.value}>
                    {translate ? t(option.label) : option.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
          )}
        />
      </StyledInnerWrapper>
      {withValidation && <FormErrorLabel label={error} />}
    </StyledFormElementWrapper>
  );
};
