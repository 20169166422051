import { StyledTextField } from 'components/molecules/FormDatePicker/styles';
import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { IFormElementProps } from 'models/form';
import { DateOrTimeViewWithMeridiem } from '@mui/x-date-pickers/internals/models';
import FormLabel from 'components/atoms/FormLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { isValidDate } from 'utils/date';
import FormErrorLabel from 'components/atoms/FormErrorLabel';

interface IFormInput extends IFormElementProps {
  placeholder?: string;
  openTo?: DateOrTimeViewWithMeridiem;
  arrayName?: string;
  arrayIndex?: number;
  fieldName?: string;
  dateFormat?: string;
  currentHour?: boolean;
}

export const FormDateTimePicker = ({
  name,
  label,
  control,
  errors,
  openTo = 'day',
  arrayName,
  arrayIndex,
  fieldName,
  placeholder,
  dateFormat,
  currentHour,
}: IFormInput) => {
  const { midDateFormat, getDateFormat } = useGlobalProperty();
  const [firstPicked, setFirstPicked] = useState(false);

  useEffect(() => {
    getDateFormat();
  }, []);

  const handleDate = (originalDate: Date | null, field: { value: string | number | Date }) => {
    let date = new Date(field.value);
    if (originalDate) {
      date = new Date(originalDate);
      if (!firstPicked && currentHour && date && date.getDate() === new Date().getDate()) {
        setFirstPicked(true);
        const currentDate = new Date();
        const currentMinutes = currentDate.getMinutes();
        const roundedMinutes = Math.floor(currentMinutes / 5) * 5;
        date.setHours(currentDate.getHours());
        date.setMinutes(roundedMinutes);
        return date;
      }
    }
    return date;
  };

  const error =
    arrayName && arrayIndex !== undefined && fieldName
      ? errors?.[arrayName]?.[arrayIndex]?.[fieldName]?.message
      : errors[name]?.message;

  const finalDateFormat = dateFormat || midDateFormat;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledFormElementWrapper>
        <FormLabel name={name} label={label} />
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <DateTimePicker
              onChange={(e) => {
                if (isValidDate(e)) {
                  const processedDate = handleDate(e, field);
                  field.onChange(processedDate);
                } else {
                  field.onChange('');
                }
              }}
              format={finalDateFormat}
              openTo={openTo}
              views={['year', 'month', 'day', 'hours', 'minutes']}
              slots={{
                textField: StyledTextField,
              }}
              slotProps={{
                textField: {
                  ref: field.ref,
                  inputProps: {
                    inputMode: 'numeric',
                    placeholder: placeholder || finalDateFormat.toUpperCase(),
                    id: name,
                    onChange: () => setFirstPicked(true),
                  },
                  error: !!error,
                },
              }}
              ampm={false}
              value={field.value !== '' ? handleDate(field.value, field) : null}
            />
          )}
        />
        <FormErrorLabel label={error} />
      </StyledFormElementWrapper>
    </LocalizationProvider>
  );
};
