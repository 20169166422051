import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { initialLegalEntityFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { FormSelect } from 'components/molecules/FormSelect';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { Typography } from '@mui/material';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { EDictionaryPrefixTypesUniqueId, mapDictionaryToOptionProp } from 'helpers/dictionary';
import { ILegalEntityData } from 'components/organisms/LegalEntityDetails';
import { useTranslations } from 'hooks/useTranslations';
import { useIndLEFormSchema } from 'pages/LegalEntitiesPage/schemaValidation';
import { EDictionaryPrefixTypes, EDictionaryTypes, IDictionary } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ECustomFieldObjectType, ECustomFieldType } from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { useAuth } from 'models/authContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { formatDateTimeToIso } from 'utils/date';
import { useScroll } from 'hooks/useScroll';

export interface ILegalEntityFormValues {
  uniqueId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string | null;
  genderUniqueId: any;
  visibilityUniqueId: string;
  city: string;
  customFields?: ICustomFieldForm[];
}

export interface ILegalEntityForm {
  onCancel?: () => void;
  onSubmit: (arg: ILegalEntityFormValues) => void;
  initialFormValues?: ILegalEntityData | null;
}

const LegalEntityForm = ({ onCancel, onSubmit, initialFormValues }: ILegalEntityForm) => {
  const { selectedLanguage } = useLang();
  const {
    loadDictionaries,
    gender,
    legalVisibility,
    legalEntityPrefix,
    civilianPrefix,
    militaryPrefix,
    policePrefix,
    getCustomField,
    customFieldsILE,
  } = useLegalEntities();
  const { elRef, scrollToError } = useScroll();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [prefixOptions, setPrefixOptions] = useState<IDictionary[] | null>(null);
  const [prefixOptionLabel, setPrefixOptionLabel] = useState<EDictionaryPrefixTypes | null>(
    null,
  );
  const [legalEntityPrefixFilter, setLegalEntityPrefixFilter] = useState<IDictionary[]>([]);
  const { t } = useTranslations();
  const { getUser, user } = useAuth();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const { schema } = useIndLEFormSchema(customFieldsILE);
  const { setAlert } = useAlert();
  let wasCustomFieldsDataChanged = false;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    getValues,
  } = useForm<ILegalEntityData | ILegalEntityFormValues>({
    defaultValues: initialFormValues || initialLegalEntityFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    reset(initialFormValues || initialLegalEntityFormValues);
  }, [reset, initialFormValues]);

  useEffect(() => {
    if (initialFormValues?.uniqueId) {
      setValue('city', 'default');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormValues]);

  useEffect(() => {
    if (user === null) {
      getUser();
    }
  }, []);

  useEffect(() => {
    if (user) {
      (async function getUserDataInit() {
        if (user) {
          if (user.prefixGroupIds && user.prefixGroupIds.length) {
            if (legalEntityPrefix) {
              const names = legalEntityPrefix.filter((prefix) =>
                user.prefixGroupIds.includes(prefix.uniqueId.toString()),
              );
              setLegalEntityPrefixFilter(names);
            }
          }
        }
      })();
    }
  }, [user, legalEntityPrefix]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const hasDataChanged = (data: any): boolean => {
    let hasChanged = false;
    if (
      initialFormValues?.dateOfBirth !== data.dateOfBirth ||
      initialFormValues?.firstName !== data.firstName ||
      initialFormValues?.lastName !== data.lastName ||
      initialFormValues?.genderUniqueId !== data.genderUniqueId ||
      initialFormValues?.legalType !== data.legalType ||
      initialFormValues?.prefixGroupUniqueId !== data.prefixGroupUniqueId ||
      initialFormValues?.prefixValueUniqueId !== data.prefixValueUniqueId ||
      initialFormValues?.visibilityUniqueId !== data.visibilityUniqueId
    ) {
      hasChanged = true;
    }

    customFieldsILE.forEach((customField) => {
      let matchingDataCustomFieldValue;
      // eslint-disable-next-line default-case
      switch (customField.customFieldType) {
        case ECustomFieldType.DATE: {
          const value = data[customField.name];
          if (value !== undefined && value !== null) {
            matchingDataCustomFieldValue = formatDateTimeToIso(value);
          }
          break;
        }
        case ECustomFieldType.BOOL: {
          const value = data[customField.name];
          if (value !== undefined && value !== null && value.trim() !== '') {
            matchingDataCustomFieldValue = data[customField.name] === '1';
          }
          break;
        }
        case ECustomFieldType.TEXT: {
          const value = data[customField.name];
          if (value !== undefined && value !== null && value.trim() !== '') {
            matchingDataCustomFieldValue = data[customField.name];
          }
          break;
        }
      }
      let initialCustomField;
      let initCustomFieldData;
      if (!!initialFormValues && initialFormValues.customFields !== undefined) {
        initialCustomField = initialFormValues.customFields.find(
          (initCustomField) => initCustomField.customFieldUniqueId === customField.uniqueId,
        );
        initCustomFieldData =
          initialCustomField &&
          initialCustomField.value !== undefined &&
          initialCustomField.value !== null
            ? initialCustomField.value
            : undefined;
      }
      if (initCustomFieldData !== matchingDataCustomFieldValue) {
        hasChanged = true;
        wasCustomFieldsDataChanged = true;
      }
    });

    return hasChanged;
  };

  const onSubmitForm = async (data: any) => {
    if (!hasDataChanged(data)) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('addLegalEntity.save.warning.text'),
        variant: EAlertVariants.warning,
      });
      return;
    }

    if (wasCustomFieldsDataChanged) {
      data.customFields = [];

      const customFieldPayload: ICustomFieldForm[] = [];

      Object.keys(data).map((key) => {
        const customField = customFieldsILE.find((item) => item.name === key);
        if (customField) {
          const customFieldItem: ICustomFieldForm = {
            customFieldUniqueId: customField.uniqueId,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            if (data[key] === '') {
              customFieldItem.bool = null;
            } else {
              customFieldItem.bool = data[key] === '1';
            }
          } else if (customField.customFieldType === ECustomFieldType.DATE) {
            customFieldItem.date = data[key] ? formatDateTimeToIso(data[key]) : null;
          }
          customFieldPayload.push(customFieldItem);
          delete data[key];
        }
        return false;
      });

      if (customFieldPayload?.length && customFieldPayload.length > 0) {
        data.customFields = customFieldPayload;
      } else {
        delete data.customFields;
      }
    } else {
      delete data.customFields;
    }

    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({
        ...data,
        dateOfBirth:
          data.dateOfBirth !== null
            ? formatDateTimeToIso(data.dateOfBirth)
            : formatDateTimeToIso(new Date().toString()),
      });
      setIsSubmitLoading(false);
    } else {
      await onSubmit(data);
      setIsSubmitLoading(false);
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.Gender,
        EDictionaryTypes.LegalVisibility,
        EDictionaryTypes.legalEntityPrefix,
        EDictionaryTypes.civilianPrefix,
        EDictionaryTypes.militaryPrefix,
        EDictionaryTypes.policePrefix,
      ]);
      await getCustomField(ECustomFieldObjectType.LE_INDIVIDUAL);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    const value = getValues('prefixGroupUniqueId');
    if (legalEntityPrefixFilter?.length && !value) {
      setValue('prefixGroupUniqueId', legalEntityPrefixFilter[0].uniqueId.toString());
      setPrefixOptions(civilianPrefix);
      setPrefixOptionLabel(EDictionaryPrefixTypes.civilianPrefix);
    } else if (legalEntityPrefixFilter?.length && value) {
      switch (value) {
        case EDictionaryPrefixTypesUniqueId.civilianPrefix:
          setPrefixOptions(civilianPrefix);
          setPrefixOptionLabel(EDictionaryPrefixTypes.civilianPrefix);
          break;
        case EDictionaryPrefixTypesUniqueId.militaryPrefix:
          setPrefixOptions(militaryPrefix);
          setPrefixOptionLabel(EDictionaryPrefixTypes.militaryPrefix);
          break;
        case EDictionaryPrefixTypesUniqueId.policePrefix:
          setPrefixOptions(policePrefix);
          setPrefixOptionLabel(EDictionaryPrefixTypes.policePrefix);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntityPrefixFilter, civilianPrefix, militaryPrefix, policePrefix]);

  const legalEntityPrefixChanged = (target: any) => {
    const value = target.value ?? '';
    switch (value) {
      case EDictionaryPrefixTypesUniqueId.civilianPrefix:
        setValue('prefixValueUniqueId', '');
        setPrefixOptions(civilianPrefix);
        setPrefixOptionLabel(EDictionaryPrefixTypes.civilianPrefix);
        break;
      case EDictionaryPrefixTypesUniqueId.militaryPrefix:
        setValue('prefixValueUniqueId', '');
        setPrefixOptions(militaryPrefix);
        setPrefixOptionLabel(EDictionaryPrefixTypes.militaryPrefix);
        break;
      case EDictionaryPrefixTypesUniqueId.policePrefix:
        setValue('prefixValueUniqueId', '');
        setPrefixOptions(policePrefix);
        setPrefixOptionLabel(EDictionaryPrefixTypes.policePrefix);
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container columnSpacing={3} ref={elRef}>
        <Grid item xs={12} sm={6} lg={4}>
          <FormRadioGroup
            name="prefixGroupUniqueId"
            label={`${t('editLegalEntity.legalEntityPrefix.label')}*`}
            options={mapDictionaryToOptionProp(legalEntityPrefixFilter)}
            control={control}
            errors={errors}
            onChangeOption={legalEntityPrefixChanged}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormSelect
            options={mapDictionaryToOptionProp(prefixOptions)}
            setValue={setValue}
            name="prefixValueUniqueId"
            label={`${t(`editLegalEntity.${prefixOptionLabel}.label`)}*`}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormInput
            name="firstName"
            label={`${t('editLegalEntity.firstName.label')}*`}
            setValue={setValue}
            control={control}
            errors={errors}
            charsLimit={20}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormInput
            name="lastName"
            label={`${t('editLegalEntity.lastName.label')}*`}
            setValue={setValue}
            control={control}
            errors={errors}
            charsLimit={20}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormDatePicker
            name="dateOfBirth"
            dateFormat={shortDateFormat}
            label={`${t('editLegalEntity.dateOfBirth.label')}*`}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormSelect
            options={mapDictionaryToOptionProp(gender)}
            setValue={setValue}
            name="genderUniqueId"
            label={`${t('editLegalEntity.gender.label')}*`}
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormRadioGroup
            name="visibilityUniqueId"
            label={`${t('editLegalEntity.visibility.label')}*`}
            options={mapDictionaryToOptionProp(legalVisibility)}
            control={control}
            errors={errors}
          />
        </Grid>

        {customFieldsILE.map((customField) => (
          <Grid item xs={12} sm={6} lg={4} key={customField.uniqueId}>
            <FormCustomField
              data={customField}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Grid>
        ))}

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              type="submit"
              fullWidth
              label={t('general.save.button')}
              id="legal_entity-save"
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="legal_entity-cancel"
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>
        <Grid item mt={2}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export { LegalEntityForm };
