import React from 'react';
import { PageTemplate } from 'components/templates/PageTemplate';
import { config } from 'pages/config';
import { IPageBuilderConfig } from 'models/route';
import { Layout } from 'components/templates/Layout';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { AuthProvider } from 'state/contexts/auth';
import { PasswordProvider } from 'state/contexts/password';
import { TrustedDeviceProvider } from 'state/contexts/trustedDevice';
import { AlertProvider } from 'state/contexts/alert';
import { AncilariesProvider } from 'state/contexts/ancillaries';
import { DictionaryProvider } from 'state/contexts/dictionary';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import { AxiosInterceptor } from 'utils/apiClient';
import { LangProvider } from 'state/contexts/lang';
import { DrawerProvider } from 'state/contexts/drawer';
import { NotificationCounterProvider } from 'state/contexts/notification';
import ScrollToTop from 'components/templates/ScrollToTop';
import { GlobalPropertyProvider } from 'state/contexts/globalProperty';

export const history = createBrowserHistory();

const App = () => (
  <HistoryRouter history={history}>
    <LangProvider>
      <AuthProvider>
        <PasswordProvider>
          <TrustedDeviceProvider>
            <DictionaryProvider>
              <AlertProvider>
                <AncilariesProvider>
                  <AxiosInterceptor />
                  <NotificationCounterProvider>
                    <GlobalPropertyProvider>
                      <DrawerProvider>
                        <Layout>
                          <ScrollToTop history={history} />
                          <PageTemplate config={config as IPageBuilderConfig[]} />
                        </Layout>
                      </DrawerProvider>
                    </GlobalPropertyProvider>
                  </NotificationCounterProvider>
                </AncilariesProvider>
              </AlertProvider>
            </DictionaryProvider>
          </TrustedDeviceProvider>
        </PasswordProvider>
      </AuthProvider>
    </LangProvider>
  </HistoryRouter>
);

export default App;
