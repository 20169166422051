import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import ARTAccessoriesForm from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTAccessoriesForm';
import { IARTAccessoriesFormValues, IARTComponentsFormValues } from 'models/ancillaries';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Box } from '@mui/material';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { ERouteLinks } from 'models/route';
import ARTAmmunitionForm from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTAmmunitionForm';
import { IAmmunitionFormValues } from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAmmunitionSearchForm';
import ARTComponentsForm from 'pages/AncillariesPage/AncillariesReferenceTablePage/ARTComponentsForm';
import { StyledSelect } from '../styles';
import { AncillaryStyle, FormStyle } from './styles';

const COMPONENT_OPTION_VALUE = 'f02686c9-148f-46ca-ac8c-3c63cd1f7eea';

const AddAncillariesReference = () => {
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const {
    loadDictionaries,
    ancillaryOption,
    setAncillaryOption,
    artAncillaryOptions,
    handleCreateAccessory,
    handleCreateAmmunition,
    handleCreateComponent,
  } = useAncillaries();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const { state: routerState }: any = useLocation();

  useEffect(() => {
    if (routerState?.firearm) {
      setAncillaryOption(COMPONENT_OPTION_VALUE);
    }
  }, [routerState]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (ancillaryOption) {
      setShowAllOptions(true);
    } else {
      setShowAllOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ancillaryOption]);

  useEffect(() => {
    if (ancillaryOption === EArtAncillaryType.ACCESSORIES) {
      setAlertText('addAncillary.accessory.text');
    }
    if (ancillaryOption === EArtAncillaryType.AMMUNITION) {
      setAlertText('addAncillary.ammunition.text');
    }
    if (ancillaryOption === EArtAncillaryType.COMPONENTS) {
      setAlertText('addAncillary.component.text');
    }
  }, [ancillaryOption]);

  const addArtAncillary = async (
    data: IARTAccessoriesFormValues | IAmmunitionFormValues | IARTComponentsFormValues,
  ) => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    try {
      if (ancillaryOption === EArtAncillaryType.ACCESSORIES) {
        await handleCreateAccessory(data as IARTAccessoriesFormValues);
      } else if (ancillaryOption === EArtAncillaryType.AMMUNITION) {
        await handleCreateAmmunition(data as IAmmunitionFormValues);
      } else if (ancillaryOption === EArtAncillaryType.COMPONENTS) {
        await handleCreateComponent(data as IARTComponentsFormValues);
      }
      setAlert(ActionTypes.SET_ANCILLARY_ALERT, {
        text: t(`${alertText}`),
        variant: EAlertVariants.success,
      });
      navigate(
        `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table&ancillaryOptionName=${ancillaryOption}`,
      );
    } catch (e: any) {
      if (e.response.data.message) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: e.response.data.message,
          variant: EAlertVariants.error,
        });
      }
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.ancillariesreferencetable.name'),
            route: `${ERouteLinks.Ancillaries}?expand=ancillaries-reference-table`,
          },
          t('module.addancilary.name'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('module.addancilary.name')}</StyledPageTitle>
      <Grid container>
        <AncillaryStyle item md={6} sm={12} xs={12}>
          <StyledSelect
            options={mapDictionaryToOptionProp(artAncillaryOptions)}
            name="preset"
            label={`${t('ancillariesReferenceTable.accordion.ancillary.label')}*`}
            value={ancillaryOption}
            setValue={(value: string) => setAncillaryOption(value)}
          />
        </AncillaryStyle>
        <FormStyle item xs={12}>
          {showAllOptions && ancillaryOption === EArtAncillaryType.ACCESSORIES && (
            <ARTAccessoriesForm onSubmit={addArtAncillary} />
          )}
          {showAllOptions && ancillaryOption === EArtAncillaryType.AMMUNITION && (
            <ARTAmmunitionForm onSubmit={addArtAncillary} />
          )}
          {showAllOptions && ancillaryOption === EArtAncillaryType.COMPONENTS && (
            <ARTComponentsForm onSubmit={addArtAncillary} />
          )}
        </FormStyle>
        {samePageAlert && (
          <Box mt={2}>
            <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
          </Box>
        )}
      </Grid>
    </>
  );
};

export default AddAncillariesReference;
