import { ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { ApiEndpoints } from 'models/apiEndpoints';
import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import {
  ISearchFilter,
  ISearchFilterAccessory,
  ISearchFilterFirearmRequest,
  ISearchFilterIndividualRequest,
  ISearchFilterOrganizationRequest,
  ISearchFilterTransactionRequest,
} from 'models/searchFilter';

export const getSearchFilters = async (type?: ESearchFilterTypes) => {
  const path = type
    ? ApiEndpoints.GET_SEARCH_FILTER_BY_TYPE(type)
    : ApiEndpoints.GET_SEARCH_FILTER();

  const { data } = await apiClient.get<TResponse<ISearchFilter[]>>(path);
  if (isResponseError(data)) {
    return null;
  }

  if (type === undefined) {
    return data.map((filter) => {
      const modifiedValues = filter.values.map((value) => {
        if (value.key === 'ownerName') {
          return { key: 'Owner', value: value.value };
        }
        if (value.key === 'keeperName') {
          return { key: 'Keeper', value: value.value };
        }
        return value;
      });
      return { ...filter, values: modifiedValues };
    });
  }

  return data;
};

export const getSearchFilter = async (id: string) => {
  const { data } = await apiClient.get<TResponse<ISearchFilter>>(
    ApiEndpoints.GET_SEARCH_FILTER_BY_ID(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export const deleteSearchFilter = async (id: string) => {
  try {
    const { data } = await apiClient.delete(ApiEndpoints.DELETE_SEARCH_FILTER(id));
    return !isResponseError(data);
  } catch (e) {
    return false;
  }
};

export const createSearchFilterIndividual = async (
  name: string,
  emailNotificationEnabled: boolean,
  notificationEnabled: boolean,
  body: any,
) => {
  try {
    const res = await apiClient.post<TResponse<ISearchFilterIndividualRequest>>(
      ApiEndpoints.CREATE_SEARCH_FILTER_INDIVIDUAL(
        name,
        emailNotificationEnabled,
        notificationEnabled,
      ),
      body,
    );

    if (isResponseError(res.data)) {
      return null;
    }

    return res;
  } catch (e: any) {
    return e;
  }
};

export const createSearchFilterOrganization = async (
  name: string,
  emailNotificationEnabled: boolean,
  notificationEnabled: boolean,
  body: any,
) => {
  try {
    const res = await apiClient.post<TResponse<ISearchFilterOrganizationRequest>>(
      ApiEndpoints.CREATE_SEARCH_FILTER_ORGANIZATION(
        name,
        emailNotificationEnabled,
        notificationEnabled,
      ),
      body,
    );

    if (isResponseError(res.data)) {
      return null;
    }

    return res;
  } catch (e: any) {
    return e;
  }
};

export const toggleSearchFilterNotification = async (id: string, active: boolean) => {
  const { data } = await apiClient.patch<TResponse<string>>(
    `${ApiEndpoints.TOGGLE_SEARCH_FILTER_NOTIFICATION(id, active)}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return true;
};

export const createSearchFilterFirearm = async (
  name: string,
  emailNotificationEnabled: boolean,
  notificationEnabled: boolean,
  body: ISearchFilterFirearmRequest,
) => {
  try {
    const res = await apiClient.post<TResponse<ISearchFilterFirearmRequest>>(
      ApiEndpoints.CREATE_SEARCH_FILTER_FIREARM(
        name,
        emailNotificationEnabled,
        notificationEnabled,
      ),
      body,
    );

    if (isResponseError(res.data)) {
      return null;
    }

    return res;
  } catch (e) {
    return e;
  }
};

export const createSearchFilterTransaction = async (
  name: string,
  emailNotificationEnabled: boolean,
  notificationEnabled: boolean,
  body: ISearchFilterTransactionRequest,
) => {
  try {
    const res = await apiClient.post<TResponse<ISearchFilterTransactionRequest>>(
      ApiEndpoints.CREATE_SEARCH_FILTER_TRANSACTION(
        name,
        emailNotificationEnabled,
        notificationEnabled,
      ),
      body,
    );

    if (isResponseError(res.data)) {
      return null;
    }

    return res;
  } catch (e: any) {
    return e;
  }
};

export const createSearchFilterAccessory = async (
  name: string,
  emailNotificationEnabled: boolean,
  notificationEnabled: boolean,
  body: any,
) => {
  try {
    const res = await apiClient.post<TResponse<ISearchFilterAccessory>>(
      ApiEndpoints.CREATE_SEARCH_FILTER_ACCESSORY(
        name,
        emailNotificationEnabled,
        notificationEnabled,
      ),
      body,
    );

    if (isResponseError(res.data)) {
      return null;
    }

    return res;
  } catch (e: any) {
    return e;
  }
};
