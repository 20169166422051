import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useQuery } from 'hooks/useQuery';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { Accordion } from 'components/molecules/Accordion';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { Typography } from '@mui/material';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteCommentPage/styles';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import { ILegalEntityData } from 'components/organisms/LegalEntityDetails';
import { Loader } from 'components/atoms/Loader';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { colors } from 'theme/colors';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EIconTypes } from 'constants/Icons';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { FormInput } from 'components/molecules/FormInput';
import Icon from 'components/atoms/Icon/Icon';
import Link from '@mui/material/Link';
import { useDictionary } from 'models/dictionaryContext';
import {
  EDictionaryTypes,
  ETransactionDictionaryItemTypes,
  IDictionary,
} from 'models/dictionary';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { EMIMETypes } from 'constants/MIMETypes';
import { createAncillaryInitialTransaction } from 'requests/transaction';
import { ETransactionTypes } from 'constants/TransactionTypes';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { AddAncillaryListItem } from 'components/organisms/AddAncillaryListItem';
import { convertSimpleDate, convertToSimpleDate, formatDateTimeToIso } from 'utils/date';
import { useAddAncillaryFormSchema } from 'pages/LegalEntitiesPage/LegalEntityAddAncillaryPage/schemaValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EArtAccessoryType,
  EArtAmmunitionType,
  EArtAncillaryTypeName,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import { FormDateTimePicker } from 'components/molecules/FormDateTimePicker';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { generateAncillariesListTemplate } from 'requests/ancillaries';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useScroll } from 'hooks/useScroll';
import ImportAncillaries from 'components/organisms/ImportAncillaries';
import { getEnumByValue } from 'utils/enum';
import { useGlobalProperty } from 'models/globalPropertyContext';
import {
  StyledFirearmListBox,
  StyledIconWrapper,
  StyledLine,
  StyledLocationMarkConfirmationMarginBottom,
  StyledTitleFirearmInformation,
} from './styles';

const MAX_FILE_SIZE_IN_MB = 3;

export const TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED,
  ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
  ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_LOT_BATCH_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.DECLARED_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_DISASSEMBLED,
];

export const TRANSACTION_INITIAL_TYPES_FOR_AMMUNITION_EXPIRY_DATE_FIELD = [
  ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED,
  ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
  ETransactionTypes.DECLARED_LOT_BATCH_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN,
];

export const TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_REQUIRED = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
];

export const TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS = [
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
];

export const TRANSACTION_INITIAL_ILLEGAL = [
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
];

export const TRANSACTION_WITH_LOT_BATCH_NUMBER = [
  ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED,
  ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
  ETransactionTypes.DECLARED_LOT_BATCH_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.SEIZED,
];

export const TRANSACTION_WITH_SERIAL_NUMBER = [
  ETransactionTypes.DECLARED_DISASSEMBLED,
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_UNKNOWN_ORIGIN,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SURRENDERED,
];

export const TRANSACTION_WITH_IMPORTER = [
  ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED,
  ETransactionTypes.DECLARED_IMPORTED,
];

export const ACCESSORY_TYPES_WITHOUT_SERIAL_NUMBER = [
  EArtAccessoryType.MAGAZINES,
  EArtAccessoryType.LASER_LIGHT_MODULES,
  EArtAccessoryType.OPTICAL_SIGHTS,
];

export const ANCILLARY_TRANSACTIONS_WITH_AUTOCOMPLETED_MANUFACTURED_AT = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
];

const individualTransactionTypes = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.DECLARED_LOT_BATCH_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED,
  ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
  ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_DISASSEMBLED,
];

const organizationTransactionTypes = [
  ETransactionTypes.DECLARED_MANUFACTURED,
  ETransactionTypes.DECLARED_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_IMPORTED,
  ETransactionTypes.SEIZED,
  ETransactionTypes.RECOVERED,
  ETransactionTypes.SURRENDERED,
  ETransactionTypes.DECLARED_LOT_BATCH_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED,
  ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED,
  ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN,
  ETransactionTypes.DECLARED_DISASSEMBLED,
];

const LegalEntityAddAncillaryPage = () => {
  const { t } = useTranslations();

  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const { elRef, scrollToError } = useScroll();
  const type: string | null = query.get('type');
  const initialFormValues: Record<string, any> = {
    typeUniqueId: routerState?.typeUniqueId || '',
    serialNumberEnabled: routerState?.serialNumberEnabled || true,
    lotBatchEnabled: routerState?.lotBatchEnabled || true,
    isAccessorySelected: routerState?.isAccessorySelected || false,
    exhibitNumberEnabled: routerState?.exhibitNumberEnabled || false,
    isLotBatchNumberNotPresent: routerState?.isLotBatchNumberNotPresent || false,
    validationOff: false,
    transactionDate: routerState?.transactionDate || '',
    firearmsQuantity: routerState?.firearmsQuantity || 'one',
    serialNumber: routerState?.serialNumber || '',
    assetId: routerState?.assetId || '',
    exhibitNumber: routerState?.exhibitNumber || '',
    isExhibitNumberChecked: routerState?.isExhibitNumberChecked || false,
    lotNumber: routerState?.lotNumber || '',
    batchNumber: routerState?.batchNumber || '',
    externalReferenceNumber: routerState?.externalReferenceNumber || '',
    manufacturedAt: routerState?.manufacturedAt || null,
    isCreateGroup: routerState?.isCreateGroup || 'no',
    groupName: routerState?.groupName || '',
    countryOfImportUniqueId: routerState?.countryOfImportUniqueId || '',
    importer: routerState?.importer || '',
    ancillaryList: routerState?.ancillaryList?.length
      ? routerState?.ancillaryList
      : [
          {
            serialNumber: '',
            assetId: '',
            exhibitNumber: '',
            externalReferenceNumber: '',
            isExhibitNumberChecked: false,
            isLotBatchNumberNotPresent: false,
            ...(TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD?.includes(
              routerState?.typeUniqueId,
            )
              ? { manufacturedAt: null }
              : {}),
          },
        ],
    attachments: routerState?.attachments?.length ? routerState?.attachments : [],
  };

  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [isLegalEntityLoading, setIsLegalEntityLoading] = useState<boolean>(false);
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const [isOrgLegalEntityLoading, setIsOrgLegalEntityLoading] = useState<boolean>(false);
  const [isErrorLocation, setIsErrorLocation] = useState<boolean>(false);
  const { loadDictionaries, getCustomField, customFieldsAncillaries } = useLegalEntities();
  const { isDesktop } = useThemeBreakpoint();
  const { getTransactionInitialTypes, transactionInitialTypes } = useDictionary();
  const { setAlert } = useAlert();
  const [selectedAncillary, setSelectedAncillary] = useState<any>(null);
  const [createInitialTransactionError, setCreateInitialTransactionError] =
    useState<string>('');
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
  const [isExhibitNumberChecked, setIsExhibitNumberChecked] = useState<boolean>(
    routerState?.isExhibitNumberChecked || false,
  );
  const [isLotBatchNumberNotPresent, setIsLotBatchNumberNotPresent] = useState<boolean>(
    routerState?.isLotBatchNumberNotPresent || false,
  );
  const { selectedLanguage } = useLang();
  const { country } = useDictionary();
  const { schema } = useAddAncillaryFormSchema(customFieldsAncillaries);
  const { shortDateFormat } = useGlobalProperty();
  const { getLegalEntityAddressesData, legalEntityAddress } = useLegalEntities();
  const [serialNumberEnabled, setSerialNumberEnabled] = useState(
    routerState?.serialNumberEnabled || false,
  );
  const [submitBlocked, setSubmitBlocked] = useState(false);
  const [isReloadCausedLocationSelect, setIsReloadCausedLocationSelect] = useState(
    routerState?.isReloadCausedLocationSelect || false,
  );
  const [isDataImportedFromFile, setIsDataImportedFromFile] = useState<boolean>(false);

  const defaultAddress = legalEntityAddress.filter((address) => address.isDefault).shift();

  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    getValues,
    setValue,
    clearErrors,
  } = useForm({ defaultValues: initialFormValues, resolver: yupResolver(schema) });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ancillaryList',
  });
  const attachments = useFieldArray({
    control,
    name: 'attachments',
  });

  const firearmsQuantityValue = watch('firearmsQuantity');
  const transactionTypeValue = watch('typeUniqueId');
  const isCreateGroupValue = watch('isCreateGroup');
  const transactionDate = watch('transactionDate');

  const isShowList = firearmsQuantityValue === 'many';
  const isShowLocation =
    transactionTypeValue === ETransactionTypes.SEIZED ||
    transactionTypeValue === ETransactionTypes.RECOVERED;
  const isShowGroupNameFormElement = isCreateGroupValue === 'yes';
  const isShowDocument = true;
  const isAccessorySelected =
    selectedAncillary?.ancillary === EArtAncillaryTypeName.ACCESSORY ||
    routerState?.ancillaryType === EArtAncillaryTypeName.ACCESSORY;
  const isComponentSelected =
    selectedAncillary?.ancillary === EArtAncillaryTypeName.COMPONENTS ||
    routerState?.ancillaryType === EArtAncillaryTypeName.COMPONENTS;
  const isExhibitNumberEnabled =
    TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.includes(transactionTypeValue);
  const isLotBatchEnabled =
    TRANSACTION_WITH_LOT_BATCH_NUMBER.includes(transactionTypeValue) &&
    selectedAncillary?.ancillary === EArtAncillaryTypeName.AMMUNITION;
  const isShowImporterDetails = TRANSACTION_WITH_IMPORTER.includes(transactionTypeValue);
  const isManufacturedDateEnabled =
    TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD.includes(transactionTypeValue);
  const isAmmunitionExpiryDateEnabled =
    TRANSACTION_INITIAL_TYPES_FOR_AMMUNITION_EXPIRY_DATE_FIELD.includes(transactionTypeValue);
  const isTransactionIllegal = TRANSACTION_INITIAL_ILLEGAL.includes(transactionTypeValue);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.CountryAll]);

      if (selectedAncillary) {
        if (selectedAncillary.ancillary) {
          getTransactionInitialTypes(
            selectedAncillary.ancillary.toUpperCase() as ETransactionDictionaryItemTypes,
          );
        } else if (routerState?.ancillaryType) {
          getTransactionInitialTypes(
            routerState.ancillaryType.toUpperCase() as ETransactionDictionaryItemTypes,
          );
        }

        const ancillaryType = selectedAncillary?.ancillary || routerState?.ancillaryType;
        if (ancillaryType === EArtAncillaryTypeName.ACCESSORY) {
          await getCustomField(ECustomFieldObjectType.AN_ACCESSORY);
        } else if (ancillaryType === EArtAncillaryTypeName.AMMUNITION) {
          await getCustomField(ECustomFieldObjectType.AN_AMMUNITION);
        } else if (ancillaryType === EArtAncillaryTypeName.COMPONENTS) {
          await getCustomField(ECustomFieldObjectType.AN_COMPONENT);
        }
      }
    })();
  }, [selectedLanguage, selectedAncillary]);

  useEffect(() => {
    if (routerState && routerState.isExhibitNumberChecked && !isExhibitNumberChecked) {
      setIsExhibitNumberChecked(true);
    }
  }, []);

  const clearUserInput = () => {
    setIsReloadCausedLocationSelect(false);

    setValue('lotNumber', '');
    setValue('batchNumber', '');
    setValue('assetId', '');
    setValue('serialNumber', '');
    setValue('externalReferenceNumber', '');
    setValue('exhibitNumber', '');
    setValue('manufacturedAt', '');
    setValue('ammunitionExpiryDate', '');
    setValue('importer', '');
    setValue('countryOfImportUniqueId', '');
    clearErrors();

    customFieldsAncillaries.forEach((customField) => setValue(customField.name, ''));
  };

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  useEffect(() => {
    if (transactionTypeValue === ETransactionTypes.DECLARED_MANUFACTURED) {
      if (firearmsQuantityValue === 'one') {
        if (transactionDate instanceof Date) {
          const manufacturedAt: Date = new Date(transactionDate);
          manufacturedAt.setHours(0);
          manufacturedAt.setMinutes(0);
          manufacturedAt.setSeconds(0);
          manufacturedAt.setMilliseconds(0);
          setValue('manufacturedAt', manufacturedAt);
        } else {
          setValue('manufacturedAt', transactionDate);
        }
      } else if (firearmsQuantityValue === 'many') {
        if (transactionDate instanceof Date) {
          const manufacturedAt: Date = new Date(transactionDate);
          manufacturedAt.setHours(0);
          manufacturedAt.setMinutes(0);
          manufacturedAt.setSeconds(0);
          manufacturedAt.setMilliseconds(0);
          fields.forEach((_, index) => {
            setValue(`ancillaryList[${index}].manufacturedAt`, manufacturedAt);
          });
        } else {
          fields.forEach((_, index) => {
            setValue(`ancillaryList[${index}].manufacturedAt`, transactionDate);
          });
        }
      }
    }
  }, [transactionTypeValue, transactionDate, firearmsQuantityValue, setValue, fields]);

  useEffect(() => {
    if (selectedAncillary) {
      if (transactionTypeValue === '') {
        setSerialNumberEnabled(false);
        setValue('serialNumberEnabled', false);
        return;
      }

      const accessoryType: EArtAccessoryType = getEnumByValue(
        EArtAccessoryType,
        selectedAncillary.accessoryTypeUniqueId,
      );

      const isSerialDisabled =
        selectedAncillary.ancillary === EArtAncillaryTypeName.AMMUNITION ||
        (selectedAncillary.ancillary === EArtAncillaryTypeName.ACCESSORY &&
          selectedAncillary.accessoryType &&
          (!TRANSACTION_WITH_SERIAL_NUMBER.includes(transactionTypeValue) ||
            ACCESSORY_TYPES_WITHOUT_SERIAL_NUMBER.includes(accessoryType)));
      setValue('serialNumberEnabled', !isSerialDisabled);
      setSerialNumberEnabled(!isSerialDisabled);
    }
  }, [selectedAncillary, transactionTypeValue]);

  useEffect(() => {
    if (selectedAncillary && !isReloadCausedLocationSelect) {
      setValue('isAccessorySelected', isAccessorySelected);
      setValue('exhibitNumberEnabled', isExhibitNumberEnabled);
      setValue('lotBatchEnabled', isLotBatchEnabled);
      setValue('isLotBatchNumberNotPresent', false);
      setValue('isExhibitNumberChecked', false);
      setIsLotBatchNumberNotPresent(false);
      setIsExhibitNumberChecked(false);
    }
  }, [selectedAncillary, transactionTypeValue]);

  useEffect(() => {
    if (type === ELegalEntityTypes.individual) {
      (async function getLegalEntityDataInit() {
        setIsLegalEntityLoading(true);
        if (id) {
          const response = await getLegalEntity(id);
          if (response) {
            setLegalEntity(response);
          }
          setIsLegalEntityLoading(false);
        }
      })();
    } else if (type === ELegalEntityTypes.organisation) {
      (async function getLegalEntityDataInit() {
        setIsOrgLegalEntityLoading(true);
        if (id) {
          const response = await getOrgLegalEntity(id);
          if (response) {
            setOrgLegalEntity(response);
          }
          setIsOrgLegalEntityLoading(false);
        }
      })();
    }
    getLegalEntityAddressesData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  useEffect(() => {
    if (routerState?.ancillary) {
      setSelectedAncillary({
        ancillary: routerState?.ancillaryType,
        ...routerState?.ancillary,
      });
    }
  }, [routerState]);

  useEffect(() => {
    if (
      ANCILLARY_TRANSACTIONS_WITH_AUTOCOMPLETED_MANUFACTURED_AT.includes(transactionTypeValue)
    ) {
      if (firearmsQuantityValue === 'one') {
        if (transactionDate instanceof Date) {
          const manufacturedAt: Date = new Date(transactionDate);
          manufacturedAt.setHours(0);
          manufacturedAt.setMinutes(0);
          manufacturedAt.setSeconds(0);
          manufacturedAt.setMilliseconds(0);
          setValue('manufacturedAt', manufacturedAt);
        } else {
          setValue('manufacturedAt', transactionDate);
        }
      } else if (firearmsQuantityValue === 'many') {
        if (transactionDate instanceof Date) {
          const manufacturedAt: Date = new Date(transactionDate);
          manufacturedAt.setHours(0);
          manufacturedAt.setMinutes(0);
          manufacturedAt.setSeconds(0);
          manufacturedAt.setMilliseconds(0);
          fields.forEach((_, index) => {
            setValue(`ancillaryList[${index}].manufacturedAt`, manufacturedAt);
          });
        }
        fields.forEach((_, index) => {
          setValue(`ancillaryList[${index}].manufacturedAt`, transactionDate);
        });
      }
    }
  }, [transactionTypeValue, transactionDate, firearmsQuantityValue, setValue, fields]);

  const quantityOptions = [
    {
      value: 'one',
      label: t('addAncillary.ancillaryDetails.section.one.radio'),
    },
    {
      value: 'many',
      label: t('addAncillary.ancillaryDetails.section.many.radio'),
    },
  ];

  const createGroupOptions = [
    {
      value: 'yes',
      label: t('general.yes.button'),
    },
    {
      value: 'no',
      label: t('general.no.button'),
    },
  ];

  const filteredTransactionInitialTypes = useRef<IDictionary[] | null>([]);
  const breadcrumbs = useRef([
    { label: t('addAncillary.legalEntities.breadcrumb'), route: ERouteLinks.LegalEntities },
    {
      label:
        type === ELegalEntityTypes.individual
          ? t('addAncillary.individual.breadcrumb')
          : t('addAncillary.organisation.breadcrumb'),
      route: `${ERouteLinks.LegalEntities}?expand=${
        type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
      }`,
    },
    {
      label: t('addAncillary.legalEntityDetails.breadcrumb'),
      route: `${ERouteLinks.ViewLegalEntity.replace(
        ':id',
        id,
      )}?type=${type}&expand=ancillaries`,
    },
    t('addAncillary.addAncillary.breadcrumb'),
  ]);

  breadcrumbs.current = [
    { label: t('addAncillary.legalEntities.breadcrumb'), route: ERouteLinks.LegalEntities },
    {
      label:
        type === ELegalEntityTypes.individual
          ? t('addAncillary.individual.breadcrumb')
          : t('addAncillary.organisation.breadcrumb'),
      route: `${ERouteLinks.LegalEntities}?expand=${
        type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
      }`,
    },
    {
      label: t('addAncillary.legalEntityDetails.breadcrumb'),
      route: `${ERouteLinks.ViewLegalEntity.replace(
        ':id',
        id,
      )}?type=${type}&expand=ancillaries`,
    },
    t('addAncillary.addAncillary.breadcrumb'),
  ];

  useEffect(() => {
    if (transactionInitialTypes) {
      if (type === ELegalEntityTypes.individual) {
        filteredTransactionInitialTypes.current = transactionInitialTypes?.filter(
          ({ uniqueId: id1 }) =>
            !TRANSACTION_INITIAL_TYPES_ONLY_FOR_ORG_IDS.some((id2) => id2 === id1),
        );
      } else if (type === ELegalEntityTypes.organisation) {
        filteredTransactionInitialTypes.current = transactionInitialTypes;
      }
    }
  }, [type, transactionInitialTypes]);

  useEffect(() => {
    customFieldsAncillaries.forEach((customField) => {
      if (routerState && routerState[customField.name]) {
        const value = routerState[customField.name] || '';
        setValue(customField.name, value);
      }
    });
  }, [customFieldsAncillaries]);

  const handleCreateInitialTransaction = () => {
    setCreateInitialTransactionError('');
    handleSubmit(async (data) => {
      const getCustomFieldsData = (ancillary: any) => {
        const customFieldsPayload: ICustomFieldForm[] = [];
        Object.keys(ancillary).map((key) => {
          const customField = customFieldsAncillaries.find((item) => item.name === key);
          if (customField && ancillary[key]) {
            const customFieldItem: ICustomFieldForm = {
              customFieldUniqueId: customField.uniqueId,
            };
            if (customField.customFieldType === ECustomFieldType.TEXT) {
              if (ancillary[key] === undefined || ancillary[key] === null) {
                customFieldItem.text = undefined;
              } else if (ancillary[key].trim() === '') {
                customFieldItem.text = undefined;
              } else {
                customFieldItem.text = ancillary[key];
              }
            } else if (customField.customFieldType === ECustomFieldType.BOOL) {
              customFieldItem.bool = ancillary[key] === '1';
            } else if (customField.customFieldType === ECustomFieldType.DATE) {
              customFieldItem.date = formatDateTimeToIso(ancillary[key]);
            }
            customFieldsPayload.push(customFieldItem);
            delete ancillary[key];
          }
          return false;
        });

        return customFieldsPayload?.length ? { customFields: customFieldsPayload } : {};
      };

      setIsErrorLocation(false);
      const date = formatDateTimeToIso(data.transactionDate);

      if (selectedAncillary === null) {
        return setCreateInitialTransactionError(t('addAncillary.youMustAddAncillary.text'));
      }

      if (isShowLocation && !routerState?.coords) {
        return setIsErrorLocation(true);
      }

      const prepareAncillaryData = (item: any) => ({
        artUniqueId: selectedAncillary.id || selectedAncillary.uniqueId,
        artLocal: true,
        ...getCustomFieldsData(item),
        ...(item?.externalReferenceNumber
          ? { externalReferenceNumber: item.externalReferenceNumber }
          : {}),
        ...(item?.serialNumber
          ? { serialNumber: item.isExhibitNumberChecked ? {} : item.serialNumber }
          : {}),
        ...(item?.assetId ? { assetId: item.assetId } : {}),
        ...(item?.exhibitNumber ? { exhibitNumber: item.exhibitNumber } : {}),
        ...(item?.lotNumber ? { lotNumber: item.lotNumber } : {}),
        ...(item?.batchNumber ? { batchNumber: item.batchNumber } : {}),
        manufacturedAt:
          item.manufacturedAt && item.manufacturedAt !== ''
            ? formatDateTimeToIso(item.manufacturedAt)
            : null,
        ammunitionExpiryDate:
          item.ammunitionExpiryDate && item.ammunitionExpiryDate !== ''
            ? formatDateTimeToIso(item.ammunitionExpiryDate)
            : null,
      });

      const ancillaries = isShowList
        ? data.ancillaryList.map((ancillary: any) => prepareAncillaryData(ancillary))
        : [prepareAncillaryData(data)];

      const leId =
        type === ELegalEntityTypes.individual
          ? legalEntity?.uniqueId
          : orgLegalEntity?.uniqueId;

      let address;
      let city;
      if (isTransactionIllegal) {
        address = routerState?.address;
        city = routerState?.city;
      } else {
        address =
          defaultAddress !== null && defaultAddress !== undefined
            ? `${defaultAddress?.postalCode} ${defaultAddress?.city} ${defaultAddress?.line1} ${defaultAddress?.line2}`
            : null;
        city = defaultAddress?.city;
      }

      try {
        setSubmitBlocked(true);
        await createAncillaryInitialTransaction({
          request: {
            ancillaryType: selectedAncillary?.ancillary
              ? selectedAncillary?.ancillary.toLocaleUpperCase()
              : routerState.ancillaryType.toUpperCase(),
            typeUniqueId: data.typeUniqueId,
            date,
            groupName: data.groupName,
            ownerId: leId,
            keeperId: leId,
            countryOfImportUniqueId: data.countryOfImportUniqueId,
            importer: data.importer,
            countryOfExportUniqueId: '',
            latitude:
              isTransactionIllegal && routerState.coords
                ? routerState.coords[0]
                : defaultAddress?.latitude,
            longitude:
              isTransactionIllegal && routerState.coords
                ? routerState.coords[1]
                : defaultAddress?.longitude,
            address,
            city,
            ancillaries,
          },
          ...(data.attachments?.filter((attachment: any) => attachment.file !== null)?.length
            ? { attachments: data.attachments }
            : {}),
        }).then(() => {
          setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
            text: t('addAncillary.transactionCreatedSuccessfully.message'),
          });
          navigate(
            `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=ancillaries`,
          );
        });
      } catch (e: any) {
        setSubmitBlocked(false);
        setCreateInitialTransactionError(e.response.data?.message);
      }
      return true;
    })();
  };

  const removeFirearmButtonProps = {
    ...(isDesktop ? { icon: EIconTypes.delete } : { label: 'Delete' }),
    ...(isDesktop ? {} : { size: EButtonSizes.small }),
    ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
    ...(isDesktop
      ? { variant: EButtonVariants.outlinedIconTertiary }
      : { variant: EButtonVariants.outlined }),
    fullWidth: !isDesktop,
  };

  const validateFile = (file: File, index: number) => {
    const fileType: any = file.type;
    if (file) {
      if (file.size === 0) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.emptyFile.text'),
        });
        return false;
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileSizeValidation.text'),
        });
        return false;
      }
      if (
        ![
          EMIMETypes.JPG,
          EMIMETypes.JPEG,
          EMIMETypes.PNG,
          EMIMETypes.PDF,
          EMIMETypes.XLSX,
        ].includes(fileType)
      ) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.fileTypeValidation.text'),
        });
        return false;
      }
    }
    clearErrors(`attachments[${index}].file`);
    return true;
  };

  const handleUploadFile = (file: File, index: number) => {
    if (validateFile(file, index)) {
      attachments.update(index, {
        file,
      });
    }
  };

  const renderFirearmDetailsActionButtons = (isTemplateFooter: boolean) => (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: isTemplateFooter ? 'flex-end' : 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        pt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
        mt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
      }}
    >
      {!isDesktop && !isTemplateFooter && (
        <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {`${t('actions.general.label')}: `}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={6}
        spacing={2}
        sx={{
          display: {
            xs: isTemplateFooter ? 'none' : 'flex',
            lg: isTemplateFooter ? 'flex' : 'none',
          },
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Grid item xs={isTemplateFooter ? 'auto' : 12} sm={isTemplateFooter ? 'auto' : 6}>
          <Button
            {...removeFirearmButtonProps}
            onClick={() => {
              setSelectedAncillary(null);
              window.history.replaceState({}, document.title);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRemoveAttachmentButton = (index: number) => (
    <Grid item xs={1} sx={!isDesktop ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
      <Button
        variant={EButtonVariants.outlined}
        icon={EIconTypes.delete}
        onClick={() => attachments.remove(index)}
        sizePX={EButtonSizesPX.px32}
        sx={{ ...(isDesktop ? { marginLeft: '20px' } : {}), marginTop: '28px' }}
      />
    </Grid>
  );

  const importMapper = (
    transactionType: ETransactionTypes,
    ancillaryType: EArtAncillaryTypeName,
    artAccessoryTypeId: EArtAccessoryType,
    artAmmunitionTypeId: EArtAmmunitionType,
    artComponentTypeUniqueId: EArtComponentType,
    customFields: ICustomFieldByObjectType[],
  ) => {
    const getCustomFields = (index: number, customFieldItemRow: string[]) => {
      const res: any = {};
      customFields.map((field) => {
        if (field.customFieldType === ECustomFieldType.BOOL) {
          if (
            (customFieldItemRow &&
              customFieldItemRow[index] &&
              customFieldItemRow[index].toLowerCase().trim() === 'true') ||
            customFieldItemRow[index].toLowerCase().trim() === 'yes' ||
            customFieldItemRow[index].toLowerCase().trim() ===
              t('general.yes.label').toLowerCase()
          ) {
            res[field.name] = '1';
          } else if (
            (customFieldItemRow &&
              customFieldItemRow[index] &&
              customFieldItemRow[index].toLowerCase().trim() === 'false') ||
            customFieldItemRow[index].toLowerCase().trim() === 'no' ||
            customFieldItemRow[index].toLowerCase().trim() ===
              t('general.no.label').toLowerCase()
          ) {
            res[field.name] = '0';
          } else {
            res[field.name] = undefined;
          }
        } else if (field.customFieldType === ECustomFieldType.DATE) {
          res[field.name] =
            convertSimpleDate(customFieldItemRow?.[index], shortDateFormat) ?? '';
        } else {
          res[field.name] = customFieldItemRow?.[index] ?? '';
        }
        index++;
        return false;
      });
      return res;
    };

    if (ancillaryType === EArtAncillaryTypeName.ACCESSORY) {
      if (
        artAccessoryTypeId === EArtAccessoryType.MAGAZINES ||
        artAccessoryTypeId === EArtAccessoryType.LASER_LIGHT_MODULES ||
        artAccessoryTypeId === EArtAccessoryType.OPTICAL_SIGHTS
      ) {
        switch (transactionType) {
          case ETransactionTypes.DECLARED_IMPORTED:
          case ETransactionTypes.DECLARED_UNKNOWN_ORIGIN:
            return (itemRow: string[]) => ({
              externalReferenceNumber: itemRow?.[0] ?? '',
              manufacturedAt: convertSimpleDate(itemRow?.[1], shortDateFormat) ?? '',
              ...getCustomFields(2, itemRow),
            });
          case ETransactionTypes.DECLARED_MANUFACTURED:
            return (itemRow: string[]) => ({
              externalReferenceNumber: itemRow?.[0] ?? '',
              ...getCustomFields(1, itemRow),
            });
          case ETransactionTypes.RECOVERED:
          case ETransactionTypes.SEIZED:
          case ETransactionTypes.SURRENDERED:
            return (itemRow: string[]) => ({
              exhibitNumber: itemRow?.[0] ?? '',
              externalReferenceNumber: itemRow?.[1] ?? '',
              ...getCustomFields(2, itemRow),
            });
          default:
            return null;
        }
      }
      if (
        artAccessoryTypeId === EArtAccessoryType.FLASH_ELIMINATORS ||
        artAccessoryTypeId === EArtAccessoryType.SUPPRESSORS
      ) {
        switch (transactionType) {
          case ETransactionTypes.DECLARED_IMPORTED:
          case ETransactionTypes.DECLARED_UNKNOWN_ORIGIN:
            return (itemRow: string[]) => ({
              serialNumber: itemRow?.[0] ?? '',
              externalReferenceNumber: itemRow?.[1] ?? '',
              manufacturedAt: convertSimpleDate(itemRow?.[2], shortDateFormat) ?? '',
              ...getCustomFields(3, itemRow),
            });
          case ETransactionTypes.DECLARED_MANUFACTURED:
            return (itemRow: string[]) => ({
              serialNumber: itemRow?.[0] ?? '',
              externalReferenceNumber: itemRow?.[1] ?? '',
              ...getCustomFields(2, itemRow),
            });
          case ETransactionTypes.RECOVERED:
          case ETransactionTypes.SEIZED:
          case ETransactionTypes.SURRENDERED:
            return (itemRow: string[]) => ({
              serialNumber: itemRow?.[0] ?? '',
              exhibitNumber: itemRow?.[1] ?? '',
              externalReferenceNumber: itemRow?.[2] ?? '',
              ...getCustomFields(3, itemRow),
            });
          default:
            return null;
        }
      }
    }
    if (ancillaryType === EArtAncillaryTypeName.AMMUNITION) {
      if (
        artAmmunitionTypeId === EArtAmmunitionType.AIR_RIFLE ||
        artAmmunitionTypeId === EArtAmmunitionType.GRENADE_LAUNCHER ||
        artAmmunitionTypeId === EArtAmmunitionType.HANDGUN_RIFLE ||
        artAmmunitionTypeId === EArtAmmunitionType.MISSILES ||
        artAmmunitionTypeId === EArtAmmunitionType.MORTAR_SHELL ||
        artAmmunitionTypeId === EArtAmmunitionType.RECOILLESS_ARTILLERY_SHELL ||
        artAmmunitionTypeId === EArtAmmunitionType.ROCKET_PROPELLED ||
        artAmmunitionTypeId === EArtAmmunitionType.SHOTGUN
      ) {
        switch (transactionType) {
          case ETransactionTypes.DECLARED_LOT_BATCH_IMPORTED:
          case ETransactionTypes.DECLARED_LOT_BATCH_UNKNOWN_ORIGIN:
            return (itemRow: string[]) => ({
              lotNumber: itemRow?.[0] ?? '',
              batchNumber: itemRow?.[1] ?? '',
              externalReferenceNumber: itemRow?.[2] ?? '',
              manufacturedAt: convertSimpleDate(itemRow?.[3], shortDateFormat) ?? '',
              ammunitionExpiryDate: convertSimpleDate(itemRow?.[4], shortDateFormat) ?? '',
              ...getCustomFields(5, itemRow),
            });
          case ETransactionTypes.DECLARED_LOT_BATCH_MANUFACTURED:
            return (itemRow: string[]) => ({
              lotNumber: itemRow?.[0] ?? '',
              batchNumber: itemRow?.[1] ?? '',
              externalReferenceNumber: itemRow?.[2] ?? '',
              ammunitionExpiryDate: convertSimpleDate(itemRow?.[3], shortDateFormat) ?? '',
              ...getCustomFields(4, itemRow),
            });
          case ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN:
            return (itemRow: string[]) => ({
              lotNumber: itemRow?.[0] ?? '',
              batchNumber: itemRow?.[1] ?? '',
              assetId: itemRow?.[2] ?? '',
              externalReferenceNumber: itemRow?.[3] ?? '',
              manufacturedAt: convertSimpleDate(itemRow?.[4], shortDateFormat) ?? '',
              ammunitionExpiryDate: convertSimpleDate(itemRow?.[5], shortDateFormat) ?? '',
              ...getCustomFields(6, itemRow),
            });
          case ETransactionTypes.RECOVERED:
          case ETransactionTypes.SEIZED:
          case ETransactionTypes.SURRENDERED:
            return (itemRow: string[]) => ({
              lotNumber: itemRow?.[0] ?? '',
              batchNumber: itemRow?.[1] ?? '',
              exhibitNumber: itemRow?.[2] ?? '',
              externalReferenceNumber: itemRow?.[3] ?? '',
              ...getCustomFields(4, itemRow),
            });
          default:
            return null;
        }
      }
    }
    if (ancillaryType === EArtAncillaryTypeName.COMPONENTS) {
      if (
        artComponentTypeUniqueId === EArtComponentType.BARREL ||
        artComponentTypeUniqueId === EArtComponentType.BOLT ||
        artComponentTypeUniqueId === EArtComponentType.FRAME ||
        artComponentTypeUniqueId === EArtComponentType.LOWER_RECEIVER ||
        artComponentTypeUniqueId === EArtComponentType.UPPER_RECEIVER ||
        artComponentTypeUniqueId === EArtComponentType.SLIDE ||
        artComponentTypeUniqueId === EArtComponentType.CYLINDER
      ) {
        switch (transactionType) {
          case ETransactionTypes.DECLARED_DISASSEMBLED:
          case ETransactionTypes.DECLARED_IMPORTED:
          case ETransactionTypes.DECLARED_UNKNOWN_ORIGIN:
            return (itemRow: string[]) => ({
              serialNumber: itemRow?.[0] ?? '',
              externalReferenceNumber: itemRow?.[1] ?? '',
              manufacturedAt: convertSimpleDate(itemRow?.[2], shortDateFormat) ?? '',
              ...getCustomFields(3, itemRow),
            });
          case ETransactionTypes.DECLARED_MANUFACTURED:
            return (itemRow: string[]) => ({
              serialNumber: itemRow?.[0] ?? '',
              externalReferenceNumber: itemRow?.[1] ?? '',
              ...getCustomFields(2, itemRow),
            });
          case ETransactionTypes.RECOVERED:
          case ETransactionTypes.SEIZED:
          case ETransactionTypes.SURRENDERED:
            return (itemRow: string[]) => ({
              serialNumber: itemRow?.[0] ?? '',
              exhibitNumber: itemRow?.[1] ?? '',
              externalReferenceNumber: itemRow?.[2] ?? '',
              ...getCustomFields(3, itemRow),
            });
          default:
            return null;
        }
      }
    }
    return null;
  };

  const mapAvailableTransactionType = (dictionaries: IDictionary[] | null) => {
    if (dictionaries) {
      const transactionTypes = dictionaries.filter((item) => {
        if (item) {
          return type === ELegalEntityTypes.individual
            ? individualTransactionTypes.includes(
                item.uniqueId.toString() as ETransactionTypes,
              )
            : organizationTransactionTypes.includes(
                item.uniqueId.toString() as ETransactionTypes,
              );
        }
        return false;
      });

      transactionTypes.sort((a, b) => a.name.localeCompare(b.name));

      return mapDictionaryToOptionProp(transactionTypes);
    }
    return [];
  };

  const handleExhibitNumberChange = () => {
    setIsExhibitNumberChecked((prevState) => !prevState);
    setValue('serialNumber', '');
  };

  const handleIsLotBatchNumberPresentChange = () => {
    setIsLotBatchNumberNotPresent((prevState) => !prevState);
    setValue('lotNumber', '');
    setValue('batchNumber', '');
  };

  const handleGenerateAncillariesListTemplate = async () => {
    const data = getValues();
    const getCustomFieldsData = (ancillary: any) => {
      const customFieldsPayload: ICustomFieldForm[] = [];
      Object.keys(ancillary).map((key) => {
        const customField = customFieldsAncillaries.find((item) => item.name === key);
        if (customField && ancillary[key]) {
          const customFieldItem: ICustomFieldForm = {
            customFieldUniqueId: customField.uniqueId,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (ancillary[key] === undefined || ancillary[key] === null) {
              customFieldItem.text = undefined;
            } else if (ancillary[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = ancillary[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool =
              ancillary[key] === '1' ? t('general.yes.label') : t('general.no.label');
          } else if (customField.customFieldType === ECustomFieldType.DATE) {
            customFieldItem.date = convertToSimpleDate(ancillary[key], shortDateFormat);
          }
          customFieldsPayload.push(customFieldItem);
        }
        return false;
      });
      return customFieldsPayload?.length ? { customFields: customFieldsPayload } : {};
    };

    if (transactionTypeValue === '') return false;
    const body = {
      typeUniqueId: data.typeUniqueId,
      ancillaryType: selectedAncillary?.ancillary
        ? selectedAncillary?.ancillary.toLocaleUpperCase()
        : routerState.ancillaryType.toUpperCase(),
      accessoryTypeUniqueId: selectedAncillary?.accessoryTypeUniqueId,
      values: data.ancillaryList.map((item: any) => ({
        ...getCustomFieldsData(item),
        serialNumber: !item.isExhibitNumberChecked ? item.serialNumber : null,
        assetId: item.assetId || null,
        lotNumber: item.lotNumber || null,
        batchNumber: item.batchNumber || null,
        exhibitNumber: item.exhibitNumber || null,
        ...(isManufacturedDateEnabled && item.manufacturedAt && item.manufacturedAt !== ''
          ? {
              manufacturedAt: item.manufacturedAt
                ? convertToSimpleDate(item.manufacturedAt, shortDateFormat)
                : null,
            }
          : {}),
        ...(isAmmunitionExpiryDateEnabled &&
        item.ammunitionExpiryDate &&
        item.ammunitionExpiryDate !== ''
          ? {
              ammunitionExpiryDate: item.ammunitionExpiryDate
                ? convertToSimpleDate(item.ammunitionExpiryDate, shortDateFormat)
                : null,
            }
          : {}),
        ...(item.externalReferenceNumber
          ? { externalReferenceNumber: item.externalReferenceNumber }
          : {}),
      })),
    };

    try {
      await generateAncillariesListTemplate(body);
    } catch (e: any) {
      setSubmitBlocked(false);
      console.error(e);
    }
    return true;
  };

  const importItem = (itemRow: string[]) => {
    const mapper = importMapper(
      transactionTypeValue,
      selectedAncillary?.ancillary,
      selectedAncillary?.accessoryTypeUniqueId,
      selectedAncillary?.ammunitionTypeUniqueId,
      selectedAncillary?.componentTypeUniqueId,
      customFieldsAncillaries,
    );

    return mapper ? append(mapper(itemRow)) : null;
  };

  return (
    <div ref={elRef}>
      <Breadcrumbs items={breadcrumbs.current} />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">{t('addAncillary.addAncillary.header')}</StyledPageTitle>
      </Box>

      {!selectedAncillary && (
        <Box sx={{ mb: 2 }}>
          <Alert
            text={t('addAncillary.pleaseSelectAncillary.text')}
            variant={EAlertVariants.warning}
          />
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <FormSelect
            options={mapAvailableTransactionType(transactionInitialTypes)}
            name="typeUniqueId"
            setValue={setValue}
            label={`${t('addAncillary.transactionType.label')}*`}
            onSelect={clearUserInput}
            control={control}
            errors={errors}
            disabled={!selectedAncillary}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <FormDateTimePicker
            name="transactionDate"
            label={`${t('addFirearm.transactionDate.label')}*`}
            control={control}
            errors={errors}
            currentHour={true}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Accordion title={t('addAncillary.ownerAndKeeper.section.name')} alwaysExpanded>
            <Box mb={5}>
              {type === ELegalEntityTypes.individual && (
                <div>
                  {isLegalEntityLoading ? (
                    <Loader isLoading={isLegalEntityLoading} />
                  ) : (
                    legalEntity && (
                      <StyledDetailsWrapper container rowGap={3}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.ownerAndKeeper.section.owner.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder([legalEntity.firstName, legalEntity.lastName])}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.ownerAndKeeper.section.keeper.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder([legalEntity.firstName, legalEntity.lastName])}
                          </Typography>
                        </Grid>
                      </StyledDetailsWrapper>
                    )
                  )}
                </div>
              )}
              {type === ELegalEntityTypes.organisation && (
                <div>
                  {isOrgLegalEntityLoading ? (
                    <Loader isLoading={isOrgLegalEntityLoading} />
                  ) : (
                    orgLegalEntity && (
                      <StyledDetailsWrapper container rowGap={3}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.ownerAndKeeper.section.owner.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(orgLegalEntity.organizationName)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.ownerAndKeeper.section.keeper.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(orgLegalEntity.organizationName)}
                          </Typography>
                        </Grid>
                      </StyledDetailsWrapper>
                    )
                  )}
                </div>
              )}
            </Box>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <Accordion title={t('addAncillary.ancillaryDetails.section.name')} alwaysExpanded>
            <Grid item xs={12} lg={3} mb={2}>
              <Button
                fullWidth
                label={t('addAncillary.selectAncillary.button')}
                size={EButtonSizes.small}
                variant={EButtonVariants.outlined}
                onClick={() => {
                  clearUserInput();
                  navigate(
                    `${ERouteLinks.SearchForARTAncillary.replace(
                      ':id',
                      id as string,
                    )}?type=${type}&expand=ancillaries`,
                    { state: { ...routerState, ...getValues() } },
                  );
                }}
              />
            </Grid>
            {selectedAncillary && (
              <>
                <Grid
                  container
                  sx={{
                    border: `1px solid ${colors.primary25}`,
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: colors.primary25,
                    }}
                    px={2}
                    py={1}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        {selectedAncillary.type ||
                          selectedAncillary.componentType ||
                          selectedAncillary.ammunitionType ||
                          selectedAncillary.accessoryType}
                      </Typography>
                    </Grid>
                    {renderFirearmDetailsActionButtons(true)}
                  </Grid>
                  <Grid item container p={2} rowSpacing={2}>
                    <Grid item xs={12} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addAncillary.details.section.ancillary.label')}
                      </Typography>
                      <Typography variant="body2">
                        {selectedAncillary?.ancillary || routerState?.ancillaryType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addAncillary.details.section.type.label')}
                      </Typography>
                      <Typography variant="body2">
                        {selectedAncillary?.type ||
                          selectedAncillary?.componentType ||
                          selectedAncillary?.accessoryType ||
                          selectedAncillary?.ammunitionType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addAncillary.details.section.artId.label')}
                      </Typography>
                      <Typography variant="body2">{selectedAncillary?.artId}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        href={`${ERouteLinks.ViewAncillaries.replace(
                          ':type',
                          selectedAncillary?.ancillary?.toLowerCase(),
                        ).replace(':id', selectedAncillary?.id)}`}
                        underline="none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          fullWidth
                          label={t('addAncillary.details.section.showARTInformation.button')}
                          variant={EButtonVariants.contained}
                          size={EButtonSizes.small}
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addAncillary.details.section.componentManufacturer.label')}
                      </Typography>
                      <Typography variant="body2">
                        {selectedAncillary?.manufacturer}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addAncillary.details.section.calibre.label')}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(selectedAncillary?.calibre)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addAncillary.details.section.composition.label')}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(selectedAncillary?.composition)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t('addAncillary.details.section.proofHouse.label')}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(selectedAncillary?.proofHouse)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {(selectedAncillary.ancillary === EArtAncillaryTypeName.COMPONENTS ||
                    routerState?.ancillaryType === EArtAncillaryTypeName.COMPONENTS) && (
                    <>
                      <StyledLine item xs={12} />
                      <Grid item container p={2} rowSpacing={2}>
                        <Grid item xs={12} container direction="column">
                          <StyledTitleFirearmInformation variant="subtitle1">
                            {t('addAncillary.details.section.firearmInformation.label')}
                          </StyledTitleFirearmInformation>
                        </Grid>
                        <Grid item xs={12} lg={3} container direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.details.section.firearmType.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(selectedAncillary?.frtType)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} container direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.details.section.firearmManufacturer.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(selectedAncillary?.frtManufacturer)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} container direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.details.section.make.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(selectedAncillary?.frtMake)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} lg={3} container direction="column">
                          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                            {t('addAncillary.details.section.model.label')}
                          </Typography>
                          <Typography variant="body2">
                            {emptyFieldHolder(selectedAncillary?.frtModel)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} container mt={2}>
                  <Grid item xs={12}>
                    <FormRadioGroup
                      name="firearmsQuantity"
                      label={t('addAncillary.details.section.howMany.text')}
                      options={quantityOptions}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  {firearmsQuantityValue === 'one' ? (
                    <Grid item xs={12} container columnSpacing={2}>
                      {isLotBatchEnabled && (
                        <>
                          {isTransactionIllegal && (
                            <Grid
                              item
                              xs={12}
                              lg={3}
                              sx={{ display: 'flex', alignItems: 'center' }}
                            >
                              <FormCheckbox
                                name="isLotBatchNumberNotPresent"
                                label={t('addAncillary.validation.lotBatchNotPresent')}
                                control={control}
                                errors={errors}
                                onChange={handleIsLotBatchNumberPresentChange}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} lg={3}>
                            <FormInput
                              name="lotNumber"
                              label={`${t('addAncillary.list.section.lotNumber.label')}`}
                              setValue={setValue}
                              control={control}
                              charsLimit={10}
                              errors={errors}
                              disabled={isLotBatchNumberNotPresent}
                            />
                          </Grid>
                          <Grid item xs={12} lg={3}>
                            <FormInput
                              name="batchNumber"
                              label={`${t('addAncillary.list.section.batchNumber.label')}`}
                              setValue={setValue}
                              control={control}
                              charsLimit={10}
                              errors={errors}
                              disabled={isLotBatchNumberNotPresent}
                            />
                          </Grid>
                        </>
                      )}
                      {isExhibitNumberEnabled && serialNumberEnabled && (
                        <Grid
                          item
                          xs={12}
                          lg={3}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <FormCheckbox
                            name="isExhibitNumberChecked"
                            label={t('addAncillary.validation.noSerialNumberPresent')}
                            control={control}
                            errors={errors}
                            onChange={handleExhibitNumberChange}
                          />
                        </Grid>
                      )}
                      {serialNumberEnabled && (
                        <Grid item xs={12} lg={3}>
                          <FormInput
                            name="serialNumber"
                            label={`${t('addAncillary.list.section.serialNumber.label')}${
                              (isTransactionIllegal &&
                                !isExhibitNumberChecked &&
                                isAccessorySelected) ||
                              (isAccessorySelected &&
                                serialNumberEnabled &&
                                !isTransactionIllegal) ||
                              (isComponentSelected && !isExhibitNumberChecked)
                                ? '*'
                                : ''
                            }`}
                            setValue={setValue}
                            control={control}
                            errors={errors}
                            charsLimit={10}
                            disabled={isExhibitNumberChecked}
                          />
                        </Grid>
                      )}
                      {(isAccessorySelected &&
                        !TRANSACTION_INITIAL_ILLEGAL.includes(
                          transactionTypeValue as ETransactionTypes,
                        )) ||
                        (transactionTypeValue ===
                          ETransactionTypes.DECLARED_PIECEMEAL_UNKNOWN_ORIGIN && (
                          <Grid item xs={12} lg={3}>
                            <FormInput
                              name="assetId"
                              label={t('addAncillary.list.section.assetId.label')}
                              setValue={setValue}
                              control={control}
                              charsLimit={50}
                              errors={errors}
                            />
                          </Grid>
                        ))}
                      {isExhibitNumberEnabled && (
                        <Grid item xs={12} lg={3}>
                          <FormInput
                            name="exhibitNumber"
                            label={`${t('addAncillary.list.section.exhibit.label')}${
                              (isTransactionIllegal &&
                                isExhibitNumberChecked &&
                                isAccessorySelected) ||
                              (isTransactionIllegal &&
                                isLotBatchEnabled &&
                                isLotBatchNumberNotPresent) ||
                              (isTransactionIllegal &&
                                isAccessorySelected &&
                                !serialNumberEnabled) ||
                              (isTransactionIllegal &&
                                isExhibitNumberChecked &&
                                isComponentSelected)
                                ? '*'
                                : ''
                            }`}
                            setValue={setValue}
                            control={control}
                            charsLimit={10}
                            errors={errors}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} lg={3}>
                        <FormInput
                          key="externalReferenceNumber"
                          name="externalReferenceNumber"
                          label={t('addAncillary.list.section.externalReferenceNo.label')}
                          setValue={setValue}
                          control={control}
                          errors={errors}
                          charsLimit={20}
                        />
                      </Grid>
                      {isManufacturedDateEnabled && (
                        <Grid item xs={12} sm={6} lg={3}>
                          <FormDatePicker
                            name="manufacturedAt"
                            label={`${t('addAncillary.list.section.manufacturedDate.label')}${
                              TRANSACTION_INITIAL_TYPES_FOR_MANUFACTURED_DATE_FIELD_REQUIRED.includes(
                                transactionTypeValue,
                              )
                                ? '*'
                                : ''
                            }`}
                            control={control}
                            disabled={ANCILLARY_TRANSACTIONS_WITH_AUTOCOMPLETED_MANUFACTURED_AT.includes(
                              transactionTypeValue,
                            )}
                            errors={errors}
                          />
                        </Grid>
                      )}
                      {isAmmunitionExpiryDateEnabled && (
                        <Grid item xs={12} sm={6} lg={3}>
                          <FormDatePicker
                            name="ammunitionExpiryDate"
                            label={`${t(
                              'addAncillary.list.section.ammunitionExpiryDate.label',
                            )}`}
                            control={control}
                            errors={errors}
                          />
                        </Grid>
                      )}
                      {customFieldsAncillaries.map((customField) => (
                        <Grid item xs={12} sm={6} lg={3} key={customField.uniqueId}>
                          <FormCustomField
                            data={customField}
                            control={control}
                            setValue={setValue}
                            errors={errors}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid item xs={12} container columnSpacing={2}>
                      <Grid item xs={12} lg={6}>
                        <FormRadioGroup
                          name="isCreateGroup"
                          label={t('addAncillary.details.section.toCreateAGroup.text')}
                          options={createGroupOptions}
                          control={control}
                          errors={errors}
                        />
                      </Grid>
                      {isShowGroupNameFormElement && (
                        <Grid item xs={12} lg={6}>
                          <FormInput
                            key="groupName"
                            name="groupName"
                            label={`${t('addAncillary.details.section.groupName.label')}*`}
                            setValue={setValue}
                            control={control}
                            charsLimit={255}
                            errors={errors}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Accordion>
        </Grid>
        {isShowList && (
          <Grid item xs={12}>
            <Accordion title={t('addAncillary.list.section.name')} alwaysExpanded>
              <Grid container mb={5}>
                <Grid
                  item
                  xs={12}
                  container
                  gap={3}
                  mb={2}
                  sx={{ flexWrap: { lg: 'nowrap' } }}
                >
                  <StyledFirearmListBox item xs={12} lg={6}>
                    <Typography variant="body1" mb={3}>
                      {t('addAncillary.list.section.downloadInfo.text')}
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      alignSelf="flex-end"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Button
                        onClick={handleGenerateAncillariesListTemplate}
                        label={t('addAncillary.list.section.downloadTemplate.button')}
                        icon={EIconTypes.download}
                        variant={EButtonVariants.outlined}
                        size={EButtonSizes.small}
                        fullWidth={!isDesktop}
                        disabled={transactionTypeValue === ''}
                      />
                    </Grid>
                  </StyledFirearmListBox>

                  <StyledFirearmListBox item xs={12} lg={6}>
                    <Typography variant="body1" mb={3}>
                      {t('addAncillary.list.section.importInfo.text')}
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      alignSelf="flex-end"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <Button
                        onClick={() => {
                          setShowImportDialog(true);
                        }}
                        label={t('addAncillary.list.section.importAncillary.button')}
                        icon={EIconTypes.download}
                        variant={EButtonVariants.outlined}
                        size={EButtonSizes.small}
                        fullWidth={!isDesktop}
                        disabled={transactionTypeValue === ''}
                      />
                    </Grid>
                  </StyledFirearmListBox>
                  <ImportAncillaries
                    open={showImportDialog}
                    handleClose={() => setShowImportDialog(false)}
                    changeAncillariesListValue={(ancillaryList) => {
                      remove();
                      setIsDataImportedFromFile(true);
                      ancillaryList.forEach((ancillaryListRow: any, index: number) => {
                        if (index !== 0) {
                          importItem(ancillaryListRow);
                        }
                      });
                    }}
                  />
                </Grid>
                {!!fields.length && (
                  <Grid item xs={12} container>
                    <Grid item xs={12} mb={2}>
                      <Typography variant="subtitle1" display="flex" alignItems="center">
                        {`${t('addAncillary.list.section.totalRowsCount.text')}:`}
                        <Typography variant="body1" ml={1}>
                          {fields.length}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {fields.map((field, index) => (
                        <AddAncillaryListItem
                          key={field.id}
                          index={index}
                          arrayName="ancillaryList"
                          removeItem={() => remove(index)}
                          fields={fields}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          isAccessorySelected={isAccessorySelected}
                          serialNumberEnabled={serialNumberEnabled}
                          isLotBatchEnabled={isLotBatchEnabled}
                          transactionTypeValue={transactionTypeValue}
                          getValues={getValues}
                          customFields={customFieldsAncillaries}
                          isComponentSelected
                          isDataImportedFromFile={isDataImportedFromFile}
                          setIsDataImportedFromFile={setIsDataImportedFromFile}
                          isReloadCausedLocationSelect={isReloadCausedLocationSelect}
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} lg={3}>
                  <Button
                    onClick={() => {
                      append({
                        serialNumber: '',
                        assetId: '',
                        exhibitNumber: '',
                        externalReferenceNumber: '',
                        manufacturedAt: '',
                      });
                    }}
                    fullWidth
                    label={t('addAncillary.list.addRow.button')}
                    icon={EIconTypes.plus}
                    size={EButtonSizes.small}
                  />
                </Grid>
              </Grid>
            </Accordion>
          </Grid>
        )}
        {isShowLocation && (
          <Grid item xs={12}>
            <Accordion title={t('addAncillary.location.section.name')} alwaysExpanded>
              <Grid
                container
                mb={4}
                lg={12}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                columnGap={3}
              >
                <Grid sx={{ flexBasis: '40%' }}>
                  <FormInput
                    name="address"
                    label={`${t('addAncillary.location.section.address.text')}*`}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    placeholder={routerState?.address || ''}
                    InputProps={{ readOnly: true }}
                    disabled
                    sx={{ minWidth: '272px' }}
                    helperText={t('addAncillary.location.section.address.helperText')}
                  />
                </Grid>
                {routerState?.coords && (
                  <Grid item>
                    <StyledLocationMarkConfirmationMarginBottom>
                      <Typography variant="body1" color={colors.success900}>
                        {t('addAncillary.location.section.locationMarked.text')}
                      </Typography>
                      <StyledIconWrapper>
                        <Icon type={EIconTypes.checkmark} color={colors.white} fontSize={14} />
                      </StyledIconWrapper>
                    </StyledLocationMarkConfirmationMarginBottom>
                  </Grid>
                )}
                <Grid item mb={4}>
                  <Button
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.SelectLocation.replace(
                          ':id',
                          id,
                        )}?type=${type}&expand=ancillaries`,
                        {
                          state: {
                            ...routerState,
                            ...getValues(),
                            breadcrumbs: [
                              ...breadcrumbs.current,
                              t(
                                'addAncillary.legalEntityDetails.transactions.searchFRT.breadcrumb',
                              ),
                            ],
                            leId: id,
                            type,
                            returnLink: `${ERouteLinks.LegalEntityAddAncillary.replace(
                              ':id',
                              id as string,
                            )}?type=${type}&expand=ancillaries`,
                          },
                        },
                      )
                    }
                    fullWidth={!isDesktop}
                    label={t('addAncillary.location.section.selectLocationOnMap.button')}
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    sx={{ marginTop: '28px' }}
                  />
                </Grid>
                {isErrorLocation && (
                  <Grid item xs={12}>
                    <FormErrorLabel
                      label={t(
                        'addAncillary.location.section.youMustSelectLocationOnMap.text',
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Accordion>
          </Grid>
        )}
        {isShowImporterDetails && (
          <Grid item xs={12}>
            <Accordion title={t('addAncillary.importerDetails.section.name')} alwaysExpanded>
              <Grid mb={2} container columnSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <FormInput
                    name="importer"
                    label={t('addAncillary.importerDetails.section.nameOfImporter.label')}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <FormSelect
                    options={mapDictionaryToOptionProp(country)}
                    name="countryOfImportUniqueId"
                    setValue={setValue}
                    label={`${t(
                      'addAncillary.importerDetails.section.countryOfImport.label',
                    )}*`}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </Accordion>
          </Grid>
        )}
        {isShowDocument && (
          <Grid item xs={12}>
            <Accordion title={`${t('addAncillary.document.section.name')}`} alwaysExpanded>
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <Box mb={2}>
                      <Alert
                        text={t('general.field.availableAttachmentFormats.text')}
                        variant={EAlertVariants.warning}
                      />
                    </Box>
                  </Grid>
                  {attachments?.fields?.map((attachment: any, index) => (
                    <Grid key={attachment.id} item xs={12} container mb={!isDesktop ? 2 : 0}>
                      <Grid item xs={attachments.fields?.length > 0 ? 11 : 12} lg={6}>
                        <FormInput
                          name={`attachments[${index}].attachmentName`}
                          label={t('addAncillary.document.section.attachmentName.label')}
                          control={control}
                          placeholder={attachment?.file?.name ?? ''}
                          setValue={setValue}
                          errors={errors}
                          arrayName="attachments"
                          arrayIndex={index}
                          fieldName="attachmentName"
                          InputProps={{ disabled: attachment?.file === null }}
                        />
                      </Grid>
                      {!isDesktop && renderRemoveAttachmentButton(index)}
                      <Grid
                        item
                        xs={12}
                        lg={3}
                        sx={{ marginTop: { lg: '28px' }, ml: { lg: 2 } }}
                      >
                        <Button
                          isUploadButton
                          onClick={(files) => handleUploadFile(files[0], index)}
                          fullWidth
                          label={t('addAncillary.document.section.browseFiles.button')}
                          id="add_file-button"
                          icon={EIconTypes.plus}
                          size={EButtonSizes.small}
                          isMultiple
                        />
                        {errors &&
                          errors.attachments &&
                          errors.attachments[index] &&
                          errors.attachments[index].file && (
                            <FormErrorLabel label={errors.attachments[index].file?.message} />
                          )}
                      </Grid>
                      {isDesktop && renderRemoveAttachmentButton(index)}
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={3} sx={{ mt: { xs: 2, lg: 0 } }}>
                    <Button
                      onClick={() =>
                        attachments.append({
                          attachmentName: '',
                          file: null,
                        })
                      }
                      fullWidth
                      label={t('addAncillary.document.section.addFile.button')}
                      id="add_file-button"
                      variant={EButtonVariants.outlined}
                      size={EButtonSizes.small}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Accordion>
          </Grid>
        )}
        <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
          <Grid item xs={12} lg={6} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={handleCreateInitialTransaction}
                fullWidth
                label={t('general.save.button')}
                id="save-button"
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                disabled={selectedAncillary === null || submitBlocked}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.ViewLegalEntity.replace(
                      ':id',
                      id,
                    )}?type=${type}&expand=ancillaries`,
                  )
                }
              />
            </Grid>
            <Grid xs={12} item>
              <Typography variant="caption">
                *{t('addAncillary.mandatoryField.label')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={4}>
          {createInitialTransactionError && (
            <Box mb={2}>
              <Alert text={createInitialTransactionError} variant={EAlertVariants.error} />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export { LegalEntityAddAncillaryPage };
