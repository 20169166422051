import React, { useEffect, useRef, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import { ILegalEntityData, LegalEntityDetails } from 'components/organisms/LegalEntityDetails';
import { Box, Typography } from '@mui/material';
import { Accordion } from 'components/molecules/Accordion';
import { FirearmSearchForm } from 'pages/LegalEntitiesPage/FirearmSearchForm';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import {
  IOrgLegalEntityData,
  OrgLegalEntityDetails,
} from 'components/organisms/OrgLegalEntityDetails';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';

import {
  legalEntityAddressTableColumns,
  legalEntityAncillariesTableColumns,
  legalEntityAncillaryGroupsTableColumns,
  legalEntityContactTableColumns,
  legalEntityFirearmsTableColumns,
  legalEntityGroupsTableColumns,
  mapLEAddressToDataSource,
  mapLEAncillaryGropsToDataSource,
  mapLEAncillaryToDataSource,
  mapLEContactToDataSource,
  mapLEFirearmToDataSource,
  mapLEGroupToDataSource,
} from 'pages/LegalEntitiesPage/helpers';

import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { LegalEntityAddressTableCard } from 'pages/LegalEntitiesPage/LegalEntityAddressTableCard';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { CommentAccordion } from 'components/molecules/CommentAccordion';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Loader } from 'components/atoms/Loader';
import { LegalEntityFirearmTableCard } from 'pages/LegalEntitiesPage/LegalEntityFirearmTableCard';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import { Table } from 'components/molecules/Table';

import {
  mapLegalityActivityMapColor,
  mapLegalityActivityMapHover,
  renderDataColor,
} from 'components/molecules/Table/helpers';
import { Pagination } from 'components/molecules/Pagination';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { parseDictionary } from 'helpers/dictionary';
import { useDictionary } from 'models/dictionaryContext';
import { CardList } from 'components/molecules/CardList';
import { colors } from 'theme/colors';
import { LegalEntityGroupTableCard } from 'pages/LegalEntitiesPage/LegalEntityGroupTableCard';
import { GroupSearchForm } from 'pages/LegalEntitiesPage/GroupSearchForm';
import {
  initialGroupSearchFormValues,
  ISearchFirearmFormValues,
  ISearchGroupFormValues,
  ISearchLegalEntityAncillariesFormValues,
  ISearchLegalEntityAncillaryGroupFormValues,
} from 'models/form';
import { useTranslations } from 'hooks/useTranslations';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { LegalEntityContactTableCard } from 'pages/LegalEntitiesPage/LegalEntityContactTableCard';
import { useLang } from 'models/langContext';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';
import { StyledButton } from 'pages/Firearms/ViewRegisteredFirearmPage/styles';
import { isStringEmpty } from 'utils/string';
import { LEAccordionNames } from 'components/organisms/LEAccordions';
import { LegalEntityAncillaryTableCard } from '../LegalEntityAncillaryTableCard';
import { AncillariesSearchForm } from '../AncillariesSearchForm';
import { AncillaryGroupsSearchForm } from '../AncillaryGroupsSearchForm';
import { LegalEntityAncillaryGroupTableCard } from '../LegalEntityAncillaryGroupTableCard';

export const getAncillaryType = (typeId: string) => {
  switch (typeId) {
    case EArtAncillaryType.ACCESSORIES:
      return 'accessory';
    case EArtAncillaryType.AMMUNITION:
      return 'ammunition';
    case EArtAncillaryType.COMPONENTS:
      return 'component';
    default:
      return '';
  }
};
const ViewLegalEntityPage = () => {
  const { id } = useParams<{ id: string }>();
  const { selectedLanguage } = useLang();
  const query = useQuery();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { region, country, phoneCode, addressType, getRegion, getCountry, getAddressType } =
    useDictionary();
  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [isLegalEntityLoading, setIsLegalEntityLoading] = useState<boolean>(false);
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const [isOrgLegalEntityLoading, setIsOrgLegalEntityLoading] = useState<boolean>(false);
  const [isLegalEntityCommentsLoading, setIsLegalEntityCommentsLoading] =
    useState<boolean>(true);
  const [isLegalEntityContactsLoading, setIsLegalEntityContactsLoading] =
    useState<boolean>(false);
  const [isLegalEntityAddressLoading, setIsLegalEntityAddressLoading] =
    useState<boolean>(false);
  const [currentPageLEComments, setCurrentPageLEComments] = useState<number>(1);
  const [perPageLEComments, setPerPageLEComments] = useState<EPerPages>(EPerPages.perPage25);
  const [isLegalEntityGroupLoading, setIsLegalEntityGroupLoading] = useState<boolean>(false);
  const [isLegalEntityAncillaryGroupLoading, setIsLegalEntityAncillaryGroupLoading] =
    useState<boolean>(true);
  const { legalEntityAlert, legalEntityOrgAlert, nextPageAlert } = useAlert();
  const {
    legalEntityFirearms,
    legalEntityAncillaries,
    legalEntityAncillaryGroups,
    paginatorAncillaries,
    paginatorAncillaryGroups,
    getLegalEntityFirearmsData,
    getLegalEntityAncillariesData,
    getLegalEntityAncillaryGroupsData,
    paginatorLEF,
    paginatorLEG,
    legalEntityGroups,
    getLegalEntityGroupsData,
    getLegalEntityAddressesData,
    legalEntityAddress,
    legalEntityContacts,
    getLegalEntityContactsData,
    getLegalEntityCommentsData,
    legalEntityComments,
    paginatorLEComments,
  } = useLegalEntities();
  const { isMobile, isDesktop } = useThemeBreakpoint();
  const { hasPermissions } = usePermission();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isShowHidden: false,
    },
  });

  const type: string | null = query.get('type');

  const [currentPageLEF, setCurrentPageLEF] = useState<number>(1);
  const [perPageLEF, setPerPageLEF] = useState<EPerPages>(EPerPages.perPage25);
  const [firearmQuery, setFirearmQuery] = useState<Partial<ISearchFirearmFormValues>>({});
  const [firearmSort, setFirearmSort] = useState<ISortOptions | null>(null);

  const [currentPageAncillaries, setCurrentPageAncillaries] = useState<number>(1);
  const [perPageAncillaries, setPerPageAncillaries] = useState<EPerPages>(EPerPages.perPage25);
  const [ancillariesQuery, setAncillariesQuery] = useState<
    Partial<ISearchLegalEntityAncillariesFormValues>
  >({});
  const [currentPageAncillaryGroups, setCurrentPageAncillaryGroups] = useState<number>(1);
  const [perPageAncillaryGroups, setPerPageAncillaryGroups] = useState<EPerPages>(
    EPerPages.perPage25,
  );
  const [ancillaryGroupsQuery, setAncillaryGroupsQuery] = useState<
    Partial<ISearchLegalEntityAncillaryGroupFormValues>
  >({});
  const [ancillariesSort, setAncillariesSort] = useState<ISortOptions | null>(null);
  const [ancillaryGroupsSort, setAncillaryGroupsSort] = useState<ISortOptions | null>(null);

  const [isFirearmPaginationLoading, setIsFirearmPaginationLoading] = useState(false);
  const [isFirearmGroupPaginationLoading, setIsFirearmGroupPaginationLoading] =
    useState(false);
  const [isAncillaryPaginationLoading, setIsAncillaryPaginationLoading] = useState(false);
  const [isAncillaryGroupPaginationLoading, setIsAncillaryGroupPaginationLoading] =
    useState(false);
  const [isCommentPaginationLoading, setIsCommentPaginationLoading] = useState(false);

  const [currentPageLEG, setCurrentPageLEG] = useState<number>(1);
  const [perPageLEG, setPerPageLEG] = useState<EPerPages>(EPerPages.perPage25);
  const [groupQuery, setGroupQuery] = useState<Partial<ISearchGroupFormValues>>({});
  const [groupSort, setGroupSort] = useState<ISortOptions | null>(null);
  const [addressSort, setAddressSort] = useState<ISortOptions | null>(null);
  const [contactSort, setContactSort] = useState<ISortOptions | null>(null);
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const [isFirearmSortLoaded, setIsFirearmSortLoaded] = useState<boolean>(true);
  const [isGroupSortLoaded, setIsGroupSortLoaded] = useState<boolean>(true);
  const [isAncillariesSortLoaded, setIsAncillariesSortLoaded] = useState<boolean>(true);
  const [isAncillaryGroupsSortLoaded, setIsAncillaryGroupsSortLoaded] =
    useState<boolean>(true);
  const [isCommentsPaginationVisible, setIsCommentsPaginationVisible] = useState(true);
  const [isContactSortLoaded, setIsContactSortLoaded] = useState(true);
  const [isAddressSortLoaded, setIsAddressSortLoaded] = useState(true);
  const [isFirearmQueryLoading, setIsFirearmQueryLoading] = useState<boolean>(false);
  const [isFirearmInitDataLoading, setIsFirearmInitDataLoading] = useState<boolean>(true);
  const [isGroupQueryLoading, setIsGroupQueryLoading] = useState<boolean>(false);
  const [isGroupQuerySearchButtonClicked, setIsGroupQuerySearchButtonClicked] =
    useState<boolean>(false);
  const [isAncillariesQueryLoading, setIsAncillariesQueryLoading] = useState<boolean>(false);
  const [isAncillariesDataLoading, setIsAncillariesDataLoading] = useState<boolean>(true);
  const [isAncillaryGroupsQueryLoading, setIsAncillaryGroupsQueryLoading] =
    useState<boolean>(false);
  const { t } = useTranslations();

  const previousFirearmSortRef = useRef(firearmSort);
  const scrollPosition = useRef(0);

  useEffect(() => {
    if (
      isAncillariesSortLoaded &&
      isAddressSortLoaded &&
      isFirearmSortLoaded &&
      isGroupSortLoaded &&
      isAncillaryGroupsSortLoaded &&
      isContactSortLoaded
    ) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [
    isAncillariesSortLoaded,
    isAddressSortLoaded,
    isFirearmSortLoaded,
    isGroupSortLoaded,
    isAncillaryGroupsSortLoaded,
    isContactSortLoaded,
  ]);

  useEffect(() => {
    (async function getLegalEntitiesFirearmsDataInit() {
      if (
        (!!legalEntity || !!orgLegalEntity) &&
        hasPermissions([EPermission.VIEW_FIREARMS_LE])
      ) {
        if (firearmSort !== previousFirearmSortRef.current) {
          setIsFirearmSortLoaded(false);
        }
        setIsFirearmPaginationLoading(true);
        await getLegalEntityFirearmsData(
          id as string,
          currentPageLEF,
          perPageLEF,
          { ...firearmQuery, legalUniqueId: id },
          firearmSort,
        );
        setIsFirearmInitDataLoading(false);
        setIsFirearmQueryLoading(false);
        setIsFirearmPaginationLoading(false);
        setIsFirearmSortLoaded(true);
        previousFirearmSortRef.current = firearmSort;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageLEF, perPageLEF, firearmQuery, firearmSort, legalEntity, orgLegalEntity]);

  const previousAncillariesSortRef = useRef(ancillariesSort);

  useEffect(() => {
    (async function getLegalEntityAncillariesDataInit() {
      if (
        (!!legalEntity || !!orgLegalEntity) &&
        hasPermissions([EPermission.VIEW_ANCILLARIES_LE])
      ) {
        if (ancillariesSort !== previousAncillariesSortRef.current) {
          setIsAncillariesSortLoaded(false);
        }
        setIsAncillaryPaginationLoading(true);
        await getLegalEntityAncillariesData(
          id as string,
          currentPageAncillaries,
          perPageAncillaries,
          ancillariesQuery,
          ancillariesSort,
        );
        setIsAncillariesDataLoading(false);
        setIsAncillariesQueryLoading(false);
        setIsAncillaryPaginationLoading(false);
        setIsAncillariesSortLoaded(true);
        previousAncillariesSortRef.current = ancillariesSort;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageAncillaries,
    perPageAncillaries,
    ancillariesQuery,
    ancillariesSort,
    legalEntity,
    orgLegalEntity,
  ]);

  const previousAncillariesGroupsSortRef = useRef(ancillaryGroupsSort);

  useEffect(() => {
    (async function getLegalEntityAncillaryGroupsDataInit() {
      if (
        (!!legalEntity || !!orgLegalEntity) &&
        hasPermissions([EPermission.VIEW_ANCILLARIES_LE, EPermission.MANAGE_GROUPS_LE], true)
      ) {
        if (ancillaryGroupsSort !== previousAncillariesGroupsSortRef.current) {
          setIsAncillaryGroupsSortLoaded(false);
        }
        setIsAncillaryGroupPaginationLoading(true);
        await getLegalEntityAncillaryGroupsData(
          id as string,
          currentPageAncillaryGroups,
          perPageAncillaryGroups,
          ancillaryGroupsQuery,
          ancillaryGroupsSort,
        );
      }
      setIsLegalEntityAncillaryGroupLoading(false);
      setIsAncillaryGroupsQueryLoading(false);
      setIsAncillaryGroupPaginationLoading(false);
      setIsAncillaryGroupsSortLoaded(true);
      previousAncillariesGroupsSortRef.current = ancillaryGroupsSort;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageAncillaryGroups,
    perPageAncillaryGroups,
    ancillaryGroupsQuery,
    ancillaryGroupsSort,
    legalEntity,
    orgLegalEntity,
  ]);

  const prepareQueryFromParams = (initialValues: any) => {
    const queryFromParams = { ...initialValues };
    let overrideGroupQuery = false;
    Object.getOwnPropertyNames(queryFromParams).forEach((key) => {
      const keyInParams = searchParams.get(key);
      if (keyInParams) {
        queryFromParams[key as keyof typeof queryFromParams] = keyInParams;
        overrideGroupQuery = true;
      }
    });
    if (overrideGroupQuery) {
      return queryFromParams;
    }
    return false;
  };

  const previousGroupSortRef = useRef(groupSort);

  useEffect(() => {
    (async function getLegalEntitiesGroupsDataInit() {
      if (legalEntity || orgLegalEntity) {
        if (
          Object.getOwnPropertyNames(groupQuery).length &&
          !isStringEmpty(groupQuery.firearmSerialNumber)
        ) {
          setSearchParams({ ...groupQuery, type: type || 'organisation', expand: 'groups' });
        } else {
          const groupQueryFromParams = prepareQueryFromParams(initialGroupSearchFormValues);
          if (!isGroupQuerySearchButtonClicked && groupQueryFromParams) {
            setGroupQuery(groupQueryFromParams);
          }
          setIsGroupQuerySearchButtonClicked(false);
        }
        if (hasPermissions([EPermission.MANAGE_GROUPS_LE])) {
          if (groupSort !== previousGroupSortRef.current) {
            setIsGroupSortLoaded(false);
          }
          setIsFirearmGroupPaginationLoading(true);
          if (!legalEntityGroups.length) {
            setIsLegalEntityGroupLoading(true);
          }
          await getLegalEntityGroupsData(
            id as string,
            currentPageLEG,
            perPageLEG,
            groupQuery,
            groupSort,
          );
          setIsGroupQueryLoading(false);
          setIsFirearmGroupPaginationLoading(false);
          setIsLegalEntityGroupLoading(false);
          setIsGroupSortLoaded(true);
          previousGroupSortRef.current = groupSort;
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageLEG, perPageLEG, groupQuery, groupSort, legalEntity, orgLegalEntity]);

  const isShowHidden: boolean = watch('isShowHidden');

  useEffect(() => {
    (async function getLegalEntitiesDataInit() {
      setIsCommentsPaginationVisible(false);
      if (
        (!!legalEntity || !!orgLegalEntity) &&
        id &&
        hasPermissions([EPermission.MANAGE_COMMENTS_LE])
      ) {
        setIsCommentPaginationLoading(true);
        await getLegalEntityCommentsData(id, {
          withHidden: isShowHidden,
          currentPage: currentPageLEComments,
          perPage: perPageLEComments,
        });

        setIsLegalEntityCommentsLoading(false);
        setIsCommentPaginationLoading(false);
        setIsCommentsPaginationVisible(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    isShowHidden,
    currentPageLEComments,
    perPageLEComments,
    legalEntity,
    orgLegalEntity,
  ]);

  useEffect(() => {
    if (type === ELegalEntityTypes.individual) {
      (async function getLegalEntityDataInit() {
        setIsLegalEntityLoading(true);
        if (id) {
          const response = await getLegalEntity(id);
          if (response) {
            setLegalEntity(response);
          }
          setIsLegalEntityLoading(false);
        }
      })();
    } else if (type === ELegalEntityTypes.organisation) {
      (async function getLegalEntityDataInit() {
        setIsOrgLegalEntityLoading(true);
        if (id) {
          const response = await getOrgLegalEntity(id);
          if (response) {
            setOrgLegalEntity(response);
          }
          setIsOrgLegalEntityLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const previousAddressSortRef = useRef(addressSort);

  useEffect(() => {
    (async function getLegalEntityAddressInit() {
      if ((!!legalEntity || !!orgLegalEntity) && id) {
        if (!legalEntityAddress.length) {
          setIsLegalEntityAddressLoading(true);
        }
        if (addressSort !== previousAddressSortRef.current) {
          setIsAddressSortLoaded(false);
        }
        await getLegalEntityAddressesData(id, addressSort);
        setIsLegalEntityAddressLoading(false);
        setIsAddressSortLoaded(true);
        previousAddressSortRef.current = addressSort;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, addressSort, legalEntity, orgLegalEntity]);

  const previousContactSortRef = useRef(contactSort);

  useEffect(() => {
    (async function getLegalEntityContactsInit() {
      if ((!!legalEntity || !!orgLegalEntity) && id) {
        if (!legalEntityContacts.length) {
          setIsLegalEntityContactsLoading(true);
        }
        if (contactSort !== previousContactSortRef.current) {
          setIsContactSortLoaded(false);
        }
        await getLegalEntityContactsData(id, contactSort);
        setIsLegalEntityContactsLoading(false);
        setIsContactSortLoaded(true);
        previousContactSortRef.current = contactSort;
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, contactSort, legalEntity, orgLegalEntity]);

  useEffect(() => {
    getRegion();
    getAddressType();
    getCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          t('legalEntityDetails.legalEntityDetails.text'),
        ]}
      />
      {type === ELegalEntityTypes.individual && (
        <div>
          {isLegalEntityLoading ? (
            <Loader isLoading={isLegalEntityLoading} />
          ) : (
            legalEntity && <LegalEntityDetails legalEntity={legalEntity} />
          )}
        </div>
      )}
      {type === ELegalEntityTypes.organisation && (
        <div>
          {isOrgLegalEntityLoading ? (
            <Loader isLoading={isOrgLegalEntityLoading} />
          ) : (
            orgLegalEntity && <OrgLegalEntityDetails legalEntity={orgLegalEntity} />
          )}
        </div>
      )}
      {orgLegalEntity && legalEntityOrgAlert && (
        <Box mt={2}>
          <Alert text={legalEntityOrgAlert.text} variant={legalEntityOrgAlert.variant} />
        </Box>
      )}
      {legalEntity && legalEntityAlert && (
        <Box mt={2}>
          <Alert text={legalEntityAlert.text} variant={legalEntityAlert.variant} />
        </Box>
      )}
      {nextPageAlert && (
        <Box mt={2}>
          <Alert text={nextPageAlert.text} variant={nextPageAlert.variant} />
        </Box>
      )}
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <StyledButton
          label={
            isAllExpanded
              ? t('transactionDetails.collapseAll.link')
              : t('transactionDetails.expandAll.link')
          }
          onClick={() => setIsAllExpanded((prev) => !prev)}
          variant={EButtonVariants.outlined}
          size={EButtonSizes.small}
        />
      </Grid>

      <Grid container width={!isDesktop ? 'calc(100vw - 48px)' : '100%'}>
        {!!legalEntity && hasPermissions([EPermission.VIEW_FIREARMS_LE]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('legalEntityDetails.firearm.accordion.label')}
              name="firearms"
              id="legal_entity_firearms"
              expanded={isAllExpanded}
            >
              <FirearmSearchForm
                onSubmit={(it) => {
                  setIsFirearmQueryLoading(true);
                  setFirearmQuery(it);
                }}
                isSearchButtonDisabled={isFirearmQueryLoading}
                isIndividualLE
              />
              {hasPermissions([EPermission.MANAGE_LE, EPermission.ADD_TRANSACTION]) && (
                <TableTopButtons>
                  <Button
                    label={t('legalEntityDetails.firearm.accordion.addFirearm.button')}
                    variant={EButtonVariants.contained}
                    id="legal_entity_individual-add_firearm_button"
                    icon={EIconTypes.plus}
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.LegalEntityAddFirearm.replace(
                          ':id',
                          id as string,
                        )}?type=${type}&expand=firearms`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                </TableTopButtons>
              )}
              {isFirearmQueryLoading || isFirearmInitDataLoading ? (
                <Loader isLoading={true} />
              ) : (
                <>
                  {isDesktop ? (
                    <Table
                      columns={legalEntityFirearmsTableColumns({
                        renderStateFieldColor: (data: ITableDataSource) =>
                          renderDataColor(data).stateField,
                        type,
                      })}
                      dataSource={mapLEFirearmToDataSource(legalEntityFirearms)}
                      onSort={(it) => {
                        setFirearmSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.firearm.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isFirearmSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEFirearmToDataSource(legalEntityFirearms)}
                      render={(data, index) => (
                        <LegalEntityFirearmTableCard
                          data={data}
                          key={index}
                          renderColor={renderDataColor}
                        />
                      )}
                    />
                  )}

                  {paginatorLEF && (
                    <Pagination
                      count={paginatorLEF?.totalElements}
                      perPage={perPageLEF}
                      onChangePage={(page) => {
                        setCurrentPageLEF(page);
                      }}
                      onChangePerPage={(value) => {
                        setPerPageLEF(value);
                      }}
                      current={currentPageLEF}
                      isLoading={isFirearmPaginationLoading}
                      isVisible={isFirearmSortLoaded}
                    />
                  )}
                </>
              )}
            </Accordion>
          </Grid>
        )}

        {!!orgLegalEntity && hasPermissions([EPermission.VIEW_FIREARMS_LE]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('legalEntityDetails.firearm.accordion.label')}
              name="firearms"
              id="legal_entity_firearms"
              expanded={isAllExpanded}
            >
              <FirearmSearchForm
                onSubmit={(it) => {
                  setIsFirearmQueryLoading(true);
                  setFirearmQuery(it);
                }}
                isSearchButtonDisabled={isFirearmQueryLoading}
              />
              {hasPermissions([EPermission.MANAGE_LE, EPermission.ADD_TRANSACTION]) && (
                <TableTopButtons>
                  <Button
                    label={t('legalEntityDetails.firearm.accordion.addFirearm.button')}
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    id="legal_entity_organisation-add_firearm_button"
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.LegalEntityAddFirearm.replace(
                          ':id',
                          id as string,
                        )}?type=${type}&expand=firearms`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                </TableTopButtons>
              )}
              {isFirearmQueryLoading || isFirearmInitDataLoading ? (
                <Loader isLoading={true} />
              ) : (
                <>
                  {isDesktop ? (
                    <Table
                      columns={legalEntityFirearmsTableColumns({
                        renderStateFieldColor: (data: ITableDataSource) =>
                          renderDataColor(data).stateField,
                        type,
                      })}
                      dataSource={mapLEFirearmToDataSource(legalEntityFirearms)}
                      onSort={(it) => {
                        setFirearmSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.firearm.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isFirearmSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEFirearmToDataSource(legalEntityFirearms)}
                      render={(data, index) => (
                        <LegalEntityFirearmTableCard
                          data={data}
                          key={index}
                          renderColor={renderDataColor}
                        />
                      )}
                    />
                  )}

                  {paginatorLEF && (
                    <Pagination
                      count={paginatorLEF?.totalElements}
                      perPage={perPageLEF}
                      onChangePage={(page) => {
                        setCurrentPageLEF(page);
                      }}
                      onChangePerPage={(value) => {
                        setPerPageLEF(value);
                      }}
                      current={currentPageLEF}
                      isLoading={isFirearmPaginationLoading}
                      isVisible={isFirearmSortLoaded}
                    />
                  )}
                </>
              )}
            </Accordion>
          </Grid>
        )}

        {!!legalEntity && hasPermissions([EPermission.MANAGE_GROUPS_LE]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('legalEntityDetails.firearmGroups.accordion.label')}
              name="groups"
              expanded={isAllExpanded}
            >
              <GroupSearchForm
                initialFormValues={groupQuery}
                onSubmit={(it) => {
                  setIsGroupQueryLoading(true);
                  setIsGroupQuerySearchButtonClicked(true);
                  setGroupQuery(it);
                }}
                isSearchButtonDisabled={isGroupQueryLoading}
              />
              {isLegalEntityGroupLoading ? (
                <Loader isLoading={isLegalEntityGroupLoading} />
              ) : (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={legalEntityGroupsTableColumns({
                        handleAdd: (groupId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityGroupFirearmSearch.replace(
                              ':id',
                              id as string,
                            )}?type=${type}&expand=groups&groupId=${groupId}`,
                            { state: { isDetailsContext: true } },
                          ),
                        handleEdit: (groupId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityEditGroup.replace(
                              ':id',
                              id as string,
                            ).replace(':groupId', groupId)}?type=${type}&expand=groups`,
                          ),
                        handleDelete: (groupId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityDeleteGroup.replace(
                              ':id',
                              id as string,
                            ).replace(':groupId', groupId)}?type=${type}&expand=groups`,
                          ),
                        renderStateFieldColor: (data: ITableDataSource) =>
                          renderDataColor(data).stateField,
                        type,
                        id,
                      })}
                      dataSource={mapLEGroupToDataSource(legalEntityGroups)}
                      onSort={(it) => {
                        setGroupSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.groups.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isGroupSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEGroupToDataSource(legalEntityGroups)}
                      render={(data, index) => (
                        <LegalEntityGroupTableCard
                          data={data}
                          key={index}
                          renderDataColor={() => renderDataColor(data)}
                        />
                      )}
                    />
                  )}

                  {paginatorLEG && (
                    <Pagination
                      count={paginatorLEG?.totalElements}
                      perPage={perPageLEG}
                      onChangePage={(page) => {
                        setCurrentPageLEG(page);
                      }}
                      onChangePerPage={(value) => {
                        setPerPageLEG(value);
                      }}
                      current={currentPageLEG}
                      isLoading={isFirearmGroupPaginationLoading}
                      isVisible={isGroupSortLoaded}
                    />
                  )}
                </div>
              )}
            </Accordion>
          </Grid>
        )}

        {!!orgLegalEntity && hasPermissions([EPermission.MANAGE_GROUPS_LE]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('legalEntityDetails.firearmGroups.accordion.label')}
              name="groups"
              expanded={isAllExpanded}
            >
              <GroupSearchForm
                initialFormValues={groupQuery}
                onSubmit={(it) => {
                  setIsGroupQueryLoading(true);
                  setIsGroupQuerySearchButtonClicked(true);
                  setGroupQuery(it);
                }}
                isSearchButtonDisabled={isGroupQueryLoading}
              />
              {isLegalEntityGroupLoading ? (
                <Loader isLoading={isLegalEntityGroupLoading} />
              ) : (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={legalEntityGroupsTableColumns({
                        handleAdd: (groupId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityGroupFirearmSearch.replace(
                              ':id',
                              id as string,
                            )}?type=${type}&expand=groups&groupId=${groupId}`,
                            { state: { isDetailsContext: true } },
                          ),
                        handleEdit: (groupId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityEditGroup.replace(
                              ':id',
                              id as string,
                            ).replace(':groupId', groupId)}?type=${type}&expand=groups`,
                          ),
                        handleDelete: (groupId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityDeleteGroup.replace(
                              ':id',
                              id as string,
                            ).replace(':groupId', groupId)}?type=${type}&expand=groups`,
                          ),
                        renderStateFieldColor: (data: ITableDataSource) =>
                          renderDataColor(data).stateField,
                        type,
                        id,
                      })}
                      dataSource={mapLEGroupToDataSource(legalEntityGroups)}
                      onSort={(it) => {
                        setGroupSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.groups.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isGroupSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEGroupToDataSource(legalEntityGroups)}
                      render={(data, index) => (
                        <LegalEntityGroupTableCard
                          data={data}
                          key={index}
                          renderDataColor={() => renderDataColor(data)}
                        />
                      )}
                    />
                  )}

                  {paginatorLEG && (
                    <Pagination
                      count={paginatorLEG?.totalElements}
                      perPage={perPageLEG}
                      onChangePage={(page) => {
                        setCurrentPageLEG(page);
                      }}
                      onChangePerPage={(value) => {
                        setPerPageLEG(value);
                      }}
                      current={currentPageLEG}
                      isLoading={isFirearmPaginationLoading}
                      isVisible={isGroupSortLoaded}
                    />
                  )}
                </div>
              )}
            </Accordion>
          </Grid>
        )}

        {(!!legalEntity || !!orgLegalEntity) &&
          hasPermissions([EPermission.VIEW_ANCILLARIES_LE]) && (
            <>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Accordion
                  title={t('legalEntityDetails.ancillaries.accordion.label')}
                  name="ancillaries"
                  id="legal_entity_ancillaries"
                  expanded={isAllExpanded}
                >
                  <>
                    <AncillariesSearchForm
                      onSubmit={(it) => {
                        setIsAncillariesQueryLoading(true);
                        setAncillariesQuery(it);
                      }}
                      isSearchButtonDisabled={isAncillariesQueryLoading}
                      isIndividualLE={legalEntity !== undefined && legalEntity !== null}
                    />
                    {hasPermissions([EPermission.MANAGE_LE, EPermission.ADD_TRANSACTION]) && (
                      <TableTopButtons>
                        <Button
                          label={t('legalEntityDetails.firearm.accordion.addAncillary.button')}
                          variant={EButtonVariants.contained}
                          icon={EIconTypes.plus}
                          onClick={() =>
                            navigate(
                              `${ERouteLinks.LegalEntityAddAncillary.replace(
                                ':id',
                                id as string,
                              )}?type=${type}&expand=ancillaries`,
                            )
                          }
                          sx={{ ml: 1 }}
                        />
                      </TableTopButtons>
                    )}

                    {isAncillariesQueryLoading || isAncillariesDataLoading ? (
                      <Loader isLoading={true} />
                    ) : (
                      <>
                        {isDesktop ? (
                          <Table
                            columns={legalEntityAncillariesTableColumns({
                              renderStateFieldColor: (data: ITableDataSource) =>
                                renderDataColor(data).stateField,
                            })}
                            dataSource={mapLEAncillaryToDataSource(legalEntityAncillaries)}
                            onSort={(it) => {
                              setAncillariesSort(it);
                              scrollPosition.current = window.scrollY;
                            }}
                            translationsKeys={{
                              noResults:
                                'legalEntityDetails.ancillaries.tab.search.noResult.info',
                            }}
                            specificRowColor={mapLegalityActivityMapColor}
                            specificRowColorHover={mapLegalityActivityMapHover}
                            isSortLoaded={isAncillariesSortLoaded}
                          />
                        ) : (
                          <CardList
                            items={mapLEAncillaryToDataSource(legalEntityAncillaries)}
                            render={(data, index) => (
                              <LegalEntityAncillaryTableCard data={data} key={index} />
                            )}
                          />
                        )}

                        {paginatorAncillaries && (
                          <Pagination
                            count={paginatorAncillaries?.totalElements}
                            perPage={perPageAncillaries}
                            onChangePage={(page) => {
                              setCurrentPageAncillaries(page);
                            }}
                            onChangePerPage={(value) => {
                              setPerPageAncillaries(value);
                            }}
                            current={currentPageAncillaries}
                            isLoading={isAncillaryPaginationLoading}
                            isVisible={isAncillariesSortLoaded}
                          />
                        )}
                      </>
                    )}
                  </>
                </Accordion>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Accordion
                  title={t('legalEntityDetails.ancillaryGroups.accordion.label')}
                  name="ancillaryGroups"
                  expanded={isAllExpanded}
                >
                  <AncillaryGroupsSearchForm
                    onSubmit={(it) => {
                      setIsAncillaryGroupsQueryLoading(true);
                      setAncillaryGroupsQuery(it);
                    }}
                    addGroup={() =>
                      navigate(
                        `${ERouteLinks.LegalEntityAddAncillaryGroup.replace(
                          ':id',
                          id as string,
                        )}?type=${type}&expand=ancillaryGroups`,
                      )
                    }
                    isSearchButtonDisabled={isAncillaryGroupsQueryLoading}
                  />
                  {isAncillaryGroupsQueryLoading || isLegalEntityAncillaryGroupLoading ? (
                    <Loader isLoading={true} />
                  ) : (
                    <>
                      {isDesktop ? (
                        <Table
                          columns={legalEntityAncillaryGroupsTableColumns({
                            handleAddToGroup: (groupId: string) =>
                              navigate(
                                `${ERouteLinks.AddToAncillaryGroup.replace(
                                  ':groupId',
                                  groupId as string,
                                ).replace(
                                  ':id',
                                  id as string,
                                )}?type=${type}&expand=ancillaryGroups`,
                              ),
                            handleEdit: (groupId: string) =>
                              navigate(
                                `${ERouteLinks.EditAncillaryGroup.replace(
                                  ':id',
                                  id as string,
                                ).replace(
                                  ':groupId',
                                  groupId,
                                )}?type=${type}&expand=ancillaryGroups`,
                              ),
                            handleDelete: (groupId: string) =>
                              navigate(
                                `${ERouteLinks.DeleteAncillaryGroup.replace(
                                  ':id',
                                  id as string,
                                ).replace(
                                  ':groupId',
                                  groupId,
                                )}?type=${type}&expand=ancillaryGroups`,
                              ),
                            renderStateFieldColor: (data: ITableDataSource) =>
                              renderDataColor(data).stateField,
                            type,
                            id,
                          })}
                          dataSource={mapLEAncillaryGropsToDataSource(
                            legalEntityAncillaryGroups,
                          )}
                          onSort={(it) => {
                            setAncillaryGroupsSort(it);
                            scrollPosition.current = window.scrollY;
                          }}
                          translationsKeys={{
                            noResults:
                              'legalEntityDetails.ancillaries.tab.search.noResult.info',
                          }}
                          specificRowColor={mapLegalityActivityMapColor}
                          specificRowColorHover={mapLegalityActivityMapHover}
                          isSortLoaded={isAncillaryGroupsSortLoaded}
                        />
                      ) : (
                        <CardList
                          items={mapLEAncillaryGropsToDataSource(legalEntityAncillaryGroups)}
                          render={(data, index) => (
                            <LegalEntityAncillaryGroupTableCard
                              data={data}
                              key={index}
                              id={id}
                              renderStateFieldColor={(d: ITableDataSource) =>
                                renderDataColor(d).stateField
                              }
                              legalEntityType={type || LEAccordionNames.INDIVIDUAL}
                            />
                          )}
                        />
                      )}

                      {paginatorAncillaryGroups && (
                        <Pagination
                          count={paginatorAncillaryGroups?.totalElements}
                          perPage={perPageAncillaryGroups}
                          onChangePage={(page) => {
                            setCurrentPageAncillaryGroups(page);
                          }}
                          onChangePerPage={(value) => {
                            setPerPageAncillaryGroups(value);
                          }}
                          current={currentPageAncillaryGroups}
                          isLoading={isAncillaryGroupPaginationLoading}
                          isVisible={isAncillaryGroupsSortLoaded}
                        />
                      )}
                    </>
                  )}
                </Accordion>
              </Grid>
            </>
          )}

        {!!legalEntity && hasPermissions([EPermission.MANAGE_COMMENTS_LE]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('legalEntityDetails.comments.accordion.label')}
              name="comments"
              expanded={isAllExpanded}
            >
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: { xs: '20px', sm: '16px' } }}
              >
                <Grid item xs={12} sm={6}>
                  <FormCheckbox
                    name="isShowHidden"
                    label={t('legalEntityDetails.commentsTab.displayDisabledComments.label')}
                    control={control}
                    errors={errors}
                    withValidation={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    label={t('legalEntityDetails.commentsTab.addComment.button')}
                    id="add-comment-button"
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() => {
                      const LEType: any = query.get('type');
                      const expand: any = 'comments';
                      const queryParams: any = new URLSearchParams({
                        type: LEType,
                        expand,
                      });
                      navigate(
                        `${ERouteLinks.LegalEntityAddComment.replace(
                          ':id',
                          id as string,
                        )}?${queryParams.toString()}`,
                      );
                    }}
                    fullWidth={isMobile}
                  />
                </Grid>
              </Grid>

              {isLegalEntityCommentsLoading ? (
                <Loader isLoading={true} />
              ) : (
                legalEntityComments?.map((item, index) => (
                  <Box key={item.uniqueId} sx={{ marginBottom: '8px' }}>
                    <CommentAccordion
                      comment={item}
                      name="comments"
                      visibilityButtonId={`legal-comment-visibility-button-${index}`}
                      dropdownIconId={`legal-comment-dropdown-${index}`}
                      deleteLink={ERouteLinks.LegalEntityDeleteComment}
                    />
                  </Box>
                ))
              )}
              {!isLegalEntityAddressLoading && !legalEntityComments?.length && (
                <Typography
                  variant="subtitle2"
                  mb={2}
                  textAlign="center"
                  color={colors.primary100}
                >
                  {t('legalEntityDetails.groups.tab.search.noResult.info')}
                </Typography>
              )}
              {paginatorLEComments && (
                <Pagination
                  count={paginatorLEComments?.totalElements}
                  perPage={perPageLEComments}
                  onChangePage={(page) => {
                    setCurrentPageLEComments(page);
                  }}
                  onChangePerPage={(value) => {
                    setPerPageLEComments(value);
                  }}
                  current={currentPageLEComments}
                  isLoading={isCommentPaginationLoading}
                  isVisible={isCommentsPaginationVisible}
                />
              )}
            </Accordion>
          </Grid>
        )}

        {!!orgLegalEntity && hasPermissions([EPermission.MANAGE_COMMENTS_LE]) && (
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Accordion
              title={t('legalEntityDetails.comments.accordion.label')}
              name="comments"
              expanded={isAllExpanded}
            >
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: { xs: '20px', sm: '16px' } }}
              >
                <Grid item xs={12} sm={6}>
                  <FormCheckbox
                    name="isShowHidden"
                    label={t('legalEntityDetails.commentsTab.displayDisabledComments.label')}
                    control={control}
                    errors={errors}
                    withValidation={false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    label={t('legalEntityDetails.commentsTab.addComment.button')}
                    id="add-comment-button"
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() => {
                      const LEType: any = query.get('type');
                      const expand: any = 'comments';
                      const queryParams: any = new URLSearchParams({
                        type: LEType,
                        expand,
                      });
                      navigate(
                        `${ERouteLinks.LegalEntityAddComment.replace(
                          ':id',
                          id as string,
                        )}?${queryParams.toString()}`,
                      );
                    }}
                    fullWidth={isMobile}
                  />
                </Grid>
              </Grid>

              {isLegalEntityCommentsLoading ? (
                <Loader isLoading={true} />
              ) : (
                legalEntityComments?.map((item) => (
                  <Box key={item.uniqueId} sx={{ marginBottom: '8px' }}>
                    <CommentAccordion
                      name="comments"
                      comment={item}
                      deleteLink={ERouteLinks.LegalEntityDeleteComment}
                    />
                  </Box>
                ))
              )}
              {!isLegalEntityAddressLoading && !legalEntityComments?.length && (
                <Typography
                  variant="subtitle2"
                  mb={2}
                  textAlign="center"
                  color={colors.primary100}
                >
                  {t('legalEntityDetails.groups.tab.search.noResult.info')}
                </Typography>
              )}
              {paginatorLEComments && (
                <Pagination
                  count={paginatorLEComments?.totalElements}
                  perPage={perPageLEComments}
                  onChangePage={(page) => {
                    setCurrentPageLEComments(page);
                  }}
                  onChangePerPage={(value) => {
                    setPerPageLEComments(value);
                  }}
                  current={currentPageLEComments}
                  isLoading={isCommentPaginationLoading}
                  isVisible={isCommentsPaginationVisible}
                />
              )}
            </Accordion>
          </Grid>
        )}

        <Grid item xs={12} sx={{ mt: 2 }}>
          {!!legalEntity && (
            <Accordion
              title={t('legalEntityDetails.contact.accordion.label')}
              name="contact"
              expanded={isAllExpanded}
            >
              <TableTopButtons>
                {hasPermissions([EPermission.MANAGE_LE]) && (
                  <Button
                    label={t('legalEntityDetails.contactTab.addContact.button')}
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.LegalEntityAddContact.replace(
                          ':id',
                          id as string,
                        )}?type=${type}&expand=contact`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                )}
              </TableTopButtons>

              {isLegalEntityContactsLoading ? (
                <Loader isLoading={isLegalEntityContactsLoading} />
              ) : (
                <div>
                  {isDesktop && type ? (
                    <Table
                      columns={legalEntityContactTableColumns({
                        type: type as ELegalEntityTypes,
                        handleEdit: (contactId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityEditContact.replace(
                              ':id',
                              id as string,
                            ).replace(':contactId', contactId)}?type=${type}&expand=contact`,
                          ),
                        handleDelete: (contactId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityDeleteContact.replace(
                              ':id',
                              id as string,
                            ).replace(':contactId', contactId)}?type=${type}&expand=contact`,
                          ),
                      })}
                      dataSource={mapLEContactToDataSource(
                        legalEntityContacts,
                        parseDictionary(phoneCode, 'name'),
                      )}
                      onSort={(it) => {
                        setContactSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.contact.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isContactSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEContactToDataSource(
                        legalEntityContacts,
                        parseDictionary(phoneCode, 'name'),
                      )}
                      render={(data, index) => (
                        <Box mb={2} key={index}>
                          <LegalEntityContactTableCard data={data} key={index} />
                        </Box>
                      )}
                    />
                  )}
                </div>
              )}
            </Accordion>
          )}

          {!!orgLegalEntity && (
            <Accordion
              title={t('legalEntityDetails.contact.accordion.label')}
              name="contact"
              expanded={isAllExpanded}
            >
              <TableTopButtons>
                {hasPermissions([EPermission.MANAGE_LE]) && (
                  <Button
                    label={t('legalEntityDetails.contactTab.addContact.button')}
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.LegalEntityAddContact.replace(
                          ':id',
                          id as string,
                        )}?type=${type}&expand=contact`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                )}
              </TableTopButtons>

              {isLegalEntityContactsLoading ? (
                <Loader isLoading={isLegalEntityContactsLoading} />
              ) : (
                <div>
                  {isDesktop && type ? (
                    <Table
                      columns={legalEntityContactTableColumns({
                        type: type as ELegalEntityTypes,
                        handleEdit: (contactId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityEditContact.replace(
                              ':id',
                              id as string,
                            ).replace(':contactId', contactId)}?type=${type}&expand=contact`,
                          ),
                        handleDelete: (contactId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityDeleteContact.replace(
                              ':id',
                              id as string,
                            ).replace(':contactId', contactId)}?type=${type}&expand=contact`,
                          ),
                      })}
                      dataSource={mapLEContactToDataSource(
                        legalEntityContacts,
                        parseDictionary(phoneCode, 'name'),
                      )}
                      onSort={(it) => {
                        setContactSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.contact.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isContactSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEContactToDataSource(
                        legalEntityContacts,
                        parseDictionary(phoneCode, 'name'),
                      )}
                      render={(data, index) => (
                        <Box mb={2} key={index}>
                          <LegalEntityContactTableCard data={data} key={index} />
                        </Box>
                      )}
                    />
                  )}
                </div>
              )}
            </Accordion>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {!!legalEntity && (
            <Accordion
              title={t('legalEntityDetails.address.accordion.label')}
              name="address"
              expanded={isAllExpanded}
            >
              <TableTopButtons>
                {hasPermissions([EPermission.MANAGE_LE]) && (
                  <Button
                    label={t('legalEntityDetails.addressTab.addAddress.button')}
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.LegalEntityAddAddress.replace(
                          ':id',
                          id as string,
                        )}?type=${type}&expand=address`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                )}
              </TableTopButtons>

              {isLegalEntityAddressLoading ? (
                <Loader isLoading={isLegalEntityAddressLoading} />
              ) : (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={legalEntityAddressTableColumns({
                        handleEdit: (addressId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityEditAddress.replace(
                              ':id',
                              id as string,
                            ).replace(':addressId', addressId)}?type=${type}&expand=address`,
                          ),
                        handleDelete: (addressId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityDeleteAddress.replace(
                              ':id',
                              id as string,
                            ).replace(':addressId', addressId)}?type=${type}&expand=address`,
                          ),
                        t,
                      })}
                      dataSource={mapLEAddressToDataSource(
                        legalEntityAddress,
                        parseDictionary(region, 'name'),
                        parseDictionary(country, 'name'),
                        parseDictionary(addressType, 'name'),
                        id as string,
                      )}
                      onSort={(it) => {
                        setAddressSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.address.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isAddressSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEAddressToDataSource(
                        legalEntityAddress,
                        parseDictionary(region, 'name'),
                        parseDictionary(country, 'name'),
                        parseDictionary(addressType, 'name'),
                        id as string,
                      )}
                      render={(data, index) => (
                        <Box mb={2} key={index}>
                          <LegalEntityAddressTableCard data={data} key={index} />
                        </Box>
                      )}
                    />
                  )}
                </div>
              )}
            </Accordion>
          )}

          {!!orgLegalEntity && (
            <Accordion
              title={t('legalEntityDetails.address.accordion.label')}
              name="address"
              expanded={isAllExpanded}
            >
              <TableTopButtons>
                {hasPermissions([EPermission.MANAGE_LE]) && (
                  <Button
                    label={t('legalEntityDetails.addressTab.addAddress.button')}
                    variant={EButtonVariants.contained}
                    icon={EIconTypes.plus}
                    onClick={() =>
                      navigate(
                        `${ERouteLinks.LegalEntityAddAddress.replace(
                          ':id',
                          id as string,
                        )}?type=${type}&expand=address`,
                      )
                    }
                    sx={{ ml: 1 }}
                  />
                )}
              </TableTopButtons>

              {isLegalEntityAddressLoading ? (
                <Loader isLoading={isLegalEntityAddressLoading} />
              ) : (
                <div>
                  {isDesktop ? (
                    <Table
                      columns={legalEntityAddressTableColumns({
                        handleEdit: (addressId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityEditAddress.replace(
                              ':id',
                              id as string,
                            ).replace(':addressId', addressId)}?type=${type}&expand=address`,
                          ),
                        handleDelete: (addressId: string) =>
                          navigate(
                            `${ERouteLinks.LegalEntityDeleteAddress.replace(
                              ':id',
                              id as string,
                            ).replace(':addressId', addressId)}?type=${type}&expand=address`,
                          ),
                        t,
                      })}
                      dataSource={mapLEAddressToDataSource(
                        legalEntityAddress,
                        parseDictionary(region, 'name'),
                        parseDictionary(country, 'name'),
                        parseDictionary(addressType, 'name'),
                        id as string,
                      )}
                      onSort={(it) => {
                        setAddressSort(it);
                        scrollPosition.current = window.scrollY;
                      }}
                      translationsKeys={{
                        noResults: 'legalEntityDetails.address.tab.search.noResult.info',
                      }}
                      specificRowColor={mapLegalityActivityMapColor}
                      specificRowColorHover={mapLegalityActivityMapHover}
                      isSortLoaded={isAddressSortLoaded}
                    />
                  ) : (
                    <CardList
                      items={mapLEAddressToDataSource(
                        legalEntityAddress,
                        parseDictionary(region, 'name'),
                        parseDictionary(country, 'name'),
                        parseDictionary(addressType, 'name'),
                        id as string,
                      )}
                      render={(data, index) => (
                        <Box mb={2} key={index}>
                          <LegalEntityAddressTableCard data={data} key={index} />
                        </Box>
                      )}
                    />
                  )}
                </div>
              )}
            </Accordion>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export { ViewLegalEntityPage };
