import * as yup from 'yup';

const useForgotPasswordFormSchema = () => {
  const schema = yup.object({
    email: yup
      .string()
      .email('Please, enter your email.')
      .max(64)
      .required('Please, enter your email.'),
  });
  return { schema };
};

export default useForgotPasswordFormSchema;
