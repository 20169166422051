import { useState } from 'react';
import { IPaginator } from 'models/paginator';
import { IAuditLog, IAuditLogItemResponse, IAuditLogSearchFormValues } from 'models/auditLog';
import { EPerPages, ISortOptions } from 'models/table';
import { getAuditLog, getAuditLogItem } from 'requests/auditLog';
import { useDictionary } from 'models/dictionaryContext';
import { EDictionaryTypes } from 'models/dictionary';
import { formatDateTimeToIso } from 'utils/date';

export const useAuditLog = () => {
  const [auditLogs, setAuditLogs] = useState<IAuditLog[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const [auditLog, setAuditLog] = useState<IAuditLogItemResponse>();

  const {
    getAuditLogModule,
    getAuditLogEventType,
    getAuditLogEventName,
    auditLogModule,
    auditLogEventType,
    auditLogEventName,
  } = useDictionary();

  const loadDictionaries = async (types: EDictionaryTypes[]) => {
    if (types.includes(EDictionaryTypes.AuditLogModule)) {
      getAuditLogModule();
    }
    if (types.includes(EDictionaryTypes.AuditLogEventType)) {
      getAuditLogEventType();
    }
    if (types.includes(EDictionaryTypes.AuditLogEventName)) {
      getAuditLogEventName();
    }
  };

  const getAuditLogData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<IAuditLogSearchFormValues> = {},
    sort?: ISortOptions | null,
  ) => {
    if (query?.eventDateFrom) {
      query.eventDateFrom = formatDateTimeToIso(query.eventDateFrom);
    }
    if (query?.eventDateTo) {
      query.eventDateTo = formatDateTimeToIso(query.eventDateTo, true);
    }

    const auditLogData = await getAuditLog(currentPage, perPage, query, sort);

    if (auditLogData) {
      const { content, ...paginatorData } = auditLogData;
      setAuditLogs(content);
      setPaginator(paginatorData);
    } else {
      setAuditLogs([]);
      setPaginator(null);
    }
  };

  const getAuditLogItemData = async (id: string) => {
    const auditLogItem = await getAuditLogItem(id);
    if (auditLogItem) {
      setAuditLog(auditLogItem);
    }
  };

  return {
    getAuditLogData,
    getAuditLogItemData,
    auditLog,
    auditLogs,
    paginator,
    auditLogModule,
    auditLogEventType,
    auditLogEventName,
    loadDictionaries,
  };
};
