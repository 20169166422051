import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { getLegalEntityAddress } from 'requests/legalEntities';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { StyledDetailsWrapper } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage/styles';
import { ILegalEntityAddress } from 'models/legalEntity';
import { useDictionary } from 'models/dictionaryContext';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';

const LegalEntityDeleteAddressPage = () => {
  const { id, addressId } = useParams<{ id: string; addressId: string }>();
  const query = useQuery();
  const [legalEntityAddress, setLegalEntityAddress] = useState<ILegalEntityAddress | null>();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();

  const { handleDeleteAddress } = useLegalEntities();
  const { navigateToDetails, loadDictionaries } = useLegalEntities();
  const { addressType, country, region } = useDictionary();
  const { setAlert, samePageAlert, clearAlert } = useAlert();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const type: string = query.get('type') || '';

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.Country,
        EDictionaryTypes.State,
        EDictionaryTypes.AddressType,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    (async function getLegalEntityAddressDataInit() {
      if (id && addressId) {
        const response = await getLegalEntityAddress(id, addressId);
        if (response) {
          setLegalEntityAddress(response);
        }
      }
    })();
  }, [id, addressId]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onDelete = async () => {
    setIsDeleteLoading(true);
    if (id && legalEntityAddress) {
      try {
        const result = await handleDeleteAddress(id, legalEntityAddress.uniqueId);
        if (result) {
          await setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
            text: t('deleteAddress.save.success.text'),
          });
          navigateToDetails();
        }
      } catch (e: any) {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: e.response.data.errorMessages[0],
          variant: EAlertVariants.error,
        });
      }
    }

    setIsDeleteLoading(false);
  };

  const parsedAddressTypeDictionary: IParsedDictionary = parseDictionary(addressType, 'name');
  const parsedCountryDictionary: IParsedDictionary = parseDictionary(country, 'name');
  const parsedRegionDictionary: IParsedDictionary = parseDictionary(region, 'name');

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=address`,
          },
          t('deleteAddress.header'),
        ]}
      />
      {legalEntityAddress && (
        <>
          <StyledPageTitle variant="h4">{t('deleteAddress.header')}</StyledPageTitle>

          <Box my={3}>
            <Alert text={t('deleteAddress.info.text')} variant={EAlertVariants.warning} />
          </Box>

          <StyledDetailsWrapper container rowGap={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('editAddress.addressType.label')}
              </Typography>
              <Typography variant="body2">
                {legalEntityAddress.typeUniqueId &&
                parsedAddressTypeDictionary?.[legalEntityAddress.typeUniqueId]
                  ? `${parsedAddressTypeDictionary?.[legalEntityAddress.typeUniqueId]}`
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.addressTab.storageAddress.column')}
              </Typography>
              <Typography variant="body2">
                {legalEntityAddress.isDefault ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('editAddress.addressLine1.label')}
              </Typography>
              <Typography variant="body2">{legalEntityAddress.line1}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('editAddress.addressLine2.label')}
              </Typography>
              <Typography variant="body2">{legalEntityAddress.line2 || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('editAddress.cityTown.label')}
              </Typography>
              <Typography variant="body2">{legalEntityAddress.city}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('editAddress.country.label')}
              </Typography>
              <Typography variant="body2">
                {legalEntityAddress.countryUniqueId &&
                parsedCountryDictionary?.[legalEntityAddress.countryUniqueId]
                  ? `${parsedCountryDictionary?.[legalEntityAddress.countryUniqueId]}`
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('editAddress.stateProvince.label')}
              </Typography>
              <Typography variant="body2">
                {legalEntityAddress.regionUniqueId &&
                parsedRegionDictionary?.[legalEntityAddress.regionUniqueId]
                  ? `${parsedRegionDictionary?.[legalEntityAddress.regionUniqueId]}`
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('editAddress.zipPostalCode.label')}
              </Typography>
              <Typography variant="body2">{legalEntityAddress.postalCode}</Typography>
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.yes.button')}
                id="yes-button"
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.no.button')}
                id="no-button"
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
          {samePageAlert && (
            <Box mt={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export { LegalEntityDeleteAddressPage };
