import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';

export const StyledNotificationListHeaderItem = styled(Grid)`
  color: ${colors.white};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 1px;
`;

export const notificationListHeaderCheckboxStyle = {
  backgroundColorChecked: colors.primary700,
  backgroundColorUnChecked: colors.white,
  borderColor: colors.gray300,
};
