import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledCFNameTranslation = styled(Typography)`
  color: ${colors.primary900};
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  width: 100%;
  border-radius: 4px;
  background-color: ${colors.primary15};
  padding: 13px 24px;
  margin: 24px 0;
`;
