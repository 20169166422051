import React from 'react';
import {
  AssignedLETemplate,
  EAssignedLETemplateTypes,
} from 'components/templates/AssignedLETemplate';
import { IAssignLE } from 'models/legalEntity';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { EAssignLEPageTranslationsKeys } from 'pages/UsersPage/AssignLEPage';

const DeleteAssignedLEPage = () => {
  const { handleDeleteAssignedLE } = useLegalEntities();

  return (
    <AssignedLETemplate
      type={EAssignedLETemplateTypes.delete}
      onSubmit={async (data: IAssignLE) => handleDeleteAssignedLE(data)}
      translationsKeys={EAssignLEPageTranslationsKeys}
    />
  );
};

export { DeleteAssignedLEPage };
