import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import { IMessage } from 'models/message';

const getTranslations = async (langCode: string): Promise<IMessage[] | null> => {
  const { data } = await apiClient.get(`${ApiEndpoints.GET_TRANSLATIONS()}?keys`, {
    headers: {
      'Accept-Language': langCode,
    },
  });

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export { getTranslations };
