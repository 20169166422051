import { EPermission } from 'models/permissions';
import { useAuth } from 'models/authContext';

export const usePermission = () => {
  const { user } = useAuth();

  const hasPermissions = (
    permissions: EPermission | EPermission[],
    isORCondition: boolean = false,
  ): boolean | null => {
    if (!user || !user.permissions || !user.permissions.length) {
      return null;
    }

    if (Array.isArray(permissions)) {
      if (permissions.length === 0) {
        return true;
      }
      if (isORCondition) {
        return permissions.some((permission: EPermission) =>
          user.permissions.includes(permission),
        );
      }
      return permissions.every((permission: EPermission) =>
        user.permissions.includes(permission),
      );
    }

    return user.permissions.includes(permissions);
  };

  return { hasPermissions };
};
