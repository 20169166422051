import styled from '@emotion/styled';
import { Theme } from '@mui/material';

export const StyledTableTopButtons = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('xs')} {
        flex-direction: column;
        align-items: stretch;  
      };
    ${(theme as Theme).breakpoints.up('sm')} {
        flex-direction: row;
        align-items: flex-end;  
      };
      `};

  & button {
    ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('xs')} {
        margin-bottom: 8px;
        margin-left: 0;
        margin-right: 0;
      };
    ${(theme as Theme).breakpoints.up('sm')} {
        margin-bottom: 0;
        margin-left: 8px;
      };
      `};
  }
`;
