import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { INotification } from 'models/notification';
import { formatDate } from 'utils/date';
import { colors } from 'theme/colors';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useForm } from 'react-hook-form';
import { mapFilterTypeToLabel } from 'pages/NotificationsPage/helpers';
import { useTranslations } from 'hooks/useTranslations';
import {
  notificationListItemCheckboxStyle,
  StyledNotificationListItem,
} from 'pages/NotificationsPage/NotificationListItem/styles';
import { useGlobalProperty } from 'models/globalPropertyContext';

interface INotificationListItem {
  item: INotification;
  onChange: (arg: string) => void;
  handleDelete: () => void;
  isChecked: boolean;
}

const defaultValues = {
  isChecked: false,
};

const NotificationListItem = ({
  item,
  onChange,
  isChecked,
  handleDelete,
}: INotificationListItem) => {
  const { t } = useTranslations();
  const { longDateFormat } = useGlobalProperty();
  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setValue('isChecked', isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <StyledNotificationListItem
      item
      xs={12}
      container
      alignItems="center"
      sx={{
        margin: '6px 0',
        padding: '4px 12px',
        borderRadius: '4px',
        backgroundColor: item.read ? colors.primary15 : colors.primary25,
        border: isChecked ? `1px solid ${colors.primary200}` : '1px solid transparent',
      }}
    >
      <Grid item xs={1}>
        <FormCheckbox
          name="isChecked"
          control={control}
          errors={errors}
          onChange={() => onChange(item.uniqueId)}
          style={notificationListItemCheckboxStyle}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography
          variant={`${item.read ? 'body2' : 'subtitle2'}`}
          sx={{ color: colors.gray900 }}
        >
          {formatDate(item.createdDate, longDateFormat)}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant={`${item.read ? 'body2' : 'subtitle2'}`}>
          {t('notification.subject')
            .replace('{0}', mapFilterTypeToLabel(item.filterType, t))
            .replace('{1}', item.filterName)}
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        <Grid item>
          <Link to={ERouteLinks.ViewNotification.replace(':id', item.uniqueId)}>
            <Button
              variant={EButtonVariants.outlined}
              icon={EIconTypes.view}
              sx={{ marginRight: '4px' }}
            />
          </Link>
        </Grid>
        <Grid item>
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.delete}
            onClick={handleDelete}
          />
        </Grid>
      </Grid>
    </StyledNotificationListItem>
  );
};

export { NotificationListItem };
