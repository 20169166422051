import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { FRTCalibreForm } from 'pages/FRT/FRTCalibreForm';
import { IFRTCalibreFormValues } from 'models/frt';
import { useFRT } from 'pages/FRT/hooks';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';

const FRTAddCalibrePage = () => {
  const { frtId = '' } = useParams<{ frtId: string }>();
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { handleCreate } = useFRT();
  const { t } = useTranslations();

  const handleSubmit = async (data: IFRTCalibreFormValues) => {
    try {
      await handleCreate(frtId, data);
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('frtCalibre.add.success.message'),
        variant: EAlertVariants.success,
      });
      navigate(`${ERouteLinks.FRTDetails.replace(':frtId', frtId)}?expand=calibre`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('frtCalibre.breadcrumbs.firearms.label'), route: ERouteLinks.Firearms },
          {
            label: t('frtCalibre.breadcrumbs.frt.label'),
            route: `${ERouteLinks.Firearms}?expand=frt`,
          },
          {
            label: t('frtCalibre.breadcrumbs.firearmDetails.label'),
            route: ERouteLinks.FRTDetails.replace(':frtId', frtId),
          },
          t('frtCalibre.addCalibre.title'),
        ]}
      />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">{t('frtCalibre.addCalibre.title')}</StyledPageTitle>
      </Box>

      <TableFormWrapper>
        <FRTCalibreForm
          onCancel={() => navigate(ERouteLinks.FRTDetails.replace(':frtId', frtId))}
          onSubmit={handleSubmit}
        />
      </TableFormWrapper>
    </>
  );
};

export { FRTAddCalibrePage };
