import React, { useEffect, useState } from 'react';
import { IAuditLogItemResponse } from 'models/auditLog';
import { Typography } from '@mui/material';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { useCustomFields } from 'pages/CustomFieldsPage/hooks';
import { ICustomField } from 'models/customField';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import {
  mapCustomFieldToLabel,
  mapCustomFieldTypeToLabel,
} from 'pages/CustomFieldsPage/helpers';
import { useTranslations } from 'hooks/useTranslations';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';

interface ICustomFieldEventDetails {
  auditLog: IAuditLogItemResponse;
}

const CustomFieldEventDetails = ({ auditLog }: ICustomFieldEventDetails) => {
  const { t } = useTranslations();
  const { handleGetCustomField } = useCustomFields();
  const { hasPermissions } = usePermission();
  const [customField, setCustomField] = useState<ICustomField>();

  useEffect(() => {
    if (auditLog?.objectUniqueId && hasPermissions([EPermission.MANAGE_CUSTOM_FIELDS])) {
      (async () => {
        const result = await handleGetCustomField(auditLog.objectUniqueId);
        if (result) {
          setCustomField(result);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditLog]);

  return (
    <>
      <AuditLogEventName>
        <Typography variant="h5">{t('auditLogDetails.customFieldDetails.title')}</Typography>
      </AuditLogEventName>

      {customField && (
        <DetailsTemplate
          pageType={EDetailsPageTypes.auditLogView}
          title={customField.name}
          disableActions={true}
        >
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.objectType.label')}
            </Typography>
            <Typography variant="body2">
              {t(mapCustomFieldToLabel(customField.objectType))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.name.label')}
            </Typography>
            <Typography variant="body2">{customField.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.type.label')}
            </Typography>
            <Typography variant="body2">
              {t(mapCustomFieldTypeToLabel(customField.customFieldType))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.status.label')}
            </Typography>
            <Chips
              text={
                customField.enabled
                  ? t('customFields.card.enabled.label')
                  : t('customFields.card.disabled.label')
              }
              variant={customField.enabled ? EChipsVariants.success : EChipsVariants.inactive}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.card.mandatoryField.label')}
            </Typography>
            <Chips
              text={
                customField.mandatory
                  ? t('customFields.card.yes.label')
                  : t('customFields.card.no.label')
              }
              variant={
                customField.mandatory ? EChipsVariants.primary : EChipsVariants.inactive
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.searchCriterion.label')}
            </Typography>
            <Chips
              text={
                customField.searchCriteria
                  ? t('customFields.card.yes.label')
                  : t('customFields.card.no.label')
              }
              variant={
                customField.searchCriteria ? EChipsVariants.primary : EChipsVariants.inactive
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('customFields.visibleSearchResults.label')}
            </Typography>
            <Chips
              text={
                customField.visibleInSearchResults
                  ? t('customFields.card.yes.label')
                  : t('customFields.card.no.label')
              }
              variant={
                customField.visibleInSearchResults
                  ? EChipsVariants.primary
                  : EChipsVariants.inactive
              }
            />
          </Grid>
        </DetailsTemplate>
      )}
    </>
  );
};

export default CustomFieldEventDetails;
