import { useState } from 'react';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { INotification } from 'models/notification';
import {
  deleteNotifications,
  getNotifications,
  getNotificationUnreadCounter,
  markAsReadNotifications,
} from 'requests/notification';
import { useNotificationCounter } from 'models/notificationCounterContext';

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const [notificationsCounter, setNotificationsCounter] = useState<number>(0);
  const { counter, setCounter } = useNotificationCounter();

  const getNotificationsData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    sort?: ISortOptions | null,
  ) => {
    const notificationsData = await getNotifications(currentPage, perPage, sort);

    if (notificationsData) {
      const { content, ...paginatorData } = notificationsData;
      setNotifications(content);
      setPaginator(paginatorData);
    } else {
      setNotifications([]);
      setPaginator(null);
    }
    return notificationsData;
  };

  const getNotificationUnreadCounterData = async () => {
    const responseCounter = await getNotificationUnreadCounter();
    if (counter !== responseCounter) {
      setNotificationsCounter(responseCounter);
    }
    setCounter(responseCounter);
  };

  const handleDeleteNotifications = async (ids: string[]) => {
    let query = '';
    ids.forEach((id: string, index: number) => {
      query = `${query}uniqueIds=${id}${index !== ids.length - 1 ? '&' : ''}`;
    });
    await deleteNotifications(`?${query}`);
    setNotifications([]);
    await getNotificationsData();
    await getNotificationUnreadCounterData();
  };

  const handleMarkAsReadNotifications = async (ids: string[]) => {
    let query = '';
    ids.forEach((id: string, index: number) => {
      query = `${query}uniqueIds=${id}${index !== ids.length - 1 ? '&' : ''}`;
    });
    await markAsReadNotifications(`?${query}`);
    setNotifications([]);
    await getNotificationsData();
    await getNotificationUnreadCounterData();
  };

  return {
    alert,
    notifications,
    getNotificationsData,
    paginator,
    getNotificationUnreadCounterData,
    notificationsCounter,
    handleDeleteNotifications,
    handleMarkAsReadNotifications,
  };
};
