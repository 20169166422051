import React, { ReactNode } from 'react';
import { isFunction } from 'helpers/guards';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';
import { useTranslations } from 'hooks/useTranslations';
import { ITranslationKey } from 'components/molecules/Table';

interface ICardList {
  items: any[];
  render: (data: any, index: number) => ReactNode;
  translationsKeys?: ITranslationKey | any;
}

export const emptyFieldHolder = (objects: any | any[]) => {
  const isEmptyField = (object: any) =>
    object === undefined || object === null || object.toString().trim() === '';

  if (Array.isArray(objects)) {
    return objects.filter((it) => isEmptyField(it)).length > 0 ? '-' : objects.join(' ');
  }
  return isEmptyField(objects) ? '-' : objects;
};

const CardList = ({ items, render, translationsKeys }: ICardList) => {
  const { t } = useTranslations();
  if (items.length) {
    return (
      <>{items.map((item, index) => (isFunction(render) ? render(item, index) : null))}</>
    );
  }
  return (
    <Typography variant="subtitle2" mb={2} textAlign="center" color={colors.primary100}>
      {t(translationsKeys?.noResults) || t('general.noResults.text')}
    </Typography>
  );
};

export { CardList };
