import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useCommentFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    title: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(256, t('general.field.validation.maxCharacters').replace('{0}', '256'))
      .trim(),
    comment: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(1000, t('general.field.validation.maxCharacters').replace('{0}', '1000'))
      .trim(),
  });
  return { schema };
};
export default useCommentFormSchema;
