import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';
import { Box } from '@mui/material';

export const AuditLogDetails = styled(Grid)`
  margin: 20px 0;
  background-color: ${colors.gray50};
  border: 1px solid ${colors.gray300};
  border-radius: 4px;
`;

export const AuditLogEventName = styled(Box)`
  background-color: ${colors.primary15};
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;

  & h5 {
    font-weight: 400;
    color: ${colors.primary900};
  }
`;

export const AuditLogDetailsWrapper = styled(Grid)`
  background-color: ${colors.gray100};
  padding: 5px 15px 20px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid ${colors.gray200};
`;
