import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Checkbox, Typography } from '@mui/material';
import { Chips } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { IAncillary } from 'models/ancillaries';

interface ILegalEntityAncillaryTableCard {
  data: ITableDataSource;
  selectAction: (p: any) => void;
  selectedAncillaries: IAncillary[];
  renderColor: (arg: ITableDataSource) => any;
}

const LegalEntityGroupSearchAncillaryTableCard = ({
  data,
  selectAction,
  selectedAncillaries,
  renderColor,
}: ILegalEntityAncillaryTableCard) => {
  const { t } = useTranslations();

  const isItemSelected = selectedAncillaries?.find((item) => item.uniqueId === data.id);

  const renderToggleSelectButtonLabel = () => {
    let label: string;
    if (isItemSelected) {
      label = t('searchForLegalEntityAncillary.unselectAncillary.button');
    } else {
      label = t('searchForLegalEntityAncillary.selectAncillary.button');
    }
    return label;
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderColor(data).border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderColor(data).background,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.fireCycleId.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.lotNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.lotNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.batchNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.batchNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.ancillary.column')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(data.ancillaryTypeDictionaryValue)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.ancillaryType.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ancillarySpecificType)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.keeper.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.keeper)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.owner.column')}
        </Typography>

        <Typography variant="body2">{emptyFieldHolder(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.calibre.column')}
        </Typography>

        <Typography variant="body2">{emptyFieldHolder(data.calibre)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAncillary.list.state.column')}
        </Typography>
        {data.state ? <Chips text={data.state} variant={renderColor(data).stateField} /> : '-'}
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
      >
        <Grid item xs={12} container mt={1}>
          <Grid item xs={6} sm={5}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {t('general.actions.text')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={1}
            textAlign="right"
            sx={{
              pr: {
                sm: 2,
                lg: 0,
              },
            }}
          >
            <Checkbox
              onClick={() => selectAction(data)}
              checked={!!isItemSelected}
              sx={{
                marginTop: '3px',
                color: colors.gray300,

                '&.Mui-checked': {
                  color: colors.primary500,
                },
                '&.MuiCheckbox-root': {
                  maxWidth: '24px',
                  maxHeight: '24px',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ mt: { xs: 1, sm: 0 } }}>
            <Button
              label={renderToggleSelectButtonLabel()}
              variant={EButtonVariants.outlined}
              onClick={() => selectAction(data)}
              size="small"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { LegalEntityGroupSearchAncillaryTableCard };
