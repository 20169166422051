import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { IFormElementOptionProp } from 'models/form';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Alert } from 'components/atoms/Alert';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { FormSelect } from 'components/molecules/FormSelect';
import { Box, Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useAlert } from 'models/alertContext';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { IAuthenticationSetupItem, ItrustedDeviceOptions } from 'models/settings';
import { useLang } from 'models/langContext';
import { ActionTypes } from 'state/actions/alert';
import { useLicenceContext } from 'models/licenceContext';
import TableFormWrapper from 'components/atoms/FormWrapper';
import useAuthenticationSetupFormSchema from './schemaValidation';
import { useAuthenticationSetup } from './hooks';
import { StyledValueFormHeader } from '../styles';

const numberOfInvalidLoginAttempts = {
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
};

const timePeriodOfAccountLockout = (t: (key: string) => string) => ({
  '15': `15 ${t('authenticationSetup.minutes.label')}`,
  '30': `30 ${t('authenticationSetup.minutes.label')}`,
  '60': `1 ${t('authenticationSetup.hour.label')}`,
  '1440': `1 ${t('authenticationSetup.day.label')}`,
  '999999999': t('authenticationSetup.perm.ban.label'),
});
const twoFactorAuthenticationOptions = (t: (key: string) => string) => ({
  true: `${t('authenticationSetup.enable.label')}`,
  false: `${t('authenticationSetup.disable.label')}`,
});

export interface IFormValues {
  twoFactorAuthentication: boolean;
  timePeriods: string[];
  numberOfInvalidLoginAttempts: string;
  timePeriodOfAccountLockout: string;
}

const initialFormValues: IFormValues = {
  twoFactorAuthentication: false,
  timePeriods: [],
  numberOfInvalidLoginAttempts: '',
  timePeriodOfAccountLockout: '',
};

const AuthenticationSetupPage = () => {
  const { t } = useTranslations();
  const { samePageAlert, clearAlert } = useAlert();
  const navigate = useNavigate();
  const { selectedLanguage } = useLang();
  const {
    handleUpdate,
    getAuthenticationSetupData,
    authenticationSetup,
    getTrustedDeviceOptionsData,
    trustedDeviceOptions,
  } = useAuthenticationSetup();

  const [initialValues, setInitialValues] = useState<IFormValues>(initialFormValues);
  const { isOnlineLicence } = useLicenceContext();
  const { schema } = useAuthenticationSetupFormSchema();
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const prepareSelectOptions = (list: IAuthenticationSetupItem): IFormElementOptionProp[] =>
    Object.keys(list).map((item) => ({
      value: item,
      label: list[item],
    }));

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (authenticationSetup) {
      setInitialValues({
        twoFactorAuthentication: authenticationSetup.two_fa_enabled,
        timePeriods: authenticationSetup.timePeriods,
        numberOfInvalidLoginAttempts: authenticationSetup.attempts_to_block,
        timePeriodOfAccountLockout: authenticationSetup?.minutes_in_ban,
      });
    }
  }, [authenticationSetup]);

  useEffect(() => {
    (async function getDataInit() {
      await getAuthenticationSetupData(isOnlineLicence());
      if (isOnlineLicence()) {
        await getTrustedDeviceOptionsData();
      }
    })();
  }, [selectedLanguage]);

  const onSubmitForm = async (data: IFormValues) => {
    setSubmitLoading(true);
    const dataTimePeriods: ItrustedDeviceOptions[] = [];
    // eslint-disable-next-line array-callback-return
    trustedDeviceOptions?.map((item) => {
      if (item.daysToExpire && data.timePeriods[item.daysToExpire] !== undefined) {
        dataTimePeriods.push({
          uniqueId: item.uniqueId,
          enabled: !!data.timePeriods[item.daysToExpire],
        });
      } else {
        dataTimePeriods.push({ uniqueId: item.uniqueId, enabled: item.enabled });
      }
    });

    const dataToUpdate: any = { ...data, timePeriods: dataTimePeriods };
    await handleUpdate(dataToUpdate);
    setSubmitLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          { label: t('systemSettings.header'), route: ERouteLinks.SystemSettings },
          t('authenticationSetup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('authenticationSetup.header')}</StyledPageTitle>

      <TableFormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container>
            <Grid container rowGap={1}>
              {isOnlineLicence() && (
                <Grid item columnSpacing={2} rowGap={3} container>
                  <Grid item xs={12}>
                    <StyledValueFormHeader>
                      {t('authenticationSetup.twoFactorAuthentication.header')}
                    </StyledValueFormHeader>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Box mb={1}>
                      <FormRadioGroup
                        name="twoFactorAuthentication"
                        label={t('authenticationSetup.twoFactorAuthentication.label')}
                        options={prepareSelectOptions(twoFactorAuthenticationOptions(t))}
                        control={control}
                        errors={errors}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <Box mb={1}>
                      <Typography variant="subtitle2">
                        {t('authenticationSetup.tomePeriods.label')}
                      </Typography>
                      <Grid item container justifyContent="space-between" maxWidth="550px">
                        {trustedDeviceOptions?.map((option) => (
                          <FormCheckbox
                            key={option.uniqueId}
                            name={`timePeriods[${option.daysToExpire}]`}
                            label={`${option.label}`}
                            control={control}
                            errors={errors}
                          />
                        ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              )}

              <Grid item container columnSpacing={2} rowGap={3}>
                <Grid item xs={12}>
                  <StyledValueFormHeader>
                    {t('authenticationSetup.failedAuthenticationSettings.header')}
                  </StyledValueFormHeader>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box mb={1}>
                    <FormSelect
                      name="numberOfInvalidLoginAttempts"
                      label={`${t('authenticationSetup.numberOfInvalidLoginAttempts.label')}?`}
                      setValue={setValue}
                      options={prepareSelectOptions(numberOfInvalidLoginAttempts)}
                      canBeEmpty={false}
                      control={control}
                      errors={errors}
                    />
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box mb={1}>
                    <FormSelect
                      name="timePeriodOfAccountLockout"
                      label={`${t('authenticationSetup.timePeriodOfAccountLockout.label')}?`}
                      setValue={setValue}
                      options={prepareSelectOptions(timePeriodOfAccountLockout(t))}
                      canBeEmpty={false}
                      control={control}
                      errors={errors}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container columnSpacing={3} rowGap={1} mt={1}>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                id="save-button"
                isLoading={isSubmitLoading}
                variant={EButtonVariants.contained}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={() => navigate(ERouteLinks.SystemSettings)}
              />
            </Grid>
          </Grid>
        </form>
      </TableFormWrapper>

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export default AuthenticationSetupPage;
