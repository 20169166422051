import React, { useEffect, useState } from 'react';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import {
  ChartColorLegend,
  ChartLabelTypography,
  ChartLegendBogItem,
  ChartLegendGridItem,
  ChartNoDataGridItem,
  DashboardChartWrapper,
} from 'pages/DashboardPage/PieCharts/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colors } from 'theme/colors';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EDashboardSortType } from 'models/dashboard';
import { getAncillariesTypeData } from 'requests/dashboard';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface IAncillariesChart {
  sortType: EDashboardSortType;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const AncillariesChart = ({ sortType, setLoader }: IAncillariesChart) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const [typeData, setTypeData] = useState<[number, number, number]>();
  const [totalValue, setTotalValue] = useState(0);
  const { isDesktop, isMobile } = useThemeBreakpoint();
  const [noData, setNoData] = useState(false);
  const [noDataText, setNoDataText] = useState<string>('');

  const [options] = useState({
    rotation: -111,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        color: '#ffffff',
        borderRadius: 3,
        font: {
          size: 18,
          weight: 600,
          lineHeight: 1,
        },
        formatter: (value: number) => (value > 0 ? `${value}%` : ''),
      },
    },
  });

  useEffect(() => {
    (async () => {
      setLoader(true);
      const data = await getAncillariesTypeData(sortType);
      if (data?.chartValues) {
        const chartValues: [number, number, number] = [
          data.chartValues?.[0]?.value || 0,
          data.chartValues?.[1]?.value || 0,
          data.chartValues?.[2]?.value || 0,
        ];
        if (chartValues.every((item) => item === 0)) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setTypeData(chartValues);
        setTotalValue(chartValues.reduce((total, current) => total + current, 0));
      } else {
        setNoData(true);
      }
      setLoader(false);
    })();
  }, [sortType]);

  useEffect(() => {
    switch (sortType) {
      case EDashboardSortType.Day: {
        setNoDataText('dashboard.chart.ancillaries.type.noDataToday');
        break;
      }
      case EDashboardSortType.Week: {
        setNoDataText('dashboard.chart.ancillaries.type.noData7days');
        break;
      }
      case EDashboardSortType.Month: {
        setNoDataText('dashboard.chart.ancillaries.type.noData30days');
        break;
      }
      case EDashboardSortType.Year: {
        setNoDataText('dashboard.chart.ancillaries.type.noDataYear');
        break;
      }
      case EDashboardSortType.All: {
        setNoDataText('dashboard.chart.ancillaries.type.noDataAll');
        break;
      }
      default: {
        setNoDataText('dashboard.chart.ancillaries.legality.noDataAll');
        break;
      }
    }
  }, [sortType]);

  const data =
    typeData?.length && totalValue
      ? {
          datasets: [
            {
              data: [
                Number(((typeData[0] / totalValue) * 100).toFixed(1)),
                Number(((typeData[1] / totalValue) * 100).toFixed(1)),
                Number(((typeData[2] / totalValue) * 100).toFixed(1)),
              ],
              backgroundColor: [colors.success400, colors.primary500, colors.warning500],
              borderColor: [colors.success400, colors.primary500, colors.warning500],
              borderWidth: 1,
            },
          ],
        }
      : undefined;

  const plugins = [
    {
      id: 'custom_canvas_background_color',
      resize: (chart: { height: any }) => {
        const { height } = chart;
        const fontSize = Number((height / 9).toFixed(0));
        options.plugins.datalabels.font = {
          size: fontSize < 13 ? 13 : fontSize,
          weight: 600,
          lineHeight: 1,
        };
      },
    },
  ];

  const chartRender = (
    <Grid
      container
      justifyContent="center"
      sx={{
        height: isDesktop ? 'fit-content' : 'auto',
        flexDirection: !isMobile ? 'row' : 'column-reverse',
      }}
    >
      <Grid
        container
        item
        sm={7}
        xs={12}
        direction="row"
        alignItems="center"
        alignContent="center"
        sx={{
          padding: '10px 10px',
          ...(isMobile && { marginTop: '20px' }),
          ...(isDesktop && { minWidth: '190px' }),
        }}
      >
        <ChartLegendGridItem
          container
          item
          xs={12}
          sx={{ ...(!isMobile ? {} : { justifyContent: 'center' }) }}
        >
          <ChartLegendBogItem>
            <ChartColorLegend color={colors.success500} />
            <ChartLabelTypography variant="body2">
              {t('dashboard.chart.ancillaries.accessories')}
              <Typography variant="subtitle2" sx={{ display: 'inline', paddingLeft: 1.5 }}>
                {typeData?.[0]}
              </Typography>
            </ChartLabelTypography>
          </ChartLegendBogItem>
        </ChartLegendGridItem>

        <ChartLegendGridItem
          container
          item
          xs={12}
          sx={{ ...(!isMobile ? {} : { justifyContent: 'center' }) }}
        >
          <ChartLegendBogItem>
            <ChartColorLegend color={colors.primary500} />
            <Typography variant="body2">
              {t('dashboard.chart.ancillaries.ammunition')}
              <Typography variant="subtitle2" sx={{ display: 'inline', paddingLeft: 1.5 }}>
                {typeData?.[1]}
              </Typography>
            </Typography>
          </ChartLegendBogItem>
        </ChartLegendGridItem>

        <ChartLegendGridItem
          container
          item
          xs={12}
          sx={{ ...(!isMobile ? {} : { justifyContent: 'center' }) }}
        >
          <ChartLegendBogItem>
            <ChartColorLegend color={colors.warning600} />
            <Typography variant="body2">
              {t('dashboard.chart.ancillaries.components')}
              <Typography variant="subtitle2" sx={{ display: 'inline', paddingLeft: 1.5 }}>
                {typeData?.[2]}
              </Typography>
            </Typography>
          </ChartLegendBogItem>
        </ChartLegendGridItem>

        <ChartLegendGridItem
          item
          xs={12}
          sx={{ ...(!isMobile ? {} : { justifyContent: 'center', textAlign: 'center' }) }}
        >
          <Typography variant="body2">
            {t('dashboard.chart.ancillaries.total').toUpperCase()}
            <Typography variant="subtitle2" sx={{ display: 'inline', paddingLeft: 1.5 }}>
              {totalValue}
            </Typography>
          </Typography>
        </ChartLegendGridItem>
      </Grid>
      <Grid
        item
        sm={5}
        xs={12}
        sx={{
          ...(!isMobile
            ? { display: 'block', alignSelf: 'center' }
            : { display: 'flex', justifyContent: 'center' }),
        }}
      >
        <Box sx={{ ...(isMobile ? { width: '40vw' } : { width: '100%', height: '100%' }) }}>
          {data && (
            <Pie
              data={data}
              options={options}
              plugins={plugins}
              style={{
                ...(isDesktop
                  ? { minWidth: '10vw', minHeight: '10vw' }
                  : { minWidth: '20vw', minHeight: '20vw' }),
                ...(isDesktop ? { maxHeight: '300px', maxWidth: '300px' } : {}),
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );

  const noDataRender = (
    <ChartNoDataGridItem container item xs={12}>
      <Typography variant="body2">{t(noDataText)}</Typography>
    </ChartNoDataGridItem>
  );

  return (
    <DashboardChartWrapper>
      <Typography variant="subtitle1" sx={{ textAlign: 'center', marginBottom: 2 }}>
        {t('dashboard.chart.ancillaries.label')}
      </Typography>
      {noData ? noDataRender : chartRender}
      <Box
        sx={{
          ...(!isMobile ? { padding: '20px 10px 0px 10px' } : { padding: '5px 10px 0 10px' }),
        }}
      >
        <Button
          variant={EButtonVariants.outlined}
          fullWidth={true}
          label={t('dashboard.chart.ancillaries.seeAncillaries')}
          onClick={() => navigate(ERouteLinks.Ancillaries)}
          size={EButtonSizes.small}
        />
      </Box>
    </DashboardChartWrapper>
  );
};

export default AncillariesChart;
