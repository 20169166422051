import { ICustomFieldByObjectType } from 'models/customField';

export const getColumnTitleFromCustomField = (
  customField: ICustomFieldByObjectType,
  selectedLanguage: string | undefined,
) => {
  if (selectedLanguage === undefined) {
    return customField.name;
  }
  const foundTranslation = customField.translations.find(
    (translation) => translation.languageUniqueId === selectedLanguage,
  );
  if (foundTranslation === undefined) {
    return customField.name;
  }
  return foundTranslation.name;
};
