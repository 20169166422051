import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/auth';
import { AuthContext } from 'models/authContext';
import {
  changeAccessToken,
  clearAuthErrors,
  getUser,
  logIn,
  logOut,
  verifyAuthCode,
} from 'requests/auth';
import { IAuthBodyRequest, IAuthResponse, IVerifyAuthCodeBodyRequest } from 'models/auth';

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      ...state,
      logIn: (body: IAuthBodyRequest, successCallback?: (res: IAuthResponse) => void) =>
        logIn(dispatch, body, (res) => successCallback && successCallback(res)),
      logOut: (successCallback: () => void) => logOut(dispatch, () => successCallback()),
      changeAccessToken: (accessToken: any) => changeAccessToken(dispatch, accessToken),
      verifyAuthCode: (
        body: IVerifyAuthCodeBodyRequest,
        successCallback: () => void,
        failureCallback: (res: string) => void,
      ) =>
        verifyAuthCode(
          dispatch,
          body,
          () => successCallback(),
          (res) => failureCallback(res),
        ),
      clearAuthErrors: () => clearAuthErrors(dispatch),
      getUser: () => getUser(dispatch),
    }),
    [state],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
