import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { Loader } from 'components/atoms/Loader';
import { ILegalEntityData, LegalEntityDetails } from 'components/organisms/LegalEntityDetails';
import { EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import {
  IOrgLegalEntityData,
  OrgLegalEntityDetails,
} from 'components/organisms/OrgLegalEntityDetails';
import { StyledDetailsWrapper } from 'pages/UsersPage/AssignLEPage/styles';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import { useAccount } from 'pages/AccountPage/hooks';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { IAssignLE } from 'models/legalEntity';
import { useTranslations } from 'hooks/useTranslations';
import { ITranslationKey } from 'components/molecules/Table';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

export enum EAssignedLETemplateTypes {
  create = 'create',
  delete = 'delete',
}

interface IAssignedLETemplate {
  type: EAssignedLETemplateTypes;
  onSubmit: (arg: IAssignLE) => any;
  translationsKeys?: ITranslationKey | any;
}

interface IAssignedLETemplateContent {
  title: string;
  subtitle: string;
}

const AssignedLETemplate = ({
  type: templateType,
  onSubmit,
  translationsKeys,
}: IAssignedLETemplate) => {
  const { LEId: id = '', id: userId = '' } = useParams<{ LEId: string; id: string }>();
  const { setAlert, samePageAlert, clearAlert } = useAlert();
  const query = useQuery();
  const navigate = useNavigate();
  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [isLegalEntityLoading, setIsLegalEntityLoading] = useState<boolean>(false);
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const [isOrgLegalEntityLoading, setIsOrgLegalEntityLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isAccountLoading, setIsAccountLoading] = useState<boolean>(false);
  const templateContent = useRef<IAssignedLETemplateContent>({ title: '', subtitle: '' });

  const { t } = useTranslations();
  const type: string = query.get('type') || '';

  useEffect(() => {
    if (type === ELegalEntityTypes.individual) {
      (async function getLegalEntityDataInit() {
        setIsLegalEntityLoading(true);
        if (id) {
          const response = await getLegalEntity(id);
          if (response) {
            setLegalEntity(response);
          }
          setIsLegalEntityLoading(false);
        }
      })();
    } else if (type === ELegalEntityTypes.organisation) {
      (async function getLegalEntityDataInit() {
        setIsOrgLegalEntityLoading(true);
        if (id) {
          const response = await getOrgLegalEntity(id);
          if (response) {
            setOrgLegalEntity(response);
          }
          setIsOrgLegalEntityLoading(false);
        }
      })();
    }
  }, [id]);

  const { account, getAccountData } = useAccount();

  useEffect(() => {
    (async function getAccountDataInit() {
      if (userId) {
        setIsAccountLoading(true);
        await getAccountData(userId);
        setIsAccountLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    (async function getAccountDataInit() {
      if (templateType === EAssignedLETemplateTypes.create) {
        templateContent.current = {
          title: t(translationsKeys.assignLegalEntityHeader),
          subtitle: `${t(translationsKeys.assignLegalEntityInfo)}:`,
        };
      } else if (templateType === EAssignedLETemplateTypes.delete) {
        templateContent.current = {
          title: t(translationsKeys.deleteLegalEntityHeader),
          subtitle: t(translationsKeys.deleteLegalEntityInfo),
        };
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateType]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleOnSubmit = async () => {
    setIsSubmitLoading(true);
    if (id) {
      try {
        const result =
          onSubmit &&
          (await onSubmit({
            legalUniqueId: id,
            accountId: userId,
          }));
        if (result) {
          navigate(ERouteLinks.ViewUser.replace(':id', userId));
        }
      } catch (e: any) {
        await setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          variant: EAlertVariants.warning,
          text: t(translationsKeys.assignLegalEntityError),
        });
      }
    }

    setIsSubmitLoading(false);
  };
  const { title, subtitle } = templateContent.current;
  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('user.breadcrumbs.administration'), route: ERouteLinks.Administration },
          { label: t('user.breadcrumbs.users'), route: ERouteLinks.Users },
          {
            label: t('user.breadcrumbs.userDetails'),
            route: ERouteLinks.ViewUser.replace(':id', userId),
          },
          title,
        ]}
      />
      <StyledPageTitle variant="h4">{title}</StyledPageTitle>
      <Typography variant="body1" my={3}>
        {subtitle}
      </Typography>
      {type === ELegalEntityTypes.individual && (
        <div>
          {isLegalEntityLoading ? (
            <Loader isLoading={isLegalEntityLoading} />
          ) : (
            legalEntity && (
              <LegalEntityDetails
                legalEntity={legalEntity}
                pageType={EDetailsPageTypes.delete}
              />
            )
          )}
        </div>
      )}
      {type === ELegalEntityTypes.organisation && (
        <div>
          {isOrgLegalEntityLoading ? (
            <Loader isLoading={isOrgLegalEntityLoading} />
          ) : (
            orgLegalEntity && (
              <OrgLegalEntityDetails
                legalEntity={orgLegalEntity}
                pageType={EDetailsPageTypes.delete}
              />
            )
          )}
        </div>
      )}
      {templateType === EAssignedLETemplateTypes.create && (
        <Typography variant="body1" mt={4}>
          {t(translationsKeys.assignLegalEntityInfo2)}:
        </Typography>
      )}
      {account && !isAccountLoading ? (
        <StyledDetailsWrapper container direction="column" gap={3} mt={2}>
          <Grid item>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              User
            </Typography>
            <Typography variant="body2">{account.email}</Typography>
          </Grid>
        </StyledDetailsWrapper>
      ) : (
        <Loader isLoading={isAccountLoading} />
      )}
      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
      {!isLegalEntityLoading && !isOrgLegalEntityLoading && !isAccountLoading && (
        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              fullWidth
              label={t('general.confirmation.yes.button')}
              id="yes-button"
              variant={EButtonVariants.contained}
              sx={{ mr: 1 }}
              onClick={async () => handleOnSubmit()}
              isLoading={isSubmitLoading}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Button
              fullWidth
              label={t('general.confirmation.no.button')}
              id="no-button"
              variant={EButtonVariants.outlined}
              onClick={() => navigate(ERouteLinks.ViewUser.replace(':id', userId))}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { AssignedLETemplate };
