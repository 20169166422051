import { useEffect } from 'react';

interface IScrollToTop {
  history: any;
}
const ScrollToTop = ({ history }: IScrollToTop) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default ScrollToTop;
