import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ISearchResultAccessoryTableCard {
  data: ITableDataSource;
  handleView: (args: string) => void;
  renderHighlight: (arg: string) => '-' | JSX.Element;
}

const SearchResultAccessoryTableCard = ({
  data,
  handleView,
  renderHighlight,
}: ISearchResultAccessoryTableCard) => {
  const { t } = useTranslations();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('accessoriesTable.accordion.firecycleId.column')}
        </Typography>
        <Typography variant="body2">{renderHighlight(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('accessoriesTable.accordion.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{renderHighlight(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('accessoriesTable.accordion.accessoryType.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.accessoryType)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('accessoriesTable.accordion.manufacturer.column')}
        </Typography>
        <Typography variant="body2">{renderHighlight(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('accessoriesTable.accordion.owner.column')}
        </Typography>
        <Typography variant="body2">{renderHighlight(data.owner)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('accessoriesTable.accordion.keeper.column')}
        </Typography>
        <Typography variant="body2">{renderHighlight(data.keeper)}</Typography>
      </Grid>

      {handleView && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
          }}
          p={2}
        >
          <>
            <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
              <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
                {t('general.actions.text')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Button
                label={t('general.details.text')}
                variant={EButtonVariants.outlined}
                onClick={() => handleView(data.id as string)}
                size="small"
                fullWidth
              />
            </Grid>
          </>
        </Grid>
      )}
    </Grid>
  );
};

export default SearchResultAccessoryTableCard;
