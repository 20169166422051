import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { FRTCalibreForm } from 'pages/FRT/FRTCalibreForm';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { IFRTCalibreFormValues } from 'models/frt';
import { useFRT } from 'pages/FRT/hooks';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { isFRTLocal } from 'pages/FRT/helpers';
import { useTranslations } from 'hooks/useTranslations';

const FRTEditCalibrePage = () => {
  const { frtId = '', specificationId = '' } = useParams<{
    frtId: string;
    specificationId: string;
  }>();
  const [FRTSpecification, setFRTSpecification] = useState<IFRTCalibreFormValues>();
  const navigate = useNavigate();
  const { setAlert } = useAlert();
  const { getFRTSpecificationsData, FRTSpecifications, handleUpdate } = useFRT();
  const isLocal: boolean = isFRTLocal(frtId);
  const { t } = useTranslations();

  useEffect(() => {
    (async function getFRTSpecificationInit() {
      await getFRTSpecificationsData(frtId);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frtId]);

  useEffect(() => {
    if (FRTSpecifications) {
      const specification = FRTSpecifications.find((spec) => spec.id === specificationId);
      if (specification) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { id, specificationId, ...specificationData } = specification;
        setFRTSpecification(specificationData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FRTSpecifications]);

  const handleSubmit = async (data: IFRTCalibreFormValues) => {
    try {
      await handleUpdate(frtId, specificationId, data);
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('frtCalibre.edit.success.message'),
        variant: EAlertVariants.success,
      });
      navigate(`${ERouteLinks.FRTDetails.replace(':frtId', frtId)}?expand=calibre`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: 'Firearms', route: ERouteLinks.Firearms },
          { label: 'Firearms Reference Table', route: `${ERouteLinks.Firearms}?expand=frt` },
          {
            label: isLocal
              ? t('firearmsReferenceTable.editFirearm.viewLocalFirearm.header')
              : t('firearmsReferenceTable.editFirearm.viewFirearm.header'),
            route: ERouteLinks.FRTDetails.replace(':frtId', frtId),
          },
          'Edit Calibre',
        ]}
      />
      <Box sx={{ mb: 2 }}>
        <StyledPageTitle variant="h4">Edit Calibre</StyledPageTitle>
      </Box>

      <TableFormWrapper>
        <FRTCalibreForm
          onCancel={() => navigate(ERouteLinks.FRTDetails.replace(':frtId', frtId))}
          onSubmit={handleSubmit}
          initialFormValues={FRTSpecification}
        />
      </TableFormWrapper>
    </>
  );
};

export { FRTEditCalibrePage };
