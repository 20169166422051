import { IApiClientResponse, isResponseError, TResponse } from 'models/apiClient';
import { apiClient } from 'utils/apiClient';
import { EActionTypes } from 'state/actions/auth';
import {
  IAuthBodyRequest,
  IAuthResponse,
  IDecodedAccessToken,
  IForgotPasswordRecaptcha,
  IRefreshAuthTokenRequest,
  IRefreshAuthTokenResponse,
  IVerifyAuthCodeBodyRequest,
} from 'models/auth';
import { ApiEndpoints } from 'models/apiEndpoints';
import { handleRestApiException } from 'utils/handleRestApiException';
import { EAlertVariants } from 'components/atoms/Alert';
import jwt_decode from 'jwt-decode';

export const FC_SYSTEM_AUTH_ACCESS_TOKEN = 'FC_SYSTEM_AUTH_ACCESS_TOKEN';
export const FC_SYSTEM_AUTH_REFRESH_TOKEN = 'FC_SYSTEM_AUTH_REFRESH_TOKEN';

const loginRequest = async (body: IAuthBodyRequest): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.post<TResponse<IAuthResponse>>(
      ApiEndpoints.LOG_IN(),
      body,
      {
        headers: {
          'Accept-Language': 'en',
        },
      },
    );
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const getUser = async (dispatch: any) => {
  const authAccessToken: string | null = localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
  if (authAccessToken !== null) {
    const {
      jti,
      sub,
      Permissions,
      prefixGroupIds,
      'First name': firstName,
      'Last name': lastName,
    }: IDecodedAccessToken = jwt_decode(authAccessToken);

    let permissions: string[] = [];
    if (Permissions) {
      permissions = Permissions.split(',');
    }

    dispatch({
      type: EActionTypes.CHANGE_USER,
      payload: {
        userId: jti,
        email: sub,
        permissions,
        prefixGroupIds,
        firstName,
        lastName,
      },
    });
  }
};

export const logIn = async (
  dispatch: any,
  body: IAuthBodyRequest,
  successCallback: (data: any) => void,
): Promise<TResponse<IAuthResponse>> => {
  dispatch({
    type: EActionTypes.LOG_IN,
  });

  const res = await loginRequest(body);
  try {
    if (res.success) {
      const { accessToken, refreshToken } = res.data;
      const payload = accessToken
        ? { accessToken, refreshToken }
        : { accessToken: null, refreshToken: null };
      dispatch({
        type: EActionTypes.LOG_IN_SUCCESS,
        payload,
      });
      if (accessToken) {
        localStorage.setItem(FC_SYSTEM_AUTH_ACCESS_TOKEN, accessToken);
        localStorage.setItem(FC_SYSTEM_AUTH_REFRESH_TOKEN, refreshToken);
        await getUser(dispatch);
      }
      successCallback(res.data);
    } else {
      dispatch({
        type: EActionTypes.LOG_IN_FAILURE,
        payload: { authMessage: { text: res.responseError, variant: EAlertVariants.error } },
      });
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

const loginOutRequest = async (): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.post<TResponse<null>>(ApiEndpoints.LOG_OUT());
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const logOut = async (
  dispatch: any,
  successCallback: () => void,
): Promise<TResponse<null>> => {
  const res = await loginOutRequest();
  try {
    if (res.success) {
      localStorage.removeItem(FC_SYSTEM_AUTH_ACCESS_TOKEN);
      localStorage.removeItem(FC_SYSTEM_AUTH_REFRESH_TOKEN);
      successCallback();
      dispatch({
        type: EActionTypes.LOG_OUT_SUCCESS,
        payload: {
          authMessage: {
            text: 'You were successfully logged out.',
            variant: EAlertVariants.success,
          },
        },
      });
    } else {
      dispatch({
        type: EActionTypes.LOG_OUT_FAILURE,
      });
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

export const changeAccessToken = async (dispatch: any, accessToken: string | null) => {
  dispatch({
    type: EActionTypes.CHANGE_ACCESS_TOKEN,
    payload: { accessToken },
  });
};

const verifyAuthCodeRequest = async (
  body: IVerifyAuthCodeBodyRequest,
): Promise<IApiClientResponse> => {
  try {
    const { data } = await apiClient.post<TResponse<IAuthResponse>>(
      ApiEndpoints.VERIFY_AUTH_CODE(),
      body,
    );
    return {
      success: true,
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      data: null,
      responseError: handleRestApiException(e),
    };
  }
};

export const verifyAuthCode = async (
  dispatch: any,
  body: IVerifyAuthCodeBodyRequest,
  successCallback: () => void,
  failureCallback: (error: any) => void,
): Promise<TResponse<IAuthResponse>> => {
  dispatch({
    type: EActionTypes.LOG_IN,
  });

  const res = await verifyAuthCodeRequest(body);

  try {
    if (res.success) {
      const { accessToken, refreshToken } = res.data;
      dispatch({
        type: EActionTypes.LOG_IN_SUCCESS,
        payload: { accessToken, refreshToken },
      });
      localStorage.setItem(FC_SYSTEM_AUTH_ACCESS_TOKEN, accessToken);
      localStorage.setItem(FC_SYSTEM_AUTH_REFRESH_TOKEN, refreshToken);
      await getUser(dispatch);
      successCallback();
    } else {
      dispatch({
        type: EActionTypes.CLEAR_AUTH_ERRORS,
      });
      dispatch({
        type: EActionTypes.LOG_IN_FAILURE,
      });
      failureCallback(res.responseError);
    }
  } catch (e) {
    console.error(e);
  }
  return res.data;
};

export const clearAuthErrors = async (dispatch: any) => {
  dispatch({
    type: EActionTypes.CLEAR_AUTH_ERRORS,
  });
};

export const changeForgotPasswordRecaptcha = async (token: string | null) => {
  const { data } = await apiClient.get<TResponse<IForgotPasswordRecaptcha>>(
    ApiEndpoints.CHANGE_FORGOT_PASSWORD_RECAPTCHA(`?gRecaptchaResponse=${token}`),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export const refreshAuthToken = async (refreshToken: IRefreshAuthTokenRequest) => {
  const { data } = await apiClient.post<TResponse<IRefreshAuthTokenResponse>>(
    ApiEndpoints.REFRESH_AUTH_TOKEN(),
    refreshToken,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};
