import React, { useEffect, useState } from 'react';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from 'requests/user';
import { IUser } from 'models/user';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useDictionary } from 'models/dictionaryContext';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { EButtonVariants } from 'constants/Buttons';
import { Accordion } from 'components/molecules/Accordion';
import { Loader } from 'components/atoms/Loader';
import { Table } from 'components/molecules/Table';
import { CardList } from 'components/molecules/CardList';
import { AssignedLETableCard } from 'pages/UsersPage/AssignedLETableCard';

import {
  assignedLEsTableColumns,
  EUserStatusActivated,
  mapAssignedLEsToDataSource,
} from 'pages/UsersPage/helpers';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import Button from 'components/atoms/Button';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';
import { getRoles } from 'requests/role';
import { EPermission } from 'models/permissions';
import { usePermission } from 'hooks/usePermission';
import { useAccount } from 'pages/AccountPage/hooks';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useUsers } from 'pages/UsersPage/hooks';
import { useAuth } from 'models/authContext';

enum EViewUserPageTranslationsKeys {
  editLink = 'userDetails.edit.link',
  deleteLink = 'userDetails.delete.link',
}

export const ViewUserPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [userDetails, setUserDetails] = useState<IUser | null>();
  const [roleNames, setRoleNames] = useState<string[]>([]);
  const [prefixNames, setPrefixNames] = useState<string[]>([]);
  const [userActivated, setUserActivated] = useState<boolean>();
  const [isAssignedLELoading, setIsAssignedLELoading] = useState<boolean>(false);
  const { phoneCode, legalEntityPrefix } = useDictionary();
  const { loadDictionaries } = useUsers();
  const { isDesktop } = useThemeBreakpoint();
  const { getAssignedLegalsToUserData, assignedLegalsToUser } = useAccount();
  const { clearAlert, legalEntityAlert, userAlert } = useAlert();
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const { longDateFormat, getDateFormat } = useGlobalProperty();
  const { user } = useAuth();

  useEffect(() => {
    getDateFormat();
    loadDictionaries();
  }, []);

  useEffect(() => {
    (async function getUserDataInit() {
      if (id) {
        const response = await getUser(id);
        if (response) {
          setUserDetails(response);
          setUserActivated(response.active);
          if (response.roleIds && response.roleIds.length) {
            const responseRoles = await getRoles(1, 100, {}, null);
            if (responseRoles) {
              const roles = responseRoles.content
                .map((role) => response.roleIds.includes(role.uniqueId) && role.name)
                .filter((row) => !!row);
              setRoleNames(roles as string[]);
            }
          }
          if (response.prefixGroupIds && response.prefixGroupIds.length) {
            if (legalEntityPrefix) {
              const names = legalEntityPrefix
                .map(
                  (prefix) =>
                    response.prefixGroupIds.includes(prefix.uniqueId.toString()) &&
                    prefix.name,
                )
                .filter((row) => !!row);
              setPrefixNames(names as string[]);
            }
          }
        }
      }
    })();
  }, [id, legalEntityPrefix]);

  useEffect(() => {
    (async function getAssignedLEsInit() {
      if (id) {
        setIsAssignedLELoading(true);
        await getAssignedLegalsToUserData(id);
        setIsAssignedLELoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_LEGAL_ENTITY_ALERT);
      clearAlert(ActionTypes.CLEAR_USER_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const parsedPhoneCodeDictionary: IParsedDictionary = parseDictionary(phoneCode, 'name');

  const detailsTemplateProps = () => {
    // todo
    if (hasPermissions([EPermission.MANAGE_USERS])) {
      return {
        redirectToEditView: () => navigate(`${ERouteLinks.EditUser.replace(':id', id)}`),
        changeActivationUserStatus: () => {
          if (userDetails) {
            navigate(
              `${ERouteLinks.EditUserStatus.replace(':id', id).replace(
                ':status',
                userActivated
                  ? EUserStatusActivated.deactivate
                  : EUserStatusActivated.activate,
              )}`,
            );
          }
        },
        handleDelete: !userDetails?.removed
          ? () => {
              if (userDetails) {
                navigate(
                  `${ERouteLinks.DeleteUser.replace(':id', id).replace(
                    ':status',
                    userActivated
                      ? EUserStatusActivated.deactivate
                      : EUserStatusActivated.activate,
                  )}`,
                );
              }
            }
          : undefined,
        unbanUserStatus: () => {
          if (userDetails) {
            navigate(
              `${ERouteLinks.EditUserStatus.replace(':id', id).replace(
                ':status',
                userActivated
                  ? EUserStatusActivated.deactivate
                  : EUserStatusActivated.activate,
              )}`,
            );
          }
        },
        redirectToGeneratePasswordView: () =>
          navigate(`${ERouteLinks.GeneratePasswordUser.replace(':id', id)}`),
        redirectToView: () => null,
      };
    }
    return {
      redirectToEditView: () => navigate(`${ERouteLinks.EditUser.replace(':id', id)}`),
      redirectToView: () => null,
    };
  };

  const handleDeleteAssignedLegalFromUser = (LEId: string, type: ELegalEntityTypes) =>
    navigate(
      `${ERouteLinks.DeleteAssignedLE.replace(':id', id).replace(':LEId', LEId)}?type=${type}`,
    );

  const statusChip = () => {
    if (userDetails) {
      if (userDetails.removed) {
        return <Chips text={t('users.removed.label')} variant={EChipsVariants.error200} />;
      }
      if (userDetails.blocked) {
        return <Chips text={t('users.blocked.label')} variant={EChipsVariants.warning100} />;
      }
      return (
        <Chips
          text={userDetails.active ? t('users.active.label') : t('users.inactive.label')}
          variant={userDetails.active ? EChipsVariants.success : EChipsVariants.error}
        />
      );
    }
    return '-';
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('user.breadcrumbs.administration'), route: ERouteLinks.Administration },
          { label: t('user.breadcrumbs.users'), route: ERouteLinks.Users },
          t('user.breadcrumbs.userDetails'),
        ]}
      />
      {userDetails && user && user.userId && (
        <DetailsTemplate
          pageType={EDetailsPageTypes.userView}
          title={userDetails.email}
          {...detailsTemplateProps()}
          userActivated={userActivated}
          userBlocked={userDetails.blocked}
          disableActions={userDetails.removed}
          actionButtonFullRow
          deletePermissions={[EPermission.MANAGE_USERS]}
          translationsKeys={EViewUserPageTranslationsKeys}
          loggedUserIdEqualsViewUserId={userDetails.uniqueId === user.userId}
        >
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.firstName.label')}
            </Typography>
            <Typography variant="body2">{userDetails.firstName}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.lastName.label')}
            </Typography>
            <Typography variant="body2">{userDetails.lastName}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.phoneNumber.label')}
            </Typography>
            <Typography variant="body2">{`${
              userDetails.phoneNumber &&
              parsedPhoneCodeDictionary?.[userDetails.phoneNumber.codeDictionaryUniqueId]
                ? `${
                    parsedPhoneCodeDictionary?.[userDetails.phoneNumber.codeDictionaryUniqueId]
                  }${userDetails.phoneNumber.value}`
                : '-'
            }`}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.lastLogged.label')}
            </Typography>
            <Typography variant="body2">
              {!userDetails.removed
                ? formatDate(userDetails.lastLoginDate, longDateFormat)
                : '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.role.label')}
            </Typography>
            <div>
              {roleNames.length > 0
                ? roleNames.map((roleItem) => (
                    <Chips key={roleItem} text={roleItem} variant={EChipsVariants.primary} />
                  ))
                : '-'}
            </div>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.status.label')}
            </Typography>
            <div>{statusChip()}</div>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userDetails.prefix.label')}
            </Typography>
            <div>
              {prefixNames.map((item) => (
                <Chips key={item} text={item} variant={EChipsVariants.primary} />
              ))}
            </div>
          </Grid>
        </DetailsTemplate>
      )}
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Accordion
          title={t('userDetails.assignedLegalEntity.section')}
          expanded={assignedLegalsToUser.length > 0}
          hideExpandIcon
        >
          <TableTopButtons>
            <Button
              label={t('userDetails.assignLegalEntity.button')}
              variant={EButtonVariants.contained}
              onClick={() => navigate(ERouteLinks.AssignLESearch.replace(':id', id))}
              fullWidth={!isDesktop}
            />
          </TableTopButtons>
          {isAssignedLELoading ? (
            <Loader isLoading={isAssignedLELoading} />
          ) : (
            <div>
              {isDesktop ? (
                <Table
                  columns={assignedLEsTableColumns({
                    handleDelete: (LEId, type) =>
                      handleDeleteAssignedLegalFromUser(LEId, type),
                  })}
                  dataSource={mapAssignedLEsToDataSource(
                    assignedLegalsToUser,
                    hasPermissions([EPermission.VIEW_ALL_LE, EPermission.VIEW_LE], true),
                  )}
                  translationsKeys={{
                    noResults: 'userDetails.assignedLegalEntitiesList.noResults.info',
                  }}
                />
              ) : (
                <CardList
                  items={mapAssignedLEsToDataSource(
                    assignedLegalsToUser,
                    hasPermissions([EPermission.VIEW_ALL_LE, EPermission.VIEW_LE], true),
                  )}
                  render={(data, index) => (
                    <Box mb={2}>
                      <AssignedLETableCard
                        data={data}
                        key={index}
                        handleDelete={() =>
                          handleDeleteAssignedLegalFromUser(data.id, data.type)
                        }
                      />
                    </Box>
                  )}
                />
              )}
            </div>
          )}
        </Accordion>
      </Grid>
      {legalEntityAlert && (
        <Box mt={2}>
          <Alert text={legalEntityAlert.text} variant={legalEntityAlert.variant} />
        </Box>
      )}
      {userAlert && (
        <Box mt={2}>
          <Alert text={userAlert.text} variant={userAlert.variant} />
        </Box>
      )}
    </>
  );
};
