import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useTranslations } from 'hooks/useTranslations';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { IAmmunitionTransactionsFormValues } from 'models/ancillaries';
import { useLang } from 'models/langContext';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { FormSelect } from 'components/molecules/FormSelect';
import { prepareFilters } from 'helpers/filters';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { formatDateTimeToIso } from 'utils/date';

const initialFormValues: IAmmunitionTransactionsFormValues = {
  stateUniqueId: '',
  transactionDateFrom: '',
  transactionDateTo: '',
};

enum EAmmunitionTransactionsLabel {
  stateUniqueId = 'ammunitionDetails.transactions.state.label',
  transactionDateFrom = 'ammunitionDetails.transactions.transactionDateFrom.label',
  transactionDateTo = 'ammunitionDetails.transactions.transactionDateTo.label',
}

interface IAmmunitionTransactionsSearchForm {
  onSubmit: (arg: IAmmunitionTransactionsFormValues) => void;
}

const AmmunitionTransactionsSearchForm = ({ onSubmit }: IAmmunitionTransactionsSearchForm) => {
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IAmmunitionTransactionsFormValues>({
    defaultValues: initialFormValues,
  });
  const [values, setValues] = useState<IAmmunitionTransactionsFormValues>(initialFormValues);
  const { selectedLanguage } = useLang();

  const { loadDictionaries, state } = useAncillaries();

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as IAmmunitionTransactionsFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.State]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  const submitForm = (data: IAmmunitionTransactionsFormValues) => {
    if (data.transactionDateFrom) {
      data.transactionDateFrom = formatDateTimeToIso(data.transactionDateFrom);
    }
    if (data.transactionDateTo) {
      data.transactionDateTo = formatDateTimeToIso(data.transactionDateTo, true);
    }
    onSubmit(data);
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid container justifyContent="flex-start" columnSpacing={2}>
          <Grid item md={3} sm={12} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(state)}
              name="stateUniqueId"
              label={t(EAmmunitionTransactionsLabel.stateUniqueId)}
              canBeEmpty
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <FormDatePicker
              name="transactionDateFrom"
              label={t(EAmmunitionTransactionsLabel.transactionDateFrom)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <FormDatePicker
              name="transactionDateTo"
              label={t(EAmmunitionTransactionsLabel.transactionDateTo)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item md={3} sm={12} xs={12} style={{ margin: 'auto' }}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant={EButtonVariants.contained}
                label={t('ancillaries.accordion.search.button')}
                type="submit"
                fullWidth={true}
                size={EButtonSizes.small}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="ammunition_transaction"
        values={values}
        labels={EAmmunitionTransactionsLabel}
        type={ESearchFilterKeys.ancillaries}
        dictionaries={{
          stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
        }}
        handleDelete={handleResetField}
        showSaveFilters={false}
        saveRouterStateAfterClearAll={false}
      />
      <Box sx={{ mt: 2 }} />
    </SearchWrapper>
  );
};

export { AmmunitionTransactionsSearchForm };
