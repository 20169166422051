import React, { ReactElement, useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormInput } from 'components/molecules/FormInput';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { FormSelect } from 'components/molecules/FormSelect';
import { initialExampleFormValues } from 'models/form';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { FormSwitch } from 'components/molecules/FormSwitch';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { DetailsTemplate } from 'components/templates/DetailsTemplate';
import { IndividualSearchForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { TableTopButtons } from 'components/atoms/TableTopButtons';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { legalEntitiesTableColumns, mapLEToDataSource } from 'pages/LegalEntitiesPage/helpers';
import { Accordion } from 'components/molecules/Accordion';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useNavigate } from 'react-router-dom';
import { EPerPages, ISortOptions } from 'models/table';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { Loader } from 'components/atoms/Loader';
import { LocationMap } from 'components/organisms/LocationMap';
import { ISearchFormValues as ISearchFormOLEValues } from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { ELEAccordionsPageTypes } from 'components/organisms/LEAccordions';
import { useLang } from 'models/langContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { usePermission } from 'hooks/usePermission';

const Spacer = styled.div`
  height: 20px;
`;

const StyledFormFields = styled.span`
  overflow-wrap: break-word;
`;

const schema = yup
  .object({
    input: yup.string().required().trim(),
    textarea: yup.string().required().trim(),
    checkbox: yup.boolean().oneOf([true]),
    switch: yup.boolean().oneOf([true]),
    select: yup.string().required().trim(),
    radioGroup: yup.string().required().trim(),
    datepicker: yup.string().required().nullable().trim(),
  })
  .required();

const radioGroupOptions = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

const selectOptions = [
  {
    label: '1',
    value: 'one',
  },
  {
    label: '2',
    value: 'two',
  },
];

const StyleGuidePage = (): ReactElement => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: initialExampleFormValues, resolver: yupResolver(schema) });

  const [currentPage] = useState<number>(1);
  const [perPage] = useState<EPerPages>(EPerPages.perPage25);
  const [formFields, setFormFields] = useState({});
  const [query, setQuery] = useState<Partial<ISearchFormOLEValues>>({});
  const [LEIndividualSort, setLEIndividualSort] = useState<ISortOptions | null>(null);
  const { legalEntities, getLegalEntitiesData } = useLegalEntities();
  const navigate = useNavigate();
  const { selectedLanguage } = useLang();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();
  const { hasPermissions } = usePermission();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    const subscription = watch(() => setFormFields(getValues()));
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    (async function getLegalEntitiesDataInit() {
      await getLegalEntitiesData(currentPage, perPage, LEIndividualSort, query);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPage, query]);

  const onSubmit = handleSubmit(() => null);

  return (
    <Container>
      <h2>Style Guide</h2>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h2>Buttons</h2>
          <Container>
            <Button
              label="Save"
              variant={EButtonVariants.contained}
              icon={EIconTypes.transactions}
            />
            <Spacer />
            <Button
              label="Button label"
              variant={EButtonVariants.outlined}
              icon={EIconTypes.transactions}
            />
            <Spacer />
            <Button
              label="Button label"
              variant={EButtonVariants.text}
              icon={EIconTypes.transactions}
            />
            <Spacer />
            <Button
              label="Button label"
              variant={EButtonVariants.outlined}
              isLoading
              sx={{ width: '187px' }}
            />
          </Container>
          <Spacer />
          <Spacer />
          <Spacer />
          <Container>
            <Button
              label="Button label"
              variant={EButtonVariants.contained}
              disabled={true}
              icon={EIconTypes.transactions}
            />
            <Spacer />
            <Button
              label="Button label"
              variant={EButtonVariants.outlined}
              disabled={true}
              icon={EIconTypes.transactions}
            />
            <Spacer />
            <Button
              label="Button label"
              variant={EButtonVariants.text}
              disabled={true}
              icon={EIconTypes.transactions}
            />
          </Container>
          <Spacer />
          <Spacer />
          <Spacer />
          <Container>
            <Button
              label="Button label"
              variant={EButtonVariants.contained}
              icon={EIconTypes.transactions}
              size={EButtonSizes.small}
            />
            <Spacer />
            <Button
              label="Button label"
              variant={EButtonVariants.outlined}
              icon={EIconTypes.transactions}
              size={EButtonSizes.small}
            />
            <Spacer />
            <Button
              label="Button label"
              variant={EButtonVariants.text}
              icon={EIconTypes.transactions}
              size={EButtonSizes.small}
            />
            <Spacer />
            <h2>Icon buttons</h2>
            <Container>
              <Button
                variant={EButtonVariants.contained}
                icon={EIconTypes.transactions}
                sizePX={EButtonSizesPX.px32}
              />
              <Spacer />
              <Button
                variant={EButtonVariants.outlined}
                icon={EIconTypes.transactions}
                sizePX={EButtonSizesPX.px24}
              />
              <Button
                variant={EButtonVariants.outlined}
                icon={EIconTypes.transactions}
                sizePX={EButtonSizesPX.px32}
              />
              <Button
                variant={EButtonVariants.outlined}
                icon={EIconTypes.transactions}
                sizePX={EButtonSizesPX.px40}
              />
              <Button
                variant={EButtonVariants.outlined}
                icon={EIconTypes.transactions}
                sizePX={EButtonSizesPX.px48}
              />
              <Spacer />
              <Button
                variant={EButtonVariants.outlined}
                icon={EIconTypes.transactions}
                tableButton={true}
              />
            </Container>
            <h2>Alerts</h2>
            <Alert text="Success" />
            <Spacer />
            <Alert text="Warning" variant={EAlertVariants.warning} />
            <Spacer />
            <Alert text="Error" variant={EAlertVariants.error} />
            <Spacer />
            <h2>Details</h2>
            <DetailsTemplate
              title="Details"
              redirectToEditView={() => null}
              redirectToView={() => null}
            >
              <Grid item xs={6} md={3} direction="row">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Date of Birth
                </Typography>
                <Typography variant="body2">-</Typography>
              </Grid>
              <Grid item xs={6} md={3} direction="row">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Gender
                </Typography>
                <Typography variant="body2">-</Typography>
              </Grid>
              <Grid item xs={6} md={3} direction="row">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  Visibility
                </Typography>
                <Typography variant="body2">-</Typography>
              </Grid>
            </DetailsTemplate>
            <Spacer />
            <h2>Accordion</h2>
            <Accordion title="Individual">
              <IndividualSearchForm
                onSubmit={setQuery}
                type={ELEAccordionsPageTypes.LegalEntity}
                saveRouterStateAfterClearAll={false}
              />
              <TableTopButtons>
                <Button label="Export" variant={EButtonVariants.outlined} onClick={() => {}} />
                <Button
                  label="Add Legal Entity"
                  variant={EButtonVariants.contained}
                  icon={EIconTypes.plus}
                  onClick={() => navigate(ERouteLinks.AddLegalEntity)}
                  sx={{ ml: 1 }}
                />
              </TableTopButtons>

              <Table
                columns={legalEntitiesTableColumns(
                  {
                    handleEdit: (id: string) =>
                      navigate(ERouteLinks.EditLegalEntity.replace(':id', id)),
                    handleView: (id: string) =>
                      navigate(ERouteLinks.ViewLegalEntity.replace(':id', id)),
                    handleDelete: (id: string) =>
                      navigate(ERouteLinks.DeleteLegalEntity.replace(':id', id)),
                    type: ELEAccordionsPageTypes.LegalEntity,
                  },
                  selectedLanguage?.uniqueId,
                  shortDateFormat,
                  hasPermissions,
                )}
                dataSource={mapLEToDataSource(legalEntities)}
                onSort={setLEIndividualSort}
              />
            </Accordion>
            <Spacer />
            <h2>Chips</h2>
            <Chips text="Chips" variant={EChipsVariants.primary} />
            <Spacer />
            <Chips text="Chips" variant={EChipsVariants.success} />
            <Spacer />
            <Chips text="Chips" variant={EChipsVariants.warning} />
            <Spacer />
            <Chips text="Chips" variant={EChipsVariants.error} />
            <Spacer />
          </Container>
        </Grid>
        <Grid item xs={6}>
          <Container>
            <h2>Example form with validation</h2>
            <FormInput
              name="input"
              label="Input"
              placeholder="Placeholder"
              control={control}
              setValue={setValue}
              errors={errors}
            />
            <FormInput
              name="textarea"
              label="Textarea"
              placeholder="Placeholder"
              setValue={setValue}
              multiline
              minRows={5}
              control={control}
              errors={errors}
            />
            <FormCheckbox name="checkbox" label="Checkbox" control={control} errors={errors} />
            <FormSwitch name="switch" label="Switch" control={control} errors={errors} />
            <FormRadioGroup
              name="radioGroup"
              label="Radio group"
              options={radioGroupOptions}
              control={control}
              errors={errors}
            />
            <FormSelect
              name="select"
              label="Select"
              setValue={setValue}
              options={selectOptions}
              control={control}
              errors={errors}
            />
            <Spacer />
            <FormDatePicker
              name="datepicker"
              label="Datepicker"
              control={control}
              errors={errors}
            />
            <Spacer />
            <StyledFormFields>{JSON.stringify(formFields)}</StyledFormFields>
            <Spacer />

            <Button
              label="Validate form"
              onClick={() => onSubmit()}
              variant={EButtonVariants.contained}
            />
            <Spacer />
            <Button
              label="Reset validation"
              onClick={() => reset()}
              variant={EButtonVariants.contained}
            />
            <Spacer />
          </Container>
          <Spacer />
          <h2>Loader</h2>
          <Loader isLoading />
          <Spacer />
          <h2>Map</h2>
          <LocationMap
            coords={[52.418056, 19.941111]}
            dimensions={{ width: '100%', height: '400px' }}
            isOnline={true}
          />
          <Spacer />
        </Grid>
      </Grid>
    </Container>
  );
};

export { StyleGuidePage };
