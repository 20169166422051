import { createContext, useContext } from 'react';

export interface INotificationCounterContext {
  counter: number;
  setCounter: (counter: number) => void;
}

export const NotificationCounterContext = createContext<INotificationCounterContext>(
  {} as INotificationCounterContext,
);

export const useNotificationCounter = (): INotificationCounterContext =>
  useContext(NotificationCounterContext);
