export const mapAncillaryTypeToTranslationKey = (ancillaryType: string | null) => {
  if (!ancillaryType) return '-';

  switch (ancillaryType.toUpperCase()) {
    case 'ACCESSORY': {
      return 'ancillaries.accessories.accordion.name';
    }
    case 'AMMUNITION': {
      return 'ancillaries.ammunition.accordion.name';
    }
    case 'COMPONENT': {
      return 'ancillaries.components.accordion.name';
    }
    default: {
      return '-';
    }
  }
};
