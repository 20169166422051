import React, { ReactElement, useEffect, useState } from 'react';
import { useApiKeys } from 'pages/ApiKeysPage/hooks';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { formatDate, getDateFromISO } from 'utils/date';
import { format } from 'date-fns';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { StyledDivider, StyledViewSectionTitle } from 'pages/ApiKeysPage/styles';
import { apiKeyScopeList, ApiKeysStatusIds, ApiKeyStatus } from 'models/apiKey';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { Alert } from 'components/atoms/Alert';
import Button from 'components/atoms/Button';
import { EIconTypes } from 'constants/Icons';
import { EButtonSizes } from 'constants/Buttons';
import { Table } from 'components/molecules/Table';
import {
  apiKeysHistoryTableColumns,
  mapApiKeysHistoryToDataSource,
} from 'pages/ApiKeysPage/helpers';
import { useRoles } from 'pages/RolesPage/hooks';
import { copyToClipboard } from 'utils/clipboard';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { CardList } from 'components/molecules/CardList';
import ApiKeyHistoryTableCard from 'pages/ApiKeysPage/ApiKeyHistoryTableCard';

const renderScopeName = (scope: string, item: string) => {
  const key = apiKeyScopeList.find((row) => row.value === scope);
  if (key) {
    const itemName = key?.items.find((name) => name.value === `${scope}_${item}`);
    if (itemName) {
      return itemName.label;
    }
  }
  return false;
};

const ViewApiKeyPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { isDesktop } = useThemeBreakpoint();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const {
    getApiKeyHandler,
    apiKey,
    changeApiKeyHandler,
    getApiKeyHistoryHandler,
    apiKeyHistory,
    sendApiKeyEmailHandler,
  } = useApiKeys();
  const { getRolesData, roles } = useRoles();
  const { shortDateFormat, longDateFormat } = useGlobalProperty();
  const [keyScope, setKeyScope] = useState({
    apikeyAncillaryAccessory: [],
    apikeyAncillaryAmmunition: [],
    apikeyAncillaryComponent: [],
    apikeyFirearm: [],
    apikeyLegalEntity: [],
    apikeyTransaction: [],
  });

  useEffect(() => {
    (async () => {
      if (id) {
        await getApiKeyHandler(id);
        await getApiKeyHistoryHandler(id);
        await getRolesData(1, 100);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (apiKey) {
      const scopeKeys = {
        apikeyAncillaryAccessory: [],
        apikeyAncillaryAmmunition: [],
        apikeyAncillaryComponent: [],
        apikeyFirearm: [],
        apikeyLegalEntity: [],
        apikeyTransaction: [],
      };

      Object.keys(scopeKeys).map((key) => {
        // @ts-ignore
        const apiKeyItem = apiKey?.[key];

        if (apiKeyItem) {
          Object.keys(apiKeyItem).map((item) => {
            if (apiKeyItem[item] === true) {
              // @ts-ignore
              scopeKeys[key].push(renderScopeName(key, item));
            }
            return false;
          });
        }
        return false;
      });

      setKeyScope(scopeKeys);
    }
  }, [apiKey]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const copyApiKey = () => {
    (async () => {
      if (apiKey) {
        try {
          await copyToClipboard(apiKey.key);
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: t('apiKey.copied.success.text'),
          });
        } catch (e: any) {
          console.error(e);
        }
      }
    })();
  };

  const sendEmail = () => {
    if (apiKey) {
      (async () => {
        try {
          await sendApiKeyEmailHandler(id as string);
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: t('apiKey.emailSent.success.text'),
          });
        } catch (e: any) {
          console.error(e);
        }
      })();
    }
  };

  const toggleStatus = () => {
    (async () => {
      if (apiKey) {
        try {
          const isActive = apiKey.statusDictionaryUniqueId === ApiKeysStatusIds.ACTIVE;
          await changeApiKeyHandler(
            id as string,
            isActive ? ApiKeyStatus.INACTIVE.toUpperCase() : ApiKeyStatus.ACTIVE.toUpperCase(),
          );
          await getApiKeyHandler(id as string);
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: `${
              isActive
                ? t('apiKey.deactivated.success.text')
                : t('apiKey.activated.success.text')
            }`,
          });
        } catch (e) {
          console.error(e);
        }
      }
    })();
  };

  const renderKeyScope = () => {
    const renderItem: ReactElement[] = [];

    Object.keys(keyScope).map((key) => {
      // @ts-ignore
      const scope = keyScope?.[key];
      if (scope?.length) {
        const title = apiKeyScopeList.find((item) => item.value === key)?.label;

        renderItem.push(
          <>
            <Grid item xs={12} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>
                {title ? t(title) : '-'}
              </Typography>
            </Grid>
            {scope.map((item: string) => (
              <Grid item xs={12} sm={6} md={6} lg={3} style={{ paddingTop: '0px' }}>
                <Typography variant="body2">{t(item)}</Typography>
              </Grid>
            ))}
          </>,
        );
      }
      return false;
    });

    return renderItem;
  };

  const renderExpiryDate = () => {
    if (!apiKey) {
      return null;
    }

    const date = getDateFromISO(apiKey.expiryDate);
    if (date === '') {
      return (
        <Chips
          text={t('deleteApiKey.state.perpetual.label')}
          variant={EChipsVariants.success100}
        />
      );
    }
    if (date > new Date()) {
      return format(date, shortDateFormat);
    }
    return (
      <Chips text={t('deleteApiKey.state.expired.label')} variant={EChipsVariants.error} />
    );
  };

  const renderContactPhone = () => {
    if (!apiKey) {
      return null;
    }
    return `00${apiKey?.phonePrefixValue?.[0]?.name} ${apiKey?.phoneNumber}`;
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('apiKeys.header'),
            route: ERouteLinks.ApiKeysPage,
          },
          t('viewApiKey.header'),
        ]}
      />
      {apiKey && (
        <>
          <Grid
            container
            xs={12}
            lg={12}
            columnSpacing={2}
            rowSpacing={2}
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            <Grid item xs={12} sm={6} md={6} lg={3} />
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Button
                label={t('viewApiKey.edit.button.label')}
                fullWidth
                size={EButtonSizes.small}
                onClick={() =>
                  navigate(ERouteLinks.EditApiKeyPage.replace(':id', id as string))
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Button
                label={
                  apiKey.statusDictionaryUniqueId === ApiKeysStatusIds.ACTIVE
                    ? t('viewApiKey.deactivate.button.label')
                    : t('viewApiKey.activate.button.label')
                }
                fullWidth
                size={EButtonSizes.small}
                onClick={() => toggleStatus()}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3}>
              <Button
                label={t('viewApiKey.delete.button.label')}
                icon={EIconTypes.delete}
                fullWidth
                size={EButtonSizes.small}
                onClick={() =>
                  navigate(ERouteLinks.DeleteApiKeyPage.replace(':id', id as string))
                }
              />
            </Grid>
          </Grid>

          <StyledPageTitle variant="h4" sx={{ marginBottom: '10px' }}>
            {t('viewApiKey.header')}
          </StyledPageTitle>

          {samePageAlert && (
            <Box mt={2} mb={1}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}

          <DetailsTemplate
            title={apiKey?.key}
            pageType={EDetailsPageTypes.view}
            actionButtons={[
              {
                text: t('viewApiKey.copy.button.label'),
                onClick: () => copyApiKey(),
              },
              {
                text: t('viewApiKey.email.button.label'),
                onClick: () => sendEmail(),
              },
            ]}
          >
            <Grid container item xs={12} lg={12} rowGap="18px">
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.key.label')}
                </Typography>
                <Typography variant="body2">
                  <Chips text={apiKey.type} variant={EChipsVariants.primary} />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.user.label')}
                </Typography>
                <Typography variant="body2">{apiKey?.username ?? '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.expiryDate.label')}
                </Typography>
                <Typography variant="body2">{renderExpiryDate()}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.lastUsed.label')}
                </Typography>
                <Typography variant="body2">
                  {apiKey?.lastUsedDate
                    ? formatDate(apiKey.lastUsedDate, longDateFormat)
                    : '-'}
                </Typography>
              </Grid>

              {apiKey?.description && (
                <Grid item xs={12} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('viewApiKey.description.label')}
                  </Typography>
                  <Typography variant="body2">{apiKey?.description ?? '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <StyledDivider />

            <StyledViewSectionTitle variant="subtitle1">
              {t('viewApiKey.administrator.title')}
            </StyledViewSectionTitle>

            <Grid container item xs={12} lg={12} rowGap="18px">
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactEmail.label')}
                </Typography>
                <Typography variant="body2">{apiKey?.contactEmail ?? '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactName.label')}
                </Typography>
                <Typography variant="body2">{`${apiKey?.firstName} ${apiKey.lastName}`}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactPhone.label')}
                </Typography>
                <Typography variant="body2">{renderContactPhone()}</Typography>
              </Grid>
            </Grid>

            <StyledDivider />

            <StyledViewSectionTitle variant="subtitle1">
              {t('viewApiKey.scope.title')}
            </StyledViewSectionTitle>

            {renderKeyScope()}
          </DetailsTemplate>

          {apiKeyHistory && (
            <>
              <StyledPageTitle variant="h4" sx={{ marginY: '10px' }}>
                {t('viewApiKeyHistory.header')}
              </StyledPageTitle>

              {isDesktop ? (
                <Table
                  columns={apiKeysHistoryTableColumns({ roles, longDateFormat })}
                  dataSource={mapApiKeysHistoryToDataSource(apiKeyHistory)}
                />
              ) : (
                <CardList
                  items={mapApiKeysHistoryToDataSource(apiKeyHistory)}
                  render={(data, index) => (
                    <ApiKeyHistoryTableCard
                      data={data}
                      key={index}
                      roles={roles}
                      longDateFormat={longDateFormat}
                    />
                  )}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ViewApiKeyPage;
