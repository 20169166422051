import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import { StyledBlock } from 'pages/SystemSettingsPage/styles';
import { Link, useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from 'theme/colors';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';

const SystemSettingsPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          t('systemSettings.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('systemSettings.header')}</StyledPageTitle>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} lg={6}>
          <StyledBlock>
            <Link
              to={ERouteLinks.AuthenticationSetup}
              style={{ color: colors.black, textDecoration: 'none' }}
            >
              {t('authenticationSetup.header')}
            </Link>
            <Typography variant="body2">{t('authenticationSetup.description')}</Typography>
            <Button
              label={t('systemSettings.setup.button')}
              variant={EButtonVariants.contained}
              onClick={() => navigate(ERouteLinks.AuthenticationSetup)}
              sx={{ maxWidth: '256px' }}
            />
          </StyledBlock>
        </Grid>
        <Grid item xs={12} lg={6}>
          <StyledBlock>
            <Link
              to={ERouteLinks.DateTimeSetup}
              style={{ color: colors.black, textDecoration: 'none' }}
            >
              {t('dateTimeSetup.header')}
            </Link>
            <Typography variant="body2">{t('dateTimeSetup.description')}</Typography>
            <Button
              label={t('systemSettings.setup.button')}
              variant={EButtonVariants.contained}
              onClick={() => navigate(ERouteLinks.DateTimeSetup)}
              sx={{ maxWidth: '256px' }}
            />
          </StyledBlock>
        </Grid>
        <Grid item xs={12} lg={6}>
          <StyledBlock>
            <Link
              to={ERouteLinks.LogosSetupPage}
              style={{ color: colors.black, textDecoration: 'none' }}
            >
              {t('logosSetup.header')}
            </Link>
            <Typography variant="body2">{t('logosSetup.description')}</Typography>
            <Button
              label={t('systemSettings.setup.button')}
              variant={EButtonVariants.contained}
              onClick={() => navigate(ERouteLinks.LogosSetupPage)}
              sx={{ maxWidth: '256px' }}
            />
          </StyledBlock>
        </Grid>
        <Grid item xs={12} lg={6}>
          <StyledBlock>
            <Link
              to={ERouteLinks.DocumentsGeneratingSetupPage}
              style={{ color: colors.black, textDecoration: 'none' }}
            >
              {t('documentsGeneratingSetup.header')}
            </Link>
            <Typography variant="body2">{t('documentsGenerating.description')}</Typography>
            <Button
              label={t('systemSettings.setup.button')}
              variant={EButtonVariants.contained}
              onClick={() => navigate(ERouteLinks.DocumentsGeneratingSetupPage)}
              sx={{ maxWidth: '256px' }}
            />
          </StyledBlock>
        </Grid>
        {/* <Grid item xs={12} lg={6}> */}
        {/*  <StyledBlock> */}
        {/*    <Link */}
        {/*      to={ERouteLinks.LocationMapSetup} */}
        {/*      style={{ color: colors.black, textDecoration: 'none' }} */}
        {/*    > */}
        {/*      {t('locationMapSetup.header')} */}
        {/*    </Link> */}
        {/*    <Typography variant="body2">{t('locationMapSetup.description')}</Typography> */}
        {/*    <Button */}
        {/*      label={t('systemSettings.setup.button')} */}
        {/*      variant={EButtonVariants.contained} */}
        {/*      onClick={() => navigate(ERouteLinks.LocationMapSetup)} */}
        {/*      sx={{ maxWidth: '256px' }} */}
        {/*    /> */}
        {/*  </StyledBlock> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} lg={6}> */}
        {/*  <StyledBlock> */}
        {/*    <Link */}
        {/*      to={ERouteLinks.NotificationsSetup} */}
        {/*      style={{ color: colors.black, textDecoration: 'none' }} */}
        {/*    > */}
        {/*      {t('notificationsSetup.header')} */}
        {/*    </Link> */}
        {/*    <Typography variant="body2">{t('notificationsSetup.description')}</Typography> */}
        {/*    <Button */}
        {/*      label={t('systemSettings.setup.button')} */}
        {/*      variant={EButtonVariants.contained} */}
        {/*      onClick={() => navigate(ERouteLinks.NotificationsSetup)} */}
        {/*      sx={{ maxWidth: '256px' }} */}
        {/*    /> */}
        {/*  </StyledBlock> */}
        {/* </Grid> */}
      </Grid>
    </>
  );
};

export default SystemSettingsPage;
