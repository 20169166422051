import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { FormSelect } from 'components/molecules/FormSelect';
import {
  mapDictionaryToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchFirearmsFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { initialSearchFirearmsFormValues, ISearchFirearmsFormValues } from 'models/form';
import { prepareFilters } from 'helpers/filters';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { filterOutAncillaryStates } from 'pages/LegalEntitiesPage/FirearmSearchForm';

interface IFirearmSearchForm {
  onSubmit: (arg: ISearchFirearmsFormValues) => void;
}

const SearchFirearmsForm = ({ onSubmit }: IFirearmSearchForm) => {
  const { make, model, state, caliber, legality, manufacturer, loadDictionaries } =
    useLegalEntities();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.Legality,
        EDictionaryTypes.Manufacturer,
        EDictionaryTypes.Make,
        EDictionaryTypes.Model,
        EDictionaryTypes.State,
        EDictionaryTypes.Caliber,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ISearchFirearmsFormValues>({
    defaultValues: initialSearchFirearmsFormValues,
  });
  const [values, setValues] = useState<ISearchFirearmsFormValues>(
    initialSearchFirearmsFormValues,
  );

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFirearmsFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialSearchFirearmsFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} columns={10}>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormInput
              name="serialNumber"
              label={t('searchForLegalEntitysFirearm.serialNumber.label')}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormInput
              name="externalReferenceNumber"
              label={t('searchForLegalEntitysFirearm.externalReferenceNo.label')}
              control={control}
              errors={errors}
              setValue={setValue}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(filterOutAncillaryStates(state))}
              name="stateUniqueId"
              setValue={setValue}
              canBeEmpty
              label={t('searchForLegalEntitysFirearm.state.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(legality)}
              name="legalityUniqueId"
              setValue={setValue}
              canBeEmpty
              label={t('searchForLegalEntitysFirearm.legality.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              name="make"
              options={mapSpecificDictionaryToOptionProp(make)}
              label={t('searchForLegalEntitysFirearm.make.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              name="model"
              options={mapSpecificDictionaryToOptionProp(model)}
              label={t('searchForLegalEntitysFirearm.model.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              name="manufacturer"
              options={mapSpecificDictionaryToOptionProp(manufacturer)}
              label={t('searchForLegalEntitysFirearm.manufacturer.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormAutocomplete
              name="caliber"
              options={mapSpecificDictionaryToOptionProp(caliber)}
              label={t('searchForLegalEntitysFirearm.calibre.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10} />
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              id="search-firearm-button"
              label={t('searchForLegalEntitysFirearm.search.button')}
              type="submit"
              fullWidth={true}
              sx={{ mt: 3.5 }}
            />
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="firearm"
        values={values}
        labels={ESearchFirearmsFormLabelsTypes}
        handleDelete={handleResetField}
        noPreset
        dictionaries={{
          stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
          legalityUniqueId: prepareFilters(legality, 'uniqueId', 'name'),
        }}
        showSaveFilters={false}
        saveRouterStateAfterClearAll={true}
        saveQueryParams={['type', 'groupId']}
      />
    </SearchWrapper>
  );
};

export { SearchFirearmsForm };
