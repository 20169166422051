import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';

interface IFirearmReferenceTableDetailsCard {
  data: ITableDataSource;
}

const FirearmReferenceTableDetailsCard = ({ data }: IFirearmReferenceTableDetailsCard) => {
  const { t } = useTranslations();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} p={2}>
        {data.isLocal ? (
          <>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'firearmsReferenceTable.firearmDetails.general.accordion.firearmReferenceLocalID.label',
              )}
            </Typography>
            <Typography variant="body2">{data.frtId}</Typography>
          </>
        ) : (
          <>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t(
                'firearmsReferenceTable.editFirearm.general.accordion.firearmReferenceNo.label',
              )}
            </Typography>
            <Typography variant="body2">{data.id}</Typography>
          </>
        )}
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.firearmDetails.calibres.label')}
        </Typography>
        <Typography variant="body2">{data.caliber}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.editFirearm.calibre.accordion.shots.label')}
        </Typography>
        <Typography variant="body2">{data.shots}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(
            'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.barrelLength.column',
          )}
        </Typography>
        <Typography variant="body2">{data.barrelLength}</Typography>
      </Grid>
      <Grid item xs={12} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('firearmsReferenceTable.accordion.level.label')}
        </Typography>
        <Typography variant="body2">{data.level}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(
            'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.legalClassification.column',
          )}
        </Typography>
        <Typography variant="body2">{data.legalClassification}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(
            'firearmsReferenceTable.firearmDetails.calibreShotsAndBarrelLength.accordion.barrelTypeCode.column',
          )}
        </Typography>
        <Typography variant="body2">{data.barrelType}</Typography>
      </Grid>
    </Grid>
  );
};

export { FirearmReferenceTableDetailsCard };
