import { EDashboardSortType } from 'models/dashboard';

export const mapSortTypeToLabel = (sortType: EDashboardSortType) => {
  switch (sortType) {
    case EDashboardSortType.Day:
      return 'dashboard.sort.button.today';
    case EDashboardSortType.Week:
      return 'dashboard.sort.button.week';
    case EDashboardSortType.Month:
      return 'dashboard.sort.button.month';
    case EDashboardSortType.Year:
      return 'dashboard.sort.button.year';
    case EDashboardSortType.All:
      return 'dashboard.sort.button.all';
    default:
      return '';
  }
};
