import React from 'react';
import { EIconTypes } from 'constants/Icons';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { SvgIcon } from '@mui/material';
import { colors } from 'theme/colors';
import { IconsSVG } from './icons';

interface IIcon {
  type: EIconTypes;
  color?: string;
  fontSize?: number;
}

const MUIIcon = (props: SvgIconProps) => <SvgIcon {...props}>{props.children}</SvgIcon>;

const Icon = ({ type, color = colors.primary900, fontSize = 24 }: IIcon) =>
  IconsSVG[type] ? <MUIIcon sx={{ color, fontSize }}>{IconsSVG[type]}</MUIIcon> : null;

export default Icon;
