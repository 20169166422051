import { useEffect, useState } from 'react';
import { useLang } from 'models/langContext';
import { FC_SYSTEM_AUTH_ACCESS_TOKEN } from 'requests/auth';

export const useAppLanguageCode = () => {
  const [appLanguageCode, setAppLanguageCode] = useState('');
  const { getLanguagesData, languages } = useLang();

  useEffect(() => {
    if (localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN)) {
      getLanguagesData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN)]);

  useEffect(() => {
    if (languages.length) {
      const browserLang = languages.find((lang) => navigator.language.includes(lang.code));
      const defaultLang = languages.find((lang) => lang.defaultLanguage);
      setAppLanguageCode(browserLang?.code || defaultLang?.code || '');
    }
  }, [languages]);
  return { appLanguageCode };
};
