import React from 'react';
import { StyledLink } from 'components/atoms/RouterLink/styles';

interface IRouterLink {
  to: string;
  label: string;
  replace?: boolean;
  forceRefresh?: boolean;
}

export const RouterLink = ({
  to,
  label,
  replace = false,
  forceRefresh = false,
}: IRouterLink) => (
  <StyledLink
    to={to}
    replace={replace}
    onClick={() => {
      if (forceRefresh) {
        window.location.reload();
      }
    }}
  >
    {label}
  </StyledLink>
);
