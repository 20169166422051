import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import { ILanguagesResponse } from 'models/language';

export const FC_SYSTEM_APP_LANG = 'FC_SYSTEM_APP_LANG';

const getLanguages = async (): Promise<ILanguagesResponse | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_LANGUAGES());

  if (isResponseError(data)) {
    return null;
  }

  return data;
};
export { getLanguages };
