import { IDictionary } from 'models/dictionary';
import { IFormElementOptionProp } from 'models/form';

export enum EDataSourceTranslationField {
  LEGALITY = 'legality',
  ACTIVITY = 'activity',
}

export enum EDictionaryPrefixTypesUniqueId {
  civilianPrefix = 'da1377a4-0b40-4e5d-8dcc-519663869441',
  militaryPrefix = '6a589af3-2093-443a-a4df-fed6b018bf3f',
  policePrefix = '864a5d8a-a018-4d64-be69-8ab6003913c8',
}

export interface IDataSourceTranslations {
  translations: IDictionary[] | null;
  field: EDataSourceTranslationField;
}

export const mapDataSourceTranslationToTableValue = (
  translations: IDataSourceTranslations[],
  field: EDataSourceTranslationField,
  dictionaryUniqueId: string,
) => {
  const fieldData = translations.find((item) => item.field === field);
  if (fieldData && fieldData.translations) {
    const dictionary = fieldData.translations.find(
      (dictItem) => dictItem.uniqueId === dictionaryUniqueId,
    );
    return dictionary?.name || '';
  }
  return '';
};

export const mapDictionaryToOptionProp = (
  dictionary: IDictionary[] | null,
  prefix?: string,
  filterValues: string[] = [],
): IFormElementOptionProp[] => {
  if (!dictionary) {
    return [];
  }
  return dictionary
    .filter((item) => item.isActive && !filterValues.includes(item.uniqueId as string))
    .map((item) => ({
      label: prefix ? `${prefix}${item.name}` : item.name,
      value: item.uniqueId,
    }));
};

export const mapDictionaryNamesToOptionProp = (
  dictionary: IDictionary[] | null,
  idAsValue = false,
): IFormElementOptionProp[] => {
  if (!dictionary) {
    return [];
  }
  return dictionary
    .filter((item) => item.isActive)
    .map((item) => ({
      label: item.name,
      value: idAsValue ? item.uniqueId : item.name,
    }));
};

export const mapSpecificDictionaryToOptionProp = (
  dictionary: string[] | null,
  valueToUpperCase?: boolean,
): IFormElementOptionProp[] => {
  if (!dictionary) {
    return [];
  }
  return dictionary.map((item) => ({
    label: item,
    value: valueToUpperCase && !!item ? item.toUpperCase() : item,
  }));
};

export interface IParsedDictionary {
  [id: string]: string;
}

export const parseDictionary = (
  dictionary: IDictionary[] | string[] | null | undefined,
  key: keyof IDictionary,
) => {
  const result: IParsedDictionary = {};

  if (!dictionary) {
    return result;
  }

  dictionary.forEach((dict) => {
    if (
      dict &&
      Object.prototype.hasOwnProperty.call(dict, 'uniqueId') &&
      Object.prototype.hasOwnProperty.call(dict, key)
    ) {
      // @ts-ignore
      result[dict.uniqueId] = <string>dict[key];
    }
  });
  return result;
};

export const parseMultipleDictionaries = (
  dictionaries: (IDictionary[] | null | undefined)[],
  key: keyof IDictionary,
) => {
  const result: Record<string, string> = {};
  dictionaries.forEach((dictionaryArray) => {
    if (dictionaryArray) {
      dictionaryArray.forEach((dict) => {
        if (
          Object.prototype.hasOwnProperty.call(dict, 'uniqueId') &&
          Object.prototype.hasOwnProperty.call(dict, key)
        ) {
          result[dict.uniqueId] = <string>dict[key];
        }
      });
    }
  });
  return result;
};
