import { createContext, useContext } from 'react';

export interface IGlobalPropertyContext {
  getDateFormat: () => void;
  shortDateFormat: string;
  midDateFormat: string;
  longDateFormat: string;
}

export const GlobalPropertyContext = createContext<IGlobalPropertyContext>(
  {} as IGlobalPropertyContext,
);

export const useGlobalProperty = (): IGlobalPropertyContext =>
  useContext(GlobalPropertyContext);
