import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { FormInput } from 'components/molecules/FormInput';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { EIconTypes } from 'constants/Icons';
import { initialGroupSearchFormValues, ISearchGroupFormValues } from 'models/form';
import { ESearchGroupFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';

interface IGroupSearchForm {
  onSubmit: (arg: ISearchGroupFormValues) => void;
  initialFormValues: Partial<ISearchGroupFormValues> | null;
  isSearchButtonDisabled: boolean;
}

const GroupSearchForm = ({
  onSubmit,
  initialFormValues,
  isSearchButtonDisabled = false,
}: IGroupSearchForm) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ISearchGroupFormValues>({
    defaultValues:
      initialFormValues && Object.getOwnPropertyNames(initialFormValues).length
        ? initialFormValues
        : initialGroupSearchFormValues,
  });
  const [values, setValues] = useState<Partial<ISearchGroupFormValues>>(
    initialFormValues || initialGroupSearchFormValues,
  );

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchGroupFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialGroupSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };
  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item lg={6} xs={12}>
            <FormInput
              name="firearmSerialNumber"
              setValue={setValue}
              label={t('groups.accordion.serialNumber.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={6} xs={12} container spacing={2}>
            <Grid item lg={6} sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '4px' }}>
                <Button
                  variant={EButtonVariants.outlined}
                  size={EButtonSizes.small}
                  label={t('groups.accordion.search.button')}
                  disabled={isSearchButtonDisabled}
                  type="submit"
                  fullWidth={true}
                />
              </Box>
            </Grid>
            <Grid item lg={6} sm={6} xs={12} sx={{ marginTop: '4px' }}>
              <Button
                id="add-group-button"
                variant={EButtonVariants.contained}
                icon={EIconTypes.plus}
                label={t('groups.accordion.addGroup.button')}
                fullWidth
                sx={{ height: '32px !important' }}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.LegalEntityAddGroup.replace(
                      ':id',
                      id as string,
                    )}?type=${type}&expand=groups`,
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="group"
        values={values}
        saveQueryParams={['type']}
        labels={ESearchGroupFormLabelsTypes}
        handleDelete={handleResetField}
        showSaveFilters={false}
        noPreset
        translationKeys={{
          selectedCriteria: 'searchForLegalEntityGroups.selectedCriteria.label',
          clearAll: 'searchForLegalEntitiesGroups.clearAll.link',
        }}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { GroupSearchForm };
