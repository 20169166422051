import React, { useEffect, useState } from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { Typography } from '@mui/material';
import { IAuditLogItemResponse } from 'models/auditLog';
import { IAccessoryDetails, IAmmunitionDetails, IComponentDetails } from 'models/ancillaries';
import {
  getAccessory,
  getAmmunition,
  getARTAccessory,
  getARTAmmunition,
  getARTComponent,
  getComponent,
} from 'requests/ancillaries';
import AccessoryDetails from 'components/organisms/AncillaryDetails/AccessoryDetails';
import {
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameLowerCase,
} from 'constants/ArtAccessoryType';
import AmmunitionDetails from 'components/organisms/AncillaryDetails/AmmunitionDetails';
import ComponentDetails from 'components/organisms/AncillaryDetails/ComponentDetails';
import ArtDetails from 'components/organisms/AncillaryDetails/ArtDetails';

enum EEventNameUniqueId {
  ADD_ACCESSORY = '7b6ce02f-89c9-4e4c-bae1-3a9481d77751',
  EDIT_ACCESSORY = '15f0be9e-3353-44fa-a3bc-fdb9c899e223',
  DISABLE_ACCESSORY = '171f2225-d199-4776-a934-b9d76572c4a5',
  ADD_ART_ACCESSORY = '53fa964b-2a72-4367-88dd-48814fba61a3',
  EDIT_ART_ACCESSORY = '113bc9fa-16f6-4b1f-a36b-a6d74cbf5109',

  ADD_AMMUNITION = '3aea32c4-842a-487a-b13a-2acef468fa28',
  EDIT_AMMUNITION = 'be1cfff4-221e-4c29-abd6-ff68c63d9070',
  DISABLE_AMMUNITION = '420bbf75-1726-4c99-a10a-960c1db5da60',
  ADD_ART_AMMUNITION = '12f0673b-912f-4c7f-b517-37c885e2e7d8',
  EDIT_ART_AMMUNITION = '14fcdda2-e662-48e3-b652-afd494cb25d4',

  ADD_COMPONENT = 'bc545646-fbb9-48da-8890-f526d5c24b33',
  EDIT_COMPONENT = 'b367cdf4-f987-4591-8312-96d0455e4fe8',
  DISABLE_COMPONENT = 'c1a9f3f6-5a61-4071-9976-06ed829a3403',
  ADD_ART_COMPONENT = '4feed94a-aaa1-4a9b-bc63-887e6608b59c',
  EDIT_ART_COMPONENT = '8ce892a4-4f74-4ca5-967f-8ee0a6ffbdca',
}
interface IAncillariesEventDetails {
  auditLog: IAuditLogItemResponse;
}
const AncillariesEventDetails = ({ auditLog }: IAncillariesEventDetails) => {
  const [ancillary, setAncillary] = useState<
    IAccessoryDetails | IAmmunitionDetails | IComponentDetails | null
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [artType, setArtType] = useState<string>('');
  const { t } = useTranslations();

  useEffect(() => {
    (async () => {
      if (
        auditLog &&
        auditLog.objectType &&
        auditLog.objectUniqueId &&
        auditLog.eventNameUniqueId
      ) {
        try {
          if (auditLog.objectType === EArtAncillaryTypeName.ACCESSORY.toUpperCase()) {
            if (
              auditLog.eventNameUniqueId === EEventNameUniqueId.ADD_ACCESSORY ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.EDIT_ACCESSORY ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.DISABLE_ACCESSORY
            ) {
              const accessoryResponse = await getAccessory(auditLog.objectUniqueId);
              if (accessoryResponse) {
                setAncillary(accessoryResponse);
              }
            }
            if (
              auditLog.eventNameUniqueId === EEventNameUniqueId.ADD_ART_ACCESSORY ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.EDIT_ART_ACCESSORY
            ) {
              const accessoryArtResponse = await getARTAccessory(auditLog.objectUniqueId);
              if (accessoryArtResponse) {
                setArtType(EArtAncillaryTypeNameLowerCase.ACCESSORY);
              }
            }
          }
          if (auditLog.objectType === EArtAncillaryTypeName.AMMUNITION.toUpperCase()) {
            if (
              auditLog.eventNameUniqueId === EEventNameUniqueId.ADD_AMMUNITION ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.EDIT_AMMUNITION ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.DISABLE_AMMUNITION
            ) {
              const ammunitionResponse = await getAmmunition(auditLog.objectUniqueId);
              if (ammunitionResponse) {
                setAncillary(ammunitionResponse);
              }
            }
            if (
              auditLog.eventNameUniqueId === EEventNameUniqueId.ADD_ART_AMMUNITION ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.EDIT_ART_AMMUNITION
            ) {
              const ammunitionArtResponse = await getARTAmmunition(auditLog.objectUniqueId);
              if (ammunitionArtResponse) {
                setArtType(EArtAncillaryTypeNameLowerCase.AMMUNITION);
              }
            }
          }
          if (auditLog.objectType === EArtAncillaryTypeName.COMPONENTS.toUpperCase()) {
            if (
              auditLog.eventNameUniqueId === EEventNameUniqueId.ADD_COMPONENT ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.EDIT_COMPONENT ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.DISABLE_COMPONENT
            ) {
              const componentResponse = await getComponent(auditLog.objectUniqueId);
              if (componentResponse) {
                setAncillary(componentResponse);
              }
            }
            if (
              auditLog.eventNameUniqueId === EEventNameUniqueId.ADD_ART_COMPONENT ||
              auditLog.eventNameUniqueId === EEventNameUniqueId.EDIT_ART_COMPONENT
            ) {
              const componentArtResponse = await getARTComponent(auditLog.objectUniqueId);
              if (componentArtResponse) {
                setArtType(EArtAncillaryTypeNameLowerCase.COMPONENTS);
              }
            }
          }
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [auditLog, artType]);

  return (
    <>
      {auditLog &&
        Object.values(EEventNameUniqueId).includes(
          auditLog.eventNameUniqueId as EEventNameUniqueId,
        ) && (
          <AuditLogEventName>
            <Typography variant="h5">
              {t('auditLogDetails.ancillariesDetails.title')}
            </Typography>
          </AuditLogEventName>
        )}
      {!loading &&
        ancillary &&
        ancillary.ancillaryType === EArtAncillaryTypeName.ACCESSORY.toUpperCase() && (
          <AccessoryDetails id={ancillary.uniqueId} />
        )}
      {!loading &&
        ancillary &&
        ancillary.ancillaryType === EArtAncillaryTypeName.AMMUNITION.toUpperCase() && (
          <AmmunitionDetails id={ancillary.uniqueId} />
        )}
      {!loading &&
        ancillary &&
        ancillary.ancillaryType === EArtAncillaryTypeName.COMPONENTS.toUpperCase() && (
          <ComponentDetails id={ancillary.uniqueId} />
        )}
      {!loading && !ancillary && auditLog?.objectUniqueId && artType !== null && (
        <ArtDetails id={auditLog.objectUniqueId} type={artType} />
      )}
    </>
  );
};

export default AncillariesEventDetails;
