import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { Pagination } from 'components/molecules/Pagination';
import {
  AccessoriesTableColumns,
  mapAccessoriesToDataSource,
} from 'pages/AncillariesPage/helpers';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useAlert } from 'models/alertContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ActionTypes } from 'state/actions/alert';
import { CardList } from 'components/molecules/CardList';
import { Loader } from 'components/atoms/Loader';
import { AccessoriesSearchForm } from 'pages/AncillariesPage/AccessoriesPage/AccessoriesSearchForm';
import { AccessoriesCard } from 'pages/AncillariesPage/AccessoriesPage/AccessoriesCard';
import { EPerPages, ISortOptions, ITableDataSource } from 'models/table';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import {
  customFieldsPrepareToSearch,
  objectToQueryString,
  prepareDataWithCustomFieldsToSearch,
  prepareQueryValues,
} from 'helpers/searchQuery';
import {
  IFormElementOptionProp,
  initialAncillariesSearchFormValues,
  ISearchAncillariesFormValues,
} from 'models/form';
import SearchFilterStore from 'state/SearchFilterStore';
import {
  EAncillaryFilterTypes,
  EArtAncillaryExpandType,
  EArtAncillaryType,
} from 'constants/ArtAccessoryType';
import { useQuery } from 'hooks/useQuery';
import { useDrawerClicked } from 'state/contexts/drawer';
import { exportAncillariesCSV } from 'requests/ancillaries';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import { useLang } from 'models/langContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EPermission } from 'models/permissions';
import { EChipsVariants } from 'components/atoms/Chips';
import { renderRowColor, renderRowColorHover } from 'components/molecules/Table/helpers';
import { Tooltip } from '@mui/material';
import { isExportAvailable } from 'constants/Export';
import { useAncillaries } from '../hooks';

interface AccessoriesPageProps {
  renderDataColor: (data: ITableDataSource) => {
    border: string;
    stateField: EChipsVariants;
    background: string;
  };
  setAccessoryAccordionExpanded: (val: boolean | undefined) => void;
}

const AccessoriesPage = ({
  renderDataColor,
  setAccessoryAccordionExpanded,
}: AccessoriesPageProps) => {
  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();
  const { pathname }: any = useLocation();
  const navigate = useNavigate();
  const [isAccessoriesLoading, setIsAccessoriesLoading] = useState<boolean | null>(null);
  const [currentPageAccessories, setCurrentPageAccessories] = useState<number>(1);
  const [perPageAccessories, setPerPageAccessories] = useState<EPerPages>(EPerPages.perPage25);
  const [accessoriesSort, setAccessoriesSort] = useState<ISortOptions | null>(null);
  const [accessoriesQuery, setAccessoriesQuery] = useState<
    Partial<ISearchAncillariesFormValues>
  >({});
  const [isAccessoriesQueryLoading, setIsAccessoriesQueryLoading] = useState<boolean>(false);
  const [isSortLoaded, setIsSortLoaded] = useState<boolean>(true);
  const query = useQuery();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);
  const [ownerOptions, setOwnerOptions] = useState<IFormElementOptionProp[]>([]);
  const [keeperOptions, setKeeperOptions] = useState<IFormElementOptionProp[]>([]);
  const [initialValues, setInitialValues] = useState<ISearchAncillariesFormValues | null>(
    null,
  );
  const [areInitialValuesLoaded, setAreInitialValuesLoaded] = useState(false);
  const [areCustomFieldsLoaded, setAreCustomFieldsLoaded] = useState(false);
  const { drawerClicked } = useDrawerClicked();
  const { selectedLanguage } = useLang();
  const { shortDateFormat } = useGlobalProperty();
  const [searchParams] = useSearchParams();
  const { clearAlert } = useAlert();
  const urlQuery = new URLSearchParams(searchParams);
  const { state: routerState }: any = useLocation();
  const expandType = urlQuery.get('expand');
  const isFromMapQuery: boolean =
    query.get('fromMap') === 'true' || routerState?.accessoriesUniqueIds?.length > 0;
  const storedAccessories = SearchFilterStore.getAccessories();
  const [isExportLoading, setExportLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const scrollPosition = useRef(0);

  const uniqueIds =
    storedAccessories.length > 0
      ? storedAccessories.map((accessoryItem) => accessoryItem.uniqueId)
      : routerState?.accessoriesUniqueIds;

  const {
    paginatorAccessories,
    accessories,
    getAccessoriesData,
    getCustomField,
    customFieldsAccessories,
  } = useAncillaries();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_NEXT_PAGE_ALERT);
      setIsAccessoriesLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isSortLoaded) {
      window.scrollTo({ top: scrollPosition.current, behavior: 'auto' });
      scrollPosition.current = 0;
    }
  }, [isSortLoaded]);

  useEffect(() => {
    (async () => {
      setAreCustomFieldsLoaded(false);
      await getCustomField(ECustomFieldObjectType.AN_ACCESSORY);
      setAreCustomFieldsLoaded(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (drawerClicked) {
      SearchFilterStore.setAccessories([]);
      window.history.replaceState({}, '', `${pathname}`);
      setOwnerOptions([]);
      setKeeperOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerClicked]);

  const previousAccessoriesSortRef = useRef(accessoriesSort);

  useEffect(() => {
    (async function getDataInit() {
      setIsPaginationLoading(true);
      if (!accessories.length) {
        setIsAccessoriesLoading(true);
      }

      if (isFromMapQuery) {
        if (uniqueIds && uniqueIds.length) {
          accessoriesQuery.uniqueIds = uniqueIds;
        } else {
          delete accessoriesQuery.uniqueIds;
        }
      }
      if (accessoriesSort !== previousAccessoriesSortRef.current) {
        setIsSortLoaded(false);
      }
      if (Object.keys(accessoriesQuery).length !== 0) {
        customFieldsPrepareToSearch(accessoriesQuery.customFields);
        const accessoriesQueryData = prepareDataWithCustomFieldsToSearch(
          accessoriesQuery,
          customFieldsAccessories.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          ),
        );
        await getAccessoriesData(
          currentPageAccessories,
          perPageAccessories,
          {
            ...accessoriesQueryData,
          },
          accessoriesSort,
        );
      }

      setIsAccessoriesQueryLoading(false);
      setIsPaginationLoading(false);
      setIsAccessoriesLoading(false);
      setIsSortLoaded(true);
      previousAccessoriesSortRef.current = accessoriesSort;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageAccessories,
    perPageAccessories,
    accessoriesQuery,
    accessoriesSort,
    selectedLanguage,
  ]);

  useEffect(() => {
    if (initialValues !== undefined && areCustomFieldsLoaded) {
      setAreInitialValuesLoaded(true);
    }
  }, [initialValues, areCustomFieldsLoaded]);

  useEffect(() => {
    if (areCustomFieldsLoaded) {
      setAreInitialValuesLoaded(false);
      if (expandType === EArtAncillaryExpandType.ACCESSORIES) {
        setShowAdvanced(true);
        const queryValues = prepareQueryValues(
          {
            ...initialAncillariesSearchFormValues,
          },
          urlQuery,
          [
            ...customFieldsAccessories
              .filter((cf) => cf.customFieldType === ECustomFieldType.DATE)
              .map((cf) => [`from_${cf.name}`, `to_${cf.name}`]),
          ].flat(),
        );
        setInitialValues({ ...initialAncillariesSearchFormValues, ...queryValues });
      } else {
        setInitialValues(initialAncillariesSearchFormValues);
        setAreInitialValuesLoaded(true);
      }
    }
  }, [searchParams, areCustomFieldsLoaded]);

  const getNewQueryPath = (values: Partial<ISearchAncillariesFormValues>): string => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;
    valuesWithoutPreset.formSubmitted = undefined;
    const objQuery = objectToQueryString(
      valuesWithoutPreset,
      customFieldsAccessories,
      shortDateFormat,
    );

    if (
      objQuery.length &&
      (!isInitialLoad || expandType === EArtAncillaryExpandType.ACCESSORIES)
    ) {
      setIsInitialLoad(false);
      return `${pathname}?expand=accessories&${objQuery}`;
    }
    if (values.formSubmitted === true) {
      return `${pathname}?expand=accessories`;
    }
    setIsInitialLoad(false);
    return `${pathname}`;
  };

  const handleSetAccessoriesQuery = (values: Partial<ISearchAncillariesFormValues>) => {
    const valuesWithoutPreset = { ...values };
    valuesWithoutPreset.preset = undefined;
    valuesWithoutPreset.formSubmitted = undefined;

    window.history.replaceState({}, '', getNewQueryPath(values));

    setIsAccessoriesQueryLoading(true);
    setAccessoriesQuery(valuesWithoutPreset);
    if (
      routerState?.presetAncillaries &&
      routerState?.ancillaryFilterType === EAncillaryFilterTypes.ACCESSORY
    ) {
      setAccessoryAccordionExpanded(true);
    }
  };

  const exportCsv = async () => {
    setExportLoading(true);
    accessoriesQuery.ancillaryDictionaryUniqueId = EArtAncillaryType.ACCESSORIES;
    await exportAncillariesCSV(accessoriesQuery, accessoriesSort);
    setExportLoading(false);
  };

  return (
    <div>
      {initialValues && areInitialValuesLoaded && areCustomFieldsLoaded && (
        <AccessoriesSearchForm
          ownerOptions={ownerOptions}
          keeperOptions={keeperOptions}
          setOwnerOptions={setOwnerOptions}
          setKeeperOptions={setKeeperOptions}
          showAdvanced={showAdvanced}
          accessoriesUniqueIds={uniqueIds}
          isSearchButtonDisabled={isAccessoriesLoading || isAccessoriesQueryLoading}
          isFromMap={isFromMapQuery}
          returnPath={`${pathname}?expand=accessories`}
          setShowAdvanced={setShowAdvanced}
          onSubmit={handleSetAccessoriesQuery}
          getNewQueryPath={getNewQueryPath}
          initialFormValues={initialValues ?? undefined}
          customFields={customFieldsAccessories.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          )}
        />
      )}
      <Grid
        item
        xs={12}
        container
        gap={1}
        sx={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}
      >
        {!isAccessoriesLoading && (
          <>
            {isExportAvailable(paginatorAccessories) ? (
              <Tooltip title={t('dataExportRecordsLimitAmount.label')} arrow>
                <span>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    permission={[EPermission.EXPORT_ANCILLARIES]}
                    label={t('ancillaries.accordion.export.button')}
                    type="submit"
                    onClick={exportCsv}
                    isLoading={isExportLoading}
                    disabled
                  />
                </span>
              </Tooltip>
            ) : (
              <Button
                variant={EButtonVariants.outlined}
                size={EButtonSizes.small}
                permission={[EPermission.EXPORT_ANCILLARIES]}
                label={t('ancillaries.accordion.export.button')}
                type="submit"
                onClick={exportCsv}
                isLoading={isExportLoading}
              />
            )}
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t('ancillaries.accordion.showOnMap.button')}
              onClick={() => {
                navigate(ERouteLinks.RegisteredAncillariesMap, {
                  state: {
                    ancillaryType: 'accessory',
                    ancillaryQuery: accessoriesQuery,
                    showAdvanced,
                    options: { ownerOptions, keeperOptions },
                    navigateBack: -1,
                  },
                });
              }}
            />
          </>
        )}
      </Grid>
      {isAccessoriesLoading || isAccessoriesQueryLoading ? (
        <Loader isLoading={true} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={AccessoriesTableColumns(
                {
                  handleEdit: (id: string) =>
                    navigate(ERouteLinks.EditAccessories.replace(':id', id)),
                  customFields: customFieldsAccessories,
                  t,
                },
                (data: ITableDataSource) => renderDataColor(data).stateField,
                selectedLanguage?.uniqueId,
                shortDateFormat,
              )}
              specificRowColor={renderRowColor}
              specificRowColorHover={renderRowColorHover}
              dataSource={mapAccessoriesToDataSource(accessories)}
              onSort={(it) => {
                setAccessoriesSort(it);
                scrollPosition.current = window.scrollY;
              }}
              translationsKeys={{
                noResults: 'ancillaries.accordion.noResults.text',
              }}
              isSortLoaded={isSortLoaded}
              customPaddingIfNoData="30px"
            />
          ) : (
            <CardList
              items={mapAccessoriesToDataSource(accessories)}
              render={(data, index) => (
                <AccessoriesCard
                  data={data}
                  key={index}
                  handleView={(id: string) =>
                    navigate(ERouteLinks.ViewAccessories.replace(':id', id))
                  }
                  handleEdit={(id: string) =>
                    navigate(ERouteLinks.EditAccessories.replace(':id', id))
                  }
                  renderColor={(item) => renderDataColor(item)}
                />
              )}
            />
          )}

          {paginatorAccessories && (
            <Pagination
              count={paginatorAccessories?.totalElements}
              perPage={perPageAccessories}
              onChangePage={(page) => {
                setCurrentPageAccessories(page);
              }}
              onChangePerPage={(value) => {
                setPerPageAccessories(value);
              }}
              current={currentPageAccessories}
              isLoading={isPaginationLoading}
              isVisible={isSortLoaded}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AccessoriesPage;
