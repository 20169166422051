import styled from '@emotion/styled';
import { MapContainer } from 'react-leaflet';
import Button from 'components/atoms/Button';
import { colors } from 'theme/colors';

export const StyledLocationMapWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 300px);
  position: relative;
  border: 1px solid ${colors.gray300};
`;

export const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 100%;
`;

export const StyledButton = styled(Button)`
  position: absolute;
  z-index: 1110;
  width: 40px;
  height: 40px;
`;

export const ButtonZoomIn = styled(StyledButton)`
  bottom: 10px;
  left: 10px;

  & svg {
    width: 20px;
  }
`;
export const ButtonZoomOut = styled(StyledButton)`
  bottom: 10px;
  left: 54px;

  & svg {
    width: 20px;
  }
`;
export const ButtonFullScreen = styled(StyledButton)`
  bottom: 10px;
  left: 110px;

  & svg {
    width: 28px;
    height: 28px;
    margin-top: 7px;
    margin-left: 4px;
  }
`;
