import React, { ReactElement, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { Table } from 'components/molecules/Table';
import { EPerPages, ESortDirectionTypes, ISortOptions, ITableColumns } from 'models/table';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { Pagination } from 'components/molecules/Pagination';

const createData = (
  id: string,
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) => ({ id, name, calories, fat, carbs, protein });

const data = [
  createData('1', 'Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('2', 'Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('3', 'Eclair', 262, 16.0, 24, 6.0),
  createData('4', 'Cupcake', 305, 3.7, 67, 4.3),
  createData('5', 'Gingerbread', 356, 16.0, 49, 3.9),
  createData('11', 'Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('12', 'Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('13', 'Eclair', 262, 16.0, 24, 6.0),
  createData('14', 'Cupcake', 305, 3.7, 67, 4.3),
  createData('15', 'Gingerbread', 356, 16.0, 49, 3.9),
  createData('21', 'Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('22', 'Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('23', 'Eclair', 262, 16.0, 24, 6.0),
  createData('24', 'Cupcake', 305, 3.7, 67, 4.3),
  createData('25', 'Gingerbread', 356, 16.0, 49, 3.9),
  createData('31', 'Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('32', 'Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('33', 'Eclair', 262, 16.0, 24, 6.0),
  createData('34', 'Cupcake', 305, 3.7, 67, 4.3),
  createData('35', 'Gingerbread', 356, 16.0, 49, 3.9),
  createData('41', 'Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('42', 'Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('43', 'Eclair', 262, 16.0, 24, 6.0),
  createData('44', 'Cupcake', 305, 3.7, 67, 4.3),
  createData('45', 'Gingerbread', 356, 16.0, 49, 3.9),
  createData('51', 'Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('52', 'Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('53', 'Eclair', 262, 16.0, 24, 6.0),
  createData('54', 'Cupcake', 305, 3.7, 67, 4.3),
  createData('55', 'Gingerbread', 356, 16.0, 49, 3.9),
];

const getColumns = (): ITableColumns[] => [
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc41',
    key: 'id',
    title: 'ID',
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc42',
    key: 'name',
    title: 'Name',
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc43',
    key: 'calories',
    title: 'Calories [kcal]',
    render: (value) => `${value} kcal`,
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc45',
    key: 'fat',
    title: 'Fat',
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc46',
    key: 'carbs',
    title: 'Carbs',
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc47',
    key: 'protein',
    title: 'Protein',
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'Actions',
    isSortable: false,
    render: () => (
      <Button
        variant={EButtonVariants.outlined}
        tableButton={true}
        icon={EIconTypes.administration1}
      />
    ),
  },
];

const TableTestPage = (): ReactElement => {
  const [, setSort] = useState<ISortOptions>({
    property: 'id',
    direction: ESortDirectionTypes.DESC,
  });
  const [currentPage, setCurrentPage] = useState(2);
  const [perPage, setPerPage] = useState<EPerPages>(EPerPages.perPage25);

  return (
    <Container>
      <Typography variant="h4">Table Test</Typography>
      <Table dataSource={data} columns={getColumns()} onSort={setSort} />

      <Pagination
        count={data.length}
        perPage={perPage}
        onChangePage={(page) => {
          setCurrentPage(page);
        }}
        onChangePerPage={(value) => {
          setPerPage(value);
        }}
        current={currentPage}
        isLoading={false}
      />
    </Container>
  );
};

export { TableTestPage };
