export enum EActionTypes {
  LOG_IN = 'LOG_IN',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT = 'LOG_OUT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  CHANGE_ACCESS_TOKEN = 'CHANGE_ACCESS_TOKEN',
  CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS',
  CHANGE_USER = 'CHANGE_USER',
}
