import * as yup from 'yup';
import { getFieldsByAmmunitionType } from 'pages/AncillariesPage/helpers';
import { useTranslations } from 'hooks/useTranslations';

const isFieldRequired = (
  val: string | number | undefined | null,
  obj: any,
  t: (key: string) => string,
) => {
  const fields = getFieldsByAmmunitionType(obj.parent.ammunitionTypeUniqueId);
  if (fields.includes(obj.path) && !val) {
    return new yup.ValidationError(t('general.field.validation.required'), null, obj.path);
  }
  return true;
};
const useAmmunitionFieldsFormSchema = () => {
  const { t } = useTranslations();

  const schema = yup.object({
    ammunitionTypeUniqueId: yup.string().required(t('general.field.validation.required')),
    manufacturerUniqueId: yup
      .string()
      .test('manufacturerUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    productName: yup
      .string()
      .max(80, t('general.field.validation.maxCharacters').replace('{0}', '80')),
    factoryUniqueId: yup
      .string()
      .test('factoryUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    calibreUniqueId: yup
      .string()
      .test('calibreUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    cartridgeCompositionUniqueId: yup
      .string()
      .test('cartridgeCompositionUniqueId', '', (val, obj: any) =>
        isFieldRequired(val, obj, t),
      ),
    colourOfPrimerSealantUniqueId: yup
      .string()
      .test('colourOfPrimerSealantUniqueId', '', (val, obj: any) =>
        isFieldRequired(val, obj, t),
      ),
    projectileShapeUniqueId: yup
      .string()
      .test('projectileShapeUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    functionalTypeUniqueId: yup
      .string()
      .test('functionalTypeUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    munitionTypeUniqueId: yup
      .string()
      .test('munitionTypeUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    shotSizeUniqueId: yup
      .string()
      .test('shotSizeUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    munitionVelocityUniqueId: yup
      .string()
      .test('munitionVelocityUniqueId', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    headstampYearMarking: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(1)
      .max(3000)
      .test('headstampYearMarking', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    alphanumericalMarkings: yup
      .string()
      .max(256, t('general.field.validation.maxCharacters').replace('{0}', '256'))
      .trim()
      .test('alphanumericalMarkings', '', (val, obj: any) => isFieldRequired(val, obj, t)),
    notes: yup
      .string()
      .max(2000, t('general.field.validation.maxCharacters').replace('{0}', '2000')),
  });
  return { schema };
};

export default useAmmunitionFieldsFormSchema;
