import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { IAccessoriesAttachments, IAccessoriesTransactions } from 'models/ancillaries';
import { EIconTypes } from 'constants/Icons';
import { EButtonVariants } from 'constants/Buttons';
import { downloadAncillaryAttachment } from 'requests/attachment';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { EFileType } from 'constants/FileType';

interface IAncillariesTranslationsTableColumns {
  handleDelete: ((arg: string) => void) | null;
  discardableTransactionId: string | null;
  dateFormat: string;
}

export const AccessoriesTranslationsTableColumns = ({
  handleDelete,
  discardableTransactionId,
  dateFormat,
}: IAncillariesTranslationsTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'transactionType',
    title: 'accessoryDetails.transactions.transactionType.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'date',
    title: 'accessoryDetails.transactions.DateAndTime.column',
    dateFormat,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'createdDate',
    title: 'accessoryDetails.transactions.createDate.column',
    dateFormat,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f513',
    key: 'owner',
    title: 'accessoryDetails.transactions.owner.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f514',
    key: 'keeper',
    title: 'accessoryDetails.transactions.keeper.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
    key: '',
    title: 'accessoriesTable.accordion.action.column',
    isSortable: false,
    width: '80px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <div>
        <Link to={ERouteLinks.ViewTransaction.replace(':id', props.id)}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        {props.id === discardableTransactionId && handleDelete && (
          <Button
            variant={EButtonVariants.outlined}
            icon={EIconTypes.delete}
            onClick={() => handleDelete(props.id)}
          />
        )}
      </div>
    ),
  },
];

export const mapAccessoriesTranslationsToDataSource = (
  transactions: IAccessoriesTransactions[],
): ITableDataSource[] =>
  transactions.map((transaction) => ({
    id: transaction.uniqueId,
    date: transaction.date,
    createdDate: transaction.createdDate,
    transactionType: transaction.transactionType,
    owner: transaction.owner,
    keeper: transaction.keeper,
  }));

interface IAncillariesAttachmentsTableColumns {
  ancillaryId: string;
  handleView: (arg: string) => void;
  handleDelete: (arg: string) => void;
  dateFormat: string;
}

export const AccessoriesAttachmentsTableColumns = ({
  ancillaryId,
  handleView,
  handleDelete,
  dateFormat,
}: IAncillariesAttachmentsTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'createdDate',
    title: 'accessoryDetails.attachments.dateAndTime.column',
    dateFormat,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'name',
    title: 'accessoryDetails.attachments.attachmentName.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'size',
    title: 'accessoryDetails.attachments.attachmentSize.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'transactionType',
    title: 'accessoryDetails.attachments.transactionType.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f517',
    key: '',
    title: 'accessoriesTable.accordion.action.column',
    isSortable: false,
    width: '80px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <div>
        {props.isReport && props.type.toLowerCase() !== EFileType.XLSX ? (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
            onClick={() => handleView && handleView(props.id)}
          />
        ) : (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.delete}
            onClick={() => handleDelete && handleDelete(props.id)}
          />
        )}
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.download}
          onClick={() =>
            downloadAncillaryAttachment(ancillaryId, props.id, props.name, props.type)
          }
        />
      </div>
    ),
  },
];

export const mapAccessoriesAttachmentsToDataSource = (
  attachments: IAccessoriesAttachments[],
): ITableDataSource[] =>
  attachments.map((attachment) => ({
    id: attachment.uniqueId,
    createdDate: attachment.createdDate,
    name: attachment.name,
    type: attachment.type,
    size: Math.trunc(Number(attachment.size) / 1024),
    transactionType: attachment.transactionType,
  }));
