import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ICommentInTransactionBody, initialCommentFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ICommentData } from 'models/comment';
import useCommentFormSchema from 'components/organisms/CommentForm/schemaValidation';
import { useTranslations } from 'hooks/useTranslations';

export interface ICommentFormValues {
  uniqueId: string;
  title: string;
  comment: string;
}

export interface ICommentForm {
  onCancel?: () => void;
  onSubmit: (arg: ICommentFormValues) => void;
  initialFormValues?: ICommentFormValues | ICommentInTransactionBody | null;
}

const CommentForm = ({ onCancel, onSubmit, initialFormValues }: ICommentForm) => {
  const { id } = useParams<{ id: string }>();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { t } = useTranslations();
  const { schema } = useCommentFormSchema();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ICommentData | ICommentFormValues>({
    defaultValues: initialFormValues || initialCommentFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initialFormValues || initialCommentFormValues);
  }, [reset, initialFormValues]);

  const onSubmitForm = async (data: any) => {
    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({
        ...initialFormValues,
        ...data,
      });
      setIsSubmitLoading(false);
    } else {
      await onSubmit({ uniqueId: id, ...data });
      setIsSubmitLoading(false);
    }
    setIsSubmitLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={12} lg={8}>
          <Grid item xs={12}>
            <Box mb={1}>
              <FormInput
                name="title"
                label={`${t('addComment.title.label')}*`}
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mb={1}>
              <FormInput
                name="comment"
                type={EFormInputTypes.textarea}
                label={`${t('addComment.comment.label')}*`}
                setValue={setValue}
                control={control}
                errors={errors}
                multiline
                minRows={6}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <Button
              type="submit"
              fullWidth
              id="save-button"
              label={t('general.save.button')}
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} sm={6} lg={4}>
              <Button
                fullWidth
                id="cancel-button"
                label={t('general.cancel.button')}
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>
        <Grid item mt={2}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export { CommentForm };
