import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  ChartNoDataGridItem,
  DashboardBarChartWrapper,
  StyledBar,
} from 'pages/DashboardPage/BarCharts/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import {
  debounce,
  EBarChartType,
  mapChartBarColorByDictionary,
  sortChartData,
} from 'pages/DashboardPage/BarCharts/helpers';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import { getTransactionsTypes } from 'requests/dashboard';
import { parseDictionary } from 'helpers/dictionary';
import { EDashboardSortType } from 'models/dashboard';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';

interface ITransactionsChart {
  sortType: EDashboardSortType;
  setLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const TransactionsChart = ({ sortType, setLoader }: ITransactionsChart) => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [data, setData] = useState<{ label: string; value: number }[]>([]);
  const [noData, setNoData] = useState(false);
  const { transactionType, getTransactionType } = useDictionary();
  const { selectedLanguage } = useLang();
  const { t } = useTranslations();
  const [noDataText, setNoDataText] = useState<string>('');
  const { isDesktop } = useThemeBreakpoint();

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 50);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    setLoader(true);
    (async function initTransactionsData() {
      const response = await getTransactionsTypes(sortType);
      const preparedData = response ? sortChartData(response.chartValues) : [];
      setData(preparedData);
      if (response && response.chartValues.every((item) => item.value === 0)) {
        setNoData(true);
      } else {
        setNoData(false);
      }
      setLoader(false);
    })();
  }, [sortType]);

  useEffect(() => {
    getTransactionType();
  }, [selectedLanguage]);

  useEffect(() => {
    switch (sortType) {
      case EDashboardSortType.Day: {
        setNoDataText('dashboard.chart.transactions.noDataToday');
        break;
      }
      case EDashboardSortType.Week: {
        setNoDataText('dashboard.chart.transactions.noData7days');
        break;
      }
      case EDashboardSortType.Month: {
        setNoDataText('dashboard.chart.transactions.noData50days');
        break;
      }
      case EDashboardSortType.Year: {
        setNoDataText('dashboard.chart.transactions.noDataYear');
        break;
      }
      case EDashboardSortType.All: {
        setNoDataText('dashboard.chart.transactions.noDataAll');
        break;
      }
      default: {
        setNoDataText('dashboard.chart.transactions.noDataAll');
        break;
      }
    }
  }, [sortType]);

  const maxValue = Math.max(...data.map((o) => o.value));

  const chartRender = (
    <Grid
      container
      direction="column"
      alignItems="center"
      alignContent="center"
      spacing={1}
      sx={{ padding: '10px 10px' }}
    >
      {data.map((item) => (
        <Grid container item spacing={2} key={`${item.label}-${item.value}`}>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={5} sx={{ textAlign: 'right' }}>
            <Typography variant="body2">
              {parseDictionary(transactionType, 'name')[item.label]}
            </Typography>
          </Grid>
          <Grid item sm={1} xs={2} sx={{ textAlign: 'right' }}>
            <Typography variant="subtitle2">{item.value}</Typography>
          </Grid>
          <Grid item xl={7} lg={7} md={7} sm={7} xs={5}>
            <StyledBar
              color={mapChartBarColorByDictionary(item.label, EBarChartType.transactionsTypes)}
              width={((item.value / maxValue) * windowWidth) / (isDesktop ? 5 : 4)}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const noDataRender = (
    <ChartNoDataGridItem container item xs={12}>
      <Typography variant="body2">{t(noDataText)}</Typography>
    </ChartNoDataGridItem>
  );

  return (
    <DashboardBarChartWrapper>
      <Box
        sx={
          noData
            ? {
                minHeight: '120px',
              }
            : {
                minHeight: '150px',
              }
        }
      >
        <Typography variant="subtitle1" sx={{ textAlign: 'center', marginBottom: 2 }}>
          {t('dashboard.chart.transactions.label')}
        </Typography>
        {noData ? noDataRender : chartRender}
      </Box>
      <Box sx={{ padding: '20px 10px 0 10px' }}>
        <Button
          variant={EButtonVariants.outlined}
          fullWidth={true}
          label={t('dashboard.chart.transactions.seeTransactions')}
          onClick={() => navigate(ERouteLinks.Transactions)}
          size={EButtonSizes.small}
        />
      </Box>
    </DashboardBarChartWrapper>
  );
};

export default TransactionsChart;
