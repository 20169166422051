import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { Select } from 'components/molecules/Select';

export const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-root {
    height: 40px;
  }
`;

export const StyledWarningSearch = styled(Grid)`
  margin-bottom: 25px;
`;
