import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useNotificationsSetupFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    notificationsFrequency: yup.string().required(t('general.field.validation.required')),
  });

  return { schema };
};

export default useNotificationsSetupFormSchema;
