import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/globalProperty';
import { getGlobalProperties } from 'requests/globalProperty';
import { EActionTypes } from 'state/actions/globalProperty';
import { GlobalPropertyContext } from 'models/globalPropertyContext';

export const GlobalPropertyProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const getDateFormat = async () => {
    const propertiesData = await getGlobalProperties();
    if (propertiesData) {
      const dateFormat = propertiesData.find((prop) => prop.key === 'date_format')?.value;
      if (dateFormat) {
        dispatch({ type: EActionTypes.GET_DATE_FORMAT, payload: dateFormat });
      }
    } else {
      dispatch({ type: EActionTypes.GET_DATE_FORMAT, payload: [] });
    }
  };

  const value = useMemo(() => ({ ...state, getDateFormat }), [state]);

  return (
    <GlobalPropertyContext.Provider value={value}>{children}</GlobalPropertyContext.Provider>
  );
};
