import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { useState } from 'react';
import { ActionTypes } from 'state/actions/alert';
import { updateDateTimeSetup, getDateTimeSetupDetail } from 'requests/settings';
import { IQlobalPropertyDetail } from 'models/settings';
import { IFormValues } from './index';

export const useDateTimeSetup = () => {
  const [dateTimeSetup, setDateTimeSetup] = useState<IFormValues | null>(null);
  const { setAlert } = useAlert();
  const { t } = useTranslations();
  const handleUpdate = async (data: IFormValues) => {
    const response = await updateDateTimeSetup(data.dateFormat);
    if (response.status === 200) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('DateTimeSetupUpdate.success.text'),
      });
    }
    return response;
  };
  const getDateTimeSetupData = async () => {
    const dateTimeSetupData: IQlobalPropertyDetail | null = await getDateTimeSetupDetail();
    if (dateTimeSetupData) {
      setDateTimeSetup({ dateFormat: dateTimeSetupData.value });
    } else {
      setDateTimeSetup(null);
    }
  };

  return { handleUpdate, getDateTimeSetupData, dateTimeSetup };
};
