import React, { PropsWithChildren } from 'react';
import { StyledFormWrapper } from './styles';

interface IFormWrapper extends PropsWithChildren<{}> {}

const FormWrapper = ({ children }: IFormWrapper) => (
  <StyledFormWrapper>{children}</StyledFormWrapper>
);

export default FormWrapper;
