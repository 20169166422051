import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { useLang } from 'models/langContext';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { Grid, Typography } from '@mui/material';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import {
  AccessoryInformationContainer,
  AccessoryInformationTitle,
} from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/styles';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { EAccessoryLabels } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories';
import {
  getFieldsByAccessoriesType,
  getFieldsByAmmunitionType,
  getFieldsByComponentType,
} from 'pages/AncillariesPage/helpers';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import {
  EArtAmmunitionType,
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameLowerCase,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import { disableAncillary } from 'requests/ancillaries';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import {
  AmmunitionInformationContainer,
  AmmunitionInformationTitle,
} from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/styles';
import { formatDate } from 'utils/date';
import { EAmmunitionLabels } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition';
import { useGlobalProperty } from 'models/globalPropertyContext';
import {
  ComponentInformationContainer,
  ComponentInformationTitle,
} from 'pages/AncillariesPage/ComponentsPage/DetailComponents/styles';
import { EComponentLabels } from 'pages/AncillariesPage/ComponentsPage/DetailComponents';
import Link from '@mui/material/Link';

const DisableAncillaryPage = () => {
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { setAlert } = useAlert();
  const { shortDateFormat } = useGlobalProperty();
  const { id = '', type = '' } = useParams<{ id: string; type: string }>();
  const {
    accessoryDetail,
    ammunitionDetail,
    componentDetail,
    getAccessoryDetail,
    getAmmunitionDetail,
    getComponentDetail,
  } = useAncillaries();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [disableButtons, setDisableButtons] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (id && type) {
        if (type === EArtAncillaryTypeNameLowerCase.ACCESSORY) {
          await getAccessoryDetail(id);
        } else if (type === EArtAncillaryTypeNameLowerCase.AMMUNITION) {
          await getAmmunitionDetail(id);
        } else if (type === EArtAncillaryTypeNameLowerCase.COMPONENTS) {
          await getComponentDetail(id);
        }
      }
    })();
  }, [id, selectedLanguage]);

  useEffect(() => {
    let fields;
    if (accessoryDetail && accessoryDetail.typeUniqueId) {
      fields = getFieldsByAccessoriesType(accessoryDetail.typeUniqueId);
    } else if (ammunitionDetail && ammunitionDetail.typeUniqueId) {
      fields = getFieldsByAmmunitionType(ammunitionDetail.typeUniqueId as EArtAmmunitionType);
    } else if (componentDetail && componentDetail.typeUniqueId) {
      fields = getFieldsByComponentType(componentDetail.typeUniqueId as EArtComponentType);
    }

    setAccessFields(fields || []);
  }, [accessoryDetail, ammunitionDetail, componentDetail]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  const getReturnUrl = () => {
    if (accessoryDetail) {
      return ERouteLinks.ViewAccessories.replace(':id', id);
    }
    if (ammunitionDetail) {
      return ERouteLinks.ViewAmmunition.replace(':id', id);
    }
    if (componentDetail) {
      return ERouteLinks.ViewComponents.replace(':id', id);
    }
    return '';
  };

  const handleDisable = async () => {
    setDisableButtons(true);
    await disableAncillary(id);
    setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
      text: 'Ancillary has been disabled successfully.',
      variant: EAlertVariants.success,
    });
    navigate(getReturnUrl());
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.ancillaries.name'), route: ERouteLinks.Ancillaries },
          {
            label: t('ancillary.details.header'),
            route: ERouteLinks.ViewAncillary.replace(':id', id).replace(':type', type),
          },
          t('ancillary.disable.header'),
        ]}
      />
      <Box sx={{ marginBottom: '20px' }}>
        <Box mb={1}>
          <StyledPageTitle variant="h4">{t('ancillary.details.header')}</StyledPageTitle>
        </Box>

        <Box mb={4}>
          <Typography variant="body2">{t('ancillary.disable.usageInfo.text')}</Typography>
        </Box>

        <Box>
          <Alert
            text={t('ancillary.disable.confirmation.text')}
            variant={EAlertVariants.warning}
          />
        </Box>
      </Box>

      {accessoryDetail && (
        <DetailsTemplate title={accessoryDetail?.type} pageType={EDetailsPageTypes.view}>
          <AccessoryInformationContainer item xs={12} lg={12} container>
            <Grid item xs={12} lg={12} container direction="column">
              <AccessoryInformationTitle variant="subtitle1">
                {t(EAccessoryLabels.accessoryGeneralInformation)}
              </AccessoryInformationTitle>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EAccessoryLabels.accessoryType)}
              </Typography>
              <Typography variant="body2">{accessoryDetail?.type}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography
                variant="subtitle2"
                sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
              >
                {t(EAccessoryLabels.artId)}
              </Typography>
              <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                {accessoryDetail?.artId}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={6} container direction="column">
              <Link
                href={`${ERouteLinks.ViewAncillaries.replace(
                  ':id',
                  accessoryDetail.artUniqueId,
                ).replace(':type', EArtAncillaryTypeName.ACCESSORY.toLowerCase())}`}
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  fullWidth
                  label={t('accessoryDetails.showArtInformation.button')}
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                />
              </Link>
            </Grid>
            {showField('manufacturer') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.manufacturer)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.manufacturer ?? '-'}</Typography>
              </Grid>
            )}
            {showField('calibre') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.calibre)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.calibre ?? '-'}</Typography>
              </Grid>
            )}
            {showField('hostType') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.hostType)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.hostType ?? '-'}</Typography>
              </Grid>
            )}
            {showField('proofHouse') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.proofHouse)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.proofHouse ?? '-'}</Typography>
              </Grid>
            )}
            {showField('composition') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.composition)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.composition ?? '-'}</Typography>
              </Grid>
            )}
            {showField('magnification') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.magnification)}
                </Typography>
                <Typography variant="body2">
                  {accessoryDetail?.magnification ?? '-'}
                </Typography>
              </Grid>
            )}
            {showField('utility') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.utility)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.utility ?? '-'}</Typography>
              </Grid>
            )}
            {showField('reticle') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.reticle)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.reticle ?? '-'}</Typography>
              </Grid>
            )}
            {showField('lightcolour') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.lightcolour)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.lightcolour ?? '-'}</Typography>
              </Grid>
            )}
            {showField('capacity') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAccessoryLabels.capacity)}
                </Typography>
                <Typography variant="body2">{accessoryDetail?.capacity ?? '-'}</Typography>
              </Grid>
            )}
          </AccessoryInformationContainer>
          <AccessoryInformationContainer item xs={12} lg={12} container borderHidden>
            <Grid item xs={12} lg={12} container direction="column">
              <AccessoryInformationTitle variant="subtitle1">
                {t(EAccessoryLabels.accessoryDetailedInformation)}
              </AccessoryInformationTitle>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EAccessoryLabels.firecycleID)}
              </Typography>
              <Typography variant="body2">{accessoryDetail?.fireCycleId ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EAccessoryLabels.serialNumber)}
              </Typography>
              <Typography variant="body2">{accessoryDetail?.serialNumber ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EAccessoryLabels.componentState)}
              </Typography>
              <Typography variant="body2">
                <Chips text={accessoryDetail?.state ?? '-'} variant={EChipsVariants.primary} />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EAccessoryLabels.exhibitNumber)}
              </Typography>
              <Typography variant="body2">{accessoryDetail?.exhibitNumber ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EAccessoryLabels.currentOwner)}
              </Typography>
              <Typography variant="body2">{accessoryDetail?.ownerName ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EAccessoryLabels.currentKeeper)}
              </Typography>
              <Typography variant="body2">{accessoryDetail?.keeperName ?? '-'}</Typography>
            </Grid>
          </AccessoryInformationContainer>
        </DetailsTemplate>
      )}

      {ammunitionDetail && (
        <DetailsTemplate title={ammunitionDetail?.type} pageType={EDetailsPageTypes.view}>
          <>
            <AmmunitionInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <AmmunitionInformationTitle variant="subtitle1">
                  {t(EAmmunitionLabels.ammunitionGeneralInformation)}
                </AmmunitionInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EAmmunitionLabels.ammunitionType)}
                </Typography>
                <Typography variant="body2">{ammunitionDetail?.type}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                >
                  {t(EAmmunitionLabels.artId)}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {ammunitionDetail?.artId}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6} container direction="column">
                <Link
                  href={`${ERouteLinks.ViewAncillaries.replace(
                    ':id',
                    ammunitionDetail.artUniqueId,
                  ).replace(':type', EArtAncillaryTypeName.AMMUNITION.toLowerCase())}`}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    fullWidth
                    label={t('ammunitionDetails.showArtInformation.button')}
                    variant={EButtonVariants.contained}
                    size={EButtonSizes.small}
                  />
                </Link>
              </Grid>

              {showField('productionDate') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.productionDate)}
                  </Typography>
                  <Typography variant="body2">
                    {formatDate(ammunitionDetail?.productionDate, shortDateFormat)}
                  </Typography>
                </Grid>
              )}
              {showField('manufacturer') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionManufacturer)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.manufacturer}</Typography>
                </Grid>
              )}
              {showField('factory') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.factory)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.factory}</Typography>
                </Grid>
              )}
              {showField('calibreGauge') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.calibreGauge)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.calibre}</Typography>
                </Grid>
              )}
              {showField('functionalType') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.functionalType)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.functionalType}</Typography>
                </Grid>
              )}
              {showField('cartridgeComposition') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.cartridgeComposition)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.cartridgeComposition}
                  </Typography>
                </Grid>
              )}
              {showField('colourOfPrimerSealant') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.colourOfPrimerSealant)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.colourOfPrimerSealant}
                  </Typography>
                </Grid>
              )}
              {showField('projectileShape') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.projectileShape)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.projectileShape}</Typography>
                </Grid>
              )}
              {showField('headStampYearMarking') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.headStampYearMarking)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.headstampYearMarking}
                  </Typography>
                </Grid>
              )}
              {showField('alphanumericalMarkins') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.alphanumericalMarkins)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.alphanumericalMarkins}
                  </Typography>
                </Grid>
              )}
            </AmmunitionInformationContainer>
            <AmmunitionInformationContainer item xs={12} lg={12} container borderHidden>
              <Grid item xs={12} lg={12} container direction="column">
                <AmmunitionInformationTitle variant="subtitle1">
                  {t(EAmmunitionLabels.ammunitionDetailedInformation)}
                </AmmunitionInformationTitle>
              </Grid>
              {showField('fireCycleId') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.firecycleID)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.fireCycleId ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('lotNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.lotNumber)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.lotNumber ?? '-'}</Typography>
                </Grid>
              )}
              {showField('batchNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.batchNumber)}
                  </Typography>
                  <Typography variant="body2">
                    {accessoryDetail?.batchNumber ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('state') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.ammunitionState)}
                  </Typography>
                  <Typography variant="body2">
                    <Chips
                      text={ammunitionDetail?.state ?? '-'}
                      variant={EChipsVariants.primary}
                    />
                  </Typography>
                </Grid>
              )}
              {showField('ownerName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.currentOwner)}
                  </Typography>
                  <Typography variant="body2">{ammunitionDetail?.ownerName ?? '-'}</Typography>
                </Grid>
              )}
              {showField('keeperName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.currentKeeper)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.keeperName ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('manufacturedAt') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.manufacturedAt)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.manufacturedAt ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('expiryDate') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.expiryDate)}
                  </Typography>
                  <Typography variant="body2">
                    {formatDate(ammunitionDetail?.expiryDate, shortDateFormat)}
                  </Typography>
                </Grid>
              )}
              {showField('exhibitNumber') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EAmmunitionLabels.exhibitNumber)}
                  </Typography>
                  <Typography variant="body2">
                    {ammunitionDetail?.exhibitNumber ?? '-'}
                  </Typography>
                </Grid>
              )}
            </AmmunitionInformationContainer>
          </>
        </DetailsTemplate>
      )}

      {componentDetail && (
        <DetailsTemplate title={accessoryDetail?.type} pageType={EDetailsPageTypes.view}>
          <ComponentInformationContainer item xs={12} lg={12} container>
            <Grid item xs={12} lg={12} container direction="column">
              <ComponentInformationTitle variant="subtitle1">
                {t(EComponentLabels.componentGeneralInformation)}
              </ComponentInformationTitle>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.componentType)}
              </Typography>
              <Typography variant="body2">{componentDetail?.type}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography
                variant="subtitle2"
                sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
              >
                {t(EComponentLabels.artId)}
              </Typography>
              <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                {componentDetail?.artId}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={6} container direction="column">
              <Link
                href={`${ERouteLinks.ViewAncillaries.replace(
                  ':id',
                  componentDetail.artUniqueId,
                ).replace(':type', EArtAncillaryTypeName.COMPONENTS.toLowerCase())}`}
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  fullWidth
                  label={t('componentDetails.showArtInformation.button')}
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                />
              </Link>
            </Grid>

            {showField('manufacturer') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.manufacturer)}
                </Typography>
                <Typography variant="body2">{componentDetail?.manufacturer ?? '-'}</Typography>
              </Grid>
            )}
            {showField('calibre') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.calibre)}
                </Typography>
                <Typography variant="body2">{componentDetail?.calibre ?? '-'}</Typography>
              </Grid>
            )}
            {showField('composition') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.composition)}
                </Typography>
                <Typography variant="body2">{componentDetail?.composition ?? '-'}</Typography>
              </Grid>
            )}
            {showField('proofHouse') && (
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.proofHouse)}
                </Typography>
                <Typography variant="body2">{componentDetail?.proofHouse ?? '-'}</Typography>
              </Grid>
            )}
          </ComponentInformationContainer>
          <ComponentInformationContainer item xs={12} lg={12} container borderHidden>
            <Grid item xs={12} lg={12} container direction="column">
              <ComponentInformationTitle variant="subtitle1">
                {t(EComponentLabels.componentDetailedInformation)}
              </ComponentInformationTitle>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.firecycleID)}
              </Typography>
              <Typography variant="body2">{componentDetail?.fireCycleId ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.serialNumber)}
              </Typography>
              <Typography variant="body2">{componentDetail?.serialNumber ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.componentState)}
              </Typography>
              <Typography variant="body2">
                <Chips text={componentDetail?.state ?? '-'} variant={EChipsVariants.primary} />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.exhibitNumber)}
              </Typography>
              <Typography variant="body2">{componentDetail?.exhibitNumber ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.currentOwner)}
              </Typography>
              <Typography variant="body2">{componentDetail?.ownerName ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.currentKeeper)}
              </Typography>
              <Typography variant="body2">{componentDetail?.keeperName ?? '-'}</Typography>
            </Grid>
          </ComponentInformationContainer>
          <ComponentInformationContainer item xs={12} lg={12} container borderHidden>
            <Grid item xs={12} lg={12} container direction="column">
              <ComponentInformationTitle variant="subtitle1">
                {t(EComponentLabels.firearmInformation)}
              </ComponentInformationTitle>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.firearmManufacturer)}
              </Typography>
              <Typography variant="body2">{componentDetail?.frtManufacturer}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.firearmReferenceNo)}
              </Typography>
              <Typography variant="body2">{componentDetail?.frtReferenceNo}</Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={6} container direction="column">
              <Link
                href={`${ERouteLinks.FRTDetails.replace(':frtId', componentDetail.frtId)}`}
                underline="none"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                  label={t('componentDetails.showFrtInformation.button')}
                  fullWidth
                />
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.firearmFirecycleType)}
              </Typography>
              <Typography variant="body2">{componentDetail?.frtType ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.firearmMake)}
              </Typography>
              <Typography variant="body2">{componentDetail?.frtMake ?? '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t(EComponentLabels.firearmModel)}
              </Typography>
              <Typography variant="body2">{componentDetail?.frtModel ?? '-'}</Typography>
            </Grid>
          </ComponentInformationContainer>
        </DetailsTemplate>
      )}

      <Box mt={5}>
        <Button
          label={t('general.yes.button')}
          id="yes-button"
          variant={EButtonVariants.contained}
          onClick={() => handleDisable()}
          disabled={disableButtons}
          sx={{
            width: { xs: '100%', sm: '250px' },
            margin: { xs: '0px 0px 20px 0px', sm: '0px 20px 0px 0px' },
          }}
        />
        <Button
          label={t('general.no.button')}
          id="no-button"
          variant={EButtonVariants.outlined}
          disabled={disableButtons}
          onClick={() => navigate(getReturnUrl())}
          sx={{
            width: { xs: '100%', sm: '250px' },
          }}
        />
      </Box>
    </>
  );
};

export default DisableAncillaryPage;
