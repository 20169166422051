import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ILegalEntityContactTableCard {
  data: ITableDataSource;
}

const LegalEntityContactTableCard = ({ data }: ILegalEntityContactTableCard) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { hasPermissions } = usePermission();

  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        backgroundColor: colors.gray100,
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        padding: '20px',
        maxWidth: 'calc(100vw - 48px)',
      }}
      rowGap={3}
    >
      {type === ELegalEntityTypes.organisation && (
        <Grid item xs={12} sm={6} lg={3}>
          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
            {t('legalEntityDetails.contactTab.contactName.column')}
          </Typography>
          <Typography variant="body2">{emptyFieldHolder(data.name)}</Typography>
        </Grid>
      )}
      {type === ELegalEntityTypes.organisation && (
        <Grid item xs={12} sm={6} lg={3}>
          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
            {t('legalEntityDetails.contactTab.role.column')}
          </Typography>
          <Typography variant="body2">{emptyFieldHolder(data.role)}</Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.contactTab.phoneNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.phoneNumber)}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntityDetails.contactTab.emailAddress.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.email)}</Typography>
      </Grid>

      {hasPermissions([EPermission.MANAGE_LE, EPermission.DELETE_LE], true) && (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
            mt: {
              xs: 0,
            },
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {t('legalEntityDetails.contactTab.action.column')}:{' '}
            </Typography>
          </Grid>

          {hasPermissions([EPermission.MANAGE_LE]) && (
            <Grid item xs={12} sm={3}>
              <Button
                label={t('legalEntityDetails.contactTab.edit.link')}
                variant={EButtonVariants.outlined}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.LegalEntityEditContact.replace(':id', id as string).replace(
                      ':contactId',
                      data.id as string,
                    )}?type=${type}&expand=contact`,
                  )
                }
                permission={[EPermission.MANAGE_LE]}
                size="small"
                fullWidth
              />
            </Grid>
          )}
          {hasPermissions([EPermission.DELETE_LE]) && (
            <Grid item xs={12} sm={3}>
              <Button
                label={t('legalEntityDetails.contactTab.delete.link')}
                variant={EButtonVariants.outlined}
                onClick={() =>
                  navigate(
                    `${ERouteLinks.LegalEntityDeleteContact.replace(
                      ':id',
                      id as string,
                    ).replace(':contactId', data.id as string)}?type=${type}&expand=contact`,
                  )
                }
                size="small"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export { LegalEntityContactTableCard };
