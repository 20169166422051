import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Pagination } from 'components/molecules/Pagination';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useAlert } from 'models/alertContext';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { ActionTypes } from 'state/actions/alert';
import { Loader } from 'components/atoms/Loader';
import { EPerPages, ITableDataSource } from 'models/table';
import {
  IFormElementOptionProp,
  initialAncillariesSearchFormValues,
  ISearchAncillariesFormValues,
} from 'models/form';
import { ECustomFieldObjectType, ICustomFieldByObjectType } from 'models/customField';
import { useLang } from 'models/langContext';
import { AmmunitionSearchForm } from 'pages/AncillariesPage/AmmunitionPage/AmmunitionSearchForm';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { StyledPageTitle } from 'theme/styles';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Box } from '@mui/material';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { LegalEntityGroupSearchAncillaryTableCard } from 'components/organisms/LegalEntityGroupSearchAncillaryTableCard';
import { CardList } from 'components/molecules/CardList';
import {
  mapLegalityActivityMapColor,
  mapLegalityActivityMapHover,
  renderDataColor,
} from 'components/molecules/Table/helpers';
import { IAncillary } from 'models/ancillaries';
import { formatDateTimeToIso } from 'utils/date';
import {
  customFieldsPrepareToSearch,
  prepareDataWithCustomFieldsToSearch,
} from 'helpers/searchQuery';
import { StyledSelect, StyledWarningSearch } from '../styles';
import { AncillaryTableColumns, mapAncillaryGroupAncillariesToDataSource } from '../helpers';
import { AccessoriesSearchForm } from '../../AncillariesPage/AccessoriesPage/AccessoriesSearchForm';
import { ComponentsSearchForm } from '../../AncillariesPage/ComponentsPage/ComponentsSearchForm';
import { useLegalEntities } from '../hooks';

const LegalEntityAddAncillaryGroupSearchPage = () => {
  const { id, groupId } = useParams<{ id: string; groupId: string }>();
  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();

  const query = useQuery();
  const type: string | null = query.get('type');

  const { state: routerState, pathname }: any = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPageAncillaries, setPerPageAncillaries] = useState<EPerPages>(EPerPages.perPage25);
  const [ancillariesQuery, setAncillariesQuery] = useState<
    Partial<ISearchAncillariesFormValues>
  >({});
  const [ownerOptions, setOwnerOptions] = useState<IFormElementOptionProp[]>([]);
  const [keeperOptions, setKeeperOptions] = useState<IFormElementOptionProp[]>([]);
  const [selectedAncillaries, setSelectedAncillaries] = useState<IAncillary[]>(
    routerState?.selectedAncillaries || routerState?.prevSelectedAncillaries || [],
  );
  const [areAllAncillariesSelected, setAreAllAncillariesSelected] = useState<boolean>(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
  const { selectedLanguage } = useLang();
  const { clearAlert, setAlert, samePageAlert } = useAlert();

  const {
    loadDictionaries,
    setAncillaryOption,
    artAncillaryOptions,
    ancillaries,
    getCustomFields,
    customFieldsAmmunition,
    customFieldsAccessories,
    customFieldsComponents,
    ancillaryOption,
    getAncillariesGroupSearchData,
    getAncillariesByGroupIdSearchData,
    paginatorAncillaries,
  } = useAncillaries();

  const { handleAddAncillaryToGroup } = useLegalEntities();

  const isAddToGroup = useMatch(ERouteLinks.AddToAncillaryGroup);

  let prevPage: any;
  if (routerState?.prevPage) {
    prevPage = routerState?.prevPage;
  } else {
    prevPage = isAddToGroup
      ? ERouteLinks.ViewLegalEntity
      : ERouteLinks.LegalEntityAddAncillaryGroup;
  }

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (ancillaries.length === 0) return;
    let allSelected = true;
    const selectedIds = selectedAncillaries.map(
      (selectedAncillary) => selectedAncillary.uniqueId,
    );

    ancillaries
      .map((ancillary) => ancillary.uniqueId)
      .forEach((ancillaryId) => {
        if (!selectedIds.includes(ancillaryId)) {
          allSelected = false;
        }
      });
    setAreAllAncillariesSelected(allSelected);
  }, [ancillaries, selectedAncillaries]);

  useEffect(() => {
    if (routerState?.ancillaryOption) {
      setAncillaryOption(routerState?.ancillaryOption);
    }
  }, []);

  const handleChangeSelectedAncillaries = (ancillary: IAncillary) => {
    const isInSelectedAncillaries = selectedAncillaries.find(
      (item: IAncillary) => item.uniqueId === ancillary.uniqueId,
    );
    setSelectedAncillaries((prevState: IAncillary[]) =>
      // @ts-ignore
      isInSelectedAncillaries
        ? // @ts-ignore
          prevState.filter((item: IAncillary) => item.uniqueId !== ancillary.uniqueId)
        : [...prevState, ancillary],
    );
  };

  const handleSelectAllAncillaries = () => {
    if (areAllAncillariesSelected) {
      const ancillariesIds = ancillaries.map((ancillary) => ancillary.uniqueId);
      const newSelected = selectedAncillaries.filter(
        (selectedAncillary) => !ancillariesIds.includes(selectedAncillary.uniqueId),
      );
      setSelectedAncillaries(newSelected);
    } else {
      const newSelected = [...selectedAncillaries];
      ancillaries.forEach((ancillary) => {
        if (
          !selectedAncillaries
            .map((selectedAncillary) => selectedAncillary.uniqueId)
            .includes(ancillary.uniqueId)
        ) {
          newSelected.push(ancillary);
        }
      });
      setSelectedAncillaries(newSelected);
    }
  };

  const handleArtAncillaryNames = (name: string) => {
    const translationMap: Record<string, string> = {
      Accessories: 'ancillaries.accessories.accordion.name',
      Ammunition: 'ancillaries.ammunition.accordion.name',
      Components: 'ancillaries.components.accordion.name',
    };
    return translationMap[name] ? t(translationMap[name]) : name;
  };

  const mapArtAncillaryDictionaryToOptionProp = (
    dictionary: IDictionary[] | null,
  ): IFormElementOptionProp[] => {
    if (!dictionary) {
      return [];
    }
    return dictionary
      .filter((item) => item.isActive)
      .map((item) => ({
        label: handleArtAncillaryNames(item.name),
        value: item.uniqueId,
      }));
  };

  useEffect(() => {
    if (ancillaryOption) {
      setShowAllOptions(true);
    } else {
      setShowAllOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ancillaryOption, ancillaries]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async () => {
      await getCustomFields([
        ECustomFieldObjectType.AN_AMMUNITION,
        ECustomFieldObjectType.AN_ACCESSORY,
        ECustomFieldObjectType.AN_COMPONENT,
      ]);
    })();
  }, []);

  useEffect(() => {
    if (ancillaryOption) {
      setIsPaginationLoading(true);
      setIsLoading(true);

      customFieldsPrepareToSearch(ancillariesQuery.customFields);
      let queryData;
      if (ancillaryOption === EArtAncillaryType.ACCESSORIES) {
        queryData = prepareDataWithCustomFieldsToSearch(
          ancillariesQuery,
          customFieldsAccessories.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          ),
        );
      } else if (ancillaryOption === EArtAncillaryType.AMMUNITION) {
        queryData = prepareDataWithCustomFieldsToSearch(
          ancillariesQuery,
          customFieldsAmmunition.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          ),
        );
      } else if (ancillaryOption === EArtAncillaryType.COMPONENTS) {
        queryData = prepareDataWithCustomFieldsToSearch(
          ancillariesQuery,
          customFieldsComponents.filter(
            (item: ICustomFieldByObjectType) => item.searchCriteria,
          ),
        );
      }

      if (Object.keys(ancillariesQuery).length > 0) {
        if (isAddToGroup) {
          getAncillariesByGroupIdSearchData(
            id as string,
            groupId as string,
            ancillaryOption,
            currentPage,
            perPageAncillaries,
            {
              ...queryData,
            },
          );
        } else {
          getAncillariesGroupSearchData(
            id as string,
            ancillaryOption,
            routerState.prevSelectedAncillaries,
            currentPage,
            perPageAncillaries,
            {
              ...queryData,
            },
          );
        }
      }

      setIsPaginationLoading(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, perPageAncillaries, ancillaryOption, ancillariesQuery]);

  const handleAddSelectedAncillaries = async () => {
    clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);

    const properStates = selectedAncillaries.every(
      (selectedAncillary: IAncillary, index: number, allAncillaries: IAncillary[]) =>
        allAncillaries[0].stateUniqueId === selectedAncillary.stateUniqueId &&
        allAncillaries[0].ownerUniqueId === selectedAncillary.ownerUniqueId &&
        allAncillaries[0].keeperUniqueId === selectedAncillary.keeperUniqueId,
    );
    if (!properStates) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('legalEntityGroup.searchAncillary.pleaseChooseSame'),
        variant: EAlertVariants.error,
      });
      return;
    }

    setIsLoading(true);

    if (isAddToGroup) {
      try {
        const ids = selectedAncillaries.map((a) => a.uniqueId);
        await handleAddAncillaryToGroup(id as string, ids, groupId as string);
        navigate(
          `${prevPage
            .replace(':id', id ?? '')
            .replace(':groupId', groupId)}?type=${type}&expand=ancillaryGroups`,
        );
      } catch (e: any) {
        setAlert(ActionTypes.SET_LEGAL_ENTITY_ALERT, {
          text: e.response.data.errors[0].message,
          variant: EAlertVariants.error,
        });
      }
    } else {
      navigate(
        `${prevPage
          .replace(':id', id ?? '')
          .replace(':groupId', groupId)}?type=${type}&expand=ancillaryGroups`,
        {
          state: {
            groupName: routerState.groupName,
            selected: selectedAncillaries,
          },
        },
      );
    }
    setIsLoading(false);
  };

  const handleSetAmmunitionQuery = (values: Partial<ISearchAncillariesFormValues>) => {
    const formattedValues = { ...values };
    formattedValues.preset = undefined;
    formattedValues.formSubmitted = undefined;
    formattedValues.ownerName = undefined;
    formattedValues.keeperName = undefined;

    if (formattedValues.expiryDateFrom) {
      formattedValues.expiryDateFrom = formatDateTimeToIso(formattedValues.expiryDateFrom);
    }
    if (formattedValues.expiryDateTo) {
      formattedValues.expiryDateTo = formatDateTimeToIso(formattedValues.expiryDateTo);
    }
    if (formattedValues.manufactureDateFrom) {
      formattedValues.manufactureDateFrom = formatDateTimeToIso(
        formattedValues.manufactureDateFrom,
      );
    }
    if (formattedValues.manufactureDateTo) {
      formattedValues.manufactureDateTo = formatDateTimeToIso(
        formattedValues.manufactureDateTo,
      );
    }

    setAncillariesQuery(formattedValues);
  };

  const handleSetAccessoryQuery = (values: Partial<ISearchAncillariesFormValues>) => {
    const formattedValues = { ...values };
    formattedValues.preset = undefined;
    formattedValues.formSubmitted = undefined;
    formattedValues.ownerName = undefined;
    formattedValues.keeperName = undefined;

    setAncillariesQuery(formattedValues);
  };

  const handleSetComponentsQuery = (values: Partial<ISearchAncillariesFormValues>) => {
    const formattedValues = { ...values };
    formattedValues.preset = undefined;
    formattedValues.formSubmitted = undefined;
    formattedValues.ownerName = undefined;
    formattedValues.keeperName = undefined;

    setAncillariesQuery(formattedValues);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${type}`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${prevPage
              .replace(':id', id ?? '')
              .replace(':groupId', groupId)}?type=${type}`,
          },
          t('searchForAncillary.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('searchForAncillary.header')}</StyledPageTitle>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Grid item>
          <Button
            fullWidth
            label={t('general.back.link')}
            variant={EButtonVariants.outlined}
            size={EButtonSizes.small}
            icon={EIconTypes.left}
            onClick={
              !isAddToGroup
                ? () => {
                    navigate(
                      `${prevPage
                        .replace(':id', id ?? '')
                        .replace(':groupId', groupId)}?type=${type}&expand=ancillaryGroups`,
                      {
                        state: {
                          groupName: routerState.groupName,
                          selected: routerState.prevSelectedAncillaries,
                        },
                      },
                    );
                  }
                : () =>
                    navigate(
                      `${prevPage
                        .replace(':id', id ?? '')
                        .replace(':groupId', groupId)}?type=${type}&expand=ancillaryGroups`,
                    )
            }
          />
        </Grid>
      </Grid>

      {!showAllOptions && (
        <StyledWarningSearch item xs={12}>
          <Alert
            text={t('searchForAncillary.pleaseSelectAnAncillary.label')}
            variant={EAlertVariants.warning}
          />
        </StyledWarningSearch>
      )}
      <SearchWrapper>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <StyledSelect
              options={mapArtAncillaryDictionaryToOptionProp(artAncillaryOptions)}
              name="ancillaryOptionName"
              label={t('ancillariesReferenceTable.accordion.ancillary.label')}
              value={ancillaryOption}
              setValue={(value: string) => {
                setIsLoading(true);
                navigate(window.location.pathname + window.location.search, {
                  replace: true,
                  state: {
                    groupName: routerState?.groupName,
                    prevSelectedAncillaries: routerState?.prevSelectedAncillaries,
                  },
                });
                setAncillaryOption(value);
              }}
            />
          </Grid>
        </Grid>
      </SearchWrapper>

      {isLoading && <Loader isLoading={isLoading} />}

      {!isLoading && showAllOptions && (
        <>
          {ancillaryOption && ancillaryOption === EArtAncillaryType.AMMUNITION && (
            <AmmunitionSearchForm
              ownerOptions={ownerOptions}
              keeperOptions={keeperOptions}
              setOwnerOptions={setOwnerOptions}
              setKeeperOptions={setKeeperOptions}
              searchAncillaryFromLE={true}
              returnPath={`${pathname}?type=${type}&expand=ancillary-groups`}
              onSubmit={handleSetAmmunitionQuery}
              initialFormValues={initialAncillariesSearchFormValues}
              customFields={customFieldsAmmunition.filter(
                (item: ICustomFieldByObjectType) => item.searchCriteria,
              )}
              addAncillaryToGroupContext={true}
              selectedAncillaries={selectedAncillaries}
            />
          )}

          {ancillaryOption && ancillaryOption === EArtAncillaryType.COMPONENTS && (
            <ComponentsSearchForm
              ownerOptions={ownerOptions}
              keeperOptions={keeperOptions}
              setOwnerOptions={setOwnerOptions}
              setKeeperOptions={setKeeperOptions}
              searchAncillaryFromLE={true}
              returnPath={`${pathname}?type=${type}&expand=ancillary-groups`}
              onSubmit={handleSetComponentsQuery}
              initialFormValues={initialAncillariesSearchFormValues}
              customFields={customFieldsComponents.filter(
                (item: ICustomFieldByObjectType) => item.searchCriteria,
              )}
              addAncillaryToGroupContext={true}
              selectedAncillaries={selectedAncillaries}
            />
          )}

          {ancillaryOption && ancillaryOption === EArtAncillaryType.ACCESSORIES && (
            <AccessoriesSearchForm
              ownerOptions={ownerOptions}
              keeperOptions={keeperOptions}
              setOwnerOptions={setOwnerOptions}
              setKeeperOptions={setKeeperOptions}
              searchAncillaryFromLE={true}
              returnPath={`${pathname}?type=${type}&expand=ancillary-groups`}
              onSubmit={handleSetAccessoryQuery}
              initialFormValues={initialAncillariesSearchFormValues}
              customFields={customFieldsAccessories.filter(
                (item: ICustomFieldByObjectType) => item.searchCriteria,
              )}
              addAncillaryToGroupContext={true}
              selectedAncillaries={selectedAncillaries}
            />
          )}

          {isDesktop ? (
            <Table
              columns={AncillaryTableColumns({
                handleView: (ancillaryId: string, ancillaryType: string) =>
                  window.open(
                    ERouteLinks.ViewAncillary.replace(':id', ancillaryId).replace(
                      ':type',
                      ancillaryType,
                    ),
                    '_blank',
                  ),
                callback: (selectedAncillariesData: IAncillary) =>
                  handleChangeSelectedAncillaries(selectedAncillariesData),
                selectAllCallback: handleSelectAllAncillaries,
                areAllAncillariesSelected,
                selectedAncillaries,
                renderStateFieldColor: (data: ITableDataSource) =>
                  renderDataColor(data).stateField,
              })}
              specificRowColor={mapLegalityActivityMapColor}
              specificRowColorHover={mapLegalityActivityMapHover}
              dataSource={mapAncillaryGroupAncillariesToDataSource(ancillaries)}
              translationsKeys={{
                noResults: 'ancillaries.accordion.noResults.text',
              }}
            />
          ) : (
            <div>
              <CardList
                items={mapAncillaryGroupAncillariesToDataSource(ancillaries)}
                render={(data, index) => (
                  <LegalEntityGroupSearchAncillaryTableCard
                    data={data}
                    key={index}
                    selectAction={(selected) => handleChangeSelectedAncillaries(selected)}
                    selectedAncillaries={selectedAncillaries}
                    renderColor={renderDataColor}
                  />
                )}
              />
            </div>
          )}

          {ancillaryOption && paginatorAncillaries && (
            <Pagination
              count={paginatorAncillaries?.totalElements}
              perPage={perPageAncillaries}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
              onChangePerPage={(value) => {
                setPerPageAncillaries(value);
              }}
              current={currentPage}
              isLoading={isPaginationLoading}
            />
          )}
        </>
      )}
      <Grid container sx={{ mt: 2, mb: 2, marginTop: '20px' }} spacing={2}>
        {!isLoading && showAllOptions && ancillaries.length ? (
          <>
            {selectedAncillaries.length > 0 && (
              <Grid item>
                <Button
                  fullWidth
                  label={t('ancillaries.addSelected.button')}
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                  isLoading={isLoading}
                  onClick={() => handleAddSelectedAncillaries()}
                />
              </Grid>
            )}
            <Grid item>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                variant={EButtonVariants.outlined}
                size={EButtonSizes.small}
                onClick={
                  !isAddToGroup
                    ? () => {
                        navigate(
                          `${prevPage
                            .replace(':id', id ?? '')
                            .replace(
                              ':groupId',
                              groupId,
                            )}?type=${type}&expand=ancillaryGroups`,
                          {
                            state: {
                              groupName: routerState.groupName,
                              selected: routerState.prevSelectedAncillaries,
                            },
                          },
                        );
                      }
                    : () =>
                        navigate(
                          `${prevPage
                            .replace(':id', id ?? '')
                            .replace(
                              ':groupId',
                              groupId,
                            )}?type=${type}&expand=ancillaryGroups`,
                        )
                }
              />
            </Grid>
          </>
        ) : (
          ''
        )}
        <Grid item xs={12}>
          {samePageAlert && (
            <Box mt={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LegalEntityAddAncillaryGroupSearchPage;
