import React, { ReactElement, useEffect, useState } from 'react';
import LegalEntitiesChart from 'pages/DashboardPage/PieCharts/LegalEntitiesChart';
import Grid from '@mui/material/Grid';
import FirearmsChart from 'pages/DashboardPage/PieCharts/FirearmsChart';
import AncillariesChart from 'pages/DashboardPage/PieCharts/AncillariesChart';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import TransactionsChart from 'pages/DashboardPage/BarCharts/TransactionsChart';
import FirearmsStatesChart from 'pages/DashboardPage/BarCharts/FirearmsStatesChart';
import GlobalSearch from 'pages/DashboardPage/GlobalSearch';
import { StyledDashboardButton } from 'pages/DashboardPage/GlobalSearch/styles';
import { EDashboardSortType } from 'models/dashboard';
import { DashboardSortButton } from 'pages/DashboardPage/DashboardSortButton';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { StyledDashboardSortButtonsWrapper } from 'pages/DashboardPage/styles';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { Loader } from 'components/atoms/Loader';
import { Box } from '@mui/system';
import { DashboardSortSelect } from './DashboardSortSelect';

const DashboardPage = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { isDesktop } = useThemeBreakpoint();
  const { hasPermissions } = usePermission();
  const [selectedSortType, setSelectedSortType] = useState<EDashboardSortType>(
    EDashboardSortType.All,
  );
  const [isLoader, setLoader] = useState(true);
  const [isFirearmsStatesLoader, setFirearmsStatesLoader] = useState(true);
  const [isTransactionsLoader, setTransactionsLoader] = useState(true);
  const [isFirearmsLegalityLoader, setFirearmsLegalityLoader] = useState(true);
  const [isAncillariesTypesLoader, setAncillariesTypesLoader] = useState(true);
  const [isLegalEntitiesLoader, setLegalEntitiesLoader] = useState(true);

  useEffect(() => {
    if (
      !isFirearmsStatesLoader &&
      !isTransactionsLoader &&
      !isFirearmsLegalityLoader &&
      !isLegalEntitiesLoader &&
      !isAncillariesTypesLoader
    ) {
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [
    isFirearmsStatesLoader,
    isTransactionsLoader,
    isFirearmsLegalityLoader,
    isLegalEntitiesLoader,
    isAncillariesTypesLoader,
  ]);

  return (
    <>
      <StyledPageTitle variant="h4">{t('module.dashboard.name')}</StyledPageTitle>
      <Grid container spacing={2} mb={1} mt={1}>
        {hasPermissions(EPermission.MANAGE_LE) && (
          <Grid container item spacing={2}>
            <Grid item xs={12} lg={6}>
              <StyledDashboardButton
                variant={EButtonVariants.contained}
                icon={EIconTypes.plus}
                label={t('dashboard.button.add.legal.entity.individual')}
                fullWidth
                onClick={() => navigate(`${ERouteLinks.AddLegalEntity}?type=individual`)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <StyledDashboardButton
                variant={EButtonVariants.contained}
                icon={EIconTypes.plus}
                label={t('dashboard.button.add.legal.entity.organisation')}
                fullWidth
                onClick={() => navigate(`${ERouteLinks.AddLegalEntity}?type=organisation`)}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <GlobalSearch />
        </Grid>

        {hasPermissions(EPermission.VIEW_DASHBOARD_CHARTS) && (
          <>
            {!isDesktop && (
              <Grid item xs={12}>
                <DashboardSortSelect
                  selectedSortType={selectedSortType}
                  setSelectedSortType={setSelectedSortType}
                />
              </Grid>
            )}

            {isDesktop && (
              <Grid item xs={12} mb={2}>
                <StyledDashboardSortButtonsWrapper>
                  {Object.values(EDashboardSortType).map((sortType) => (
                    <DashboardSortButton
                      selected={selectedSortType === sortType}
                      setSelectedSortType={setSelectedSortType}
                      sortType={sortType}
                    />
                  ))}
                </StyledDashboardSortButtonsWrapper>
              </Grid>
            )}
            <Grid item xs={12}>
              {isLoader && <Loader isLoading={isLoader} />}
              <Box display={!isLoader ? 'block' : 'none'}>
                <Grid container spacing={2}>
                  <Grid item lg={4} xs={12}>
                    <LegalEntitiesChart
                      sortType={selectedSortType}
                      setLoader={setLegalEntitiesLoader}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <FirearmsChart
                      sortType={selectedSortType}
                      setLoader={setFirearmsLegalityLoader}
                    />
                  </Grid>
                  <Grid item lg={4} xs={12}>
                    <AncillariesChart
                      sortType={selectedSortType}
                      setLoader={setAncillariesTypesLoader}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                  <Grid item lg={6} xs={12} display="grid">
                    <FirearmsStatesChart
                      sortType={selectedSortType}
                      setLoader={setFirearmsStatesLoader}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TransactionsChart
                      sortType={selectedSortType}
                      setLoader={setTransactionsLoader}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
export { DashboardPage };
