import { ITableColumns, ITableDataSource } from 'models/table';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import React from 'react';
import { IAddTrustedDeviceResponse } from 'models/trustedDevice';

export const mapTrustedDevicesToDataSource = (
  devices: IAddTrustedDeviceResponse[],
): ITableDataSource[] =>
  devices.map((device) => ({
    id: device.uniqueId,
    title: device.title,
    deviceToken: device.deviceToken,
  }));

interface ITrustedDevicesTableColumns {
  handleDelete: (arg: string) => void;
}

export const trustedDevicesTableColumns = ({
  handleDelete,
}: ITrustedDevicesTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-a169-304a6f32f511',
    key: 'title',
    title: 'userSettings.trustedDevices.trustedDevices.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-a269-304a6f32f511',
    key: '',
    width: '100px',
    align: 'center',
    title: 'userSettings.trustedDevices.actions.column',
    isCenterTableHead: true,
    isSortable: false,
    render: (_data, props) => (
      <Button
        variant={EButtonVariants.outlined}
        tableButton={true}
        icon={EIconTypes.delete}
        onClick={() => handleDelete(props.id)}
      />
    ),
  },
];
