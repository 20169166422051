import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { getLegalEntityContact } from 'requests/legalEntities';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { StyledDetailsWrapper } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage/styles';
import { ILegalEntityContact } from 'models/legalEntity';
import { useDictionary } from 'models/dictionaryContext';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import { useTranslations } from 'hooks/useTranslations';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

const LegalEntityDeleteContactPage = () => {
  const { id, contactId } = useParams<{ id: string; contactId: string }>();
  const query = useQuery();
  const [legalEntityContact, setLegalEntityContact] = useState<ILegalEntityContact | null>();
  const { t } = useTranslations();

  const { handleDeleteContact, navigateToDetails } = useLegalEntities();

  const { phoneCode } = useDictionary();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const type: string = query.get('type') || '';

  useEffect(() => {
    (async function getLegalEntityContactDataInit() {
      if (id && contactId) {
        const response = await getLegalEntityContact(id, contactId);
        if (response) {
          setLegalEntityContact(response);
        }
      }
    })();
  }, [id, contactId]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (id && legalEntityContact) {
        const result = await handleDeleteContact(id, legalEntityContact.uniqueId);

        if (result) {
          navigateToDetails();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  const parsedPhoneCodeDictionary: IParsedDictionary = parseDictionary(phoneCode, 'name');

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(':id', id as string)}`,
          },
          t('deleteContact.header'),
        ]}
      />
      {legalEntityContact && (
        <>
          <StyledPageTitle variant="h4">{t('deleteContact.header')}</StyledPageTitle>

          <Box my={3}>
            <Alert text={t('deleteContact.info.text')} variant={EAlertVariants.warning} />
          </Box>

          <StyledDetailsWrapper container rowGap={3}>
            {(type as ELegalEntityTypes) === ELegalEntityTypes.organisation && (
              <>
                <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('deleteContact.contactName.label')}
                  </Typography>
                  <Typography variant="body2">{legalEntityContact.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('deleteContact.role.label')}
                  </Typography>
                  <Typography variant="body2">{legalEntityContact.role}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteContact.emailAddress.label')}
              </Typography>
              <Typography variant="body2">{legalEntityContact.email || '-'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteContact.phoneNumber.label')}
              </Typography>
              <Typography variant="body2">
                {legalEntityContact.phoneNumber.codeDictionaryUniqueId &&
                parsedPhoneCodeDictionary?.[
                  legalEntityContact.phoneNumber.codeDictionaryUniqueId
                ]
                  ? `${
                      parsedPhoneCodeDictionary?.[
                        legalEntityContact.phoneNumber.codeDictionaryUniqueId
                      ]
                    }${legalEntityContact.phoneNumber.value}`
                  : ''}
              </Typography>
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.yes.button')}
                id="yes-button"
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.no.button')}
                id="no-button"
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { LegalEntityDeleteContactPage };
