import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useParams } from 'react-router-dom';
import { useFirearms } from 'pages/Firearms/hooks';
import { CommentForm, ICommentFormValues } from 'components/organisms/CommentForm';
import { useTranslations } from 'hooks/useTranslations';

const RegisteredFirearmAddCommentPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { handleCreateComment, navigateToDetails } = useFirearms();
  const { t } = useTranslations();

  const handleSubmit = async (data: ICommentFormValues) => {
    const result = await handleCreateComment(data);
    if (result) {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.accordion.name'),
            route: ERouteLinks.Firearms,
          },
          {
            label: t('registeredFirearms.firearmsDetails.header'),
            route: `${ERouteLinks.ViewRegisteredFirearm.replace(
              ':id',
              id as string,
            )}?expand=comments`,
          },
          t('registeredFirearms.firearmsDetails.comments.accordion.addComment.button'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('registeredFirearms.firearmsDetails.comments.accordion.addComment.button')}
      </StyledPageTitle>

      <FormWrapper>
        <CommentForm onCancel={navigateToDetails} onSubmit={handleSubmit} />
      </FormWrapper>
    </>
  );
};

export { RegisteredFirearmAddCommentPage };
