import styled from '@emotion/styled';

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  min-height: calc(100vh - 34px);
  flex-direction: column;
  padding: 24px;
`;

const StyledLanguageSelectWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledLanguageSelectOuterWrapper = styled.div`
  position: relative;
`;

export { PageWrapper, StyledLanguageSelectWrapper, StyledLanguageSelectOuterWrapper };
