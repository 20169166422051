import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { IOrganizationLegalEntityListResponse } from 'models/organizationLegalEntity';
import { EPerPages, ISortOptions } from 'models/table';
import { queryStringWithSort } from 'helpers/request';
import { ISearchFormValues } from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { clearObject } from 'helpers/objects';

const getOrganizationLegalEntities = async (
  currentPage: number,
  perPage: EPerPages,
  sort: ISortOptions | null | undefined,
  query: Partial<ISearchFormValues>,
) => {
  const qString = queryStringWithSort(
    {
      page: currentPage - 1,
      size: perPage,
    },
    sort,
  );

  const body = {
    ...query,
  };

  delete body.preset;

  const { data } = await apiClient.post<TResponse<IOrganizationLegalEntityListResponse>>(
    `${ApiEndpoints.GET_ORGANIZATION_LEGAL_ENTITIES()}?${qString}`,
    clearObject(body),
  );

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

export { getOrganizationLegalEntities };
