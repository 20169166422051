import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser } from 'requests/user';
import { IUser } from 'models/user';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';
import { Typography } from '@mui/material';
import { useUsers } from 'pages/UsersPage/hooks';
import { useTranslations } from 'hooks/useTranslations';

const GeneratePasswordUserPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser | null>();
  const [password, setPassword] = useState<string>('*********');
  const { handleRegeneratePassword } = useUsers();
  const { t } = useTranslations();

  useEffect(() => {
    (async function getUserDataInit() {
      if (id) {
        const response = await getUser(id);
        if (response) {
          setUser(response);
        }
      }
    })();
  }, [id]);

  const handleRegenerate = async () => {
    const response = await handleRegeneratePassword(user?.email as string);
    if (response) {
      setPassword(response.newPassword);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('user.breadcrumbs.users'),
            route: ERouteLinks.Users,
          },
          {
            label: t('user.breadcrumbs.userDetails'),
            route: `${ERouteLinks.ViewUser.replace(':id', id)}`,
          },
          t('generatePassword.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('generatePassword.header')}</StyledPageTitle>
      <Grid container>
        <Grid
          item
          container
          xs={12}
          lg={6}
          mb={4}
          pb={2}
          sx={{
            border: `1px solid ${colors.gray200}`,
            borderRadius: '4px',
            overflow: 'hidden',
            mt: 1,
            backgroundColor: colors.gray100,
          }}
        >
          <Grid item xs={12} md={6} lg={6} px={2} pt={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('generatePassword.username.label')}
            </Typography>
            <Typography variant="body2">{user?.email}</Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={6} px={2} pt={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('generatePassword.password.label')}
            </Typography>
            <Typography variant="body2">{password}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} lg={12}>
          <Grid item lg={2} md={6} xs={12}>
            <Button
              fullWidth
              label={t('general.back.link')}
              variant={EButtonVariants.outlined}
              size={EButtonSizes.small}
              icon={EIconTypes.left}
              onClick={() => navigate(-1)}
            />
          </Grid>
          <Grid item lg={2} md={6} xs={12}>
            <Button
              fullWidth
              label={t('generatePassword.regenerate.button')}
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              onClick={handleRegenerate}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { GeneratePasswordUserPage };
