import { useState } from 'react';
import {
  getETraceSetup,
  getIArmsSetup,
  updateETraceSetup,
  updateIArmsSetup,
} from 'requests/settings';
import { IDocumentsGeneratingFormValues } from 'pages/SystemSettingsPage/DocumentsGeneratingSetupPage/index';

export const useDocumentsGeneratingSetup = () => {
  const [eTraceEnabled, setETraceEnabled] = useState<boolean | null>(null);
  const [iArmsEnabled, setIArmsEnabled] = useState<boolean | null>(null);

  const loadInitData = () => {
    getIArmsSetup().then((result) => setIArmsEnabled(result?.value === 'true'));
    getETraceSetup().then((result) => setETraceEnabled(result?.value === 'true'));
  };

  const updateDocumentsGeneratingData = async (data: IDocumentsGeneratingFormValues) => {
    if (data.eTraceEnabled !== null && data.iArmsEnabled !== null) {
      await updateIArmsSetup(data.iArmsEnabled === 'yes');
      await updateETraceSetup(data.eTraceEnabled === 'yes');
    }
  };

  return { eTraceEnabled, iArmsEnabled, loadInitData, updateDocumentsGeneratingData };
};
