import { createTheme, Theme } from '@mui/material';
import { colors } from './colors';

export const theme: Theme = createTheme({
  typography: {
    allVariants: {
      color: colors.gray900,
    },
    fontFamily: 'NotoSansLight, sans-serif',
    h1: {
      fontFamily: 'NotoSansLight, sans-serif',
      fontSize: '4.5rem',
      lineHeight: '5.75rem',
    },
    h2: {
      fontFamily: 'NotoSansLight, sans-serif',
      fontSize: '3rem',
      lineHeight: '4rem',
    },
    h3: {
      fontFamily: 'NotoSansRegular, sans-serif',
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
    h4: {
      fontFamily: 'NotoSansRegular, sans-serif',
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    h5: {
      fontFamily: 'NotoSansRegular, sans-serif',
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    },
    subtitle1: {
      fontFamily: 'NotoSansSemiBold, sans-serif',
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    subtitle2: {
      fontFamily: 'NotoSansSemiBold, sans-serif',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    body1: {
      fontFamily: 'NotoSansRegular, sans-serif',
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    body2: {
      fontFamily: 'NotoSansRegular, sans-serif',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    caption: {
      fontFamily: 'NotoSansRegular, sans-serif',
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    button: {
      fontFamily: 'NotoSansMedium, sans-serif',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: '',
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1440,
    },
  },
});
