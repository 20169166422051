import { Select, Theme } from '@mui/material';
import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import { colors } from 'theme/colors';

export const StyledLanguageSelect = styled(Select)`
  background-color: ${colors.white};
  height: 32px;
  max-height: 32px;
  width: 200px;
  padding-top: 4px;

  & fieldset {
    border: 1px solid ${colors.gray300};
    border-radius: 4px;
  }

  & div {
    display: flex;
    flex-direction: row;

    & img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('lg')} {
      margin: 0 auto 40px;
      width: 200px;
      };
      `};
  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('sm')} {
      margin: 0 auto 40px;
      width: 95%;
      };
      `};
`;

export const StyledMenuItem = styled(MenuItem)`
  & div {
    display: flex;
    flex-direction: row;

    & img {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }
`;
