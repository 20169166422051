import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { useLang } from 'models/langContext';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { EPerPages, ISortOptions } from 'models/table';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import AncillariesReferenceAmmunitionSearchForm, {
  IAmmunitionFormValues,
  initialFormValues as initialFormValuesAmmunition,
} from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAmmunitionSearchForm';
import AncillariesReferenceAmmunition from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceAmmunition';
import { Box } from '@mui/material';
import AncillariesReferenceComponentsSearchForm, {
  initialFormValues as initialFormValuesComponents,
} from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceComponentsSearchForm';
import AncillariesReferenceComponents from 'pages/AncillariesPage/AncillariesReferenceTablePage/AncillariesReferenceComponents';
import { EIconTypes } from 'constants/Icons';
import { IARTComponentsFormValues } from 'models/ancillaries';
import { useQuery } from 'hooks/useQuery';
import { EPermission } from 'models/permissions';
import { ActionTypes } from 'state/actions/alert';
import { IFormElementOptionProp } from 'models/form';
import {
  AncillariesReferenceAccesoriessSearchForm,
  initialFormValues as initialFormValuesAccessories,
  ISearchFormValues as ISearchFormValuesAccessories,
} from './AncillariesReferenceAccesoriessSearchForm';
import { StyledSelect, StyledWarningSearch } from './styles';
import { useAncillaries } from '../hooks';
import AncillariesReferenceAccesoriess from './AncillariesReferenceAccesoriess';

export interface ISearchParams {
  [key: string]: string;
}

const AncillariesReferenceTablePage = () => {
  const { t } = useTranslations();
  const { pathname }: any = useLocation();
  const { id } = useParams<{ id: string }>();
  const queryParams = useQuery();
  const type: string | null = queryParams.get('type');
  const [searchParams] = useSearchParams();
  const { selectedLanguage } = useLang();
  const transactionPagePath = useMatch(ERouteLinks.SearchForARTAncillary);
  const isTransactionPage = !!transactionPagePath;
  const [isAncillariesReferenceLoading, setIsAncillariesReferenceLoading] = useState(true);
  const [queryAncillariesReference, setQueryAncillariesReference] = useState<Partial<any>>({});
  const [isQueryAncillariesReferenceLoading, setIsQueryAncillariesReferenceLoading] =
    useState<boolean>(true);
  const [isSortLoaded, setIsSortLoaded] = useState<boolean>(true);
  const [currentPageAncillariesReference, setCurrentPageAncillariesReference] =
    useState<number>(1);
  const [perPageAncillariesReference, setPerPageAncillariesReference] = useState<EPerPages>(
    EPerPages.perPage25,
  );
  const [ancillariesReferenceSort, setAncillariesReferenceSort] =
    useState<ISortOptions | null>(null);
  const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
  const { ancillaryAlert, clearAlert } = useAlert();
  const navigate = useNavigate();
  const [querySearchFormValuesAccessory, setQuerySearchFormValuesAccessory] =
    useState<ISearchFormValuesAccessories>(initialFormValuesAccessories);
  const [querySearchFormValuesAmmunition, setQuerySearchFormValuesAmmunition] =
    useState<IAmmunitionFormValues>(initialFormValuesAmmunition);
  const [querySearchFormValuesComponent, setQuerySearchFormValuesComponent] =
    useState<IARTComponentsFormValues>(initialFormValuesComponents);
  const [ancillaryOptionSelected, setAncillaryOptionSelected] = useState<string | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [isPaginationVisible, setIsPaginationVisible] = useState(true);

  const {
    loadDictionaries,
    getAncillariesReferenceAccessoryData,
    getAncillariesReferenceAmmunitionData,
    getAncillariesReferenceComponentsData,
    ancillaryOption,
    setAncillaryOption,
    artAncillaryOptions,
    ancillariesReference,
    paginatorAncillariesReference,
  } = useAncillaries();

  useEffect(() => {
    const query = new URLSearchParams(searchParams);
    const queryAncillaryOptionName = query.get('ancillaryOptionName');
    if (queryAncillaryOptionName) {
      setAncillaryOptionSelected(queryAncillaryOptionName);
    } else {
      setAncillaryOptionSelected(null);
    }
  }, [searchParams]);

  useEffect(() => {
    const query = new URLSearchParams(searchParams);
    if (ancillaryOptionSelected) {
      if (ancillaryOptionSelected === EArtAncillaryType.ACCESSORIES) {
        const queryValues: ISearchParams = {};
        query.forEach((value, field) => {
          if (field in initialFormValuesAccessories) {
            queryValues[field] = value;
          }
        });
        setQuerySearchFormValuesAccessory({ ...initialFormValuesAccessories, ...queryValues });
      } else if (ancillaryOptionSelected === EArtAncillaryType.AMMUNITION) {
        const queryValues: ISearchParams = {};
        query.forEach((value, field) => {
          if (field in initialFormValuesAmmunition) {
            queryValues[field] = value;
          }
        });
        setQuerySearchFormValuesAmmunition({ ...initialFormValuesAmmunition, ...queryValues });
      } else if (ancillaryOptionSelected === EArtAncillaryType.COMPONENTS) {
        const queryValues: ISearchParams = {};
        query.forEach((value, field) => {
          if (field in initialFormValuesComponents) {
            queryValues[field] = value;
          }
        });
        setQuerySearchFormValuesComponent({ ...initialFormValuesComponents, ...queryValues });
      }
    }
    setAncillaryOption(ancillaryOptionSelected);
  }, [ancillaryOptionSelected]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (ancillaryOption) {
      setShowAllOptions(true);
    } else {
      setShowAllOptions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ancillaryOption]);

  useEffect(
    () => {
      setIsAncillariesReferenceLoading(false);
      clearAlert(ActionTypes.CLEAR_ANCILLARY_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const previousAncillariesReferenceSortRef = useRef(ancillariesReferenceSort);

  useEffect(() => {
    (async function getAncillariesReferenceDataInit() {
      setIsPaginationVisible(false);
      setIsPaginationLoading(true);
      if (ancillariesReferenceSort !== previousAncillariesReferenceSortRef.current) {
        setIsSortLoaded(false);
      }
      if (ancillaryOption && ancillaryOption === EArtAncillaryType.ACCESSORIES) {
        await getAncillariesReferenceAccessoryData(
          currentPageAncillariesReference,
          perPageAncillariesReference,
          queryAncillariesReference,
          ancillariesReferenceSort,
        );
      } else if (ancillaryOption && ancillaryOption === EArtAncillaryType.AMMUNITION) {
        await getAncillariesReferenceAmmunitionData(
          currentPageAncillariesReference,
          perPageAncillariesReference,
          queryAncillariesReference,
          ancillariesReferenceSort,
        );
      } else if (ancillaryOption && ancillaryOption === EArtAncillaryType.COMPONENTS) {
        await getAncillariesReferenceComponentsData(
          currentPageAncillariesReference,
          perPageAncillariesReference,
          queryAncillariesReference,
          ancillariesReferenceSort,
        );
      }
      setIsQueryAncillariesReferenceLoading(false);
      setIsPaginationLoading(false);
      setIsPaginationVisible(true);
      setIsSortLoaded(true);
      previousAncillariesReferenceSortRef.current = ancillariesReferenceSort;
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPageAncillariesReference,
    perPageAncillariesReference,
    queryAncillariesReference,
    ancillariesReferenceSort,
    ancillaryOption,
  ]);

  const handleSetAncillaryOption = (value: string) => {
    setQuerySearchFormValuesComponent(initialFormValuesComponents);
    setQuerySearchFormValuesAmmunition(initialFormValuesAmmunition);
    setQuerySearchFormValuesAccessory(initialFormValuesAccessories);
    if (isTransactionPage) {
      window.history.replaceState(
        {},
        '',
        `${pathname}?type=${type}&expand=ancillaries&ancillaryOptionName=${value}`,
      );
    } else if (value) {
      window.history.replaceState(
        {},
        '',
        `${pathname}?expand=ancillaries-reference-table&ancillaryOptionName=${value}`,
      );
    } else {
      window.history.replaceState({}, '', pathname);
    }
    setAncillaryOptionSelected(value);
  };

  const handleArtAncillaryNames = (name: string) => {
    const translationMap: Record<string, string> = {
      'Controlled Accessories': 'ancillaries.accessories.accordion.name',
      Ammunition: 'ancillaries.ammunition.accordion.name',
      'Essential Components': 'ancillaries.components.accordion.name',
    };
    return translationMap[name] ? t(translationMap[name]) : name;
  };

  const mapArtAncillaryDictionaryToOptionProp = (
    dictionary: IDictionary[] | null,
  ): IFormElementOptionProp[] => {
    if (!dictionary) {
      return [];
    }
    return dictionary
      .filter((item) => item.isActive)
      .map((item) => ({
        label: handleArtAncillaryNames(item.name),
        value: item.uniqueId,
      }));
  };

  return (
    <div>
      {!showAllOptions && (
        <StyledWarningSearch item xs={12}>
          <Alert
            text={t('ancillariesReferenceTable.accordion.pleaseSelect.text')}
            variant={EAlertVariants.warning}
          />
        </StyledWarningSearch>
      )}
      {ancillaryAlert && (
        <Box mt={2} margin="15px 0">
          <Alert text={ancillaryAlert.text} variant={ancillaryAlert.variant} />
        </Box>
      )}
      <SearchWrapper>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <StyledSelect
              options={mapArtAncillaryDictionaryToOptionProp(artAncillaryOptions)}
              name="ancillaryOptionName"
              label={t('ancillariesReferenceTable.accordion.ancillary.label')}
              value={ancillaryOption}
              setValue={(value: string) => handleSetAncillaryOption(value)}
            />
          </Grid>
        </Grid>
      </SearchWrapper>

      {showAllOptions && ancillaryOption === EArtAncillaryType.ACCESSORIES && (
        <AncillariesReferenceAccesoriessSearchForm
          onSubmit={setQueryAncillariesReference}
          initialValues={querySearchFormValuesAccessory}
          isTransactionPage={isTransactionPage}
          type={type}
          isSearchButtonDisabled={
            isAncillariesReferenceLoading ||
            isQueryAncillariesReferenceLoading ||
            isPaginationLoading
          }
          setIsQueryAncillariesReferenceLoading={setIsQueryAncillariesReferenceLoading}
        />
      )}
      {showAllOptions && ancillaryOption === EArtAncillaryType.AMMUNITION && (
        <AncillariesReferenceAmmunitionSearchForm
          onSubmit={setQueryAncillariesReference}
          initialValues={querySearchFormValuesAmmunition}
          isTransactionPage={isTransactionPage}
          type={type}
          isSearchButtonDisabled={
            isAncillariesReferenceLoading ||
            isQueryAncillariesReferenceLoading ||
            isPaginationLoading
          }
          setIsQueryAncillariesReferenceLoading={setIsQueryAncillariesReferenceLoading}
        />
      )}
      {showAllOptions && ancillaryOption === EArtAncillaryType.COMPONENTS && (
        <AncillariesReferenceComponentsSearchForm
          onSubmit={setQueryAncillariesReference}
          initialValues={querySearchFormValuesComponent}
          isTransactionPage={isTransactionPage}
          isSearchButtonDisabled={
            isAncillariesReferenceLoading ||
            isQueryAncillariesReferenceLoading ||
            isPaginationLoading
          }
          type={type}
          setIsQueryAncillariesReferenceLoading={setIsQueryAncillariesReferenceLoading}
        />
      )}

      {!isTransactionPage && (
        <Grid
          item
          xs={12}
          container
          gap={1}
          sx={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0' }}
        >
          <Button
            variant={EButtonVariants.contained}
            size={EButtonSizes.medium}
            icon={EIconTypes.plus}
            permission={[EPermission.MANAGE_LOCAL_ART]}
            label={t('ancillariesReferenceTable.accordion.addAncillary.button')}
            onClick={() => navigate(`${ERouteLinks.AddAncillaries}`)}
            type="submit"
          />
        </Grid>
      )}

      {showAllOptions && ancillaryOption === EArtAncillaryType.ACCESSORIES && (
        <AncillariesReferenceAccesoriess
          setAncillariesReferenceSort={setAncillariesReferenceSort}
          perPageAncillariesReference={perPageAncillariesReference}
          setPerPageAncillariesReference={setPerPageAncillariesReference}
          currentPageAncillariesReference={currentPageAncillariesReference}
          setCurrentPageAncillariesReference={setCurrentPageAncillariesReference}
          isAncillariesReferenceLoading={isAncillariesReferenceLoading}
          paginatorAncillariesReference={paginatorAncillariesReference}
          ancillariesReference={ancillariesReference}
          isTransactionPage={isTransactionPage}
          LEId={id}
          type={type}
          isPaginationLoading={isPaginationLoading}
          isQueryAncillariesReferenceLoading={isQueryAncillariesReferenceLoading}
          isSortLoaded={isSortLoaded}
          isPaginationVisible={isPaginationVisible}
        />
      )}

      {showAllOptions && ancillaryOption === EArtAncillaryType.AMMUNITION && (
        <AncillariesReferenceAmmunition
          setAncillariesReferenceSort={setAncillariesReferenceSort}
          perPageAncillariesReference={perPageAncillariesReference}
          setPerPageAncillariesReference={setPerPageAncillariesReference}
          currentPageAncillariesReference={currentPageAncillariesReference}
          setCurrentPageAncillariesReference={setCurrentPageAncillariesReference}
          isAncillariesReferenceLoading={isAncillariesReferenceLoading}
          paginatorAncillariesReference={paginatorAncillariesReference}
          ancillariesReference={ancillariesReference}
          isTransactionPage={isTransactionPage}
          LEId={id}
          type={type}
          isPaginationLoading={isPaginationLoading}
          isQueryAncillariesReferenceLoading={isQueryAncillariesReferenceLoading}
          isSortLoaded={isSortLoaded}
          isPaginationVisible={isPaginationVisible}
        />
      )}

      {showAllOptions && ancillaryOption === EArtAncillaryType.COMPONENTS && (
        <AncillariesReferenceComponents
          setAncillariesReferenceSort={setAncillariesReferenceSort}
          perPageAncillariesReference={perPageAncillariesReference}
          setPerPageAncillariesReference={setPerPageAncillariesReference}
          currentPageAncillariesReference={currentPageAncillariesReference}
          setCurrentPageAncillariesReference={setCurrentPageAncillariesReference}
          isAncillariesReferenceLoading={isAncillariesReferenceLoading}
          paginatorAncillariesReference={paginatorAncillariesReference}
          ancillariesReference={ancillariesReference}
          isTransactionPage={isTransactionPage}
          LEId={id}
          type={type}
          isPaginationLoading={isPaginationLoading}
          isQueryAncillariesReferenceLoading={isQueryAncillariesReferenceLoading}
          isSortLoaded={isSortLoaded}
          isPaginationVisible={isPaginationVisible}
        />
      )}
    </div>
  );
};

export default AncillariesReferenceTablePage;
