import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/ancillaries';
import { AncillariesContext } from 'models/ancillaries';

export const AncilariesProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const setAncillariesArtOptions = (type: string, payload: any) => {
    dispatch({
      type,
      payload,
    });
  };

  const clearAncillariesArtOptions = (type: string) => {
    dispatch({ type, payload: null });
  };

  const value = useMemo(
    () => ({ ...state, setAncillariesArtOptions, clearAncillariesArtOptions }),
    [state],
  );

  return <AncillariesContext.Provider value={value}>{children}</AncillariesContext.Provider>;
};
