import React from 'react';
import { Typography } from '@mui/material';
import { StyledFooter } from './styles';

const Footer = () => (
  <StyledFooter>
    <Typography variant="caption">
      &copy;2024 Arquebus Solutions Limited. All rights reserved.
    </Typography>
    <Typography variant="caption">Version {process.env.REACT_APP_VERSION || '-'}</Typography>
  </StyledFooter>
);

export { Footer };
