import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useAuthenticationSetupFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    numberOfInvalidLoginAttempts: yup
      .string()
      .required(t('general.field.validation.required')),
    timePeriodOfAccountLockout: yup.string().required(t('general.field.validation.required')),
  });

  return { schema };
};

export default useAuthenticationSetupFormSchema;
