import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useTranslations } from 'hooks/useTranslations';
import { getRoleNames } from 'pages/AuditLogPage/helpers';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { IRole } from 'models/role';
import { getHistoryEventTypeColor } from 'pages/ApiKeysPage/helpers';
import { formatDate } from 'utils/date';

interface IApiKeyHistoryTableCard {
  data: ITableDataSource;
  roles: IRole[];
  longDateFormat: string;
}

const ApiKeyHistoryTableCard = ({ data, roles, longDateFormat }: IApiKeyHistoryTableCard) => {
  const { t } = useTranslations();

  const renderRoleNames = () => {
    const roleNamesArr = getRoleNames(data?.roleName, roles);
    return roleNamesArr.map((roleName) => (
      <Chips text={roleName as string} variant={EChipsVariants.primary} />
    ));
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysHistoryTable.username.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.username)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysHistoryTable.roleName.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {renderRoleNames()}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysHistoryTable.eventType.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          <Chips
            text={data?.eventType}
            variant={getHistoryEventTypeColor(data.eventTypeUniqueId)}
          />
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysHistoryTable.eventName.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.eventName)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysHistoryTable.eventDate.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {data.eventDate ? formatDate(data.eventDate, longDateFormat) : '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ApiKeyHistoryTableCard;
