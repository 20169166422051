import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { EPerPages, ISortOptions } from 'models/table';
import { IFirearm, IFirearmGroup, IFirearmsResponse, IFRTResponse } from 'models/firearm';
import { ISearchFirearmsFormValues, ISearchTransactionFormValues } from 'models/form';
import { queryString, queryStringWithSort } from 'helpers/request';
import {
  IFirearmCommentData,
  IFirearmCommentsList,
  IRegisteredFirearmAttachmentListResponse,
  IRegisteredFirearmData,
  IRegisteredFirearmsSearchQuery,
  IRegisteredFirearmTransactionListResponse,
} from 'models/registeredFirearm';
import { ILegalEntityCommentData } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage';
import { ICommentFormValues } from 'components/organisms/CommentForm';
import { RequestHeaderKeyTypes, RequestHeaderValueTypes } from 'constants/RequestHeaderTypes';
import { clearObject } from 'helpers/objects';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { formatDateTimeToIso, simpleFormatDate } from 'utils/date';
import { ILastTransactionUniqueId, IShortTransaction } from 'models/transaction';
import { EDateFormats } from 'constants/dates';
import { exportTimeout } from 'constants/Export';

const getFirearms = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchFirearmsFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort(
    {
      page: page - 1,
      size,
    },
    sort,
  );

  const { data } = await apiClient.post<TResponse<IFirearmsResponse>>(
    `${ApiEndpoints.GET_FIREARMS()}?${qString}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFirearmsForMap = async (query: Partial<ISearchFirearmsFormValues>) => {
  const { data } = await apiClient.post<TResponse<IFirearm[]>>(
    `${ApiEndpoints.GET_FIREARMS_FOR_MAP()}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFirearmsAvailableForGroup = async (
  leId: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchFirearmsFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const sortedQuery = queryStringWithSort({ page: page - 1, size, ...query }, sort);

  const { data } = await apiClient.get<TResponse<IFirearmsResponse>>(
    `${ApiEndpoints.GET_FIREARMS_AVAILABLE_FOR_GROUP(leId)}?${sortedQuery}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFirearmGroupDetails = async (id: string) => {
  const { data } = await apiClient.get<TResponse<IFirearmGroup>>(
    `${ApiEndpoints.GET_FIREARM_GROUP_DETAILS(id)}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getRegisteredFirearm = async (id: string): Promise<IRegisteredFirearmData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_FIREARM(id), {
    data: {
      type: '',
    },
  });

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const updateFirearmCustomField = async (
  id: string,
  body: ICustomFieldForm[],
  version: number,
) => {
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_FIREARM_CUSTOM_FIELDS(id), body, {
    params: {
      version,
    },
  });

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFRT = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchFirearmsFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const frtQueryString = queryStringWithSort({ page: page - 1, size, ...query }, sort);

  const { data } = await apiClient.get<TResponse<IFRTResponse>>(
    `${ApiEndpoints.GET_FRT()}?${frtQueryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFirearmTransactions = async (
  id: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchTransactionFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const ftQueryString = queryStringWithSort(
    {
      page: page - 1,
      size,
      ...query,
      ...(query.transactionDateFrom
        ? {
            transactionDateFrom: formatDateTimeToIso(query.transactionDateFrom),
          }
        : {}),
      ...(query.transactionDateTo
        ? {
            transactionDateTo: formatDateTimeToIso(query.transactionDateTo),
          }
        : {}),
    },
    sort,
  );
  const { data } = await apiClient.get<TResponse<IRegisteredFirearmTransactionListResponse>>(
    `${ApiEndpoints.GET_FIREARM_TRANSACTIONS(id)}?${ftQueryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFirearmAttachments = async (
  id: string,
  page: number,
  size: EPerPages,
  sort: ISortOptions | null | undefined,
) => {
  const faQueryString = queryStringWithSort(
    {
      page: page - 1,
      size,
    },
    sort,
  );

  const { data } = await apiClient.get<TResponse<IRegisteredFirearmAttachmentListResponse>>(
    `${ApiEndpoints.GET_FIREARM_ATTACHMENTS(id)}?${faQueryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLastFirearmTransactionUniqueId = async (firearmUniqueId: string) => {
  const { data } = await apiClient.get<ILastTransactionUniqueId>(
    ApiEndpoints.GET_FIREARM_LAST_TRANSACTION_UNIQUE_ID(firearmUniqueId),
  );
  return data;
};

const getGroupLastTransactionUniqueId = async (groupUniqueId: string) => {
  const { data } = await apiClient.get<ILastTransactionUniqueId>(
    ApiEndpoints.GET_GROUP_LAST_TRANSACTION_UNIQUE_ID(groupUniqueId),
  );
  return data;
};

const getFirearmComments = async (
  id: string,
  queryParams: {
    withHidden: boolean;
    currentPage: number;
    perPage: EPerPages;
  },
): Promise<IFirearmCommentsList | null> => {
  const { withHidden, currentPage, perPage } = queryParams;
  const fcQuery = queryString({
    withHidden,
    page: currentPage - 1,
    size: perPage,
  });

  const { data } = await apiClient.get(`${ApiEndpoints.GET_FIREARM_COMMENTS(id)}?${fcQuery}`);

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createFirearmComment = async (comment: ICommentFormValues) => {
  const { uniqueId, ...body } = comment;

  const { data } = await apiClient.post<TResponse<ILegalEntityCommentData>>(
    ApiEndpoints.CREATE_FIREARM_COMMENT(uniqueId),
    body,
  );
  return !isResponseError(data);
};

const getFirearmComment = async (
  id: string,
  commentId: string,
): Promise<IFirearmCommentData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_FIREARM_COMMENT(id, commentId));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const exportRegisteredFirearmsCSV = async (
  queryRegisteredFirearm: IRegisteredFirearmsSearchQuery,
  sort: ISortOptions | null,
): Promise<Blob | null> => {
  const { customFields } = queryRegisteredFirearm;
  Object.keys(queryRegisteredFirearm).forEach((key: string) => {
    if (!queryRegisteredFirearm[key] || key === 'customFields') {
      delete queryRegisteredFirearm[key];
    }
  });

  queryRegisteredFirearm.size = 9999;

  const dataPart =
    sort !== null
      ? queryStringWithSort(queryRegisteredFirearm, sort)
      : queryString(queryRegisteredFirearm);

  const { data } = await apiClient.post(
    `${ApiEndpoints.EXPORT_REGISTERED_FIREARMS_CSV()}?${dataPart}`,
    customFields,
    {
      timeout: exportTimeout,
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );
  const date = simpleFormatDate(new Date(), EDateFormats.yyyyMMddhhmm);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `firearms_${date}.csv`);
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAttachmentAvailableTransactionTypes = async (
  id: string,
): Promise<IShortTransaction[] | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_FIREARM_USED_TRANSACTION_TYPES(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const disableFirearm = async (id: string): Promise<void> => {
  await apiClient.post(ApiEndpoints.DISABLE_FIREARM(id));
};

const generateFirearmListTemplate = async (body: any): Promise<Blob | null> => {
  const { data } = await apiClient.post<Blob>(ApiEndpoints.GENERATE_FIREARMS_TEMPLATE(), body);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'add firearm template.csv');
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

export {
  getFirearms,
  getFirearmsAvailableForGroup,
  getRegisteredFirearm,
  getFirearmTransactions,
  updateFirearmCustomField,
  getFRT,
  getFirearmComments,
  getFirearmComment,
  createFirearmComment,
  disableFirearm,
  getFirearmGroupDetails,
  exportRegisteredFirearmsCSV,
  getFirearmAttachments,
  getAttachmentAvailableTransactionTypes,
  generateFirearmListTemplate,
  getFirearmsForMap,
  getLastFirearmTransactionUniqueId,
  getGroupLastTransactionUniqueId,
};
