import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { FormSelect } from 'components/molecules/FormSelect';

export const StyledPresetSelect = styled(FormSelect)`
  & .MuiOutlinedInput-root {
    height: 32px;
  }
`;

export const StyledFormSelect = styled(FormSelect)`
  & .MuiSvgIcon-root {
    display: none;
  }
`;

export const StyledAdvancedSearch = styled(Grid)`
  margin-top: 20px;
  margin-bottom: 5px;
  & .MuiGrid-item {
    padding-top: 0px;
  }
`;
