import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { IDictionaryTab, IDictionaryContentValues } from 'models/dictionary';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { Chips, EChipsVariants } from '../../components/atoms/Chips';

export const mapDictionariesToDataSource = (
  dictionaries: IDictionaryTab[],
): ITableDataSource[] =>
  dictionaries.map((dictionary) => ({
    id: dictionary.name,
    name: dictionary.name,
    description: dictionary.description,
    translations: dictionary.translatedValues,
    managerByAdmin: dictionary.managerByAdmin,
    translateName: dictionary.translateName,
  }));

export const mapDictionaryViewToDataSource = (
  values: IDictionaryContentValues[] | null,
): ITableDataSource[] | [] => {
  if (values) {
    return values.map((value) => ({
      id: value.uniqueId,
      name: value.name,
      status: value.isActive,
    }));
  }
  return [];
};

interface IDictionariesTableColumns {
  t: (arg: string) => string;
}

interface IDictionaryViewTableColumns {
  handleEdit: (arg: string) => void;
  t: (key: string) => string;
}

export const dictionariesTableColumns = ({
  t,
}: IDictionariesTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'name',
    title: 'dictionaries.dictionariesTable.dictionaryName.column',
    isSortable: false,
    render: (_data, props) => props.name.toUpperCase(),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'description',
    title: 'dictionaries.dictionariesTable.description.column',
    isSortable: false,
    render: (_data, props) => t(props.description),
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'translations',
    title: 'dictionaries.dictionariesTable.translations.column',
    isSortable: false,
    align: 'center',
    justifyContentHead: 'center',
    width: '120px',
    render: (_data, props) => (
      <Chips
        text={props.translations ? t('general.yes.label') : t('general.no.label')}
        variant={props.translations ? EChipsVariants.success100 : EChipsVariants.error}
      />
    ),
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'dictionaries.dictionariesTable.actions.column',
    isSortable: false,
    width: '100px',
    align: 'center',
    justifyContentHead: 'center',
    render: (_data, props) => (
      <Link to={ERouteLinks.ViewDictionary.replace(':type', props.id)}>
        <Button variant={EButtonVariants.outlined} tableButton={true} icon={EIconTypes.view} />
      </Link>
    ),
  },
];

export const dictionaryViewTableColumns = ({
  handleEdit,
  t,
}: IDictionaryViewTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'name',
    title: 'dictionaries.dictionaryViewTable.dictionaryValueName.column',
    isSortable: false,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'status',
    title: 'dictionaries.dictionaryViewTable.dictionaryStatus.column',
    isSortable: false,
    width: '150px',
    align: 'center',
    justifyContentHead: 'center',
    render: (_data, props) => (
      <Chips
        variant={props.status ? EChipsVariants.success100 : EChipsVariants.error}
        text={props.status ? t('general.active.label') : t('general.inactive.label')}
      />
    ),
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'dictionaries.dictionaryViewTable.actions.column',
    isSortable: false,
    width: '100px',
    align: 'center',
    justifyContentHead: 'center',
    render: (_data, props) => (
      <Button
        variant={EButtonVariants.outlined}
        tableButton={true}
        icon={EIconTypes.edit}
        onClick={() => handleEdit(props.id)}
      />
    ),
  },
];
