import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { downloadAncillaryAttachment } from 'requests/attachment';

export enum EComponentAttachmentsLabel {
  dateAndTime = 'componentDetails.attachments.dateAndTime.column',
  name = 'componentDetails.attachments.attachmentName.column',
  transactionType = 'componentDetails.attachments.transactionType.column',
  size = 'componentDetails.attachments.attachmentSize.column',
}

interface IComponentsAttachmentsCard {
  data: ITableDataSource;
  handleView: (args: string) => void;
  handleDelete: (arg: string) => void;
  idAncillary: string | undefined;
}

const ComponentsAttachmentsCard = ({
  data,
  handleView,
  handleDelete,
  idAncillary,
}: IComponentsAttachmentsCard) => {
  const { t } = useTranslations();
  const { longDateFormat } = useGlobalProperty();
  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentAttachmentsLabel.dateAndTime)}
        </Typography>
        <Typography variant="body2">{formatDate(data.createdDate, longDateFormat)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentAttachmentsLabel.name)}
        </Typography>
        <Typography variant="body2">{data.name}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentAttachmentsLabel.size)}
        </Typography>
        <Typography variant="body2">{data.size}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EComponentAttachmentsLabel.transactionType)}
        </Typography>
        <Typography variant="body2">{data.transactionType}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('general.actions.button')}:{' '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {data.isReport && (
            <Button
              label={t('componentDetails.attachments.detail.button')}
              variant={EButtonVariants.outlined}
              onClick={() => handleView(data.id as string)}
              size="small"
              fullWidth
              sx={{
                marginBottom: {
                  sm: 1,
                  lg: 0,
                },
              }}
            />
          )}
          {!data.isReport && (
            <Button
              label={t('componentDetails.attachments.delete.button')}
              variant={EButtonVariants.outlined}
              onClick={() => handleDelete && handleDelete(data.id as string)}
              size="small"
              fullWidth
              sx={{
                marginBottom: {
                  sm: 1,
                  lg: 0,
                },
              }}
            />
          )}
          <Button
            label={t('componentDetails.attachments.download.button')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              downloadAncillaryAttachment(
                idAncillary ?? '',
                data.id as string,
                data.name as string,
                data.type as string,
              )
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ComponentsAttachmentsCard };
