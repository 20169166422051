import { useRef } from 'react';
import { FieldErrors } from 'react-hook-form';

export const useScroll = () => {
  const elRef = useRef<null | HTMLDivElement>(null);
  const scrollToError = (errors: FieldErrors) => {
    const errorList = Object.keys(errors);
    if (errorList.length) {
      if (elRef && elRef.current) {
        const current = elRef?.current;
        const labels = current.getElementsByTagName('label');
        if (labels && labels.length) {
          for (let i = 0; i < labels.length; i++) {
            const label = labels[i];
            const labelFor =
              label && label?.attributes ? label?.attributes?.getNamedItem('for') : null;
            if (labelFor && labelFor.value) {
              if (errorList.includes(labelFor.value)) {
                label.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'nearest',
                });
                break;
              }
            }
          }
        }
      }
    }
    return null;
  };

  return { elRef, scrollToError };
};
