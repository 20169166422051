export enum ELicenceType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export interface ILicenceData {
  licenceId: string;
  licenceType: ELicenceType;
  startDate: string;
  expiryDate: string;
  numberOfUsers: number;
  currentSystemUsers: number;
}

export interface ILicenceBasicInfo {
  licenceType: string;
  expiryDate: string | null;
}
