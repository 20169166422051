import { Box, css } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from 'theme/colors';

type StyledNotificationListItemProps = {
  isError: boolean;
};

export const StyledRoleFormCheckboxListWrapper = styled(Box)<StyledNotificationListItemProps>`
  border: 0px solid ${colors.gray300};
  border-radius: 4px;
  padding: 8px;
  width: 100%;

  ${(props: StyledNotificationListItemProps) =>
    !props.isError &&
    css`
      &:hover {
        border: 0 solid ${colors.gray700};
      }
    `}

  ${(props: StyledNotificationListItemProps) =>
    props.isError &&
    css`
      border: 1px solid ${colors.error500};
    `}
`;
