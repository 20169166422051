import React, { useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { Control, UseFormWatch } from 'react-hook-form/dist/types/form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { ICustomFieldFormValues } from 'models/customField';
import {
  StyledMaskForm,
  StyledMaskingExample,
  StyledMaskingRules,
} from 'pages/CustomFieldsPage/CustomFieldForm/MaskForm/styles';
import { Box, Typography } from '@mui/material';
import FormLabel from 'components/atoms/FormLabel';
import { StyledTextField } from 'components/molecules/FormInput/styles';
import { StyledFormElementWrapper } from 'theme/styles';
import { colors } from 'theme/colors';
import { useLang } from 'models/langContext';
import { useTranslations } from 'hooks/useTranslations';

interface IMaskForm {
  control: Control<ICustomFieldFormValues>;
  errors: FieldErrors;
  watch: UseFormWatch<ICustomFieldFormValues>;
  show: boolean;
  isEditForm: boolean;
}

enum ERandomCharType {
  NUMBER = 'number',
  TEXT = 'text',
  ALL = 'all',
}

const getRandomChar = (type: ERandomCharType) => {
  const chars = 'BCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '123456789';
  const generate = (set: string) => set.charAt(Math.floor(Math.random() * set.length));

  switch (type) {
    case ERandomCharType.NUMBER:
      return generate(numbers);
    case ERandomCharType.TEXT:
      return generate(chars);
    case ERandomCharType.ALL:
    default:
      return generate(chars + numbers);
  }
};

const MaskForm = ({ control, errors, watch, isEditForm, show = false }: IMaskForm) => {
  const { selectedLanguage } = useLang();
  const inputMaskWatch = watch('inputMask');
  const mandatoryWatch = watch('mandatory');
  const allWatch = watch();
  const { t } = useTranslations();
  const [previewName, setPreviewName] = useState('');

  useEffect(() => {
    if (selectedLanguage?.uniqueId) {
      // @ts-ignore
      setPreviewName(allWatch.translations?.[selectedLanguage.uniqueId] || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allWatch]);

  const renderPreview = useMemo(() => {
    if (!inputMaskWatch?.length) {
      return '';
    }

    const inputMask = inputMaskWatch.replaceAll('[', '').replaceAll(']', '');

    if (!inputMask?.length) {
      return '';
    }

    const maskArr = [...inputMask];

    if (maskArr[0] === '\\' && maskArr[1] === '\\' && maskArr[2] === '\\') {
      const maskItems = inputMask.slice(3).split(',');

      let error = false;

      let maskRegexItems = maskItems.map((item) => {
        item = item.trim();
        if (item.length > 1) {
          error = true;
        }
        return item;
      });
      maskRegexItems = [...new Set(maskRegexItems)];

      if (error) {
        return t('customFields.singleCharsError.label');
      }
      return maskRegexItems[Math.floor(Math.random() * maskRegexItems.length)];
    }

    const result = maskArr.map((char, index) => {
      switch (char) {
        case '0':
          if (maskArr?.[index - 1] === '\\' && maskArr?.[index - 2] === '\\') {
            return char;
          }
          return getRandomChar(ERandomCharType.NUMBER);
        case 'a':
          if (maskArr?.[index - 1] === '\\' && maskArr?.[index - 2] === '\\') {
            return char;
          }
          return getRandomChar(ERandomCharType.TEXT);
        case '*':
          if (maskArr?.[index - 1] === '\\' && maskArr?.[index - 2] === '\\') {
            return char;
          }
          return getRandomChar(ERandomCharType.ALL);
        case '\\':
          return '';

        default:
          return char;
      }
    });

    return result.join('');
  }, [inputMaskWatch]);

  if (!show) {
    return null;
  }

  return (
    <StyledMaskForm container item xs={12}>
      <Grid item xs={12} sm={12} lg={6} sx={{ padding: '10px' }}>
        <FormInput
          name="inputMask"
          label={t('customFields.maskForm.inputMask.label')}
          control={control}
          errors={errors}
          disabled={isEditForm}
        />
        <Box>
          <Typography variant="subtitle2">
            {t('customFields.maskForm.fieldPreview.label')}
          </Typography>
          <Box sx={{ backgroundColor: colors.white, padding: '25px' }}>
            <StyledFormElementWrapper>
              <FormLabel
                name="maskPreview"
                label={`${previewName}${mandatoryWatch ? '*' : ''}` || ' '}
              />
              <StyledTextField
                id="maskPreview"
                variant="outlined"
                value={renderPreview}
                InputProps={{ readOnly: true }}
              />
            </StyledFormElementWrapper>
          </Box>
        </Box>
      </Grid>
      <StyledMaskingRules item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2" paddingBottom="8px">
          {t('customFields.maskForm.maskingRules.label')}
        </Typography>
        <Typography>
          <strong>0</strong> – {t('customFields.maskForm.maskingRules1.label')}
          <br />
          <strong>a</strong> – {t('customFields.maskForm.maskingRules2.label')}
          <br />
          <strong>*</strong> – {t('customFields.maskForm.maskingRules3.label')}
          <br />
          <strong>[ ]</strong> – {t('customFields.maskForm.maskingRules4.label')}
          <br />
          <strong>\\0</strong> – {t('customFields.maskForm.maskingRules5.label')} “
          <strong>0</strong>”
          <br />
          <strong>\\a</strong> – {t('customFields.maskForm.maskingRules5.label')} “
          <strong>a</strong>”
          <br />
          <strong>\\*</strong> – {t('customFields.maskForm.maskingRules5.label')} “
          <strong>*</strong>”
          <br />
          <strong>\\\A,B,C</strong> – {t('customFields.maskForm.maskingRules6.label')}
          <br />
          <br />
          {t('customFields.maskForm.maskingRules7.label')}
        </Typography>
      </StyledMaskingRules>
      <StyledMaskingExample item xs={12} sm={6} lg={3}>
        <Typography variant="subtitle2">{t('customFields.maskForm.example.label')}</Typography>
        <div>
          <p>{t('customFields.maskForm.postalCode.label')}</p>
          <h5>12-345</h5>
          <p>{t('customFields.maskForm.shouldBe.label')}</p>
          <h5>00-000</h5>
        </div>
      </StyledMaskingExample>
    </StyledMaskForm>
  );
};

export default MaskForm;
