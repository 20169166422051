import React, { useEffect } from 'react';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import MenuLogoOpen from 'assets/images/menu-logo-open.png';
import MenuLogoClosed from 'assets/images/menu-logo-closed.png';
import Icon from 'components/atoms/Icon/Icon';
import { List } from '@mui/material';
import { IMenuItem, MenuItems } from 'constants/MenuItems';
import { Counter } from 'components/atoms/Counter';
import { NavLink, useLocation } from 'react-router-dom';
import { MenuProfileItem } from 'components/molecules/MenuProfileItem';
import { LanguageSelect } from 'components/molecules/LanguageSelect';
import { usePermission } from 'hooks/usePermission';
import { useTranslations } from 'hooks/useTranslations';
import { useNotifications } from 'pages/NotificationsPage/hooks';
import { useDrawerClicked } from 'state/contexts/drawer';
import { useNotificationCounter } from 'models/notificationCounterContext';
import { colors } from 'theme/colors';
import {
  DrawerHeader,
  DrawerImageWrapper,
  StyledDrawer,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
} from './styles';

interface IDrawer {
  open: boolean;
  setOpen: (arg: boolean) => void;
  mobile?: boolean;
}

const MyNavLink = React.forwardRef<any, any>((props, ref) => {
  const { pathname } = useLocation();

  let isActiveItems = false;
  if (props?.items) {
    isActiveItems = props.items.some((item: string) => pathname.startsWith(item));
  }

  return (
    <NavLink
      ref={ref}
      to={props.to}
      className={({ isActive }) =>
        `${props.className} ${isActive || isActiveItems ? props.activeClassName : ''}`
      }
    >
      {props.children}
    </NavLink>
  );
});
const Drawer = ({ open, setOpen, mobile = false }: IDrawer) => {
  const { hasPermissions } = usePermission();
  const { t } = useTranslations();
  const { getNotificationUnreadCounterData, notificationsCounter } = useNotifications();
  const { setDrawerClicked } = useDrawerClicked();
  const { counter, setCounter } = useNotificationCounter();

  useEffect(() => {
    (async function initNotificationCounter() {
      await getNotificationUnreadCounterData();
    })();
  }, []);

  useEffect(() => {
    const refreshTime = process.env.NOTIFICATION_COUNTER_REFRESH_TIME_MS
      ? parseInt(process.env.NOTIFICATION_COUNTER_REFRESH_TIME_MS, 10)
      : 5000;
    const counterInterval = setInterval(async () => {
      await getNotificationUnreadCounterData();
    }, refreshTime);
    return () => clearInterval(counterInterval);
  }, [counter]);

  useEffect(() => {
    setCounter(notificationsCounter);
  }, [notificationsCounter]);

  return (
    <StyledDrawer variant="permanent" open={open} mobile={mobile ? 1 : 0}>
      <DrawerHeader>
        {mobile ? (
          <Button
            variant={EButtonVariants.contained}
            icon={EIconTypes.close}
            sizePX={EButtonSizesPX.px32}
            onClick={() => setOpen(!open)}
            sx={{
              mt: 2,
              mr: 2,
              backgroundColor: `${colors.primary900} !important`,
              '& > svg': { fill: colors.white },
            }}
          />
        ) : (
          <Button
            variant={EButtonVariants.contained}
            icon={open ? EIconTypes.left : EIconTypes.right}
            onClick={() => {
              setOpen(!open);
            }}
          />
        )}
      </DrawerHeader>
      <DrawerImageWrapper open={open}>
        <img src={open ? MenuLogoOpen : MenuLogoClosed} alt="Logo" />
      </DrawerImageWrapper>
      {mobile && <LanguageSelect isVisible={true} />}
      <List
        style={{ marginTop: 0 }}
        sx={{ mt: open ? 0 : 8, overflowY: mobile ? 'visible' : 'auto', overflowX: 'hidden' }}
      >
        {MenuItems.map(
          (item: IMenuItem) =>
            (!item.permissions ||
              hasPermissions(item.permissions, item?.permissionOrCondition)) && (
              <StyledListItem
                component={MyNavLink}
                to={item.url}
                key={item.label}
                disablePadding
                open={open}
                activeClassName="active-list-item"
                mobile={mobile}
                items={item?.items}
              >
                <StyledListItemButton
                  id={item.id}
                  onClick={
                    mobile
                      ? () => {
                          setOpen(!open);
                          setDrawerClicked(true);
                        }
                      : () => {
                          setDrawerClicked(true);
                        }
                  }
                >
                  <StyledListItemIcon
                    sx={{
                      mr: open ? 1.5 : 'auto',
                    }}
                  >
                    <Icon
                      type={
                        item.isNotificationItem && counter
                          ? EIconTypes.notificationOn
                          : item.icon
                      }
                    />
                  </StyledListItemIcon>
                  <StyledListItemText primary={t(item.label)} sx={{ opacity: open ? 1 : 0 }} />
                  {item.isNotificationItem && counter ? (
                    <Counter label={counter} open={open} />
                  ) : null}
                </StyledListItemButton>
              </StyledListItem>
            ),
        )}
      </List>
      <MenuProfileItem open={open} mobile={mobile} close={() => setOpen(false)} />
    </StyledDrawer>
  );
};

export { Drawer };
