import { RequestHeaderKeyTypes, RequestHeaderValueTypes } from 'constants/RequestHeaderTypes';
import { apiClient } from 'utils/apiClient';
import { isResponseError, TResponse } from 'models/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { ELoginPartTypes, ILoginScreenItem } from 'models/loginScreenSetup';
import { objectToQueryString } from 'helpers/searchQuery';

export const updateLoginSetup = async (
  type: ELoginPartTypes,
  text: string,
  file: File | null,
) => {
  const config = {
    headers: {
      [RequestHeaderKeyTypes.CONTENT_TYPE]: RequestHeaderValueTypes.MULTIPART_FORM_DATA,
    },
    timeout: 0,
  };
  const queryString = objectToQueryString({ type, display: true, text: text || '' });

  let payload = null;
  if (file) {
    payload = new FormData();
    payload.append('file', file);
  }

  const { data } = await apiClient.post<TResponse<any>>(
    `${ApiEndpoints.UPDATE_LOGIN_SCREEN()}?${queryString}`,
    payload,
    config,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export const getLoginScreenSetupData = async () => {
  const { data } = await apiClient.get<TResponse<ILoginScreenItem[]>>(
    ApiEndpoints.GET_LOGIN_SCREEN(),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};
