import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { IRole } from 'models/role';
import { getRole } from 'requests/role';
import { ERouteLinks } from 'models/route';
import { Box, Typography } from '@mui/material';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import { StyledPageTitle } from 'theme/styles';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { IParsedPermissions, useRoles } from 'pages/RolesPage/hooks';
import { EPermission, permissionsGroups } from 'models/permissions';
import { colors } from 'theme/colors';
import { useTranslations } from 'hooks/useTranslations';
import { ADMINISTRATOR_ROLE_ID } from 'pages/RolesPage/helpers';

const ViewRolePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { id = '' } = useParams<{ id: string }>();
  const [role, setRole] = useState<IRole | null>();
  const [parsedPermissions, setParsedPermissions] = useState<IParsedPermissions>({});
  const { getPermissions, parsePermissions, permissions } = useRoles();

  useEffect(() => {
    (async function getRoleDataInit() {
      if (id) {
        const response = await getRole(id);
        if (response) {
          setRole(response);
        }
      }
    })();
  }, [id]);

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParsedPermissions(parsePermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('roles.header'),
            route: ERouteLinks.Roles,
          },
          t('roleDetails.header'),
        ]}
      />
      {role && (
        <>
          <Box sx={{ mb: 2 }}>
            <StyledPageTitle variant="h4">{t('roleDetails.header')}</StyledPageTitle>
          </Box>
          <DetailsTemplate
            disableOnlyDelete={id === ADMINISTRATOR_ROLE_ID}
            pageType={EDetailsPageTypes.view}
            title={role.name}
            deletePermissions={[EPermission.MANAGE_ROLES]}
            redirectToEditView={() => navigate(`${ERouteLinks.EditRole.replace(':id', id)}`)}
            redirectToView={() => navigate(`${ERouteLinks.DeleteRole.replace(':id', id)}`)}
          >
            <Grid item lg={12} container direction="column">
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('roleDetails.description.label')}
              </Typography>
              <Typography variant="body2">
                {role?.description ? role.description : '---'}
              </Typography>
            </Grid>

            {!!role?.permissionIds?.length && (
              <>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: colors.primary500,
                      textTransform: 'uppercase',
                      letterSpacing: '0.02em',
                    }}
                  >
                    {t('roleDetails.selectedPermissions.label')}
                  </Typography>
                </Grid>

                {permissionsGroups
                  .filter(
                    (group) =>
                      permissions
                        .filter((permission) => permission.relateModule === group.module)
                        .filter((permission) =>
                          role?.permissionIds?.includes(permission.uniqueId),
                        ).length > 0,
                  )
                  .map(
                    (group) =>
                      parsedPermissions?.[group.module] && (
                        <Grid item lg={3} md={6} xs={12} key={group.module} sx={{ mb: 2 }}>
                          <Box mb={1}>
                            <Typography variant="subtitle2">{t(group.title)}</Typography>

                            <div>
                              {parsedPermissions?.[group.module].map(
                                (permission) =>
                                  role?.permissionIds?.includes(permission.uniqueId) && (
                                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                                      {permission.name}
                                    </Typography>
                                  ),
                              )}
                            </div>
                          </Box>
                        </Grid>
                      ),
                  )}
              </>
            )}
          </DetailsTemplate>
          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} lg={4}>
              <Button
                fullWidth
                label={t('general.back.link')}
                variant={EButtonVariants.outlined}
                size={EButtonSizes.small}
                icon={EIconTypes.left}
                onClick={() => navigate(-1)}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { ViewRolePage };
