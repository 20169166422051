import { EPermission, IPermission } from 'models/permissions';

export const permissionsWithTooltip = [
  EPermission.VIEW_FIREARMS_LE,
  EPermission.VIEW_ANCILLARIES_LE,
  EPermission.MANAGE_FRT_FIREARM,
  EPermission.VIEW_FIREARMS,
  EPermission.VIEW_TRANSACTIONS,
];

const sortedPermissions = [
  EPermission.MANAGE_LE,
  EPermission.DELETE_LE,
  EPermission.VIEW_ALL_LE,
  EPermission.MANAGE_COMMENTS_LE,
  EPermission.MANAGE_GROUPS_LE,
  EPermission.VIEW_FIREARMS_LE,
  EPermission.VIEW_ANCILLARIES_LE,
  EPermission.EXPORT_LE,
  EPermission.VIEW_FIREARMS,
  EPermission.VIEW_ALL_FIREARMS,
  EPermission.MANAGE_COMMENTS_FIREARMS,
  EPermission.MANAGE_ATTACHMENTS_FIREARMS,
  EPermission.DISABLE_FIREARM,
  EPermission.MODIFY_FIREARM,
  EPermission.MANAGE_FRT_FIREARM,
  EPermission.EXPORT_FIREARMS,
  EPermission.MANAGE_IARMS_EXPORT,
  EPermission.ADD_TRANSACTION,
  EPermission.DISCARD_TRANSACTION,
  EPermission.VIEW_ALL_TRANSACTIONS,
  EPermission.VIEW_TRANSACTIONS,
  EPermission.MANAGE_TRANSACTION_COMMENTS,
];

export const prepareRoleList = (permissions: IPermission[]) =>
  permissions.sort(
    (a, b) =>
      Object.values(sortedPermissions).indexOf(a.authority as EPermission) -
      Object.values(sortedPermissions).indexOf(b.authority as EPermission),
  );
