import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { useTranslations } from 'hooks/useTranslations';
import * as yup from 'yup';
import { IMask } from 'react-imask';
import { CHARS_LIMIT } from 'components/organisms/FormCustomField';

export const useAncillaryCustomFieldFormSchema = (
  customFields: ICustomFieldByObjectType[],
) => {
  const { t } = useTranslations();

  const customFieldsValidations: { [key: string]: any } = {};

  customFields.map((customField) => {
    const baseValidation = yup.string().nullable();

    if (customField.customFieldType === ECustomFieldType.TEXT && customField.mandatory) {
      customFieldsValidations[customField.name] = baseValidation
        .required(t('general.field.validation.required'))
        .max(
          CHARS_LIMIT,
          t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
        );
    } else if (customField.customFieldType === ECustomFieldType.TEXT) {
      customFieldsValidations[customField.name] = baseValidation.max(
        CHARS_LIMIT,
        t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
      );
    } else if (customField.mandatory) {
      customFieldsValidations[customField.name] = baseValidation.required(
        t('general.field.validation.required'),
      );
    }

    return false;
  });

  let schema = yup.object({ ...customFieldsValidations });

  customFields.map((customField) => {
    if (customField.inputMaskEnabled && customField.inputMask) {
      schema = schema.test(`customFieldMaskValidation${customField.uniqueId}`, '', (obj) => {
        const mask = customField?.inputMask;
        if (mask) {
          const masked = IMask.createMask({ mask } as IMask.AnyMaskedOptions);
          // @ts-ignore
          if (obj?.[customField.name]) {
            // @ts-ignore
            masked.resolve(obj?.[customField.name] as string);
            if (!masked.isComplete) {
              return new yup.ValidationError(
                `Wrong input mask value (${mask})`,
                null,
                customField.name,
              );
            }
          }
        }

        return true;
      });
    }
    return false;
  });

  return { schema };
};
