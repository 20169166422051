import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { FilterChip } from 'components/atoms/FilterChip';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import SearchFilterStore from 'state/SearchFilterStore';
import { useTranslations } from 'hooks/useTranslations';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { useLang } from 'models/langContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { format } from 'date-fns';
import {
  StyledButtonsContainer,
  StyledFilterChipWrapper,
  StyledSelectedFiltersWrapper,
} from './styles';

export interface IValues {
  [key: string]: any;
}

export interface ITranslationKeys {
  [key: string]: string;
}

interface ISelectedFilters {
  values: IValues;
  labels: IValues;
  dictionaries?: IValues;
  noPreset?: boolean;
  handleDelete: (arg?: string) => void;
  saveRoute?: string;
  additionalSaveFilterValues?: {};
  excludes?: string[];
  type?: ESearchFilterKeys;
  dictionariesToSave?: string[];
  noRemovable?: string[];
  translationKeys?: ITranslationKeys;
  customFields?: ICustomFieldByObjectType[];
  showSaveFilters?: boolean;
  additionalPath?: string;
  updatedQueryPath?: string;
  name?: string;
  saveQueryParams?: string[];
  saveRouterStateAfterClearAll: boolean;
}

const fieldsWithMidDateFormat = [
  'transactionTimeFrom',
  'transactionTimeTo',
  'createTimeFrom',
  'createTimeTo',
  'expirationTimeFrom',
  'expirationTimeTo',
];

const SelectedFilters = ({
  values,
  labels,
  dictionaries,
  handleDelete,
  saveRoute,
  additionalSaveFilterValues = {},
  excludes,
  type,
  noPreset = false,
  dictionariesToSave = [],
  noRemovable = [],
  translationKeys = {},
  customFields = [],
  showSaveFilters = true,
  additionalPath,
  updatedQueryPath,
  name,
  saveQueryParams,
  saveRouterStateAfterClearAll = false,
}: ISelectedFilters) => {
  const navigate = useNavigate();

  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { shortDateFormat, midDateFormat, getDateFormat } = useGlobalProperty();
  const [searchParams] = useSearchParams();
  const expandParam = searchParams.get('expand');
  const limitToParam = searchParams.get('limitTo');
  const { isDesktop, isMobile } = useThemeBreakpoint();
  const { state: routerState }: any = useLocation();

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  customFields.map((customField) => {
    const customFieldTranslation = customField.translations.find(
      (translation) => translation.languageUniqueId === selectedLanguage?.uniqueId,
    );
    if (customFieldTranslation) {
      if (customField.customFieldType === ECustomFieldType.DATE) {
        labels[`from_${customField.name}`] = `${customFieldTranslation.name} (${t(
          'general.from.label',
        )})`;
        labels[`to_${customField.name}`] = `${customFieldTranslation.name} (${t(
          'general.to.label',
        )})`;
      } else {
        labels[customField.name] = customFieldTranslation.name;
      }
    }

    if (
      customField.customFieldType === ECustomFieldType.BOOL &&
      values?.[customField.name] !== '' &&
      values?.[customField.name] !== undefined
    ) {
      const trueValues = ['1', 'true', t('general.confirmation.yes.button')];

      values[customField.name] = trueValues.includes(values[customField.name])
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    return false;
  });

  const { pathname } = useLocation();
  if (excludes) {
    values = Object.fromEntries(
      Object.entries(values).filter((item) => !excludes.includes(item[0])),
    );
  }

  const shouldDisplay = Object.values(values).some(
    (item) =>
      (!Array.isArray(item) && !!item) ||
      typeof item === 'number' ||
      (Array.isArray(item) && item?.length),
  );

  let showClearAllBtn = true;
  if (noRemovable) {
    const noRemovableFiltered = Object.fromEntries(
      Object.entries(values).filter((item) => !noRemovable.includes(item[0])),
    );
    showClearAllBtn = Object.values(noRemovableFiltered).some(
      (item) => !!item || typeof item === 'number',
    );
  }

  if (!shouldDisplay) {
    return null;
  }

  values = Object.fromEntries(
    Object.entries(values).map((item) => {
      if (item[1] instanceof Date) {
        let dateFormat = shortDateFormat;
        if (fieldsWithMidDateFormat.includes(item[0])) {
          dateFormat = midDateFormat;
        }
        item[1] = format(item[1], dateFormat);
      }
      return item;
    }),
  );

  const customFieldNames = customFields.map((item) => item.name);
  const filteredValues = { ...values };
  Object.keys(filteredValues).map((key) => {
    if (customFieldNames.includes(key)) {
      delete filteredValues[key];
    }
    return false;
  });
  if (filteredValues.customFields !== undefined) {
    delete filteredValues.customFields;
  }

  noPreset = !Object.values(filteredValues).some(
    (item) =>
      (!Array.isArray(item) && !!item) ||
      typeof item === 'number' ||
      (Array.isArray(item) && item?.length),
  );
  if (type) {
    SearchFilterStore.setValue(type, filteredValues);
    if (dictionariesToSave) {
      const storeDictionaries = {};
      dictionariesToSave.map((dict) => {
        if (dictionaries && dictionaries?.[dict]) {
          // @ts-ignore
          storeDictionaries[dict] = dictionaries[dict];
        }
        return false;
      });
      SearchFilterStore.setDictionaries(type, storeDictionaries);
    }
  }

  const handleClearAll = () => {
    handleDelete();

    if (saveQueryParams) {
      if (!additionalPath) {
        additionalPath = '?';
      }
      saveQueryParams.forEach((param, index) => {
        const paramVal = searchParams.get(param);
        if (paramVal) {
          additionalPath += `${index !== 0 ? '&' : ''}${param}=${paramVal}`;
        }
      });
    }

    if (expandParam) {
      if (additionalPath) {
        additionalPath += additionalPath?.includes('?')
          ? `&expand=${expandParam}`
          : `?expand=${expandParam}`;
      } else {
        additionalPath = `?expand=${expandParam}`;
      }
    }

    if (limitToParam) {
      if (additionalPath) {
        additionalPath += additionalPath?.includes('?')
          ? `&limitTo=${limitToParam}`
          : `?limitTo=${limitToParam}`;
      } else {
        additionalPath = `?limitTo=${limitToParam}`;
      }
    }

    const path = additionalPath ? pathname + additionalPath : pathname;
    navigate(path, {
      replace: true,
      state: saveRouterStateAfterClearAll
        ? routerState
        : {
            uniqueIds: routerState?.uniqueIds,
            accessoriesUniqueIds: routerState?.accessoriesUniqueIds,
            ammunitionUniqueIds: routerState?.ammunitionUniqueIds,
            componentsUniqueIds: routerState?.componentsUniqueIds,
          },
    });
  };

  // @ts-ignore
  return (
    <StyledSelectedFiltersWrapper container flexWrap={isDesktop ? 'nowrap' : 'wrap'}>
      <Grid item>
        <Typography variant="body2">{t('saveMyFilter.selectedCriteria.section')}:</Typography>
      </Grid>
      <StyledFilterChipWrapper item>
        {Object.entries(values)
          .filter((item) => {
            if (typeof item[1] === 'number') {
              return true;
            }
            return item[1] && labels?.[item[0]];
          })
          .map((item) => {
            if (labels?.[item[0]]) {
              return (
                <FilterChip
                  key={item[0]}
                  name={item[0]}
                  label={t(labels?.[item[0]]) || labels?.[item[0]]}
                  values={dictionaries?.[item[0]]?.[item[1]] || item[1]}
                  handleDelete={noRemovable?.includes(item[0]) ? undefined : handleDelete}
                />
              );
            }
            return null;
          })}
      </StyledFilterChipWrapper>
      <StyledButtonsContainer
        container
        item
        xs={12}
        lg="auto"
        spacing={2}
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        maxWidth={isDesktop ? 'fit-content' : '100%'}
      >
        {showClearAllBtn && (
          <Grid item xs={12} sm={!noPreset && showSaveFilters ? 6 : 12} lg="auto">
            <Button
              variant={EButtonVariants.text}
              label={
                translationKeys?.clearAll
                  ? t(translationKeys.clearAll)
                  : t('clearAll.general.label')
              }
              icon={EIconTypes.close}
              size={EButtonSizes.small}
              onClick={handleClearAll}
              id={`${name}-clear_all`}
              fullWidth={!isDesktop}
            />
          </Grid>
        )}
        {!noPreset && showSaveFilters && (
          <Grid item xs={12} sm={showClearAllBtn ? 6 : 12} lg="auto">
            <Button
              id={`${name}-save_filter`}
              variant={EButtonVariants.outlined}
              label={t('saveMyFilter.header')}
              size={EButtonSizes.small}
              onClick={() => {
                if (additionalSaveFilterValues) {
                  return saveRoute
                    ? navigate(saveRoute, {
                        state: {
                          additionalSaveFilterValues,
                          cancelPath:
                            updatedQueryPath ||
                            window.location.pathname + window.location.search,
                        },
                      })
                    : {};
                }
                return saveRoute
                  ? navigate(saveRoute, {
                      state: {
                        cancelPath:
                          updatedQueryPath ||
                          window.location.pathname + window.location.search,
                      },
                    })
                  : {};
              }}
              fullWidth={!isDesktop}
            />
          </Grid>
        )}
      </StyledButtonsContainer>
    </StyledSelectedFiltersWrapper>
  );
};

export { SelectedFilters };
