import React from 'react';
import { Typography } from '@mui/material';
import { StyledCounter } from './styles';

interface ICounter {
  label: string | number;
  open: boolean;
}

const Counter = ({ label, open }: ICounter) => (
  <StyledCounter open={open}>
    <Typography variant="caption">{label}</Typography>
  </StyledCounter>
);

export { Counter };
