import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { colors } from 'theme/colors';
import { Box } from '@mui/material';

const ToBeStyledTooltip = ({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

export const StyledTooltip = styled(ToBeStyledTooltip)(() => ({
  backgroundColor: colors.error700,
  color: colors.white,
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '12px',
  fontFamily: 'NotoSans',
}));

export const StyledNoResults = styled(Box)(() => ({
  backgroundColor: colors.gray200,
  borderRadius: '4px',
  padding: '20px',
}));
