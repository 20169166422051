import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ILegalEntityGroupFormValues, initialLegalEntityGroupFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useLEGroupSchema } from 'pages/LegalEntitiesPage/schemaValidation';
import { useScroll } from 'hooks/useScroll';

export interface ILegalEntityGroupForm {
  onCancel?: () => void;
  onSubmit: (arg: ILegalEntityGroupFormValues | any) => void;
  onNameChange?: (arg: string) => void;
  initialFormValues?: ILegalEntityGroupFormValues | null;
  children?: JSX.Element;
}

const LegalEntityGroupForm = ({
  onCancel,
  onSubmit,
  onNameChange,
  initialFormValues,
  children,
}: ILegalEntityGroupForm) => {
  // const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { leGroupSchema } = useLEGroupSchema();
  const { elRef, scrollToError } = useScroll();

  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ILegalEntityGroupFormValues>({
    defaultValues: initialFormValues || initialLegalEntityGroupFormValues,
    resolver: yupResolver(leGroupSchema),
  });

  const watchName = watch('name');

  useEffect(() => {
    if (onNameChange) {
      onNameChange(watchName);
    }
  }, [watchName]);

  useEffect(() => {
    reset(initialFormValues || initialLegalEntityGroupFormValues);
  }, [reset, initialFormValues]);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const onSubmitForm = async (data: any) => {
    setIsSubmitLoading(true);
    if (initialFormValues) {
      await onSubmit({
        ...initialFormValues,
        ...data,
      });
      setIsSubmitLoading(false);
    } else {
      await onSubmit(data);
      setIsSubmitLoading(false);
    }
    setIsSubmitLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container ref={elRef}>
        <Grid item xs={12} lg={9} container columnSpacing={2}>
          <Grid item xs={12} lg={8}>
            <FormInput
              name="name"
              label={`${t('addGroup.groupName.label')}*`}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item>{children}</Grid>
          <Grid item xs={12} lg={9} container columnSpacing={3} rowGap={3} mt={10}>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                id="save-button"
                label={t('general.save.button')}
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
              />
            </Grid>

            {onCancel && (
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  id="cancel-button"
                  label={t('general.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={onCancel}
                />
              </Grid>
            )}
          </Grid>
          <Grid xs={12} item mt={2}>
            <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export { LegalEntityGroupForm };
