export enum ETransactionTypes {
  SEIZED = '5a857cb4-01c7-11ed-b939-0242ac120002',
  SURRENDERED = '5a858b96-01c7-11ed-b939-0242ac120002',
  RECOVERED = '5a857da4-01c7-11ed-b939-0242ac120002',
  DECLARED_UNKNOWN_ORIGIN = '5a857bc4-01c7-11ed-b939-0242ac120002',
  DECLARED_MANUFACTURED = '5a857ad4-01c7-11ed-b939-0242ac120002',
  DECLARED_IMPORTED = '5a85791c-01c7-11ed-b939-0242ac120002',
  EXPORTED = '5a8587cc-01c7-11ed-b939-0242ac120002',
  ASSIGNED_TEMPORARILY = '5a858524-01c7-11ed-b939-0242ac120002',
  ASSIGNED_INDEFINITELY = '5a85860a-01c7-11ed-b939-0242ac120002',
  TEST_FIRED = '5a858c86-01c7-11ed-b939-0242ac120002',
  SOLD = '5a857e8a-01c7-11ed-b939-0242ac120002',
  GIVEN = '5a858196-01c7-11ed-b939-0242ac120002',
  STOLEN = '5a858286-01c7-11ed-b939-0242ac120002',
  LOST = '5a85836c-01c7-11ed-b939-0242ac120002',
  LEASED = '5a858448-01c7-11ed-b939-0242ac120002',
  RETURNED = '5a8586e6-01c7-11ed-b939-0242ac120002',
  MODIFIED = '5a8589de-01c7-11ed-b939-0242ac120002',
  REPAIRED_OR_MAINTAINED = '5a858aba-01c7-11ed-b939-0242ac120002',
  PROOFED = '5a858d62-01c7-11ed-b939-0242ac120002',
  TRANSFERRED = '5a858f1a-01c7-11ed-b939-0242ac120002',
  DEACTIVATED = '5a858ff6-01c7-11ed-b939-0242ac120002',
  MARKED = '5a8591ea-01c7-11ed-b939-0242ac120002',
  MARKED_PENDING = 'a5499c72-44ff-43ad-a43d-dd0485adda3f',
  SPOT_CHECK = '13bb1766-2d15-11ed-a261-0242ac120003',
  DECLARED_DISASSEMBLED = 'f276d3a8-2d12-11ed-a261-0242ac120002',
  DECLARED_LOT_BATCH_IMPORTED = '87992de6-2d13-11ed-a261-0242ac120002',
  DECLARED_LOT_BATCH_MANUFACTURED = 'c98457b6-2d14-11ed-a261-0242ac120002',
  DECLARED_LOT_BATCH_UNKNOWN_ORIGIN = 'c98452c0-2d14-11ed-a261-0242ac120002',
  DECLARED_PIECEMEAL_UNKNOWN_ORIGIN = '8799308e-2d13-11ed-a261-0242ac120002',
  DEPLETED = '13bb1766-2d15-11ed-a261-0242ac120002',
  DISABLED = '8a5d04aa-8abc-11ee-b9d1-0242ac120002',
  DESTROYED = '5a858e34-01c7-11ed-b939-0242ac120002',
}
