import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuditLog } from 'pages/AuditLogPage/hooks';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useTranslations } from 'hooks/useTranslations';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { AuditLogDetails } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { Typography } from '@mui/material';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { EventTypes, getEventTypeColor, getRoleNames } from 'pages/AuditLogPage/helpers';
import { formatDate } from 'utils/date';
import { useRoles } from 'pages/RolesPage/hooks';
import { useGlobalProperty } from 'models/globalPropertyContext';
import FirearmEventDetails from 'pages/AuditLogPage/AuditLogViewPage/FirearmEventDetails';
import TransactionEventDetails from 'pages/AuditLogPage/AuditLogViewPage/TransactionEventDetails';
import AdministrationRoleEventDetails from 'pages/AuditLogPage/AuditLogViewPage/AdministrationRoleEventDetails';
import AdministrationDictionaryEventDetails from 'pages/AuditLogPage/AuditLogViewPage/AdministrationDictionaryEventDetails';
import CustomFieldEventDetails from 'pages/AuditLogPage/AuditLogViewPage/CustomFieldEventDetails';
import LegalEntitiesEventDetails from 'pages/AuditLogPage/AuditLogViewPage/LegalEntitiesEventDetails';
import AncillariesEventDetails from 'pages/AuditLogPage/AuditLogViewPage/AncillariesEventDetails';

enum AuditLogModules {
  FIREARMS = '20fbd0b5-8668-4c03-8eea-cc92c0f4c200',
  TRANSACTIONS = 'f4517045-0c4b-4a14-be9e-a327f5e7a8cf',
  ADMINISTRATION = 'c6746456-24c6-4c53-b242-53ab63c86864',
  CUSTOM_FIELDS = 'e9cb112e-6645-46ea-a302-5ce7c44910fa',
  LEGAL_ENTITIES = '973f6d22-b2e7-43bd-929c-12c7c747dcd1',
  ANCILLARIES = '5bdfb1dc-4315-4de9-bc27-b08237fb6f7c',
  API_KEYS = 'daaca311-edd1-46f3-9ec4-5d0b3ddb2e77',
}

enum AdministrationObjectTypes {
  ROLE = 'ROLE',
  DICTIONARY = 'DICTIONARY',
}

const AuditLogViewPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { getAuditLogItemData, auditLog } = useAuditLog();
  const { getRolesData, roles } = useRoles();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    (async () => {
      getRolesData(1, 100);
      getDateFormat();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleNamesArr = auditLog ? getRoleNames(auditLog?.author?.roleIds, roles) : [];

  useEffect(() => {
    if (id) {
      (async () => {
        await getAuditLogItemData(id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDetails = () => {
    if (!auditLog?.objectUniqueId) {
      return null;
    }
    switch (auditLog.moduleUniqueId) {
      case AuditLogModules.FIREARMS:
        return <FirearmEventDetails auditLog={auditLog} />;

      case AuditLogModules.ANCILLARIES:
        return <AncillariesEventDetails auditLog={auditLog} />;

      case AuditLogModules.TRANSACTIONS:
        return <TransactionEventDetails auditLog={auditLog} />;

      case AuditLogModules.CUSTOM_FIELDS:
        return <CustomFieldEventDetails auditLog={auditLog} />;

      case AuditLogModules.ADMINISTRATION:
        if (auditLog?.objectType === AdministrationObjectTypes.ROLE) {
          return <AdministrationRoleEventDetails auditLog={auditLog} />;
        }
        if (auditLog?.objectType === AdministrationObjectTypes.DICTIONARY) {
          return <AdministrationDictionaryEventDetails auditLog={auditLog} />;
        }

        return null;

      case AuditLogModules.LEGAL_ENTITIES:
        return <LegalEntitiesEventDetails auditLog={auditLog} />;

      default:
        return null;
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('user.breadcrumbs.administration'), route: ERouteLinks.Administration },
          {
            label: t('auditLogDetails.breadcrumbs.auditLog'),
            route: ERouteLinks.AuditLogPage,
          },
          t('auditLogDetails.breadcrumbs.eventDetails'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('auditLogDetails.common.eventDetails')}
      </StyledPageTitle>

      <Grid container>
        <Grid item xs={12} lg={2}>
          <Button
            fullWidth
            label={t('general.back.link')}
            variant={EButtonVariants.outlined}
            size={EButtonSizes.medium}
            icon={EIconTypes.left}
            onClick={() => navigate(-1)}
          />
        </Grid>
      </Grid>

      {auditLog && (
        <>
          <AuditLogDetails container>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventAuthor')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {`${auditLog?.author?.firstName} ${auditLog?.author?.lastName}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.roleName')}
              </Typography>
              <Typography variant="body2">
                {roleNamesArr.map((roleName) => (
                  <Chips text={roleName as string} variant={EChipsVariants.primary} />
                ))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.phone')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog?.author?.phoneNumber?.value)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.email')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog?.author?.email)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.module')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog?.module)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventType')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {auditLog?.eventType ? (
                  <Chips
                    text={auditLog.eventType}
                    variant={getEventTypeColor(auditLog.eventTypeUniqueId as EventTypes)}
                  />
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventName')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {emptyFieldHolder(auditLog.eventName)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={3} p={2}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('auditLogDetails.common.eventDate')}
              </Typography>
              <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                {formatDate(auditLog.eventDate, longDateFormat)}
              </Typography>
            </Grid>
          </AuditLogDetails>
          {renderDetails()}
        </>
      )}
    </>
  );
};

export default AuditLogViewPage;
