import { createContext, useContext } from 'react';
import {
  IChangePasswordBodyRequest,
  IForgotPasswordBodyRequest,
  IGeneratePasswordBodyRequest,
} from 'models/password';
import { IAuthResponse } from 'models/auth';

export interface IPasswordContext {
  changePassword: (
    body: IChangePasswordBodyRequest,
    query: any,
    successCallback: (arg: IAuthResponse) => void,
    failureCallback: (arg: string) => void,
  ) => void;
  isChangePasswordLoading: boolean;
  forgotPassword: (
    body: IForgotPasswordBodyRequest,
    successCallback?: () => void,
    failureCallback?: () => void,
  ) => void;
  isForgotPasswordLoading: boolean;
  generatePassword: (body: IGeneratePasswordBodyRequest, successCallback: () => void) => void;
}

export const PasswordContext = createContext<IPasswordContext>({} as IPasswordContext);

export const usePassword = (): IPasswordContext => useContext(PasswordContext);
