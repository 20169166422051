import React, { useEffect, useState } from 'react';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useLang } from 'models/langContext';
import {
  EAncillaryState,
  EArtAmmunitionType,
  EArtAncillaryTypeName,
  EArtAncillaryTypeNameLowerCase,
  EArtComponentType,
} from 'constants/ArtAccessoryType';
import {
  getFieldsByAccessoriesType,
  getFieldsByAmmunitionType,
  getFieldsByComponentType,
} from 'pages/AncillariesPage/helpers';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useTranslations } from 'hooks/useTranslations';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import {
  AccessoryInformationContainer,
  AccessoryInformationTitle,
} from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories/styles';
import { Box, Grid, Typography } from '@mui/material';
import { EAccessoryLabels } from 'pages/AncillariesPage/AccessoriesPage/DetailAccessories';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import {
  AmmunitionInformationContainer,
  AmmunitionInformationTitle,
} from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition/styles';
import { EAmmunitionLabels } from 'pages/AncillariesPage/AmmunitionPage/DetailAmmunition';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { formatDate, formatDateTimeToIso } from 'utils/date';
import {
  ComponentInformationContainer,
  ComponentInformationTitle,
} from 'pages/AncillariesPage/ComponentsPage/DetailComponents/styles';
import { EComponentLabels } from 'pages/AncillariesPage/ComponentsPage/DetailComponents';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { StyledPageTitle } from 'theme/styles';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import { IAccessoryDetails, IAmmunitionDetails, IComponentDetails } from 'models/ancillaries';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import AncillaryCustomFieldsForm from 'pages/AncillariesPage/EditAncillaryPage/AncillaryCustomFieldsForm';
import { ICustomFieldForm } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { ActionTypes } from 'state/actions/alert';
import { updateAncillaryCustomField } from 'requests/ancillaries';
import { useAlert } from 'models/alertContext';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { areCustomFieldsValuesEqual } from 'utils/string';
import Link from '@mui/material/Link';
import { Loader } from 'components/atoms/Loader';

const EditAncillaryPage = () => {
  const { t } = useTranslations();
  const { id = '', type = '' } = useParams<{ id: string; type: string }>();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const { shortDateFormat } = useGlobalProperty();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [validCustomFields, setValidCustomFields] = useState<ICustomFieldByObjectType[]>([]);

  const {
    accessoryDetail,
    ammunitionDetail,
    componentDetail,
    getAccessoryDetail,
    getAmmunitionDetail,
    getComponentDetail,
    getCustomField,
    customFieldsAccessories,
    customFieldsAmmunition,
    customFieldsComponents,
    customFieldsLoaded,
  } = useAncillaries();
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [isAncillaryLoading, setIsAncillaryLoading] = useState<boolean>(false);

  const isStateIdDisabled =
    accessoryDetail?.stateUniqueId === EAncillaryState.DISABLED ||
    ammunitionDetail?.stateUniqueId === EAncillaryState.DISABLED ||
    componentDetail?.stateUniqueId === EAncillaryState.DISABLED;

  useEffect(() => {
    if (customFieldsAccessories.length > 0) {
      setValidCustomFields(customFieldsAccessories);
    } else if (customFieldsAmmunition.length > 0) {
      setValidCustomFields(customFieldsAmmunition);
    } else if (customFieldsComponents.length > 0) {
      setValidCustomFields(customFieldsComponents);
    }
  }, [customFieldsAccessories, customFieldsAmmunition, customFieldsComponents]);

  useEffect(() => {
    (async () => {
      setIsAncillaryLoading(true);
      if (id && type) {
        if (type === EArtAncillaryTypeNameLowerCase.ACCESSORY) {
          await getAccessoryDetail(id);
        } else if (type === EArtAncillaryTypeNameLowerCase.AMMUNITION) {
          await getAmmunitionDetail(id);
        } else if (type === EArtAncillaryTypeNameLowerCase.COMPONENTS) {
          await getComponentDetail(id);
        }
        setIsAncillaryLoading(false);
      }
    })();
  }, [id, selectedLanguage]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const parseAncillary = (
    item: IAccessoryDetails | IAmmunitionDetails | IComponentDetails,
  ) => {
    if (item.customFields) {
      item.customFields?.map((customField, index) => {
        if (
          customField.customFieldType === ECustomFieldType.BOOL &&
          customField.value === null
        ) {
          delete item?.customFields?.[index];
        }
        return false;
      });
      item.customFields = item.customFields?.filter((row) => row);
    }
    return item;
  };

  useEffect(() => {
    (async () => {
      let fields;

      if (accessoryDetail && accessoryDetail.typeUniqueId) {
        parseAncillary(accessoryDetail);
        fields = getFieldsByAccessoriesType(accessoryDetail.typeUniqueId);

        await getCustomField(ECustomFieldObjectType.AN_ACCESSORY);
      } else if (ammunitionDetail && ammunitionDetail.typeUniqueId) {
        parseAncillary(ammunitionDetail);
        fields = getFieldsByAmmunitionType(
          ammunitionDetail.typeUniqueId as EArtAmmunitionType,
        );

        await getCustomField(ECustomFieldObjectType.AN_AMMUNITION);
      } else if (componentDetail && componentDetail.typeUniqueId) {
        parseAncillary(componentDetail);
        fields = getFieldsByComponentType(componentDetail.typeUniqueId as EArtComponentType);

        await getCustomField(ECustomFieldObjectType.AN_COMPONENT);
      }

      setAccessFields(fields || []);
    })();
  }, [accessoryDetail, ammunitionDetail, componentDetail]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  const getReturnUrl = () => {
    if (accessoryDetail) {
      return ERouteLinks.ViewAccessories.replace(':id', id);
    }
    if (ammunitionDetail) {
      return ERouteLinks.ViewAmmunition.replace(':id', id);
    }
    if (componentDetail) {
      return ERouteLinks.ViewComponents.replace(':id', id);
    }
    return '';
  };

  const onSubmitForm = async (data: { [key: string]: any }) => {
    setSubmitLoading(true);
    let customFieldPayload: ICustomFieldForm[] = [];

    Object.keys(data).map((key) => {
      const customField = validCustomFields.find((item) => item.name === key);
      if (customField) {
        const customFieldItem: ICustomFieldForm = {
          customFieldUniqueId: customField.uniqueId,
        };
        if (customField.customFieldType === ECustomFieldType.TEXT) {
          if (data[key] === undefined || data[key] === null) {
            customFieldItem.text = undefined;
          } else if (data[key].trim() === '') {
            customFieldItem.text = undefined;
          } else {
            customFieldItem.text = data[key];
          }
        } else if (customField.customFieldType === ECustomFieldType.BOOL) {
          if (data[key] === '') {
            customFieldItem.bool = null;
          } else {
            customFieldItem.bool = data[key] === '1';
          }
        } else if (customField.customFieldType === ECustomFieldType.DATE) {
          customFieldItem.date = data[key] ? formatDateTimeToIso(data[key]) : null;
        }
        customFieldPayload.push(customFieldItem);
        delete data[key];
      }
      return false;
    });

    const ancillaryDetails = accessoryDetail || ammunitionDetail || componentDetail;
    if (ancillaryDetails?.customFields) {
      customFieldPayload = customFieldPayload.filter((item) => {
        const cfItem = ancillaryDetails?.customFields.find(
          (cf) => cf.customFieldUniqueId === item.customFieldUniqueId,
        );
        const value = item?.text || item?.date || item?.bool;
        if (cfItem) {
          if (
            item.date !== undefined &&
            item.date !== null &&
            cfItem.value !== undefined &&
            cfItem.value !== null &&
            typeof value !== 'boolean' &&
            cfItem.customFieldType === ECustomFieldType.DATE
          ) {
            const formattedValue = formatDateTimeToIso(value, true);
            const newValue = formattedValue.substring(0, formattedValue.indexOf('T'));
            const oldValue = cfItem.value.substring(0, cfItem.value.indexOf('T'));
            return !areCustomFieldsValuesEqual(newValue, oldValue);
          }
          return !areCustomFieldsValuesEqual(value, cfItem.value);
        }
        return !areCustomFieldsValuesEqual(value, undefined);
      });
    }

    if (ancillaryDetails?.version === undefined) {
      setSubmitLoading(false);
      return;
    }

    try {
      if (customFieldPayload.length > 0) {
        await updateAncillaryCustomField(
          id as string,
          customFieldPayload,
          ancillaryDetails.version,
        );
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('customField.has.updated.successfully'),
          variant: EAlertVariants.success,
        });
        navigate(getReturnUrl());
      } else {
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: t('editCustomFieldData.save.warning.text'),
          variant: EAlertVariants.warning,
        });
      }
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.ancillaries.name'), route: ERouteLinks.Ancillaries },
          {
            label: t('ancillary.details.header'),
            route: ERouteLinks.ViewAncillary.replace(':id', id).replace(':type', type),
          },
          t('ancillary.edit.header'),
        ]}
      />

      <StyledPageTitle variant="h4" sx={{ marginBottom: '20px' }}>
        {t('ancillary.edit.header')}
      </StyledPageTitle>
      <div>
        {isAncillaryLoading ? (
          <Loader isLoading={isAncillaryLoading} />
        ) : (
          <>
            {accessoryDetail && (
              <DetailsTemplate title={accessoryDetail?.type} pageType={EDetailsPageTypes.view}>
                <AccessoryInformationContainer item xs={12} lg={12} container>
                  <Grid item xs={12} lg={12} container direction="column">
                    <AccessoryInformationTitle variant="subtitle1">
                      {t(EAccessoryLabels.accessoryGeneralInformation)}
                    </AccessoryInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.accessoryType)}
                    </Typography>
                    <Typography variant="body2">{accessoryDetail?.type}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                    >
                      {t(EAccessoryLabels.artId)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {accessoryDetail?.artId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} container direction="column">
                    <Link
                      href={`${ERouteLinks.ViewAncillaries.replace(
                        ':id',
                        accessoryDetail.artUniqueId,
                      ).replace(':type', EArtAncillaryTypeName.ACCESSORY.toLowerCase())}`}
                      underline="none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        fullWidth
                        label={t('accessoryDetails.showArtInformation.button')}
                        variant={EButtonVariants.contained}
                        size={EButtonSizes.small}
                      />
                    </Link>
                  </Grid>

                  <Grid item xs={12} md={12} lg={6} container direction="column" />

                  {showField('manufacturer') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.manufacturer)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.manufacturer)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('productName') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.productName)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.productName)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('calibre') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.calibre)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.calibre)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('hostType') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.hostType)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.hostType)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('proofHouse') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.proofHouse)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.proofHouse)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('composition') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.composition)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.composition)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('magnification') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.magnification)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.magnification)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('utility') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.utility)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.utility)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('reticle') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.reticle)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.reticle)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('lightcolour') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.lightcolour)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.lightcolour)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('capacity') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAccessoryLabels.capacity)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(accessoryDetail?.capacity)}
                      </Typography>
                    </Grid>
                  )}
                </AccessoryInformationContainer>
                <AccessoryInformationContainer item xs={12} lg={12} container borderHidden>
                  <Grid item xs={12} lg={12} container direction="column">
                    <AccessoryInformationTitle variant="subtitle1">
                      {t(EAccessoryLabels.accessoryDetailedInformation)}
                    </AccessoryInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.firecycleID)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.fireCycleId)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.serialNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.serialNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.componentState)}
                    </Typography>
                    <Typography variant="body2">
                      <Chips
                        text={emptyFieldHolder(accessoryDetail?.state)}
                        variant={EChipsVariants.primary}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.exhibitNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.exhibitNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.currentOwner)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.ownerName)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EAccessoryLabels.currentKeeper)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(accessoryDetail?.keeperName)}
                    </Typography>
                  </Grid>
                </AccessoryInformationContainer>
              </DetailsTemplate>
            )}

            {ammunitionDetail && (
              <DetailsTemplate
                title={ammunitionDetail?.type}
                pageType={EDetailsPageTypes.view}
              >
                <>
                  <AmmunitionInformationContainer item xs={12} lg={12} container>
                    <Grid item xs={12} lg={12} container direction="column">
                      <AmmunitionInformationTitle variant="subtitle1">
                        {t(EAmmunitionLabels.ammunitionGeneralInformation)}
                      </AmmunitionInformationTitle>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EAmmunitionLabels.ammunitionType)}
                      </Typography>
                      <Typography variant="body2">{ammunitionDetail?.type}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                      >
                        {t(EAmmunitionLabels.artId)}
                      </Typography>
                      <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                        {ammunitionDetail?.artId}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} container direction="column">
                      <Link
                        href={`${ERouteLinks.ViewAncillaries.replace(
                          ':id',
                          ammunitionDetail.artUniqueId,
                        ).replace(':type', EArtAncillaryTypeName.AMMUNITION.toLowerCase())}`}
                        underline="none"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          fullWidth
                          label={t('ammunitionDetails.showArtInformation.button')}
                          variant={EButtonVariants.contained}
                          size={EButtonSizes.small}
                        />
                      </Link>
                    </Grid>

                    {showField('productionDate') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.productionDate)}
                        </Typography>
                        <Typography variant="body2">
                          {formatDate(ammunitionDetail?.productionDate, shortDateFormat)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('manufacturer') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.ammunitionManufacturer)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.manufacturer}
                        </Typography>
                      </Grid>
                    )}
                    {showField('productName') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.productName)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.productName)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('factory') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.factory)}
                        </Typography>
                        <Typography variant="body2">{ammunitionDetail?.factory}</Typography>
                      </Grid>
                    )}
                    {showField('calibreGauge') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.calibreGauge)}
                        </Typography>
                        <Typography variant="body2">{ammunitionDetail?.calibre}</Typography>
                      </Grid>
                    )}
                    {showField('functionalType') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.functionalType)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.functionalType}
                        </Typography>
                      </Grid>
                    )}
                    {showField('cartridgeComposition') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.cartridgeComposition)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.cartridgeComposition}
                        </Typography>
                      </Grid>
                    )}
                    {showField('colourOfPrimerSealant') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.colourOfPrimerSealant)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.colourOfPrimerSealant}
                        </Typography>
                      </Grid>
                    )}
                    {showField('projectileShape') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.projectileShape)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.projectileShape}
                        </Typography>
                      </Grid>
                    )}
                    {showField('headStampYearMarking') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.headStampYearMarking)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.headstampYearMarking}
                        </Typography>
                      </Grid>
                    )}
                    {showField('alphanumericalMarkins') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.alphanumericalMarkins)}
                        </Typography>
                        <Typography variant="body2">
                          {ammunitionDetail?.alphanumericalMarkins}
                        </Typography>
                      </Grid>
                    )}
                  </AmmunitionInformationContainer>
                  <AmmunitionInformationContainer item xs={12} lg={12} container borderHidden>
                    <Grid item xs={12} lg={12} container direction="column">
                      <AmmunitionInformationTitle variant="subtitle1">
                        {t(EAmmunitionLabels.ammunitionDetailedInformation)}
                      </AmmunitionInformationTitle>
                    </Grid>
                    {showField('fireCycleId') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.firecycleID)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.fireCycleId)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('lotNumber') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.lotNumber)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.lotNumber)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('batchNumber') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.batchNumber)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(accessoryDetail?.batchNumber)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('state') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.ammunitionState)}
                        </Typography>
                        <Typography variant="body2">
                          <Chips
                            text={emptyFieldHolder(ammunitionDetail?.state)}
                            variant={EChipsVariants.primary}
                          />
                        </Typography>
                      </Grid>
                    )}
                    {showField('ownerName') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.currentOwner)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.ownerName)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('keeperName') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.currentKeeper)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.keeperName)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('manufacturedAt') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.manufacturedAt)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.manufacturedAt)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('expiryDate') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.expiryDate)}
                        </Typography>
                        <Typography variant="body2">
                          {formatDate(ammunitionDetail?.expiryDate, shortDateFormat)}
                        </Typography>
                      </Grid>
                    )}
                    {showField('exhibitNumber') && (
                      <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                          {t(EAmmunitionLabels.exhibitNumber)}
                        </Typography>
                        <Typography variant="body2">
                          {emptyFieldHolder(ammunitionDetail?.exhibitNumber)}
                        </Typography>
                      </Grid>
                    )}
                  </AmmunitionInformationContainer>
                </>
              </DetailsTemplate>
            )}

            {componentDetail && (
              <DetailsTemplate title={componentDetail?.type} pageType={EDetailsPageTypes.view}>
                <ComponentInformationContainer item xs={12} lg={12} container>
                  <Grid item xs={12} lg={12} container direction="column">
                    <ComponentInformationTitle variant="subtitle1">
                      {t(EComponentLabels.componentGeneralInformation)}
                    </ComponentInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.componentType)}
                    </Typography>
                    <Typography variant="body2">{componentDetail?.type}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography
                      variant="subtitle2"
                      sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                    >
                      {t(EComponentLabels.artId)}
                    </Typography>
                    <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                      {componentDetail?.artId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} container direction="column">
                    <Link
                      href={`${ERouteLinks.ViewAncillaries.replace(
                        ':id',
                        componentDetail.artUniqueId,
                      ).replace(':type', EArtAncillaryTypeName.COMPONENTS.toLowerCase())}`}
                      underline="none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        fullWidth
                        label={t('componentDetails.showArtInformation.button')}
                        variant={EButtonVariants.contained}
                        size={EButtonSizes.small}
                      />
                    </Link>
                  </Grid>

                  {showField('manufacturer') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.manufacturer)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.manufacturer)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('productName') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.productName)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.productName)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('calibre') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.calibre)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.calibre)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('composition') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.composition)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.composition)}
                      </Typography>
                    </Grid>
                  )}
                  {showField('proofHouse') && (
                    <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                        {t(EComponentLabels.proofHouse)}
                      </Typography>
                      <Typography variant="body2">
                        {emptyFieldHolder(componentDetail?.proofHouse)}
                      </Typography>
                    </Grid>
                  )}
                </ComponentInformationContainer>
                <ComponentInformationContainer item xs={12} lg={12} container>
                  <Grid item xs={12} lg={12} container direction="column">
                    <ComponentInformationTitle variant="subtitle1">
                      {t(EComponentLabels.componentDetailedInformation)}
                    </ComponentInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firecycleID)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.fireCycleId)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.serialNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.serialNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.componentState)}
                    </Typography>
                    <Typography variant="body2">
                      <Chips
                        text={emptyFieldHolder(componentDetail?.state)}
                        variant={EChipsVariants.primary}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.exhibitNumber)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.exhibitNumber)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.currentOwner)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.ownerName)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.currentKeeper)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.keeperName)}
                    </Typography>
                  </Grid>
                </ComponentInformationContainer>
                <ComponentInformationContainer item xs={12} lg={12} container borderHidden>
                  <Grid item xs={12} lg={12} container direction="column">
                    <ComponentInformationTitle variant="subtitle1">
                      {t(EComponentLabels.firearmInformation)}
                    </ComponentInformationTitle>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmManufacturer)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtManufacturer)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmReferenceNo)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtReferenceNo)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} container direction="column">
                    <Link
                      href={`${ERouteLinks.FRTDetails.replace(
                        ':frtId',
                        componentDetail.frtId,
                      )}`}
                      underline="none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        variant={EButtonVariants.contained}
                        size={EButtonSizes.small}
                        label={t('componentDetails.showFrtInformation.button')}
                        fullWidth
                      />
                    </Link>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmFirecycleType)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtType)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmMake)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtMake)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t(EComponentLabels.firearmModel)}
                    </Typography>
                    <Typography variant="body2">
                      {emptyFieldHolder(componentDetail?.frtModel)}
                    </Typography>
                  </Grid>
                </ComponentInformationContainer>
              </DetailsTemplate>
            )}

            {(customFieldsLoaded &&
              ((accessoryDetail && !customFieldsAccessories?.length) ||
                (ammunitionDetail && !customFieldsAmmunition?.length) ||
                (componentDetail && !customFieldsComponents?.length))) ||
            isStateIdDisabled ? (
              <Box sx={{ margin: '20px 0' }}>
                <Alert
                  text={t('general.noAvaibleDataToEdit.label')}
                  variant={EAlertVariants.error}
                />
              </Box>
            ) : (
              <AncillaryCustomFieldsForm
                customFields={validCustomFields}
                ancillary={accessoryDetail || ammunitionDetail || componentDetail}
                onSubmit={onSubmitForm}
                isSubmitLoading={isSubmitLoading}
              />
            )}

            {samePageAlert && (
              <Box mt={2}>
                <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
              </Box>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EditAncillaryPage;
