import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';

export const StyledEmptyNotificationWrapper = styled(Grid)`
  background-color: ${colors.gray100};
  border: 1px solid ${colors.gray200};
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 16px;
  width: 100%;
`;
