import React, { useEffect, useState } from 'react';
import { initialTransactionSearchFormValues, ISearchTransactionFormValues } from 'models/form';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { useTranslations } from 'hooks/useTranslations';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchTransactionFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { prepareFilters } from 'helpers/filters';
import { add } from 'date-fns';
import { isDateAfter, isDateEqual } from 'utils/date';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { filterOutAncillaryStates } from 'pages/LegalEntitiesPage/FirearmSearchForm';

enum ESearchFormLabels {
  stateUniqueId = 'registeredFirearms.firearmsDetails.transactions.accordion.state.label',
  transactionDateFrom = 'registeredFirearms.firearmsDetails.transactions.accordion.transactionDateFrom.label',
  transactionDateTo = 'registeredFirearms.firearmsDetails.transactions.accordion.transactionDateTo.label',
}

interface ITransactionSearchForm {
  onSubmit: (arg: ISearchTransactionFormValues) => void;
  showSaveFilterButton?: boolean;
}

const TransactionSearchForm = ({
  onSubmit,
  showSaveFilterButton = true,
}: ITransactionSearchForm) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ISearchTransactionFormValues>({
    defaultValues: initialTransactionSearchFormValues,
  });
  const [values, setValues] = useState<ISearchTransactionFormValues>(
    initialTransactionSearchFormValues,
  );
  const { t } = useTranslations();
  const { state, loadDictionaries } = useLegalEntities();
  const { selectedLanguage } = useLang();

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchTransactionFormValues);
      const message = t(
        'registeredFirearms.firearmsDetails.transactions.accordion.transactionDateValidation.text',
      );
      if (
        isDateAfter(getValues().transactionDateFrom, value.transactionDateTo as string) ||
        isDateEqual(value.transactionDateTo as string, getValues().transactionDateFrom)
      ) {
        setError('transactionDateTo', { type: 'custom', message });
      } else {
        clearErrors('transactionDateTo');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.State]);
    })();
  }, [selectedLanguage]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialTransactionSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  const minTransactionDateTo = (date: Date) =>
    date
      ? add(date, {
          days: 1,
        })
      : undefined;

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" spacing={1} columns={12}>
          <Grid item lg={3} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(filterOutAncillaryStates(state))}
              name="stateUniqueId"
              canBeEmpty={true}
              label={t(ESearchFormLabels.stateUniqueId)}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <FormDatePicker
              name="transactionDateFrom"
              openTo="year"
              label={t(ESearchFormLabels.transactionDateFrom)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <FormDatePicker
              name="transactionDateTo"
              openTo="year"
              label={t(ESearchFormLabels.transactionDateTo)}
              control={control}
              errors={errors}
              minDate={minTransactionDateTo(getValues().transactionDateFrom as Date)}
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <Button
              id="transactions-search-button"
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t(
                'registeredFirearms.firearmsDetails.transactions.accordion.search,button',
              )}
              type="submit"
              fullWidth={true}
              sx={{ mt: 3.5 }}
            />
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="transaction"
        values={values}
        labels={ESearchTransactionFormLabelsTypes}
        showSaveFilters={showSaveFilterButton}
        handleDelete={handleResetField}
        noPreset
        dictionaries={{
          stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
        }}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { TransactionSearchForm };
