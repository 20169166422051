import { createContext, useContext } from 'react';

export interface ILicenceContext {
  setLicenceData: (type: string, payload: any) => void;
  isOnlineLicence: () => boolean;
  isValidLicence: () => boolean;
  licenceType: string;
  expiryDate: string | null;
}

export const LicenceContext = createContext<ILicenceContext>({} as ILicenceContext);

export const useLicenceContext = (): ILicenceContext => useContext(LicenceContext);
