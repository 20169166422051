import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';

export const StyledMaskForm = styled(Grid)`
  background-color: ${colors.primary15};
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
`;

export const StyledMaskingRules = styled(Grid)`
  color: ${colors.primary500};
  padding: 10px;

  & h6,
  & p {
    color: ${colors.primary500};
    font-size: 14px;
    line-height: 20px;
  }
`;

export const StyledMaskingExample = styled(Grid)`
  padding: 10px;

  & h6,
  & h5,
  & p {
    color: ${colors.primary500};
  }

  & p {
    font-size: 14px;
    line-height: 20px;
  }

  & h5 {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  & > div {
    border: 1px solid ${colors.primary50};
    border-radius: 4px;
    padding: 15px;
    margin: 0 auto;
    text-align: center;
  }
`;
