import React from 'react';
import { EIconTypes } from 'constants/Icons';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { StyledChip } from './styles';

export interface IFilterChip {
  name: string;
  label: string;
  values: string | number;
  handleDelete?: (arg0?: string, arg1?: boolean) => void;
}

const FilterChip = ({ name, label, values, handleDelete }: IFilterChip) => (
  <StyledChip hasDelete={!!handleDelete}>
    <span>{`${label}:`}</span>
    <span>{values}</span>
    {handleDelete && (
      <Button
        variant={EButtonVariants.outlined}
        icon={EIconTypes.close}
        onClick={() => handleDelete(name, false)}
      />
    )}
  </StyledChip>
);

export { FilterChip };
