import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useTranslations } from 'hooks/useTranslations';

interface ILETableCard {
  data: ITableDataSource;
  handleDelete: (arg: string) => void;
}

export const AssignedLETableCard = ({ data, handleDelete }: ILETableCard) => {
  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={12} sm={12} lg={3} px={2} pt={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('userDetails.assignedLegalEntityTable.legalEntityType.column')}
        </Typography>
        <Chips
          text={data.type}
          variant={
            data.type === ELegalEntityTypes.individual
              ? EChipsVariants.warning
              : EChipsVariants.primary
          }
        />
      </Grid>
      {data.orgName && (
        <Grid item xs={12} sm={6} lg={3} px={2} pt={2}>
          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
            {t('userDetails.assignedLegalEntityTable.organizationName.column')}
          </Typography>
          <Typography variant="body2">{data.orgName}</Typography>
        </Grid>
      )}
      {data.orgType && (
        <Grid item xs={12} sm={6} lg={3} px={2} pt={2}>
          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
            {t('userDetails.assignedLegalEntityTable.organizationType.column')}
          </Typography>
          <Typography variant="body2">{data.orgType}</Typography>
        </Grid>
      )}
      {data.firstName && (
        <Grid item xs={12} sm={6} lg={3} px={2} pt={2}>
          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
            {t('userDetails.assignedLegalEntityTable.firstName.column')}
          </Typography>
          <Typography variant="body2">{data.firstName}</Typography>
        </Grid>
      )}
      {data.lastName && (
        <Grid item xs={12} sm={6} lg={3} px={2} pt={2}>
          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
            {t('userDetails.assignedLegalEntityTable.lastName.column')}
          </Typography>
          <Typography variant="body2">{data.lastName}</Typography>
        </Grid>
      )}
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        pt={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" textTransform="uppercase">
            {t('userDetails.assignedLegalEntityTable.action.column')}:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('userDetails.assignedLegalEntityTable.delete.link')}
            variant={EButtonVariants.outlined}
            onClick={handleDelete}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
