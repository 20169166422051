import React, { ReactElement, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { EFormInputTypes, FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { initialLoginFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'pages/LoginPage/schemaValidation';
import { EButtonVariants } from 'constants/Buttons';
import { RouterLink } from 'components/atoms/RouterLink';
import { useAuth } from 'models/authContext';
import { IAuthBodyRequest, IAuthResponse, ILoginForm } from 'models/auth';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { Alert } from 'components/atoms/Alert';
import {
  AuthTemplate,
  getBase64Data,
  StyledAuthHeader,
  StyledImg,
} from 'components/templates/AuthTemplate';
import { IData } from 'pages/AuthPage';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useLicenceContext } from 'models/licenceContext';
import { ELoginPartTypes } from 'models/loginScreenSetup';
import { IImageData, useLogoSetup } from 'pages/SystemSettingsPage/LogoSetupPage/hooks';

export const FC_SYSTEM_DEVICE_ID = 'FC_SYSTEM_DEVICE_ID';

export interface ILoginPageProps {
  changePage: (p: number) => void;
  changeDataCallback: (p: IData) => void;
}

const LoginPage = ({ changePage, changeDataCallback }: ILoginPageProps): ReactElement => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: initialLoginFormValues, resolver: yupResolver(schema) });
  const { logIn, authMessage, isLoginLoading, clearAuthErrors } = useAuth();
  const { authAlert, clearAlert } = useAlert();
  const { isOnlineLicence } = useLicenceContext();
  const { getLoginScreen, loginScreenDataParsed } = useLogoSetup();
  const anyLogoPresent: boolean =
    loginScreenDataParsed !== null &&
    loginScreenDataParsed !== undefined &&
    (loginScreenDataParsed[ELoginPartTypes.LOGO_1] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_2] !== undefined ||
      loginScreenDataParsed[ELoginPartTypes.LOGO_3]) !== undefined;

  useEffect(() => {
    (async () => {
      await getLoginScreen();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAuthErrors();
    },
    [],
  );
  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_AUTH_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmit = (data: ILoginForm) => {
    const body: IAuthBodyRequest = {
      ...data,
      deviceToken: localStorage.getItem(FC_SYSTEM_DEVICE_ID) || null,
    };
    logIn(body, (res: IAuthResponse) => {
      if (res.accessToken) {
        navigate(ERouteLinks.Dashboard);
        changePage(3);
      } else if (res.phoneNumber) {
        changePage(2);
        const phoneNumber = res.phoneNumber.toString();
        const numberEndPart = phoneNumber.slice(phoneNumber.length - 3);
        changeDataCallback({
          ...data,
          phoneNumber: `*********${numberEndPart}`,
        });
      }
    });
  };
  return (
    <AuthTemplate>
      <Grid
        item
        container
        justifyContent="center"
        alignItems={anyLogoPresent ? 'flex-start' : 'center'}
        sm={10}
        md={7}
        lg={6}
        pb={5}
        sx={{ p: { xs: 4 } }}
        px={2}
      >
        {anyLogoPresent && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              minHeight: '151px',
              width: '100%',
            }}
            mt={3}
          >
            <Box>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_1] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_1] as IImageData,
                  )}
                  alt="Logo One"
                />
              )}
            </Box>
            <Box pr={2} pl={2}>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_2] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_2] as IImageData,
                  )}
                  alt="Logo Two"
                />
              )}
            </Box>
            <Box>
              {loginScreenDataParsed?.[ELoginPartTypes.LOGO_3] && (
                <StyledImg
                  src={getBase64Data(
                    loginScreenDataParsed[ELoginPartTypes.LOGO_3] as IImageData,
                  )}
                  alt="Logo Three"
                />
              )}
            </Box>
          </Box>
        )}

        <Grid item xl={6} container justifyContent="center" alignItems="flex-start">
          <Grid item xs={12} mt={1}>
            {authAlert && (
              <Box mb={3} mt={2}>
                <Alert text={authAlert.text} variant={authAlert.variant} />
              </Box>
            )}
            {authMessage.text && !authAlert && (
              <Box mb={3} mt={2}>
                <Alert text={authMessage.text} variant={authMessage.variant} />
              </Box>
            )}
            <StyledAuthHeader mb={4}>Welcome</StyledAuthHeader>
            <Typography variant="body1" textAlign="center" mb={3}>
              Log in to access FireCycle
              <br />
              using your email address and password.
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <FormInput
                  name="email"
                  label="Email"
                  placeholder="name@domain.com"
                  control={control}
                  errors={errors}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: { xs: 32, sm: 40 },
                    },
                  }}
                />
                <FormInput
                  name="password"
                  type={EFormInputTypes.password}
                  label="Password"
                  placeholder="Password"
                  control={control}
                  errors={errors}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: { xs: 32, sm: 40 },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={7} xl={12} container justifyContent="center">
                <Grid item xs={12} mb={2}>
                  <Button
                    id="login"
                    type="submit"
                    label="Login"
                    variant={EButtonVariants.contained}
                    isLoading={isLoginLoading}
                    fullWidth
                  />
                </Grid>
                {isOnlineLicence() && (
                  <RouterLink to={ERouteLinks.ForgotPassword} label="Forgot password?" />
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </AuthTemplate>
  );
};
export { LoginPage };
