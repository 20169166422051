import * as yup from 'yup';

import { useTranslations } from 'hooks/useTranslations';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { IMask } from 'react-imask';
import { CHARS_LIMIT } from 'components/organisms/FormCustomField';

export const useIndLEFormSchema = (customFields: ICustomFieldByObjectType[]) => {
  const { t } = useTranslations();

  const customFieldsValidations: { [key: string]: any } = {};

  customFields.map((customField) => {
    const baseValidation = yup.string().nullable();

    if (customField.customFieldType === ECustomFieldType.TEXT && customField.mandatory) {
      customFieldsValidations[customField.name] = baseValidation
        .required(t('general.field.validation.required'))
        .max(
          CHARS_LIMIT,
          t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
        );
    } else if (customField.customFieldType === ECustomFieldType.TEXT) {
      customFieldsValidations[customField.name] = baseValidation.max(
        CHARS_LIMIT,
        t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
      );
    } else if (customField.mandatory) {
      customFieldsValidations[customField.name] = baseValidation.required(
        t('general.field.validation.required'),
      );
    }

    return false;
  });

  let schema = yup.object({
    firstName: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
      .trim(),
    lastName: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
      .trim(),
    dateOfBirth: yup
      .string()
      .nullable()
      .required(t('general.field.validation.required'))
      .test(
        'age-validation',
        t('legalEntity.validation.minAge').replace('{0}', '255'),
        (value) => {
          if (typeof value !== 'string') {
            return false;
          }
          const maxValidDateOfBirth = new Date(
            new Date().setFullYear(new Date().getFullYear() - 14),
          ).setDate(new Date().getDate() - 1);
          const dateOfBirth = new Date(value);
          return dateOfBirth.valueOf() <= maxValidDateOfBirth.valueOf();
        },
      ),
    genderUniqueId: yup.string().required(t('general.field.validation.required')).trim(),
    visibilityUniqueId: yup.string().required(t('general.field.validation.required')).trim(),
    prefixGroupUniqueId: yup.string().required(t('general.field.validation.required')).trim(),
    prefixValueUniqueId: yup.string().required(t('general.field.validation.required')).trim(),
    ...customFieldsValidations,
  });

  customFields.map((customField) => {
    if (customField.inputMaskEnabled && customField.inputMask) {
      schema = schema.test(`customFieldMaskValidation${customField.uniqueId}`, '', (obj) => {
        const mask = customField?.inputMask;
        if (mask) {
          const masked = IMask.createMask({ mask } as IMask.AnyMaskedOptions);
          // @ts-ignore
          if (obj?.[customField.name]) {
            // @ts-ignore
            masked.resolve(obj?.[customField.name] as string);
            if (!masked.isComplete) {
              return new yup.ValidationError(
                `Wrong input mask value (${mask})`,
                null,
                customField.name,
              );
            }
          }
        }

        return true;
      });
    }
    return false;
  });

  return { schema };
};

export const useOrgLEFormSchema = (customFields: ICustomFieldByObjectType[]) => {
  const { t } = useTranslations();

  const customFieldsValidations: { [key: string]: any } = {};

  customFields.map((customField) => {
    const baseValidation = yup.string().nullable();

    if (customField.customFieldType === ECustomFieldType.TEXT && customField.mandatory) {
      customFieldsValidations[customField.name] = baseValidation
        .required(t('general.field.validation.required'))
        .max(
          CHARS_LIMIT,
          t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
        );
    } else if (customField.customFieldType === ECustomFieldType.TEXT) {
      customFieldsValidations[customField.name] = baseValidation.max(
        CHARS_LIMIT,
        t('general.field.validation.maxCharacters').replace('{0}', CHARS_LIMIT.toString()),
      );
    } else if (customField.mandatory) {
      customFieldsValidations[customField.name] = baseValidation.required(
        t('general.field.validation.required'),
      );
    }

    return false;
  });

  let schema = yup.object({
    organizationName: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(80, t('general.field.validation.maxCharacters').replace('{0}', '80'))
      .trim(),
    organizationTypeUniqueId: yup
      .string()
      .required(t('general.field.validation.required'))
      .trim(),
    departmentName: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(80, t('general.field.validation.maxCharacters').replace('{0}', '80'))
      .trim(),
    visibilityUniqueId: yup.string().required(t('general.field.validation.required')).trim(),
    ...customFieldsValidations,
  });

  customFields.map((customField) => {
    if (customField.inputMaskEnabled && customField.inputMask) {
      schema = schema.test(`customFieldMaskValidation${customField.uniqueId}`, '', (obj) => {
        const mask = customField?.inputMask;
        if (mask) {
          const masked = IMask.createMask({ mask } as IMask.AnyMaskedOptions);
          // @ts-ignore
          if (obj?.[customField.name]) {
            // @ts-ignore
            masked.resolve(obj?.[customField.name] as string);
            if (!masked.isComplete) {
              return new yup.ValidationError(
                `Wrong input mask value (${mask})`,
                null,
                customField.name,
              );
            }
          }
        }

        return true;
      });
    }
    return false;
  });

  return { schema };
};

export const useLEContactSchema = (type: ELegalEntityTypes) => {
  const { t } = useTranslations();
  if (type === ELegalEntityTypes.individual) {
    const leContactSchema = yup.object({
      email: yup
        .string()
        .email(t('general.field.email.validation.incorrect'))
        .required(t('general.field.validation.required'))
        .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
        .trim(),
      codeDictionaryUniqueId: yup
        .string()
        .required(t('general.field.validation.required'))
        .trim(),
      value: yup
        .string()
        .required(t('general.field.validation.required'))
        .max(15, t('general.field.validation.maxNumberCharacters').replace('{0}', '15'))
        .min(5, t('general.field.validation.minNumberCharacters').replace('{0}', '5'))
        .trim(),
    });
    return { leContactSchema };
  }
  const leContactSchema = yup.object({
    name: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    role: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    email: yup
      .string()
      .email(t('general.field.email.validation.incorrect'))
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    codeDictionaryUniqueId: yup
      .string()
      .required(t('general.field.validation.required'))
      .trim(),
    value: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(15, t('general.field.validation.maxNumberCharacters').replace('{0}', '15'))
      .min(5, t('general.field.validation.minNumberCharacters').replace('{0}', '5'))
      .trim(),
  });
  return { leContactSchema };
};

export const useAddressSchema = () => {
  const { t } = useTranslations();
  const addressSchema = yup.object({
    typeUniqueId: yup.string().required(t('general.field.validation.required')).nullable(),
    isDefault: yup.boolean(),
    line1: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    line2: yup
      .string()
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    postalCode: yup
      .string()
      .notRequired()
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    city: yup
      .string()
      .required(t('general.field.validation.required'))
      .nullable()
      .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
      .trim(),
    regionUniqueId: yup.string().notRequired().nullable(),
    countryUniqueId: yup.string().required(t('general.field.validation.required')).nullable(),
    latitude: yup.string().required(t('general.field.validation.required')),
    longitude: yup.string().required(t('general.field.validation.required')),
  });
  return { addressSchema };
};

export const useLEGroupSchema = () => {
  const { t } = useTranslations();
  const leGroupSchema = yup.object({
    name: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(128, t('general.field.validation.maxCharacters').replace('{0}', '128'))
      .trim(),
  });
  return { leGroupSchema };
};
