import styled from '@emotion/styled';
import { Box, Theme, Typography } from '@mui/material';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';

export const DashboardChartWrapper = styled(Box)`
  border: 1px solid ${colors.primary25};
  border-radius: 4px;
  padding: 20px;

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('lg')} {
        height: 100%;
        display: grid;
        align-items: stretch;
        align-content: space-between;
      };
      `};
`;

interface IChartColorLegend {
  color: string;
}

export const ChartLabelTypography = styled(Typography)`
  word-break-wrap: true;
  width: auto;
  max-width: max-content;
  min-width: min-content;
`;

export const ChartColorLegend = styled.div<IChartColorLegend>`
  width: 32px;
  height: 16px;
  border-radius: 4px;
  background-color: ${({ color }: IChartColorLegend): string => color || colors.primary900};
  margin-right: 10px;
`;

export const ChartNoDataGridItem = styled(Grid)`
  background-color: ${colors.primary25};
  height: 160px;
  margin: 10px;
  padding: 20px 0;
  align-items: center;
  align-content: center;
  width: auto;
  & p {
    color: ${colors.gray400};
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    max-width: 165px;
    margin: auto;
  }
`;

export const ChartLegendGridItem = styled(Grid)`
  padding-bottom: 8px;
  display: flex;
`;

export const ChartLegendBogItem = styled(Box)`
  display: flex;
  align-items: center;
`;
