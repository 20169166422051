import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { colors } from 'theme/colors';
import { Theme } from '@mui/system';

export const StyledFirearmListBox = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid ${colors.gray200};
  background-color: ${colors.white};
`;

export const StyledLocationMarkConfirmation = styled.div`
  max-width: 285px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 6px 16px;
  background-color: ${colors.success50};
  border-radius: 4px;
  height: 34px;
  margin-top: 28px;
  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('lg')} {
      margin: 0 auto
      };
      `};
`;

export const StyledIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  padding: 3px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
  background-color: ${colors.success900};
`;
