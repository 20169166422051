import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { IRole, IRoleFormEditRequest, IRoleFormRequest, IRolesResponse } from 'models/role';
import { isResponseError, TResponse } from 'models/apiClient';
import { EPerPages, ISortOptions } from 'models/table';
import { ISearchFormValues } from 'pages/RolesPage/RoleSearchForm';
import { queryStringWithSort } from 'helpers/request';

/**
 *
 */
const getRoles = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const queryString = queryStringWithSort({ page: page - 1, size, ...query }, sort);

  const { data } = await apiClient.get<TResponse<IRolesResponse>>(
    `${ApiEndpoints.GET_ROLES()}?${queryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param body
 */
const createRole = async (body: IRoleFormRequest) =>
  apiClient.post<TResponse<IRole>>(ApiEndpoints.CREATE_ROLE(), body);

/**
 *
 * @param id
 */
const getRole = async (id: string): Promise<IRole | null> => {
  const { data } = await apiClient.get<TResponse<IRole>>(ApiEndpoints.GET_ROLE(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param role
 */
const updateRole = async (role: IRoleFormEditRequest) => {
  const { uniqueId, createdDate, ...body } = role;

  try {
    const { data } = await apiClient.put(ApiEndpoints.UPDATE_ROLE(uniqueId), body, {
      timeout: 60000,
    });
    return !isResponseError(data);
  } catch (e: any) {
    return e?.response?.data?.errors?.[0]?.message;
  }
};

const deleteRole = async (id: string): Promise<boolean> => {
  const { data } = await apiClient.delete(ApiEndpoints.DELETE_ROLE(id));

  return !isResponseError(data);
};

export { getRoles, createRole, getRole, updateRole, deleteRole };
