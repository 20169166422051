import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/auth';
import { addTrustedDevice, fetchTrustedDevices } from 'requests/trustedDevice';
import { IAddTrustedDeviceBodyRequest, IAddTrustedDeviceResponse } from 'models/trustedDevice';
import { useNavigate } from 'react-router-dom';
import { TrustedDeviceContext } from 'models/trustedDeviceContext';

export const TrustedDeviceProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const navigate = useNavigate();

  const value = useMemo(
    () => ({
      ...state,
      addTrustedDevice: (body: IAddTrustedDeviceBodyRequest, successCallback: () => void) =>
        addTrustedDevice(dispatch, body, () => successCallback()),
      fetchTrustedDevices: (
        successCallback: (res: Array<IAddTrustedDeviceResponse>) => void,
      ) => fetchTrustedDevices((res) => successCallback(res)),
    }),
    [state, navigate],
  );

  return (
    <TrustedDeviceContext.Provider value={value}>{children}</TrustedDeviceContext.Provider>
  );
};
