import styled from '@emotion/styled';

export const PDFDocumentWrapper = styled.div`
  canvas {
    height: auto !important;
    margin: 0 auto;
  }
  span {
    display: none;
  }
`;
