import { useState } from 'react';
import { ILicenceData } from 'models/licence';
import { getLicenceDetail, redeemLicenceKey } from 'requests/licence';

export const useLicence = () => {
  const [licence, setLicence] = useState<ILicenceData | null>(null);

  const getLicenceData = async (): Promise<ILicenceData | null> => {
    const licenceData = await getLicenceDetail();
    if (licenceData) {
      setLicence(licenceData);
      return licenceData;
    }
    setLicence(null);
    return null;
  };

  const replaceLicenceHandler = async (key: string) => redeemLicenceKey(key);

  return {
    licence,
    getLicenceData,
    replaceLicenceHandler,
  };
};
