import { IPaginator } from 'models/paginator';

export interface IApiKey {
  uniqueId: string;
  username: string;
  type: string;
  typeDictionaryUniqueId: string;
  key: string;
  status: string;
  statusDictionaryUniqueId: string;
  lastUsedDate: string;
  expiryDate: string;
  expiryStatus: string;
  expiryStatusDictionaryUniqueId: string;
}

export interface IApiKeysResponse extends IPaginator {
  content: IApiKey[];
}

export interface IApiKeyResponse {
  type: string;
  username: string;
  expiryDate: string | null;
  description: string;
  contactEmail: string;
  firstName: string;
  lastName: string;
  phonePrefixDictionaryUniqueId: string;
  phoneNumber: string;
  phonePrefixValue: { languageUniqueId: string; name: string; uniqueId: string }[];
  statusDictionaryUniqueId: string;
  lastUsedDate: string | null;
  uniqueId: string;

  key: string;

  apikeyAncillaryAccessory: { [key: string]: boolean };
  apikeyAncillaryAmmunition: { [key: string]: boolean };
  apikeyAncillaryComponent: { [key: string]: boolean };
  apikeyFirearm: { [key: string]: boolean };
  apikeyLegalEntity: { [key: string]: boolean };
  apikeyTransaction: { [key: string]: boolean };
}

export interface IApiKeyRequest {
  apikeyAncillaryAccessory: { [key: string]: boolean };
  apikeyAncillaryAmmunition: { [key: string]: boolean };
  apikeyAncillaryComponent: { [key: string]: boolean };
  apikeyFirearm: { [key: string]: boolean };
  apikeyLegalEntity: { [key: string]: boolean };
  apikeyTransaction: { [key: string]: boolean };

  type: string;
  username: string;
  expiryDate: string | null;
  description: string;
  contactEmail: string;
  firstName: string;
  lastName: string;
  contactPhone: {
    codeDictionaryUniqueId: string;
    value: string;
  };

  [key: string]: any;
}

export interface IApiKeyHistory {
  uniqueId: string;
  eventTypeUniqueId: string;
  eventType: string;
  eventNameUniqueId: string;
  eventName: string;
  eventDate: string;
  authorUniqueId: string;
  authorFirstName: string;
  authorLastName: string;
  authorRoleIds: string[];
}

export interface IApiKeyHistoryResponse extends Array<IApiKeyHistory> {}

export const apiKeyScopeList = [
  {
    label: 'apiKeysForm.scope.legalEntities.label',
    value: 'apikeyLegalEntity',
    items: [
      {
        label: 'apiKeysForm.scope.legalEntities.type.label',
        value: 'apikeyLegalEntity_leType',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.group.label',
        value: 'apikeyLegalEntity_leGroup',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.address.label',
        value: 'apikeyLegalEntity_leAddress',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.individualPrefix.label',
        value: 'apikeyLegalEntity_leIndividualPrefix',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.individualName.label',
        value: 'apikeyLegalEntity_leIndividualName',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.individualSurname.label',
        value: 'apikeyLegalEntity_leIndividualSurname',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.individualDateOfBirth.label',
        value: 'apikeyLegalEntity_leIndividualDateOfBirth',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.individualGender.label',
        value: 'apikeyLegalEntity_leIndividualGender',
      },
      {
        label: 'apiKeysForm.scope.legalEntities.organisationType.label',
        value: 'apikeyLegalEntity_leOrganisationType',
      },
    ],
  },
  {
    label: 'apiKeysForm.scope.firearms.label',
    value: 'apikeyFirearm',
    items: [
      {
        label: 'apiKeysForm.scope.firearms.serialNumber.label',
        value: 'apikeyFirearm_serialNumber',
      },
      {
        label: 'apiKeysForm.scope.firearms.exhibitNumber.label',
        value: 'apikeyFirearm_exhibitNumber',
      },
      {
        label: 'apiKeysForm.scope.firearms.externalRefNo.label',
        value: 'apikeyFirearm_externalReferenceNo',
      },
      {
        label: 'apiKeysForm.scope.firearms.manufacturer.label',
        value: 'apikeyFirearm_manufacturer',
      },
      { label: 'apiKeysForm.scope.firearms.make.label', value: 'apikeyFirearm_make' },
      { label: 'apiKeysForm.scope.firearms.model.label', value: 'apikeyFirearm_model' },
      { label: 'apiKeysForm.scope.firearms.type.label', value: 'apikeyFirearm_type' },
      { label: 'apiKeysForm.scope.firearms.calibre.label', value: 'apikeyFirearm_calibre' },
      { label: 'apiKeysForm.scope.firearms.action.label', value: 'apikeyFirearm_action' },
      {
        label: 'apiKeysForm.scope.firearms.dateOfManufacture.label',
        value: 'apikeyFirearm_dateOfManufacture',
      },
      {
        label: 'apiKeysForm.scope.firearms.countryOfManufacture.label',
        value: 'apikeyFirearm_countryOfManufacture',
      },
      {
        label: 'apiKeysForm.scope.firearms.countryOfImport.label',
        value: 'apikeyFirearm_countryOfImport',
      },
      { label: 'apiKeysForm.scope.firearms.owner.label', value: 'apikeyFirearm_owner' },
      { label: 'apiKeysForm.scope.firearms.keeper.label', value: 'apikeyFirearm_keeper' },
      { label: 'apiKeysForm.scope.firearms.state.label', value: 'apikeyFirearm_state' },
      { label: 'apiKeysForm.scope.firearms.legality.label', value: 'apikeyFirearm_legality' },
    ],
  },
  {
    label: 'apiKeysForm.scope.accessories.label',
    value: 'apikeyAncillaryAccessory',
    items: [
      {
        label: 'apiKeysForm.scope.accessories.serialNumber.label',
        value: 'apikeyAncillaryAccessory_serialNumber',
      },
      {
        label: 'apiKeysForm.scope.accessories.exhibitNumber.label',
        value: 'apikeyAncillaryAccessory_exhibitNumber',
      },
      {
        label: 'apiKeysForm.scope.accessories.externalRefNo.label',
        value: 'apikeyAncillaryAccessory_externalReferenceNo',
      },
      {
        label: 'apiKeysForm.scope.accessories.accessoryType.label',
        value: 'apikeyAncillaryAccessory_accessoryType',
      },
      {
        label: 'apiKeysForm.scope.accessories.accessoryManufacturer.label',
        value: 'apikeyAncillaryAccessory_accessoryManufacturer',
      },
      {
        label: 'apiKeysForm.scope.accessories.hostType.label',
        value: 'apikeyAncillaryAccessory_hostType',
      },
      {
        label: 'apiKeysForm.scope.accessories.owner.label',
        value: 'apikeyAncillaryAccessory_owner',
      },
      {
        label: 'apiKeysForm.scope.accessories.keeper.label',
        value: 'apikeyAncillaryAccessory_keeper',
      },
      {
        label: 'apiKeysForm.scope.accessories.state.label',
        value: 'apikeyAncillaryAccessory_state',
      },
      {
        label: 'apiKeysForm.scope.accessories.legality.label',
        value: 'apikeyAncillaryAccessory_legality',
      },
    ],
  },
  {
    label: 'apiKeysForm.scope.components.label',
    value: 'apikeyAncillaryComponent',
    items: [
      {
        label: 'apiKeysForm.scope.components.serialNumber.label',
        value: 'apikeyAncillaryComponent_serialNumber',
      },
      {
        label: 'apiKeysForm.scope.components.exhibitNumber.label',
        value: 'apikeyAncillaryComponent_exhibitNumber',
      },
      {
        label: 'apiKeysForm.scope.components.externalRefNo.label',
        value: 'apikeyAncillaryComponent_externalReferenceNo',
      },
      {
        label: 'apiKeysForm.scope.components.type.label',
        value: 'apikeyAncillaryComponent_componentType',
      },
      {
        label: 'apiKeysForm.scope.components.manufacturer.label',
        value: 'apikeyAncillaryComponent_componentManufacturer',
      },
      {
        label: 'apiKeysForm.scope.components.firearmType.label',
        value: 'apikeyAncillaryComponent_firearmType',
      },
      {
        label: 'apiKeysForm.scope.components.firearmMake.label',
        value: 'apikeyAncillaryComponent_firearmMake',
      },
      {
        label: 'apiKeysForm.scope.components.firearmModel.label',
        value: 'apikeyAncillaryComponent_firearmModel',
      },
      {
        label: 'apiKeysForm.scope.components.firearmManufacturer.label',
        value: 'apikeyAncillaryComponent_firearmManufacturer',
      },
      {
        label: 'apiKeysForm.scope.components.owner.label',
        value: 'apikeyAncillaryComponent_owner',
      },
      {
        label: 'apiKeysForm.scope.components.keeper.label',
        value: 'apikeyAncillaryComponent_keeper',
      },
      {
        label: 'apiKeysForm.scope.components.state.label',
        value: 'apikeyAncillaryComponent_state',
      },
      {
        label: 'apiKeysForm.scope.components.legality.label',
        value: 'apikeyAncillaryComponent_legality',
      },
    ],
  },
  {
    label: 'apiKeysForm.scope.ammunition.label',
    value: 'apikeyAncillaryAmmunition',
    items: [
      {
        label: 'apiKeysForm.scope.ammunition.lotNumber.label',
        value: 'apikeyAncillaryAmmunition_lotNumber',
      },
      {
        label: 'apiKeysForm.scope.ammunition.batchNumber.label',
        value: 'apikeyAncillaryAmmunition_batchNumber',
      },
      {
        label: 'apiKeysForm.scope.ammunition.exhibitNumber.label',
        value: 'apikeyAncillaryAmmunition_exhibitNumber',
      },
      {
        label: 'apiKeysForm.scope.ammunition.externalRefNo.label',
        value: 'apikeyAncillaryAmmunition_externalReferenceNo',
      },
      {
        label: 'apiKeysForm.scope.ammunition.ammunitionType.label',
        value: 'apikeyAncillaryAmmunition_ammunitionType',
      },
      {
        label: 'apiKeysForm.scope.ammunition.ammunitionManufacturer.label',
        value: 'apikeyAncillaryAmmunition_ammunitionManufacturer',
      },
      {
        label: 'apiKeysForm.scope.ammunition.functionalType.label',
        value: 'apikeyAncillaryAmmunition_functionalType',
      },
      {
        label: 'apiKeysForm.scope.ammunition.calibre.label',
        value: 'apikeyAncillaryAmmunition_calibre',
      },
      {
        label: 'apiKeysForm.scope.ammunition.headstampYear.label',
        value: 'apikeyAncillaryAmmunition_headstampYear',
      },
      {
        label: 'apiKeysForm.scope.ammunition.alphanumericMarkings.label',
        value: 'apikeyAncillaryAmmunition_alphanumericMarking',
      },
      {
        label: 'apiKeysForm.scope.ammunition.dateOfManufacture.label',
        value: 'apikeyAncillaryAmmunition_dateOfManufacture',
      },
      {
        label: 'apiKeysForm.scope.ammunition.owner.label',
        value: 'apikeyAncillaryAmmunition_owner',
      },
      {
        label: 'apiKeysForm.scope.ammunition.keeper.label',
        value: 'apikeyAncillaryAmmunition_keeper',
      },
      {
        label: 'apiKeysForm.scope.ammunition.state.label',
        value: 'apikeyAncillaryAmmunition_state',
      },
      {
        label: 'apiKeysForm.scope.ammunition.legality.label',
        value: 'apikeyAncillaryAmmunition_legality',
      },
    ],
  },
  {
    label: 'apiKeysForm.scope.transactions.label',
    value: 'apikeyTransaction',
    items: [
      {
        label: 'apiKeysForm.scope.transactions.transactionType.label',
        value: 'apikeyTransaction_transactionType',
      },
      {
        label: 'apiKeysForm.scope.transactions.transactionDate.label',
        value: 'apikeyTransaction_transactionDate',
      },
      {
        label: 'apiKeysForm.scope.transactions.transactionCoordinates.label',
        value: 'apikeyTransaction_transactionCoordinates',
      },
    ],
  },
];

export enum ApiKeyStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum ApiKeysStatusIds {
  ACTIVE = '11d0927c-0746-11ed-b939-0242ac120002',
  INACTIVE = '11d094fc-0746-11ed-b939-0242ac120002',
}
