import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledHeading = styled.div`
  width: 100%;
`;

export const StyledTypography = styled(Typography)`
  color: ${colors.primary500};
  text-align: center;
  margin-top: 6px;
`;
