import React, { useEffect } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import {
  ILegalEntityFormValues,
  LegalEntityForm,
} from 'pages/LegalEntitiesPage/LegalEntityForm';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import {
  IOrgLegalEntityFormValues,
  OrgLegalEntityForm,
} from 'pages/LegalEntitiesPage/OrgLegalEntityForm';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';
import { Alert } from 'components/atoms/Alert';
import { ActionTypes } from 'state/actions/alert';
import { formatDateTimeToIso } from 'utils/date';

const AddLegalEntityPage = () => {
  const query = useQuery();
  const type: string | null = query.get('type');
  const { t } = useTranslations();
  const { handleCreate, navigateToList, handleOrgLECreate } = useLegalEntities();
  const { clearAlert, samePageAlert } = useAlert();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const handleSubmit = async (data: ILegalEntityFormValues) => {
    const result = await handleCreate({
      ...data,
      dateOfBirth: formatDateTimeToIso(data.dateOfBirth),
    });
    if (result) {
      navigateToList();
    }
  };

  const handleOrgLESubmit = async (data: IOrgLegalEntityFormValues) => {
    const result = await handleOrgLECreate(data);
    if (result) {
      navigateToList();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          t('addLegalEntity.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addLegalEntity.header')}</StyledPageTitle>

      <FormWrapper>
        {type === ELegalEntityTypes.individual && (
          <LegalEntityForm onCancel={navigateToList} onSubmit={handleSubmit} />
        )}
        {type === ELegalEntityTypes.organisation && (
          <OrgLegalEntityForm onCancel={navigateToList} onSubmit={handleOrgLESubmit} />
        )}
      </FormWrapper>

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { AddLegalEntityPage };
