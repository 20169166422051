import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ISearchFilter } from 'models/searchFilter';
import { getSearchFilter } from 'requests/searchFilter';
import { PresetItem } from 'pages/MyFiltersPage/PresetItem';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ESearchFormLabels as ESearchFormLabelsILE } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { ESearchFormLabels as ESearchFormLabelsOLE } from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { useDictionary } from 'models/dictionaryContext';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { useTranslations } from 'hooks/useTranslations';
import { ESearchFormTransactionLabels } from 'pages/TransactionsPage/TransactionSearchForm';
import { ESearchFormFirearmLabels } from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { EAncillaryLabels } from 'pages/AncillariesPage/AncillariesReferenceTablePage/DetailAncillariesReference';

const getCorrectLabels = (type: ESearchFilterTypes) => {
  switch (type) {
    case ESearchFilterTypes.individual:
      return ESearchFormLabelsILE;
    case ESearchFilterTypes.organisation:
      return ESearchFormLabelsOLE;
    case ESearchFilterTypes.transaction:
      return ESearchFormTransactionLabels;
    case ESearchFilterTypes.firearm:
      return ESearchFormFirearmLabels;
    case ESearchFilterTypes.ancillaries:
      return EAncillaryLabels;
    default:
      return {};
  }
};

const DeleteFilterPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const [preset, setPreset] = useState<ISearchFilter>();
  const { t } = useTranslations();

  const { handleDelete } = useFilterPresets();
  const {
    gender,
    getGender,
    legalVisibility,
    getLegalVisibility,
    organizationType,
    getOrganizationType,
    country,
    getCountry,
    legality,
    getLegality,
    state,
    getState,
    firearmType,
    getFirearmType,
    firearmAction,
    getFirearmAction,
    transactionType,
    getTransactionType,
    artAncillaryOptions,
    getArtAncillaryOptions,
    artAccessoryType,
    getArtAccessoryType,
    artAccessoryManufacturerFlashEliminator,
    getArtAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    getArtAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    getArtAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    getArtAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    getArtAccessoryManufacturerSuppressor,
    artAccessoryCalibre,
    getArtAccessoryCalibre,
    artAccessoryComposition,
    getArtAccessoryComposition,
    artAccessoryHostType,
    getArtAccessoryHostType,
    artAccessoryLightColour,
    getArtAccessoryLightColour,
    artAccessoryMagnification,
    getArtAccessoryMagnification,
    artAccessoryReticle,
    getArtAccessoryReticle,
    artAccessoryUtility,
    getArtAccessoryUtility,
    artAccessoryProofHouse,
    getArtAccessoryProofHouse,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionFunctionalType,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    artComponentCalibre,
    artComponentCapacity,
    artComposition,
    artComponentManufacturer,
    artComponentType,
    artComponentProofHouse,
    getArtAmmunitionType,
    getArtAmmunitionManufacturer,
    getArtAmmunitionFactory,
    getArtAmmunitionCalibreGauge,
    getArtAmmunitionFunctionalType,
    getArtAmmunitionCartridgeComposition,
    getArtAmmunitionColourOfPrimerSealant,
    getArtAmmunitionProjectileShape,
    getArtAmmunitionShotSize,
    getArtAmmunitionMunitionType,
    getArtAmmunitionMunitionVelocity,
    getArtComponentCalibre,
    getArtComponentCapacity,
    getArtComposition,
    getArtComponentManufacturer,
    getArtComponentType,
    getArtComponentProofHouse,
  } = useDictionary();

  useEffect(() => {
    (async function init() {
      if (id) {
        if (gender === null) {
          await getGender();
        }
        if (legalVisibility === null) {
          await getLegalVisibility();
        }
        if (organizationType === null) {
          await getOrganizationType();
        }
        if (country === null) {
          await getCountry();
        }
        if (legality === null) {
          await getLegality();
        }
        if (state === null) {
          await getState();
        }
        if (firearmType === null) {
          await getFirearmType();
        }
        if (firearmAction === null) {
          await getFirearmAction();
        }
        if (transactionType === null) {
          await getTransactionType();
        }
        if (artAncillaryOptions === null) {
          await getArtAncillaryOptions();
        }
        if (artAccessoryType === null) {
          await getArtAccessoryType();
        }
        if (artAccessoryManufacturerFlashEliminator === null) {
          await getArtAccessoryManufacturerFlashEliminator();
        }
        if (artAccessoryManufacturerLaserLightModule === null) {
          await getArtAccessoryManufacturerLaserLightModule();
        }
        if (artAccessoryManufacturerMagazine === null) {
          await getArtAccessoryManufacturerMagazine();
        }
        if (artAccessoryManufacturerOpticalSight === null) {
          await getArtAccessoryManufacturerOpticalSight();
        }
        if (artAccessoryManufacturerSuppressor === null) {
          await getArtAccessoryManufacturerSuppressor();
        }
        if (artAccessoryCalibre === null) {
          await getArtAccessoryCalibre();
        }
        if (artAccessoryComposition === null) {
          await getArtAccessoryComposition();
        }
        if (artAccessoryHostType === null) {
          await getArtAccessoryHostType();
        }
        if (artAccessoryLightColour === null) {
          await getArtAccessoryLightColour();
        }
        if (artAccessoryMagnification === null) {
          await getArtAccessoryMagnification();
        }
        if (artAccessoryReticle === null) {
          await getArtAccessoryReticle();
        }
        if (artAccessoryUtility === null) {
          await getArtAccessoryUtility();
        }
        if (artAccessoryProofHouse === null) {
          await getArtAccessoryProofHouse();
        }
        if (artAmmunitionType === null) {
          await getArtAmmunitionType();
        }
        if (artAmmunitionManufacturer === null) {
          await getArtAmmunitionManufacturer();
        }
        if (artAmmunitionFactory === null) {
          await getArtAmmunitionFactory();
        }
        if (artAmmunitionCalibreGauge === null) {
          await getArtAmmunitionCalibreGauge();
        }
        if (artAmmunitionFunctionalType === null) {
          await getArtAmmunitionFunctionalType();
        }
        if (artAmmunitionCartridgeComposition === null) {
          await getArtAmmunitionCartridgeComposition();
        }
        if (artAmmunitionColourOfPrimerSealant === null) {
          await getArtAmmunitionColourOfPrimerSealant();
        }
        if (artAmmunitionProjectileShape === null) {
          await getArtAmmunitionProjectileShape();
        }
        if (artAmmunitionShotSize === null) {
          await getArtAmmunitionShotSize();
        }
        if (artAmmunitionMunitionType === null) {
          await getArtAmmunitionMunitionType();
        }
        if (artAmmunitionMunitionVelocity === null) {
          await getArtAmmunitionMunitionVelocity();
        }
        if (artComponentCalibre === null) {
          await getArtComponentCalibre();
        }
        if (artComponentCapacity === null) {
          await getArtComponentCapacity();
        }
        if (artComposition === null) {
          await getArtComposition();
        }
        if (artComponentManufacturer === null) {
          await getArtComponentManufacturer();
        }
        if (artComponentType === null) {
          await getArtComponentType();
        }
        if (artComponentProofHouse === null) {
          await getArtComponentProofHouse();
        }
        const response = await getSearchFilter(id);
        if (response) {
          setPreset(response);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onDelete = async () => {
    if (preset) {
      const result = await handleDelete(preset?.uniqueId);
      if (result) {
        navigate(ERouteLinks.MyFilters);
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('userSettings.header'), route: ERouteLinks.Account },
          { label: t('myFilters.header'), route: ERouteLinks.MyFilters },
          t('deleteMyFilter.deleteFilterPreset.header'),
        ]}
      />

      {preset && (
        <>
          <Box sx={{ mb: 1 }}>
            <StyledPageTitle variant="h4">
              {t('deleteMyFilter.deleteFilterPreset.header')}
            </StyledPageTitle>
          </Box>

          <PresetItem
            preset={preset}
            noRemoveButton={true}
            dictionaries={{
              gender,
              legalVisibility,
              organizationType,
              country,
              legality,
              state,
              transactionType,
              artAncillaryOptions,
              artAccessoryType,
              artAccessoryManufacturerFlashEliminator,
              artAccessoryManufacturerLaserLightModule,
              artAccessoryManufacturerMagazine,
              artAccessoryManufacturerOpticalSight,
              artAccessoryManufacturerSuppressor,
              artAccessoryCalibre,
              artAccessoryComposition,
              artAccessoryHostType,
              artAccessoryLightColour,
              artAccessoryMagnification,
              artAccessoryReticle,
              artAccessoryUtility,
              artAccessoryProofHouse,
              artAmmunitionType,
              artAmmunitionManufacturer,
              artAmmunitionFactory,
              artAmmunitionCalibreGauge,
              artAmmunitionFunctionalType,
              artAmmunitionCartridgeComposition,
              artAmmunitionColourOfPrimerSealant,
              artAmmunitionProjectileShape,
              artAmmunitionShotSize,
              artAmmunitionMunitionType,
              artAmmunitionMunitionVelocity,
              artComponentCalibre,
              artComponentCapacity,
              artComposition,
              artComponentManufacturer,
              artComponentType,
              artComponentProofHouse,
            }}
            labels={getCorrectLabels(preset.type)}
          />
          <Alert text={t('deleteMyFilter.alert.text')} variant={EAlertVariants.error} />
          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.yes.button')}
                  id="yes-button"
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                  onClick={() => onDelete()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.no.button')}
                  id="no-button"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.MyFilters)}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { DeleteFilterPage };
