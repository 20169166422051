import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { Box, Theme } from '@mui/material';
import { Chips } from 'components/atoms/Chips';

export const StyledSidebarWrapper = styled(Box)`
  ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('xs')} {
      max-width: 250px;
      };
    ${(theme as Theme).breakpoints.up('sm')} {
      max-width: 350px;
      };
    ${(theme as Theme).breakpoints.up('md')} {
      max-width: 450px;
      };
      `};
  height: calc(100vh - 300px);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1001;
  background: ${colors.white};
  border-left: 1px solid ${colors.gray300};
  padding: 20px;
  overflow-x: hidden;
`;

export const StyledCloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 32px;
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledChips = styled(Chips)`
  font-weight: bold;
  cursor: pointer;
`;

export const StyledWarningWrapper = styled(Box)`
  background-color: ${colors.warning25};
  width: 100%;
  height: 120px;
  margin: 10px 0 20px;
  color: ${colors.warning700};
  padding: 15px 20px 15px 10px;
  border: 1px solid ${colors.warning100};
  border-radius: 4px;

  & span {
    color: ${colors.warning700};
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
  }
`;

export const StyledWarningIcon = styled(Box)`
  background-color: ${colors.warning700};
  width: 32px;
  height: 32px;
  border-radius: 4px;
  padding: 4px;
  margin: 30px 10px;
`;
