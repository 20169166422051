import { useState } from 'react';
import {
  IAccountRequest,
  IAccountResponse,
  IAssignedIndividualLegalResponse,
  IAssignedOrganizationLegalResponse,
} from 'models/account';
import {
  changePassword,
  getAccount,
  getAssignedLegalsIdsToUser,
  getAssignedLegalsToUser,
  getAssignedSelfLegalsIdsToUser,
  getSelfAccount,
  updateSelfAccount,
  updateUser,
} from 'requests/account';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';

export const useAccount = () => {
  const { t } = useTranslations();
  const [account, setAccount] = useState<IAccountResponse>();
  const [assignedLegalsToUser, setAssignedLegalsToUser] = useState<
    (IAssignedIndividualLegalResponse | IAssignedOrganizationLegalResponse)[]
  >([]);
  const { setAlert } = useAlert();

  const getAccountData = async (id: string) => {
    const accountData = await getAccount(id);
    if (accountData) {
      setAccount(accountData);
    }
  };

  const getSelfAccountData = async () => {
    const accountData = await getSelfAccount();
    if (accountData) {
      setAccount(accountData);
    }
  };

  const updateAccount = async (accountData: IAccountRequest) => {
    const response = await updateUser(accountData);
    if (response) {
      await setAlert(ActionTypes.SET_USER_ALERT, {
        text: t('editUserData.success.text'),
      });
    }
    return response;
  };

  const updateSelf = async (accountData: IAccountRequest) => {
    const response = await updateSelfAccount(accountData);
    if (response) {
      await setAlert(ActionTypes.SET_USER_ALERT, {
        text: t('editUserData.success.text'),
      });
    }
    return response;
  };

  const changeAccountPassword = async (currentPassword: string, newPassword: string) => {
    const response = await changePassword(currentPassword, newPassword);
    if (response) {
      setAlert(ActionTypes.SET_USER_ALERT, {
        text: t('changePassword.confirmation.text'),
      });
    }
    return response;
  };

  const getAssignedLegalsToUserData = async (id: string) => {
    const response = await getAssignedLegalsIdsToUser(id);
    if (response.length) {
      const res = await getAssignedLegalsToUser(response);
      return setAssignedLegalsToUser([...res.individuals, ...res.organizations]);
    }
    return setAssignedLegalsToUser([]);
  };

  const getAssignedSelfLegalsToUserData = async () => {
    const response = await getAssignedSelfLegalsIdsToUser();
    if (response.length) {
      const res = await getAssignedLegalsToUser(response);
      return setAssignedLegalsToUser([...res.individuals, ...res.organizations]);
    }
    return setAssignedLegalsToUser([]);
  };

  return {
    account,
    getAccountData,
    updateSelf,
    updateAccount,
    getSelfAccountData,
    changeAccountPassword,
    getAssignedLegalsToUserData,
    getAssignedSelfLegalsToUserData,
    assignedLegalsToUser,
  };
};
