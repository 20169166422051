import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { EChipsVariants } from 'components/atoms/Chips';

interface ILegalEntityGroupAncillaryTableCard {
  data: ITableDataSource;
  deleteAction?: (p: string | any) => void;
  renderColor: (data: ITableDataSource) => {
    border: string;
    stateField: EChipsVariants;
    background: string;
  };
}

const LegalEntityAncillaryGroupAncillaryTableCard = ({
  data,
  deleteAction,
  renderColor,
}: ILegalEntityGroupAncillaryTableCard) => {
  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        border: `1px solid ${renderColor(data).border}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: renderColor(data).background,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.fireCycleId.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.fireCycleId)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.serialNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.serialNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.lotNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.lotNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.batchNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.batchNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.ancillary.column')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(data.ancillaryTypeDictionaryValue)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.ancillaryType.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ancillaryType)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.manufacturer.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturer)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.list.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.calibre)}</Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('groupDetails.ancillaryListSection.action.column')}:{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('addGroup.ancillaryList.section.ancillaryTable.remove.link')}
            variant={EButtonVariants.outlined}
            onClick={() => deleteAction && deleteAction(data?.id)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { LegalEntityAncillaryGroupAncillaryTableCard };
