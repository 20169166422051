import styled from '@emotion/styled';
import { Box, Tab, Tabs, Theme } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledTab = styled(Tab)`
  min-height: 24px;

  & h6 {
    text-transform: none;
    margin-right: 10px;
  }
`;

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${colors.gray900};
    height: 4px;
  }
`;

export const NoResultWrapper = styled(Box)`
  padding: 20px;
  border: 1px solid ${colors.gray200};
  background-color: ${colors.gray100};

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.up('lg')} {
      margin-top: -50px;
      };
      `};
`;
