import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/dictionary';
import { DictionaryContext } from 'models/dictionaryContext';
import { DictionaryActionTypes } from 'state/actions/dictionary';
import {
  getDictionary,
  getSpecificDictionary,
  getTransactionInitialTypesDictionary,
  getTransactionMiddleTypesDictionary,
} from 'requests/dictionary';
import {
  EDictionaryTypes,
  ESpecificDictionaryTypes,
  ETransactionDictionaryItemTypes,
} from 'models/dictionary';

export const DictionaryProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const getGender = async () => {
    dispatch({ type: DictionaryActionTypes.SET_GENDER, payload: [] });
    const genderData = await getDictionary(EDictionaryTypes.Gender);
    dispatch({ type: DictionaryActionTypes.SET_GENDER, payload: genderData });
  };

  const getOrganizationType = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ORGANIZATION_TYPE,
      payload: [],
    });
    const organizationTypes = await getDictionary(EDictionaryTypes.OrganizationType);
    dispatch({
      type: DictionaryActionTypes.SET_ORGANIZATION_TYPE,
      payload: organizationTypes,
    });
  };

  const getLegalVisibility = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_LEGAL_VISIBILITY,
      payload: [],
    });
    const legalVisibilityData = await getDictionary(EDictionaryTypes.LegalVisibility);
    dispatch({
      type: DictionaryActionTypes.SET_LEGAL_VISIBILITY,
      payload: legalVisibilityData,
    });
  };

  const getLegalEntityPrefix = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_LEGAL_ENTITY_PREFIX,
      payload: [],
    });
    const legalEntityPrefixData = await getDictionary(EDictionaryTypes.legalEntityPrefix);
    dispatch({
      type: DictionaryActionTypes.SET_LEGAL_ENTITY_PREFIX,
      payload: legalEntityPrefixData,
    });
  };

  const getCivilianPrefix = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_CIVILIAN_PREFIX,
      payload: [],
    });
    const prefixData = await getDictionary(EDictionaryTypes.civilianPrefix);
    dispatch({
      type: DictionaryActionTypes.SET_CIVILIAN_PREFIX,
      payload: prefixData,
    });
  };

  const getMilitaryPrefix = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_MILITARY_PREFIX,
      payload: [],
    });
    const prefixData = await getDictionary(EDictionaryTypes.militaryPrefix);
    dispatch({
      type: DictionaryActionTypes.SET_MILITARY_PREFIX,
      payload: prefixData,
    });
  };

  const getPolicePrefix = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_POLICE_PREFIX,
      payload: [],
    });
    const prefixData = await getDictionary(EDictionaryTypes.policePrefix);
    dispatch({
      type: DictionaryActionTypes.SET_POLICE_PREFIX,
      payload: prefixData,
    });
  };

  const getMake = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_MAKE,
      payload: [],
    });
    const makeData = await getSpecificDictionary(ESpecificDictionaryTypes.Make);
    dispatch({
      type: DictionaryActionTypes.SET_MAKE,
      payload: makeData,
    });
  };

  const getModel = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_MODEL,
      payload: [],
    });
    const modelData = await getSpecificDictionary(ESpecificDictionaryTypes.Model);
    dispatch({
      type: DictionaryActionTypes.SET_MODEL,
      payload: modelData,
    });
  };

  const getCaliber = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_CALIBER,
      payload: [],
    });
    const caliberData = await getSpecificDictionary(ESpecificDictionaryTypes.Caliber);
    dispatch({
      type: DictionaryActionTypes.SET_CALIBER,
      payload: caliberData,
    });
  };

  const getBarrelType = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_BARREL_TYPE,
      payload: [],
    });
    const barrelTypeData = await getDictionary(EDictionaryTypes.BarrelType);
    dispatch({
      type: DictionaryActionTypes.SET_BARREL_TYPE,
      payload: barrelTypeData,
    });
  };

  const getState = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_STATE,
      payload: [],
    });
    const stateData = await getDictionary(EDictionaryTypes.State);
    dispatch({
      type: DictionaryActionTypes.SET_STATE,
      payload: stateData,
    });
  };

  const getRegion = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_REGION,
      payload: [],
    });
    const regionData = await getDictionary(EDictionaryTypes.Region);
    dispatch({
      type: DictionaryActionTypes.SET_REGION,
      payload: regionData,
    });
  };

  const getCountry = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_COUNTRY,
      payload: [],
    });
    const countryData = await getDictionary(EDictionaryTypes.Country);
    dispatch({
      type: DictionaryActionTypes.SET_COUNTRY,
      payload: countryData,
    });
  };
  const getCountryAll = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_COUNTRY,
      payload: [],
    });
    const countryData = await getDictionary(EDictionaryTypes.CountryAll);
    dispatch({
      type: DictionaryActionTypes.SET_COUNTRY,
      payload: countryData,
    });
  };

  const getPhoneCode = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_PHONE_CODE,
      payload: [],
    });
    const phoneCodeData = await getDictionary(EDictionaryTypes.PhoneCode);
    dispatch({
      type: DictionaryActionTypes.SET_PHONE_CODE,
      payload: phoneCodeData,
    });
  };

  const getAddressType = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ADDRESS_TYPE,
      payload: [],
    });
    const addressTypeData = await getDictionary(EDictionaryTypes.AddressType);
    dispatch({
      type: DictionaryActionTypes.SET_ADDRESS_TYPE,
      payload: addressTypeData,
    });
  };

  const getLegality = async () => {
    dispatch({ type: DictionaryActionTypes.SET_LEGALITY, payload: [] });
    const legalityData = await getDictionary(EDictionaryTypes.Legality);
    dispatch({ type: DictionaryActionTypes.SET_LEGALITY, payload: legalityData });
  };

  const getManufacturer = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_MANUFACTURER,
      payload: [],
    });
    const manufacturerData = await getSpecificDictionary(
      ESpecificDictionaryTypes.Manufacturer,
    );
    dispatch({
      type: DictionaryActionTypes.SET_MANUFACTURER,
      payload: manufacturerData,
    });
  };

  const getLevel = async () => {
    dispatch({ type: DictionaryActionTypes.SET_LEVEL, payload: [] });
    const levelData = await getSpecificDictionary(ESpecificDictionaryTypes.Level);
    dispatch({ type: DictionaryActionTypes.SET_LEVEL, payload: levelData });
  };

  const getFirearmAction = async () => {
    dispatch({ type: DictionaryActionTypes.SET_FIREARM_ACTION, payload: [] });
    const firearmActionData = await getSpecificDictionary(
      ESpecificDictionaryTypes.FirearmAction,
    );
    dispatch({ type: DictionaryActionTypes.SET_FIREARM_ACTION, payload: firearmActionData });
  };

  const getFirearmType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_FIREARM_TYPE, payload: [] });
    const firearmTypeData = await getSpecificDictionary(ESpecificDictionaryTypes.FirearmType);
    dispatch({ type: DictionaryActionTypes.SET_FIREARM_TYPE, payload: firearmTypeData });
  };

  const getTransactionType = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_TRANSACTION_TYPE,
      payload: [],
    });
    const transactionTypeData = await getDictionary(EDictionaryTypes.TransactionType);
    dispatch({
      type: DictionaryActionTypes.SET_TRANSACTION_TYPE,
      payload: transactionTypeData,
    });
  };

  const getActivity = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ACTIVITY, payload: [] });
    const activityData = await getDictionary(EDictionaryTypes.Activity);
    dispatch({
      type: DictionaryActionTypes.SET_ACTIVITY,
      payload: activityData,
    });
  };

  const getLegalClassification = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_LEGAL_CLASSIFICATION,
      payload: [],
    });
    const legalClassificationData = await getSpecificDictionary(
      ESpecificDictionaryTypes.LegalClassification,
    );
    dispatch({
      type: DictionaryActionTypes.SET_LEGAL_CLASSIFICATION,
      payload: legalClassificationData,
    });
  };

  const getManufacturerCountry = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_COUNTRY_OF_MANUFACTURER,
      payload: [],
    });
    const manufacturerCountryData = await getSpecificDictionary(
      ESpecificDictionaryTypes.ManufacturerCountry,
    );
    dispatch({
      type: DictionaryActionTypes.SET_COUNTRY_OF_MANUFACTURER,
      payload: manufacturerCountryData,
    });
  };

  const getTransactionInitialTypes = async (itemType: ETransactionDictionaryItemTypes) => {
    dispatch({
      type: DictionaryActionTypes.SET_TRANSACTION_INITIAL_TYPES,
      payload: [],
    });
    const transactionInitialTypeData = await getTransactionInitialTypesDictionary(itemType);
    dispatch({
      type: DictionaryActionTypes.SET_TRANSACTION_INITIAL_TYPES,
      payload: transactionInitialTypeData,
    });
  };

  const getTransactionMiddleTypes = async (itemType: ETransactionDictionaryItemTypes) => {
    dispatch({
      type: DictionaryActionTypes.SET_TRANSACTION_MIDDLE_TYPES,
      payload: [],
    });
    const transactionMiddleTypeData = await getTransactionMiddleTypesDictionary(itemType);
    dispatch({
      type: DictionaryActionTypes.SET_TRANSACTION_MIDDLE_TYPES,
      payload: transactionMiddleTypeData,
    });
  };

  const getArtAncillaryOptions = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ANCILLARY_OPTIONS, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAncillaryOptions);
    dispatch({ type: DictionaryActionTypes.SET_ART_ANCILLARY_OPTIONS, payload: data });
  };
  const getArtAccessoryCalibre = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_CALIBRE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryCalibre);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_CALIBRE, payload: data });
  };
  const getArtAccessoryComposition = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_COMPOSITION, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtaccessoryComposition);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_COMPOSITION, payload: data });
  };
  const getArtAccessoryHostType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_HOST_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryHostType);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_HOST_TYPE, payload: data });
  };
  const getArtAccessoryLightColour = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_LIGHT_COLOUR, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryLightColour);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_LIGHT_COLOUR, payload: data });
  };
  const getArtAccessoryMagnification = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_MAGNIFICATION, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryMagnification);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_MAGNIFICATION, payload: data });
  };
  const getArtAccessoryManufacturerFlashEliminator = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_FLASH_ELIMINATOR,
      payload: [],
    });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryManufacturerFlashEliminator);
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_FLASH_ELIMINATOR,
      payload: data,
    });
  };
  const getArtAccessoryManufacturerLaserLightModule = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_LASER_LIGHT_MODULE,
      payload: [],
    });
    const data = await getDictionary(
      EDictionaryTypes.ArtAccessoryManufacturerLaserLightModule,
    );
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_LASER_LIGHT_MODULE,
      payload: data,
    });
  };
  const getArtAccessoryManufacturerMagazine = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_MAGAZINE,
      payload: [],
    });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryManufacturerMagazine);
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_MAGAZINE,
      payload: data,
    });
  };
  const getArtAccessoryManufacturerOpticalSight = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_OPTICAL_SIGHT,
      payload: [],
    });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryManufacturerOpticalSight);
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_OPTICAL_SIGHT,
      payload: data,
    });
  };
  const getArtAccessoryManufacturerSuppressor = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_SUPPRESSOR,
      payload: [],
    });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryManufacturerSuppressor);
    dispatch({
      type: DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_SUPPRESSOR,
      payload: data,
    });
  };
  const getArtAccessoryReticle = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_RETICLE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryReticle);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_RETICLE, payload: data });
  };
  const getArtAccessoryType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryType);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_TYPE, payload: data });
  };
  const getArtAccessoryUtility = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_UTILITY, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryUtility);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_UTILITY, payload: data });
  };
  const getArtAccessoryProofHouse = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_PROOF_HOUSE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAccessoryProofHouse);
    dispatch({ type: DictionaryActionTypes.SET_ART_ACCESSORY_PROOF_HOUSE, payload: data });
  };
  const getArtAmmunitionType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionType);
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_TYPE, payload: data });
  };
  const getArtAmmunitionManufacturer = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_MANUFACTURER, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionManufacturer);
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_MANUFACTURER, payload: data });
  };
  const getArtAmmunitionFactory = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_FACTORY, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionFactory);
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_FACTORY, payload: data });
  };
  const getArtAmmunitionCalibreGauge = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_CALIBRE_GAUGE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionCalibreGauge);
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_CALIBRE_GAUGE, payload: data });
  };
  const getArtAmmunitionCartridgeComposition = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_CARTRIDGE_COMPOSITION,
      payload: [],
    });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionCartridgeComposition);
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_CARTRIDGE_COMPOSITION,
      payload: data,
    });
  };
  const getArtAmmunitionColourOfPrimerSealant = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_COLOUR_OF_PRIMER_SEALANT,
      payload: [],
    });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionColourOfPrimerSealant);
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_COLOUR_OF_PRIMER_SEALANT,
      payload: data,
    });
  };
  const getArtAmmunitionProjectileShape = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_PROJECTILE_SHAPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionProjectileShape);
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_PROJECTILE_SHAPE,
      payload: data,
    });
  };
  const getArtAmmunitionFunctionalType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_FUNCTIONAL_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionFunctionalType);
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_FUNCTIONAL_TYPE,
      payload: data,
    });
  };
  const getArtAmmunitionShotSize = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_SHOT_SIZE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionShotSize);
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_SHOT_SIZE, payload: data });
  };
  const getArtAmmunitionMunitionType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_MUNITION_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionMunitionType);
    dispatch({ type: DictionaryActionTypes.SET_ART_AMMUNITION_MUNITION_TYPE, payload: data });
  };
  const getArtAmmunitionMunitionVelocity = async () => {
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_MUNITION_VELOCITY,
      payload: [],
    });
    const data = await getDictionary(EDictionaryTypes.ArtAmmunitionMunitionVelocity);
    dispatch({
      type: DictionaryActionTypes.SET_ART_AMMUNITION_MUNITION_VELOCITY,
      payload: data,
    });
  };

  const getArtComponentType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtComponentType);
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_TYPE, payload: data });
  };
  const getArtComponentManufacturer = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_MANUFACTURER, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtComponentManufacturer);
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_MANUFACTURER, payload: data });
  };
  const getArtComponentCalibre = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_CALIBRE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtComponentCalibre);
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_CALIBRE, payload: data });
  };
  const getArtComponentCapacity = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_CAPACITY, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtComponentCapacity);
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_CAPACITY, payload: data });
  };
  const getArtComponentProofHouse = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_PROOF_HOUSE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtComponentProofHouse);
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPONENT_PROOF_HOUSE, payload: data });
  };
  const getArtComposition = async () => {
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPOSITION, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ArtComposition);
    dispatch({ type: DictionaryActionTypes.SET_ART_COMPOSITION, payload: data });
  };
  const getAuditLogModule = async () => {
    dispatch({ type: DictionaryActionTypes.SET_AUDIT_LOG_MODULE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.AuditLogModule);
    dispatch({ type: DictionaryActionTypes.SET_AUDIT_LOG_MODULE, payload: data });
  };

  const getAuditLogEventType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_AUDIT_LOG_EVENT_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.AuditLogEventType);
    dispatch({ type: DictionaryActionTypes.SET_AUDIT_LOG_EVENT_TYPE, payload: data });
  };

  const getAuditLogEventName = async () => {
    dispatch({ type: DictionaryActionTypes.SET_AUDIT_LOG_EVENT_NAME, payload: [] });
    const data = await getDictionary(EDictionaryTypes.AuditLogEventName);
    dispatch({ type: DictionaryActionTypes.SET_AUDIT_LOG_EVENT_NAME, payload: data });
  };

  const getApiKeyType = async () => {
    dispatch({ type: DictionaryActionTypes.SET_API_KEY_TYPE, payload: [] });
    const data = await getDictionary(EDictionaryTypes.ApiKeyType);
    dispatch({ type: DictionaryActionTypes.SET_API_KEY_TYPE, payload: data });
  };

  const value = useMemo(
    () => ({
      ...state,
      getGender,
      getOrganizationType,
      getLegalVisibility,
      getLegalEntityPrefix,
      getCivilianPrefix,
      getMilitaryPrefix,
      getPolicePrefix,
      getMake,
      getModel,
      getCaliber,
      getBarrelType,
      getState,
      getRegion,
      getCountry,
      getPhoneCode,
      getAddressType,
      getLegality,
      getManufacturer,
      getLevel,
      getFirearmAction,
      getFirearmType,
      getTransactionType,
      getLegalClassification,
      getManufacturerCountry,
      getTransactionInitialTypes,
      getTransactionMiddleTypes,
      getActivity,
      getCountryAll,
      getArtAncillaryOptions,
      getArtAccessoryCalibre,
      getArtAccessoryComposition,
      getArtAccessoryHostType,
      getArtAccessoryLightColour,
      getArtAccessoryMagnification,
      getArtAccessoryManufacturerFlashEliminator,
      getArtAccessoryManufacturerLaserLightModule,
      getArtAccessoryManufacturerMagazine,
      getArtAccessoryManufacturerOpticalSight,
      getArtAccessoryManufacturerSuppressor,
      getArtAccessoryReticle,
      getArtAccessoryType,
      getArtAccessoryUtility,
      getArtAccessoryProofHouse,
      getArtAmmunitionType,
      getArtAmmunitionManufacturer,
      getArtAmmunitionFactory,
      getArtAmmunitionCalibreGauge,
      getArtAmmunitionCartridgeComposition,
      getArtAmmunitionColourOfPrimerSealant,
      getArtAmmunitionProjectileShape,
      getArtAmmunitionFunctionalType,
      getArtAmmunitionShotSize,
      getArtAmmunitionMunitionType,
      getArtAmmunitionMunitionVelocity,
      getArtComponentType,
      getArtComponentManufacturer,
      getArtComponentCalibre,
      getArtComponentCapacity,
      getArtComponentProofHouse,
      getArtComposition,
      getAuditLogModule,
      getAuditLogEventType,
      getAuditLogEventName,
      getApiKeyType,
    }),
    [state],
  );

  return <DictionaryContext.Provider value={value}>{children}</DictionaryContext.Provider>;
};
