import React, { useEffect, useState } from 'react';
import {
  StyledChips,
  StyledCloseIcon,
  StyledSidebarWrapper,
  StyledWarningIcon,
  StyledWarningWrapper,
} from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmDataSidebar/style';
import { Box, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { EIconTypes } from 'constants/Icons';
import { colors } from 'theme/colors';
import Icon from 'components/atoms/Icon/Icon';
import SearchFilterStore from 'state/SearchFilterStore';
import { EChipsVariants } from 'components/atoms/Chips';
import Grid from '@mui/material/Grid';
import { EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import { IAccessory } from 'models/ancillaries';
import { LEGAL_ID } from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmsLocationMap';
import AccessoryDataListItem from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/AncillaryDataSidebar/AccessoryDataListItem';

export enum ELegalityFilter {
  legal = 'd90f05bf-6baf-4f86-9ca3-9501af6901ea',
  illegal = 'ae5de668-67ac-4650-af26-23dd0cb97c89',
}

const AccessoryDataSidebar = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state, getState } = useDictionary();
  const { selectedLanguage } = useLang();
  const [accessories, setAccessories] = useState<IAccessory[]>([]);
  const [filteredAccessories, setFilteredAccessories] = useState<IAccessory[]>([]);
  const [chipsData, setChipsData] = useState<{ illegal: number; legal: number }>({
    illegal: 0,
    legal: 0,
  });
  const [filter, setFilter] = useState<ELegalityFilter>();

  useEffect(() => {
    (async function initDictionaries() {
      getState();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);
  const prepareFirearms = (accessoriesData: IAccessory[]) => {
    let illegalCount = 0;
    let legalCount = 0;

    let filteredItems: IAccessory[] = [];

    if (filter) {
      filteredItems = accessoriesData.filter(
        (accessory) => accessory.legalityUniqueId === filter,
      );
    } else {
      filteredItems = accessoriesData;
    }

    filteredItems.map((accessory) => {
      if (accessory.legalityUniqueId !== LEGAL_ID) {
        illegalCount++;
      } else {
        legalCount++;
      }
      return false;
    });

    setFilteredAccessories(filteredItems);
    setChipsData({ illegal: illegalCount, legal: legalCount });
  };

  document.addEventListener('setAccessoriesEvent', (data: any) => {
    const accessoriesData: IAccessory[] = data.detail.data as IAccessory[];
    setAccessories(accessoriesData);
    prepareFirearms(accessoriesData);
    setFilter(undefined);
  });

  useEffect(() => {
    prepareFirearms(accessories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (!filteredAccessories.length) {
    return null;
  }

  return (
    <StyledSidebarWrapper id="accessories-data-sidebar">
      <StyledCloseIcon
        onClick={() => {
          SearchFilterStore.setAccessories([]);
        }}
      >
        <Icon type={EIconTypes.close} color={colors.black} />
      </StyledCloseIcon>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        {t('ancillaries.ancillariesLocation.accessoryLegality')}
      </Typography>
      <Box>
        {!!chipsData.legal && (
          <StyledChips
            text={`${t('ancillaries.ancillariesLocation.legal.label')}: ${chipsData.legal}`}
            variant={EChipsVariants.success}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.legal ? undefined : ELegalityFilter.legal,
              );
            }}
          />
        )}
        {!!chipsData.illegal && (
          <StyledChips
            text={`${t('ancillaries.ancillariesLocation.illegal.label')}: ${
              chipsData.illegal
            }`}
            variant={EChipsVariants.error}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.illegal ? undefined : ELegalityFilter.illegal,
              );
            }}
          />
        )}
      </Box>

      <Grid
        container
        sx={{
          margin: '15px 0',
          paddingTop: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.gray200}`,
          },
        }}
      >
        {filteredAccessories.length > 50 && (
          <StyledWarningWrapper>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <StyledWarningIcon>
                  <Icon type={EIconTypes.info} color={colors.white} />
                </StyledWarningIcon>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="caption">
                  {t('ancillaries.ancillariesLocation.accessory.caption')}
                </Typography>
              </Grid>
            </Grid>
          </StyledWarningWrapper>
        )}

        <Grid item xs={12}>
          <Button
            label={t('ancillaries.ancillariesLocation.showFirearms.label')}
            variant={EButtonVariants.contained}
            onClick={() => {
              navigate(`${ERouteLinks.Ancillaries}?expand=accessories&fromMap=true`);
            }}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>

      {filteredAccessories.length <= 50 &&
        filteredAccessories.map((accessory) => (
          <AccessoryDataListItem data={accessory} key={accessory.uniqueId} state={state} />
        ))}
    </StyledSidebarWrapper>
  );
};

export default AccessoryDataSidebar;
