import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { Grid } from '@mui/material';

export const StyledNotificationListItem = styled(Grid)`
  &:hover {
    border: 1px solid ${colors.primary200};
  }
`;

export const notificationListItemCheckboxStyle = {
  backgroundColorChecked: colors.primary700,
  backgroundColorUnChecked: colors.white,
  borderColor: colors.gray300,
};
