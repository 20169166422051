import { useState } from 'react';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { IPaginator } from 'models/paginator';
import { EPerPages, ISortOptions } from 'models/table';
import { EAlertVariants } from 'components/atoms/Alert';
import { IUser } from 'models/user';
import { useDictionary } from 'models/dictionaryContext';
import {
  createUser,
  deleteUser,
  generatePassword,
  getUsers,
  setUserActive,
  setUserBlocked,
  updateUser,
} from 'requests/user';
import { ISearchUserFormValues, IUserFormValues } from 'models/form';
import { useTranslations } from 'hooks/useTranslations';

export const useUsers = () => {
  const { t } = useTranslations();
  const [users, setUsers] = useState<IUser[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const [assignedLEs, setAssignedLEs] = useState<any[]>([]);

  const { setAlert } = useAlert();
  const { getPhoneCode, getLegalEntityPrefix } = useDictionary();

  const getUsersData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    query: Partial<ISearchUserFormValues> = {},
    sort?: ISortOptions | null,
  ) => {
    const usersData = await getUsers(currentPage, perPage, query, sort);

    if (usersData) {
      const { content, ...paginatorData } = usersData;
      setUsers(content);
      setPaginator(paginatorData);
    } else {
      setUsers([]);
      setPaginator(null);
    }
  };

  const loadDictionaries = async () => {
    getPhoneCode();
    getLegalEntityPrefix();
  };

  const activateUser = async (id: string, active: boolean) => {
    const result = await setUserActive(id, active);
    if (result) {
      setUsers((prevState) =>
        prevState.map((item) => {
          if (item.uniqueId === id) {
            item.status = active;
          }
          return item;
        }),
      );
    }

    return result;
  };

  const unbanUser = async (id: string) => {
    const result = await setUserBlocked(id);
    if (result) {
      setUsers((prevState) =>
        prevState.map((item) => {
          if (item.uniqueId === id) {
            item.blocked = false;
          }
          return item;
        }),
      );
    }
  };

  const handleCreate = async (data: IUserFormValues) => {
    data.password = `Fake!${Math.floor(Math.random() * 10000)}!Password`;

    if (data.formRoleIds) {
      data.roleIds = Object.keys(data.formRoleIds).filter(
        (key) => data?.formRoleIds?.[key] === true,
      );
    }
    if (data.formPrefixGroupIds) {
      data.prefixGroupIds = Object.keys(data.formPrefixGroupIds).filter(
        (key) => data?.formPrefixGroupIds?.[key] === true,
      );
    }
    const {
      formRoleIds,
      formPrefixGroupIds,
      phoneNumberCodeDictionaryUniqueId,
      phoneNumberValue,
      ...rest
    } = data;
    const phoneNumber = {
      codeDictionaryUniqueId: phoneNumberCodeDictionaryUniqueId,
      value: phoneNumberValue,
    };
    const response = await createUser({ ...rest, phoneNumber });
    if (response.status === 200) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, { text: t('addUser.success.text') });
    } else if (response.status === 400) {
      await setAlert(ActionTypes.SET_USER_ALERT, {
        text: t('addUser.emailIsTaken.message'),
        variant: EAlertVariants.error,
      });
    }

    return response;
  };

  const handleEdit = async (id: string, data: IUserFormValues) => {
    if (data.formRoleIds) {
      data.roleIds = Object.keys(data.formRoleIds).filter(
        (key) => data?.formRoleIds?.[key] === true,
      );
    }
    if (data.formPrefixGroupIds) {
      data.prefixGroupIds = Object.keys(data.formPrefixGroupIds).filter(
        (key) => data?.formPrefixGroupIds?.[key] === true,
      );
    }
    const {
      formRoleIds,
      formPrefixGroupIds,
      phoneNumberCodeDictionaryUniqueId,
      phoneNumberValue,
      password,
      ...rest
    } = data;
    const phoneNumber = {
      codeDictionaryUniqueId: phoneNumberCodeDictionaryUniqueId,
      value: phoneNumberValue,
    };
    const response = await updateUser(id, { ...rest, phoneNumber });
    if (response) {
      await setAlert(ActionTypes.SET_USER_ALERT, { text: t('editUser.success.text') });
    }
    return response;
  };

  const handleRegeneratePassword = async (email: string) => {
    const response = await generatePassword(email);
    return response;
  };

  const handleDelete = async (id: string) => {
    const response = await deleteUser(id);
    if (response) {
      await setAlert(ActionTypes.SET_USER_ALERT, { text: t('users.delete.success.message') });
    }
    return response;
  };

  const getAssignedLEData = async () => {
    // const assignedLEData = await getAssignedLEs();
    const assignedLEData: any = [];
    if (assignedLEData) {
      setAssignedLEs(assignedLEData);
    } else {
      setAssignedLEs([]);
    }
  };

  return {
    loadDictionaries,
    alert,
    users,
    paginator,
    handleCreate,
    handleEdit,
    handleDelete,
    handleRegeneratePassword,
    getUsersData,
    activateUser,
    unbanUser,
    getAssignedLEData,
    assignedLEs,
  };
};
