import React from 'react';
import { ITableColumns, ITableDataSource } from 'models/table';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { IRole } from 'models/role';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';

export const mapRolesToDataSource = (roles: IRole[]): ITableDataSource[] =>
  roles.map((role) => ({
    id: role.uniqueId,
    description: role.description,
    name: role.name,
    createdDate: role.createdDate,
  }));

export const ADMINISTRATOR_ROLE_ID: string = '2cde44fc-69c3-11ee-8c99-0242ac120002';

interface IRolesTableColumns {
  handleEdit: (arg: string) => void;
  handleDelete: (arg: string) => void;
  longDateFormat: string;
}

export const rolesTableColumns = ({
  handleEdit,
  handleDelete,
  longDateFormat,
}: IRolesTableColumns): ITableColumns[] => [
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f510',
    key: 'name',
    title: 'roles.rolesTable.roleName.column',
    align: 'center',
    render: (_data, props) => <Chips text={props.name} variant={EChipsVariants.primary} />,
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f511',
    key: 'description',
    title: 'roles.rolesTable.description.column',
  },
  {
    id: 'f0504ca5-6b32-482d-b369-304a6f32f512',
    key: 'createdDate',
    title: 'roles.rolesTable.created.column',
    dateFormat: longDateFormat,
  },
  {
    id: 'fe7a7688-1583-4233-8319-dd5c8973cc44',
    key: '',
    title: 'actions.general.label',
    isSortable: false,
    width: '110px',
    align: 'center',
    isCenterTableHead: true,
    render: (_data, props) => (
      <>
        <Link to={ERouteLinks.ViewRole.replace(':id', props.id)}>
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.view}
          />
        </Link>
        <Button
          variant={EButtonVariants.outlined}
          tableButton={true}
          icon={EIconTypes.edit}
          onClick={() => handleEdit(props.id)}
        />
        {props.id !== ADMINISTRATOR_ROLE_ID && (
          <Button
            variant={EButtonVariants.outlined}
            tableButton={true}
            icon={EIconTypes.delete}
            onClick={() => handleDelete(props.id)}
          />
        )}
      </>
    ),
  },
];
