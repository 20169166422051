import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

export const useDocumentsGeneratingSetupPageSchema = () => {
  const { t } = useTranslations();

  return yup.object({
    eTraceEnabled: yup.string().nullable().required(t('general.field.validation.required')),
    iArmsEnabled: yup.string().nullable().required(t('general.field.validation.required')),
  });
};
