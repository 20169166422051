import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { ERouteLinks } from 'models/route';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EButtonSizes, EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { Chips } from 'components/atoms/Chips';
import { getFieldsByComponentType } from 'pages/AncillariesPage/helpers';
import { EArtAncillaryTypeName, EArtComponentType } from 'constants/ArtAccessoryType';
import { EComponentLabels } from 'pages/AncillariesPage/ComponentsPage/DetailComponents';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import {
  ComponentInformationContainer,
  ComponentInformationTitle,
} from 'pages/AncillariesPage/ComponentsPage/DetailComponents/styles';
import Link from '@mui/material/Link';
import Button from 'components/atoms/Button';
import { renderDataColorByValues } from 'components/molecules/Table/helpers';
import { mapAncillaryTypeToTranslationKey } from 'utils/mapAncillaryTypeToTranslation';

interface IComponentDetails {
  id: string;
}
const ComponentDetails = ({ id }: IComponentDetails) => {
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const navigate = useNavigate();
  const { isDesktop } = useThemeBreakpoint();
  const { loadDictionaries, componentDetail, getComponentDetail } = useAncillaries();
  const [accessFields, setAccessFields] = useState<string[]>([]);

  useEffect(() => {
    if (componentDetail) {
      if (componentDetail.typeUniqueId) {
        const fields = getFieldsByComponentType(
          componentDetail.typeUniqueId as EArtComponentType,
        );
        setAccessFields(fields);
      } else {
        setAccessFields([]);
      }
    }
  }, [componentDetail]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getComponentDetail(id);
      }
    })();
  }, [id]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAncillaryOptions]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const showField = (fieldName: string) => accessFields.includes(fieldName);

  return (
    <div>
      {componentDetail && (
        <Grid container item gap={3}>
          <DetailsTemplate
            title={componentDetail?.type}
            pageType={EDetailsPageTypes.view}
            redirectToView={() => navigate(`${ERouteLinks.ViewComponents.replace(':id', id)}`)}
            actionButtonProps={{
              ...{ label: t('legalEntityDetails.ancillary.accordion.details.link') },
              ...(isDesktop ? {} : { size: EButtonSizes.small }),
              ...(isDesktop ? {} : { sizePX: EButtonSizesPX.px24 }),
              ...{ variant: EButtonVariants.outlined },
            }}
          >
            <ComponentInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <ComponentInformationTitle variant="subtitle1">
                  {t(EComponentLabels.componentGeneralInformation)}
                </ComponentInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.ancillaryType)}
                </Typography>
                <Typography variant="body2">
                  {t(mapAncillaryTypeToTranslationKey(componentDetail?.ancillaryType))}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.componentType)}
                </Typography>
                <Typography variant="body2">{componentDetail?.type}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: '4px', textTransform: 'uppercase' }}
                >
                  {t(EComponentLabels.artId)}
                </Typography>
                <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
                  {componentDetail?.artId}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Link
                  href={`${ERouteLinks.ViewAncillaries.replace(
                    ':id',
                    componentDetail.artUniqueId,
                  ).replace(':type', EArtAncillaryTypeName.COMPONENTS.toLowerCase())}`}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    fullWidth
                    label={t('componentDetails.showArtInformation.button')}
                    variant={EButtonVariants.contained}
                    size={EButtonSizes.small}
                  />
                </Link>
              </Grid>

              {showField('manufacturer') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.manufacturer)}
                  </Typography>
                  <Typography variant="body2">
                    {componentDetail?.manufacturer ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('productName') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.productName)}
                  </Typography>
                  <Typography variant="body2">
                    {componentDetail?.productName ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('calibre') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.calibre)}
                  </Typography>
                  <Typography variant="body2">{componentDetail?.calibre ?? '-'}</Typography>
                </Grid>
              )}
              {showField('calibre') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.calibre)}
                  </Typography>
                  <Typography variant="body2">{componentDetail?.calibre ?? '-'}</Typography>
                </Grid>
              )}
              {showField('composition') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.composition)}
                  </Typography>
                  <Typography variant="body2">
                    {componentDetail?.composition ?? '-'}
                  </Typography>
                </Grid>
              )}
              {showField('proofHouse') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.proofHouse)}
                  </Typography>
                  <Typography variant="body2">{componentDetail?.proofHouse ?? '-'}</Typography>
                </Grid>
              )}
              {showField('capacity') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.capacity)}
                  </Typography>
                  <Typography variant="body2">{componentDetail?.capacity ?? '-'}</Typography>
                </Grid>
              )}
              {showField('length') && (
                <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t(EComponentLabels.length)}
                  </Typography>
                  <Typography variant="body2">{componentDetail?.length ?? '-'}</Typography>
                </Grid>
              )}
            </ComponentInformationContainer>
            <ComponentInformationContainer item xs={12} lg={12} container>
              <Grid item xs={12} lg={12} container direction="column">
                <ComponentInformationTitle variant="subtitle1">
                  {t(EComponentLabels.componentDetailedInformation)}
                </ComponentInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firecycleID)}
                </Typography>
                <Typography variant="body2">{componentDetail?.fireCycleId ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.serialNumber)}
                </Typography>
                <Typography variant="body2">{componentDetail?.serialNumber ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.componentState)}
                </Typography>
                <Typography variant="body2">
                  <Chips
                    text={componentDetail?.state ?? '-'}
                    variant={
                      renderDataColorByValues(
                        componentDetail.activityUniqueId,
                        componentDetail.legalityUniqueId,
                      ).stateField
                    }
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.exhibitNumber)}
                </Typography>
                <Typography variant="body2">
                  {componentDetail?.exhibitNumber ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.currentOwner)}
                </Typography>
                <Typography variant="body2">{componentDetail?.ownerName ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.currentKeeper)}
                </Typography>
                <Typography variant="body2">{componentDetail?.keeperName ?? '-'}</Typography>
              </Grid>
            </ComponentInformationContainer>
            <ComponentInformationContainer item xs={12} lg={12} container borderHidden>
              <Grid item xs={12} lg={12} container direction="column">
                <ComponentInformationTitle variant="subtitle1">
                  {t(EComponentLabels.firearmInformation)}
                </ComponentInformationTitle>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmManufacturer)}
                </Typography>
                <Typography variant="body2">
                  {componentDetail?.frtManufacturer ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmReferenceNo)}
                </Typography>
                <Typography variant="body2">
                  {componentDetail?.frtReferenceNo ?? '-'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} container direction="column">
                <Link
                  href={`${ERouteLinks.FirearmsReferenceTableDetails}?frtId=${componentDetail.frtId}`}
                  underline="none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    fullWidth
                    label={t('componentDetails.showFrtInformation.button')}
                    variant={EButtonVariants.contained}
                    size={EButtonSizes.small}
                  />
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmFirecycleType)}
                </Typography>
                <Typography variant="body2">{componentDetail?.frtType ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmMake)}
                </Typography>
                <Typography variant="body2">{componentDetail?.frtMake ?? '-'}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t(EComponentLabels.firearmModel)}
                </Typography>
                <Typography variant="body2">{componentDetail?.frtModel ?? '-'}</Typography>
              </Grid>
            </ComponentInformationContainer>
          </DetailsTemplate>
        </Grid>
      )}
    </div>
  );
};

export default ComponentDetails;
