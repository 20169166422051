import { IParsedDictionary } from 'helpers/dictionary';
import { ISearchFormValues } from 'models/transaction';
import { ISearchFilterTransactionRequest } from 'models/searchFilter';
import { clearObject } from 'helpers/objects';
import { formatDateToRequest } from 'utils/date';
import { parse } from 'date-fns';

export const mapValuesToTransactionPresetRequest = (
  data: ISearchFormValues,
): ISearchFilterTransactionRequest => {
  const values: ISearchFilterTransactionRequest = {
    ownerUniqueId: data.ownerUniqueId,
    keeperUniqueId: data.keeperUniqueId,

    transactionTypeUniqueId: data.transactionTypeUniqueId,

    transactionTimeFrom: data.transactionTimeFrom
      ? formatDateToRequest(
          parse(data.transactionTimeFrom, 'dd/MM/yyyy HH:mm', new Date()),
          true,
        )
      : '',

    transactionTimeTo: data.transactionTimeTo
      ? formatDateToRequest(
          parse(data.transactionTimeTo, 'dd/MM/yyyy HH:mm', new Date()),
          true,
        )
      : '',
    createTimeFrom: data.createTimeFrom
      ? formatDateToRequest(parse(data.createTimeFrom, 'dd/MM/yyyy HH:mm', new Date()), true)
      : '',
    createTimeTo: data.createTimeTo
      ? formatDateToRequest(parse(data.createTimeTo, 'dd/MM/yyyy HH:mm', new Date()), true)
      : '',
    expirationTimeFrom: data.expirationTimeFrom
      ? formatDateToRequest(
          parse(data.expirationTimeFrom, 'dd/MM/yyyy HH:mm', new Date()),
          true,
        )
      : '',
    expirationTimeTo: data.expirationTimeTo
      ? formatDateToRequest(parse(data.expirationTimeTo, 'dd/MM/yyyy HH:mm', new Date()), true)
      : '',
    legalityUniqueId: data.legalityUniqueId,
    asset: data.asset?.toString() || '',
  };
  return clearObject(values);
};

export const mapValuesWithDictionariesHelper = (
  values: { key: string; value: string }[],
  dictionaries: { [key: string]: IParsedDictionary | any },
) =>
  values.map((item) => {
    if (dictionaries?.[item.key] && dictionaries[item.key]?.[item.value]) {
      item.value = dictionaries[item.key]?.[item.value];
    }
    return item;
  });
