import { deleteSearchFilter, getSearchFilters } from 'requests/searchFilter';
import { ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { useEffect, useState } from 'react';
import { ISearchFilter } from 'models/searchFilter';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { useTranslations } from 'hooks/useTranslations';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { getDateFromISO } from 'utils/date';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';

export const useFilterPresets = () => {
  const [presets, setPresets] = useState<ISearchFilter[]>();
  const { setAlert } = useAlert();
  const { t } = useTranslations();
  const { getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  const preparePresetsList = (filters: ISearchFilter[], label?: string) => {
    const labelText = label
      ? t(label)
      : t('legalEntities.individual.accordion.selectMyFilters.label');
    const list = [{ label: labelText, value: '' }];

    if (filters.length) {
      filters.map((filter) => list.push({ label: filter.name, value: filter.uniqueId }));
    }
    return list;
  };

  const getFilterPresets = async (
    type?: ESearchFilterTypes,
    ancillaryType?: EArtAncillaryType,
  ) => {
    const filters = await getSearchFilters(type);
    if (filters) {
      if (type === ESearchFilterTypes.ancillaries && ancillaryType) {
        const filteredPresets = filters.filter((filter) =>
          filter.values.some((it) => it.value === ancillaryType),
        );
        setPresets(filteredPresets);
      } else {
        setPresets(filters);
      }
    }
  };

  const getPresetValues = (id: string) => {
    const dateKeys = [
      'birthdayTo',
      'birthdayFrom',
      'transactionTimeFrom',
      'transactionTimeTo',
      'createTimeFrom',
      'createTimeTo',
      'expirationTimeFrom',
      'expirationTimeTo',
      'manufactureDateFrom',
      'manufactureDateTo',
      'expiryDateFrom',
      'expiryDateTo',
    ];
    const preset = presets?.find((item) => item.uniqueId === id);
    if (preset) {
      const values: { [key: string]: any } = {};

      preset.values.map((item) => {
        if (dateKeys.includes(item.key) && item.value) {
          values[item.key] = new Date(getDateFromISO(item.value as string));
        } else {
          values[item.key] = item.value;
        }
        return true;
      });
      return values;
    }
    return false;
  };

  const handleDelete = async (id: string) => {
    const response = await deleteSearchFilter(id);
    if (response) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('searchFilter.delete.success.text'),
      });
    }
    return response;
  };

  return { getFilterPresets, presets, getPresetValues, preparePresetsList, handleDelete };
};
