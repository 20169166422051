import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';

interface IDictionaryTableCard {
  data: ITableDataSource;
}

const DictionaryTableCard = ({ data }: IDictionaryTableCard) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        backgroundColor: `${colors.gray100}`,
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        width: '100%',
        maxWidth: 'calc(100vw - 48px)',
        mt: 1,
      }}
    >
      <Grid item xs={12} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('dictionaries.dictionariesTable.dictionaryName.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          {data.name.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('dictionaries.dictionariesTable.description.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
          {data.name.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('dictionaries.dictionariesTable.translations.column')}
        </Typography>
        <Chips
          text={
            data.translations
              ? t('general.yes.label').toUpperCase()
              : t('general.no.label').toUpperCase()
          }
          variant={data.translations ? EChipsVariants.success100 : EChipsVariants.error}
        />
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: `1px solid ${colors.gray200}`,
          m: 1,
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('dictionaries.dictionariesTable.actions.column')}:{' '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Button
            label={t('dictionaries.dictionariesTable.view.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.ViewDictionary.replace(':type', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { DictionaryTableCard };
