import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/alert';
import { AlertContext } from 'models/alertContext';

export const AlertProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const setAlert = (type: string, payload: any) => {
    dispatch({
      type,
      payload,
    });
  };

  const clearAlert = (type: string) => {
    dispatch({ type, payload: null });
  };

  const value = useMemo(() => ({ ...state, setAlert, clearAlert }), [state]);

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};
