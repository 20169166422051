import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import FormLabel from 'components/atoms/FormLabel';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementProps } from 'models/form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StyledDatePicker, StyledTextField } from 'components/molecules/FormDatePicker/styles';
import { getDateFromISOOrNull, isValidDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { DateView } from '@mui/x-date-pickers';

export enum EFormInputTypes {
  text = 'text',
  password = 'password',
}

interface IFormInput extends IFormElementProps {
  placeholder?: string;
  minDate?: Date;
  openTo?: DateView;
  arrayName?: string;
  arrayIndex?: number;
  fieldName?: string;
  dateFormat?: string;
  disabled?: boolean;
  helperText?: string;
}

export const FormDatePicker = ({
  name,
  label,
  control,
  errors,
  minDate,
  openTo = 'day',
  arrayName,
  arrayIndex,
  fieldName,
  placeholder,
  dateFormat,
  disabled = false,
  helperText,
}: IFormInput) => {
  const { shortDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  const error =
    arrayName && arrayIndex !== undefined && fieldName
      ? errors?.[arrayName]?.[arrayIndex]?.[fieldName]?.message
      : errors[name]?.message;

  const finalDateFormat = dateFormat || shortDateFormat;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledFormElementWrapper disabled={disabled}>
        <FormLabel name={name} label={label} disabled={disabled} />
        <Controller
          name={name}
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <StyledDatePicker
              openTo={openTo}
              onAccept={(newDate) => {
                if (newDate === null) {
                  field.onChange(null);
                }
              }}
              disabled={disabled}
              format={finalDateFormat}
              value={getDateFromISOOrNull(field.value)}
              minDate={minDate}
              onChange={(e) => {
                if (isValidDate(e) || e === null) {
                  field.onChange(e);
                }
              }}
              slots={{
                textField: StyledTextField,
              }}
              slotProps={{
                field: {
                  clearable: true,
                },
                textField: {
                  ref: field.ref,
                  inputProps: {
                    inputMode: 'numeric',
                    placeholder: placeholder || finalDateFormat.toUpperCase(),
                    id: name,
                    disabled: !!disabled,
                  },
                  error: !!error,
                  helperText,
                },
              }}
            />
          )}
        />
        <FormErrorLabel label={error} />
      </StyledFormElementWrapper>
    </LocalizationProvider>
  );
};
