import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes } from 'models/dictionary';

interface IDictionaryTableCard {
  data: ITableDataSource;
  type: EDictionaryTypes | undefined;
}

const DictionaryViewTableCard = ({ data, type }: IDictionaryTableCard) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  return (
    <Grid
      container
      sx={{
        backgroundColor: `${colors.gray100}`,
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('dictionaries.dictionaryViewTable.dictionaryValueName.column')}
        </Typography>
        <Typography variant="body2">{data.name}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('dictionaries.dictionaryViewTable.dictionaryStatus.column')}
        </Typography>
        <Chips
          text={data.status ? 'Active' : 'Inactive'}
          variant={data.status ? EChipsVariants.success100 : EChipsVariants.error}
        />
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('dictionaries.dictionaryViewTable.actions.column')}:{' '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={9}>
          <Button
            label={t('dictionaries.dictionaryViewTable.edit.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(
                `${ERouteLinks.EditDictionaryValue.replace(':id', data.id as string).replace(
                  ':type',
                  type ?? 'null',
                )}`,
              )
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { DictionaryViewTableCard };
