import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .email('Please, enter your email.')
    .max(64)
    .required('Please, enter your email.'),
  password: yup.string().required('Please, enter your password.'),
});
