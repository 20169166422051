import styled from '@emotion/styled';
import { Pagination, Theme } from '@mui/material';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';

export const StyledPagination = styled(Pagination)`
  margin-top: 6px;

  & .MuiPaginationItem-page {
    color: ${colors.primary900};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    border: 1px solid ${colors.primary50};
    border-radius: 4px;
  }

  & .Mui-selected {
    background-color: ${colors.primary500} !important;
    color: ${colors.white};
  }
`;

export const PaginationWrapper = styled(Grid)`
  margin: 15px 0 10px;
  align-items: center;

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('md')} {
      justify-content: space-between;
      };
      `};
`;

export const PaginationPerPage = styled(Grid)`
  width: 180px;
  max-width: 180px;

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('md')} {
      & {
          width: auto;
          max-width: inherit;
          padding-left:0 !important;
          
        };
      };
      `};
`;
export const PaginationSummary = styled(Grid)`
  width: 100px;
  max-width: 100px;
  white-space: nowrap;
  padding-right: 10px;

  & .MuiTypography-root {
    margin-top: 5px;
  }

  ${({ theme }) => `
      ${(theme as Theme).breakpoints.down('md')} {
      & {width: auto; max-width: inherit;};
      };
      ${(theme as Theme).breakpoints.down('sm')} {
      & {order:1; padding-left: 0 !important;};
      };
  `};
`;
export const PaginatorPerPageSelect = styled(FormSelect)`
  flex-direction: row;
  align-items: center;
  gap: 12px;
  > div {
    height: 23px;
    margin-top: 1px;
  }
  margin-top: 1px;

  & .MuiSelect-select {
    font-size: 12px;
    padding: 5px 32px 5px 12px !important;
  }
  & .MuiOutlinedInput-root {
    height: 23px;
  }
  & label {
    margin: 0;
  }
`;

export const StyledCurrentPageMobile = styled(FormSelect)`
  margin-top: 1px;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  > div {
    height: 23px;
    margin-top: 1px;
  }
  & .MuiSelect-select {
    font-size: 12px;
    padding: 5px 32px 5px 12px !important;
  }
  & .MuiOutlinedInput-root {
    height: 23px;
  }
  & label {
    margin: 0;
  }
`;
