import React, { useEffect, useState } from 'react';
import { useAccount } from 'pages/AccountPage/hooks';
import { useAuth } from 'models/authContext';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useDictionary } from 'models/dictionaryContext';
import { IParsedDictionary, parseDictionary } from 'helpers/dictionary';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledHeading } from 'pages/AccountPage/styles';
import { fetchTrustedDevices } from 'requests/trustedDevice';
import { IAddTrustedDeviceResponse } from 'models/trustedDevice';
import { Table } from 'components/molecules/Table';
import {
  mapTrustedDevicesToDataSource,
  trustedDevicesTableColumns,
} from 'pages/AccountPage/helpers';
import { CardList } from 'components/molecules/CardList';
import { TrustedDeviceTableCard } from 'pages/AccountPage/TrustedDeviceTableCard';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert } from 'components/atoms/Alert';
import { useTranslations } from 'hooks/useTranslations';
import { useLicenceContext } from 'models/licenceContext';

const AccountPage = () => {
  const { t } = useTranslations();
  const [trustedDevices, setTrustedDevices] = useState<IAddTrustedDeviceResponse[]>([]);
  const { account, getSelfAccountData } = useAccount();
  const { user } = useAuth();
  const { isTablet, isDesktop, isMobile } = useThemeBreakpoint();
  const { phoneCode, getPhoneCode } = useDictionary();
  const { isOnlineLicence } = useLicenceContext();
  const navigate = useNavigate();
  const { userAlert, clearAlert, trustedDeviceAlert } = useAlert();

  useEffect(() => {
    (async function getAccountDataInit() {
      if (user?.userId) {
        await getSelfAccountData();
      }
      if (!phoneCode) {
        await getPhoneCode();
      }

      if (isOnlineLicence()) {
        await fetchTrustedDevices((devices) => setTrustedDevices(devices));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_USER_ALERT);
      clearAlert(ActionTypes.CLEAR_TRUSTED_DEVICE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const parsedPhoneCodes: IParsedDictionary = parseDictionary(phoneCode, 'name');
  const phoneNumber =
    account &&
    account.phoneNumber &&
    account.phoneNumber.codeDictionaryUniqueId &&
    parsedPhoneCodes?.[account.phoneNumber.codeDictionaryUniqueId]
      ? `${parsedPhoneCodes?.[account.phoneNumber.codeDictionaryUniqueId]} ${
          account.phoneNumber.value
        }`
      : '-';

  const renderActionButtons = (isTemplateFooter: boolean) => (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: isTemplateFooter ? 'flex-end' : 'space-between',
        alignItems: 'center',
        borderTop: {
          xs: `1px solid ${colors.primary25}`,
          lg: 'none',
        },
        pt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
        mt: {
          xs: isTemplateFooter ? 0 : 2,
          lg: 0,
        },
      }}
    >
      {isTablet && !isTemplateFooter && (
        <Grid item xs={12} sm={6} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {`${t('actions.general.label')}: `}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        sm={12}
        spacing={2}
        sx={{
          display: {
            xs: isTemplateFooter ? 'none' : 'flex',
            lg: isTemplateFooter ? 'flex' : 'none',
          },
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Grid item xs={isTemplateFooter ? 'auto' : 12} sm={isTemplateFooter ? 'auto' : 12}>
          <Button
            label={t('userSettings.edit.button')}
            variant={EButtonVariants.outlined}
            onClick={() => navigate(ERouteLinks.AccountEdit)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={isTemplateFooter ? 'auto' : 12} sm={isTemplateFooter ? 'auto' : 6}>
          <Button
            label={t('userSettings.myFilters.button')}
            variant={EButtonVariants.outlined}
            onClick={() => navigate(ERouteLinks.MyFilters)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={isTemplateFooter ? 'auto' : 12} sm={isTemplateFooter ? 'auto' : 6}>
          <Button
            label={t('userSettings.changePassword.button')}
            variant={EButtonVariants.outlined}
            onClick={() => navigate(ERouteLinks.AccountChangePassword)}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Breadcrumbs items={[t('userSettings.header')]} />
      <Grid
        container
        sx={{
          border: `1px solid ${colors.primary25}`,
          borderRadius: '4px',
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: colors.primary25,
          }}
          px={2}
          py={1}
        >
          <Grid item xs={isMobile ? 12 : 4}>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
              {t('userSettings.userData.section')}
            </Typography>
          </Grid>
          <Grid item xs={isMobile ? 12 : 8}>
            {renderActionButtons(true)}
          </Grid>
        </Grid>
        <Grid item container p={2} rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={12} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userSettings.username.label')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ wordBreak: 'break-all', overflowWrap: 'anywhere' }}
            >
              {account?.email}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={4} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userSettings.firstName.label')}
            </Typography>
            <Typography variant="body2">{account?.firstName}</Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={4} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userSettings.lastName.label')}
            </Typography>
            <Typography variant="body2">{account?.lastName}</Typography>
          </Grid>
          <Grid item xs={6} md={3} lg={4} xl={3}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('userSettings.phoneNumber.label')}
            </Typography>
            <Typography variant="body2">{phoneNumber}</Typography>
          </Grid>
          {renderActionButtons(false)}
        </Grid>
      </Grid>

      {userAlert && (
        <Box mt={2}>
          <Alert text={userAlert.text} variant={userAlert.variant} />
        </Box>
      )}

      {isOnlineLicence() && (
        <>
          <StyledHeading>
            <Typography variant="body1">{t('userSettings.trustedDevices.section')}</Typography>
          </StyledHeading>
          {trustedDeviceAlert && (
            <Box mb={2}>
              <Alert text={trustedDeviceAlert.text} variant={trustedDeviceAlert.variant} />
            </Box>
          )}
          {isDesktop ? (
            <Table
              columns={trustedDevicesTableColumns({
                handleDelete: (id: string) =>
                  navigate(ERouteLinks.AccountRemoveDevice.replace(':id', id)),
              })}
              dataSource={mapTrustedDevicesToDataSource(trustedDevices)}
            />
          ) : (
            <CardList
              items={mapTrustedDevicesToDataSource(trustedDevices)}
              render={(data, index) => <TrustedDeviceTableCard data={data} key={index} />}
            />
          )}
        </>
      )}
    </>
  );
};

export { AccountPage };
