import React, { useCallback, useEffect, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { useTranslations } from 'hooks/useTranslations';
import { DropzoneWrapper } from './styles';

interface IUploadDropzoneField {
  onUpload: (...args: any) => void;
  shouldClearFiles?: boolean;
  setFilesCleared?: (arg0: boolean) => void;
  acceptFiles?: { [key: string]: string[] };
  description?: JSX.Element | null;
}

const UploadDropzoneField = ({
  onUpload,
  shouldClearFiles = false,
  setFilesCleared,
  acceptFiles = {
    'text/plain': ['.txt'],
  },
  description,
}: IUploadDropzoneField) => {
  const [files, setFiles] = useState<any>([]);
  const [error, setError] = useState('');
  const { t } = useTranslations();

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setFiles([...acceptedFiles]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files],
  );

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    maxFiles: 1,
    maxSize: 3 * 1024 * 1024,
    accept: acceptFiles,
    onDrop,
  });

  const defaultDescription = () => (
    <p>
      {t('dragAndDrop.description.dragAndDrop.text')} <br />
      {t('dragAndDrop.description.orClickToBrowse.text')}
    </p>
  );

  useEffect(() => {
    if (fileRejections?.length) {
      if (fileRejections?.[0]?.errors?.[0].code === ErrorCode.FileTooLarge) {
        setError(t('dropzone.tooLarge.error'));
      } else if (fileRejections?.[0]?.errors?.[0].code === ErrorCode.FileInvalidType) {
        setError(t('dropzone.invalidType.error'));
      }
    } else {
      setError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections]);

  useEffect(() => {
    if (shouldClearFiles) {
      setFiles([]);
      if (setFilesCleared) {
        setTimeout(() => {
          setFilesCleared(false);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldClearFiles]);

  useEffect(() => {
    if (files?.length) {
      onUpload(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <section className="container">
      <DropzoneWrapper {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />

        {description || defaultDescription()}
      </DropzoneWrapper>

      {error && <FormErrorLabel label={error} />}
    </section>
  );
};

export default UploadDropzoneField;
