import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { IFRTCalibreFormValues } from 'models/frt';
import { initialCalibreFormValues } from 'models/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDictionary } from 'models/dictionaryContext';
import { FormSelect } from 'components/molecules/FormSelect';
import {
  mapDictionaryNamesToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import useCalibreFormSchema from 'pages/FRT/FRTCalibreForm/schemaValidation';
import { Typography } from '@mui/material';

interface IFRTCalibreForm {
  onCancel?: () => void;
  onSubmit: (arg: IFRTCalibreFormValues) => void;
  initialFormValues?: IFRTCalibreFormValues;
}

const FRTCalibreForm = ({ onCancel, onSubmit, initialFormValues }: IFRTCalibreForm) => {
  const { t } = useTranslations();
  const { schema } = useCalibreFormSchema();
  const {
    caliber,
    getCaliber,
    barrelType,
    getBarrelType,
    level,
    getLevel,
    legalClassification,
    getLegalClassification,
  } = useDictionary();
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IFRTCalibreFormValues>({
    defaultValues: initialFormValues || initialCalibreFormValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (level === null) {
      getLevel();
    }
    if (legalClassification === null) {
      getLegalClassification();
    }
    if (caliber === null) {
      getCaliber();
    }
    if (barrelType === null) {
      getBarrelType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset(initialFormValues || initialCalibreFormValues);
  }, [reset, initialFormValues]);

  const onSubmitForm = async (data: IFRTCalibreFormValues) => {
    setIsSubmitLoading(true);
    await onSubmit(data);
    setIsSubmitLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <FormSelect
            options={mapSpecificDictionaryToOptionProp(caliber)}
            setValue={setValue}
            name="caliber"
            label="Calibre*"
            control={control}
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormInput
            name="barrelLength"
            label="Barrel Length*"
            control={control}
            errors={errors}
            setValue={setValue}
            InputProps={{
              inputProps: {
                maxLength: 9,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <FormInput
            name="shots"
            label="Shots*"
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
          <FormSelect
            options={mapSpecificDictionaryToOptionProp(level)}
            name="level"
            setValue={setValue}
            label="Level*"
            control={control}
            errors={errors}
          />
        </Grid>

        <Grid container item xs={12} columnSpacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <FormSelect
              options={mapSpecificDictionaryToOptionProp(legalClassification)}
              name="legalClassification"
              setValue={setValue}
              label="Legal Classification*"
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <FormSelect
              options={mapDictionaryNamesToOptionProp(barrelType)}
              name="barrelType"
              setValue={setValue}
              label="Barrel Type*"
              control={control}
              errors={errors}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              type="submit"
              fullWidth
              label={t('general.save.button')}
              id="save-button"
              variant={EButtonVariants.contained}
              isLoading={isSubmitLoading}
            />
          </Grid>

          {onCancel && (
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={onCancel}
              />
            </Grid>
          )}
        </Grid>

        <Grid item mt={2}>
          <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export { FRTCalibreForm };
