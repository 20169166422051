import styled from '@emotion/styled';
import { Box, css, Select } from '@mui/material';
import { colors } from 'theme/colors';
import Button from 'components/atoms/Button';

export const StyledSelect = styled(Select)`
  width: 100%;
  border: 1px solid ${colors.primary500} !important;
  height: 40px;
  padding: 8px 13px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const StyledDashboardSortButtonsWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 4px;
  padding: 0px;
  border-radius: 4px;
  background-color: ${colors.primary15};
`;

interface IStyledDashboardSortButton {
  selected: boolean;
}

export const StyledDashboardSortButton = styled(Button)<IStyledDashboardSortButton>`
  flex: 1;
  height: 40px;
  font-weight: 500;
  ${({ selected }: IStyledDashboardSortButton) => css`
    ${`background-color: ${selected ? colors.primary500 : colors.primary15}`};
    ${`color: ${selected ? colors.white : colors.primary500}`};
    &:hover {
      background: ${selected ? colors.primary300 : colors.primary15};
      box-shadow: none;
    }
  `};
`;
