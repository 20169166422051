import styled from '@emotion/styled';

export const StyledFirearmLegalityDropdownWrapper = styled.div`
  position: absolute;
  z-index: 1000;
  width: 200px;
  height: 40px;
  top: 10px;
  left: 10px;
`;
