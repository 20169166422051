import React, { useEffect, useState } from 'react';
import { IAuditLogItemResponse } from 'models/auditLog';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { Box, Typography } from '@mui/material';
import { getLegalEntity, getOrgLegalEntity } from 'requests/legalEntities';
import {
  ILegalEntityCustomField,
  ILegalEntityData,
} from 'components/organisms/LegalEntityDetails';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { ERouteLinks } from 'models/route';
import Grid from '@mui/material/Grid';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { useNavigate } from 'react-router-dom';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useLang } from 'models/langContext';
import { EDictionaryPrefixTypesUniqueId } from 'helpers/dictionary';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { ECustomFieldType } from 'models/customField';
import { formatDate } from 'utils/date';

export enum ELEObjectType {
  LE_INDIVIDUAL = 'LE_INDIVIDUAL',
  LE_ORGANIZATION = 'LE_ORGANIZATION',
}

interface ILegalEntitiesEventDetails {
  auditLog: IAuditLogItemResponse;
}

const LegalEntitiesEventDetails = ({ auditLog }: ILegalEntitiesEventDetails) => {
  const [legalEntity, setLegalEntity] = useState<ILegalEntityData | null>();
  const [orgLegalEntity, setOrgLegalEntity] = useState<IOrgLegalEntityData | null>();
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { longDateFormat, shortDateFormat, getDateFormat } = useGlobalProperty();

  const {
    loadDictionaries,
    gender,
    legalEntityPrefix,
    civilianPrefix,
    militaryPrefix,
    policePrefix,
    legalVisibility,
    organizationType,
  } = useLegalEntities();

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dictionariesList =
    auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
      ? [
          EDictionaryTypes.Gender,
          EDictionaryTypes.LegalVisibility,
          EDictionaryTypes.legalEntityPrefix,
          EDictionaryTypes.civilianPrefix,
          EDictionaryTypes.militaryPrefix,
          EDictionaryTypes.policePrefix,
        ]
      : [EDictionaryTypes.LegalVisibility, EDictionaryTypes.OrganizationType];

  useEffect(() => {
    (async function init() {
      await loadDictionaries(dictionariesList);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL) {
      (async () => {
        if (auditLog?.objectUniqueId) {
          const response = await getLegalEntity(auditLog.objectUniqueId);
          if (response) {
            setLegalEntity(response);
          }
        }
      })();
    } else if (auditLog?.objectType === ELEObjectType.LE_ORGANIZATION) {
      (async () => {
        if (auditLog?.objectUniqueId) {
          const response = await getOrgLegalEntity(auditLog.objectUniqueId);
          if (response) {
            setOrgLegalEntity(response);
          }
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditLog]);

  const renderItemDetail = (dictionary: IDictionary[] | null, dictionaryItemId: string) => {
    const legal: ILegalEntityData = legalEntity as ILegalEntityData;
    const orgLegal: IOrgLegalEntityData = orgLegalEntity as IOrgLegalEntityData;
    if (legal) {
      return dictionary?.find(
        (item: IDictionary) =>
          item?.uniqueId === legal[dictionaryItemId as keyof ILegalEntityData],
      )?.name;
    }
    if (orgLegal) {
      return dictionary?.find(
        (item: IDictionary) =>
          item?.uniqueId === orgLegal[dictionaryItemId as keyof IOrgLegalEntityData],
      )?.name;
    }
    return '';
  };

  const renderPrefixDetail = (prefixGroupUniqueId: string | undefined) => {
    switch (prefixGroupUniqueId) {
      case EDictionaryPrefixTypesUniqueId.civilianPrefix:
        return renderItemDetail(civilianPrefix, 'prefixValueUniqueId');
      case EDictionaryPrefixTypesUniqueId.militaryPrefix:
        return renderItemDetail(militaryPrefix, 'prefixValueUniqueId');
      case EDictionaryPrefixTypesUniqueId.policePrefix:
        return renderItemDetail(policePrefix, 'prefixValueUniqueId');
      default:
        return '';
    }
  };

  const renderCustomFieldData = (customField: ILegalEntityCustomField) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value;
  };

  return (
    <>
      <AuditLogEventName>
        <Typography variant="h5">Legal Entity Details</Typography>
      </AuditLogEventName>

      {legalEntity && (
        <DetailsTemplate
          title={`${legalEntity.firstName} ${legalEntity.lastName}`}
          pageType={EDetailsPageTypes.auditLogView}
          redirectToView={() =>
            navigate(
              `${ERouteLinks.ViewRegisteredFirearm.replace(
                ':id',
                legalEntity.uniqueId,
              )}?type=individual`,
            )
          }
        >
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.legalEntityType.label')}
            </Typography>
            <Box>
              <Chips
                text={
                  auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
                    ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                    : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                }
                variant={EChipsVariants.primary}
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntities.individual.accordion.legalEntityTable.prefixGroup.column')}
            </Typography>
            <Typography variant="body2">
              <Chips
                text={renderItemDetail(legalEntityPrefix, 'prefixGroupUniqueId')}
                variant={EChipsVariants.primary}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntities.individual.accordion.legalEntityTable.prefixValue.column')}
            </Typography>
            <Typography variant="body2">
              {renderPrefixDetail(legalEntity.prefixGroupUniqueId)}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.dateOfBirth.label')}
            </Typography>
            <Typography variant="body2">
              {legalEntity?.dateOfBirth
                ? formatDate(legalEntity.dateOfBirth, longDateFormat)
                : undefined}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.visibility.label')}
            </Typography>
            <Box>
              <Chips
                text={renderItemDetail(legalVisibility, 'visibilityUniqueId')}
                variant={EChipsVariants.success}
              />
            </Box>
          </Grid>

          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.gender.label')}
              </Typography>
            </Grid>
            <Typography variant="body2">
              {renderItemDetail(gender, 'genderUniqueId')}
            </Typography>
          </Grid>
          {legalEntity?.customFields?.map((customField) => (
            <Grid
              item
              xs={6}
              lg={3}
              container
              direction="column"
              key={customField.customFieldUniqueId}
            >
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {
                  customField?.translations?.filter(
                    (translation) =>
                      selectedLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name
                }
              </Typography>
              <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
            </Grid>
          ))}
        </DetailsTemplate>
      )}

      {orgLegalEntity && (
        <DetailsTemplate
          title={`${orgLegalEntity.organizationName}`}
          pageType={EDetailsPageTypes.auditLogView}
          redirectToView={() =>
            navigate(
              `${ERouteLinks.ViewRegisteredFirearm.replace(
                ':id',
                orgLegalEntity.uniqueId,
              )}?type=organisation`,
            )
          }
        >
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.legalEntityType.label')}
            </Typography>
            <Box>
              <Chips
                text={
                  auditLog?.objectType === ELEObjectType.LE_INDIVIDUAL
                    ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                    : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                }
                variant={EChipsVariants.primary}
              />
            </Box>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.name.label')}
            </Typography>
            <Typography variant="body2">{orgLegalEntity.organizationName}</Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.organizationType.label')}
              </Typography>
            </Grid>
            <Typography variant="body2">
              {renderItemDetail(organizationType, 'organizationTypeUniqueId')}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.departmentName.label')}
              </Typography>
            </Grid>
            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
              {orgLegalEntity.departmentName}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('deleteLegalEntity.visibility.label')}
            </Typography>
            <Box>
              <Chips
                text={renderItemDetail(legalVisibility, 'visibilityUniqueId')}
                variant={EChipsVariants.success}
              />
            </Box>
          </Grid>
          {orgLegalEntity?.customFields?.map((customField) => (
            <Grid
              item
              xs={6}
              lg={3}
              container
              direction="column"
              key={customField.customFieldUniqueId}
            >
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {
                  customField?.translations?.filter(
                    (translation) =>
                      selectedLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name
                }
              </Typography>
              <Typography variant="body2">{renderCustomFieldData(customField)}</Typography>
            </Grid>
          ))}
        </DetailsTemplate>
      )}
    </>
  );
};

export default LegalEntitiesEventDetails;
