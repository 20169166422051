import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';

const useCustomFieldFormSchema = () => {
  const { t } = useTranslations();
  const { languages } = useLang();

  let schema = yup.object({
    name: yup
      .string()
      .required(t('general.field.validation.required'))
      .max(20, t('general.field.validation.maxCharacters').replace('{0}', '20'))
      .trim(),
    customFieldType: yup.string().required(t('general.field.validation.required')),
    objectType: yup.string().required(t('general.field.validation.required')),
    uniqueValue: yup.boolean(),
    inputMaskEnabled: yup.boolean(),
    inputMask: yup.string().when('inputMaskEnabled', {
      is: true,
      then: (yupSchema) =>
        yupSchema
          .required(t('general.field.validation.required'))
          .max(64, t('general.field.validation.maxCharacters').replace('{0}', '64'))
          .trim(),
    }),
    enabledString: yup.string().required(t('general.field.validation.required')),
    mandatory: yup.boolean(),
    searchCriteria: yup.boolean(),
    visibleInSearchResults: yup.boolean(),
    translations: yup.object(),
    placeholder: yup.object(),
  });

  languages.map((lang) => {
    schema = schema.test(`translationTest${lang.uniqueId}`, '', (obj) => {
      if (!obj.translations?.[lang.uniqueId]) {
        return new yup.ValidationError(
          t('general.field.validation.required'),
          null,
          `translations.${lang.uniqueId}`,
        );
      }
      return true;
    });
    return false;
  });

  languages.map((lang) => {
    schema = schema.test(`placeholderTest${lang.uniqueId}`, '', (obj) => {
      if (
        obj.placeholder?.[lang.uniqueId] &&
        (obj.placeholder?.[lang.uniqueId] as string).length > 36
      ) {
        return new yup.ValidationError(
          t('general.field.validation.maxCharacters').replace('{0}', '36'),
          null,
          `placeholder.${lang.uniqueId}`,
        );
      }
      return true;
    });
    return false;
  });

  languages.map((lang) => {
    schema = schema.test(`translationLength${lang.uniqueId}`, '', (obj) => {
      if (
        obj.translations?.[lang.uniqueId] &&
        (obj.translations?.[lang.uniqueId] as string).length > 36
      ) {
        return new yup.ValidationError(
          t('general.field.validation.maxCharacters').replace('{0}', '36'),
          null,
          `translations.${lang.uniqueId}`,
        );
      }
      return true;
    });
    return false;
  });

  return { schema };
};

export default useCustomFieldFormSchema;
