import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { usePermission } from 'hooks/usePermission';
import { emptyFieldHolder } from 'components/molecules/CardList';

interface ITransactionDetailsFirearmsCard {
  data: ITableDataSource;
  isDifferentMakesOrModels: boolean;
  handleView?: (args: string) => void;
}

const TransactionDetailsFirearmsCard = ({
  data,
  isDifferentMakesOrModels,
  handleView,
}: ITransactionDetailsFirearmsCard) => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.firearmDetails.accordion.firecycleID.label')}
        </Typography>
        <Typography variant="body2">{data.fireCycleId}</Typography>
      </Grid>
      {!isDifferentMakesOrModels && (
        <>
          <Grid item xs={12} sm={6} p={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('transactionDetails.firearmDetails.accordion.make.column')}
            </Typography>
            <Typography variant="body2">{data.make}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} p={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('transactionDetails.firearmDetails.accordion.model.column')}
            </Typography>
            <Typography variant="body2">{data.model}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} p={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('transactionDetails.firearmDetails.accordion.firearmAction.column')}
            </Typography>
            <Typography variant="body2">{data.action}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} p={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('transactionDetails.firearmDetails.accordion.type.column')}
            </Typography>
            <Typography variant="body2">{data.type}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} p={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('transactionDetails.firearmDetails.accordion.calibre.column')}
            </Typography>
            <Typography variant="body2">{data.caliber}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} p={2}>
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('transactionDetails.firearmDetails.accordion.firearmReferenceNo.column')}
            </Typography>
            <Typography variant="body2">{data.frtId}</Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.firearmDetails.accordion.serialNumber.label')}
        </Typography>
        <Typography variant="body2">{data.serialNumber}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.firearmDetails.accordion.externalReferenceNo.label')}
        </Typography>
        <Typography variant="body2">{data.externalReferenceNumber}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('transactionDetails.firearmDetails.accordion.manufacturedDate.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.manufacturedAt)}</Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('registeredFirearms.accordion.actions.column')}:{' '}
          </Typography>
        </Grid>

        {hasPermissions([EPermission.VIEW_FIREARMS]) && (
          <Grid item xs={12} sm={6}>
            {handleView && (
              <Button
                label={t('registeredFirearms.accordion.details.link')}
                variant={EButtonVariants.outlined}
                onClick={() => handleView(data.uniqueId)}
                size="small"
                fullWidth
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { TransactionDetailsFirearmsCard };
