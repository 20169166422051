import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { ELEAccordionsPageTypes } from 'components/organisms/LEAccordions';

interface IOrganisationLegalEntityTableCard {
  data: ITableDataSource;
  handleView?: (arg: string) => void;
  handleEdit?: (arg: string) => void;
  handleDelete?: (arg: string) => void;
  handleSelect?: (arg: string) => void;
  type: ELEAccordionsPageTypes;
}

const OrganisationLegalEntityTableCard = ({
  data,
  handleSelect,
  handleView,
  handleEdit,
  handleDelete,
  type,
}: IOrganisationLegalEntityTableCard) => {
  const { t } = useTranslations();

  const dataToSelect = data as any;

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.primary25}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: colors.primary25,
        }}
        px={2}
        py={1}
      >
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            {emptyFieldHolder(data['organization.organizationName'])}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntity.legalEntityType.label')}
        </Typography>
        <Chips text="Organisation" variant={EChipsVariants.primary} />
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Grid>
          <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
            {t('legalEntities.organization.accordion.visibility.label')}
          </Typography>
        </Grid>
        {data.visibility && <Chips text={data.visibility} variant={EChipsVariants.success} />}
      </Grid>
      <Grid item xs={6} lg={3} p={2} sx={{ overflowWrap: 'break-word' }}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntity.legalEntityTable.departmentName.column')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(data['organization.departmentName'])}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('legalEntities.organization.accordion.legalEntityTable.organizationType.column')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(data.organizationTypeUniqueId)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForLegalEntity.legalEntityTable.city.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.city)}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={2} sx={{ mb: { xs: 1, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('searchForLegalEntity.legalEntityTable.action.column')}:
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={10} justifyContent="flex-end" columnSpacing={1}>
          {type === ELEAccordionsPageTypes.LegalEntity ? (
            <>
              <Grid item xs={12} sm={4} sx={{ mb: { xs: 1, sm: 0 } }}>
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  label={t(
                    'legalEntities.organization.accordion.legalEntityTable.details.link',
                  )}
                  onClick={() => handleView && handleView(`${data.id}`)}
                  fullWidth
                  size={EButtonSizes.small}
                  id={`action_mobile_view_${data.id}`}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ mb: { xs: 1, sm: 0 } }}>
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  label={t('legalEntities.organization.accordion.legalEntityTable.edit.link')}
                  onClick={() => handleEdit && handleEdit(`${data.id}`)}
                  fullWidth
                  size={EButtonSizes.small}
                  id={`action_mobile_edit_${data.id}`}
                />
              </Grid>
              <Grid item xs={12} sm={4} sx={{ mb: { xs: 1, sm: 0 } }}>
                <Button
                  variant={EButtonVariants.outlined}
                  tableButton={true}
                  label={t(
                    'legalEntities.organization.accordion.legalEntityTable.delete.link',
                  )}
                  onClick={() => handleDelete && handleDelete(`${data.id}`)}
                  permission={[EPermission.DELETE_LE]}
                  fullWidth
                  size={EButtonSizes.small}
                  id={`action_mobile_delete_${data.id}`}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={10} sx={{ mb: { xs: 1, sm: 0 } }}>
              <Button
                label={t('legalEntities.organization.accordion.legalEntityTable.select.link')}
                variant={EButtonVariants.outlined}
                onClick={() =>
                  handleSelect &&
                  handleSelect(
                    type && type === ELEAccordionsPageTypes.SelectLegalEntity
                      ? dataToSelect
                      : `${data.id}`,
                  )
                }
                fullWidth={true}
                size={EButtonSizes.small}
                id={`action_mobile_check_${data.id}`}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { OrganisationLegalEntityTableCard };
