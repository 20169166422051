import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useDictionaryFormSchema = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    isActive: yup.string().required(t('general.field.validation.required')),
  });

  return { schema };
};

export default useDictionaryFormSchema;
