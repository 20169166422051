import React, { useEffect, useState } from 'react';
import {
  StyledChips,
  StyledCloseIcon,
  StyledSidebarWrapper,
  StyledWarningIcon,
  StyledWarningWrapper,
} from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmDataSidebar/style';
import { Box, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { IFirearm } from 'models/firearm';
import FirearmDataListItem from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmDataSidebar/FirearmDataListItem';
import { EIconTypes } from 'constants/Icons';
import { colors } from 'theme/colors';
import Icon from 'components/atoms/Icon/Icon';
import SearchFilterStore from 'state/SearchFilterStore';
import { EChipsVariants } from 'components/atoms/Chips';
import Grid from '@mui/material/Grid';
import { EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';

export enum ELegalityFilter {
  legal = 'Legal',
  illegal = 'Illegal',
}

const FirearmDataSidebar = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state, getState } = useDictionary();
  const { selectedLanguage } = useLang();
  const [firearms, setFirearms] = useState<IFirearm[]>([]);
  const [filteredFirearms, setFilteredFirearms] = useState<IFirearm[]>([]);
  const [chipsData, setChipsData] = useState<{ illegal: number; legal: number }>({
    illegal: 0,
    legal: 0,
  });
  const [filter, setFilter] = useState<ELegalityFilter>();

  useEffect(() => {
    (async function initDictionaries() {
      getState();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);
  const prepareFirearms = (firearmsData: IFirearm[]) => {
    let illegalCount = 0;
    let legalCount = 0;

    let filteredItems: IFirearm[] = [];

    if (filter) {
      filteredItems = firearmsData.filter((firearm) => firearm.legality === filter);
    } else {
      filteredItems = firearmsData;
    }

    filteredItems.map((firearm) => {
      if (firearm.legality === 'Illegal') {
        illegalCount++;
      } else {
        legalCount++;
      }
      return false;
    });

    setFilteredFirearms(filteredItems);
    setChipsData({ illegal: illegalCount, legal: legalCount });
  };

  document.addEventListener('setFirearmsEvent', (data: any) => {
    const firearmsData: IFirearm[] = data.detail.data as IFirearm[];
    setFirearms(firearmsData);
    prepareFirearms(firearmsData);
    setFilter(undefined);
  });

  useEffect(() => {
    prepareFirearms(firearms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (!filteredFirearms.length) {
    return null;
  }

  return (
    <StyledSidebarWrapper id="firearm-data-sidebar">
      <StyledCloseIcon
        onClick={() => {
          SearchFilterStore.setFirearms([]);
        }}
      >
        <Icon type={EIconTypes.close} color={colors.black} />
      </StyledCloseIcon>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        {t('registeredFirearms.firearmsLocation.legality.label')}
      </Typography>
      <Box>
        {!!chipsData.legal && (
          <StyledChips
            text={`${t('registeredFirearms.firearmsLocation.legal.label')}: ${
              chipsData.legal
            }`}
            variant={EChipsVariants.success}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.legal ? undefined : ELegalityFilter.legal,
              );
            }}
          />
        )}
        {!!chipsData.illegal && (
          <StyledChips
            text={`${t('registeredFirearms.firearmsLocation.illegal.label')}: ${
              chipsData.illegal
            }`}
            variant={EChipsVariants.error}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.illegal ? undefined : ELegalityFilter.illegal,
              );
            }}
          />
        )}
      </Box>

      <Grid
        container
        sx={{
          margin: '15px 0',
          paddingTop: '15px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.gray200}`,
          },
        }}
      >
        {filteredFirearms.length > 50 && (
          <StyledWarningWrapper>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <StyledWarningIcon>
                  <Icon type={EIconTypes.info} color={colors.white} />
                </StyledWarningIcon>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="caption">
                  {t('firearms.firearmLocation.caption')}
                </Typography>
              </Grid>
            </Grid>
          </StyledWarningWrapper>
        )}

        <Grid item xs={12}>
          <Button
            label={t('registeredFirearms.firearmsLocation.showFirearms.label')}
            variant={EButtonVariants.contained}
            onClick={() => {
              navigate(`${ERouteLinks.Firearms}?expand=registered-firearms&fromMap=true`);
            }}
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>

      {filteredFirearms.length <= 50 &&
        filteredFirearms.map((firearm) => (
          <FirearmDataListItem data={firearm} key={firearm.uniqueId} state={state} />
        ))}
    </StyledSidebarWrapper>
  );
};

export default FirearmDataSidebar;
