import React, { useEffect, useState } from 'react';
import {
  StyledChips,
  StyledCloseIcon,
  StyledSidebarWrapper,
  StyledWarningIcon,
  StyledWarningWrapper,
} from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmDataSidebar/style';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';
import { EIconTypes } from 'constants/Icons';
import { colors } from 'theme/colors';
import Icon from 'components/atoms/Icon/Icon';
import SearchFilterStore from 'state/SearchFilterStore';
import { EChipsVariants } from 'components/atoms/Chips';
import Grid from '@mui/material/Grid';
import { EButtonVariants } from 'constants/Buttons';
import Button from 'components/atoms/Button';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useDictionary } from 'models/dictionaryContext';
import { useLang } from 'models/langContext';
import { IAncillary } from 'models/ancillaries';
import { LEGAL_ID } from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmsLocationMap';
import AmmunitionDataListItem from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/AmmunitionDataSidebar/AmmunitionDataListItem';
import { EArtAncillaryTypeNameUpperCase } from 'constants/ArtAccessoryType';
import AccessoryDataListItem from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/AncillaryDataSidebar/AccessoryDataListItem';
import ComponentDataListItem from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap/ComponentDataSidebar/ComponentDataListItem';

export enum ELegalityFilter {
  legal = 'd90f05bf-6baf-4f86-9ca3-9501af6901ea',
  illegal = 'ae5de668-67ac-4650-af26-23dd0cb97c89',
}

const AncillariesDataSidebar = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state, getState } = useDictionary();
  const { selectedLanguage } = useLang();
  const [ancillaries, setAncillaries] = useState<IAncillary[]>([]);
  const [filteredAncillaries, setFilteredAncillaries] = useState<IAncillary[]>([]);
  const [chipsData, setChipsData] = useState<{ illegal: number; legal: number }>({
    illegal: 0,
    legal: 0,
  });
  const [filter, setFilter] = useState<ELegalityFilter>();

  const filteredAccessories = filteredAncillaries.filter(
    (ancillary) => ancillary.ancillaryType === EArtAncillaryTypeNameUpperCase.ACCESSORY,
  );
  const filteredAmmunition = filteredAncillaries.filter(
    (ancillary) => ancillary.ancillaryType === EArtAncillaryTypeNameUpperCase.AMMUNITION,
  );
  const filteredComponents = filteredAncillaries.filter(
    (ancillary) => ancillary.ancillaryType === EArtAncillaryTypeNameUpperCase.COMPONENT,
  );

  useEffect(() => {
    (async function initDictionaries() {
      getState();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const prepareAncillaries = (ancillariesData: IAncillary[]) => {
    let illegalCount = 0;
    let legalCount = 0;

    let filteredItems: IAncillary[] = [];

    if (filter) {
      filteredItems = ancillariesData.filter(
        (ancillary) => ancillary.legalityUniqueId === filter,
      );
    } else {
      filteredItems = ancillariesData;
    }

    filteredItems.map((ancilllary) => {
      if (ancilllary.legalityUniqueId !== LEGAL_ID) {
        illegalCount++;
      } else {
        legalCount++;
      }
      return false;
    });

    setFilteredAncillaries(filteredItems);
    setChipsData({ illegal: illegalCount, legal: legalCount });
  };

  document.addEventListener('setAncillariesEvent', (data: any) => {
    const ancillariesData: IAncillary[] = data.detail.data as IAncillary[];
    setAncillaries(ancillariesData);
    prepareAncillaries(ancillariesData);
    setFilter(undefined);
  });

  useEffect(() => {
    prepareAncillaries(ancillaries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (!filteredAncillaries.length) {
    return null;
  }

  return (
    <StyledSidebarWrapper id="accessories-data-sidebar">
      <StyledCloseIcon
        onClick={() => {
          SearchFilterStore.setAncillaries([]);
        }}
      >
        <Icon type={EIconTypes.close} color={colors.black} />
      </StyledCloseIcon>
      <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
        {t('ancillaries.ancillariesLocation.accessoryLegality')}
      </Typography>
      <Box>
        {!!chipsData.legal && (
          <StyledChips
            text={`${t('ancillaries.ancillariesLocation.legal.label')}: ${chipsData.legal}`}
            variant={EChipsVariants.success}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.legal ? undefined : ELegalityFilter.legal,
              );
            }}
          />
        )}
        {!!chipsData.illegal && (
          <StyledChips
            text={`${t('ancillaries.ancillariesLocation.illegal.label')}: ${
              chipsData.illegal
            }`}
            variant={EChipsVariants.error}
            onClick={() => {
              setFilter((prev) =>
                prev === ELegalityFilter.illegal ? undefined : ELegalityFilter.illegal,
              );
            }}
          />
        )}
      </Box>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(filteredAncillaries.length > 50
            ? {
                margin: '15px 0',
                paddingTop: '15px',
                borderTop: {
                  xs: `1px solid ${colors.gray200}`,
                },
              }
            : undefined),
        }}
      >
        {filteredAncillaries.length > 50 && (
          <>
            <StyledWarningWrapper>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <StyledWarningIcon>
                    <Icon type={EIconTypes.info} color={colors.white} />
                  </StyledWarningIcon>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="caption">
                    {t('ancillaries.ancillariesLocation.accessory.caption')}
                  </Typography>
                </Grid>
              </Grid>
            </StyledWarningWrapper>
            {filteredAccessories.length > 0 && (
              <Grid item xs={12} mb={2}>
                <Button
                  label={t('ancillaries.ancillariesLocation.showFirearms.label')}
                  variant={EButtonVariants.contained}
                  onClick={() => {
                    navigate(`${ERouteLinks.Ancillaries}?expand=accessories&fromMap=true`, {
                      state: {
                        accessoriesUniqueIds: filteredAccessories.map(
                          (accessory) => accessory.uniqueId,
                        ),
                      },
                    });
                  }}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}
            {filteredAmmunition.length > 0 && (
              <Grid item xs={12} mb={2}>
                <Button
                  label={t('ancillaries.ancillariesLocation.showAmmunition.label')}
                  variant={EButtonVariants.contained}
                  onClick={() => {
                    navigate(`${ERouteLinks.Ancillaries}?expand=ammunition&fromMap=true`, {
                      state: {
                        ammunitionUniqueIds: filteredAmmunition.map(
                          (accessory) => accessory.uniqueId,
                        ),
                      },
                    });
                  }}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}
            {filteredComponents.length > 0 && (
              <Grid item xs={12}>
                <Button
                  label={t('ancillaries.ancillariesLocation.showComponents.label')}
                  variant={EButtonVariants.contained}
                  onClick={() => {
                    navigate(`${ERouteLinks.Ancillaries}?expand=components&fromMap=true`, {
                      state: {
                        componentsUniqueIds: filteredComponents.map(
                          (accessory) => accessory.uniqueId,
                        ),
                      },
                    });
                  }}
                  size="small"
                  fullWidth
                />
              </Grid>
            )}
          </>
        )}

        {filteredAncillaries.length <= 50 && filteredAccessories.length > 0 && (
          <>
            <Grid item xs={12} mt={2} mb={2}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                label={t('ancillaries.ancillariesLocation.showFirearms.label')}
                variant={EButtonVariants.contained}
                onClick={() => {
                  navigate(`${ERouteLinks.Ancillaries}?expand=accessories&fromMap=true`, {
                    state: {
                      accessoriesUniqueIds: filteredAccessories.map(
                        (accessory) => accessory.uniqueId,
                      ),
                    },
                  });
                }}
                size="small"
                fullWidth
              />
            </Grid>
            {filteredAccessories.map((ancillary) => (
              <AccessoryDataListItem data={ancillary} key={ancillary.uniqueId} state={state} />
            ))}
          </>
        )}
        {filteredAncillaries.length <= 50 && filteredAmmunition.length > 0 && (
          <>
            <Grid item xs={12} mt={2} mb={2}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                label={t('ancillaries.ancillariesLocation.showAmmunition.label')}
                variant={EButtonVariants.contained}
                onClick={() => {
                  navigate(`${ERouteLinks.Ancillaries}?expand=ammunition&fromMap=true`, {
                    state: {
                      ammunitionUniqueIds: filteredAmmunition.map(
                        (accessory) => accessory.uniqueId,
                      ),
                    },
                  });
                }}
                size="small"
                fullWidth
              />
            </Grid>
            {filteredAmmunition.map((ancillary) => (
              <AmmunitionDataListItem
                data={ancillary}
                key={ancillary.uniqueId}
                state={state}
              />
            ))}
          </>
        )}
        {filteredAncillaries.length <= 50 && filteredComponents.length > 0 && (
          <>
            <Grid item xs={12} mt={2} mb={2}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                label={t('ancillaries.ancillariesLocation.showComponents.label')}
                variant={EButtonVariants.contained}
                onClick={() => {
                  navigate(`${ERouteLinks.Ancillaries}?expand=components&fromMap=true`, {
                    state: {
                      componentsUniqueIds: filteredComponents.map(
                        (accessory) => accessory.uniqueId,
                      ),
                    },
                  });
                }}
                size="small"
                fullWidth
              />
            </Grid>
            {filteredComponents.map((ancillary) => (
              <ComponentDataListItem data={ancillary} key={ancillary.uniqueId} state={state} />
            ))}
          </>
        )}
      </Grid>
    </StyledSidebarWrapper>
  );
};

export default AncillariesDataSidebar;
