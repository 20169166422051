import styled from '@emotion/styled';
import { MapContainer } from 'react-leaflet';
import {
  ILocationMapDimensions,
  ILocationMapProps,
} from 'components/organisms/LocationMap/index';
import { css } from '@mui/material';

interface IStyledLocationMapWrapper {
  dimensions: ILocationMapDimensions;
}

const StyledLocationMapWrapper = styled.div<IStyledLocationMapWrapper | any>`
  ${({ dimensions }: ILocationMapProps) =>
    dimensions?.width && dimensions?.height
      ? css`
          width: ${dimensions?.width};
          height: ${dimensions?.height};
        `
      : css`
          width: 300px;
          height: 300px;
        `}
`;
const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 100%;
`;

export { StyledLocationMapWrapper, StyledMapContainer };
