import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useTranslations } from 'hooks/useTranslations';
import { EPermission } from 'models/permissions';

interface IAmmunitionCard {
  data: ITableDataSource;
  handleEdit?: (args: string) => void;
  handleView?: (args: string) => void;
  handleSelect?: () => void;
  isTransactionPage: boolean;
}

const AncillariesReferenceAmmunitionCard = ({
  data,
  handleEdit,
  handleView,
  handleSelect,
  isTransactionPage,
}: IAmmunitionCard) => {
  const { t } = useTranslations();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillariesReferenceTable.accordion.ancillary.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ancillary)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillariesReferenceTable.accordion.artId.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.artId)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ammunitionTable.accordion.ammunitionType.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.ammunitionType)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ammunitionTable.accordion.ammunitionManufacturer.label')}
        </Typography>
        <Typography variant="body2">
          {emptyFieldHolder(data.ammunitionManufacturer)}
        </Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ammunitionTable.accordion.productName.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.productName)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ammunitionTable.accordion.factory.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.factory)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillariesReferenceTable.accordion.calibre.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.calibre)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ammunitionTable.accordion.functionalType.label')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.functionalType)}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('ammunitionTable.accordion.action.column')}:{' '}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={9} spacing={1} justifyContent="flex-end">
          {handleEdit && !isTransactionPage && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('ammunitionTable.accordion.edit.column')}
                variant={EButtonVariants.outlined}
                permission={[EPermission.MANAGE_LOCAL_ART]}
                onClick={handleEdit}
                size="small"
                fullWidth
              />
            </Grid>
          )}
          {handleSelect && isTransactionPage && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('general.select.text')}
                variant={EButtonVariants.contained}
                onClick={handleSelect}
                size="small"
                fullWidth
              />
            </Grid>
          )}
          {handleView && (
            <Grid item xs={12} sm={6}>
              <Button
                label={t('ammunitionTable.accordion.details.column')}
                variant={EButtonVariants.outlined}
                onClick={() => handleView(data.id as string)}
                size="small"
                fullWidth
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export { AncillariesReferenceAmmunitionCard };
