import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StyledFirearmLegalityDropdownWrapper } from 'pages/Firearms/RegisteredFirearmsMapPage/MapLegalityDropdown/style';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { IDictionary } from 'models/dictionary';
import { useTranslations } from 'hooks/useTranslations';

export interface IFirearmsLocationMapForm {
  legality: string;
}

const initialFormValues: IFirearmsLocationMapForm = {
  legality: '',
};

interface ILegalityDropdown {
  updateLegality(id: string): void;
  label: string;
  legality: IDictionary[] | null;
}

const MapLegalityDropdown = ({ updateLegality, legality, label }: ILegalityDropdown) => {
  const { t } = useTranslations();
  const [isInit, setIsInit] = useState(true);
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFirearmsLocationMapForm>({
    defaultValues: initialFormValues,
  });

  const watchLegality = watch('legality');

  useEffect(() => {
    if (!isInit) {
      updateLegality(watchLegality);
    }
    setIsInit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLegality]);

  return (
    <StyledFirearmLegalityDropdownWrapper>
      <FormSelect
        options={[{ label: t(label), value: '' }, ...mapDictionaryToOptionProp(legality)]}
        setValue={setValue}
        name="legality"
        control={control}
        errors={errors}
      />
    </StyledFirearmLegalityDropdownWrapper>
  );
};

export default MapLegalityDropdown;
