import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import {
  ISearchFilterIndividualRequest,
  ISearchFilterOrganizationRequest,
} from 'models/searchFilter';
import {
  createSearchFilterIndividual,
  createSearchFilterOrganization,
} from 'requests/searchFilter';

interface ISavePreset {
  type: ESearchFilterKeys | ELegalEntityTypes;
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
  body: ISearchFilterIndividualRequest | ISearchFilterOrganizationRequest;
}

export const useSavePreset = () => {
  const savePreset = ({
    type,
    name,
    emailNotificationEnabled,
    notificationEnabled,
    body,
  }: ISavePreset) => {
    switch (type) {
      case ESearchFilterKeys.individual:
      case ELegalEntityTypes.individual:
        return createSearchFilterIndividual(
          name,
          emailNotificationEnabled,
          notificationEnabled,
          body as ISearchFilterIndividualRequest,
        );

      case ESearchFilterKeys.organisation:
      case ELegalEntityTypes.organisation:
        return createSearchFilterOrganization(
          name,
          emailNotificationEnabled,
          notificationEnabled,
          body as ISearchFilterOrganizationRequest,
        );
      default:
        return null;
    }
  };

  return { savePreset };
};
