import React from 'react';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { ICustomField } from 'models/customField';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { StyledTooltip } from 'pages/CustomFieldsPage/styles';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { ECustomFieldActions } from 'pages/CustomFieldsPage/ToggleCustomFieldPage';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useTranslations } from 'hooks/useTranslations';
import { mapCustomFieldToLabel, mapCustomFieldTypeToLabel } from './helpers';

interface ICustomFieldCardList {
  data: ICustomField;
  canBeEnabled: boolean;
  maxActive: number | undefined;
}

const CustomFieldCardList = ({ data, canBeEnabled, maxActive }: ICustomFieldCardList) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { isDesktop } = useThemeBreakpoint();

  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('customFields.card.objectType.label')}
        </Typography>
        <Typography variant="body2">{t(mapCustomFieldToLabel(data.objectType))}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('customFields.card.name.label')}
        </Typography>
        <Typography variant="body2">{data.name}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('customFields.card.type.label')}
        </Typography>
        <Typography variant="body2">
          {t(mapCustomFieldTypeToLabel(data.customFieldType))}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('customFields.card.status.label')}
        </Typography>
        <Chips
          text={
            data.enabled
              ? t('customFields.card.enabled.label')
              : t('customFields.card.disabled.label')
          }
          variant={data.enabled ? EChipsVariants.success : EChipsVariants.inactive}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('customFields.card.mandatoryField.label')}
        </Typography>
        <Chips
          text={
            data.mandatory ? t('customFields.card.yes.label') : t('customFields.card.no.label')
          }
          variant={data.mandatory ? EChipsVariants.primary : EChipsVariants.inactive}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('customFields.searchCriterion.label')}
        </Typography>
        <Chips
          text={
            data.searchCriteria
              ? t('customFields.card.yes.label')
              : t('customFields.card.no.label')
          }
          variant={data.searchCriteria ? EChipsVariants.primary : EChipsVariants.inactive}
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('customFields.visibleSearchResults.label')}
        </Typography>
        <Chips
          text={
            data.visibleInSearchResults
              ? t('customFields.card.yes.label')
              : t('customFields.card.no.label')
          }
          variant={
            data.visibleInSearchResults ? EChipsVariants.primary : EChipsVariants.inactive
          }
        />
      </Grid>
      {isDesktop ? (
        <Grid item xs={12} sm={6} lg={3} p={2}>
          {!data.enabled && !canBeEnabled ? (
            <StyledTooltip
              title={`${t('customField.details.theLimitOf')} ${maxActive} ${t(
                'customField.details.enabledCustomFieldsForThisObjectTypeHasBeenReached',
              )}`}
              placement="bottom-end"
            >
              <span>
                <Button
                  label={
                    data.enabled
                      ? t('customFields.disable.button')
                      : t('customFields.enable.button')
                  }
                  disabled={true}
                  size="small"
                  sx={{ marginRight: '5px', marginBottom: '5px' }}
                  onClick={() =>
                    navigate(
                      `${ERouteLinks.ToggleCustomField.replace(':id', data.uniqueId).replace(
                        ':toggleType',
                        data.enabled
                          ? ECustomFieldActions.Disable
                          : ECustomFieldActions.Enable,
                      )}`,
                    )
                  }
                />
              </span>
            </StyledTooltip>
          ) : (
            <Button
              label={
                data.enabled
                  ? t('customFields.disable.button')
                  : t('customFields.enable.button')
              }
              size="small"
              sx={{ marginRight: '5px', marginBottom: '5px' }}
              onClick={() =>
                navigate(
                  `${ERouteLinks.ToggleCustomField.replace(':id', data.uniqueId).replace(
                    ':toggleType',
                    data.enabled ? ECustomFieldActions.Disable : ECustomFieldActions.Enable,
                  )}`,
                )
              }
            />
          )}
          <Button
            label={t('customFields.card.edit.label')}
            variant={EButtonVariants.outlined}
            size="small"
            sx={{ marginRight: '5px', marginBottom: '5px' }}
            onClick={() =>
              navigate(`${ERouteLinks.EditCustomField.replace(':id', data.uniqueId)}`)
            }
          />
          {data.deletable ? (
            <Button
              variant={EButtonVariants.outlined}
              icon={EIconTypes.delete}
              sizePX={EButtonSizesPX.px32}
              sx={{ marginBottom: '5px' }}
              onClick={() =>
                navigate(
                  `${ERouteLinks.ToggleCustomField.replace(':id', data.uniqueId).replace(
                    ':toggleType',
                    ECustomFieldActions.Delete,
                  )}`,
                )
              }
            />
          ) : (
            <StyledTooltip title={t('customFields.tooltip.label')} placement="bottom-end">
              <span>
                <Button
                  variant={EButtonVariants.outlined}
                  icon={EIconTypes.delete}
                  sizePX={EButtonSizesPX.px32}
                  sx={{ marginBottom: '5px' }}
                  disabled={true}
                />
              </span>
            </StyledTooltip>
          )}
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTop: {
              xs: `1px solid ${colors.primary25}`,
              lg: 'none',
            },
            m: {
              xs: 2,
              lg: 2,
            },
          }}
          spacing={0}
        >
          <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
            <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
              {t('legalEntities.individual.accordion.legalEntityTable.action.column')}
            </Typography>
          </Grid>

          <Grid container item xs={12} sm={9} justifyContent="flex-end" columnGap={3}>
            <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
              {!data.enabled && !canBeEnabled ? (
                <StyledTooltip
                  title={`${t('customField.details.theLimitOf')} ${maxActive} ${t(
                    'customField.details.enabledCustomFieldsForThisObjectTypeHasBeenReached',
                  )}`}
                  placement="top-start"
                >
                  <span>
                    <Button
                      label={
                        data.enabled
                          ? t('customFields.card.disabled.label')
                          : t('customFields.card.enabled.label')
                      }
                      disabled={true}
                      fullWidth={true}
                      size="small"
                      onClick={() =>
                        navigate(
                          `${ERouteLinks.ToggleCustomField.replace(
                            ':id',
                            data.uniqueId,
                          ).replace(
                            ':toggleType',
                            data.enabled
                              ? ECustomFieldActions.Disable
                              : ECustomFieldActions.Enable,
                          )}`,
                        )
                      }
                    />
                  </span>
                </StyledTooltip>
              ) : (
                <Button
                  label={
                    data.enabled
                      ? t('customFields.card.disabled.label')
                      : t('customFields.card.enabled.label')
                  }
                  fullWidth={true}
                  size="small"
                  onClick={() =>
                    navigate(
                      `${ERouteLinks.ToggleCustomField.replace(':id', data.uniqueId).replace(
                        ':toggleType',
                        data.enabled
                          ? ECustomFieldActions.Disable
                          : ECustomFieldActions.Enable,
                      )}`,
                    )
                  }
                />
              )}
            </Grid>

            <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
              <Button
                label={t('customFields.card.edit.label')}
                fullWidth={true}
                variant={EButtonVariants.outlined}
                size="small"
                onClick={() =>
                  navigate(`${ERouteLinks.EditCustomField.replace(':id', data.uniqueId)}`)
                }
              />
            </Grid>

            <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
              {data.deletable ? (
                <Button
                  variant={EButtonVariants.outlined}
                  fullWidth={true}
                  label={t('customFields.delete.label')}
                  size="small"
                  onClick={() =>
                    navigate(
                      `${ERouteLinks.ToggleCustomField.replace(':id', data.uniqueId).replace(
                        ':toggleType',
                        ECustomFieldActions.Delete,
                      )}`,
                    )
                  }
                />
              ) : (
                <StyledTooltip title={t('customFields.tooltip.label')} placement="top-start">
                  <span>
                    <Button
                      variant={EButtonVariants.outlined}
                      fullWidth={true}
                      label={t('customFields.delete.label')}
                      size="small"
                      disabled={true}
                    />
                  </span>
                </StyledTooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CustomFieldCardList;
