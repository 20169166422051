import styled from '@emotion/styled';
import { css, Typography } from '@mui/material';
import { colors } from 'theme/colors';

export interface CheckboxStyle {
  backgroundColorUnChecked?: string;
  backgroundColorChecked: string;
  backgroundColorDisabled?: string;
  borderColor: string;
}

export const defaultCheckboxStyle = {
  backgroundColorChecked: colors.primary500,
  borderColor: colors.primary500,
  backgroundColorDisabled: colors.gray400,
};

export const StyledFormCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export interface IStyledCheckboxBackgroundWrapper {
  backgroundColor?: string;
}

interface IStyledFormLabel {
  disabled?: boolean;
}

export const StyledCheckboxBackgroundWrapper = styled.div<IStyledCheckboxBackgroundWrapper>`
  ${(props: IStyledCheckboxBackgroundWrapper) =>
    props.backgroundColor
      ? css`
          width: 16px;
          height: 16px;
          margin: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${props.backgroundColor};
        `
      : ''},
`;
export const StyledCheckboxLabel = styled(Typography)<IStyledFormLabel>`
  ${(props: IStyledFormLabel) =>
    props.disabled &&
    css`
      color: ${colors.gray400};
    `}
`;
