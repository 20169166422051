import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { FormInput } from 'components/molecules/FormInput';
import {
  mapDictionaryToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { StyledPresetSelect } from 'pages/LegalEntitiesPage/IndividualSearchForm/styles';
import { Box } from '@mui/material';
import { ERouteLinks } from 'models/route';
import { ESearchFilterKeys, ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { prepareFilters } from 'helpers/filters';
import { useTranslations } from 'hooks/useTranslations';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import { useFirearms } from 'pages/Firearms/hooks';
import { IFormElementOptionProp } from 'models/form';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { formatDateTimeToIso } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { filterOutAncillaryStates } from 'pages/LegalEntitiesPage/FirearmSearchForm';
import { isEmpty } from 'utils/search';
import { useDrawerClicked } from 'state/contexts/drawer';
import { getSearchFilter } from 'requests/searchFilter';
import { ISearchParams } from 'pages/UsersPage';
import { LEAccordionNames } from 'pages/Firearms/index';
import { isEmptyObject } from '../helpers';
import { StyledAdvancedSearch, StyledFormSelect } from './styles';

export interface ISearchFormValues {
  preset: string | undefined;
  fireCycleId: string;
  exhibitNumber: string;
  stateUniqueId: string;
  legalityUniqueId: string;
  legalEntityGroup: string;
  make: string;
  model: string;
  manufacturer: string;
  caliber: string;
  productCode: string;
  action: string;
  type: string;
  ownerUniqueId: string;
  keeperUniqueId: string;
  serialNumber: string;
  uniqueIds?: string[];
  customFields?: ISearchFormCustomFieldValue[];
  ownerName: string;
  keeperName: string;

  [key: string]: any;
}

export enum ESearchFormFirearmLabels {
  serialNumber = 'registeredFirearms.accordion.serialNumber.label',
  fireCycleId = 'registeredFirearms.accordion.fireCycleId.label',
  stateUniqueId = 'registeredFirearms.accordion.state.label',
  legalityUniqueId = 'registeredFirearms.accordion.legality.label',
  make = 'registeredFirearms.accordion.make.label',
  model = 'registeredFirearms.accordion.model.label',
  manufacturer = 'registeredFirearms.accordion.manufacturer.label',
  caliber = 'registeredFirearms.accordion.calibre.label',
  productCode = 'registeredFirearms.accordion.alsoKnownAsProductCode.label',
  action = 'registeredFirearms.accordion.firearmAction.label',
  type = 'registeredFirearms.accordion.type.label',
  ownerName = 'transactionsList.owner.label',
  keeperName = 'transactionsList.keeper.label',
  exhibitNumber = 'accessoriesTable.accordion.exhibitNumber.column',
}

export const initialForm: ISearchFormValues = {
  preset: '',
  fireCycleId: '',
  stateUniqueId: '',
  legalityUniqueId: '',
  legalEntityGroup: '',
  make: '',
  model: '',
  manufacturer: '',
  caliber: '',
  productCode: '',
  action: '',
  type: '',
  ownerUniqueId: '',
  keeperUniqueId: '',
  serialNumber: '',
  exhibitNumber: '',
  customFields: [],
  ownerName: '',
  keeperName: '',
};

export enum ESelectLE {
  keeper = 'keeper',
  owner = 'owner',
}

interface IRegisteredFirearmsSearchForm {
  onSubmit: (arg: any) => void;
  getNewQueryPath?: (arg: any) => string;
  ownerOptions: IFormElementOptionProp[];
  setOwnerOptions: (opt: IFormElementOptionProp[]) => void;
  keeperOptions: IFormElementOptionProp[];
  setKeeperOptions: (opt: IFormElementOptionProp[]) => void;
  showAdvanced: boolean;
  uniqueIds?: string[];
  isFromMap: boolean;
  returnPath: string;
  setShowAdvanced: (val: boolean) => void;
  customFields?: ICustomFieldByObjectType[];
  initialFormValues?: ISearchFormValues;
  isSearchButtonDisabled?: boolean;
}

const customFieldWrapper = (children: React.ReactNode) => (
  <Grid item lg={2} md={5} sm={5} xs={10}>
    {children}
  </Grid>
);

const RegisteredFirearmsSearchForm = ({
  ownerOptions,
  keeperOptions,
  setOwnerOptions,
  setKeeperOptions,
  showAdvanced,
  setShowAdvanced,
  onSubmit,
  uniqueIds,
  isFromMap,
  returnPath,
  customFields = [],
  initialFormValues = initialForm,
  isSearchButtonDisabled = false,
  getNewQueryPath,
}: IRegisteredFirearmsSearchForm) => {
  const { state: routerState }: any = useLocation();
  const [values, setValues] = useState<ISearchFormValues>({
    ...initialFormValues,
    uniqueIds,
  });
  const { t } = useTranslations();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialFormValues,
  });
  const { isDesktop } = useThemeBreakpoint();
  const { selectedLanguage } = useLang();
  const watchPreset = watch('preset');

  const {
    loadDictionariesForRegisteredFirearms,
    make,
    model,
    state,
    caliber,
    legality,
    manufacturer,
    firearmAction,
    firearmType,
  } = useFirearms();

  const { getFilterPresets, presets, getPresetValues, preparePresetsList } =
    useFilterPresets();

  const [searchParams] = useSearchParams();
  const presetId =
    searchParams.get('presetId') || searchParams.get('preset') || routerState?.presetFirearm;
  const expandParam = searchParams.get('expand');
  const { getDateFormat } = useGlobalProperty();
  const [showSaveFilter, setShowSaveFilter] = useState(false);
  const { drawerClicked } = useDrawerClicked();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    if (initialFormValues?.ownerUniqueId && initialFormValues.ownerName) {
      setOwnerOptions([
        {
          label: initialFormValues.ownerName,
          value: initialFormValues.ownerUniqueId,
        },
      ]);
    }
    if (initialFormValues?.keeperUniqueId && initialFormValues.keeperName) {
      setKeeperOptions([
        {
          label: initialFormValues.keeperName,
          value: initialFormValues.keeperUniqueId,
        },
      ]);
    }
  }, []);

  const setPresetFilters = async (
    selectedPreset: string,
    isFromEmailLink: boolean = false,
    isClickedManually: boolean = false,
    isInitialLoad: boolean = false,
  ) => {
    const handleValues = async () => {
      if (isFromEmailLink) {
        const filter = await getSearchFilter(selectedPreset);
        if (filter) {
          const queryValues: ISearchParams = {};
          filter.values.forEach((it) => {
            queryValues[it.key] = it.value;
          });
          return queryValues;
        }
      }
      return getPresetValues(selectedPreset);
    };

    const initialValues = isInitialLoad
      ? { ...initialFormValues, ...routerState?.formValues }
      : initialFormValues;

    const presetValues: any = {
      ...(isClickedManually ? initialForm : initialValues),
      preset: selectedPreset,
      ...(await handleValues()),
    };

    if (isClickedManually) {
      customFields
        .filter((it) => it.searchCriteria)
        .forEach((it) => {
          if (it.customFieldType === ECustomFieldType.DATE) {
            presetValues[`from_${it.name}`] = '';
            presetValues[`to_${it.name}`] = '';
          } else {
            presetValues[it.name] = '';
          }
        });
    }

    if (presetValues.ownerUniqueId && presetValues?.ownerName) {
      setOwnerOptions([
        {
          label: presetValues.ownerName,
          value: presetValues.ownerUniqueId,
        },
      ]);
    }

    if (presetValues.keeperUniqueId && presetValues.keeperName) {
      setKeeperOptions([
        {
          label: presetValues.keeperName,
          value: presetValues.keeperUniqueId,
        },
      ]);
    }

    setValues(presetValues);
    reset(presetValues);

    if (isClickedManually || isFromEmailLink) {
      await handleSubmit(onSubmit)();
    }
  };

  const setLegalEntity = () => {
    const formValues = {
      ...initialFormValues,
      ...routerState?.formValues,
    };

    if (routerState?.type && routerState?.legalEntity) {
      const legalName = routerState?.legalEntity?.['organization.organizationName']
        ? routerState?.legalEntity?.['organization.organizationName']
        : `${routerState?.legalEntity?.['individual.firstName']} ${routerState?.legalEntity?.['individual.lastName']}`;
      const option = [
        {
          label: legalName,
          value: routerState.legalEntity.id,
        },
      ];
      if (routerState.type === ESelectLE.keeper) {
        setKeeperOptions(option);
        formValues.keeperName = legalName;
        if (routerState?.options?.ownerOptions) {
          setOwnerOptions(routerState.options.ownerOptions);
        }
      } else {
        setOwnerOptions(option);
        formValues.ownerName = legalName;
        if (routerState?.options?.keeperOptions) {
          setKeeperOptions(routerState.options.keeperOptions);
        }
      }
      formValues[routerState?.type === ESelectLE.owner ? 'ownerUniqueId' : 'keeperUniqueId'] =
        routerState.legalEntity.id;
    }
    setValues(formValues);
    setShowAdvanced(true);
    reset(formValues);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionariesForRegisteredFirearms([
        EDictionaryTypes.Make,
        EDictionaryTypes.Model,
        EDictionaryTypes.State,
        EDictionaryTypes.Caliber,
        EDictionaryTypes.Legality,
        EDictionaryTypes.Manufacturer,
        EDictionaryTypes.FirearmAction,
        EDictionaryTypes.FirearmType,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    (async function init() {
      await getFilterPresets(ESearchFilterTypes.firearm);
      if (routerState?.presetFirearm) {
        setPresetFilters(routerState?.presetFirearm);
        await handleSubmit(onSubmit)();
      }

      if (routerState?.options?.keeperOptions.length) {
        setKeeperOptions(routerState.options.keeperOptions);
      }

      if (routerState?.options?.ownerOptions.length) {
        setOwnerOptions(routerState.options.ownerOptions);
      }

      if (presetId && expandParam === LEAccordionNames.REGISTERED) {
        setPresetFilters(presetId, true);
        await handleSubmit(onSubmit)();
      }

      if (routerState?.formValues) {
        const formValues = {
          ...initialFormValues,
          ...routerState.formValues,
          legalityUniqueId: routerState?.legalityUniqueId,
          keeperUniqueId: routerState?.options?.keeperOptions[0]?.value || '',
          ownerUniqueId: routerState?.options?.ownerOptions[0]?.value || '',
        };
        setValues(formValues);
        reset(formValues);
      }
      if (!isEmptyObject(routerState?.formValues)) {
        setShowAdvanced(true);
      }
      if (routerState?.legalEntity) {
        setLegalEntity();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isEmpty(values.serialNumber) &&
      isEmpty(values.fireCycleId) &&
      isEmpty(values.exhibitNumber) &&
      isEmpty(values.stateUniqueId) &&
      isEmpty(values.legalityUniqueId) &&
      isEmpty(values.type) &&
      isEmpty(values.make) &&
      isEmpty(values.model) &&
      isEmpty(values.manufacturer) &&
      isEmpty(values.caliber) &&
      isEmpty(values.productCode) &&
      isEmpty(values.action) &&
      isEmpty(values.ownerUniqueId) &&
      isEmpty(values.keeperUniqueId)
    ) {
      setShowSaveFilter(false);
    } else {
      setShowSaveFilter(true);
    }
  }, [values]);

  useEffect(() => {
    (async function init() {
      if (watchPreset) {
        await setPresetFilters(watchPreset, false, false, true);
      } else {
        await setPresetFilters('', false, false, true);
      }
      await handleSubmit(onSubmit)();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareData = (data: ISearchFormValues): ISearchFormValues => {
    const customFieldsPayload: ISearchFormCustomFieldValue[] = [];

    Object.keys(data).map((key) => {
      const isDateCF = key.startsWith('from_') || key.startsWith('to_');

      if (!isDateCF) {
        const customField = customFields.find((item) => item.name === key);
        if (customField && data[key]) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool = data[key] === '1';
          }

          customFieldsPayload.push(customFieldItem);
          delete data[key];
        }
      } else {
        const customFieldKey = key.startsWith('from_')
          ? key.replace('from_', '')
          : key.replace('to_', '');

        const customField = customFields.find((item) => item.name === customFieldKey);

        if (customField && (data[`from_${customFieldKey}`] || data[`to_${customFieldKey}`])) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (data[`from_${customFieldKey}`]) {
            customFieldItem.dateFrom =
              data[`from_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`from_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          if (data[`to_${customFieldKey}`]) {
            customFieldItem.dateTo =
              data[`to_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`to_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          customFieldsPayload.push(customFieldItem);
          if (data[`from_${customFieldKey}`]) {
            delete data[`from_${customFieldKey}`];
          }
          if (data[`to_${customFieldKey}`]) {
            delete data[`to_${customFieldKey}`];
          }
        }
      }
      return false;
    });

    if (customFieldsPayload?.length) {
      data.customFields = customFieldsPayload;
    } else {
      delete data.customFields;
    }

    if (ownerOptions.length > 0) {
      data.ownerName = ownerOptions[0]?.label || '';
    }
    if (keeperOptions.length > 0) {
      data.keeperName = keeperOptions[0]?.label || '';
    }

    return data;
  };

  const submitForm = (data: ISearchFormValues) => {
    onSubmit(prepareData(data));
  };

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    const currentValues = getValues();
    if (name) {
      if (name === 'ownerName') {
        setOwnerOptions([]);
        currentValues.ownerUniqueId = '';
      }
      if (name === 'keeperName') {
        setKeeperOptions([]);
        currentValues.keeperUniqueId = '';
      }
      reset({ ...currentValues, [name]: '' });
    } else {
      const formValues = { ...getValues() };
      Object.keys(formValues).map((key: string) => {
        if (typeof values[key] === 'string') {
          values[key] = '';
        } else if (typeof values[key] === 'object') {
          values[key] = null;
        }
        return false;
      });
      reset(values);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    if (drawerClicked) {
      handleResetField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerClicked]);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)} id="registered_Firearms_search_form">
        <Grid
          container
          justifyContent="space-between"
          rowSpacing={1}
          sx={{ mb: { xs: 2, md: 0 } }}
        >
          <Grid item md={6} sm={12} xs={12}>
            <StyledPresetSelect
              options={presets ? preparePresetsList(presets) : []}
              setValue={(name: string, value: string) => {
                setValue('preset', value);
                handleResetField();
              }}
              onSelect={(val) => {
                if (!val) {
                  handleResetField();
                } else {
                  setPresetFilters(val, false, true);
                }
              }}
              name="preset"
              control={control}
              errors={errors}
              withValidation={false}
              withLabel={false}
            />
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant={EButtonVariants.outlined}
                id="registered_firearms-advanced_filters"
                label={t('registeredFirearms.accordion.advancedFilters.button')}
                onClick={() => setShowAdvanced(!showAdvanced)}
                fullWidth={!isDesktop}
                size={EButtonSizes.small}
              />
            </Box>
          </Grid>
        </Grid>

        {showAdvanced && (
          <StyledAdvancedSearch container spacing={2} columns={10}>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormInput
                name="serialNumber"
                label={t(ESearchFormFirearmLabels.serialNumber)}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormInput
                name="fireCycleId"
                label={t(ESearchFormFirearmLabels.fireCycleId)}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormInput
                name="exhibitNumber"
                label={t(ESearchFormFirearmLabels.exhibitNumber)}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(filterOutAncillaryStates(state))}
                name="stateUniqueId"
                setValue={setValue}
                label={t(ESearchFormFirearmLabels.stateUniqueId)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormSelect
                name="legalityUniqueId"
                options={mapDictionaryToOptionProp(legality)}
                setValue={setValue}
                label={t(ESearchFormFirearmLabels.legalityUniqueId)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(firearmType)}
                setValue={setValue}
                name="type"
                label={t(ESearchFormFirearmLabels.type)}
                canBeEmpty
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormAutocomplete
                name="make"
                options={mapSpecificDictionaryToOptionProp(make)}
                label={t(ESearchFormFirearmLabels.make)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormAutocomplete
                name="model"
                options={mapSpecificDictionaryToOptionProp(model)}
                label={t(ESearchFormFirearmLabels.model)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormAutocomplete
                name="manufacturer"
                options={mapSpecificDictionaryToOptionProp(manufacturer)}
                label={t(ESearchFormFirearmLabels.manufacturer)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormAutocomplete
                options={mapSpecificDictionaryToOptionProp(caliber)}
                name="caliber"
                label={t(ESearchFormFirearmLabels.caliber)}
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormInput
                name="productCode"
                label={t(ESearchFormFirearmLabels.productCode)}
                control={control}
                setValue={setValue}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2} md={5} sm={5} xs={10}>
              <FormSelect
                options={mapSpecificDictionaryToOptionProp(firearmAction)}
                name="action"
                label={t(ESearchFormFirearmLabels.action)}
                canBeEmpty
                setValue={setValue}
                control={control}
                errors={errors}
              />
            </Grid>
            {customFields.map((customField) => (
              <FormCustomField
                data={customField}
                control={control}
                setValue={setValue}
                errors={errors}
                key={customField.uniqueId}
                wrapper={customFieldWrapper}
                isSearch={true}
              />
            ))}

            <Grid item xs={12} container columns={10} columnSpacing={2}>
              <Grid item xs={10} sm={5} md={5} lg={7}>
                <StyledFormSelect
                  name="ownerUniqueId"
                  label={t(ESearchFormFirearmLabels.ownerName)}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  options={ownerOptions}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={10} sm={5} md={5} lg={3}>
                <Button
                  variant={EButtonVariants.outlined}
                  size={EButtonSizes.small}
                  label={t('registeredFirearms.accordion.selectLegalEntity.button')}
                  id="registered_firearms-select_legal_entity-owner"
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                  onClick={() =>
                    navigate(ERouteLinks.SelectLegalEntity, {
                      state: {
                        legalityUniqueId: routerState?.legalityUniqueId,
                        isFromMap: isFromMap || routerState?.isFromMap,
                        type: ESelectLE.owner,
                        form: getValues(),
                        returnPath,
                        options: { keeperOptions, ownerOptions },
                        context: 'firearms',
                        uniqueIds,
                        breadcrumbs: [
                          {
                            label: t('module.firearm.name'),
                            route: ERouteLinks.Firearms,
                          },
                          t('firearms.search.selectLegal.label'),
                        ],
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container columns={10} columnSpacing={2}>
              <Grid item xs={10} sm={5} md={5} lg={7}>
                <StyledFormSelect
                  name="keeperUniqueId"
                  label={t(ESearchFormFirearmLabels.keeperName)}
                  control={control}
                  errors={errors}
                  options={keeperOptions}
                  disabled={true}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={10} sm={5} md={5} lg={3}>
                <Button
                  variant={EButtonVariants.outlined}
                  size={EButtonSizes.small}
                  label={t('registeredFirearms.accordion.selectLegalEntity.button')}
                  id="registered_firearms-select_legal_entity-keeper"
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                  onClick={() =>
                    navigate(ERouteLinks.SelectLegalEntity, {
                      state: {
                        type: ESelectLE.keeper,
                        form: getValues(),
                        isFromMap: isFromMap || routerState?.isFromMap,
                        options: { keeperOptions, ownerOptions },
                        context: 'firearms',
                        uniqueIds,
                        returnPath,
                        breadcrumbs: [
                          {
                            label: t('module.firearm.name'),
                            route: ERouteLinks.Firearms,
                          },
                          t('firearms.search.selectLegal.label'),
                        ],
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item xs={12} lg={4}>
                <Button
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                  disabled={isSearchButtonDisabled}
                  id="registered_firearms-search"
                  label={t('registeredFirearms.accordion.search.button')}
                  type="submit"
                  fullWidth
                  sx={{ mt: 3.5 }}
                />
              </Grid>
            </Grid>
          </StyledAdvancedSearch>
        )}
      </form>
      <SelectedFilters
        name="registered_firearms"
        values={values}
        excludes={['preset', 'uniqueIds']}
        noRemovable={['uniqueIds']}
        labels={ESearchFormFirearmLabels}
        handleDelete={handleResetField}
        additionalSaveFilterValues={{
          owner: ownerOptions[0],
          keeper: keeperOptions[0],
          uniqueIds,
        }}
        type={ESearchFilterKeys.firearm}
        updatedQueryPath={
          getNewQueryPath ? getNewQueryPath(prepareData(getValues())) : undefined
        }
        additionalPath={
          isFromMap || (routerState && routerState.isFromMap) ? '?fromMap=true' : ''
        }
        saveRoute={ERouteLinks.SaveFirearmsPreset.replace(':type', ESearchFilterKeys.firearm)}
        dictionaries={{
          stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
          legalityUniqueId: prepareFilters(legality, 'uniqueId', 'name'),
          make: prepareFilters(make, 'uniqueId', 'name'),
          model: prepareFilters(model, 'uniqueId', 'name'),
          manufacturer: prepareFilters(manufacturer, 'uniqueId', 'name'),
          caliber: prepareFilters(caliber, 'uniqueId', 'name'),
          action: prepareFilters(firearmAction, 'uniqueId', 'name'),
          type: prepareFilters(firearmType, 'uniqueId', 'name'),
          ownerName: prepareFilters(ownerOptions, 'value', 'label'),
          keeperName: prepareFilters(keeperOptions, 'value', 'label'),
        }}
        dictionariesToSave={['ownerUniqueId', 'keeperUniqueId']}
        showSaveFilters={showSaveFilter}
        customFields={customFields}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { RegisteredFirearmsSearchForm };
