import { IPaginator } from 'models/paginator';

export interface ILanguage {
  uniqueId: string;
  name: string;
  code: string;
  defaultLanguage: boolean;
}

export interface ILanguagesResponse extends IPaginator {
  content: ILanguage[];
}

export enum ELanguageUniqueId {
  EN = '34c4897a-29de-4e2a-a624-5bb71ec0e421',
  PL = '9cf7467c-e7f4-4a25-9767-79a2d9461746',
}
