import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchUserFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { initialUserSearchFormValues, ISearchUserFormValues } from 'models/form';
import { FormSelect } from 'components/molecules/FormSelect';
import { useRoles } from 'pages/RolesPage/hooks';
import { EPerPages } from 'models/table';
import { prepareFilters } from 'helpers/filters';
import { useTranslations } from 'hooks/useTranslations';
import { IRole } from 'models/role';
import { StyledSearchForm } from '../../../theme/styles';

enum ESearchFormLabels {
  email = 'users.email.label',
  firstName = 'users.firstName.label',
  lastName = 'users.lastName.label',
  phoneNumber = 'users.phoneNumber.label',
  status = 'users.status.label',
  role = 'users.role.label',
}

interface IUserSearchForm {
  onSubmit: (arg: ISearchUserFormValues) => void;
  initialSearchForm: ISearchUserFormValues;
  isSearchButtonDisabled?: boolean;
}

export enum EUserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  REMOVED = 'REMOVED',
}

const UserSearchForm = ({
  onSubmit,
  initialSearchForm,
  isSearchButtonDisabled = false,
}: IUserSearchForm) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ISearchUserFormValues>({
    defaultValues: initialSearchForm,
  });
  const [values, setValues] = useState<ISearchUserFormValues>(initialSearchForm);
  const [roles, setRoles] = useState<IRole[]>([]);
  const { getRolesData } = useRoles();
  const { t } = useTranslations();
  const statusData = [
    { label: t('general.active.label'), value: EUserStatus.ACTIVE },
    { label: t('general.inactive.label'), value: EUserStatus.INACTIVE },
    { label: t('users.blocked.label'), value: EUserStatus.BLOCKED },
    { label: t('users.removed.label'), value: EUserStatus.REMOVED },
  ];

  useEffect(
    () => {
      (async function getRolesDataInit() {
        const rolesData = await getRolesData(1, EPerPages.perPage50);
        if (rolesData) {
          setRoles(rolesData.content);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchUserFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setValues(initialSearchForm);
    reset(initialSearchForm);
    handleSubmit(onSubmit)();
  }, [initialSearchForm]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialUserSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledSearchForm
          container
          justifyContent="space-between"
          spacing={2}
          columns={10}
          sx={{ mb: { xs: 2, md: 0 } }}
        >
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="email"
              label={t(ESearchFormLabels.email)}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="firstName"
              label={t(ESearchFormLabels.firstName)}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="lastName"
              label={t(ESearchFormLabels.lastName)}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="phoneNumber"
              label={t(ESearchFormLabels.phoneNumber)}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormSelect
              options={roles.map((role) => ({
                label: role.name,
                value: role.uniqueId,
              }))}
              name="roleUniqueId"
              setValue={setValue}
              canBeEmpty={true}
              label={t(ESearchFormLabels.role)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormSelect
              options={statusData}
              name="status"
              setValue={setValue}
              canBeEmpty={true}
              label={t(ESearchFormLabels.status)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              disabled={isSearchButtonDisabled}
              label={t('users.search.button')}
              type="submit"
              fullWidth={true}
              sx={{ mt: 3.5 }}
            />
          </Grid>
        </StyledSearchForm>
      </form>
      <SelectedFilters
        name="user"
        values={values}
        excludes={['removed']}
        labels={ESearchUserFormLabelsTypes}
        handleDelete={handleResetField}
        dictionaries={{
          status: prepareFilters(statusData, 'value', 'label'),
          roleUniqueId: prepareFilters(roles, 'uniqueId', 'name'),
        }}
        noPreset
        showSaveFilters={false}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { UserSearchForm };
