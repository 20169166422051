import { IPaginator } from 'models/paginator';
import { ILegalEntityCustomField } from 'components/organisms/LegalEntityDetails';

export interface IFirearmGroup {
  groupUniqueId: string;
  groupName: string;
  firearms: IFirearm[];
}

export interface IFirearm {
  uniqueId: string;
  id: string;
  serialNumber: string;
  fireCycleId: string;
  exhibitNumber: string;
  keeper: string;
  owner: string;
  state: string;
  make: string;
  type: string;
  action: string;
  frtId: string;
  frtUniqueId: string;
  manufacturedAt: string;
  manufacturer: string;
  model: string;
  externalReferenceNumber: string;
  caliber: string;
  latitude: number | null;
  longitude: number | null;
  legality: string;
  firearmModel: {
    uniqueId: string;
    make: string;
    manufacturer: string;
    model: string;
  };
  firearmSpecification: {
    uniqueId: string;
    family: string;
    legalityUniqueId: string;
    caliber: string;
    shot: string;
    barrelLength: string;
    barrelType: string;
    level: string;
  };
  activityUniqueId: string;
  stateUniqueId: string;
  legalityUniqueId: string;
  customFields?: ILegalEntityCustomField[];
}

export interface IFirearmsResponse extends IPaginator {
  content: IFirearm[];
}

export interface ISingleFirearm {
  uniqueId: string;
  serialNumber: string;
  fireCycleId: string;
  exhibitNumber: string;
  make: string;
  model: string;
  manufacturer: string;
  caliber: string;
  owner: string;
  keeper: string;
  state: string;
  stateUniqueId: string;
  legality: string;
  legalityUniqueId: string;
  activityUniqueId: string;
  customFields?: ILegalEntityCustomField[];
}

export interface IFRTResponse extends IPaginator {
  content: ISingleFRT[];
}

export interface ISingleFRT {
  id: string;
  make: string;
  model: string;
  manufacturer: string;
  action: string;
  type: string;
  country: string;
  frtId: string;
}

export enum EFirearmState {
  OWNED = 'Owned',
  EXPORTED = 'Exported',
  ASSIGNED = 'Assigned',
  LEASED = 'Leased',
  RECOVERED = 'Recovered',
  LOST = 'Lost',
  SEIZED = 'Seized',
  START = 'Start',
  DESTROYED = 'Destroyed',
  DISABLED = 'Disabled',
}

export enum EFirearmStateId {
  OWNED = 'de37bdfc-069e-11ed-b939-0242ac120002',
  EXPORTED = 'de37c1e4-069e-11ed-b939-0242ac120002',
  ASSIGNED = 'de37c306-069e-11ed-b939-0242ac120002',
  LEASED = 'de37c428-069e-11ed-b939-0242ac120002',
  RECOVERED = 'de37c52c-069e-11ed-b939-0242ac120002',
  LOST = 'de37c63a-069e-11ed-b939-0242ac120002',
  SEIZED = 'de37c748-069e-11ed-b939-0242ac120002',
  DESTROYED = '95e3c28a-8ac8-11ee-b9d1-0242ac120002',
  DISABLED = '3a8da198-8abf-11ee-b9d1-0242ac120002',
}
