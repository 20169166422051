import React, { useEffect } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { ESearchAncillaryGroupsFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import {
  initialLegalEntityAncillaryGroupSearchFormValues,
  ISearchLegalEntityAncillaryGroupFormValues,
} from 'models/form';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { EDictionaryTypes } from 'models/dictionary';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { EIconTypes } from 'constants/Icons';
import { EArtAncillaryType, EArtAncillaryTypeName } from 'constants/ArtAccessoryType';

interface ISearchForm {
  onSubmit: (arg: ISearchLegalEntityAncillaryGroupFormValues) => void;
  addGroup: () => void;
  isSearchButtonDisabled: boolean;
}

const AncillaryGroupsSearchForm = ({
  onSubmit,
  addGroup,
  isSearchButtonDisabled = false,
}: ISearchForm) => {
  const { loadDictionaries, artAncillaryOptions } = useLegalEntities();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ISearchLegalEntityAncillaryGroupFormValues>({
    defaultValues: initialLegalEntityAncillaryGroupSearchFormValues,
  });
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { hasPermissions } = usePermission();

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([EDictionaryTypes.ArtAccessoryType]);
    })();
  }, [selectedLanguage]);

  const getAncillaryType = (value: string) => {
    switch (value) {
      case EArtAncillaryType.ACCESSORIES:
        return EArtAncillaryTypeName.ACCESSORY.toLocaleUpperCase();
      case EArtAncillaryType.AMMUNITION:
        return EArtAncillaryTypeName.AMMUNITION.toLocaleUpperCase();
      case EArtAncillaryType.COMPONENTS:
        return EArtAncillaryTypeName.COMPONENTS.toLocaleUpperCase();
      default:
        return '';
    }
  };

  const submit = async (data: any) => {
    const type = getAncillaryType(data.ancillaryType);
    if (!type) {
      onSubmit({ ...data });
    } else {
      onSubmit({ ancillaryType: type });
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submit)}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item lg={6} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAncillaryOptions)}
              name="ancillaryType"
              label={t(ESearchAncillaryGroupsFormLabelsTypes.ancillary)}
              canBeEmpty={true}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} sx={{ marginTop: '8px' }}>
            <Button
              variant={EButtonVariants.outlined}
              size={EButtonSizes.small}
              label={t('legalEntities.individual.accordion.search.button')}
              disabled={isSearchButtonDisabled}
              type="submit"
              fullWidth={true}
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} sx={{ marginTop: { sm: '8px', xs: 0 } }}>
            {hasPermissions(EPermission.MANAGE_LE) && (
              <Button
                label={t('legalEntityDetails.ancillaryGroups.accordion.addGroup.button')}
                variant={EButtonVariants.contained}
                icon={EIconTypes.plus}
                onClick={addGroup}
                size={EButtonSizes.small}
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      </form>
    </SearchWrapper>
  );
};

export { AncillaryGroupsSearchForm };
