import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material';
import { mapSortTypeToLabel } from 'pages/DashboardPage/helpers';
import { EDashboardSortType } from 'models/dashboard';
import { useTranslations } from 'hooks/useTranslations';
import { StyledSelect } from 'pages/DashboardPage/styles';

interface IDashboardSortSelect {
  selectedSortType: EDashboardSortType;
  setSelectedSortType: (sortType: EDashboardSortType) => void;
}

export const DashboardSortSelect = ({
  selectedSortType,
  setSelectedSortType,
}: IDashboardSortSelect) => {
  const { t } = useTranslations();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedSortType(event.target.value as EDashboardSortType);
  };

  return (
    <StyledSelect value={selectedSortType} onChange={handleChange}>
      {Object.values(EDashboardSortType).map((sortType) => (
        <MenuItem value={sortType}>{t(mapSortTypeToLabel(sortType))}</MenuItem>
      ))}
    </StyledSelect>
  );
};
