import React, { PropsWithChildren, useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { Typography } from '@mui/material';
import { slugify } from 'helpers/slugify';
import { useQuery } from 'hooks/useQuery';
import { useDrawerClicked } from 'state/contexts/drawer';
import {
  StyledAccordion,
  StyledAccordionCounter,
  StyledAccordionDetails,
  StyledAccordionSubtitle,
  StyledAccordionSummary,
} from './styles';

export interface IAccordion extends PropsWithChildren<{}> {
  id?: string;
  title: string;
  onChange?: (name: string) => (event: React.SyntheticEvent, expanded: boolean) => void;
  name?: string;
  expanded?: boolean;
  alwaysExpanded?: boolean;
  hideExpandIcon?: boolean;
  counter?: string;
  subtitle?: string;
}

const Accordion = ({
  id,
  children,
  title,
  onChange,
  name = slugify(title),
  expanded = false,
  alwaysExpanded = false,
  hideExpandIcon = false,
  counter,
  subtitle,
}: IAccordion) => {
  const [queryExpanded, setQueryExpanded] = useState<boolean>(false);
  const { drawerClicked, setDrawerClicked } = useDrawerClicked();

  const query = useQuery();
  const currentExpandQueryParamValue: string | null = query.get('expand');

  const nameIsEqualToParamValue = () =>
    !!name && !!currentExpandQueryParamValue && name === currentExpandQueryParamValue;

  useEffect(() => {
    if (nameIsEqualToParamValue()) {
      expanded = true;
    }
  }, [name]);

  useEffect(() => {
    if (expanded || alwaysExpanded) {
      setQueryExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    setQueryExpanded(expanded || alwaysExpanded);
  }, [expanded]);

  useEffect(() => {
    if (drawerClicked && !nameIsEqualToParamValue() && !alwaysExpanded) {
      setQueryExpanded(false);
      setDrawerClicked(false);
    }
  }, [drawerClicked, name]);

  const handleChange = () => {
    setQueryExpanded((prevState) => !prevState || alwaysExpanded);
    if (onChange) {
      onChange(name);
    }
  };

  return (
    <StyledAccordion expanded={queryExpanded} onChange={handleChange}>
      <StyledAccordionSummary
        expandIcon={
          !alwaysExpanded && !hideExpandIcon ? (
            <Button variant={EButtonVariants.outlined} icon={EIconTypes.dropdownDown} />
          ) : (
            <span />
          )
        }
        id={id || slugify(title)}
        sx={alwaysExpanded ? { cursor: 'default !important' } : {}}
      >
        <Typography variant="h5">{title}</Typography>
        {counter && <StyledAccordionCounter>{counter}</StyledAccordionCounter>}
        {subtitle && (
          <StyledAccordionSubtitle expanded={queryExpanded} variant="subtitle1">
            {subtitle}
          </StyledAccordionSubtitle>
        )}
      </StyledAccordionSummary>
      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};

export { Accordion };
