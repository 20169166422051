import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { FormInput } from 'components/molecules/FormInput';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { Box, Typography } from '@mui/material';
import { ERouteLinks } from 'models/route';
import { ESearchFilterKeys, ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { prepareFilters } from 'helpers/filters';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import {
  IFormElementOptionProp,
  initialAncillariesSearchFormValues,
  ISearchAncillariesFormValues,
} from 'models/form';
import { useLang } from 'models/langContext';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import {
  filterHostTypeByAccessoryType,
  getFieldsByAccessoriesType,
  isEmptyObject,
} from 'pages/AncillariesPage/helpers';
import {
  EAncillaryFilterTypes,
  EArtAccessoryType,
  EArtAncillaryExpandType,
  EArtAncillaryType,
  EArtAncillaryTypeNameLowerCase,
} from 'constants/ArtAccessoryType';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { formatDateTimeToIso } from 'utils/date';
import { ISearchFormCustomFieldValue } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { getSearchFilter } from 'requests/searchFilter';
import { ISearchParams } from 'pages/UsersPage';
import { isEmpty } from 'utils/search';
import { useDrawerClicked } from 'state/contexts/drawer';
import { IAncillary } from 'models/ancillaries';
import {
  StyledAdvancedSearch,
  StyledFormSelect,
  StyledPresetSelect,
  StyledSubtitle,
} from './styles';

export enum ESearchFormLabels {
  ancillaryTypeUniqueId = 'accessoriesTable.accordion.accessoryType.label',
  fireCycleId = 'accessoriesTable.accordion.firecycleId.label',
  serialNumber = 'accessoriesTable.accordion.serialNumber.label',
  exhibitNumber = 'accessoriesTable.accordion.exhibitNumber.label',
  manufacturerUniqueId = 'accessoriesTable.accordion.accessoryManufacturer.label',
  productName = 'accessoriesTable.accordion.productName.label',
  calibreUniqueId = 'accessoriesTable.accordion.calibre.label',
  hostTypeUniqueId = 'accessoriesTable.accordion.hostType.label',
  magnificationUniqueId = 'accessoriesTable.accordion.magnification.label',
  utilityUniqueId = 'accessoriesTable.accordion.utility.label',
  reticleUniqueId = 'accessoriesTable.accordion.reticle.label',
  lightColourUniqueId = 'accessoriesTable.accordion.lightColour.label',
  compositionUniqueId = 'accessoriesTable.accordion.composition.label',
  accessoryCapacity = 'accessoriesTable.accordion.capacity.label',
  proofHouseUniqueId = 'accessoriesTable.accordion.proofMark.label',
  stateUniqueId = 'accessoriesTable.accordion.accessoryState.label',
  legalityUniqueId = 'accessoriesTable.accordion.accessoryLegality.label',
  owner = 'accessoriesTable.accordion.owner.label',
  keeper = 'accessoriesTable.accordion.keeper.label',
  ownerUniqueId = 'accessoriesTable.accordion.owner.label',
  keeperUniqueId = 'accessoriesTable.accordion.keeper.label',
}

export enum ESelectLE {
  keeper = 'keeper',
  owner = 'owner',
}

interface ISearchForm {
  onSubmit: (arg: any) => void;
  getNewQueryPath?: (arg: any) => string;
  ownerOptions: IFormElementOptionProp[];
  setOwnerOptions: (opt: IFormElementOptionProp[]) => void;
  keeperOptions: IFormElementOptionProp[];
  setKeeperOptions: (opt: IFormElementOptionProp[]) => void;
  showAdvanced?: boolean;
  accessoriesUniqueIds?: string[];
  isFromMap?: boolean;
  returnPath: string;
  setShowAdvanced?: (val: boolean) => void;
  initialFormValues?: ISearchAncillariesFormValues;
  customFields?: ICustomFieldByObjectType[];
  searchAncillaryFromLE?: boolean;
  isSearchButtonDisabled?: boolean;
  addAncillaryToGroupContext?: boolean;
  selectedAncillaries?: IAncillary[];
}

const customFieldWrapper = (children: React.ReactNode) => (
  <Grid item lg={2.5} md={3} sm={5} xs={10}>
    {children}
  </Grid>
);

const AccessoriesSearchForm = ({
  ownerOptions,
  keeperOptions,
  setOwnerOptions,
  setKeeperOptions,
  showAdvanced = true,
  setShowAdvanced = () => {},
  accessoriesUniqueIds,
  isFromMap = false,
  returnPath,
  onSubmit,
  getNewQueryPath,
  searchAncillaryFromLE = false,
  initialFormValues = initialAncillariesSearchFormValues,
  customFields = [],
  isSearchButtonDisabled = false,
  addAncillaryToGroupContext = false,
  selectedAncillaries,
}: ISearchForm) => {
  const [values, setValues] = useState<ISearchAncillariesFormValues>({
    ...initialFormValues,
    accessoriesUniqueIds,
  });
  const [accessFields, setAccessFields] = useState<string[]>([]);
  const [accessoryManufacturer, setAccessoryManufacturer] = useState<IDictionary[] | null>(
    null,
  );
  const [accessoryHostType, setAccessoryHostType] = useState<IDictionary[] | null>(null);
  const { state: routerState }: any = useLocation();
  const { t } = useTranslations();
  const { drawerClicked } = useDrawerClicked();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<ISearchAncillariesFormValues>({
    defaultValues: initialFormValues,
  });

  const { isDesktop } = useThemeBreakpoint();
  const { selectedLanguage } = useLang();
  const [searchParams] = useSearchParams();
  const presetId =
    searchParams.get('presetId') ||
    searchParams.get('preset') ||
    routerState?.presetAncillaries;
  const expandParam = searchParams.get('expand');
  const watchPreset = watch('preset');

  const {
    loadDictionaries,
    legality,
    state,
    artAccessoryCalibre,
    artAccessoryComposition,
    artAccessoryHostType,
    artAccessoryLightColour,
    artAccessoryMagnification,
    artAccessoryReticle,
    artAccessoryType,
    artAccessoryUtility,
    artAccessoryProofHouse,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
  } = useAncillaries();

  const { getFilterPresets, presets, getPresetValues, preparePresetsList } =
    useFilterPresets();
  const watchAccessoryTypeUniqueId = watch('ancillaryTypeUniqueId');
  const [showSaveFilter, setShowSaveFilter] = useState(false);

  const setPresetFilters = async (
    selectedPreset: string,
    isFromEmailLink: boolean,
    isClickedManually: boolean = false,
    isInitialLoad: boolean = false,
  ) => {
    const handleValues = async () => {
      if (isFromEmailLink) {
        const filter = await getSearchFilter(selectedPreset);
        if (filter) {
          const queryValues: ISearchParams = {};
          filter.values.forEach((it) => {
            queryValues[it.key] = it.value;
          });
          return queryValues;
        }
      }
      return getPresetValues(selectedPreset);
    };

    const initialValues = isInitialLoad
      ? { ...initialFormValues, ...routerState?.formValues }
      : initialFormValues;

    const presetValues: any = {
      ...(isClickedManually ? initialAncillariesSearchFormValues : initialValues),
      preset: selectedPreset,
      ...(await handleValues()),
    };

    if (isClickedManually) {
      customFields
        .filter((it) => it.searchCriteria)
        .forEach((it) => {
          if (it.customFieldType === ECustomFieldType.DATE) {
            presetValues[`from_${it.name}`] = '';
            presetValues[`to_${it.name}`] = '';
          } else {
            presetValues[it.name] = '';
          }
        });
    }

    if (presetValues.ownerUniqueId && presetValues.ownerName) {
      setOwnerOptions([
        {
          label: presetValues.ownerName,
          value: presetValues.ownerUniqueId,
        },
      ]);
    }

    if (presetValues.keeperUniqueId && presetValues.keeperName) {
      setKeeperOptions([
        {
          label: presetValues.keeperName,
          value: presetValues.keeperUniqueId,
        },
      ]);
    }

    if (presetValues.ancillaryTypeUniqueId) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      accessoryTypeChange(presetValues.ancillaryTypeUniqueId);
    }
    setValues(presetValues);
    if (selectedPreset !== '') {
      setShowAdvanced(true);
    }
    reset(presetValues);

    if (isClickedManually) {
      await handleSubmit(onSubmit)();
    }
  };

  const setLegalEntity = () => {
    const formValues = {
      ...initialFormValues,
      ...routerState?.formValues,
    };

    if (routerState?.type && routerState?.legalEntity) {
      const legalName = routerState?.legalEntity?.['organization.organizationName']
        ? routerState?.legalEntity?.['organization.organizationName']
        : `${routerState?.legalEntity?.['individual.firstName']} ${routerState?.legalEntity?.['individual.lastName']}`;
      const option = [
        {
          label: legalName,
          value: routerState.legalEntity.id,
        },
      ];
      if (routerState.type === ESelectLE.keeper) {
        setKeeperOptions(option);
        formValues.keeperName = legalName;
        if (routerState?.options?.ownerOptions) {
          setOwnerOptions(routerState.options.ownerOptions);
        }
      } else {
        setOwnerOptions(option);
        formValues.ownerName = legalName;
        if (routerState?.options?.keeperOptions) {
          setKeeperOptions(routerState.options.keeperOptions);
        }
      }
      formValues[routerState?.type === ESelectLE.owner ? 'ownerUniqueId' : 'keeperUniqueId'] =
        routerState.legalEntity.id;
    }
    setValues(formValues);
    setShowAdvanced(true);
    reset(formValues);
  };

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchAncillariesFormValues);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.Legality,
        EDictionaryTypes.State,
        EDictionaryTypes.ArtAccessoryCalibre,
        EDictionaryTypes.ArtaccessoryComposition,
        EDictionaryTypes.ArtAccessoryHostType,
        EDictionaryTypes.ArtAccessoryLightColour,
        EDictionaryTypes.ArtAccessoryMagnification,
        EDictionaryTypes.ArtAccessoryManufacturerFlashEliminator,
        EDictionaryTypes.ArtAccessoryManufacturerLaserLightModule,
        EDictionaryTypes.ArtAccessoryManufacturerMagazine,
        EDictionaryTypes.ArtAccessoryManufacturerOpticalSight,
        EDictionaryTypes.ArtAccessoryManufacturerSuppressor,
        EDictionaryTypes.ArtAccessoryReticle,
        EDictionaryTypes.ArtAccessoryType,
        EDictionaryTypes.ArtAccessoryUtility,
        EDictionaryTypes.ArtAccessoryProofHouse,
        EDictionaryTypes.ManufacturerCountry,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    if (
      routerState?.formValues &&
      routerState?.module === EArtAncillaryTypeNameLowerCase.ACCESSORY
    ) {
      const formValues = {
        ...routerState.formValues,
        legalityUniqueId: routerState?.legalityUniqueId,
        keeperUniqueId: routerState?.options?.keeperOptions[0]?.value || '',
        ownerUniqueId: routerState?.options?.ownerOptions[0]?.value || '',
      };
      setValues(formValues);
      reset(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function init() {
      await getFilterPresets(ESearchFilterTypes.ancillaries, EArtAncillaryType.ACCESSORIES);

      if (routerState?.options?.keeperOptions.length) {
        setKeeperOptions(routerState.options.keeperOptions);
      }

      if (routerState?.options?.ownerOptions.length) {
        setOwnerOptions(routerState.options.ownerOptions);
      }

      if (presetId && expandParam === EArtAncillaryExpandType.ACCESSORIES) {
        await setPresetFilters(presetId, true);
        await handleSubmit(onSubmit)();
      }

      if (
        routerState?.presetAncillaries &&
        routerState?.ancillaryFilterType === EAncillaryFilterTypes.ACCESSORY
      ) {
        setValue('preset', routerState.presetAncillaries);
      }

      if (!isEmptyObject(routerState?.formValues)) {
        setShowAdvanced(true);
      }
      if (routerState?.legalEntity) {
        setLegalEntity();
      }
      if (
        routerState?.module === EArtAncillaryTypeNameLowerCase.AMMUNITION ||
        routerState?.module === EArtAncillaryTypeNameLowerCase.COMPONENTS
      ) {
        reset(values);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareData = (data: ISearchAncillariesFormValues): ISearchAncillariesFormValues => {
    const customFieldsPayload: ISearchFormCustomFieldValue[] = [];

    Object.keys(data).map((key) => {
      const isDateCF = key.startsWith('from_') || key.startsWith('to_');

      if (!isDateCF) {
        const customField = customFields.find((item) => item.name === key);
        if (customField && data[key]) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool = data[key] === '1';
          }

          customFieldsPayload.push(customFieldItem);
          delete data[key];
        }
      } else {
        const customFieldKey = key.startsWith('from_')
          ? key.replace('from_', '')
          : key.replace('to_', '');

        const customField = customFields.find((item) => item.name === customFieldKey);

        if (customField && (data[`from_${customFieldKey}`] || data[`to_${customFieldKey}`])) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (data[`from_${customFieldKey}`]) {
            customFieldItem.dateFrom =
              data[`from_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`from_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          if (data[`to_${customFieldKey}`]) {
            customFieldItem.dateTo =
              data[`to_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`to_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          customFieldsPayload.push(customFieldItem);
          if (data[`from_${customFieldKey}`]) {
            delete data[`from_${customFieldKey}`];
          }
          if (data[`to_${customFieldKey}`]) {
            delete data[`to_${customFieldKey}`];
          }
        }
      }
      return false;
    });

    if (customFieldsPayload?.length) {
      data.customFields = customFieldsPayload;
    } else {
      delete data.customFields;
    }

    if (ownerOptions.length > 0) {
      data.ownerName = ownerOptions[0]?.label || '';
    }
    if (keeperOptions.length > 0) {
      data.keeperName = keeperOptions[0]?.label || '';
    }

    return data;
  };

  const submitForm = (data: ISearchAncillariesFormValues) => {
    onSubmit({ ...prepareData(data), preset: undefined, formSubmitted: true });
  };

  const setAccessoryManufacturerDictionaryData = (accessoriesTypeId: EArtAccessoryType) => {
    switch (accessoriesTypeId) {
      case EArtAccessoryType.MAGAZINES:
        setAccessoryManufacturer(artAccessoryManufacturerMagazine);
        break;
      case EArtAccessoryType.LASER_LIGHT_MODULES:
        setAccessoryManufacturer(artAccessoryManufacturerLaserLightModule);
        break;
      case EArtAccessoryType.OPTICAL_SIGHTS:
        setAccessoryManufacturer(artAccessoryManufacturerOpticalSight);
        break;
      case EArtAccessoryType.FLASH_ELIMINATORS:
        setAccessoryManufacturer(artAccessoryManufacturerFlashEliminator);
        break;
      case EArtAccessoryType.SUPPRESSORS:
        setAccessoryManufacturer(artAccessoryManufacturerSuppressor);
        break;
      default:
        setAccessoryManufacturer(null);
        break;
    }
  };

  const accessoryTypeChange = (value: any) => {
    const currentValues = getValues();
    const formValues = {
      ...initialAncillariesSearchFormValues,
      ancillaryTypeUniqueId: value,
      preset: currentValues.preset,
      ownerUniqueId: currentValues.ownerUniqueId,
      keeperUniqueId: currentValues.keeperUniqueId,
    };
    setValues(formValues);
    if (value) {
      const fields = getFieldsByAccessoriesType(value);
      setAccessFields(fields);
      setAccessoryManufacturerDictionaryData(value);
      setAccessoryHostType(
        filterHostTypeByAccessoryType(value as EArtAccessoryType, artAccessoryHostType),
      );
    } else {
      setAccessFields([]);
      setAccessoryManufacturer(null);
      setAccessoryHostType(null);
    }
  };

  useEffect(() => {
    if (
      watchAccessoryTypeUniqueId !== undefined &&
      watchAccessoryTypeUniqueId !== null &&
      watchAccessoryTypeUniqueId !== ''
    ) {
      setAccessoryHostType(
        filterHostTypeByAccessoryType(
          watchAccessoryTypeUniqueId as EArtAccessoryType,
          artAccessoryHostType,
        ),
      );
    } else {
      setAccessoryHostType([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAccessoryTypeUniqueId]);

  useEffect(() => {
    if (initialFormValues?.ancillaryTypeUniqueId) {
      const fields = getFieldsByAccessoriesType(initialFormValues.ancillaryTypeUniqueId);
      setAccessFields(fields);
      setAccessoryManufacturerDictionaryData(
        initialFormValues.ancillaryTypeUniqueId as EArtAccessoryType,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      Object.values(EArtAccessoryType).includes(
        watchAccessoryTypeUniqueId as EArtAccessoryType,
      )
    ) {
      const fields = getFieldsByAccessoriesType(watchAccessoryTypeUniqueId);
      setAccessFields(fields);
      setAccessoryManufacturerDictionaryData(watchAccessoryTypeUniqueId as EArtAccessoryType);
    }
    if (watchAccessoryTypeUniqueId === '') {
      setAccessFields([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchAccessoryTypeUniqueId]);

  const resetValues = () => {
    const formValues = { ...getValues() };
    Object.keys(formValues).map((key: string) => {
      if (typeof values[key] === 'string') {
        values[key] = '';
      } else if (typeof values[key] === 'object') {
        values[key] = null;
      }
      return false;
    });
    reset(values);
  };

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    const currentValues = getValues();
    if (name) {
      if (name === 'ancillaryTypeUniqueId') {
        setValue('ancillaryTypeUniqueId', '');
        resetValues();
        accessoryTypeChange(null);
        onSubmit(values);
        return;
      }
      if (name === 'ownerUniqueId') {
        setOwnerOptions([]);
        currentValues.ownerUniqueId = '';
      }
      if (name === 'keeperUniqueId') {
        setKeeperOptions([]);
        currentValues.keeperUniqueId = '';
      }
      reset({ ...currentValues, [name]: '' });
    } else {
      resetValues();
    }

    if (refreshResults) {
      onSubmit(values);
    }
  };

  useEffect(() => {
    if (drawerClicked) {
      handleResetField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerClicked]);

  useEffect(() => {
    if (
      isEmpty(values.ancillaryTypeUniqueId) &&
      isEmpty(values.fireCycleId) &&
      isEmpty(values.serialNumber) &&
      isEmpty(values.exhibitNumber) &&
      isEmpty(values.manufacturerUniqueId) &&
      isEmpty(values.productName) &&
      isEmpty(values.calibreUniqueId) &&
      isEmpty(values.hostTypeUniqueId) &&
      isEmpty(values.magnificationUniqueId) &&
      isEmpty(values.utilityUniqueId) &&
      isEmpty(values.reticleUniqueId) &&
      isEmpty(values.lightColourUniqueId) &&
      isEmpty(values.compositionUniqueId) &&
      isEmpty(values.accessoryCapacity) &&
      isEmpty(values.proofHouseUniqueId) &&
      isEmpty(values.stateUniqueId) &&
      isEmpty(values.legalityUniqueId) &&
      isEmpty(values.ownerUniqueId) &&
      isEmpty(values.keeperUniqueId)
    ) {
      setShowSaveFilter(false);
    } else {
      setShowSaveFilter(true);
    }
  }, [values]);

  useEffect(() => {
    (async function init() {
      if (watchPreset) {
        await setPresetFilters(watchPreset, false, false, true);
      } else {
        await setPresetFilters('', false, false, true);
      }
      await handleSubmit(onSubmit)();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialAncillariesSearchFormValues.ancillaryTypeUniqueId) {
      accessoryTypeChange(initialAncillariesSearchFormValues.ancillaryTypeUniqueId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialAncillariesSearchFormValues]);

  const checkDisabled = (fieldName: string) => !accessFields.includes(fieldName);

  const clearAfterAccessoryTypeChange = () => {
    const formValues = { ...getValues() };
    Object.keys(formValues).map((key: string) => {
      if (typeof values[key] === 'string') {
        values[key] = '';
      } else if (typeof values[key] === 'object') {
        values[key] = null;
      }
      return false;
    });
    values.ancillaryTypeUniqueId = formValues.ancillaryTypeUniqueId;
    reset(values);
    onSubmit(values);
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        {!searchAncillaryFromLE && (
          <Grid
            container
            justifyContent="space-between"
            sx={{ mb: { xs: 2, md: 0 } }}
            spacing={1}
          >
            <Grid item md={6} sm={12} xs={12}>
              <StyledPresetSelect
                options={
                  presets
                    ? preparePresetsList(
                        presets,
                        'ancillaries.accordion.selectFilterPreset.label',
                      )
                    : preparePresetsList([], 'ancillaries.accordion.selectFilterPreset.label')
                }
                name="preset"
                control={control}
                errors={errors}
                setValue={(name: string, value: string) => {
                  setValue('preset', value);
                  handleResetField();
                }}
                onSelect={(val) => {
                  if (!val) {
                    handleResetField();
                  } else {
                    setPresetFilters(val, false, true);
                  }
                }}
                withValidation={false}
                withLabel={false}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant={EButtonVariants.outlined}
                  label={t('ancillaries.accordion.advancedFilters.button')}
                  onClick={() => setShowAdvanced(!showAdvanced)}
                  fullWidth={!isDesktop}
                  size={EButtonSizes.small}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        {showAdvanced && (
          <StyledAdvancedSearch container spacing={2} columns={10}>
            <Grid item xs={12}>
              <StyledSubtitle item xs={3}>
                <Typography variant="subtitle2">
                  {t('ancillaries.accordion.subtilte.label')}
                </Typography>
              </StyledSubtitle>
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryType)}
                name="ancillaryTypeUniqueId"
                label={t(ESearchFormLabels.ancillaryTypeUniqueId)}
                setValue={(name: string, value: string) => {
                  setValue('ancillaryTypeUniqueId', value);
                  if (!value) {
                    accessoryTypeChange(null);
                    handleResetField();
                  }
                }}
                onSelect={clearAfterAccessoryTypeChange}
                canBeEmpty
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="fireCycleId"
                label={t(ESearchFormLabels.fireCycleId)}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="serialNumber"
                label={t(ESearchFormLabels.serialNumber)}
                control={control}
                errors={errors}
                disabled={checkDisabled('serialNumber')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="exhibitNumber"
                label={t(ESearchFormLabels.exhibitNumber)}
                control={control}
                errors={errors}
                disabled={checkDisabled('exhibitNumber')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                name="manufacturerUniqueId"
                options={mapDictionaryToOptionProp(accessoryManufacturer)}
                label={t(ESearchFormLabels.manufacturerUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('manufacturerUniqueId')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="productName"
                label={t(ESearchFormLabels.productName)}
                control={control}
                errors={errors}
                disabled={checkDisabled('productName')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryCalibre)}
                name="calibreUniqueId"
                label={t(ESearchFormLabels.calibreUniqueId)}
                control={control}
                errors={errors}
                setValue={setValue}
                disabled={checkDisabled('calibreUniqueId')}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(accessoryHostType)}
                name="hostTypeUniqueId"
                label={t(ESearchFormLabels.hostTypeUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('hostTypeUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryMagnification)}
                name="magnificationUniqueId"
                label={t(ESearchFormLabels.magnificationUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('magnificationUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryUtility)}
                name="utilityUniqueId"
                label={t(ESearchFormLabels.utilityUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('utilityUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryReticle)}
                name="reticleUniqueId"
                label={t(ESearchFormLabels.reticleUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('reticleUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryLightColour)}
                name="lightColourUniqueId"
                label={t(ESearchFormLabels.lightColourUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('lightColourUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryComposition)}
                name="compositionUniqueId"
                label={t(ESearchFormLabels.compositionUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('compositionUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormInput
                name="accessoryCapacity"
                label={t(ESearchFormLabels.accessoryCapacity)}
                control={control}
                errors={errors}
                disabled={checkDisabled('accessoryCapacity')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(artAccessoryProofHouse)}
                name="proofHouseUniqueId"
                label={t(ESearchFormLabels.proofHouseUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('proofHouseUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(state)}
                name="stateUniqueId"
                label={t(ESearchFormLabels.stateUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('stateUniqueId')}
                setValue={setValue}
              />
            </Grid>
            <Grid item lg={2.5} md={3} sm={5} xs={10}>
              <FormSelect
                options={mapDictionaryToOptionProp(legality)}
                name="legalityUniqueId"
                label={t(ESearchFormLabels.legalityUniqueId)}
                canBeEmpty
                control={control}
                errors={errors}
                disabled={checkDisabled('legalityUniqueId')}
                setValue={setValue}
              />
            </Grid>

            {customFields.map((customField) => (
              <FormCustomField
                data={customField}
                setValue={setValue}
                control={control}
                errors={errors}
                key={customField.uniqueId}
                wrapper={customFieldWrapper}
                isSearch={true}
              />
            ))}

            {new Array(4 - customFields.length >= 0 ? 4 - customFields.length : 0)
              .fill('')
              .map((index) => (
                <Grid item lg={2} md={5} sm={5} xs={10} key={index} />
              ))}

            <Grid item xs={12} container columns={10} columnSpacing={2}>
              <Grid item xs={10} sm={5} lg={7}>
                <StyledFormSelect
                  name="ownerUniqueId"
                  label={t(ESearchFormLabels.owner)}
                  control={control}
                  errors={errors}
                  options={ownerOptions}
                  disabled={checkDisabled('ownerUniqueId')}
                  setValue={setValue}
                />
              </Grid>
              <Grid item xs={10} sm={5} lg={3}>
                <Button
                  variant={EButtonVariants.outlined}
                  size={EButtonSizes.small}
                  label={t('ancillaries.accordion.selectLegalEntity.button')}
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                  onClick={() =>
                    navigate(ERouteLinks.SelectLegalEntity, {
                      state: {
                        legalityUniqueId: routerState?.legalityUniqueId,
                        type: ESelectLE.owner,
                        form: getValues(),
                        isFromMap: isFromMap || routerState?.isFromMap,
                        module: EArtAncillaryTypeNameLowerCase.ACCESSORY,
                        options: { keeperOptions, ownerOptions },
                        returnPath,
                        context: 'searchAncillaries',
                        ...(addAncillaryToGroupContext
                          ? {
                              ancillaryOption: EArtAncillaryType.ACCESSORIES,
                              selectedAncillaries,
                            }
                          : undefined),
                        accessoriesUniqueIds,
                        breadcrumbs: [
                          {
                            label: t('module.ancillaries.name'),
                            route: ERouteLinks.Ancillaries,
                          },
                          t('ancillaries.search.selectLegal.label'),
                        ],
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
            {!addAncillaryToGroupContext && (
              <Grid item xs={12} container columns={10} columnSpacing={2}>
                <Grid item xs={10} sm={5} lg={7}>
                  <StyledFormSelect
                    name="keeperUniqueId"
                    label={t(ESearchFormLabels.keeper)}
                    control={control}
                    errors={errors}
                    options={keeperOptions}
                    disabled={true}
                    setValue={setValue}
                  />
                </Grid>
                <Grid item xs={10} sm={5} lg={3}>
                  <Button
                    variant={EButtonVariants.outlined}
                    size={EButtonSizes.small}
                    label={t('ancillaries.accordion.selectLegalEntity.button')}
                    fullWidth
                    sx={{
                      mt: {
                        sm: 3.5,
                      },
                    }}
                    onClick={() =>
                      navigate(ERouteLinks.SelectLegalEntity, {
                        state: {
                          type: ESelectLE.keeper,
                          form: getValues(),
                          isFromMap: isFromMap || routerState?.isFromMap,
                          module: EArtAncillaryTypeNameLowerCase.ACCESSORY,
                          options: { keeperOptions, ownerOptions },
                          returnPath,
                          accessoriesUniqueIds,
                          context: 'searchAncillaries',
                          breadcrumbs: [
                            {
                              label: t('module.ancillaries.name'),
                              route: ERouteLinks.Ancillaries,
                            },
                            t('ancillaries.search.selectLegal.label'),
                          ],
                        },
                      })
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              container
              columns={10}
              columnSpacing={2}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Grid item xs={10} sm={5} lg={2}>
                <Button
                  variant={EButtonVariants.contained}
                  size={EButtonSizes.small}
                  label={t('ancillaries.accordion.search.button')}
                  disabled={isSearchButtonDisabled}
                  type="submit"
                  id="ancillaries_accessories_search-button"
                  fullWidth
                  sx={{
                    mt: {
                      sm: 3.5,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </StyledAdvancedSearch>
        )}
      </form>
      {showAdvanced && (
        <SelectedFilters
          name="accessories"
          values={values}
          excludes={['preset', 'uniqueIds', 'ownerName', 'keeperName']}
          noRemovable={['uniqueIds']}
          labels={ESearchFormLabels}
          additionalSaveFilterValues={{
            owner: ownerOptions[0],
            keeper: keeperOptions[0],
            accessoriesUniqueIds,
          }}
          handleDelete={handleResetField}
          type={ESearchFilterKeys.accessory}
          saveRoute={ERouteLinks.SaveAncillariesPreset.replace(
            ':type',
            ESearchFilterKeys.accessory,
          )}
          additionalPath={
            isFromMap || (routerState && routerState.isFromMap) ? '?fromMap=true' : ''
          }
          updatedQueryPath={
            getNewQueryPath ? getNewQueryPath(prepareData(getValues())) : undefined
          }
          dictionaries={{
            ancillaryTypeUniqueId: prepareFilters(artAccessoryType, 'uniqueId', 'name'),
            manufacturerUniqueId: prepareFilters(accessoryManufacturer, 'uniqueId', 'name'),
            hostTypeUniqueId: prepareFilters(artAccessoryHostType, 'uniqueId', 'name'),
            magnificationUniqueId: prepareFilters(
              artAccessoryMagnification,
              'uniqueId',
              'name',
            ),
            utilityUniqueId: prepareFilters(artAccessoryUtility, 'uniqueId', 'name'),
            reticleUniqueId: prepareFilters(artAccessoryReticle, 'uniqueId', 'name'),
            lightColourUniqueId: prepareFilters(artAccessoryLightColour, 'uniqueId', 'name'),
            compositionUniqueId: prepareFilters(artAccessoryComposition, 'uniqueId', 'name'),
            proofHouseUniqueId: prepareFilters(artAccessoryProofHouse, 'uniqueId', 'name'),
            stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
            legalityUniqueId: prepareFilters(legality, 'uniqueId', 'name'),
            calibreUniqueId: prepareFilters(artAccessoryCalibre, 'uniqueId', 'name'),
            ownerUniqueId: prepareFilters(ownerOptions, 'value', 'label'),
            keeperUniqueId: prepareFilters(keeperOptions, 'value', 'label'),
          }}
          dictionariesToSave={['ownerUniqueId', 'keeperUniqueId']}
          showSaveFilters={addAncillaryToGroupContext ? false : showSaveFilter}
          customFields={customFields}
          saveRouterStateAfterClearAll={addAncillaryToGroupContext}
          saveQueryParams={addAncillaryToGroupContext ? ['type'] : undefined}
        />
      )}
    </SearchWrapper>
  );
};

export { AccessoriesSearchForm };
