import React from 'react';
import { Typography } from '@mui/material';
import { colors } from 'theme/colors';
import { StyledWrapper } from './styles';

export enum EChipsVariants {
  primary = 'primary',
  success = 'success',
  success100 = 'success100',
  warning = 'warning',
  warning100 = 'warning100',
  error = 'error',
  error200 = 'error200',
  oneSign = 'oneSign',
  inactive = 'inactive',
}

export interface IChips {
  text: string | undefined;
  variant?: EChipsVariants;
  className?: string;
  onClick?: (...args: any) => void;
  id?: string;
  textId?: string;
}

export const Chips = ({
  text,
  variant = EChipsVariants.success,
  className = '',
  onClick = () => {},
  id,
  textId,
}: IChips) => {
  let backgroundColor: string;
  let textColor: string;
  let lineHeight: string = '14px';
  switch (variant) {
    case EChipsVariants.primary:
      textColor = colors.primary700;
      backgroundColor = colors.primary50;
      break;
    case EChipsVariants.oneSign:
      textColor = colors.white;
      backgroundColor = colors.primary400;
      lineHeight = '22px';
      break;
    case EChipsVariants.success:
      textColor = colors.success700;
      backgroundColor = colors.success50;
      break;
    case EChipsVariants.success100:
      textColor = colors.success900;
      backgroundColor = colors.success100;
      break;
    case EChipsVariants.warning:
      textColor = colors.warning800;
      backgroundColor = colors.warning50;
      break;
    case EChipsVariants.warning100:
      textColor = colors.warning900;
      backgroundColor = colors.warning100;
      break;
    case EChipsVariants.error:
      textColor = colors.error700;
      backgroundColor = colors.error100;
      break;
    case EChipsVariants.error200:
      textColor = colors.error900;
      backgroundColor = colors.error200;
      break;
    case EChipsVariants.inactive:
      textColor = colors.gray900;
      backgroundColor = colors.gray300;
      break;
    default:
      textColor = colors.success600;
      backgroundColor = colors.success50;
  }
  return (
    <StyledWrapper id={id} backgroundColor={backgroundColor} onClick={onClick}>
      <Typography
        id={textId}
        variant="overline"
        color={textColor}
        className={className}
        sx={{
          display: 'flex',
          alignItems: 'center',
          lineHeight: { lineHeight },
          letterSpacing: '0.08em',
        }}
      >
        {text}
      </Typography>
    </StyledWrapper>
  );
};
