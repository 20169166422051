import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApiKeys } from 'pages/ApiKeysPage/hooks';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { StyledPageTitle } from 'theme/styles';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { formatDate, getDateFromISO } from 'utils/date';
import { StyledDivider, StyledViewSectionTitle } from 'pages/ApiKeysPage/styles';
import { format } from 'date-fns';
import { useGlobalProperty } from 'models/globalPropertyContext';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { deleteApiKey } from 'requests/apiKeys';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';

const DeleteApiKeyPage = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { apiKey, getApiKeyHandler } = useApiKeys();
  const { shortDateFormat, longDateFormat } = useGlobalProperty();
  const { setAlert } = useAlert();

  useEffect(() => {
    if (id) {
      (async () => {
        await getApiKeyHandler(id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onDelete = async () => {
    if (apiKey) {
      try {
        await deleteApiKey(id);
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('deleteApiKey.success.text'),
        });
        navigate(ERouteLinks.ApiKeysPage);
      } catch (e: any) {
        console.error(e);
      }
    }
  };

  const renderExpiryDate = () => {
    if (!apiKey) {
      return null;
    }

    const date = getDateFromISO(apiKey.expiryDate);
    if (date === '') {
      return (
        <Chips
          text={t('deleteApiKey.state.perpetual.label')}
          variant={EChipsVariants.success100}
        />
      );
    }
    if (date > new Date()) {
      return format(date, shortDateFormat);
    }
    return (
      <Chips text={t('deleteApiKey.state.expired.label')} variant={EChipsVariants.error} />
    );
  };

  const renderContactPhone = () => {
    if (!apiKey) {
      return null;
    }
    return `00${apiKey?.phonePrefixValue?.[0]?.name} ${apiKey?.phoneNumber}`;
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('apiKeys.header'),
            route: ERouteLinks.ApiKeysPage,
          },
          t('deleteApiKey.header'),
        ]}
      />

      {apiKey && (
        <>
          <StyledPageTitle variant="h4" sx={{ marginBottom: '10px' }}>
            {t('deleteApiKey.header')}
          </StyledPageTitle>

          <Typography variant="body1" sx={{ mb: 2 }}>
            {t('deleteApiKey.info.text')}
          </Typography>

          <DetailsTemplate
            title={apiKey?.key}
            pageType={EDetailsPageTypes.view}
            disableActions={true}
          >
            <Grid container item xs={12} lg={12} rowGap="18px">
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.key.label')}
                </Typography>
                <Typography variant="body2">
                  <Chips text={apiKey.type} variant={EChipsVariants.primary} />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.user.label')}
                </Typography>
                <Typography variant="body2">{apiKey?.username ?? '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.expiryDate.label')}
                </Typography>
                <Typography variant="body2">{renderExpiryDate()}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.lastUsed.label')}
                </Typography>
                <Typography variant="body2">
                  {apiKey?.lastUsedDate
                    ? formatDate(apiKey.lastUsedDate, longDateFormat)
                    : '-'}
                </Typography>
              </Grid>

              {apiKey?.description && (
                <Grid item xs={12} container direction="column">
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('viewApiKey.description.label')}
                  </Typography>
                  <Typography variant="body2">{apiKey?.description ?? '-'}</Typography>
                </Grid>
              )}
            </Grid>

            <StyledDivider />

            <StyledViewSectionTitle variant="subtitle1">
              {t('viewApiKey.administrator.title')}
            </StyledViewSectionTitle>

            <Grid container item xs={12} lg={12} rowGap="18px">
              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactEmail.label')}
                </Typography>
                <Typography variant="body2">{apiKey?.contactEmail ?? '-'}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactName.label')}
                </Typography>
                <Typography variant="body2">{`${apiKey?.firstName} ${apiKey.lastName}`}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('viewApiKey.contactPhone.label')}
                </Typography>
                <Typography variant="body2">{renderContactPhone()}</Typography>
              </Grid>
            </Grid>
          </DetailsTemplate>

          <Grid container justifyContent="flex-start" sx={{ mt: 4 }}>
            <Grid item xs={12} lg={6} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.yes.button')}
                  id="yes-button"
                  variant={EButtonVariants.contained}
                  sx={{ mr: 1 }}
                  onClick={() => onDelete()}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button
                  fullWidth
                  label={t('general.confirmation.no.button')}
                  id="no-button"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.ApiKeysPage)}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default DeleteApiKeyPage;
