import styled from '@emotion/styled';
import { css } from '@mui/material';

interface IStyledWrapper {
  backgroundColor: string;
}

const StyledWrapper = styled.div<IStyledWrapper>`
  display: inline-block;
  padding: 3px 10px;
  border-radius: 4px;
  margin-right: 5px;
  ${(props: IStyledWrapper) =>
    css`
      background-color: ${props.backgroundColor};
    `}
`;

export { StyledWrapper };
