import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { css } from '@mui/material';

interface IStyledChip {
  hasDelete?: boolean;
}

const StyledChip = styled.div<IStyledChip>`
  position: relative;
  background-color: ${colors.success50};
  height: 24px;
  border-radius: 4px;
  padding: 4px 24px 4px 8px;
  color: ${colors.success900};
  font-size: 12px;
  line-height: 16px;

  ${(props: IStyledChip) =>
    !props.hasDelete &&
    css`
      padding: 4px 8px;
    `}
  & span:first-of-type {
    font-weight: 600;
    padding-right: 4px;
  }

  & button,
  & button:hover {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 3px;
    right: 1px;
    cursor: pointer;
    border: none;
    background-color: ${colors.success50};
  }

  & button:hover,
  & button:active,
  & button:focus {
    background-color: initial;
    box-shadow: none;
  }
`;

export { StyledChip };
