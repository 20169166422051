import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { IShortTransaction } from 'models/transaction';
import { AttachmentForm } from 'components/organisms/AttachmentForm';
import { getAttachmentAvailableTransactionTypes } from 'requests/ancillaries';
import { EDictionaryTypes } from 'models/dictionary';
import { useLang } from 'models/langContext';
import { useAncillaries } from '../../../hooks';

const AmmunitionAddAttachmentPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [usedTransactions, setUsedTransactions] = useState<IShortTransaction[]>([]);
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { loadDictionaries, handleAddAttachment, transactionType } = useAncillaries();

  useEffect(() => {
    (async function init() {
      await loadDictionaries([EDictionaryTypes.TransactionType]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    (async function getAddAttachmentDataInit() {
      if (id) {
        const response = await getAttachmentAvailableTransactionTypes(id);
        if (response) {
          setUsedTransactions(response);
        }
      }
    })();
  }, [id]);

  const handleSubmit = async (data: any) => {
    const result = await handleAddAttachment(id, data);
    if (result) {
      navigate(
        `${ERouteLinks.ViewAmmunition.replace(':id', id as string)}?expand=attachments`,
      );
    }
  };

  const handleCancel = () => {
    navigate(`${ERouteLinks.ViewAmmunition.replace(':id', id as string)}?expand=attachments`);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.ammunition.name'),
            route: `${ERouteLinks.Ancillaries}?expand=ammunition`,
          },
          {
            label: t('ammunitionDetails.accordion.title.label'),
            route: `${ERouteLinks.ViewAmmunition.replace(
              ':id',
              id as string,
            )}?expand=attachments`,
          },
          t('ammunitionDetails.addAttachment.header'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('ammunitionDetails.addAttachment.header')}
      </StyledPageTitle>

      <FormWrapper>
        <AttachmentForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          usedTransactions={usedTransactions}
          transactionType={transactionType}
        />
      </FormWrapper>
    </>
  );
};

export { AmmunitionAddAttachmentPage };
