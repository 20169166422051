import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormSelect } from 'components/molecules/FormSelect';
import { useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import { FormInput } from 'components/molecules/FormInput';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { ITranslationKeys, SelectedFilters } from 'components/organisms/SelectedFilters';
import { prepareFilters } from 'helpers/filters';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { ELEAccordionsPageTypes, LEAccordionNames } from 'components/organisms/LEAccordions';
import {
  StyledAdvancedSearch,
  StyledPresetSelect,
} from 'pages/LegalEntitiesPage/IndividualSearchForm/styles';
import { Box } from '@mui/material';
import { formatDateTimeToIso, isDateAfter } from 'utils/date';
import { useTranslations } from 'hooks/useTranslations';
import { ERouteLinks } from 'models/route';
import { ESearchFilterKeys, ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { useLocation, useMatch, useParams, useSearchParams } from 'react-router-dom';
import { getUser as getUserById } from 'requests/user';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { useLang } from 'models/langContext';
import {
  ECustomFieldObjectType,
  ECustomFieldType,
  ICustomFieldByObjectType,
} from 'models/customField';
import FormCustomField from 'components/organisms/FormCustomField';
import { useAuth } from 'models/authContext';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { isEmpty } from 'utils/search';
import { useDrawerClicked } from 'state/contexts/drawer';
import { ISearchParams } from 'pages/UsersPage';
import { getSearchFilter } from 'requests/searchFilter';

export interface ICustomFieldForm {
  customFieldUniqueId: string;
  text?: string;
  bool?: boolean | string | null;
  date?: string | null;
  dateFrom?: string;
  dateTo?: string;
}

export interface ISearchFormCustomFieldValue extends ICustomFieldForm {
  customFieldType: ECustomFieldType;
  name?: string;
}

export interface ISearchFormValues {
  preset: string | undefined;
  firstname: string;
  lastName: string;
  city: string;
  birthdayFrom: string | Date | number | null;
  birthdayTo: string | Date | number | null;
  visibilityUniqueId: string;
  prefixGroupUniqueId: string;
  genderUniqueId: string;
  customFields?: ISearchFormCustomFieldValue[];

  [key: string]: any;
}

export enum ESearchFormLabels {
  firstname = 'searchForLegalEntity.legalEntityTable.firstName.column',
  lastName = 'searchForLegalEntity.legalEntityTable.lastName.column',
  city = 'searchForLegalEntity.legalEntityTable.city.column',
  birthdayFrom = 'searchForLegalEntity.legalEntityTable.dateOfBirthFrom.column',
  birthdayTo = 'searchForLegalEntity.legalEntityTable.dateOfBirthTo.column',
  visibilityUniqueId = 'searchForLegalEntity.legalEntityTable.visibility.column',
  genderUniqueId = 'searchForLegalEntity.legalEntityTable.gender.column',
  legalEntityType = 'searchForLegalEntity.legalEntityTable.LegalEntityType.column',
  prefixGroupUniqueId = 'searchForLegalEntity.legalEntityTable.legalEntityGroup.column',
}

export const initialValues: ISearchFormValues = {
  preset: '',
  firstname: '',
  lastName: '',
  city: '',
  birthdayFrom: '',
  birthdayTo: '',
  visibilityUniqueId: '',
  prefixGroupUniqueId: '',
  genderUniqueId: '',
  customFields: [],
};

interface IIndividualSearchForm {
  onSubmit: (arg: ISearchFormValues) => void;
  getNewQueryPath?: (arg: ISearchFormValues) => string;
  type: ELEAccordionsPageTypes;
  translationKeys?: ITranslationKeys;
  initialFormValues?: ISearchFormValues;
  isSearchParams?: boolean;
  isSearchButtonDisabled?: boolean;
  saveRouterStateAfterClearAll: boolean;
}

const customFieldWrapper = (children: React.ReactNode) => (
  <Grid item lg={2} md={5} sm={5} xs={10}>
    {children}
  </Grid>
);

const IndividualSearchForm = ({
  onSubmit,
  getNewQueryPath,
  type,
  translationKeys,
  initialFormValues = initialValues,
  isSearchParams = false,
  isSearchButtonDisabled = false,
  saveRouterStateAfterClearAll,
}: IIndividualSearchForm) => {
  const {
    loadDictionaries,
    gender,
    legalVisibility,
    legalEntityPrefix,
    getCustomField,
    customFieldsILE,
  } = useLegalEntities();
  const { id = '' } = useParams<{ id: string }>();
  const [legalEntityPrefixFilter, setLegalEntityPrefixFilter] = useState<IDictionary[]>([]);
  const { selectedLanguage } = useLang();
  const [showAdvanced, setShowAdvanced] = useState<boolean>(isSearchParams);
  const [values, setValues] = useState<ISearchFormValues>(initialFormValues);
  const [customFields, setCustomFields] = useState<ICustomFieldByObjectType[]>([]);
  const { state: routerState, pathname }: any = useLocation();
  const isUsersPage = useMatch(ERouteLinks.AssignLESearch);
  const { getUser, user } = useAuth();
  const [showSaveFilter, setShowSaveFilter] = useState(false);
  const { drawerClicked } = useDrawerClicked();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialFormValues,
  });
  const { isDesktop } = useThemeBreakpoint();
  const { t } = useTranslations();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();

  const { getFilterPresets, presets, getPresetValues, preparePresetsList } =
    useFilterPresets();
  const [searchParams] = useSearchParams();
  const expand = searchParams.get('expand');
  const presetId = searchParams.get('preset');

  const watchPreset = watch('preset');

  const { birthdayFrom, birthdayTo } = getValues();

  const isDateValid = !(birthdayFrom && birthdayTo && isDateAfter(birthdayFrom, birthdayTo));

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    if (user === null) {
      getUser();
    }
  }, []);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      const formValues = { ...getValues() };
      Object.keys(formValues).map((key: string) => {
        if (typeof values[key] === 'string') {
          values[key] = '';
        } else if (typeof values[key] === 'object') {
          values[key] = null;
        }
        return false;
      });
      reset(values);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    if (drawerClicked) {
      handleResetField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerClicked]);

  useEffect(() => {
    (async function getUserDataInit() {
      if (id && isUsersPage) {
        const response = await getUserById(id);
        if (response) {
          if (response.prefixGroupIds && response.prefixGroupIds.length) {
            if (legalEntityPrefix) {
              const names = legalEntityPrefix.filter((prefix) =>
                response.prefixGroupIds.includes(prefix.uniqueId.toString()),
              );
              setLegalEntityPrefixFilter(names);
            }
          }
        }
      }
    })();
  }, [id, legalEntityPrefix]);

  useEffect(() => {
    if (!isUsersPage && user) {
      (async function getUserDataInit() {
        if (user) {
          if (user.prefixGroupIds && user.prefixGroupIds.length) {
            if (legalEntityPrefix) {
              const names = legalEntityPrefix.filter((prefix) =>
                user.prefixGroupIds.includes(prefix.uniqueId.toString()),
              );
              setLegalEntityPrefixFilter(names);
            }
          }
        }
      })();
    }
  }, [user, legalEntityPrefix]);

  useEffect(() => {
    if (
      isEmpty(values.birthdayFrom) &&
      isEmpty(values.birthdayTo) &&
      isEmpty(values.city) &&
      isEmpty(values.lastName) &&
      isEmpty(values.firstname) &&
      isEmpty(values.genderUniqueId) &&
      isEmpty(values.prefixGroupUniqueId) &&
      isEmpty(values.visibilityUniqueId)
    ) {
      setShowSaveFilter(false);
    } else {
      setShowSaveFilter(true);
    }
  }, [values]);

  const setPresetFilters = async (
    selectedPreset: string,
    isFromEmailLink: boolean,
    isClickedManually: boolean = false,
  ) => {
    const handleValues = async () => {
      if (isFromEmailLink) {
        const filter = await getSearchFilter(selectedPreset);
        if (filter) {
          const queryValues: ISearchParams = {};
          filter.values.forEach((it) => {
            queryValues[it.key] = it.value;
          });
          return queryValues;
        }
      }
      return getPresetValues(selectedPreset);
    };

    const presetValues = {
      ...(isClickedManually ? initialValues : initialFormValues),
      preset: selectedPreset,
      ...(await handleValues()),
    };

    setValues(presetValues);
    reset(presetValues);
    await handleSubmit(onSubmit)();
  };

  const prepareData = (data: ISearchFormValues): ISearchFormValues => {
    const customFieldsPayload: ISearchFormCustomFieldValue[] = [];

    Object.keys(data).map((key) => {
      const isDateCF = key.startsWith('from_') || key.startsWith('to_');

      if (!isDateCF) {
        const customField = customFieldsILE.find((item) => item.name === key);
        if (customField && data[key]) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (customField.customFieldType === ECustomFieldType.TEXT) {
            if (data[key] === undefined || data[key] === null) {
              customFieldItem.text = undefined;
            } else if (data[key].trim() === '') {
              customFieldItem.text = undefined;
            } else {
              customFieldItem.text = data[key];
            }
          } else if (customField.customFieldType === ECustomFieldType.BOOL) {
            customFieldItem.bool = data[key] === '1';
          }

          customFieldsPayload.push(customFieldItem);
          delete data[key];
        }
      } else {
        const customFieldKey = key.startsWith('from_')
          ? key.replace('from_', '')
          : key.replace('to_', '');

        const customField = customFieldsILE.find((item) => item.name === customFieldKey);

        if (customField && (data[`from_${customFieldKey}`] || data[`to_${customFieldKey}`])) {
          const customFieldItem: ISearchFormCustomFieldValue = {
            customFieldUniqueId: customField.uniqueId,
            customFieldType: customField.customFieldType,
            name: customField.name,
          };
          if (data[`from_${customFieldKey}`]) {
            customFieldItem.dateFrom =
              data[`from_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`from_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          if (data[`to_${customFieldKey}`]) {
            customFieldItem.dateTo =
              data[`to_${customFieldKey}`] !== null
                ? formatDateTimeToIso(data[`to_${customFieldKey}`])
                : formatDateTimeToIso(new Date().toString());
          }
          customFieldsPayload.push(customFieldItem);
          if (data[`from_${customFieldKey}`]) {
            delete data[`from_${customFieldKey}`];
          }
          if (data[`to_${customFieldKey}`]) {
            delete data[`to_${customFieldKey}`];
          }
        }
      }
      return false;
    });

    if (customFieldsPayload?.length) {
      data.customFields = customFieldsPayload;
    } else {
      delete data.customFields;
    }

    return data;
  };

  const submitForm = (data: ISearchFormValues) => {
    onSubmit(prepareData(data));
  };

  useEffect(() => {
    (async function init() {
      await getFilterPresets(ESearchFilterTypes.individual);
      if (routerState?.presetIndividual) {
        await setPresetFilters(routerState?.presetIndividual, true, false);
      }
      if (presetId && expand === LEAccordionNames.INDIVIDUAL) {
        await setPresetFilters(presetId, true, false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function init() {
      if (watchPreset) {
        await setPresetFilters(watchPreset, false, false);
      } else {
        await setPresetFilters('', false, false);
      }
      await handleSubmit(onSubmit)();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await loadDictionaries([
        EDictionaryTypes.Gender,
        EDictionaryTypes.LegalVisibility,
        EDictionaryTypes.legalEntityPrefix,
      ]);
      await getCustomField(ECustomFieldObjectType.LE_INDIVIDUAL);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    setCustomFields(
      customFieldsILE.filter((item: ICustomFieldByObjectType) => item.searchCriteria),
    );
  }, [customFieldsILE]);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFormValues);
      const message = t('general.validation.dateShouldBeLater.message');
      if (isDateAfter(getValues().birthdayFrom, value.birthdayTo as string)) {
        setError('birthdayTo', { type: 'custom', message });
      } else {
        clearErrors('birthdayTo');
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (type === ELEAccordionsPageTypes.UserManagement) {
      setShowAdvanced(true);
    }
  }, [type]);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        {(type === ELEAccordionsPageTypes.LegalEntity ||
          type === ELEAccordionsPageTypes.SelectLegalEntity) && (
          <Grid
            container
            justifyContent="space-between"
            rowSpacing={1}
            sx={{ mb: { xs: 2, md: 0 } }}
          >
            <Grid item md={6} sm={12} xs={12}>
              <StyledPresetSelect
                options={presets ? preparePresetsList(presets) : []}
                name="preset"
                setValue={(name: string, value: string) => {
                  setValue('preset', value);
                  handleResetField();
                }}
                onSelect={(val) => {
                  if (!val) {
                    handleResetField();
                  } else {
                    setPresetFilters(val, false, true);
                  }
                }}
                control={control}
                errors={errors}
                withValidation={false}
                withLabel={false}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant={EButtonVariants.outlined}
                  label={t('legalEntities.individual.accordion.advancedFilters.button')}
                  id="legal_entities-individual-advanced_filters"
                  onClick={() => setShowAdvanced(!showAdvanced)}
                  fullWidth={!isDesktop}
                  size={EButtonSizes.small}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {showAdvanced && (
          <>
            <StyledAdvancedSearch
              container
              justifyContent="space-between"
              spacing={2}
              columns={10}
            >
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormInput
                  name="firstname"
                  label={t('legalEntities.individual.accordion.firstName.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormInput
                  name="lastName"
                  label={t('legalEntities.individual.accordion.lastName.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormInput
                  name="city"
                  label={t('legalEntities.individual.accordion.city.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormDatePicker
                  name="birthdayFrom"
                  openTo="year"
                  dateFormat={shortDateFormat}
                  label={t('legalEntities.individual.accordion.dateBirthFrom.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormDatePicker
                  name="birthdayTo"
                  openTo="year"
                  dateFormat={shortDateFormat}
                  label={t('legalEntities.individual.accordion.cdateBirthTo.label')}
                  control={control}
                  errors={errors}
                  minDate={getValues().birthdayFrom as Date}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormSelect
                  options={mapDictionaryToOptionProp(legalEntityPrefixFilter)}
                  name="prefixGroupUniqueId"
                  label={t('legalEntities.individual.accordion.legalEntityGroup.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormSelect
                  options={mapDictionaryToOptionProp(legalVisibility)}
                  name="visibilityUniqueId"
                  label={t('legalEntities.individual.accordion.visibility.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item lg={2} md={5} sm={5} xs={10}>
                <FormSelect
                  options={mapDictionaryToOptionProp(gender)}
                  name="genderUniqueId"
                  setValue={setValue}
                  label={t('legalEntities.individual.accordion.gender.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>

              {customFields.map((customField) => (
                <FormCustomField
                  data={customField}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  key={customField.uniqueId}
                  wrapper={customFieldWrapper}
                  isSearch={true}
                />
              ))}

              {new Array(4 - customFields.length >= 0 ? 4 - customFields.length : 0)
                .fill('')
                .map((index) => (
                  <Grid item lg={2} md={5} sm={5} xs={10} key={index} />
                ))}
            </StyledAdvancedSearch>
            <Grid container justifyContent="space-between" sx={{ mb: { xs: 2, md: 0 } }}>
              <Grid item md={6} sm={12} xs={12} />
              <Grid item md={4} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant={EButtonVariants.contained}
                    label={t('legalEntities.individual.accordion.search.button')}
                    id="legal_entities-individual-search"
                    type="submit"
                    size={EButtonSizes.small}
                    fullWidth={!isDesktop}
                    disabled={isSearchButtonDisabled || !isDateValid}
                    sx={{
                      minWidth: {
                        lg: '240px !important',
                        xs: undefined,
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </form>
      <SelectedFilters
        name="individual"
        values={values}
        excludes={['preset', 'customFields']}
        labels={ESearchFormLabels}
        updatedQueryPath={
          getNewQueryPath ? getNewQueryPath(prepareData(getValues())) : undefined
        }
        saveQueryParams={['']}
        handleDelete={handleResetField}
        type={ESearchFilterKeys.individual}
        saveRoute={ERouteLinks.SavePreset.replace(':type', ESearchFilterKeys.individual)}
        dictionaries={{
          genderUniqueId: prepareFilters(gender, 'uniqueId', 'name'),
          visibilityUniqueId: prepareFilters(legalVisibility, 'uniqueId', 'name'),
          prefixGroupUniqueId: prepareFilters(legalEntityPrefix, 'uniqueId', 'name'),
        }}
        showSaveFilters={showSaveFilter && pathname !== ERouteLinks.SelectLegalEntity}
        translationKeys={translationKeys}
        customFields={customFieldsILE}
        saveRouterStateAfterClearAll={saveRouterStateAfterClearAll}
      />
    </SearchWrapper>
  );
};

export { IndividualSearchForm };
