import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ECustomFieldStatus,
  ICustomField,
  ICustomFieldFormValues,
  ICustomFieldUpdateFormValues,
} from 'models/customField';
import { useCustomFields } from 'pages/CustomFieldsPage/hooks';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import CustomFieldForm from 'pages/CustomFieldsPage/CustomFieldForm';
import { getDictionaryById } from 'requests/dictionary';
import { IDictionary, ITranslation } from 'models/dictionary';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';
import { diffObjects, isObjectEmpty } from 'helpers/objects';

const mapCustomFieldToFormValues = (
  data: ICustomField,
  translation: IDictionary,
  placeholderDictionary: IDictionary | undefined,
) => {
  const translations: { [key: string]: string } = {};
  translation?.translations?.map((item: ITranslation) => {
    translations[item.languageUniqueId] = item.name;
    return true;
  });

  const placeholder: { [key: string]: string } = {};
  placeholderDictionary?.translations?.map((item: ITranslation) => {
    placeholder[item.languageUniqueId] = item.name;
    return true;
  });

  const { uniqueId, inputMask, ...values } = data;

  return {
    ...values,
    inputMask: inputMask ?? '',
    enabledString: data.enabled ? ECustomFieldStatus.ENABLED : ECustomFieldStatus.DISABLED,
    translations,
    placeholder,
  };
};

const EditCustomFieldPage = () => {
  const { id } = useParams<{ id: string }>();
  const [customField, setCustomField] = useState<ICustomFieldFormValues>();
  const { handleGetCustomField, handleUpdateCustomField } = useCustomFields();
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  useEffect(() => {
    (async () => {
      if (id) {
        const result = await handleGetCustomField(id);
        if (result) {
          const dictionaryTranslation = await getDictionaryById(result.dictionaryUniqueId);
          let placeholderTranslation;
          if (result.placeholderUniqueId) {
            placeholderTranslation = await getDictionaryById(result.placeholderUniqueId);
          }
          const values = mapCustomFieldToFormValues(
            result,
            dictionaryTranslation,
            placeholderTranslation,
          );
          setCustomField(values);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = async (data: ICustomFieldFormValues) => {
    const diffData = diffObjects(customField, data);
    const payload: ICustomFieldUpdateFormValues = {
      ...(diffData?.name ? { name: data.name } : {}),
      ...(diffData?.enabledString ? { enabledString: data.enabledString } : {}),
      ...(diffData?.enabled ? { enabled: data.enabled } : {}),
      ...(diffData?.searchCriteria !== undefined
        ? { searchCriteria: data.searchCriteria }
        : {}),
      ...(diffData?.placeholder ? { placeholder: data.placeholder } : {}),
      ...(diffData?.visibleInSearchResults !== undefined
        ? { visibleInSearchResults: data.visibleInSearchResults }
        : {}),
      ...(diffData?.translations ? { translations: data.translations } : {}),
    };

    try {
      if (!isObjectEmpty(payload)) {
        await handleUpdateCustomField(id as string, payload);
      }

      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('customField.has.updated.successfully'),
        variant: EAlertVariants.success,
      });
      navigate(ERouteLinks.CustomFields);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data?.errors?.[0]?.message || e.response.data?.message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('customFields.header'),
            route: ERouteLinks.CustomFields,
          },
          t('customFields.edit.header'),
        ]}
      />
      {customField && (
        <>
          <StyledPageTitle variant="h4">{t('customFields.edit.header')}</StyledPageTitle>
          <TableFormWrapper>
            <CustomFieldForm
              onSubmit={handleSubmit}
              onCancel={() => navigate(ERouteLinks.CustomFields)}
              initialFormValues={customField}
              isEditForm={true}
            />
          </TableFormWrapper>

          {samePageAlert && (
            <Box mt={2}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default EditCustomFieldPage;
