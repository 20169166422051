import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { CommentForm, ICommentFormValues } from 'components/organisms/CommentForm';
import { useTranslations } from 'hooks/useTranslations';
import { useAncillaries } from 'pages/AncillariesPage/hooks';

const AccessoriesAddCommentPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { handleCreateComment } = useAncillaries();
  const { t } = useTranslations();
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate(`${ERouteLinks.ViewAccessories.replace(':id', id as string)}?expand=comments`);
  };

  const handleSubmit = async (data: ICommentFormValues) => {
    const result = await handleCreateComment(data);
    if (result) {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.accessories.name'),
            route: `${ERouteLinks.Ancillaries}?expand=accessories`,
          },
          {
            label: t('accessoryDetails.accordion.title.label'),
            route: `${ERouteLinks.ViewAccessories.replace(
              ':id',
              id as string,
            )}?expand=comments`,
          },
          t('ancillary.comments.accordion.addComment.button'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('ancillary.comments.accordion.addComment.button')}
      </StyledPageTitle>

      <FormWrapper>
        <CommentForm onCancel={navigateToDetails} onSubmit={handleSubmit} />
      </FormWrapper>
    </>
  );
};

export { AccessoriesAddCommentPage };
