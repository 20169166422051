import styled from '@emotion/styled';
import { Breadcrumbs } from '@mui/material';
import { colors } from 'theme/colors';

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 15px;

  & li,
  & li span {
    color: ${colors.gray300};
    letter-spacing: 0.08em;
  }

  & li:last-of-type,
  & li:nth-last-of-type(2),
  & li:nth-last-of-type(2) span,
  & li:last-child span {
    color: ${colors.gray400};
    font-weight: 600;
  }

  & li a {
    color: ${colors.gray300};
    font-family: NotoSansLight, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    text-transform: uppercase;
    text-decoration: none;
  }
`;

export { StyledBreadcrumbs };
