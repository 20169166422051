import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';

export const DashboardBarChartWrapper = styled(Box)`
  border: 1px solid ${colors.primary25};
  border-radius: 4px;
  min-height: 270px;
  padding: 20px;
  display: grid;
  align-content: space-between;
`;

interface IStyledBar {
  width: number;
  color: string;
}

export const StyledBar = styled.div<IStyledBar>`
  width: ${({ width }: IStyledBar): string => `${width}px`};
  height: 20px;
  border-radius: 4px;
  background-color: ${({ color }: IStyledBar): string => color || '#2e72ad'};
`;

export const StyledStateSwitcher = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${colors.primary25};
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  margin-bottom: 20px;
`;

interface IStyledStateTab {
  active: boolean;
}

export const StyledStateTab = styled.div<IStyledStateTab>`
  cursor: pointer;
  margin: 4px;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ active }: IStyledStateTab): string =>
    active ? colors.primary500 : colors.primary25};

  & span {
    color: ${({ active }: IStyledStateTab): string =>
      active ? colors.primary25 : colors.primary500};
  }
`;

export const ChartNoDataGridItem = styled(Grid)`
  background-color: ${colors.primary25};
  height: 80px;
  margin: 10px;
  padding: 40px 0px;
  width: auto;
  align-items: center;
  align-content: center;
  & p {
    color: ${colors.gray400};
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    max-width: 165px;
    margin: auto;
  }
`;

export enum ETransactionsChartBarColor {
  SEIZED = '#E43132',
  SURRENDERED = '#064D8B',
  RECOVERED = '#eeaf15',
  DECLARED_UNKNOWN_ORIGIN = '#0e3156',
  DECLARED_MANUFACTURED = '#7DA7CC',
  DECLARED_IMPORTED = '#A4C1DB',
  EXPORTED = '#2e72ae',
  ASSIGNED_TEMPORARILY = '#043056',
  ASSIGNED_INDEFINITELY = '#1D2939',
  TEST_FIRED = '#f9c9cf',
  SOLD = '#A80019',
  GIVEN = '#004D30',
  STOLEN = '#E69396',
  LOST = '#C41C2B',
  LEASED = '#667085',
  RETURNED = '#32A585',
  MODIFIED = '#fad164',
  REPAIRED_OR_MAINTAINED = '#178866',
  PROOFED = '#127858',
  TRANSFERRED = '#ffd164',
  DEACTIVATED = '#475467',
  MARKED = '#EEAF15',
  MARKED_PENDING = '#E8F5F2',
  SPOT_CHECK = '#9C6107',
  DECLARED_DISASSEMBLED = '#E7EAF6',
  DECLARED_LOT_BATCH_IMPORTED = '#E9D7FE',
  DECLARED_LOT_BATCH_MANUFACTURED = '#536ABE',
  DECLARED_LOT_BATCH_UNKNOWN_ORIGIN = '#2A4AAA',
  DECLARED_PIECEMEAL_UNKNOWN_ORIGIN = '#153592',
  DEPLETED = '#ffde8f',
  DISABLED = '#E4E7EC',
  DESTROYED = '#101828',
  DEFAULT_COLOR = '#0e3156',
}
