import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { mapSpecificDictionaryToOptionProp } from 'helpers/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchGroupFirearmFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import {
  initialGroupFirearmSearchFormValues,
  ISearchGroupFirearmFormValues,
} from 'models/form';
import { useTranslations } from 'hooks/useTranslations';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';

interface ISearchFormValues {
  serialNumber: string;
  make: string;
  model: string;
  caliber: string;
}

enum ESearchFormLabels {
  serialNumber = 'Serial Number',
  make = 'Make',
  model = 'Model',
  caliber = 'Caliber',
}

interface IGroupFirearmSearchForm {
  onSubmit: (arg: ISearchGroupFirearmFormValues) => void;
}

const GroupFirearmSearchForm = ({ onSubmit }: IGroupFirearmSearchForm) => {
  const { make, model, caliber } = useLegalEntities();
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialGroupFirearmSearchFormValues,
  });
  const [values, setValues] = useState<ISearchGroupFirearmFormValues>(
    initialGroupFirearmSearchFormValues,
  );

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchGroupFirearmFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialGroupFirearmSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" spacing={2} columns={10}>
          <Grid item lg={2} sm={5} xs={10}>
            <FormInput
              name="serialNumber"
              label={t('groupDetails.firearmListSection.serialNumber.label')}
              placeholder={t('groupDetails.firearmListSection.serialNumber.label')}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormAutocomplete
              options={mapSpecificDictionaryToOptionProp(make)}
              name="make"
              label={t('groupDetails.firearmListSection.make.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormAutocomplete
              options={mapSpecificDictionaryToOptionProp(model)}
              name="model"
              label={ESearchFormLabels.model}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <FormAutocomplete
              options={mapSpecificDictionaryToOptionProp(caliber)}
              name="caliber"
              label={t('groupDetails.firearmListSection.calibre.label')}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} sm={5} xs={10}>
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t('groupDetails.firearmListSection.search.button')}
              type="submit"
              fullWidth={true}
              sx={{ mt: 3.5 }}
            />
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="group_firearm"
        values={values}
        labels={ESearchGroupFirearmFormLabelsTypes}
        handleDelete={handleResetField}
        showSaveFilters={false}
        noPreset
        saveRouterStateAfterClearAll={false}
        saveQueryParams={['type']}
      />
    </SearchWrapper>
  );
};

export { GroupFirearmSearchForm };
