import { EArtComponentType } from 'constants/ArtAccessoryType';

export enum EFirearmType {
  AIRSOFT = 'Airsoft',
  CANNON = 'Cannon',
  COMBINATION_GUN = 'Combination Gun',
  GRENADE_LAUNCHER = 'Grenade Launcher',
  MACHINE_GUN = 'Machine Gun',
  OTHER = 'Other',
  RIFLE = 'Rifle',
  SHOTGUN = 'Shotgun',
  SUBMACHINE_GUN = 'Submachine Gun',
  RECOILLESS_RIFLE = 'Recoilless Rifle',
  ROCKET_LAUNCHER = 'Rocket Launcher',
  MANPAD = 'MANPAD',
  ANTI_TANK_MISSILE = 'Anti-Tank Missile',
  HANDGUN = 'Handgun',
}

export enum EFirearmTypeUniqueId {
  AIRSOFT = '9faabc60-dade-40ad-8910-525d1d68da97',
  CANNON = '00b509fa-34b9-42df-bc63-f075fc908285',
  COMBINATION_GUN = '51f65154-f7e5-4a2b-8bb8-b46ebad6ca9a',
  GRENADE_LAUNCHER = 'b8096119-5dc4-4ddf-bb1a-d34698a9d82f',
  MACHINE_GUN = '772a1a89-3a1e-4c9e-9d46-d92dc6913691',
  OTHER = '726096b2-2fa0-45e0-830a-f0be32cef8f8',
  RIFLE = 'b33c9715-bbc7-443e-b6c0-91f2fa93ebe3',
  SHOTGUN = '79b779a7-bf51-4fc7-b362-eb5e8f8290a4',
  SUBMACHINE_GUN = 'b48efc5f-9fb9-4a8b-b3c7-c83a75349243',
  RECOILLESS_RIFLE = '4bd20b3f-d97b-4c78-bba3-b524577d3b2d',
  ROCKET_LAUNCHER = '933e6067-4270-43c2-bb89-70c9388615bf',
  MANPAD = '6b7b1a5a-b6df-4315-8b56-4cbfb905273d',
  ANTI_TANK_MISSILE = '79b9e12c-1502-4d2c-a597-38449ccbfe63',
  HANDGUN = 'dbd1f85e-950a-44cc-8724-b62c1214ee74',
}

export const componentTypeForFirearmType = (
  firearmType: string | undefined,
): EArtComponentType[] => {
  if (firearmType === undefined) {
    return [];
  }
  switch (firearmType as EFirearmType) {
    case EFirearmType.AIRSOFT: {
      return [
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
        EArtComponentType.SLIDE,
        EArtComponentType.CYLINDER,
      ];
    }
    case EFirearmType.CANNON: {
      return [EArtComponentType.BARREL, EArtComponentType.FRAME, EArtComponentType.BOLT];
    }
    case EFirearmType.COMBINATION_GUN: {
      return [
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
      ];
    }
    case EFirearmType.GRENADE_LAUNCHER: {
      return [
        EArtComponentType.BARREL,
        EArtComponentType.CYLINDER,
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
      ];
    }
    case EFirearmType.MACHINE_GUN: {
      return [
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
      ];
    }
    case EFirearmType.OTHER: {
      return [
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
        EArtComponentType.SLIDE,
        EArtComponentType.CYLINDER,
      ];
    }
    case EFirearmType.RIFLE: {
      return [
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
      ];
    }
    case EFirearmType.SHOTGUN: {
      return [
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
      ];
    }
    case EFirearmType.SUBMACHINE_GUN: {
      return [
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
      ];
    }
    case EFirearmType.RECOILLESS_RIFLE: {
      return [
        EArtComponentType.BARREL,
        EArtComponentType.UPPER_RECEIVER,
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.FRAME,
      ];
    }
    case EFirearmType.ROCKET_LAUNCHER: {
      return [
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.BARREL,
        EArtComponentType.FRAME,
      ];
    }
    case EFirearmType.MANPAD: {
      return [
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.BARREL,
        EArtComponentType.FRAME,
      ];
    }
    case EFirearmType.ANTI_TANK_MISSILE: {
      return [
        EArtComponentType.LOWER_RECEIVER,
        EArtComponentType.BARREL,
        EArtComponentType.FRAME,
      ];
    }
    case EFirearmType.HANDGUN: {
      return [
        EArtComponentType.FRAME,
        EArtComponentType.BARREL,
        EArtComponentType.BOLT,
        EArtComponentType.SLIDE,
        EArtComponentType.CYLINDER,
      ];
    }
    default: {
      return [];
    }
  }
};

export enum ESearchFormLabels {
  componentTypeUniqueId = 'ancillaryReferenceTable.addAncillary.component.componentType.label',
  manufacturerUniqueId = 'ancillaryReferenceTable.addAncillary.component.componentManufacturer.label',
  productName = 'ancillaryReferenceTable.addAncillary.component.productName.label',
  calibreUniqueId = 'ancillaryReferenceTable.addAncillary.component.calibre.label',
  length = 'ancillaryReferenceTable.addAncillary.component.length.label',
  compositionUniqueId = 'ancillaryReferenceTable.addAncillary.component.composition.label',
  capacityUniqueId = 'ancillaryReferenceTable.addAncillary.component.capacity.label',
  proofHouseUniqueId = 'ancillaryReferenceTable.addAncillary.component.proofMark.label',
  notes = 'ancillaryReferenceTable.addAncillary.component.notes.label',
}
