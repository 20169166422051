import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { Box, InputAdornment } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { StyledFormInput } from 'pages/RolesPage/RoleSearchForm/styles';
import Icon from 'components/atoms/Icon/Icon';
import { EIconTypes } from 'constants/Icons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';

export interface ISearchFormValues {
  name: string;
}

const initialFormValues: ISearchFormValues = {
  name: '',
};

enum ESearchFormLabels {
  name = 'roles.roleName.label',
}

interface IRoleSearchForm {
  onSubmit: (arg: ISearchFormValues) => void;
}

const RoleSearchForm = ({ onSubmit }: IRoleSearchForm) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ISearchFormValues>({
    defaultValues: initialFormValues,
  });
  const [values, setValues] = useState<ISearchFormValues>(initialFormValues);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="flex-start"
          columnSpacing={4}
          sx={{ mb: { xs: 2, md: 0 }, mt: 2 }}
        >
          <Grid item md={3} sm={12} xs={12}>
            <StyledFormInput
              name="name"
              label={t(ESearchFormLabels.name)}
              placeholder={t('roles.roleName.placeholder.text')}
              control={control}
              errors={errors}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon type={EIconTypes.search} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item md={3} sm={12} xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant={EButtonVariants.outlined}
                label={t('roles.search.button')}
                type="submit"
                fullWidth={true}
                sx={{ mt: { xs: 0, md: 3.5 } }}
              />
            </Box>
          </Grid>

          <Grid item md={3} sm={0} xs={0} />

          <Grid item md={3} sm={12} xs={12} justifyContent="flex-end" display="flex">
            <Button
              fullWidth
              label={t('roles.addRole.button')}
              variant={EButtonVariants.contained}
              icon={EIconTypes.plus}
              onClick={() => navigate(ERouteLinks.AddRole)}
              sx={{ mt: { xs: 1, md: 3.5 } }}
            />
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="role"
        values={values}
        showSaveFilters={false}
        labels={ESearchFormLabels}
        handleDelete={handleResetField}
        noPreset
        saveRouterStateAfterClearAll={false}
      />
      <Box sx={{ mt: 2 }} />
    </>
  );
};

export { RoleSearchForm };
