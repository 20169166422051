import { useTranslations } from 'hooks/useTranslations';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import React, { useEffect, useState } from 'react';
import TableFormWrapper from 'components/atoms/FormWrapper';
import Grid from '@mui/material/Grid';
import { StyledValueFormHeader } from 'pages/SystemSettingsPage/styles';
import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useNavigate } from 'react-router-dom';
import { FormRadioGroup } from 'components/molecules/FormRadioGroup';
import { useForm } from 'react-hook-form';
import { useScroll } from 'hooks/useScroll';
import { ActionTypes } from 'state/actions/alert';
import { useDocumentsGeneratingSetupPageSchema } from 'pages/SystemSettingsPage/DocumentsGeneratingSetupPage/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDocumentsGeneratingSetup } from 'pages/SystemSettingsPage/DocumentsGeneratingSetupPage/hooks';

const initialValues = {
  eTraceEnabled: null,
  iArmsEnabled: null,
};

export interface IDocumentsGeneratingFormValues {
  eTraceEnabled: string | null;
  iArmsEnabled: string | null;
}

export const DocumentsGeneratingSetupPage = () => {
  const { t } = useTranslations();
  const { samePageAlert, clearAlert, setAlert } = useAlert();
  const navigate = useNavigate();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const { updateDocumentsGeneratingData, loadInitData, eTraceEnabled, iArmsEnabled } =
    useDocumentsGeneratingSetup();
  const schema = useDocumentsGeneratingSetupPageSchema();
  const { elRef, scrollToError } = useScroll();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDocumentsGeneratingFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    loadInitData();
  }, []);

  useEffect(() => {
    if (eTraceEnabled !== null) {
      setValue('eTraceEnabled', eTraceEnabled ? 'yes' : 'no');
    }
  }, [eTraceEnabled]);

  useEffect(() => {
    if (iArmsEnabled !== null) {
      setValue('iArmsEnabled', iArmsEnabled ? 'yes' : 'no');
    }
  }, [iArmsEnabled]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmitForm = async (data: IDocumentsGeneratingFormValues) => {
    if (data.iArmsEnabled !== null && data.eTraceEnabled !== null) {
      setSubmitLoading(true);
      await updateDocumentsGeneratingData(data);
      setSubmitLoading(false);
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('documentsGeneratinPage.success.text'),
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          { label: t('systemSettings.header'), route: ERouteLinks.SystemSettings },
          t('documentsGeneratingSetup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('documentsGeneratingSetup.header')}</StyledPageTitle>
      <TableFormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container ref={elRef}>
            <Grid container rowGap={1}>
              <Grid item container>
                <Grid item xs={12} mb={3}>
                  <StyledValueFormHeader>
                    {t('documentsGeneratingSetup.section.header')}
                  </StyledValueFormHeader>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box mb={1}>
                    <FormRadioGroup
                      name="eTraceEnabled"
                      label={t('documentsGenerating.eTraceRadioButtons.label')}
                      options={[
                        { label: t('general.yes.label'), value: 'yes' },
                        { label: t('general.no.label'), value: 'no' },
                      ]}
                      control={control}
                      errors={errors}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box mb={1}>
                    <FormRadioGroup
                      name="iArmsEnabled"
                      label={t('documentsGenerating.iArmsRadioButtons.label')}
                      options={[
                        { label: t('general.yes.label'), value: 'yes' },
                        { label: t('general.no.label'), value: 'no' },
                      ]}
                      control={control}
                      errors={errors}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container columnSpacing={3} rowGap={1} mt={1}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('general.save.button')}
                  isLoading={isSubmitLoading}
                  id="save-button"
                  variant={EButtonVariants.contained}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  id="cancel-button"
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(ERouteLinks.SystemSettings)}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </TableFormWrapper>
      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};
