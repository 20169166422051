import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { getComponentsComment } from 'requests/ancillaries';
import { useQuery } from 'hooks/useQuery';
import { StyledDetailsWrapper } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage/styles';
import { ICommentData } from 'models/comment';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAncillaries } from '../../../hooks';

export interface IComponentsCommentData extends ICommentData {}

const ComponentsDeleteCommentPage = () => {
  const { id, commentId } = useParams<{ id: string; commentId: string }>();
  const query = useQuery();
  const navigate = useNavigate();
  const [componentsComment, setComponentsComment] = useState<IComponentsCommentData | null>();
  const { t } = useTranslations();

  const { handleDeleteComment } = useAncillaries();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const type: string = query.get('type') || '';
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  const navigateToDetails = () =>
    navigate(
      `${ERouteLinks.ViewComponents.replace(
        ':id',
        id as string,
      )}?type=${type}&expand=comments`,
    );

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function getComponentsCommentDataInit() {
      if (id && commentId) {
        const response = await getComponentsComment(id, commentId);
        if (response) {
          setComponentsComment(response);
        }
      }
    })();
  }, [id, commentId]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (componentsComment) {
        const result = await handleDeleteComment(
          componentsComment.uniqueId,
          componentsComment.hidden,
        );

        if (result.status === 204) {
          navigateToDetails();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  let componentsActionType: string = '';
  if (componentsComment) {
    componentsActionType = componentsComment.hidden
      ? t('enableComment.header')
      : t('disableComment.header');
  }

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('module.ancillaries.name'),
            route: ERouteLinks.Ancillaries,
          },
          {
            label: t('module.components.name'),
            route: `${ERouteLinks.Ancillaries}?expand=components`,
          },
          {
            label: t('componentDetails.accordion.title.label'),
            route: `${ERouteLinks.ViewComponents.replace(
              ':id',
              id as string,
            )}?type=${type}&expand=comments`,
          },
          `${componentsActionType ? `${componentsActionType}` : ''}`,
        ]}
      />
      {componentsComment && (
        <>
          <StyledPageTitle variant="h4">{`${componentsActionType}`} </StyledPageTitle>

          <Grid item xs={12} style={{ margin: '10px 0 10px' }}>
            <Alert
              text={
                componentsComment.hidden
                  ? t('enableComment.info.text')
                  : t('disableComment.info.text')
              }
              variant={EAlertVariants.warning}
            />
          </Grid>

          <StyledDetailsWrapper container direction="column" gap={3}>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('enableComment.title.text')}
              </Typography>
              <Typography variant="body2">{componentsComment.title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('enableComment.comment.text')}
              </Typography>
              <Typography variant="body2">{componentsComment.comment}</Typography>
            </Grid>
            <Grid container rowGap={3}>
              <Grid container item xs={12} lg={6} rowGap={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('enableComment.addedBy.text')}
                  </Typography>
                  <Typography variant="body2">
                    {`${componentsComment.createdByFirstName} ${componentsComment.createdByLastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('enableComment.date.text')}
                  </Typography>
                  <Typography variant="body2">
                    {formatDate(componentsComment.createdDate, longDateFormat)}
                  </Typography>
                </Grid>
              </Grid>
              {componentsComment.hidden && (
                <Grid container item xs={12} lg={6} rowGap={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('enableComment.disabledBy.text')}
                    </Typography>
                    <Typography variant="body2">{`${componentsComment.hiddenByFirstName} ${componentsComment.hiddenByLastName}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('enableComment.date.text')}
                    </Typography>
                    <Typography variant="body2">
                      {formatDate(componentsComment.hiddenAt, longDateFormat)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.yes.button')}
                id="yes-button"
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('general.no.button')}
                id="no-button"
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { ComponentsDeleteCommentPage };
