import { AncillariesActionTypes } from 'state/actions/ancilaries';
import { IReducerAction } from 'models/context';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';

export interface IOptionsArt {
  expanded: boolean;
  acillaryValue?: EArtAncillaryType;
}
interface IState {
  optionsArt: null | IOptionsArt;
}

export const initialState: IState = {
  optionsArt: null,
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case AncillariesActionTypes.SET_ANCILLARY_ART: {
      return { ...state, optionsArt: payload };
    }
    case AncillariesActionTypes.CLEAR_ANCILLARY_ART: {
      return { ...state, optionsArt: null };
    }
    default:
      return state;
  }
};
