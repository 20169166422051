import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { IAuditLogItemResponse } from 'models/auditLog';
import { getRegisteredFirearm } from 'requests/firearm';
import { IRegisteredFirearmData } from 'models/registeredFirearm';
import { RegisteredFirearmDetails } from 'components/organisms/RegisteredFirearmDetails';
import { AuditLogEventName } from 'pages/AuditLogPage/AuditLogViewPage/styles';
import { EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { useTranslations } from 'hooks/useTranslations';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';

interface IFirearmEventDetails {
  auditLog: IAuditLogItemResponse;
}

const FirearmEventDetails = ({ auditLog }: IFirearmEventDetails) => {
  const [firearm, setFirearm] = useState<IRegisteredFirearmData | null>();
  const { hasPermissions } = usePermission();
  const { t } = useTranslations();

  useEffect(() => {
    (async () => {
      if (auditLog?.objectUniqueId && hasPermissions([EPermission.VIEW_FIREARMS])) {
        const response = await getRegisteredFirearm(auditLog.objectUniqueId);
        if (response) {
          setFirearm(response);
        }
      }
    })();
  }, [auditLog]);

  return (
    <>
      <AuditLogEventName>
        <Typography variant="h5">{t('auditLogDetails.firearmsDetails.title')}</Typography>
      </AuditLogEventName>

      {firearm && (
        <RegisteredFirearmDetails
          firearm={firearm}
          pageType={EDetailsPageTypes.auditLogView}
        />
      )}
    </>
  );
};

export default FirearmEventDetails;
