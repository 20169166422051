import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { ERouteLinks } from 'models/route';

import LEAccordions, { ELEAccordionsPageTypes } from 'components/organisms/LEAccordions';
import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';

const AssignLESearchPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t } = useTranslations();
  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('user.breadcrumbs.administration'), route: ERouteLinks.Administration },
          { label: t('user.breadcrumbs.users'), route: ERouteLinks.Users },
          {
            label: t('user.breadcrumbs.userDetails'),
            route: ERouteLinks.ViewUser.replace(':id', id),
          },
          t('user.breadcrumbs.assignLegalEntity'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('searchForLegalEntity.header')}</StyledPageTitle>
      <Box mt={2}>
        <Button
          label={t('searchForLegalEntity.back.button')}
          variant={EButtonVariants.outlined}
          icon={EIconTypes.left}
          onClick={() => navigate(ERouteLinks.ViewUser.replace(':id', id))}
        />
      </Box>
      <LEAccordions
        type={ELEAccordionsPageTypes.UserManagement}
        translationKeys={{ clearAll: 'assignLegalEntity.search.clearAll.button' }}
        saveRouterStateAfterClearAll={true}
      />
    </>
  );
};

export default AssignLESearchPage;
