import React, { useEffect } from 'react';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { ITableDataSource } from 'models/table';
import { IRole } from 'models/role';
import { getEventTypeColor, getRoleNames } from 'pages/AuditLogPage/helpers';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { formatDate } from 'utils/date';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';

interface IAuditLogTableCard {
  data: ITableDataSource;
  roles: IRole[];
}

const AuditLogTableCard = ({ data, roles }: IAuditLogTableCard) => {
  const navigate = useNavigate();
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  const { t } = useTranslations();
  const roleNamesArr = getRoleNames(data?.roleName, roles);

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAuditLog.auditLogTable.eventAuthor.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.author)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAuditLog.auditLogTable.roleName.column')}
        </Typography>
        <Typography variant="body2">
          {roleNamesArr.map((roleName) => (
            <Chips text={roleName as string} variant={EChipsVariants.primary} />
          ))}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAuditLog.auditLogTable.module.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.module)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAuditLog.auditLogTable.eventType.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {data?.eventType ? (
            <Chips text={data.eventType} variant={getEventTypeColor(data.eventTypeUniqueId)} />
          ) : (
            '-'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAuditLog.auditLogTable.eventName.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.eventName)}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('searchForAuditLog.auditLogTable.eventDate.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {formatDate(data.eventDate, longDateFormat)}
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          mt: 2,
          mb: 2,
          ml: 1,
          mr: 1,
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {`${t('searchForAuditLog.auditLogTable.action.column')}:`}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('searchForAuditLog.details.label')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.AuditLogViewPage.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default AuditLogTableCard;
