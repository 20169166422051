import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from 'components/atoms/FormLabel';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementOptionProp } from 'models/form';
import { IconButton, InputAdornment } from '@mui/material';
import { EIconTypes } from 'constants/Icons';
import Icon from 'components/atoms/Icon/Icon';
import { StyledInnerWrapper, StyledSelect } from './styles';

interface ISelect {
  options: IFormElementOptionProp[];
  name: string;
  placeholder?: string;
  disabled?: boolean;
  setValue?: any;
  value?: string | null;
  className?: string;
  canBeEmpty?: boolean;
  label?: string;
}

export const Select = ({
  name,
  label,
  placeholder,
  options,
  disabled,
  className,
  setValue,
  value,
  canBeEmpty = true,
}: ISelect) => (
  <StyledFormElementWrapper className={className}>
    <FormLabel name={name} label={label} />
    <StyledInnerWrapper>
      <StyledSelect
        displayEmpty
        placeholder={placeholder || name}
        labelId={`${name}-label`}
        endAdornment={
          !disabled &&
          value && (
            <InputAdornment position="end">
              <IconButton onClick={() => setValue(null)}>
                <Icon type={EIconTypes.clear} />
              </IconButton>
            </InputAdornment>
          )
        }
        id={name}
        disabled={disabled}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      >
        <MenuItem
          disabled={!canBeEmpty}
          value=""
          style={{
            display: 'none',
          }}
        >
          {placeholder}
        </MenuItem>
        {options.map((option: IFormElementOptionProp) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </StyledInnerWrapper>
  </StyledFormElementWrapper>
);
