import React from 'react';
import { StyledLogo } from 'components/atoms/Logo/styles';

interface ILogo {
  src: string;
  alt?: string;
}

const Logo = ({ src, alt = '' }: ILogo) => <StyledLogo src={src} alt={alt} />;

export default Logo;
