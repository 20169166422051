import React, { SyntheticEvent } from 'react';
import { Box } from '@mui/material';
import { Accordion } from 'components/molecules/Accordion';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  isDesktop?: boolean;
  title?: string;
  counter?: string;
  onChange?: (event: SyntheticEvent, newValue: number) => void;
}

const TabPanel = (props: TabPanelProps) => {
  const {
    children,
    value,
    index,
    isDesktop = true,
    title = '',
    counter = '',
    onChange = undefined,
    ...other
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOnChange = (name: string) => (event: SyntheticEvent, expanded: boolean) => {
    if (onChange && expanded) {
      onChange(event, index);
    }
    event.preventDefault();
  };

  return isDesktop ? (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  ) : (
    <Accordion
      title={title}
      name={title}
      counter={counter}
      expanded={index === value}
      onChange={handleOnChange}
    >
      {children}
    </Accordion>
  );
};

export default TabPanel;
