import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { ILegalEntityFormValues } from 'pages/LegalEntitiesPage/LegalEntityForm';
import {
  IAssignLE,
  ILegalEntityAddress,
  ILegalEntityAncillariesListResponse,
  ILegalEntityAncillaryGroupAddResponse,
  ILegalEntityAncillaryGroupResponse,
  ILegalEntityContact,
  ILegalEntityFirearmListResponse,
  ILegalEntityGroupListResponse,
  ILegalEntityGroupResponse,
  ILegalEntityListResponse,
  ILegalEntityResponse,
  ILegalEntityType,
  IOrgLegalEntityResponse,
  ITransactionType,
} from 'models/legalEntity';
import { EPerPages, ISortOptions } from 'models/table';
import { ILegalEntityData } from 'components/organisms/LegalEntityDetails';
import { IOrgLegalEntityData } from 'components/organisms/OrgLegalEntityDetails';
import { IOrgLegalEntityFormValues } from 'pages/LegalEntitiesPage/OrgLegalEntityForm';
import {
  ILegalEntityCommentData,
  ILegalEntityCommentsList,
} from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage';
import { ILegalEntityAddressFormValues } from 'pages/LegalEntitiesPage/LegalEntityAddressForm';
import { queryString, queryStringWithSort } from 'helpers/request';
import {
  ILegalEntityEditGroupFormValues,
  ILegalEntityGroupFormValues,
  ISearchLegalEntityAncillariesFormValues,
  ISearchFirearmFormValues,
  ISearchGroupFormValues,
  ISearchLegalEntityAncillaryGroupFormValues,
  ILegalEntityAncillaryGroupFormValues,
} from 'models/form';
import { ISearchFormILEValues } from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { ICommentFormValues } from 'components/organisms/CommentForm';
import { ILegalEntityContactFormValues } from 'pages/LegalEntitiesPage/LegalEntityContactForm';
import { clearObject } from 'helpers/objects';
import { formatDateTimeToIso } from 'utils/date';
import { IAncillaryResponse } from 'models/ancillaries';

const getLegalEntity = async (id: string): Promise<ILegalEntityData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_LEGAL_ENTITY(id));
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const createLegalEntity = async (body: ILegalEntityFormValues) => {
  const { data } = await apiClient.post<TResponse<ILegalEntityResponse>>(
    ApiEndpoints.CREATE_LEGAL_ENTITY(),
    body,
  );

  return !isResponseError(data);
};

const getLegalEntities = async (
  currentPage: number,
  perPage: EPerPages,
  sort: ISortOptions | null | undefined,
  query: Partial<ISearchFormILEValues>,
) => {
  const qString = queryStringWithSort(
    {
      page: currentPage - 1,
      size: perPage,
    },
    sort,
  );

  const body = {
    ...query,
    ...(query.birthdayFrom ? { birthdayFrom: formatDateTimeToIso(query.birthdayFrom) } : {}),
    ...(query.birthdayTo ? { birthdayTo: formatDateTimeToIso(query.birthdayTo) } : {}),
  };

  delete body.preset;

  const { data } = await apiClient.post<TResponse<ILegalEntityListResponse>>(
    `${ApiEndpoints.GET_LEGAL_ENTITIES()}?${qString}`,
    clearObject(body),
  );
  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalType = async (id: string) => {
  const { data } = await apiClient.get<TResponse<ILegalEntityType>>(
    ApiEndpoints.GET_LEGAL_TYPE(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityFirearms = async (
  id: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchFirearmFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size }, sort);
  const { data } = await apiClient.post<TResponse<ILegalEntityFirearmListResponse>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_FIREARMS()}?${qString}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityAncillaries = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchLegalEntityAncillariesFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size }, sort);
  const { data } = await apiClient.post<TResponse<ILegalEntityAncillariesListResponse>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_ANCILLARIES()}?${qString}`,
    clearObject(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityAncillaryGroup = async (
  id: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchLegalEntityAncillaryGroupFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  const { data } = await apiClient.get<TResponse<ILegalEntityAncillaryGroupResponse>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_ANCILLARY_GROUP(id)}?${qString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityAncillaryGroupAncillaries = async (
  id: string,
  groupId: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchLegalEntityAncillaryGroupFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const qString = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  const { data } = await apiClient.get<TResponse<IAncillaryResponse>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_ANCILLARY_GROUP_ANCILLARIES(id, groupId)}?${qString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityGroupFirearms = async (
  leId: string,
  groupId: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchFirearmFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const queryStringGroup = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  const { data } = await apiClient.get<TResponse<ILegalEntityFirearmListResponse>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_GROUP_FIREARMS(leId, groupId)}?${queryStringGroup}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityGroups = async (
  id: string,
  page: number,
  size: EPerPages,
  query: Partial<ISearchGroupFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  const queryStringGroup = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  const { data } = await apiClient.get<TResponse<ILegalEntityGroupListResponse>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_GROUPS(id)}?${queryStringGroup}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityGroup = async (id: string, groupId: string) => {
  const { data } = await apiClient.get<TResponse<ILegalEntityGroupResponse>>(
    ApiEndpoints.GET_LEGAL_ENTITY_GROUP(id, groupId),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const updateLegalEntity = async (legalEntity: ILegalEntityFormValues) => {
  const { uniqueId, ...body } = legalEntity;

  const { data } = await apiClient.put(ApiEndpoints.UPDATE_LEGAL_ENTITY(uniqueId), body);

  return !isResponseError(data);
};

const deleteLegalEntity = async (id: string): Promise<boolean> => {
  const { data } = await apiClient.delete(ApiEndpoints.DELETE_LEGAL_ENTITY(id));

  return !isResponseError(data);
};

const getOrgLegalEntity = async (id: string): Promise<IOrgLegalEntityData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_ORG_LEGAL_ENTITY(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createOrgLegalEntity = async (body: IOrgLegalEntityFormValues) => {
  const { data } = await apiClient.post<TResponse<IOrgLegalEntityResponse>>(
    ApiEndpoints.CREATE_ORG_LEGAL_ENTITY(),
    body,
  );

  return !isResponseError(data);
};

const updateOrgLegalEntity = async (legalEntity: IOrgLegalEntityFormValues) => {
  const { uniqueId, ...body } = legalEntity;

  const { data } = await apiClient.put(ApiEndpoints.UPDATE_ORG_LEGAL_ENTITY(uniqueId), body);

  return !isResponseError(data);
};

const deleteOrgLegalEntity = async (id: string): Promise<boolean> => {
  const { data } = await apiClient.delete(ApiEndpoints.DELETE_ORG_LEGAL_ENTITY(id));

  return !isResponseError(data);
};

const createLegalEntityComment = async (comment: ICommentFormValues) => {
  const { uniqueId, ...body } = comment;

  const { data } = await apiClient.post<TResponse<ILegalEntityCommentData>>(
    ApiEndpoints.CREATE_LEGAL_ENTITY_COMMENT(uniqueId),
    body,
  );
  return !isResponseError(data);
};

const getLegalEntityComments = async (
  id: string,
  queryParams: {
    withHidden: boolean;
    currentPage: number;
    perPage: EPerPages;
  },
): Promise<ILegalEntityCommentsList | null> => {
  const { withHidden, currentPage, perPage } = queryParams;
  const query = queryString({
    withHidden,
    page: currentPage - 1,
    size: perPage,
  });

  const { data } = await apiClient.get(
    `${ApiEndpoints.GET_LEGAL_ENTITY_COMMENTS(id)}?${query}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityComment = async (
  id: string,
  commentId: string,
): Promise<ILegalEntityCommentData | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_LEGAL_ENTITY_COMMENT(id, commentId));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntityAddresses = async (id: string, sort?: ISortOptions | null) => {
  const queryStringAddress = sort
    ? `?sortBy=${sort.property}&sortDirection=${sort.direction}`
    : '';
  const { data } = await apiClient.get<TResponse<ILegalEntityAddress[]>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_ADDRESSES(id)}${queryStringAddress}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export interface ISearchParams {
  [key: string]: any;
}

const searchLocationCoordsByAddress = async (
  searchParams: ISearchParams,
  successCallback: (p: any) => void,
  isOnline: boolean = true,
) => {
  Object.keys(searchParams).forEach((key) =>
    !searchParams[key]?.trim() ? delete searchParams[key] : {},
  );
  const queryParams = new URLSearchParams({ ...searchParams, format: 'json' });

  const apiURL = isOnline
    ? ApiEndpoints.SEARCH_LOCATION_COORDS_BY_ADDRESS
    : ApiEndpoints.SEARCH_LOCATION_COORDS_BY_ADDRESS_OFFLINE;

  const { data } = await apiClient.get<TResponse<any>>(
    apiURL(`?${queryParams.toString().toLowerCase()}`),
  );

  if (data?.length) {
    successCallback(data);
  }

  if (data.length === 0) {
    return false;
  }

  return !isResponseError(data);
};

const searchLocationAddressByCoords = async (
  latitude: string,
  longitude: string,
  isOnline: boolean = true,
) => {
  const apiUrl = isOnline
    ? ApiEndpoints.SEARCH_LOCATION_ADDRESS_BY_COORDS
    : ApiEndpoints.SEARCH_LOCATION_ADDRESS_BY_COORDS_OFFLINE;

  const { data } = await apiClient.get<TResponse<any>>(apiUrl(`${latitude}`, `${longitude}`));

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const createLegalEntityAddress = async (address: ILegalEntityAddressFormValues) => {
  const { uniqueId, ...body } = address;

  const { data } = await apiClient.post<TResponse<ILegalEntityAddress>>(
    ApiEndpoints.CREATE_LEGAL_ENTITY_ADDRESS(uniqueId),
    body,
  );
  return !isResponseError(data);
};

const setLegalEntityDefaultAddress = async (uniqueId: string, addressId: string) => {
  const { data } = await apiClient.post<TResponse<{}>>(
    ApiEndpoints.SET_LEGAL_ENTITY_DEFAULT_ADDRESS(uniqueId, addressId),
  );
  return !isResponseError(data);
};

const updateLegalEntityAddress = async (
  address: ILegalEntityAddressFormValues,
  addressId: string,
) => {
  const { uniqueId, ...body } = address;

  const { data } = await apiClient.put<TResponse<ILegalEntityAddress>>(
    ApiEndpoints.UPDATE_LEGAL_ENTITY_ADDRESS(uniqueId, addressId),
    body,
  );
  return !isResponseError(data);
};

const getLegalEntityAddress = async (leId: string, addressId: string) => {
  const { data } = await apiClient.get<TResponse<ILegalEntityAddress>>(
    ApiEndpoints.GET_LEGAL_ENTITY_ADDRESS(leId, addressId),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const deleteLegalEntityAddress = async (id: string, addressId: string): Promise<any> => {
  const res = await apiClient.delete(ApiEndpoints.DELETE_LEGAL_ENTITY_ADDRESS(id, addressId));
  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const getLegalEntityContacts = async (id: string, sort?: ISortOptions | null) => {
  const queryStringContact = sort
    ? `?sortBy=${sort.property}&sortDirection=${sort.direction}`
    : '';
  const { data } = await apiClient.get<TResponse<ILegalEntityContact[]>>(
    `${ApiEndpoints.GET_LEGAL_ENTITY_CONTACTS(id)}${queryStringContact}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createLegalEntityContact = async (address: ILegalEntityContactFormValues) => {
  const { uniqueId, ...body } = address;

  const { data } = await apiClient.post<TResponse<ILegalEntityContact>>(
    ApiEndpoints.CREATE_LEGAL_ENTITY_CONTACT(uniqueId),
    body,
  );
  return !isResponseError(data);
};

const updateLegalEntityContact = async (
  legalEntityId: string,
  contact: ILegalEntityContact,
) => {
  const { uniqueId, ...body } = contact;

  const { data } = await apiClient.put<TResponse<ILegalEntityContact>>(
    ApiEndpoints.UPDATE_LEGAL_ENTITY_CONTACT(legalEntityId, uniqueId),
    body,
  );
  return !isResponseError(data);
};

const getLegalEntityContact = async (leId: string, addressId: string) => {
  const { data } = await apiClient.get<TResponse<ILegalEntityContact>>(
    ApiEndpoints.GET_LEGAL_ENTITY_CONTACT(leId, addressId),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const deleteLegalEntityContact = async (id: string, contactId: string): Promise<any> => {
  const res = await apiClient.delete(ApiEndpoints.DELETE_LEGAL_ENTITY_CONTACT(id, contactId));

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const createLegalEntityGroup = async (address: ILegalEntityGroupFormValues) => {
  const { uniqueId, ...body } = address;

  const { data } = await apiClient.post<TResponse<ILegalEntityGroupResponse>>(
    ApiEndpoints.CREATE_LEGAL_ENTITY_GROUP(uniqueId),
    body,
  );
  return !isResponseError(data);
};

const createLegalEntityAncillaryGroup = async (form: ILegalEntityAncillaryGroupFormValues) => {
  const { uniqueId, ...body } = form;

  const { data } = await apiClient.post<TResponse<ILegalEntityAncillaryGroupAddResponse>>(
    ApiEndpoints.CREATE_LEGAL_ENTITY_ANCILLARY_GROUP(uniqueId),
    body,
  );
  return !isResponseError(data);
};

const updateLegalEntityGroup = async (
  group: ILegalEntityEditGroupFormValues,
  groupId: string,
) => {
  const { uniqueId, ...body } = group;

  const { data } = await apiClient.put<TResponse<ILegalEntityGroupResponse>>(
    ApiEndpoints.UPDATE_LEGAL_ENTITY_FIREARM_GROUP(uniqueId, groupId),
    body,
  );
  return !isResponseError(data);
};

const addAncillariesToGroup = async (LEId: string, uniqueIds: string[], groupId: string) => {
  const { data } = await apiClient.post<TResponse<ILegalEntityGroupResponse>>(
    ApiEndpoints.UPDATE_LEGAL_ENTITY_ADD_TO_GROUP(LEId, groupId),
    { uniqueIds },
  );
  return !isResponseError(data);
};
const updateLegalEntityAncillaryGroup = async (
  LEId: string,
  name: string,
  groupId: string,
) => {
  const { data } = await apiClient.put<TResponse<ILegalEntityGroupResponse>>(
    ApiEndpoints.UPDATE_LEGAL_ENTITY_ANCILLARY_GROUP(LEId, groupId),
    { name },
  );
  return !isResponseError(data);
};
const deleteLegalEntityAncillariesGroup = async (
  id: string,
  groupId: string,
): Promise<any> => {
  const res = await apiClient.delete(
    ApiEndpoints.DELETE_LEGAL_ENTITY_ANCILLARIES_GROUP(id, groupId),
  );
  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const deleteLegalEntityGroup = async (id: string, groupId: string): Promise<any> => {
  const res = await apiClient.delete(ApiEndpoints.DELETE_LEGAL_ENTITY_GROUP(id, groupId));
  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const assignLE = async (data: IAssignLE) => {
  const res = await apiClient.put<TResponse<any>>(
    `${ApiEndpoints.ASSIGN_LE(data.legalUniqueId)}?accountId=${data.accountId}`,
  );

  return !isResponseError(res.data);
};

const deleteAssignedLE = async (data: IAssignLE) => {
  const res = await apiClient.delete<TResponse<any>>(
    `${ApiEndpoints.DELETE_ASSIGN_LE(data.legalUniqueId)}?accountId=${data.accountId}`,
  );

  return !isResponseError(res.data);
};

const removeLEGroupFirearm = async (leId: string, groupId: string, firearmId: string) => {
  const { data } = await apiClient.delete<TResponse<any>>(
    ApiEndpoints.DELETE_LE_GROUP_FIREARM(leId, groupId, firearmId),
  );
  return !isResponseError(data);
};

const removeLEGroupAncillary = async (leId: string, groupId: string, firearmId: string) => {
  const { data } = await apiClient.delete<TResponse<any>>(
    ApiEndpoints.DELETE_LE_GROUP_ANCILLARY(leId, groupId, firearmId),
  );
  return !isResponseError(data);
};

const createLEGroupFirearm = async (leId: string, groupId: string, firearmIds: string[]) => {
  const { data } = await apiClient.post<TResponse<any>>(
    ApiEndpoints.CREATE_LE_GROUP_FIREARM(leId, groupId),
    { uniqueIds: firearmIds },
  );
  return !isResponseError(data);
};

const getLegalEntityAncillaryGroupTransactionType = async (
  groupId: string,
  leType: string,
) => {
  const qString = queryString({
    groupId,
    legalType: leType.toLocaleUpperCase(),
  });

  const { data } = await apiClient.get<TResponse<ITransactionType[]>>(
    `${ApiEndpoints.GET_ANCILLARY_GROUP_TRANSACTION_TYPES()}?${qString}`,
  );
  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const transactionAncillaryGroupTypeValidate = async (groupId: string, typeId: string) => {
  const qString = queryString({
    groupUniqueId: groupId,
    transactionTypeUniqueId: typeId,
  });
  const { data } = await apiClient.get<TResponse<string[] | null>>(
    `${ApiEndpoints.GET_ANCILLARY_GROUP_TRANSACTION_VALIDATE_TYPE()}?${qString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  getLegalEntity,
  createLegalEntity,
  getLegalType,
  updateLegalEntity,
  deleteLegalEntity,
  getLegalEntities,
  getOrgLegalEntity,
  createOrgLegalEntity,
  updateOrgLegalEntity,
  deleteOrgLegalEntity,
  createLegalEntityComment,
  getLegalEntityFirearms,
  getLegalEntityGroupFirearms,
  getLegalEntityGroups,
  getLegalEntityComments,
  getLegalEntityComment,
  getLegalEntityAddresses,
  createLegalEntityAddress,
  searchLocationCoordsByAddress,
  searchLocationAddressByCoords,
  updateLegalEntityAddress,
  getLegalEntityAddress,
  deleteLegalEntityAddress,
  getLegalEntityContacts,
  createLegalEntityContact,
  updateLegalEntityContact,
  getLegalEntityContact,
  deleteLegalEntityContact,
  setLegalEntityDefaultAddress,
  getLegalEntityGroup,
  createLegalEntityGroup,
  updateLegalEntityGroup,
  deleteLegalEntityGroup,
  assignLE,
  deleteAssignedLE,
  removeLEGroupFirearm,
  createLEGroupFirearm,
  getLegalEntityAncillaries,
  getLegalEntityAncillaryGroup,
  createLegalEntityAncillaryGroup,
  addAncillariesToGroup,
  updateLegalEntityAncillaryGroup,
  deleteLegalEntityAncillariesGroup,
  getLegalEntityAncillaryGroupAncillaries,
  removeLEGroupAncillary,
  getLegalEntityAncillaryGroupTransactionType,
  transactionAncillaryGroupTypeValidate,
};
