import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useParams } from 'react-router-dom';
import { CommentForm } from 'components/organisms/CommentForm';
import { useTranslations } from 'hooks/useTranslations';
import { useTransactions } from 'pages/TransactionsPage/hooks';
import { initialCommentInTransactionFormValues } from 'models/form';

const TransactionAddCommentPage = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { navigateToDetails, handleCreateComment } = useTransactions();
  const { t } = useTranslations();

  const handleSubmit = async (data: any) => {
    delete data.uniqueId;
    const result = await handleCreateComment(id, data);
    if (result) {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('transactionDetails.transactionDetails.header'),
            route: ERouteLinks.Transactions,
          },
          {
            label: t('transactionDetails.addComment.transactionDetails.header'),
            route: ERouteLinks.ViewTransaction.replace(':id', id),
          },
          t('transactionDetails.addComment.addComment.header'),
        ]}
      />
      <StyledPageTitle variant="h4">
        {t('transactionDetails.addComment.addComment.header')}
      </StyledPageTitle>

      <FormWrapper>
        <CommentForm
          onCancel={navigateToDetails}
          onSubmit={handleSubmit}
          initialFormValues={initialCommentInTransactionFormValues}
        />
      </FormWrapper>
    </>
  );
};

export { TransactionAddCommentPage };
