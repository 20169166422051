import React, { useEffect, useState } from 'react';
import SearchFilterStore from 'state/SearchFilterStore';
import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { StyledPageTitle } from 'theme/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import { FormInput } from 'components/molecules/FormInput';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { Typography } from '@mui/material';
import {
  mapValuesToPresetRequest,
  mapValuesWithDictionariesHelper,
  permittedIndividualFilterFields,
  permittedOrganisationFilterFields,
} from 'pages/LegalEntitiesPage/SavePresetPage/helpers';
import { useSavePreset } from 'pages/LegalEntitiesPage/SavePresetPage/hooks';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import FormWrapper from 'components/atoms/FormWrapper';
import {
  StyledFilterChipWrapper,
  StyledSelectedFiltersWrapper,
} from 'pages/MyFiltersPage/styles';
import { FilterChip } from 'components/atoms/FilterChip';
import { ESearchFormLabels as ESearchFormLabelsILE } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { ESearchFormLabels as ESearchFormLabelsOLE } from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { objectToKeyValueArray } from 'helpers/objects';
import { useDictionary } from 'models/dictionaryContext';
import { parseDictionary } from 'helpers/dictionary';
import { FormCheckbox } from 'components/molecules/FormCheckbox';
import { useTranslations } from 'hooks/useTranslations';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import useSafePresetSchema from 'pages/LegalEntitiesPage/SavePresetPage/schema';
import { useLicenceContext } from 'models/licenceContext';

export interface IPresetFormValues {
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
}

const initialFormValues = {
  name: '',
  emailNotificationEnabled: false,
  notificationEnabled: false,
};

const SavePresetPage = () => {
  const {
    gender,
    getGender,
    legalVisibility,
    getLegalVisibility,
    legalEntityPrefix,
    getLegalEntityPrefix,
    organizationType,
    getOrganizationType,
  } = useDictionary();
  const { t } = useTranslations();
  const { schema } = useSafePresetSchema();

  const [filtersArray, setFiltersArray] = useState<{ key: string; value: string }[]>([]);
  const { type } = useParams<{ type: ESearchFilterKeys | ELegalEntityTypes }>();
  const [error, setError] = useState<string>('');
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { savePreset } = useSavePreset();
  const { setAlert } = useAlert();
  const { isOnlineLicence } = useLicenceContext();
  const { state: routerState }: any = useLocation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IPresetFormValues>({
    defaultValues: initialFormValues,
    resolver: yupResolver(schema),
  });
  const filters = SearchFilterStore.getValue(type as ESearchFilterKeys);

  const filtersWithDictionaries = () => {
    switch (type) {
      case ELegalEntityTypes.individual:
        return setFiltersArray(
          mapValuesWithDictionariesHelper(
            objectToKeyValueArray(filters).filter((record) =>
              permittedIndividualFilterFields.includes(record.key),
            ),
            {
              genderUniqueId: parseDictionary(gender, 'name'),
              visibilityUniqueId: parseDictionary(legalVisibility, 'name'),
              prefixGroupUniqueId: parseDictionary(legalEntityPrefix, 'name'),
            },
          ),
        );
      case ELegalEntityTypes.organisation:
        return setFiltersArray(
          mapValuesWithDictionariesHelper(
            objectToKeyValueArray(filters).filter((record) =>
              permittedOrganisationFilterFields.includes(record.key),
            ),
            {
              organizationTypeUniqueId: parseDictionary(organizationType, 'name'),
              visibilityUniqueId: parseDictionary(legalVisibility, 'name'),
            },
          ),
        );

      default:
        return [];
    }
  };

  useEffect(() => {
    (async function init() {
      if (type === ELegalEntityTypes.individual) {
        if (!gender) {
          getGender();
        }
        if (!legalVisibility) {
          getLegalVisibility();
        }
        if (!legalEntityPrefix) {
          getLegalEntityPrefix();
        }
      } else if (type === ELegalEntityTypes.organisation) {
        if (!organizationType) {
          getOrganizationType();
        }
        if (!legalVisibility) {
          getLegalVisibility();
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (filters) {
      filtersWithDictionaries();
    }
  }, [gender, legalVisibility, legalEntityPrefix]);

  const getBackRoute = () => {
    switch (type) {
      case ELegalEntityTypes.individual:
        return `${ERouteLinks.LegalEntities}?expand=${type}`;
      case ELegalEntityTypes.organisation:
        return `${ERouteLinks.LegalEntities}?expand=organisation`;

      default:
        return ERouteLinks.LegalEntities;
    }
  };

  const getCorrectLabels = () => {
    switch (type) {
      case ELegalEntityTypes.individual:
        return ESearchFormLabelsILE;
      case ELegalEntityTypes.organisation:
        return ESearchFormLabelsOLE;

      default:
        return ESearchFormLabelsILE;
    }
  };

  const labels = getCorrectLabels();

  useEffect(() => {
    if (filters === null) {
      navigate(getBackRoute());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onSubmitForm = async (data: IPresetFormValues) => {
    if (type) {
      setIsSubmitLoading(true);
      const body = mapValuesToPresetRequest(type, filters);

      if (body) {
        try {
          const res = await savePreset({
            name: data.name,
            emailNotificationEnabled: data.emailNotificationEnabled,
            notificationEnabled: data.notificationEnabled,
            type,
            body,
          });
          if (res.status === 200) {
            setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
              text: t('saveMyFilter.save.success.text'),
              variant: EAlertVariants.success,
            });
            return navigate(`${ERouteLinks.MyFilters}`);
          }

          setError(res.response.data.message);
        } catch (e: any) {
          console.error(e);
        }
      }
    }
    return setIsSubmitLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntity.individual.label')
                : t('legalEntity.organisation.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          t('saveMyFilter.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('saveMyFilter.header')}</StyledPageTitle>

      <FormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box mb={1}>
                <FormInput
                  name="name"
                  label={`${t('saveMyFilter.filterName.label')}*`}
                  placeholder={t('saveMyFilter.filterName.label')}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <StyledSelectedFiltersWrapper container rowGap={1}>
                <Grid item>
                  <Typography variant="body2">{`${t(
                    'saveMyFilter.selectedCriteria.section',
                  )}:`}</Typography>
                </Grid>
                <StyledFilterChipWrapper item>
                  {filtersArray &&
                    filtersArray.map((item: { key: string; value: string }) => (
                      <FilterChip
                        key={item.key}
                        name={item.key}
                        label={t(labels?.[item.key as keyof typeof labels]) || item.key}
                        values={item.value}
                      />
                    ))}
                </StyledFilterChipWrapper>
              </StyledSelectedFiltersWrapper>
            </Grid>

            <Grid item xs={12} container columnSpacing={3}>
              <Grid item xs={12}>
                <FormCheckbox
                  name="notificationEnabled"
                  label={t('saveMyFilter.notifyMeViaSystem.checkbox.label')}
                  control={control}
                  errors={errors}
                />
              </Grid>
              {isOnlineLicence() && (
                <Grid item xs={12}>
                  <FormCheckbox
                    name="emailNotificationEnabled"
                    label={t('saveMyFilter.notifyMeViaEmail.checkbox.label')}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  type="submit"
                  fullWidth
                  label={t('saveMyFilter.save.button')}
                  variant={EButtonVariants.contained}
                  isLoading={isSubmitLoading}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Button
                  fullWidth
                  label={t('general.cancel.button')}
                  variant={EButtonVariants.outlined}
                  onClick={() => navigate(routerState.cancelPath || getBackRoute())}
                />
              </Grid>
            </Grid>
            <Grid item mt={2} xs={12}>
              <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
            </Grid>
            {error && (
              <Grid item mt={2} xs={12}>
                <Alert text={error} variant={EAlertVariants.error} />
              </Grid>
            )}
          </Grid>
        </form>
      </FormWrapper>
    </>
  );
};

export { SavePresetPage };
