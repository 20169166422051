import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useParams } from 'react-router-dom';
import { LegalEntityGroupForm } from 'pages/LegalEntitiesPage/LegalEntityGroupForm';
import { ILegalEntityEditGroupFormValues } from 'models/form';
import { getLegalEntityGroup } from 'requests/legalEntities';
import { ILegalEntityGroupResponse } from 'models/legalEntity';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';
import { useTranslations } from 'hooks/useTranslations';

const LegalEntityEditGroupPage = () => {
  const { id = '', groupId = '' } = useParams<{ id: string; groupId: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { t } = useTranslations();
  const { handleEditGroup, navigateToDetails } = useLegalEntities();
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  const [legalEntityGroup, setLegalEntityGroup] = useState<ILegalEntityGroupResponse | null>();

  useEffect(() => {
    (async function getLegalEntityGroupDataInit() {
      if (id && groupId) {
        const response = await getLegalEntityGroup(id, groupId);
        if (response) {
          setLegalEntityGroup(response);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, groupId]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = async (data: ILegalEntityEditGroupFormValues) => {
    try {
      await handleEditGroup({ name: data.name, uniqueId: id }, groupId);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=groups`,
          },
          'Edit group',
        ]}
      />
      <StyledPageTitle variant="h4">Edit Group</StyledPageTitle>
      <FormWrapper>
        <LegalEntityGroupForm
          onCancel={navigateToDetails}
          onSubmit={handleSubmit}
          initialFormValues={legalEntityGroup}
        />
      </FormWrapper>
      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { LegalEntityEditGroupPage };
