import React, { useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { useLocation, useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { StyledDetailsWrapper } from 'pages/Firearms/RegisteredFirearmDeleteAttachmentPage/styles';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useFirearms } from 'pages/Firearms/hooks';
import { useTranslations } from 'hooks/useTranslations';
import { simpleFormatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';

const RegisteredFirearmDeleteAttachmentPage = () => {
  const { id, attachmentId } = useParams<{ id: string; attachmentId: string }>();
  const { state }: any = useLocation();
  const { t } = useTranslations();
  const { handleDeleteAttachment, navigateToDetails } = useFirearms();
  const { longDateFormat } = useGlobalProperty();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  if (state?.attachment?.id !== attachmentId) {
    navigateToDetails();
  }

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (state?.attachment) {
        const result = await handleDeleteAttachment(id as string, state?.attachment.id);

        if (result.status === 204) {
          navigateToDetails();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('firearms.registeredFirearms.accordion.name'),
            route: ERouteLinks.Firearms,
          },
          {
            label: t('registeredFirearms.firearmsDetails.header'),
            route: `${ERouteLinks.ViewRegisteredFirearm.replace(
              ':id',
              id as string,
            )}?expand=attachment`,
          },
          t('deleteAttchment.deleteAttachment.header'),
        ]}
      />
      {state?.attachment && (
        <>
          <StyledPageTitle variant="h4">
            {t('deleteAttchment.deleteAttachment.header')}
          </StyledPageTitle>

          <Typography variant="body1">{t('ancillary.attachment.delete.text')}</Typography>

          <StyledDetailsWrapper container mt={2} sx={{ width: { lg: '70%' } }}>
            <Grid item xs={12} md={6} sx={{ mb: { xs: 3, md: 0 } }}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAttchment.creationDateAndTime.label')}
              </Typography>
              <Typography variant="body2">
                {simpleFormatDate(state?.attachment.createdDate, longDateFormat)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('deleteAttchment.attachmentName.label')}
              </Typography>
              <Typography variant="body2">{state?.attachment.name}</Typography>
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Button
                fullWidth
                label={t('general.yes.button')}
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <Button
                fullWidth
                label={t('general.no.button')}
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { RegisteredFirearmDeleteAttachmentPage };
