import React, { useEffect } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useTranslations } from 'hooks/useTranslations';
import { ApiKeyForm } from 'pages/ApiKeysPage/ApiKeyForm';
import { IApiKeyRequest } from 'models/apiKey';
import { useApiKeys } from 'pages/ApiKeysPage/hooks';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';

const AddApiKeyPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { createApiKeyHandler } = useApiKeys();
  const { setAlert, clearAlert, samePageAlert } = useAlert();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmit = async (payload: IApiKeyRequest) => {
    try {
      await createApiKeyHandler(payload);
      setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('addApiKey.success.text'),
        variant: EAlertVariants.success,
      });
      navigate(ERouteLinks.ApiKeysPage);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: e.response.data.errors[0].message,
        variant: EAlertVariants.error,
      });
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('apiKeys.header'),
            route: ERouteLinks.ApiKeysPage,
          },
          t('addApiKey.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addApiKey.header')}</StyledPageTitle>
      <ApiKeyForm onSubmit={onSubmit} />

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export default AddApiKeyPage;
