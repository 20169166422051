import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { EPerPages, ISortOptions } from 'models/table';
import { queryStringWithSort } from 'helpers/request';
import {
  EDictionaryTypes,
  ESpecificDictionaryTypes,
  ETransactionDictionaryItemTypes,
  IDictionariesResponse,
  IDictionaryDetailResponse,
  IDictionaryLanguage,
  IDictionaryResponse,
  IDictionarySearchLike,
  IDictionaryValueFormValues,
  IDictionaryValues,
} from 'models/dictionary';

const getDictionaries = async (
  page: number,
  size: EPerPages,
  sort: ISortOptions | null | undefined,
) => {
  const queryString = queryStringWithSort({ page: page - 1, size }, sort);
  const { data } = await apiClient.get<TResponse<IDictionariesResponse>>(
    `${ApiEndpoints.GET_DICTIONARIES()}?${queryString}`,
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getDictionaryDetail = async (
  type: EDictionaryTypes,
  page: number,
  size: EPerPages,
  sort: ISortOptions | null | undefined,
) => {
  const queryString = queryStringWithSort({ page: page - 1, size }, sort);
  const { data } = await apiClient.get<TResponse<IDictionaryDetailResponse>>(
    `${ApiEndpoints.GET_DICTIONARY_DETAIL(type)}?${queryString}`,
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getDictionary = async (type: EDictionaryTypes) => {
  const { data } = await apiClient.get<TResponse<IDictionaryResponse>>(
    ApiEndpoints.GET_DICTIONARY(type),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getDictionaryNamesLike = async (type: EDictionaryTypes, body: IDictionarySearchLike) => {
  const { data } = await apiClient.post<TResponse<{ name: string[] }>>(
    ApiEndpoints.GET_DICTIONARY_LIKE(type),
    body,
  );

  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getDictionaryTypeLanguages = async (type: EDictionaryTypes) => {
  const { data } = await apiClient.get<TResponse<IDictionaryLanguage[]>>(
    ApiEndpoints.GET_DICTIONARY_LANGUAGES(type),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getDictionaryById = async (id: string) => {
  const { data } = await apiClient.get(ApiEndpoints.GET_DICTIONARY_BY_ID(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getSpecificDictionary = async (type: ESpecificDictionaryTypes) => {
  const { data } = await apiClient.get<TResponse<IDictionaryResponse>>(
    ApiEndpoints.GET_SPECIFIC_DICTIONARY(type),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getTransactionInitialTypesDictionary = async (
  itemType: ETransactionDictionaryItemTypes,
) => {
  const { data } = await apiClient.get<TResponse<IDictionaryResponse>>(
    ApiEndpoints.GET_INITIAL_TRANSACTION_DICTIONARY(itemType),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getTransactionMiddleTypesDictionary = async (
  itemType: ETransactionDictionaryItemTypes,
) => {
  const { data } = await apiClient.get<TResponse<IDictionaryResponse>>(
    ApiEndpoints.GET_MIDDLE_TRANSACTION_DICTIONARY(itemType),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const createDictionaryValue = async (
  body: IDictionaryValueFormValues,
  type: EDictionaryTypes,
) =>
  apiClient.post<TResponse<IDictionaryValues>>(
    ApiEndpoints.CREATE_DICTIONARY_VALUE(type),
    body,
  );

const updateDictionaryValue = async (body: IDictionaryValueFormValues, id: string) =>
  apiClient.put<TResponse<IDictionaryValues>>(ApiEndpoints.UPDATE_DICTIONARY_VALUE(id), body);

export {
  getDictionaries,
  getDictionaryDetail,
  getDictionary,
  getSpecificDictionary,
  getTransactionInitialTypesDictionary,
  getTransactionMiddleTypesDictionary,
  getDictionaryById,
  createDictionaryValue,
  updateDictionaryValue,
  getDictionaryTypeLanguages,
  getDictionaryNamesLike,
};
