import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { EMIMETypes } from 'constants/MIMETypes';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from '../../../theme/colors';

const MAX_FILE_SIZE_IN_MB = 3;

interface IImportFirearms {
  open: boolean;
  handleClose: () => void;
  changeFirearmsListValue: (arg: any) => void;
}

const ImportFirearms = ({ open, handleClose, changeFirearmsListValue }: IImportFirearms) => {
  const { t } = useTranslations();
  const [errorLabel, setErrorLabel] = useState('');
  useEffect(() => {
    if (open) {
      setErrorLabel('');
    }
  }, [open]);

  const validateFile = (file: File) => {
    const fileType: any = file.type;
    if (file) {
      setErrorLabel('');
      if (file.size === 0) {
        // @ts-ignore
        setError(`attachments[${index}].file`, {
          type: 'custom',
          message: t('addAttachment.emptyFile.text'),
        });
        return false;
      }
      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
        setErrorLabel(
          t('addFirearm.firearmList.section.importFirearms.popup.text.invalidSize'),
        );
        return false;
      }
      if (![EMIMETypes.MS_CSV, EMIMETypes.CSV].includes(fileType)) {
        setErrorLabel(
          t('addFirearm.firearmList.section.importFirearms.popup.text.invalidExtension'),
        );
        return false;
      }
      return true;
    }
    return false;
  };

  function parseCSV(csvData: string) {
    const rows = csvData.split('\n');
    return rows.map((row) => {
      const separator = row.includes(';') ? ';' : ',';
      return row.split(separator).map((cell) => cell.replace(/"/g, ''));
    });
  }

  const handleUploadFile = (file: File) => {
    if (validateFile(file)) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const csvData = event?.target?.result;
        if (csvData && typeof csvData === 'string') {
          const rows = parseCSV(csvData);
          const isLastRowEmpty = rows[rows.length - 1].every((cell) => cell === '');
          if (isLastRowEmpty) {
            rows.pop();
          }
          changeFirearmsListValue(rows);
          handleClose();
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <Dialog open={open} keepMounted sx={{ p: 3 }}>
      <DialogTitle sx={{ color: colors.primary500 }}>
        {t('addFirearm.firearmList.section.importFirearms.popup.text.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: colors.gray900 }}>
          {t('addFirearm.firearmList.section.importFirearms.popup.text.subtitle')}
        </DialogContentText>
        <Button
          isUploadButton
          onClick={(files) => handleUploadFile(files[0])}
          fullWidth
          label={t('addFirearm.firearmList.section.importFirearms.popup.button.addFile')}
          id="add_file-button"
          icon={EIconTypes.plus}
          size={EButtonSizes.small}
          sx={{ mt: 2 }}
          isMultiple
        />
        <FormErrorLabel label={errorLabel} />
      </DialogContent>

      <DialogActions>
        <Button
          label={t('addFirearm.firearmList.section.importFirearms.popup.button.cancel')}
          id="cancel-button"
          onClick={handleClose}
          variant={EButtonVariants.outlined}
          sx={{ mr: 2, mb: 1 }}
        />
      </DialogActions>
    </Dialog>
  );
};

export { ImportFirearms };
