import { createContext, useContext } from 'react';
import { IAddTrustedDeviceBodyRequest } from 'models/trustedDevice';

export interface ITrustedDeviceContext {
  addTrustedDevice: (body: IAddTrustedDeviceBodyRequest, successCallback: () => void) => void;
  fetchTrustedDevices: (successCallback: () => void) => void;
}

export const TrustedDeviceContext = createContext<ITrustedDeviceContext>(
  {} as ITrustedDeviceContext,
);

export const useTrustedDevice = (): ITrustedDeviceContext => useContext(TrustedDeviceContext);
