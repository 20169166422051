import { useTranslations } from 'hooks/useTranslations';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { AncillariesLocationMap } from 'pages/AncillariesPage/AncillariesMap/AncillariesLocationMap';
import { EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import { useLicenceContext } from 'models/licenceContext';
import { Loader } from 'components/atoms/Loader';
import { Alert, EAlertVariants } from 'components/atoms/Alert';

export const RegisteredAncillariesMapPage = () => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { isOnlineLicence } = useLicenceContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    getAccessoriesDataForMap,
    getAccessoryDetail,
    accessories,
    getAmmunitionDataForMap,
    ammunition,
    getAmmunitionDetail,
    getComponentsDataForMap,
    components,
    getComponentDetail,
    ancillaryIsEmpty,
    getAncillariesDataForMap,
    ancillaries,
  } = useAncillaries();

  useEffect(() => {
    if (routerState) {
      if (routerState.ancillaryType === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        (async function getAccessoriesDataInit() {
          if (routerState?.ancillaryQuery) {
            setIsLoading(true);
            await getAccessoriesDataForMap(routerState.ancillaryQuery);
            setIsLoading(false);
          }
        })();
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        (async function getAmmunitionDataInit() {
          if (routerState?.ancillaryQuery) {
            setIsLoading(true);
            await getAmmunitionDataForMap(routerState.ancillaryQuery);
            setIsLoading(false);
          }
        })();
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        (async function getComponentsDataInit() {
          if (routerState?.ancillaryQuery) {
            setIsLoading(true);
            await getComponentsDataForMap(routerState.ancillaryQuery);
            setIsLoading(false);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState?.ancillaryQuery]);

  useEffect(() => {
    if (routerState) {
      if (routerState.ancillaryType === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        (async function getAccessoryDataInit() {
          if (routerState?.ancillaryUniqueId) {
            setIsLoading(true);
            await getAccessoriesDataForMap({
              uniqueIds: [routerState.ancillaryUniqueId],
              ancillaryType: 'ACCESSORY',
            });
            setIsLoading(false);
          }
        })();
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        (async function getAmmunitionDataInit() {
          if (routerState?.ancillaryUniqueId) {
            setIsLoading(true);
            await getAmmunitionDataForMap({
              uniqueIds: [routerState.ancillaryUniqueId],
              ancillaryType: 'AMMUNITION',
            });
            setIsLoading(false);
          }
        })();
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        (async function getComponentDataInit() {
          if (routerState?.ancillaryUniqueId) {
            setIsLoading(true);
            await getComponentsDataForMap({
              uniqueIds: [routerState.ancillaryUniqueId],
              ancillaryType: 'COMPONENT',
            });
            setIsLoading(false);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState?.ancillariesUniqueId, routerState.ancillaryType]);

  useEffect(() => {
    if (routerState) {
      if (routerState.ancillaryType === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        (async function getData() {
          if (routerState?.ancillariesUniqueId) {
            setIsLoading(true);
            await getAccessoriesDataForMap({
              uniqueIds: routerState.ancillariesUniqueId,
            });
            setIsLoading(false);
          }
        })();
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        (async function getData() {
          setIsLoading(true);
          if (routerState?.ancillariesUniqueId) {
            await getAmmunitionDataForMap({
              uniqueIds: routerState.ancillariesUniqueId,
            });
          }
          setIsLoading(false);
        })();
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        (async function getData() {
          if (routerState?.ancillariesUniqueId) {
            setIsLoading(true);
            await getComponentsDataForMap({
              uniqueIds: routerState.ancillariesUniqueId,
            });
            setIsLoading(false);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerState?.ancillariesUniqueId, routerState.ancillaryType]);

  useEffect(() => {
    (async function getData() {
      if (routerState.transactionUniqueId) {
        await getAncillariesDataForMap(routerState.transactionUniqueId);
        if (ancillaries) {
          setIsLoading(false);
        } else {
          setIsLoading(true);
        }
      }
    })();
  }, [routerState.ancillariesUniqueId]);

  const updateLegality = async (value: string) => {
    if (routerState?.ancillaryQuery) {
      routerState.ancillaryQuery.legalityUniqueId = value;
      if (routerState) {
        if (routerState.ancillaryType === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
          await (async function getAccessoriesDataInit() {
            if (routerState?.ancillaryQuery) {
              await getAccessoriesDataForMap(routerState.ancillaryQuery);
            }
          })();
        }
        if (routerState.ancillaryType === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
          await (async function getAmmunitionDataInit() {
            if (routerState?.ancillaryQuery) {
              await getAmmunitionDataForMap(routerState.ancillaryQuery);
            }
          })();
        }
        if (routerState.ancillaryType === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
          await (async function getComponentDataInit() {
            if (routerState?.ancillaryQuery) {
              await getComponentsDataForMap(routerState.ancillaryQuery);
            }
          })();
        }
      }
    } else if (routerState.transactionUniqueId) {
      await (async function getAncillariesDataInit() {
        await getAncillariesDataForMap(routerState.transactionUniqueId, value);
      })();
    }
  };

  // eslint-disable-next-line consistent-return
  const handleNavigation = () => {
    if (routerState) {
      if (routerState.ancillaryType === EArtAncillaryTypeName.ACCESSORY.toLowerCase()) {
        return `${ERouteLinks.Ancillaries}?expand=accessories`;
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.AMMUNITION.toLowerCase()) {
        return `${ERouteLinks.Ancillaries}?expand=ammunition`;
      }
      if (routerState.ancillaryType === EArtAncillaryTypeName.COMPONENTS.toLowerCase()) {
        return `${ERouteLinks.Ancillaries}?expand=components`;
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={
          routerState.breadcrumbs || [
            { label: t('module.ancillaries.name'), route: ERouteLinks.Ancillaries },
            t('ancillaries.ancillariesLocation.showOnMap.label'),
          ]
        }
      />
      <StyledPageTitle variant="h4">
        {routerState.ancillaryType === EArtAncillaryTypeName.ACCESSORY.toLowerCase() &&
          t('ancillaries.ancillariesLocation.header')}
        {routerState.ancillaryType === EArtAncillaryTypeName.AMMUNITION.toLowerCase() &&
          t('ancillaries.ancillariesLocation.ammunition.header')}
        {routerState.ancillaryType === EArtAncillaryTypeName.COMPONENTS.toLowerCase() &&
          t('ancillaries.ancillariesLocation.component.header')}
      </StyledPageTitle>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Grid item>
          <Button
            fullWidth
            label={t('ancillaries.ancillariesLocation.back.button')}
            variant={EButtonVariants.outlined}
            size={EButtonSizes.small}
            icon={EIconTypes.left}
            onClick={
              !routerState.navigateBack
                ? () => {
                    navigate(`${handleNavigation()}`, {
                      state: {
                        formValues: {
                          ...routerState?.ancillaryQuery,
                        },
                        options: {
                          ownerOptions: routerState?.options.ownerOptions,
                          keeperOptions: routerState?.options.keeperOptions,
                        },
                      },
                    });
                  }
                : () => navigate(routerState.navigateBack)
            }
          />
        </Grid>
      </Grid>
      {isLoading && <Loader isLoading={isLoading} />}
      {!isLoading && (
        <div>
          {!ancillaryIsEmpty ? (
            <AncillariesLocationMap
              accessories={accessories}
              ammunition={ammunition}
              components={components}
              ancillaries={ancillaries}
              type={routerState?.ancillaryType}
              latitude={routerState?.latitude}
              longitude={routerState?.longitude}
              legalityUniqueId={routerState?.legalityUniqueId}
              showLegalityBar={routerState?.showLegalityBar}
              updateLegality={updateLegality}
              defaultLegality={routerState?.ancillaryQuery?.legalityUniqueId}
              isOnline={isOnlineLicence()}
            />
          ) : (
            <Grid item mt={2} xs={12}>
              <Alert
                text={t('ancillaries.ancillariesLocation.noAncillaries.text')}
                variant={EAlertVariants.warning}
              />
            </Grid>
          )}
        </div>
      )}
    </>
  );
};
