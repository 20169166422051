import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { RequestHeaderKeyTypes, RequestHeaderValueTypes } from 'constants/RequestHeaderTypes';
import { ILESearchQuery } from 'models/report';
import { queryString, queryStringOfSort, queryStringWithSort } from 'helpers/request';
import { IAuditLogSearchFormValues } from 'models/auditLog';
import { simpleFormatDate } from 'utils/date';
import { EDateFormats } from 'constants/dates';
import { ISearchTransactionFormValues } from 'models/form';
import { exportTimeout } from 'constants/Export';
import { ISortOptions } from 'models/table';

const exportLECSV = async (
  LEType: ELegalEntityTypes,
  queryLE: ILESearchQuery,
  sort: ISortOptions | null,
): Promise<Blob | null> => {
  const { customFields } = queryLE;
  Object.keys(queryLE).forEach((key: string) => {
    if (!queryLE[key] || key === 'customFields') {
      delete queryLE[key];
    }
  });

  if (LEType) {
    let endpoint = '';
    if (LEType === ELegalEntityTypes.individual) {
      endpoint = ApiEndpoints.EXPORT_ILE_CSV();
    } else if (LEType === ELegalEntityTypes.organisation) {
      endpoint = ApiEndpoints.EXPORT_OLE_CSV();
    }

    const dataPart = sort !== null ? queryStringWithSort(queryLE, sort) : queryString(queryLE);

    const { data } = await apiClient.post(`${endpoint}?${dataPart}`, customFields, {
      timeout: exportTimeout,
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    });
    const date = simpleFormatDate(new Date(), EDateFormats.yyyyMMddhhmm);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `legal_entity_${LEType}${date}.csv`);
    document.body.appendChild(link);
    link.click();

    if (isResponseError(data)) {
      return null;
    }

    return data;
  }
  return null;
};

const exportFirearmTransactionsCSV = async (
  firearmId: string,
  query: Partial<ISearchTransactionFormValues>,
  sort: ISortOptions | null,
): Promise<Blob | null> => {
  const sortPart = sort !== null ? `?${queryStringOfSort(sort)}` : '';

  const { data } = await apiClient.post(
    `${ApiEndpoints.EXPORT_FIREARM_TRANSACTIONS_CSV(firearmId)}${sortPart}`,
    query,
    {
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );

  const date = simpleFormatDate(new Date(), EDateFormats.yyyyMMddhhmm);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', `firearm_transactions_${date}.csv`);
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const exportAuditLog = async (query: Partial<IAuditLogSearchFormValues>) => {
  const { data } = await apiClient.post(
    `${ApiEndpoints.EXPORT_AUDIT_LOG()}?${queryString(query)}`,
    null,
    {
      timeout: exportTimeout,
      headers: {
        [RequestHeaderKeyTypes.RESPONSE_TYPE]: RequestHeaderValueTypes.BLOB,
      },
    },
  );

  const date = simpleFormatDate(new Date(), EDateFormats.yyyyMMddhhmm);
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `audit_log_${date}.csv`);
  document.body.appendChild(link);
  link.click();

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export { exportLECSV, exportAuditLog, exportFirearmTransactionsCSV };
