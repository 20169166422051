import { IReducerAction } from 'models/context';
import { LicenceActionTypes } from 'state/actions/licence';

interface ILicenceState {
  licenceId: string;
  licenceType: string;
  startDate: string;
  expiryDate: string | null;
  currentSystemUsers: number | null;
  numberOfUsers: null;
}

export const licenceInitialState: ILicenceState = {
  licenceId: '',
  licenceType: '',
  startDate: '',
  expiryDate: '',
  currentSystemUsers: null,
  numberOfUsers: null,
};

export const reducer = (state: ILicenceState, { type, payload }: IReducerAction) => {
  switch (type) {
    case LicenceActionTypes.SET_LICENCE_DATA: {
      return { ...state, ...payload };
    }

    default:
      return state;
  }
};
