import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { FormSelect } from 'components/molecules/FormSelect';
import { mapDictionaryToOptionProp } from 'helpers/dictionary';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import { ESearchAncillariesFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import {
  initialLegalEntityAncillariesSearchFormValues,
  ISearchLegalEntityAncillariesFormValues,
} from 'models/form';
import { prepareFilters } from 'helpers/filters';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { EArtAncillaryType } from 'constants/ArtAccessoryType';
import { EFirearmStateId } from 'models/firearm';

interface IFirearmSearchForm {
  onSubmit: (arg: ISearchLegalEntityAncillariesFormValues) => void;
  isSearchButtonDisabled: boolean;
  isIndividualLE: boolean;
}

const AncillariesSearchForm = ({
  onSubmit,
  isSearchButtonDisabled = false,
  isIndividualLE,
}: IFirearmSearchForm) => {
  const {
    state,
    loadDictionaries,
    artAncillaryOptions,
    artComponentType,
    artAccessoryType,
    artAmmunitionType,
  } = useLegalEntities();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ISearchLegalEntityAncillariesFormValues>({
    defaultValues: initialLegalEntityAncillariesSearchFormValues,
  });
  const [values, setValues] = useState<ISearchLegalEntityAncillariesFormValues>(
    initialLegalEntityAncillariesSearchFormValues,
  );
  const [type, setType] = useState<IDictionary[] | null>(null);
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();

  const watchAncillary: unknown = watch('ancillaryUniqueId');

  useEffect(() => {
    if (watchAncillary) {
      setValue('ancillaryTypeUniqueId', '');
      switch (watchAncillary) {
        case EArtAncillaryType.ACCESSORIES:
          setType(artAccessoryType);
          break;
        case EArtAncillaryType.AMMUNITION:
          setType(artAmmunitionType);
          break;
        case EArtAncillaryType.COMPONENTS:
          setType(artComponentType);
          break;
        default:
          setType(null);
          break;
      }
    } else {
      setType(null);
    }
  }, [watchAncillary]);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchLegalEntityAncillariesFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialLegalEntityAncillariesSearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
  };

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.ArtAccessoryType,
        EDictionaryTypes.ArtAmmunitionType,
        EDictionaryTypes.ArtComponentType,
        EDictionaryTypes.ArtAncillaryOptions,
        EDictionaryTypes.State,
      ]);
    })();
  }, [selectedLanguage]);

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="space-between" spacing={2} columns={10}>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(artAncillaryOptions)}
              name="ancillaryUniqueId"
              label={t(ESearchAncillariesFormLabelsTypes.ancillaryUniqueId)}
              canBeEmpty={true}
              setValue={(name: string, value: string) => {
                setValue('ancillaryUniqueId', value);
                setValue('ancillaryTypeUniqueId', '');
              }}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(type)}
              name="ancillaryTypeUniqueId"
              label={t(ESearchAncillariesFormLabelsTypes.ancillaryTypeUniqueId)}
              canBeEmpty={true}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={!type}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormInput
              name="fireCycleId"
              label={t(ESearchAncillariesFormLabelsTypes.fireCycleId)}
              setValue={setValue}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <FormSelect
              options={mapDictionaryToOptionProp(
                state,
                undefined,
                isIndividualLE
                  ? [
                      EFirearmStateId.EXPORTED,
                      EFirearmStateId.RECOVERED,
                      EFirearmStateId.SEIZED,
                    ]
                  : [EFirearmStateId.EXPORTED],
              )}
              name="stateUniqueId"
              canBeEmpty={true}
              setValue={setValue}
              label={t(ESearchAncillariesFormLabelsTypes.stateUniqueId)}
              control={control}
              errors={errors}
            />
          </Grid>
          <Grid item lg={2} md={5} sm={5} xs={10}>
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t('legalEntities.individual.accordion.search.button')}
              disabled={isSearchButtonDisabled}
              type="submit"
              fullWidth={true}
              sx={{ mt: 3.5 }}
            />
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="ancillaries"
        values={values}
        labels={ESearchAncillariesFormLabelsTypes}
        handleDelete={handleResetField}
        saveQueryParams={['type']}
        noPreset
        showSaveFilters={false}
        dictionaries={{
          ancillaryUniqueId: prepareFilters(artAncillaryOptions, 'uniqueId', 'name'),
          ancillaryTypeUniqueId: prepareFilters(type, 'uniqueId', 'name'),
          stateUniqueId: prepareFilters(state, 'uniqueId', 'name'),
        }}
        translationKeys={{
          selectedCriteria: 'searchForLegalEntityAncillary.selectedCriteria.label',
        }}
        saveRouterStateAfterClearAll={false}
      />
    </SearchWrapper>
  );
};

export { AncillariesSearchForm };
