import React, { useEffect } from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { ITranslationKey } from 'components/molecules/Table';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useAuth } from 'models/authContext';

interface IUserTableCard {
  data: ITableDataSource;
  activateUser: (id: string, active: boolean) => void;
  unbanUser: (id: string) => void;
  translationsKeys?: ITranslationKey | any;
}

const UserTableCard = ({
  data,
  activateUser,
  unbanUser,
  translationsKeys,
}: IUserTableCard) => {
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { longDateFormat, getDateFormat } = useGlobalProperty();
  const { user } = useAuth();

  useEffect(() => {
    getDateFormat();
  }, []);

  const statusChip = () => {
    if (data.removed) {
      return <Chips text={t('users.removed.label')} variant={EChipsVariants.error200} />;
    }
    if (data.blocked) {
      return <Chips text={t('users.blocked.label')} variant={EChipsVariants.warning100} />;
    }
    return (
      <Chips
        text={data.active ? t('users.active.label') : t('users.inactive.label')}
        variant={data.active ? EChipsVariants.success : EChipsVariants.error}
      />
    );
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('users.usersTable.email.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.email)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('users.usersTable.firstName.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.firstName)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('users.usersTable.lastName.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.lastName)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('users.usersTable.phoneNumber.column')}
        </Typography>
        <Typography variant="body2">{emptyFieldHolder(data.phoneNumber)}</Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('users.usersTable.status.column')}
        </Typography>
        {statusChip()}
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('users.usersTable.created.column')}
        </Typography>
        <Typography variant="body2">
          {data.removed ? formatDate(data.createdDate, longDateFormat) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('users.usersTable.lastLogin.column')}
        </Typography>
        <Typography variant="body2">
          {formatDate(data.lastLoginDate, longDateFormat)}
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('users.usersTable.actions.column')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t('users.usersTable.details.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.ViewUser.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            label={t(translationsKeys.editLink)}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.EditUser.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
        {user && data.id !== user.userId ? (
          <Grid item xs={12} sm={3}>
            {data.blocked ? (
              <Button
                label={t(translationsKeys.activateLink)}
                variant={EButtonVariants.outlined}
                onClick={() => unbanUser(data.id as string)}
                size="small"
                fullWidth
              />
            ) : (
              <Button
                label={
                  data.active
                    ? t(translationsKeys.deactivateLink)
                    : t(translationsKeys.activateLink)
                }
                variant={EButtonVariants.outlined}
                onClick={() =>
                  data.active
                    ? activateUser(data.id as string, false)
                    : activateUser(data.id as string, true)
                }
                size="small"
                fullWidth
              />
            )}
          </Grid>
        ) : (
          <Grid item xs={12} sm={3} />
        )}
      </Grid>
    </Grid>
  );
};

export { UserTableCard };
