import {
  format,
  formatDistanceToNowStrict,
  isAfter,
  isEqual,
  isMatch,
  parse,
  parseISO,
} from 'date-fns';

type TDate = Date | number | null | string;

export const isDateAfter = (date: TDate, dateToCompare: TDate) => {
  if (typeof date !== 'string' && date && typeof dateToCompare !== 'string' && dateToCompare) {
    return isAfter(date, dateToCompare);
  }
  return null;
};

export const isDateEqual = (date: TDate, dateToCompare: TDate) => {
  if (typeof date !== 'string' && date && typeof dateToCompare !== 'string' && dateToCompare) {
    return isEqual(date, dateToCompare);
  }
  return null;
};

// eslint-disable-next-line no-restricted-globals
export const isValidDate = (date: any) => !isNaN(Date.parse(date));

export function formatDate(date: string | null | undefined, dateFormat: string): string {
  if (date === null || date === undefined || date.trim() === '') {
    return '-';
  }
  const parseTimezone = date.match(/\[.+?]/g);
  const timezone = parseTimezone && parseTimezone.length ? parseTimezone[0] : '';
  return format(parseISO(date.slice(0, -timezone.length)), dateFormat);
}

export function leftDays(date: string | null | undefined, t: (key: string) => string): string {
  if (date === null || date === undefined || date.trim() === '') {
    return '';
  }
  const parseTimezone = date.match(/\[.+?]/g);
  const timezone = parseTimezone && parseTimezone.length ? parseTimezone[0] : '';
  const dateFormat = parseISO(date.slice(0, -timezone.length));

  return formatDistanceToNowStrict(dateFormat, { unit: 'day' })
    .replace('days', t('formatDate.days'))
    .replace('day', t('formatDate.day'));
}

export const getDateFromISO = (date: string | null) => {
  if (date === null || date === undefined || date.trim() === '') {
    return '';
  }
  const parseTimezone = date.match(/\[.+?]/g);
  const timezone = parseTimezone && parseTimezone.length ? parseTimezone[0] : '';
  const dateFormat = parseISO(date.slice(0, -timezone.length));
  return dateFormat;
};

export const getDateFromISOOrNull = (date: string | null | Date) => {
  if (date instanceof Date) {
    return date;
  }
  if (date === null || date === undefined || date.trim() === '') {
    return null;
  }
  const parseTimezone = date.match(/\[.+?]/g);

  if (parseTimezone === null) {
    return new Date(date);
  }

  const timezone = parseTimezone && parseTimezone.length ? parseTimezone[0] : '';
  const dateFormat = parseISO(date.slice(0, -timezone.length));
  return dateFormat;
};

export function simpleFormatDate(
  date: Date | string | null | undefined,
  dateFormat: string,
): string {
  if (typeof date === 'string') {
    date = new Date(getDateFromISO(date as string));
  }
  if (date === null || date === undefined) {
    return '-';
  }
  return format(date, dateFormat);
}

export function convertSimpleDate(
  dateStr: string | null | undefined,
  dateFormat: string,
): String {
  if (
    dateStr === null ||
    dateStr === undefined ||
    dateStr.trim() === '' ||
    !isMatch(dateStr, dateFormat)
  ) {
    return '';
  }
  const date = parse(dateStr, dateFormat, new Date()).toString();
  return date !== 'Invalid Date' ? date : '';
}

export function convertToSimpleDate(
  date: null | undefined | Date,
  dateFormat: string,
): string {
  if (date === null || date === undefined) {
    return '';
  }
  try {
    return format(date, dateFormat);
  } catch (e: any) {
    return '';
  }
}

export const formatDateTimeToIso = (
  dateTime: string | Date | number | undefined | null,
  endDay?: boolean,
) => {
  if (dateTime === undefined || dateTime === null || dateTime === '') {
    return '';
  }
  const dateTimeString = dateTime.toString();

  if (dateTimeString.includes('[')) {
    return dateTimeString;
  }

  const newDate = new Date(dateTimeString);
  if (endDay) {
    newDate.setHours(23, 59, 59);
  }
  return `${newDate.toISOString().slice(0, -5)}Z`;
};

export const formatDateToRequest = (
  inputDate: string | Date | undefined,
  withTime: boolean = false,
  withTZOffset = false,
): string => {
  if (inputDate === '' || inputDate === null || inputDate === undefined) {
    return '';
  }
  inputDate = inputDate.toString();

  if (!withTime) {
    // eslint-disable-next-line prefer-destructuring
    inputDate = inputDate.split(' ')[0];
  }

  const inputDateObj = new Date(inputDate);

  if (!withTime) {
    inputDateObj.setHours(12, 0, 0);
  }

  if (withTZOffset) {
    return format(inputDateObj, "yyyy-MM-dd'T'HH:mm:ssxxx");
  }

  return format(inputDateObj, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export enum EAlertTime {
  SHORT = 7000,
  LONG = 12000,
}

export const clearAlertTimeout = (
  clearAlert: (type: string) => void,
  type: string,
  time: EAlertTime,
) => {
  const timeoutId = setTimeout(() => {
    clearAlert(type);
  }, time);

  return () => clearTimeout(timeoutId);
};
