import { EPermission } from 'models/permissions';

export interface INestedRoute {
  componentType: EPageComponentTypes;
  route: ERouteLinks;
  isProtected: boolean;
  permissions?: EPermission[];
  permissionOrCondition?: boolean;
}

export interface IPageBuilderConfig {
  componentType: EPageComponentTypes;
  route: ERouteLinks;
  isProtected: boolean;
  nestedRoutes?: INestedRoute[];
  permissions?: EPermission[];
  permissionOrCondition?: boolean;
}

export enum ERouteLinks {
  Auth = '',
  AuthWithSlash = '/',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  ResetPasswordLink = ':token',
  Dashboard = '/dashboard',
  DashboardSearchResultsPage = '/search',
  LegalEntities = '/legal-entities',
  LegalEntitiesExpand = ':expand',
  AddLegalEntity = '/legal-entity/add',
  EditLegalEntity = '/legal-entity/edit/:id',
  DeleteLegalEntity = '/legal-entity/delete/:id',
  ViewLegalEntity = '/legal-entity/:id',
  ViewLegalEntityFirearm = '/legal-entity-firearm/:id',
  LegalEntityAddGroup = '/legal-entity/:id/group/add',
  LegalEntityGroupFirearmSearch = '/legal-entity/:id/group/search-firearms',
  LegalEntityEditGroup = '/legal-entity/:id/group/edit/:groupId',
  LegalEntityDeleteGroup = '/legal-entity/:id/group/delete/:groupId',
  ViewLegalEntityGroup = '/legal-entity/:id/group/:groupId',
  LegalEntityGroupAddFirearm = '/legal-entity/:id/group/:groupId/firearm/add',
  LegalEntityAddComment = '/legal-entity/:id/comment/add',
  LegalEntityDeleteComment = '/legal-entity/:id/comment/delete/:commentId',
  LegalEntityAddAddress = '/legal-entity/:id/address/add',
  LegalEntityEditAddress = '/legal-entity/:id/address/edit/:addressId',
  LegalEntityDeleteAddress = '/legal-entity/:id/address/delete/:addressId',
  LegalEntityAddContact = '/legal-entity/:id/contact/add',
  LegalEntityEditContact = '/legal-entity/:id/contact/edit/:contactId',
  LegalEntityDeleteContact = '/legal-entity/:id/contact/delete/:contactId',
  LegalEntityAddFirearm = '/legal-entity/:id/firearm/add',
  LegalEntityAddTransactionForGroup = '/legal-entity/:id/transaction-for-group/:groupId/add',
  LegalEntityAddTransactionForAncillaryGroup = '/legal-entity/:id/transaction-for-ancillary-group/:groupId/add',
  LegalEntityAddAncillary = '/legal-entity/:id/ancillary/add',
  LegalEntityViewAncillary = '/legal-entity/:leId/ancillary/:type/:id',
  LegalEntityAddAncillaryGroup = '/legal-entity/:id/ancillary-group/add',
  ViewLegalEntityAncillaryGroup = '/legal-entity/:id/ancillary-group/:groupId',
  LegalEntityAddAncillaryGroupSearch = '/legal-entity/:id/ancillary-group/search-ancillaries',
  AddToAncillaryGroup = '/legal-entity/:id/ancillary-group/add-to-group/:groupId',
  EditAncillaryGroup = '/legal-entity/:id/ancillary-group/edit/:groupId',
  DeleteAncillaryGroup = '/legal-entity/:id/ancillary-group/delete/:groupId',
  MiddleTransaction = '/registered-firearms/:id/transaction/add',
  SearchForFRTFirearm = '/legal-entity/:id/firearm/add/search',
  SearchForARTAncillary = '/legal-entity/:id/ancillary/add/search',
  FRTFirearmDetails = '/legal-entity/:id/firearm/add/details/:frtId',
  FirearmsReferenceTableDetails = '/firearms-reference-table-details',
  SelectLocation = '/legal-entity/:id/firearm/add/location',
  Firearms = '/firearms',
  ViewRegisteredFirearm = '/registered-firearms/:id',
  EditRegisteredFirearm = '/registered-firearms/edit/:id',
  DisableRegisteredFirearm = '/registered-firearms/disable/:id',
  ViewRegisteredFirearmTransaction = '/registered-firearm-transaction/:id',
  RegisteredFirearmAddComment = '/registered-firearms/:id/comment/add',
  RegisteredFirearmDeleteComment = '/registered-firearms/:id/comment/delete/:commentId',
  RegisteredFirearmAddAttachment = '/registered-firearms/:id/attachment/add',
  RegisteredFirearmDeleteAttachment = '/registered-firearms/:id/attachment/delete/:attachmentId',
  ViewRegisteredFirearmAttachment = '/registered-firearms/:id/attachment/preview/:attachmentId',
  RegisteredFirearmsMap = '/registered-firearms-map',
  RegisteredAncillariesMap = '/registered-ancillaries-map',
  SelectLegalEntity = '/select-legal-entity',
  FRTDetails = '/firearms/frt/:frtId',
  FRTAddCalibre = '/firearms/frt/:frtId/add-calibre',
  FRTEditCalibre = '/firearms/frt/:frtId/:specificationId/edit-calibre',
  AddFRT = '/firearms/frt/add',
  EditFRT = '/firearms/frt/edit/:frtId',
  Ancillaries = '/ancillaries',
  ViewAncillaries = '/ancillaries-reference-table/:type/:id',
  EditAncillaries = '/ancillaries-reference-table/:type/edit/:id',
  AddAncillaries = '/ancillaries-reference-table/add',
  ViewAccessories = '/ancillaries/accessory/:id',
  ViewAmmunition = '/ancillaries/ammunition/:id',
  ViewComponents = '/ancillaries/component/:id',
  EditAccessories = '/ancillaries/accessory/edit/:id',
  EditComponents = '/ancillaries/component/edit/:id',
  ViewAncillaryDetails = '/ancillaries-reference-table-details/:type/:id',
  AccessoriesDeleteComment = '/ancillaries/accessory/:id/comment/delete/:commentId',
  AccessoriesAddComment = '/ancillaries/accessory/:id/comment/add',
  AccessoriesAddAttachment = '/ancillaries/accessory/:id/attachment/add',
  AccessoriesViewAttachment = '/ancillaries/accessory/:ancillaryId/attachment/:id',
  AccessoriesDeleteAttachment = '/ancillaries/accessory/:ancillaryId/attachment/:id/delete',
  ComponentsDeleteComment = '/ancillaries/component/:id/comment/delete/:commentId',
  ComponentsAddComment = '/ancillaries/component/:id/comment/add',
  ComponentsAddAttachment = '/ancillaries/component/:id/attachment/add',
  ComponentsViewAttachment = '/ancillaries/component/:ancillaryId/attachment/:id',
  ComponentsDeleteAttachment = '/ancillaries/component/:ancillaryId/attachment/:id/delete',
  AmmunitionDeleteComment = '/ancillaries/ammunition/:id/comment/delete/:commentId',
  AmmunitionAddComment = '/ancillaries/ammunition/:id/comment/add',
  AmmunitionAddAttachment = '/ancillaries/ammunition/:id/attachment/add',
  AmmunitionViewAttachment = '/ancillaries/ammunition/:ancillaryId/attachment/:id',
  AmmunitionDeleteAttachment = '/ancillaries/ammunition/:ancillaryId/attachment/:id/delete',
  ViewAncillary = '/ancillaries/:type/:id',
  ViewAncillaryGroup = '/ancillary-group/:type/:id',
  EditAncillary = '/ancillaries/:type/edit/:id',
  AddAncillariesSearchForFRTFirearm = '/ancillaries/add/firearm/search',
  AncillariesMiddleTransaction = '/ancillaries/:id/transaction/add',
  DisableAncillary = '/ancillaries/disable/:type/:id',
  Transactions = '/transactions',
  ViewTransaction = '/transactions/:id',
  CancelTransaction = '/transactions/:id/cancel',
  TransactionAddComment = '/transactions/:id/comment/add',
  TransactionDeleteComment = '/transactions/:id/comment/delete/:commentId',
  SaveTransactionPreset = '/transactions/:type/save-preset',
  SaveAncillariesPreset = '/ancillary/:type/save-preset',
  Users = '/users',
  AddUser = '/users/add',
  EditUser = '/users/edit/:id',
  GeneratePasswordUser = '/users/generate-password/:id',
  ViewUser = '/users/:id',
  EditUserStatus = '/users/:id/:status',
  AssignLESearch = '/users/:id/legal-entities',
  AssignLE = '/users/:id/legal-entity/assign/:LEId',
  DeleteAssignedLE = '/users/:id/legal-entity/delete/:LEId',
  DeleteUser = '/users/delete/:id',
  ActivateUser = '/users/activate/:id',
  DeactivateUser = '/users/deactivate/:id',
  Roles = '/roles',
  AddRole = '/role/add',
  EditRole = '/role/edit/:id',
  DeleteRole = '/role/delete/:id',
  ViewRole = '/role/:id',
  CustomFields = '/custom-fields',
  LicenceDetails = '/licence-details',
  LicenceReplace = '/licence/replace',
  AddCustomField = '/custom-field/add',
  EditCustomField = '/custom-field/edit/:id',
  ToggleCustomField = '/custom-field/:toggleType/:id',
  Administration = '/administration',
  Notifications = '/notifications',
  ViewNotification = '/notification/:id',
  TableTest = '/table',
  StyleGuide = '/styleguide',
  Account = '/account',
  AccountRemoveDevice = '/account/remove-device/:id',
  AccountChangePassword = '/account/change-password',
  MyFilters = '/account/my-filters',
  DeleteFilter = '/account/my-filters/delete/:id',
  AccountEdit = '/account/edit',
  NotFoundPage = '*',
  AccessDeniedPage = '/access-denied',
  ServerErrorPage = '/internal-server-error',
  SavePreset = '/legal-entity/:type/save-preset',
  SaveFirearmsPreset = '/firearms/:type/save-preset',
  Dictionaries = '/dictionaries',
  ViewDictionary = '/dictionary/:type',
  AddDictionaryValue = '/dictionary/:type/add',
  EditDictionaryValue = '/dictionary/:type/edit/:id',
  AddDictionary = '/dictionary/add',
  EditDictionary = '/dictionary/edit/:id',
  SystemSettings = '/settings',
  AuthenticationSetup = '/settings/authentication',
  DateTimeSetup = '/settings/date-time',
  LocationMapSetup = '/settings/location-map',
  NotificationsSetup = '/settings/notifications',
  LogosSetupPage = '/settings/logos-setup',
  DocumentsGeneratingSetupPage = '/settings/documents-generating',
  AuditLogPage = '/audit-log',
  AuditLogViewPage = '/audit-log/:id',
  ApiKeysPage = '/api-keys',
  AddApiKeyPage = '/api-keys/add',
  EditApiKeyPage = '/api-keys/edit/:id',
  ViewApiKeyPage = '/api-key/:id',
  DeleteApiKeyPage = '/api-key/delete/:id',
  StartLicencePage = '/init',
}

export enum EPageComponentTypes {
  AuthPage = 'AuthPage',
  ForgotPasswordPage = 'ForgotPasswordPage',
  ResetPasswordPage = 'ResetPasswordPage',
  ResetPasswordLink = 'ResetPasswordPage',
  DashboardPage = 'DashboardPage',
  DashboardSearchResultsPage = 'DashboardSearchResultsPage',
  LegalEntitiesPage = 'LegalEntitiesPage',
  LegalEntitiesExpand = 'LegalEntitiesPage',
  AddLegalEntityPage = 'AddLegalEntityPage',
  EditLegalEntityPage = 'EditLegalEntityPage',
  DeleteLegalEntityPage = 'DeleteLegalEntityPage',
  ViewLegalEntityPage = 'ViewLegalEntityPage',
  LegalEntityAddGroupPage = 'LegalEntityAddGroupPage',
  LegalEntityGroupFirearmSearchPage = 'LegalEntityGroupFirearmSearchPage',
  LegalEntityEditGroupPage = 'LegalEntityEditGroupPage',
  LegalEntityDeleteGroupPage = 'LegalEntityDeleteGroupPage',
  LegalEntityAddCommentPage = 'LegalEntityAddCommentPage',
  LegalEntityDeleteCommentPage = 'LegalEntityDeleteCommentPage',
  LegalEntityAddAddressPage = 'LegalEntityAddAddressPage',
  LegalEntityEditAddressPage = 'LegalEntityEditAddressPage',
  LegalEntityDeleteAddressPage = 'LegalEntityDeleteAddressPage',
  LegalEntityAddContactPage = 'LegalEntityAddContactPage',
  LegalEntityEditContactPage = 'LegalEntityEditContactPage',
  LegalEntityDeleteContactPage = 'LegalEntityDeleteContactPage',
  ViewLegalEntityGroupPage = 'ViewLegalEntityGroupPage',
  LegalEntityGroupAddFirearmPage = 'LegalEntityGroupAddFirearm',
  LegalEntityAddFirearmPage = 'LegalEntityAddFirearmPage',
  LegalEntityAddTransactionForGroupPage = 'LegalEntityAddTransactionForGroupPage',
  LegalEntityAddTransactionForAncillaryGroupPage = 'LegalEntityAddTransactionForAncillaryGroupPage',
  LegalEntityAddAncillaryPage = 'LegalEntityAddAncillaryPage',
  LegalEntityAddAncillaryGroupPage = 'LegalEntityAddAncillaryGroupPage',
  ViewLegalEntityAncillaryGroupPage = 'ViewLegalEntityAncillaryGroupPage',
  LegalEntityEditAncillaryGroupPage = 'LegalEntityEditAncillaryGroupPage',
  LegalEntityDeleteAncillariesGroupPage = 'LegalEntityDeleteAncillariesGroupPage',
  LegalEntityAddAncillaryGroupSearchPage = 'LegalEntityAddAncillaryGroupSearchPage',
  SearchForFRTFirearmPage = 'SearchForFRTFirearmPage',
  SearchForARTAncillaryPage = 'SearchForARTAncillaryPage',
  FRTFirearmDetailsPage = 'FRTFirearmDetailsPage',
  FRTDetailsPage = 'FRTDetailsPage',
  FRTAddCalibrePage = 'FRTAddCalibrePage',
  FRTEditCalibrePage = 'FRTEditCalibrePage',
  EditFRTPage = 'EditFRTPage',
  AddFRTPage = 'AddFRTPage',
  FirearmsReferenceTableDetailsPage = 'FirearmsReferenceTableDetailsPage',
  SelectLocationPage = 'SelectLocationPage',
  FirearmsPage = 'FirearmsPage',
  ViewRegisteredFirearmPage = 'ViewRegisteredFirearmPage',
  EditRegisteredFirearmPage = 'EditRegisteredFirearmPage',
  DisableRegisteredFirearmPage = 'DisableRegisteredFirearmPage',
  ViewRegisteredFirearmAttachment = 'ViewRegisteredFirearmAttachment',
  RegisteredFirearmAddCommentPage = 'RegisteredFirearmAddCommentPage',
  RegisteredFirearmDeleteCommentPage = 'RegisteredFirearmDeleteCommentPage',
  RegisteredFirearmDeleteAttachmentPage = 'RegisteredFirearmDeleteAttachmentPage',
  RegisteredFirearmAddAttachmentPage = 'RegisteredFirearmAddAttachmentPage',
  RegisteredFirearmsMapPage = 'RegisteredFirearmsMapPage',
  RegisteredAncillariesMapPage = 'RegisteredAncillariesMapPage',
  SelectLegalEntityPage = 'SelectLegalEntityPage',
  AncillariesAddTransactionPage = 'AncillariesAddTransactionPage',
  AncillariesPage = 'AncillariesPage',
  AddAncillaries = 'AddAncillaries',
  EditAncillaries = 'EditAncillaries',
  EditAncillary = 'EditAncillary',
  DetailAncillaries = 'DetailAncillaries',
  AncillaryDetails = 'AncillaryDetails',
  DetailAccessories = 'DetailAccessories',
  DetailComponents = 'DetailComponents',
  DetailAmmunition = 'DetailAmmunition',
  DisableAncillary = 'DisableAncillary',
  AccessoriesDeleteCommentPage = 'AccessoriesDeleteCommentPage',
  AccessoriesAddCommentPage = 'AccessoriesAddCommentPage',
  AmmunitionDeleteCommentPage = 'AmmunitionDeleteCommentPage',
  AmmunitionAddCommentPage = 'AmmunitionAddCommentPage',
  ComponentsDeleteCommentPage = 'ComponentsDeleteCommentPage',
  ComponentsAddCommentPage = 'ComponentsAddCommentPage',
  AccessoriesAddAttachmentPage = 'AccessoriesAddAttachmentPage',
  AccessoriesViewAttachmentPage = 'AccessoriesViewAttachmentPage',
  AccessoriesDeleteAttachmentPage = 'AccessoriesDeleteAttachmentPage',
  AmmunitionAddAttachmentPage = 'AmmunitionAddAttachmentPage',
  AmmunitionViewAttachmentPage = 'AmmunitionViewAttachmentPage',
  AmmunitionDeleteAttachmentPage = 'AmmunitionDeleteAttachmentPage',
  ComponentsAddAttachmentPage = 'ComponentsAddAttachmentPage',
  ComponentsViewAttachmentPage = 'ComponentsViewAttachmentPage',
  ComponentsDeleteAttachmentPage = 'ComponentsDeleteAttachmentPage',
  TransactionsPage = 'TransactionsPage',
  ViewTransactionPage = 'ViewTransactionPage',
  CancelTransactionPage = 'CancelTransactionPage',
  TransactionAddCommentPage = 'TransactionAddCommentPage',
  TransactionDeleteCommentPage = 'TransactionDeleteCommentPage',
  SaveTransactionPresetPage = 'SaveTransactionPresetPage',
  UsersPage = 'UsersPage',
  AddUserPage = 'AddUserPage',
  EditUserPage = 'EditUserPage',
  GeneratePasswordUserPage = 'GeneratePasswordUserPage',
  ViewUserPage = 'ViewUserPage',
  EditUserStatusPage = 'EditUserStatusPage',
  AssignLESearchPage = 'AssignLESearchPage',
  AssignLEPage = 'AssignLEPage',
  DeleteAssignedLEPage = 'DeleteAssignedLEPage',
  RolesPage = 'RolesPage',
  DictionariesPage = 'DictionariesPage',
  LicencePage = 'LicencePage',
  ReplaceLicencePage = 'ReplaceLicencePage',
  ViewDictionaryPage = 'ViewDictionaryPage',
  EditDictionaryPage = 'EditDictionaryPage',
  AddDictionaryValuePage = 'AddDictionaryValuePage',
  AddRolePage = 'AddRolePage',
  EditRolePage = 'EditRolePage',
  DeleteRolePage = 'DeleteRolePage',
  ViewRolePage = 'ViewRolePage',
  AdministrationPage = 'AdministrationPage',
  SystemSettingsPage = 'SystemSettingsPage',
  NotificationsSetupPage = 'NotificationsSetupPage',
  AuthenticationSetupPage = 'AuthenticationSetupPage',
  DateTimeSetupPage = 'DateTimeSetupPage',
  LogosSetupPage = 'LogosSetupPage',
  DocumentsGeneratingSetupPage = 'DocumentsGeneratingSetupPage',
  AuditLogPage = 'AuditLogPage',
  AuditLogViewPage = 'AuditLogViewPage',
  CustomFieldsPage = 'CustomFieldsPage',
  AddCustomFieldPage = 'AddCustomFieldPage',
  ToggleCustomFieldPage = 'ToggleCustomFieldPage',
  EditCustomFieldPage = 'EditCustomFieldPage',
  NotificationsPage = 'NotificationsPage',
  ViewNotificationPage = 'ViewNotificationPage',
  AccountPage = 'AccountPage',
  TableTestPage = 'TableTestPage',
  NotFoundPage = 'NotFoundPage',
  AccessDeniedPage = 'AccessDeniedPage',
  ServerErrorPage = 'ServerErrorPage',
  StyleGuidePage = 'StyleGuidePage',
  RemoveDevicePage = 'RemoveDevicePage',
  ChangePasswordPage = 'ChangePasswordPage',
  AccountEditPage = 'EditAccountPage',
  MyFiltersPage = 'MyFiltersPage',
  DeleteFilterPage = 'DeleteFilterPage',
  SavePresetPage = 'SavePresetPage',
  SaveFirearmsPresetPage = 'SaveFirearmsPresetPage',
  SaveAncillariesPresetPage = 'SaveAncillariesPresetPage',
  ApiKeysPage = 'ApiKeysPage',
  AddApiKeyPage = 'AddApiKeyPage',
  EditApiKeyPage = 'EditApiKeyPage',
  ViewApiKeyPage = 'ViewApiKeyPage',
  DeleteApiKeyPage = 'DeleteApiKeyPage',
  StartLicencePage = 'StartLicencePage',
  DeleteUserPage = 'DeleteUserPage',
}
