import React from 'react';
import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { Box, Typography } from '@mui/material';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import FormCustomField from 'components/organisms/FormCustomField';
import { useForm } from 'react-hook-form';
import { useFirearmCustomFieldFormSchema } from 'pages/Firearms/EditRegisteredFirearmPage/FirearmCustomFieldsForm/schemaValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { IRegisteredFirearmData } from 'models/registeredFirearm';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { useLocation, useNavigate } from 'react-router-dom';

interface IFirearmCustomFieldsForm {
  customFields: ICustomFieldByObjectType[];
  firearm: IRegisteredFirearmData;
  onSubmit: (data: { [key: string]: any }) => void;
  isSubmitLoading: boolean;
}

/**
 *
 * @param customFields
 * @param firearm
 */
const getInitialFormValues = ({
  customFields,
  firearm,
}: {
  customFields: ICustomFieldByObjectType[];
  firearm: IRegisteredFirearmData;
}) => {
  const initialFormValues: { [key: string]: any } = {};

  customFields.map((customField) => {
    const customFieldValue = firearm?.customFields?.find(
      (cField) => cField.customFieldUniqueId === customField.uniqueId,
    );

    if (customFieldValue) {
      if (customFieldValue.customFieldType === ECustomFieldType.BOOL) {
        initialFormValues[customField.name] = customFieldValue.value ? '1' : '0';
      } else {
        initialFormValues[customField.name] = customFieldValue.value;
      }
    } else {
      initialFormValues[customField.name] = '';
    }

    return false;
  });

  return initialFormValues;
};

/**
 * FirearmCustomFieldsForm Component
 * @param customFields
 * @param firearm
 * @param onSubmit
 * @param isSubmitLoading
 * @constructor
 */
const FirearmCustomFieldsForm = ({
  customFields,
  firearm,
  onSubmit,
  isSubmitLoading,
}: IFirearmCustomFieldsForm) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const { state: routerState }: any = useLocation();
  const { schema } = useFirearmCustomFieldFormSchema(customFields);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: getInitialFormValues({ customFields, firearm }),
    resolver: yupResolver(schema),
  });

  const handleCancel = () => {
    if (routerState?.prevPage) {
      navigate(routerState.prevPage);
    } else {
      navigate(-1);
    }
  };

  return (
    <Box>
      <StyledPageTitle variant="h4" sx={{ margin: '20px 0' }}>
        {t('customFields.header')}
      </StyledPageTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} container columnSpacing={3}>
          {customFields.map((customField) => (
            <Grid item xs={12} lg={3} key={customField.uniqueId}>
              <FormCustomField
                data={customField}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Grid>
          ))}

          <Grid item xs={12} container columnSpacing={3} rowGap={3} mt={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                id="save-button"
                variant={EButtonVariants.contained}
                isLoading={isSubmitLoading}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={handleCancel}
              />
            </Grid>
          </Grid>
          <Grid item mt={2}>
            <Typography variant="caption">{t('general.mandatoryField.text')}</Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default FirearmCustomFieldsForm;
