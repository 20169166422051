import styled from '@emotion/styled';

export const StyledPasswordRulesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    position: relative;
  }

  li:before {
    content: '•';
    position: absolute;
    margin-top: 2px;
  }

  li .MuiTypography-root {
    margin-left: 20px;
  }
`;
