import React from 'react';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { EDashboardSortType } from 'models/dashboard';
import { mapSortTypeToLabel } from 'pages/DashboardPage/helpers';
import { StyledDashboardSortButton } from 'pages/DashboardPage/styles';

interface IDashboardSortButton {
  sortType: EDashboardSortType;
  selected: boolean;
  setSelectedSortType: (sortType: EDashboardSortType) => void;
}

export const DashboardSortButton = ({
  sortType,
  selected,
  setSelectedSortType,
}: IDashboardSortButton) => {
  const { t } = useTranslations();

  const handleClick = () => {
    setSelectedSortType(sortType);
  };

  return (
    <StyledDashboardSortButton
      selected={selected}
      label={t(mapSortTypeToLabel(sortType))}
      variant={EButtonVariants.contained}
      onClick={handleClick}
      fullWidth
    />
  );
};
