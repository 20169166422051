import { ISearchFilterTransactionRequest } from 'models/searchFilter';
import { createSearchFilterTransaction } from 'requests/searchFilter';

interface ISavePreset {
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
  body: ISearchFilterTransactionRequest;
}

export const useSavePreset = () => {
  const savePreset = ({
    name,
    emailNotificationEnabled,
    notificationEnabled,
    body,
  }: ISavePreset) =>
    createSearchFilterTransaction(
      name,
      emailNotificationEnabled,
      notificationEnabled,
      body as ISearchFilterTransactionRequest,
    );

  return { savePreset };
};
