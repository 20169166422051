import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import FormWrapper from 'components/atoms/FormWrapper';
import { ERouteLinks } from 'models/route';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { useQuery } from 'hooks/useQuery';
import { ELegalEntityTypes } from 'constants/LegalEntityTypes';
import { useParams } from 'react-router-dom';
import { getLegalEntityContact } from 'requests/legalEntities';
import { ILegalEntityContact } from 'models/legalEntity';
import { StyledPageTitle } from 'theme/styles';
import {
  ILegalEntityContactFormValues,
  LegalEntityContactForm,
} from 'pages/LegalEntitiesPage/LegalEntityContactForm';
import { useTranslations } from 'hooks/useTranslations';

const LegalEntityEditContactPage = () => {
  const { id = '', contactId } = useParams<{ id: string; contactId: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const [contact, setContact] = useState<ILegalEntityContact | null>();
  const { handleEditContact, navigateToDetails } = useLegalEntities();
  const { t } = useTranslations();

  useEffect(() => {
    (async function getLEContactDataInit() {
      if (id && contactId) {
        const response = await getLegalEntityContact(id, contactId);
        if (response) {
          setContact(response);
        }
      }
    })();
  }, [id, contactId]);

  const handleSubmit = async (data: ILegalEntityContactFormValues) => {
    const { codeDictionaryUniqueId, value, ...rest } = data;
    const result = await handleEditContact(id, {
      ...rest,
      phoneNumber: {
        codeDictionaryUniqueId: data.codeDictionaryUniqueId,
        value: data.value,
      },
    });
    if (result) {
      navigateToDetails();
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.legalEntity.name'), route: ERouteLinks.LegalEntities },
          {
            label:
              type === ELegalEntityTypes.individual
                ? t('legalEntities.individual.accordion.label')
                : t('legalEntities.organization.accordion.label'),
            route: `${ERouteLinks.LegalEntities}?expand=${
              type === ELegalEntityTypes.individual ? 'individual' : 'organisation'
            }`,
          },
          {
            label: t('legalEntityDetails.legalEntityDetails.text'),
            route: `${ERouteLinks.ViewLegalEntity.replace(
              ':id',
              id,
            )}?type=${type}&expand=contact`,
          },
          t('editContact.header'),
        ]}
      />
      {contact && (
        <>
          <StyledPageTitle variant="h4">{t('editContact.header')}</StyledPageTitle>

          <FormWrapper>
            <LegalEntityContactForm
              onCancel={navigateToDetails}
              onSubmit={handleSubmit}
              initialFormValues={{
                uniqueId: contact.uniqueId,
                name: contact.name,
                role: contact.role,
                email: contact.email,
                codeDictionaryUniqueId: contact.phoneNumber.codeDictionaryUniqueId,
                value: contact.phoneNumber.value,
              }}
              type={type as ELegalEntityTypes}
            />
          </FormWrapper>
        </>
      )}
    </>
  );
};

export { LegalEntityEditContactPage };
