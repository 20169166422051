import { ETransactionTypes } from 'constants/TransactionTypes';
import { ETransactionsChartBarColor } from 'pages/DashboardPage/BarCharts/styles';

export function debounce(fn: () => void, ms: number) {
  let timer: any;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn();
    }, ms);
  };
}

export const sortChartData = (data: { label: string; value: number }[]) =>
  data.sort((a, b) => b.value - a.value);

export enum EBarChartType {
  transactionsTypes = 'transactionsTypes',
}

export const mapChartBarColorByDictionary = (dictId: string, chartType: EBarChartType) => {
  switch (chartType) {
    case EBarChartType.transactionsTypes: {
      const transactionTypeEntry = Object.entries(ETransactionTypes).find(
        (entry) => entry[1] === dictId,
      );
      if (transactionTypeEntry) {
        const chartColorEntry = Object.entries(ETransactionsChartBarColor).find(
          (entry) => transactionTypeEntry[0] === entry[0],
        );
        return chartColorEntry && chartColorEntry[1]
          ? chartColorEntry[1]
          : ETransactionsChartBarColor.DEFAULT_COLOR;
      }
      return ETransactionsChartBarColor.DEFAULT_COLOR;
    }
    default: {
      return ETransactionsChartBarColor.DEFAULT_COLOR;
    }
  }
};
