import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { IRole, IRoleFormValues } from 'models/role';
import { getRole } from 'requests/role';
import { RoleForm } from 'pages/RolesPage/RoleForm';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { useRoles } from 'pages/RolesPage/hooks';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { useTranslations } from 'hooks/useTranslations';

const EditRolePage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [role, setRole] = useState<IRole | null>();
  const { handleEdit } = useRoles();
  const { samePageAlert, clearAlert } = useAlert();
  const { t } = useTranslations();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    (async function getRoleDataInit() {
      if (id) {
        const response = await getRole(id);
        if (response) {
          response.formPermissionIds = {};
          response.permissionIds?.forEach((permission) => {
            response.formPermissionIds[permission] = true;
          });

          setRole(response);
        }
      }
    })();
  }, [id]);

  const onSubmit = async (data: IRole | IRoleFormValues) => {
    const result = await handleEdit(data as IRole);
    if (result) {
      navigate(ERouteLinks.Roles);
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          { label: t('roles.header'), route: ERouteLinks.Roles },
          t('editRole.header'),
        ]}
      />
      {role && (
        <>
          <StyledPageTitle variant="h4">{t('editRole.header')}</StyledPageTitle>
          <TableFormWrapper>
            <RoleForm
              onCancel={() => navigate(ERouteLinks.Roles)}
              onSubmit={onSubmit}
              initialFormValues={role}
              uniqueId={role.uniqueId}
            />
            {samePageAlert && (
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            )}
          </TableFormWrapper>
        </>
      )}
    </>
  );
};

export { EditRolePage };
