import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { EPermission } from 'models/permissions';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';

export enum EAmmunitionTransactionsLabel {
  transactionDateAndTime = 'ammunitionDetails.transactions.DateAndTime.column',
  createDate = 'ammunitionDetails.transactions.createDate.column',
  transactionType = 'ammunitionDetails.transactions.transactionType.column',
  owner = 'ammunitionDetails.transactions.owner.column',
  keeper = 'ammunitionDetails.transactions.keeper.column',
}

interface IAmmunitionTransactionsCard {
  data: ITableDataSource;
  discardableTransactionId?: string | null;
  handleView: (args: string) => void;
}

const AmmunitionTransactionsCard = ({
  data,
  handleView,
  discardableTransactionId,
}: IAmmunitionTransactionsCard) => {
  const { t } = useTranslations();
  const { longDateFormat } = useGlobalProperty();
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{
        border: colors.gray200,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTransactionsLabel.transactionType)}
        </Typography>
        <Typography variant="body2">{data.transactionType}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTransactionsLabel.transactionDateAndTime)}
        </Typography>
        <Typography variant="body2">{formatDate(data.date, longDateFormat)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTransactionsLabel.createDate)}
        </Typography>
        <Typography variant="body2">{formatDate(data.createdDate, longDateFormat)}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTransactionsLabel.owner)}
        </Typography>
        <Typography variant="body2">{data.owner}</Typography>
      </Grid>
      <Grid item xs={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t(EAmmunitionTransactionsLabel.keeper)}
        </Typography>
        <Typography variant="body2">{data.keeper}</Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={3} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('general.actions.button')}:{' '}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            label={t('ammunitionDetails.transactions.detail.button')}
            variant={EButtonVariants.outlined}
            onClick={() => handleView(data.id as string)}
            size="small"
            fullWidth
          />
        </Grid>
        {discardableTransactionId === (data.id as string) && (
          <Grid item xs={12} sm={3}>
            <Button
              label={t(
                'registeredFirearms.firearmsDetails.transactions.accordion.remove.link',
              )}
              variant={EButtonVariants.outlined}
              permission={EPermission.DISCARD_TRANSACTION}
              onClick={() =>
                navigate(ERouteLinks.CancelTransaction.replace(':id', data.id as string))
              }
              size="small"
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export { AmmunitionTransactionsCard };
