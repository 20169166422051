import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { EPerPages, ISortOptions } from 'models/table';
import { queryStringWithSort } from 'helpers/request';
import { IUser, IUsersResponse } from 'models/user';
import { ISearchUserFormValues, IUserRequest, IUserUpdateRequest } from 'models/form';
import { IGeneratePasswordResponse } from 'models/password';

/**
 *
 */
const getUsers = async (
  page: number,
  size: EPerPages,
  query: Partial<ISearchUserFormValues>,
  sort: ISortOptions | null | undefined,
) => {
  let queryString = queryStringWithSort({ page: page - 1, size, ...query }, sort);
  queryString = queryString.replace('sort=status', 'sort=active');
  queryString = queryString.replace('sort=createdDate', 'sort=created');
  queryString = queryString.replace('sort=phoneNumber', 'sort=phoneNumber.value');

  const { data } = await apiClient.get<TResponse<IUsersResponse>>(
    `${ApiEndpoints.GET_USERS()}?${queryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const setUserActive = async (id: string, active: boolean) => {
  const { data } = await apiClient.patch<TResponse<string>>(
    `${ApiEndpoints.ACTIVATE_USER(id)}?value=${active}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return true;
};

const setUserBlocked = async (id: string) => {
  const { data } = await apiClient.put(ApiEndpoints.UNBAN_USER(id));

  if (isResponseError(data)) {
    return null;
  }

  return true;
};

/**
 *
 * @param body
 */
const createUser = async (body: IUserRequest) =>
  apiClient.post<TResponse<IUser>>(ApiEndpoints.CREATE_USER(), body);

/**
 *
 * @param id
 */
const getUser = async (id: string): Promise<IUser | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_USER(id));

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

/**
 *
 * @param uniqueId
 * @param user
 */
const updateUser = async (uniqueId: string, user: IUserUpdateRequest) => {
  const { data } = await apiClient.put(ApiEndpoints.UPDATE_USER(uniqueId), user);

  return !isResponseError(data);
};

const deleteUser = async (id: string): Promise<boolean> => {
  const { data } = await apiClient.delete(ApiEndpoints.DELETE_USER(id));

  return !isResponseError(data);
};

const generatePassword = async (email: string) => {
  const { data } = await apiClient.post<TResponse<IGeneratePasswordResponse>>(
    ApiEndpoints.GENERATE_PASSWORD(),
    {
      email,
    },
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getAssignedLEs = async () => {
  const { data } = await apiClient.get<TResponse<any[]>>('');

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  getUsers,
  createUser,
  getUser,
  updateUser,
  deleteUser,
  setUserActive,
  setUserBlocked,
  getAssignedLEs,
  generatePassword,
};
