import { ESearchFilterKeys } from 'constants/SearchFilterTypes';
import {
  ISearchFilterAccessory,
  ISearchFilterAmmunition,
  ISearchFilterComponent,
} from 'models/searchFilter';
import { createSearchFilterAccessory } from 'requests/searchFilter';
import { EArtAncillaryTypeNameLowerCase } from 'constants/ArtAccessoryType';

interface ISavePreset {
  type: ESearchFilterKeys | EArtAncillaryTypeNameLowerCase;
  name: string;
  emailNotificationEnabled: boolean;
  notificationEnabled: boolean;
  body: ISearchFilterAccessory | ISearchFilterAmmunition | ISearchFilterComponent;
}

export const useSaveAncillaryPreset = () => {
  const savePreset = ({
    type,
    name,
    emailNotificationEnabled,
    notificationEnabled,
    body,
  }: ISavePreset) => {
    switch (type) {
      case ESearchFilterKeys.ancillaries:
      case EArtAncillaryTypeNameLowerCase.ACCESSORY:
      case EArtAncillaryTypeNameLowerCase.AMMUNITION:
      case EArtAncillaryTypeNameLowerCase.COMPONENTS:
        return createSearchFilterAccessory(
          name,
          emailNotificationEnabled,
          notificationEnabled,
          body as ISearchFilterAccessory | ISearchFilterAmmunition | ISearchFilterComponent,
        );

      default:
        return null;
    }
  };

  return { savePreset };
};
