export const isStringEmpty = (s: string | null | undefined) =>
  s === undefined || s === null || (typeof s === 'string' && s.trim() === '');

export function areCustomFieldsValuesEqual(value1: any, value2: any): boolean {
  const isNullUndefinedOrEmpty = (value: any) =>
    value === null || value === undefined || value === '';

  if (isNullUndefinedOrEmpty(value1) && isNullUndefinedOrEmpty(value2)) {
    return true;
  }
  return value1 === value2;
}
