import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { DetailsTemplate, EDetailsPageTypes } from 'components/templates/DetailsTemplate';
import { EDictionaryTypes, IDictionary, ITranslation } from 'models/dictionary';
import { useQuery } from 'hooks/useQuery';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { useLegalEntities } from 'pages/LegalEntitiesPage/hooks';
import { ERouteLinks } from 'models/route';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { ECustomFieldType } from 'models/customField';
import { EDictionaryPrefixTypesUniqueId } from 'helpers/dictionary';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { formatDate } from 'utils/date';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useGlobalProperty } from 'models/globalPropertyContext';

export interface ILegalEntityCustomField {
  customFieldName: string;
  customFieldType: string;
  customFieldUniqueId: string;
  value: string;
  translations?: ITranslation[];
}

export interface ILegalEntityData {
  city: string | null;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  uniqueId: string;
  visibilityUniqueId: string;
  prefixGroupUniqueId?: string;
  prefixValueUniqueId?: string;
  customFields?: ILegalEntityCustomField[];
  version: string;

  [key: string]: any;
}

interface ILegalEntityDetailsProps {
  legalEntity: ILegalEntityData;
  pageType?: EDetailsPageTypes;
}

const LegalEntityDetails = ({
  legalEntity,
  pageType = EDetailsPageTypes.view,
}: ILegalEntityDetailsProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const type: string = query.get('type') || '';
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const { hasPermissions } = usePermission();
  const {
    gender,
    legalEntityPrefix,
    civilianPrefix,
    militaryPrefix,
    policePrefix,
    loadDictionaries,
    legalVisibility,
  } = useLegalEntities();
  const { shortDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  enum DetailTranslations {
    editLink = 'legalEntityDetails.edit.link',
  }

  const renderCustomFieldData = (customField: ILegalEntityCustomField) => {
    if (customField.customFieldType === ECustomFieldType.BOOL) {
      return customField.value
        ? t('customFields.value.yes.label')
        : t('customFields.value.no.label');
    }
    if (customField.customFieldType === ECustomFieldType.DATE) {
      return formatDate(customField.value, shortDateFormat);
    }
    return customField.value;
  };

  useEffect(() => {
    (async function init() {
      await loadDictionaries([
        EDictionaryTypes.Gender,
        EDictionaryTypes.LegalVisibility,
        EDictionaryTypes.Make,
        EDictionaryTypes.State,
        EDictionaryTypes.legalEntityPrefix,
        EDictionaryTypes.civilianPrefix,
        EDictionaryTypes.militaryPrefix,
        EDictionaryTypes.policePrefix,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  const renderItemDetail = (dictionary: IDictionary[] | null, dictionaryItemId: string) => {
    const legal: ILegalEntityData = legalEntity;
    if (legal) {
      return dictionary?.find(
        (item: IDictionary) =>
          item?.uniqueId === legal[dictionaryItemId as keyof ILegalEntityData],
      )?.name;
    }
    return '';
  };

  const renderPrefixDetail = (prefixGroupUniqueId: string | undefined) => {
    switch (prefixGroupUniqueId) {
      case EDictionaryPrefixTypesUniqueId.civilianPrefix:
        return renderItemDetail(civilianPrefix, 'prefixValueUniqueId');
      case EDictionaryPrefixTypesUniqueId.militaryPrefix:
        return renderItemDetail(militaryPrefix, 'prefixValueUniqueId');
      case EDictionaryPrefixTypesUniqueId.policePrefix:
        return renderItemDetail(policePrefix, 'prefixValueUniqueId');
      default:
        return '';
    }
  };

  return (
    <div>
      {legalEntity && (
        <DetailsTemplate
          deletePermissions={[EPermission.DELETE_LE]}
          title={`${legalEntity.firstName} ${legalEntity.lastName}`}
          pageType={pageType}
          redirectToEditView={
            hasPermissions([EPermission.MANAGE_LE])
              ? () =>
                  navigate(
                    `${ERouteLinks.EditLegalEntity.replace(':id', id)}?type=${type}&view=true`,
                  )
              : undefined
          }
          redirectToView={() =>
            navigate(`${ERouteLinks.DeleteLegalEntity.replace(':id', id)}?type=${type}`)
          }
          translationsKeys={DetailTranslations}
        >
          {' '}
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.legalEntityType.label')}
            </Typography>
            <Box>
              <Chips
                id="legalEntityType"
                text={
                  type === 'individual'
                    ? t('legalEntityDetails.legalEntityType.individual.chip.label')
                    : t('legalEntityDetails.legalEntityType.organisation.chip.label')
                }
                variant={EChipsVariants.primary}
              />
            </Box>
          </Grid>
          {type === 'individual' && (
            <>
              <Grid item xs={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('legalEntities.individual.accordion.legalEntityTable.prefixGroup.column')}
                </Typography>
                <Typography variant="body2">
                  <Chips
                    id="prefixGroup"
                    text={renderItemDetail(legalEntityPrefix, 'prefixGroupUniqueId')}
                    variant={EChipsVariants.primary}
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} lg={3} container direction="column">
                <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                  {t('legalEntities.individual.accordion.legalEntityTable.prefixValue.column')}
                </Typography>
                <Typography variant="body2" id="prefixValue">
                  {renderPrefixDetail(legalEntity.prefixGroupUniqueId)}
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.dateOfBirth.label')}
            </Typography>
            <Typography variant="body2" id="dateOfBirth">
              {formatDate(legalEntity.dateOfBirth, shortDateFormat)}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Grid>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('legalEntityDetails.gender.label')}
              </Typography>
            </Grid>
            <Typography variant="body2" id="gender">
              {renderItemDetail(gender, 'genderUniqueId')}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} container direction="column">
            <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
              {t('legalEntityDetails.visibility.label')}
            </Typography>
            <Box>
              <Chips
                id="visibility"
                text={renderItemDetail(legalVisibility, 'visibilityUniqueId')}
                variant={EChipsVariants.success}
              />
            </Box>
          </Grid>
          {legalEntity?.customFields?.map((customField) => (
            <Grid
              item
              xs={6}
              lg={3}
              container
              direction="column"
              key={customField.customFieldUniqueId}
            >
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {
                  customField?.translations?.filter(
                    (translation) =>
                      selectedLanguage?.uniqueId === translation.languageUniqueId,
                  )?.[0]?.name
                }
              </Typography>
              <Typography
                variant="body2"
                id={`customField_${customField.customFieldUniqueId}`}
              >
                {emptyFieldHolder(renderCustomFieldData(customField))}
              </Typography>
            </Grid>
          ))}
        </DetailsTemplate>
      )}
    </div>
  );
};

export { LegalEntityDetails };
