import React, { useEffect, useState } from 'react';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ICommentData } from 'models/comment';
import { EIconTypes } from 'constants/Icons';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { colors } from 'theme/colors';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate } from 'utils/date';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from './styles';

interface ICommentAccordion {
  name: string;
  comment: ICommentData;
  deleteLink: string;
  dropdownIconId?: string;
  visibilityButtonId?: string;
}

const CommentAccordion = ({
  name,
  comment,
  deleteLink,
  dropdownIconId,
  visibilityButtonId,
}: ICommentAccordion) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslations();
  const { isDesktop } = useThemeBreakpoint();
  const { id } = useParams<{ id: string }>();
  const query = useQuery();
  const type: string | null = query.get('type');
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  const typoColor = comment.hidden ? colors.gray400 : '';

  const renderCommentDate = () => {
    const dateFormat = longDateFormat;
    if (comment.hidden && comment.hiddenAt !== null) {
      return formatDate(comment.hiddenAt, dateFormat);
    }
    if (!comment.hidden && comment.createdDate !== null) {
      return formatDate(comment.createdDate, dateFormat);
    }

    return null;
  };

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={() => setExpanded((prevState) => !prevState)}
      sx={{ padding: { xs: '0 16px', sm: '0 20px' } }}
    >
      {!isDesktop ? (
        <StyledAccordionSummary
          expandIcon={
            <Button
              id={dropdownIconId}
              variant={EButtonVariants.outlined}
              icon={EIconTypes.dropdownDown}
            />
          }
          id={name}
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: '20px',
          }}
        >
          <Grid
            sx={{
              width: '100%',
              marginTop: '-12px',
              marginLeft: '-20px',
            }}
          >
            <Grid
              container
              rowGap={1}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <Grid
                item
                xs={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignSelf: {
                    xs: 'flex-start',
                    lg: 'center',
                  },
                }}
              >
                <Button
                  id={visibilityButtonId}
                  variant={EButtonVariants.outlined}
                  tableButton
                  icon={comment.hidden ? EIconTypes.view : EIconTypes.hidden}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `${deleteLink
                        .replace(':id', id as string)
                        .replace(':commentId', comment.uniqueId as string)}?${
                        type ? `type=${type}&` : ''
                      }expand=comments`,
                    );
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={11}
              container
              rowGap={1}
              direction={isDesktop ? 'row' : 'column'}
              justifyContent={isDesktop ? 'space-between' : 'flex-start'}
              alignItems={isDesktop ? 'center' : 'flex-start'}
              sx={{ paddingTop: '12px' }}
            >
              <Grid item xs={4} xl={6} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography variant="subtitle2" mr={1} color={typoColor}>
                  {t('legalEntityDetails.commentsTab.commentsListSection.title.label')}:{' '}
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: 'break-word' }} color={typoColor}>
                  {comment.title}
                </Typography>
              </Grid>
              <Grid item xs={4} xl={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" mr={1} color={typoColor}>
                  {comment.hidden
                    ? t('legalEntityDetails.commentsTab.commentsListSection.hiddenBy.label')
                    : t('legalEntityDetails.commentsTab.commentsListSection.addedBy.label')}
                  :{' '}
                </Typography>
                <Typography variant="body2" color={typoColor}>
                  {comment.hidden
                    ? `${comment.hiddenByFirstName} ${comment.hiddenByLastName}`
                    : `${comment.createdByFirstName} ${comment.createdByLastName}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                xl={3}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <Typography variant="subtitle2" mr={1} color={typoColor}>
                  {t('legalEntityDetails.commentsTab.commentsListSection.date.label')}:{' '}
                </Typography>
                <Typography variant="body2" color={typoColor}>
                  {renderCommentDate()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </StyledAccordionSummary>
      ) : (
        <StyledAccordionSummary
          expandIcon={
            <Button
              id={dropdownIconId}
              variant={EButtonVariants.outlined}
              icon={EIconTypes.dropdownDown}
            />
          }
          id={name}
        >
          <Grid
            container
            rowGap={1}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft: '20px',
            }}
          >
            <Grid
              item
              xs={11}
              container
              rowGap={1}
              direction={isDesktop ? 'row' : 'column'}
              justifyContent={isDesktop ? 'space-between' : 'flex-start'}
              alignItems={isDesktop ? 'center' : 'flex-start'}
            >
              <Grid item xs={4} xl={6} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography variant="subtitle2" mr={1} color={typoColor}>
                  {t('legalEntityDetails.commentsTab.commentsListSection.title.label')}:{' '}
                </Typography>
                <Typography variant="body2" sx={{ wordBreak: 'break-word' }} color={typoColor}>
                  {comment.title}
                </Typography>
              </Grid>
              <Grid item xs={4} xl={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" mr={1} color={typoColor}>
                  {comment.hidden
                    ? t('legalEntityDetails.commentsTab.commentsListSection.hiddenBy.label')
                    : t('legalEntityDetails.commentsTab.commentsListSection.addedBy.label')}
                  :{' '}
                </Typography>
                <Typography variant="body2" color={typoColor}>
                  {comment.hidden
                    ? `${comment.hiddenByFirstName} ${comment.hiddenByLastName}`
                    : `${comment.createdByFirstName} ${comment.createdByLastName}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                xl={3}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                <Typography variant="subtitle2" mr={1} color={typoColor}>
                  {t('legalEntityDetails.commentsTab.commentsListSection.date.label')}:{' '}
                </Typography>
                <Typography variant="body2" color={typoColor}>
                  {renderCommentDate()}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignSelf: {
                  xs: 'flex-start',
                  lg: 'center',
                },
              }}
            >
              <Button
                id={visibilityButtonId}
                variant={EButtonVariants.outlined}
                tableButton
                icon={comment.hidden ? EIconTypes.view : EIconTypes.hidden}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `${deleteLink
                      .replace(':id', id as string)
                      .replace(':commentId', comment.uniqueId as string)}?${
                      type ? `type=${type}&` : ''
                    }expand=comments`,
                  );
                }}
              />
            </Grid>
          </Grid>
        </StyledAccordionSummary>
      )}
      <StyledAccordionDetails isHidden={comment.hidden}>
        <Grid container>
          {comment.hidden && (
            <Grid item container rowGap={1} sx={{ marginBottom: '20px' }}>
              <Grid item xs={12} lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" mr={1}>
                  {t('legalEntityDetails.commentsTab.commentsListSection.addedBy.label')}:{' '}
                </Typography>
                <Typography variant="body2">
                  {`${comment.createdByFirstName} ${comment.createdByLastName}`}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={3} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" mr={1}>
                  {t('legalEntityDetails.commentsTab.commentsListSection.date.label')}:
                </Typography>
                <Typography variant="body2">
                  {formatDate(comment.createdDate, longDateFormat)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
              {comment.comment}
            </Typography>
          </Grid>
        </Grid>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export { CommentAccordion };
