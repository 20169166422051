import React from 'react';
import { Controller } from 'react-hook-form';
import Switch, { SwitchProps } from '@mui/material/Switch';
import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';
import { StyledFormElementWrapper } from 'theme/styles';
import { IFormElementProps } from 'models/form';
import { colors } from 'theme/colors';
import { EIconTypes } from 'constants/Icons';
import FormLabel from 'components/atoms/FormLabel';
import Icon from 'components/atoms/Icon/Icon';
import FormErrorLabel from 'components/atoms/FormErrorLabel';

export const StyledIconWrapper = styled.span`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${colors.white};
  color: ${colors.error700};

  & .MuiSvgIcon-root {
    position: absolute;
    top: -4px;
    left: -4px;
  }
`;

const StyledSwitch = styled((props: JSX.IntrinsicAttributes & SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 38,
  height: 20,
  padding: 0,
  marginLeft: 10,
  marginTop: 4,
  marginBottom: 3,
  boxSizing: 'border-box',

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',

    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: colors.gray100,
      opacity: 1,
    },

    '&.Mui-disabled svg': {
      color: colors.gray100,
    },

    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: colors.white,

      '& + .MuiSwitch-track': {
        backgroundColor: colors.success200,
        opacity: 1,
        border: 0,
      },

      '&.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: colors.gray100,
        opacity: 1,
      },

      '&.Mui-disabled svg': {
        color: colors.gray100,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    boxShadow: 'none',
  },

  '& .MuiSwitch-track': {
    borderRadius: 100,
    backgroundColor: colors.error200,
    opacity: 1,
  },
}));

export const FormSwitch = ({ name, label, control, errors, disabled }: IFormElementProps) => (
  <StyledFormElementWrapper>
    <FormLabel name={name} label={label} />
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          {...field}
          control={
            <StyledSwitch
              disabled={disabled}
              checked={field.value}
              checkedIcon={
                <StyledIconWrapper>
                  <Icon type={EIconTypes.smallCheckmark} color={colors.success300} />
                </StyledIconWrapper>
              }
              icon={
                <StyledIconWrapper>
                  <Icon type={EIconTypes.closeSmall} color={colors.error300} />
                </StyledIconWrapper>
              }
            />
          }
          label=""
        />
      )}
    />
    <FormErrorLabel label={errors[name]?.message} />
  </StyledFormElementWrapper>
);
