import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import { IGlobalProperty } from 'models/globalProperty';

const getGlobalProperties = async (): Promise<IGlobalProperty[] | null> => {
  const { data } = await apiClient.get(ApiEndpoints.GET_GLOBAL_PROPERTIES());

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export { getGlobalProperties };
