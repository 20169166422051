import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { colors } from 'theme/colors';

export const StyledBlock = styled.div`
    display: grid;
    row-gap: 20px;
    align-content: center;
    background-color: ${colors.primary15};
    padding: 24px;
    height: 188px;
    border-color: ${colors.primary25}
    border-radius: 1px;
`;

export const StyledValueFormHeader = styled(Typography)`
  background: ${colors.primary15};
  height: min-content;
  width: 100%;
  font-size: 20px;
  border-radius: 4px;
  padding: 10px 20px;
  margin-left: -5px;
`;

export const ResponsiveImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 16px;
`;

export const StyledTypography = styled(Typography)`
  color: ${colors.gray900};
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
`;
