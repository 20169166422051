import React from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import Grid from '@mui/material/Grid';
import { StyledBlock } from 'pages/AdministrationPage/styles';
import { Link } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from 'theme/colors';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { useLicenceContext } from 'models/licenceContext';

const AdministrationPage = () => {
  const { t } = useTranslations();
  const { hasPermissions } = usePermission();
  const { isOnlineLicence } = useLicenceContext();

  return (
    <>
      <Breadcrumbs items={[t('module.administration.name')]} />
      <StyledPageTitle variant="h4">{t('module.administration.name')}</StyledPageTitle>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        {hasPermissions([EPermission.MANAGE_USERS]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.Users}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('users.header')}
              </Link>
            </StyledBlock>
          </Grid>
        )}
        {hasPermissions([EPermission.MANAGE_ROLES]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.Roles}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('roles.header')}
              </Link>
            </StyledBlock>
          </Grid>
        )}
        {hasPermissions([EPermission.MANAGE_DICTIONARIES]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.Dictionaries}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('dictionaries.header')}
              </Link>
            </StyledBlock>
          </Grid>
        )}
        {hasPermissions([EPermission.VIEW_AND_MANAGE_LICENCE]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.LicenceDetails}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('licenceDetails.header')}
              </Link>
            </StyledBlock>
          </Grid>
        )}

        {hasPermissions([EPermission.MANAGE_CUSTOM_FIELDS]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.CustomFields}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('customFields.header')}
              </Link>
            </StyledBlock>
          </Grid>
        )}

        {hasPermissions([EPermission.VIEW_AUDIT_LOG]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.AuditLogPage}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('auditLog.header')}
              </Link>
            </StyledBlock>
          </Grid>
        )}

        {hasPermissions([EPermission.MANAGE_SETTINGS]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.SystemSettings}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('systemSettings.header')}
              </Link>
            </StyledBlock>
          </Grid>
        )}

        {isOnlineLicence() && hasPermissions([EPermission.MANAGE_API_KEYS]) && (
          <Grid item xs={12} md={6} lg={3}>
            <StyledBlock>
              <Link
                to={ERouteLinks.ApiKeysPage}
                style={{ color: colors.black, textDecoration: 'none' }}
              >
                {t('systemSettings.apiKeys')}
              </Link>
            </StyledBlock>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AdministrationPage;
