import React from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { mapErrorPageTypeToLabels } from 'components/templates/ErrorPageTemplate/helpers';
import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/atoms/Button';
import { EIconTypes } from 'constants/Icons';
import LargeLogo from 'assets/images/ErrorPageLogoLarge.svg';
import SmallLogo from 'assets/images/ErrorPageLogoSmall.svg';
import { useNavigate } from 'react-router-dom';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { EErrorPageType } from 'models/errorPage';
import {
  StyledErrorLabelsWrapper,
  StyledErrorPageStatus,
  StyledErrorPageSubtitle,
  StyledErrorPageTitle,
  StyledErrorPageWrapper,
  StyledLogoWrapper,
} from 'components/templates/ErrorPageTemplate/styles';

interface IErrorPage {
  type: EErrorPageType;
  isStatusVisible?: boolean;
}

export const ErrorPageTemplate = ({ type, isStatusVisible = true }: IErrorPage) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const isSmallLogo = useMediaQuery((theme: Theme) => theme.breakpoints.between(1024, 1350));
  const { isDesktop, isMobile } = useThemeBreakpoint();
  const labels = mapErrorPageTypeToLabels(type);

  const handleGoBack = () => {
    navigate(type === EErrorPageType.NotFound ? -1 : -3);
  };

  return (
    <StyledErrorPageWrapper>
      <StyledErrorLabelsWrapper isMobile={isMobile} isSmallLogo={isSmallLogo}>
        {isStatusVisible && <StyledErrorPageStatus>{labels.status}</StyledErrorPageStatus>}
        <StyledErrorPageTitle>{t(labels.title)}</StyledErrorPageTitle>
        <StyledErrorPageSubtitle>{t(labels.subtitle)}</StyledErrorPageSubtitle>
        <Button
          icon={EIconTypes.arrowLeft}
          label={t('errorPage.back.button')}
          onClick={handleGoBack}
        />
      </StyledErrorLabelsWrapper>
      {isDesktop && (
        <StyledLogoWrapper isSmallLogo={isSmallLogo}>
          <img src={isSmallLogo ? SmallLogo : LargeLogo} alt="logo" />
        </StyledLogoWrapper>
      )}
    </StyledErrorPageWrapper>
  );
};
