import React, { useEffect, useMemo, useRef, useState } from 'react';
import L from 'leaflet';
import { Marker, TileLayer, useMap } from 'react-leaflet';
import {
  StyledLocationMapWrapper,
  StyledMapContainer,
} from 'components/organisms/LocationMap/styles';

import IconImg from './icons/marker-icon.png';
import IconShadow from './icons/marker-shadow.png';

export interface ILocationMapDimensions {
  width: string;
  height: string;
}

export interface ILocationMapProps {
  coords: any;
  dimensions?: ILocationMapDimensions;
  defaultZoom?: number;
  isOnline: boolean;
  getCoordsCallback?: (p: any) => void;
}

const LOCATION_MAP_ZOOM = 13;

const ChangeView = ({ center, firstZoomed, defaultZoom }: any) => {
  const map = useMap();
  map.setView(center, firstZoomed ? defaultZoom : LOCATION_MAP_ZOOM);
  return null;
};

const customIcon = L.icon({
  iconUrl: IconImg,
  shadowUrl: IconShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const DraggableMarker = ({ center, getCoordsCallback }: any) => {
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;
        if (marker != null) {
          setPosition(marker?.getLatLng());
          if (getCoordsCallback !== null) {
            getCoordsCallback(marker?.getLatLng());
          }
        }
      },
    }),
    [],
  );
  useEffect(() => {
    setPosition(center);
  }, [center]);

  return (
    <Marker
      draggable
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={customIcon}
    />
  );
};

const LocationMap = ({
  coords,
  dimensions,
  defaultZoom,
  isOnline,
  getCoordsCallback,
}: ILocationMapProps) => {
  const [firstZoomed, setFirstZoomed] = useState(true);
  const [firstCoords] = useState(coords);
  const coordinates = coords.every((coordinate: number | string) => !Number.isNaN(coordinate))
    ? coords
    : [0, 0];

  useEffect(() => {
    if (coords[0] !== firstCoords[0] && coords[1] !== firstCoords[1] && firstZoomed) {
      setFirstZoomed(false);
    }
  }, [coords]);

  const url = isOnline
    ? 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    : `${process.env.REACT_APP_MAP_TILE_URL}tile/{z}/{x}/{y}.png`;

  return (
    <StyledLocationMapWrapper dimensions={dimensions}>
      <StyledMapContainer
        center={coordinates}
        zoom={firstZoomed ? defaultZoom : LOCATION_MAP_ZOOM}
        scrollWheelZoom
      >
        <ChangeView center={coordinates} firstZoomed={firstZoomed} defaultZoom={defaultZoom} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url={url}
        />
        <DraggableMarker
          center={coordinates}
          getCoordsCallback={getCoordsCallback ? (data: any) => getCoordsCallback(data) : null}
          setFirstZoomed={setFirstZoomed}
          firstZoomed={firstZoomed}
        />
      </StyledMapContainer>
    </StyledLocationMapWrapper>
  );
};

export { LocationMap };
