import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { EPerPages, ISortOptions } from 'models/table';
import { queryStringWithSort } from 'helpers/request';
import { INotificationDetails, INotificationsResponse } from 'models/notification';

const getNotifications = async (
  page: number,
  size: EPerPages,
  sort: ISortOptions | null | undefined,
) => {
  const queryString = queryStringWithSort({ page: page - 1, size }, sort);

  const { data } = await apiClient.get<TResponse<INotificationsResponse>>(
    `${ApiEndpoints.GET_NOTIFICATIONS()}?${queryString}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getNotification = async (id: string): Promise<INotificationDetails | null> => {
  const { data } = await apiClient.get<TResponse<INotificationDetails>>(
    ApiEndpoints.GET_NOTIFICATION(id),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getNotificationUnreadCounter = async (): Promise<number> => {
  const { data } = await apiClient.get<TResponse<number>>(
    ApiEndpoints.GET_NOTIFICATION_UNREAD_COUNTER(),
  );

  if (isResponseError(data)) {
    return 0;
  }

  return data;
};

const deleteNotifications = async (query: string): Promise<any> => {
  const { data } = await apiClient.delete<TResponse<any>>(
    ApiEndpoints.DELETE_NOTIFICATIONS(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const markAsReadNotifications = async (query: string): Promise<any> => {
  const { data } = await apiClient.put<TResponse<any>>(
    ApiEndpoints.MARK_AS_READ_NOTIFICATIONS(query),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  getNotifications,
  getNotification,
  getNotificationUnreadCounter,
  deleteNotifications,
  markAsReadNotifications,
};
