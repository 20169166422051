import React, { useEffect, useState } from 'react';
import { ERouteLinks } from 'models/route';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { useParams } from 'react-router-dom';
import { StyledPageTitle } from 'theme/styles';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { StyledDetailsWrapper } from 'pages/LegalEntitiesPage/LegalEntityDeleteCommentPage/styles';
import { ICommentData, ICommentsList } from 'models/comment';
import { useTranslations } from 'hooks/useTranslations';
import { formatDate } from 'utils/date';
import { getCommentInTransaction } from 'requests/transaction';
import { useTransactions } from 'pages/TransactionsPage/hooks';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Box } from '@mui/system';

export interface ILegalEntityCommentsList extends ICommentsList {
  content: ILegalEntityCommentData[];
}

export interface ILegalEntityCommentData extends ICommentData {}

const TransactionDeleteCommentPage = () => {
  const { id, commentId } = useParams<{ id: string; commentId: string }>();
  const [comment, setComment] = useState<ILegalEntityCommentData | null>();
  const { t } = useTranslations();

  const { handleDeleteComment, navigateToDetails } = useTransactions();

  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const { longDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    (async function getCommentInTransactionDataInit() {
      if (id && commentId) {
        const response = await getCommentInTransaction(id, commentId);
        if (response) {
          setComment(response);
        }
      }
    })();
  }, [id, commentId]);

  const onDelete = async () => {
    setIsDeleteLoading(true);
    try {
      if (comment) {
        const result = await handleDeleteComment(comment.uniqueId, comment.hidden);

        if (result.status === 204) {
          navigateToDetails();
        }
      }
    } catch (e: any) {
      console.error(e);
    }
    setIsDeleteLoading(false);
  };

  let actionType: string = '';
  if (comment) {
    actionType = comment.hidden
      ? t('transactionDetails.enableComment.enableComment.header')
      : t('transactionDetails.disableComment.disableComment.header');
  }

  return (
    <>
      <Breadcrumbs
        items={[
          {
            label: t('transactionDetails.transactionDetails.header'),
            route: ERouteLinks.Transactions,
          },
          {
            label: t('transactionDetails.addComment.transactionDetails.header'),
            route: ERouteLinks.ViewTransaction.replace(':id', id as string),
          },
          `${actionType ? `${actionType}` : ''}`,
        ]}
      />

      {comment && (
        <>
          <StyledPageTitle variant="h4">{`${actionType}`} </StyledPageTitle>

          <Box my={3}>
            <Alert
              text={
                comment.hidden
                  ? t('transactionDetails.enableComment.enabledInfo.text')
                  : t('transactionDetails.disableComment.disableInfo.text')
              }
              variant={EAlertVariants.warning}
            />
          </Box>

          <StyledDetailsWrapper container direction="column" gap={3}>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('transactionDetails.enableComment.title.label')}
              </Typography>
              <Typography variant="body2">{comment.title}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                {t('transactionDetails.enableComment.comment.label')}
              </Typography>
              <Typography variant="body2">{comment.comment}</Typography>
            </Grid>
            <Grid container rowGap={3}>
              <Grid container item xs={12} lg={6} rowGap={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('transactionDetails.enableComment.addedBy.label')}
                  </Typography>
                  <Typography variant="body2">
                    {`${comment.createdByFirstName} ${comment.createdByLastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                    {t('transactionDetails.enableComment.date.label')}
                  </Typography>
                  <Typography variant="body2">
                    {formatDate(comment.createdDate, longDateFormat)}
                  </Typography>
                </Grid>
              </Grid>
              {comment.hidden && (
                <Grid container item xs={12} lg={6} rowGap={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('transactionDetails.enableComment.disabledBy.label')}
                    </Typography>
                    <Typography variant="body2">{`${comment.hiddenByFirstName} ${comment.hiddenByLastName}`}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
                      {t('transactionDetails.enableComment.date.label')}
                    </Typography>
                    <Typography variant="body2">
                      {formatDate(comment.hiddenAt, longDateFormat)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </StyledDetailsWrapper>
          <Grid container sx={{ mt: 2 }} spacing={2}>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('transactionDetails.enableComment.yes.button')}
                variant={EButtonVariants.contained}
                sx={{ mr: 1 }}
                onClick={async () => onDelete()}
                isLoading={isDeleteLoading}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                label={t('transactionDetails.enableComment.no.button')}
                variant={EButtonVariants.outlined}
                onClick={navigateToDetails}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export { TransactionDeleteCommentPage };
