import React from 'react';
import { ITableDataSource } from 'models/table';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { emptyFieldHolder } from 'components/molecules/CardList';
import { useTranslations } from 'hooks/useTranslations';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { ApiKeysStatusIds } from 'models/apiKey';
import { formatDate, getDateFromISO } from 'utils/date';
import { format } from 'date-fns';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';

interface IApiKeyTableCard {
  data: ITableDataSource;
  handleChangeStatus: (id: string, status: string) => void;
  handleCopyApiKey: (key: string) => void;
  longDateFormat: string;
  shortDateFormat: string;
}

const ApiKeyTableCard = ({
  data,
  handleChangeStatus,
  handleCopyApiKey,
  longDateFormat,
  shortDateFormat,
}: IApiKeyTableCard) => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  const renderExpiryDate = () => {
    const date = getDateFromISO(data.expiryDate);
    if (date === '') {
      return (
        <Chips
          text={t('deleteApiKey.state.perpetual.label')}
          variant={EChipsVariants.success100}
        />
      );
    }
    if (date > new Date()) {
      return format(date, shortDateFormat);
    }
    return (
      <Chips text={t('deleteApiKey.state.expired.label')} variant={EChipsVariants.error} />
    );
  };

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: colors.gray100,
      }}
    >
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysTable.username.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.username)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysTable.type.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          <Chips text={data.type} variant={EChipsVariants.primary} />
        </Typography>
      </Grid>
      <Grid item xs={12} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysTable.key.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {emptyFieldHolder(data.key)}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysTable.status.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          <Chips
            variant={
              data.statusDictionaryUniqueId === ApiKeysStatusIds.ACTIVE
                ? EChipsVariants.success100
                : EChipsVariants.error
            }
            text={data.status}
          />
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysTable.lastUsed.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {data.lastUsedDate ? formatDate(data.lastUsedDate, longDateFormat) : '-'}
        </Typography>
      </Grid>
      <Grid item xs={6} lg={3} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('apiKeys.apiKeysTable.expiryDate.column')}
        </Typography>
        <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
          {renderExpiryDate()}
        </Typography>
      </Grid>

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.primary25}`,
            lg: 'none',
          },
          m: {
            xs: 2,
            lg: 2,
          },
        }}
        spacing={2}
      >
        <Grid item xs={12} sx={{ mb: { xs: 2, sm: 0 } }}>
          <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
            {t('apiKeys.apiKeysTable.actions.column')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            label={t('apiKeys.apiKeysTable.copy.link')}
            variant={EButtonVariants.outlined}
            onClick={() => handleCopyApiKey(data.key)}
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            label={t('apiKeys.apiKeysTable.edit.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.EditApiKeyPage.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            label={t('apiKeys.apiKeysTable.delete.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.DeleteApiKeyPage.replace(':id', data.id as string)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            label={t('users.usersTable.changeStatus.link')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              handleChangeStatus(data.id as string, data.statusDictionaryUniqueId)
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ApiKeyTableCard;
