import { DictionaryActionTypes } from 'state/actions/dictionary';
import { IReducerAction } from 'models/context';
import { IDictionary } from 'models/dictionary';

interface IState {
  gender: IDictionary[] | null;
  organizationType: IDictionary[] | null;
  legalVisibility: IDictionary[] | null;
  legalEntityPrefix: IDictionary[] | null;
  civilianPrefix: IDictionary[] | null;
  militaryPrefix: IDictionary[] | null;
  policePrefix: IDictionary[] | null;
  make: string[] | null;
  model: string[] | null;
  caliber: string[] | null;
  barrelType: IDictionary[] | null;
  state: IDictionary[] | null;
  region: IDictionary[] | null;
  country: IDictionary[] | null;
  phoneCode: IDictionary[] | null;
  addressType: IDictionary[] | null;
  legality: IDictionary[] | null;
  manufacturer: string[] | null;
  level: string[] | null;
  firearmAction: string[] | null;
  firearmType: string[] | null;
  transactionType: IDictionary[] | null;
  legalClassification: string[] | null;
  manufacturerCountry: string[] | null;
  transactionInitialTypes: IDictionary[] | null;
  transactionMiddleTypes: IDictionary[] | null;
  activity: IDictionary[] | null;
  countryAll: IDictionary[] | null;
  artAncillaryOptions: IDictionary[] | null;
  artAccessoryCalibre: IDictionary[] | null;
  artAccessoryComposition: IDictionary[] | null;
  artAccessoryHostType: IDictionary[] | null;
  artAccessoryLightColour: IDictionary[] | null;
  artAccessoryMagnification: IDictionary[] | null;
  artAccessoryManufacturerFlashEliminator: IDictionary[] | null;
  artAccessoryManufacturerLaserLightModule: IDictionary[] | null;
  artAccessoryManufacturerMagazine: IDictionary[] | null;
  artAccessoryManufacturerOpticalSight: IDictionary[] | null;
  artAccessoryManufacturerSuppressor: IDictionary[] | null;
  artAccessoryReticle: IDictionary[] | null;
  artAccessoryType: IDictionary[] | null;
  artAccessoryUtility: IDictionary[] | null;
  artAccessoryProofHouse: IDictionary[] | null;
  artAmmunitionType: IDictionary[] | null;
  artAmmunitionManufacturer: IDictionary[] | null;
  artAmmunitionFactory: IDictionary[] | null;
  artAmmunitionCalibreGauge: IDictionary[] | null;
  artAmmunitionCartridgeComposition: IDictionary[] | null;
  artAmmunitionColourOfPrimerSealant: IDictionary[] | null;
  artAmmunitionProjectileShape: IDictionary[] | null;
  artAmmunitionFunctionalType: IDictionary[] | null;
  artAmmunitionShotSize: IDictionary[] | null;
  artAmmunitionMunitionType: IDictionary[] | null;
  artAmmunitionMunitionVelocity: IDictionary[] | null;
  artComponentType: IDictionary[] | null;
  artComponentManufacturer: IDictionary[] | null;
  artComponentCalibre: IDictionary[] | null;
  artComponentCapacity: IDictionary[] | null;
  artComposition: IDictionary[] | null;
  artComponentProofHouse: IDictionary[] | null;
  auditLogModule: IDictionary[] | null;
  auditLogEventType: IDictionary[] | null;
  auditLogEventName: IDictionary[] | null;
  apiKeyType: IDictionary[] | null;
}

export const initialState: IState = {
  gender: null,
  organizationType: null,
  legalVisibility: null,
  legalEntityPrefix: null,
  civilianPrefix: null,
  militaryPrefix: null,
  policePrefix: null,
  make: null,
  model: null,
  caliber: null,
  barrelType: null,
  state: null,
  region: null,
  country: null,
  phoneCode: null,
  addressType: null,
  legality: null,
  manufacturer: null,
  level: null,
  firearmAction: null,
  firearmType: null,
  transactionType: null,
  legalClassification: null,
  manufacturerCountry: null,
  transactionInitialTypes: null,
  transactionMiddleTypes: null,
  activity: null,
  countryAll: null,
  artAncillaryOptions: null,
  artAccessoryCalibre: null,
  artAccessoryComposition: null,
  artAccessoryHostType: null,
  artAccessoryLightColour: null,
  artAccessoryMagnification: null,
  artAccessoryManufacturerFlashEliminator: null,
  artAccessoryManufacturerLaserLightModule: null,
  artAccessoryManufacturerMagazine: null,
  artAccessoryManufacturerOpticalSight: null,
  artAccessoryManufacturerSuppressor: null,
  artAccessoryReticle: null,
  artAccessoryType: null,
  artAccessoryUtility: null,
  artAccessoryProofHouse: null,
  artAmmunitionType: null,
  artAmmunitionManufacturer: null,
  artAmmunitionFactory: null,
  artAmmunitionCalibreGauge: null,
  artAmmunitionCartridgeComposition: null,
  artAmmunitionColourOfPrimerSealant: null,
  artAmmunitionProjectileShape: null,
  artAmmunitionFunctionalType: null,
  artAmmunitionShotSize: null,
  artAmmunitionMunitionType: null,
  artAmmunitionMunitionVelocity: null,
  artComponentType: null,
  artComponentManufacturer: null,
  artComponentCalibre: null,
  artComponentCapacity: null,
  artComposition: null,
  artComponentProofHouse: null,
  auditLogModule: null,
  auditLogEventType: null,
  auditLogEventName: null,
  apiKeyType: null,
};

export const reducer = (state: IState, { type, payload }: IReducerAction) => {
  switch (type) {
    case DictionaryActionTypes.SET_GENDER: {
      return { ...state, gender: payload };
    }
    case DictionaryActionTypes.SET_ORGANIZATION_TYPE: {
      return { ...state, organizationType: payload };
    }
    case DictionaryActionTypes.SET_LEGAL_VISIBILITY: {
      return { ...state, legalVisibility: payload };
    }
    case DictionaryActionTypes.SET_LEGAL_ENTITY_PREFIX: {
      return { ...state, legalEntityPrefix: payload };
    }
    case DictionaryActionTypes.SET_CIVILIAN_PREFIX: {
      return { ...state, civilianPrefix: payload };
    }
    case DictionaryActionTypes.SET_MILITARY_PREFIX: {
      return { ...state, militaryPrefix: payload };
    }
    case DictionaryActionTypes.SET_POLICE_PREFIX: {
      return { ...state, policePrefix: payload };
    }
    case DictionaryActionTypes.SET_MAKE: {
      return { ...state, make: payload };
    }
    case DictionaryActionTypes.SET_MODEL: {
      return { ...state, model: payload };
    }
    case DictionaryActionTypes.SET_CALIBER: {
      return { ...state, caliber: payload };
    }
    case DictionaryActionTypes.SET_BARREL_TYPE: {
      return { ...state, barrelType: payload };
    }
    case DictionaryActionTypes.SET_STATE: {
      return { ...state, state: payload };
    }
    case DictionaryActionTypes.SET_REGION: {
      return { ...state, region: payload };
    }
    case DictionaryActionTypes.SET_COUNTRY: {
      return { ...state, country: payload };
    }
    case DictionaryActionTypes.SET_PHONE_CODE: {
      return { ...state, phoneCode: payload };
    }
    case DictionaryActionTypes.SET_ADDRESS_TYPE: {
      return { ...state, addressType: payload };
    }
    case DictionaryActionTypes.SET_LEGALITY: {
      return { ...state, legality: payload };
    }
    case DictionaryActionTypes.SET_MANUFACTURER: {
      return { ...state, manufacturer: payload };
    }
    case DictionaryActionTypes.SET_LEVEL: {
      return { ...state, level: payload };
    }
    case DictionaryActionTypes.SET_FIREARM_ACTION: {
      return { ...state, firearmAction: payload };
    }
    case DictionaryActionTypes.SET_FIREARM_TYPE: {
      return { ...state, firearmType: payload };
    }
    case DictionaryActionTypes.SET_TRANSACTION_TYPE: {
      return { ...state, transactionType: payload };
    }
    case DictionaryActionTypes.SET_LEGAL_CLASSIFICATION: {
      return { ...state, legalClassification: payload };
    }
    case DictionaryActionTypes.SET_TRANSACTION_INITIAL_TYPES: {
      return { ...state, transactionInitialTypes: payload };
    }
    case DictionaryActionTypes.SET_TRANSACTION_MIDDLE_TYPES: {
      return { ...state, transactionMiddleTypes: payload };
    }
    case DictionaryActionTypes.SET_COUNTRY_OF_MANUFACTURER: {
      return { ...state, manufacturerCountry: payload };
    }
    case DictionaryActionTypes.SET_ACTIVITY: {
      return { ...state, activity: payload };
    }
    case DictionaryActionTypes.SET_ART_ANCILLARY_OPTIONS: {
      return { ...state, artAncillaryOptions: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_CALIBRE: {
      return { ...state, artAccessoryCalibre: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_COMPOSITION: {
      return { ...state, artAccessoryComposition: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_HOST_TYPE: {
      return { ...state, artAccessoryHostType: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_LIGHT_COLOUR: {
      return { ...state, artAccessoryLightColour: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_MAGNIFICATION: {
      return { ...state, artAccessoryMagnification: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_FLASH_ELIMINATOR: {
      return { ...state, artAccessoryManufacturerFlashEliminator: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_LASER_LIGHT_MODULE: {
      return { ...state, artAccessoryManufacturerLaserLightModule: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_MAGAZINE: {
      return { ...state, artAccessoryManufacturerMagazine: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_OPTICAL_SIGHT: {
      return { ...state, artAccessoryManufacturerOpticalSight: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_MANUFACTURER_SUPPRESSOR: {
      return { ...state, artAccessoryManufacturerSuppressor: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_RETICLE: {
      return { ...state, artAccessoryReticle: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_TYPE: {
      return { ...state, artAccessoryType: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_UTILITY: {
      return { ...state, artAccessoryUtility: payload };
    }
    case DictionaryActionTypes.SET_ART_ACCESSORY_PROOF_HOUSE: {
      return { ...state, artAccessoryProofHouse: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_TYPE: {
      return { ...state, artAmmunitionType: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_MANUFACTURER: {
      return { ...state, artAmmunitionManufacturer: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_FACTORY: {
      return { ...state, artAmmunitionFactory: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_CALIBRE_GAUGE: {
      return { ...state, artAmmunitionCalibreGauge: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_CARTRIDGE_COMPOSITION: {
      return { ...state, artAmmunitionCartridgeComposition: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_COLOUR_OF_PRIMER_SEALANT: {
      return { ...state, artAmmunitionColourOfPrimerSealant: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_PROJECTILE_SHAPE: {
      return { ...state, artAmmunitionProjectileShape: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_FUNCTIONAL_TYPE: {
      return { ...state, artAmmunitionFunctionalType: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_SHOT_SIZE: {
      return { ...state, artAmmunitionShotSize: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_MUNITION_TYPE: {
      return { ...state, artAmmunitionMunitionType: payload };
    }
    case DictionaryActionTypes.SET_ART_AMMUNITION_MUNITION_VELOCITY: {
      return { ...state, artAmmunitionMunitionVelocity: payload };
    }
    case DictionaryActionTypes.SET_ART_COMPONENT_TYPE: {
      return { ...state, artComponentType: payload };
    }
    case DictionaryActionTypes.SET_ART_COMPONENT_MANUFACTURER: {
      return { ...state, artComponentManufacturer: payload };
    }
    case DictionaryActionTypes.SET_ART_COMPONENT_CALIBRE: {
      return { ...state, artComponentCalibre: payload };
    }
    case DictionaryActionTypes.SET_ART_COMPONENT_CAPACITY: {
      return { ...state, artComponentCapacity: payload };
    }
    case DictionaryActionTypes.SET_ART_COMPOSITION: {
      return { ...state, artComposition: payload };
    }
    case DictionaryActionTypes.SET_ART_COMPONENT_PROOF_HOUSE: {
      return { ...state, artComponentProofHouse: payload };
    }
    case DictionaryActionTypes.SET_AUDIT_LOG_MODULE: {
      return { ...state, auditLogModule: payload };
    }
    case DictionaryActionTypes.SET_AUDIT_LOG_EVENT_TYPE: {
      return { ...state, auditLogEventType: payload };
    }
    case DictionaryActionTypes.SET_AUDIT_LOG_EVENT_NAME: {
      return { ...state, auditLogEventName: payload };
    }
    case DictionaryActionTypes.SET_API_KEY_TYPE: {
      return { ...state, apiKeyType: payload };
    }

    default:
      return state;
  }
};
