import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { UserForm } from 'pages/UsersPage/UserForm';
import { useNavigate } from 'react-router-dom';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useUsers } from 'pages/UsersPage/hooks';
import { useAlert } from 'models/alertContext';
import { initialUserFormValues, IUserFormValues } from 'models/form';
import { useRoles } from 'pages/RolesPage/hooks';
import { EPerPages } from 'models/table';
import { useTranslations } from 'hooks/useTranslations';
import { useDictionary } from 'models/dictionaryContext';
import { handleRestApiException } from 'utils/handleRestApiException';
import { useLicence } from 'pages/LicenceDetailsPage/hooks';

enum TranslationsKeys {
  emailLabel = 'addUser.email.label',
  firstNameLabel = 'addUser.firstName.label',
  lastNameLabel = 'addUser.lastName.label',
  phonePrefixLabel = 'addUser.phonePrefix.label',
  phoneNumberLabel = 'addUser.phoneNumber.label',
  roleLabel = 'addUser.role.label',
  legalEntityPrefix = 'addUser.legalEntityPrefix.label',
}

const AddUserPage = () => {
  const navigate = useNavigate();
  const { handleCreate } = useUsers();
  const { setAlert, clearAlert } = useAlert();
  const { roles, getRolesData } = useRoles();
  const { legalEntityPrefix } = useDictionary();
  const { getLicenceData, licence } = useLicence();
  const { t } = useTranslations();
  const [initialValues, setInitialValues] = useState<IUserFormValues | null>();

  const handleSubmit = async (data: IUserFormValues) => {
    try {
      await handleCreate(data);
      navigate(ERouteLinks.Users);
    } catch (e: any) {
      setAlert(ActionTypes.SET_USER_ALERT, {
        text: handleRestApiException(e) ?? '',
        variant: EAlertVariants.error,
      });
    }
  };
  useEffect(() => {
    const formInitialValues = { ...initialUserFormValues };
    roles.forEach((role) => {
      formInitialValues.formRoleIds[role.uniqueId] = false;
    });
    if (legalEntityPrefix) {
      legalEntityPrefix.forEach((prefix) => {
        formInitialValues.formPrefixGroupIds[prefix.uniqueId] = false;
      });
    }
    setInitialValues(formInitialValues);
  }, [roles, legalEntityPrefix]);

  useEffect(
    () => {
      (async function getRolesDataInit() {
        await getRolesData(1, EPerPages.perPage50);
        await getLicenceData();
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_USER_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('user.breadcrumbs.users'),
            route: ERouteLinks.Users,
          },
          t('addUser.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addUser.header')}</StyledPageTitle>

      <TableFormWrapper>
        {!!roles.length &&
          initialValues?.formRoleIds &&
          Object.keys(initialValues.formRoleIds).length && (
            <UserForm
              onCancel={() => navigate(ERouteLinks.Users)}
              onSubmit={handleSubmit}
              initialFormValues={initialValues}
              translationsKeys={TranslationsKeys}
              roles={roles}
              legalEntityPrefix={legalEntityPrefix}
              isDisabled={
                !!(
                  licence &&
                  licence.numberOfUsers &&
                  licence.numberOfUsers <= licence.currentSystemUsers
                )
              }
            />
          )}
      </TableFormWrapper>
    </>
  );
};

export { AddUserPage };
