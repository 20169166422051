import styled from '@emotion/styled';
import { colors } from 'theme/colors';
import { css } from '@mui/material';

interface IStyledCounter {
  open: boolean;
}

const StyledCounter = styled.div<IStyledCounter>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${colors.error600};

  ${(props: IStyledCounter) =>
    !props.open &&
    css`
      display: none;
    `}
  & span {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.white};
  }
`;

export { StyledCounter };
