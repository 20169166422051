import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { colors } from 'theme/colors';

export const StyledDetailsWrapper = styled(Grid)`
  padding: 20px;
  border-radius: 4px;
  border: 1px solid ${colors.gray200};
  background-color: ${colors.gray100};
`;
