import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError, TResponse } from 'models/apiClient';
import { ICommentFormValues } from 'components/organisms/CommentForm';
import { ICommentData } from 'models/comment';

export interface IAncillaryCommentData extends ICommentData {}

const deleteComment = async (id: string, isHidden: boolean): Promise<any> => {
  const res = await apiClient.put(`${ApiEndpoints.DELETE_COMMENT(id)}?value=${isHidden}`);

  if (isResponseError(res.data)) {
    return null;
  }
  return res;
};

const createAncillaryComment = async (comment: ICommentFormValues) => {
  const { uniqueId, ...body } = comment;

  const { data } = await apiClient.post<TResponse<IAncillaryCommentData>>(
    ApiEndpoints.CREATE_ACCESSORY_COMMENT(uniqueId),
    body,
  );
  return !isResponseError(data);
};

export { deleteComment, createAncillaryComment };
