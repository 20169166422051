import * as yup from 'yup';
import { useTranslations } from 'hooks/useTranslations';

const useAmmunitionSearchFormSchemaValidation = () => {
  const { t } = useTranslations();
  const schema = yup.object({
    headstampYearMarking: yup
      .string()
      .nullable()
      .test(
        'lengthTest',
        t('general.field.validation.maxCharacters').replace('{0}', '4'),
        (obj) => !(obj && obj.length > 4),
      )
      .test(
        'valueTest',
        t('general.validation.field.onlyIntegersAllowed.label'),
        (obj) => !(obj && ['e', 'E', '+', '-', '.', ','].some((char) => obj.includes(char))),
      ),
  });
  return { schema };
};

export { useAmmunitionSearchFormSchemaValidation };
