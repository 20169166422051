import { ECustomFieldType, ICustomFieldByObjectType } from 'models/customField';
import { FormInput } from 'components/molecules/FormInput';
import React, { ReactNode, useEffect, useState } from 'react';
import { FormDatePicker } from 'components/molecules/FormDatePicker';
import { FormSelect } from 'components/molecules/FormSelect';
import { Control, FieldErrors } from 'react-hook-form';
import { useTranslations } from 'hooks/useTranslations';
import { useLang } from 'models/langContext';
import { MaskedFormInput } from 'components/molecules/MaskedFormInput';
import { useGlobalProperty } from 'models/globalPropertyContext';

interface IConditionalWrapper {
  wrapper?: (children: ReactNode) => JSX.Element;
  children: NonNullable<ReactNode>;
}

interface IFormCustomField {
  data: ICustomFieldByObjectType;
  control: Control<any>;
  errors: FieldErrors;
  setValue: any;
  wrapper?: (children: ReactNode) => JSX.Element;
  isSearch?: boolean;
  arrayName?: string;
  arrayIndex?: number;
}

export const CHARS_LIMIT = 30;

const ConditionalWrapper = ({ wrapper, children }: IConditionalWrapper) =>
  wrapper ? wrapper(children as JSX.Element) : <div>{children}</div>;

const FormCustomField = ({
  data,
  control,
  errors,
  wrapper,
  arrayName,
  arrayIndex,
  setValue,
  isSearch = false,
}: IFormCustomField) => {
  const { t } = useTranslations();
  const { selectedLanguage } = useLang();
  const [placeholderName, setPlaceholderName] = useState<string | null>(null);
  const { shortDateFormat, getDateFormat } = useGlobalProperty();

  useEffect(() => {
    getDateFormat();
  }, []);

  useEffect(() => {
    if (data.placeholder) {
      const translationPlaceholder =
        data.placeholder.find(
          (holder) => holder.languageUniqueId === selectedLanguage?.uniqueId,
        )?.name || '';
      setPlaceholderName(translationPlaceholder);
    }
  }, [data.placeholder]);

  const name =
    arrayName && arrayIndex !== undefined
      ? `${arrayName}[${arrayIndex}].${data.name}`
      : data.name;

  const translationName =
    data.translations.find(
      (translation) => translation.languageUniqueId === selectedLanguage?.uniqueId,
    )?.name || '';

  switch (data.customFieldType) {
    case ECustomFieldType.TEXT:
      return data.inputMaskEnabled ? (
        <ConditionalWrapper wrapper={wrapper}>
          <MaskedFormInput
            name={name}
            label={`${translationName}${!isSearch && data.mandatory ? '*' : ''}`}
            placeholder={placeholderName || translationName}
            control={control}
            errors={errors}
            mask={data.inputMask}
            arrayName={arrayName}
            arrayIndex={arrayIndex}
            fieldName={data.name}
          />
        </ConditionalWrapper>
      ) : (
        <ConditionalWrapper wrapper={wrapper}>
          <FormInput
            name={name}
            label={`${translationName}${!isSearch && data.mandatory ? '*' : ''}`}
            placeholder={placeholderName || translationName}
            control={control}
            setValue={setValue}
            errors={errors}
            charsLimit={CHARS_LIMIT}
            arrayName={arrayName}
            arrayIndex={arrayIndex}
            fieldName={data.name}
          />
        </ConditionalWrapper>
      );

    case ECustomFieldType.DATE:
      return isSearch ? (
        <>
          <ConditionalWrapper wrapper={wrapper}>
            <FormDatePicker
              name={`from_${data.name}`}
              openTo="day"
              dateFormat={shortDateFormat}
              label={`${translationName} (${t('general.from.label')})`}
              placeholder={placeholderName || translationName}
              control={control}
              errors={errors}
            />
          </ConditionalWrapper>
          <ConditionalWrapper wrapper={wrapper}>
            <FormDatePicker
              name={`to_${data.name}`}
              openTo="day"
              dateFormat={shortDateFormat}
              label={`${translationName} (${t('general.to.label')})`}
              placeholder={placeholderName || translationName}
              control={control}
              errors={errors}
            />
          </ConditionalWrapper>
        </>
      ) : (
        <ConditionalWrapper wrapper={wrapper}>
          <FormDatePicker
            name={name}
            openTo="day"
            dateFormat={shortDateFormat}
            label={`${translationName}${data.mandatory ? '*' : ''}`}
            placeholder={placeholderName || undefined}
            control={control}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={arrayIndex}
            fieldName={data.name}
          />
        </ConditionalWrapper>
      );

    case ECustomFieldType.BOOL:
      return (
        <ConditionalWrapper wrapper={wrapper}>
          <FormSelect
            options={[
              { label: t('customFields.value.yes.label'), value: '1' },
              { label: t('customFields.value.no.label'), value: '0' },
            ]}
            setValue={setValue}
            name={name}
            label={`${translationName}${!isSearch && data.mandatory ? '*' : ''}`}
            control={control}
            errors={errors}
            arrayName={arrayName}
            arrayIndex={arrayIndex}
            fieldName={data.name}
          />
        </ConditionalWrapper>
      );

    default:
      return null;
  }
};

export default FormCustomField;
