import { Theme } from '@mui/material';
import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import { colors } from 'theme/colors';

export const StyledSelectedFiltersWrapper = styled(Grid)`
  background-color: ${colors.white};
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 16px;
  border-radius: 5px;
  column-gap: 8px;
  row-gap: 16px;
  margin-top: 10px;

  ${({ theme }) => `
    ${(theme as Theme).breakpoints.down('lg')} {
      padding: 16px;
      };
 `};

  & > div:first-of-type {
    margin-right: 10px;
    min-width: fit-content;
  }

  & > div:nth-of-type(2) {
    width: inherit;
  }

  & > div:nth-of-type(3) {
    width: fit-content;
    min-width: fit-content;
    gap: 8px;
    margin: auto;
  }
`;

export const StyledButtonsContainer = styled(Grid)`
  justify-content: space-between;
  margin-top: 0px;
  & > div {
    padding: 0px !important;
  }
`;

export const StyledFilterChipWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StyledFilters = styled(Grid)`
  & .MuiGrid-item {
    padding-top: 0px;
  }
`;
