import { EArtAncillaryType, EArtAncillaryTypeName } from 'constants/ArtAccessoryType';

const getAncillaryNameById = (uniqueId: string) => {
  switch (uniqueId) {
    case EArtAncillaryType.ACCESSORIES:
      return EArtAncillaryTypeName.ACCESSORY.toUpperCase();
    case EArtAncillaryType.AMMUNITION:
      return EArtAncillaryTypeName.AMMUNITION.toUpperCase();
    case EArtAncillaryType.COMPONENTS:
      return EArtAncillaryTypeName.COMPONENTS.toUpperCase();
    default:
      return null;
  }
};

export { getAncillaryNameById };
