import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { colors } from 'theme/colors';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { Chips, EChipsVariants } from 'components/atoms/Chips';
import { INACTIVE_ID } from 'pages/Firearms';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useNavigate } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { IDictionary } from 'models/dictionary';
import { parseDictionary } from 'helpers/dictionary';
import { LEGAL_ID } from 'pages/Firearms/RegisteredFirearmsMapPage/FirearmsLocationMap';
import { IAncillary, IComponent } from 'models/ancillaries';

interface IComponentDataListItem {
  data: IComponent | IAncillary;
  state: IDictionary[] | null;
}

const renderDataColor = (data: IComponent | IAncillary, isLegal: boolean) => {
  if (!isLegal) {
    return {
      background: colors.error15,
      border: colors.error100,
      stateField: EChipsVariants.error,
    };
  }
  if (data.activityUniqueId === INACTIVE_ID) {
    return {
      background: colors.gray200,
      border: colors.gray400,
      stateField: EChipsVariants.inactive,
    };
  }
  return {
    background: colors.gray100,
    border: colors.gray200,
    stateField: EChipsVariants.primary,
  };
};

const ComponentDataListItem = ({ data, state }: IComponentDataListItem) => {
  const { t } = useTranslations();
  const navigate = useNavigate();
  const isLegal = data.legalityUniqueId === LEGAL_ID;

  return (
    <Grid
      container
      sx={{
        border: `1px solid ${colors.gray200}`,
        borderRadius: '4px',
        overflow: 'hidden',
        mt: 1,
        backgroundColor: `${isLegal ? colors.gray100 : colors.error15}`,
      }}
    >
      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.componentDetails.section.serialNumber.label')}
        </Typography>
        <Typography variant="body2">{data.serialNumber || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.componentDetails.section.firecycleId.label')}
        </Typography>
        <Typography variant="body2">{data.fireCycleId || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.componentDetails.section.componentType.label')}
        </Typography>
        <Typography variant="body2">{data.type || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.componentDetails.section.firearmType.label')}
        </Typography>
        <Typography variant="body2">{data.frtType || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.componentDetails.section.make.label')}
        </Typography>
        <Typography variant="body2">{data.frtMake || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.componentDetails.section.model.label')}
        </Typography>
        <Typography variant="body2">{data.frtModel || '-'}</Typography>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        <Typography variant="subtitle2" sx={{ marginBottom: '4px' }}>
          {t('ancillaries.ancillariesLocation.componentDetails.section.state.label')}
        </Typography>
        <Chips
          text={parseDictionary(state, 'name')[data.stateUniqueId]}
          variant={renderDataColor(data, isLegal).stateField}
        />
      </Grid>

      <Grid
        container
        sx={{
          margin: '0 10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTop: {
            xs: `1px solid ${colors.gray200}`,
          },
        }}
        p={2}
      >
        <Grid item xs={12} sm={12}>
          <Button
            label={t('registeredFirearms.firearmsLocation.seeDetails.label')}
            variant={EButtonVariants.outlined}
            onClick={() =>
              navigate(`${ERouteLinks.ViewComponents.replace(':id', data.uniqueId)}`)
            }
            size="small"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComponentDataListItem;
