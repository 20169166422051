import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { IFormElementOptionProp } from 'models/form';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Alert } from 'components/atoms/Alert';
import { ERouteLinks } from 'models/route';
import { useTranslations } from 'hooks/useTranslations';
import { FormSelect } from 'components/molecules/FormSelect';
import { Box } from '@mui/material';
import Button from 'components/atoms/Button';
import { EButtonVariants } from 'constants/Buttons';
import { useAlert } from 'models/alertContext';
import { FormInput } from 'components/molecules/FormInput';
import { ActionTypes } from 'state/actions/alert';
import { useAuthenticationSetup } from 'pages/SystemSettingsPage/AuthenticationSetupPage/hooks';
import { useScroll } from 'hooks/useScroll';
import TableFormWrapper from 'components/atoms/FormWrapper';
import useDateTimeSetupFormSchema from './schemaValidation';
import { useDateTimeSetup } from './hooks';
import { StyledValueFormHeader } from '../styles';

const dateFormatList = ['dd/MM/yyyy', 'yyyy/MM/dd', 'MM/dd/yyyy', 'dd/MM/yy', 'MM/dd/yy'];

export interface IFormValues {
  dateFormat: string;
  timeZone?: string;
}

const initialFormValues = {
  dateFormat: '',
  timeZone: '',
};

const DateTimeSetupPage = () => {
  const { t } = useTranslations();
  const { samePageAlert, clearAlert } = useAlert();
  const navigate = useNavigate();
  const { handleUpdate, getDateTimeSetupData, dateTimeSetup } = useDateTimeSetup();
  const [initialValues, setInitialValues] = useState<IFormValues>(initialFormValues);
  const { schema } = useDateTimeSetupFormSchema();
  const { elRef, scrollToError } = useScroll();
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const { getTimeZoneData, timeZoneData } = useAuthenticationSetup();

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const dateFormatDropdownOptions: IFormElementOptionProp[] = dateFormatList.map((option) => ({
    label: option.toUpperCase(),
    value: option,
  }));

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  useEffect(() => {
    if (dateTimeSetup) {
      setInitialValues(dateTimeSetup);
    }
  }, [dateTimeSetup]);

  useEffect(() => {
    (async function getDataInit() {
      await getDateTimeSetupData();
      await getTimeZoneData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      scrollToError(errors);
    })();
  }, [errors]);

  const onSubmitForm = async (data: IFormValues) => {
    setSubmitLoading(true);
    await handleUpdate(data);
    setSubmitLoading(false);
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          { label: t('systemSettings.header'), route: ERouteLinks.SystemSettings },
          t('dateTimeSetup.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('dateTimeSetup.header')}</StyledPageTitle>

      <TableFormWrapper>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container ref={elRef}>
            <Grid container rowGap={1}>
              <Grid item container>
                <Grid item xs={12}>
                  <StyledValueFormHeader>
                    {t('dateTimeSetup.dateFormat.header')}
                  </StyledValueFormHeader>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Box mb={1}>
                    <FormSelect
                      name="dateFormat"
                      label={`${t('dateTimeSetup.dateFormat.label')}`}
                      setValue={setValue}
                      options={dateFormatDropdownOptions}
                      canBeEmpty={false}
                      control={control}
                      errors={errors}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <StyledValueFormHeader>
                  {t('dateTimeSetup.timeZone.header')}
                </StyledValueFormHeader>
              </Grid>
              <Grid item xs={12} lg={6} mt={1}>
                <Box mb={1}>
                  <FormInput
                    name="timeZone"
                    placeholder={timeZoneData || ''}
                    label={`${t('dateTimeSetup.timeZone.label')}`}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    disabled={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container columnSpacing={3} rowGap={1} mt={1}>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                type="submit"
                fullWidth
                label={t('general.save.button')}
                isLoading={isSubmitLoading}
                id="save-button"
                variant={EButtonVariants.contained}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Button
                fullWidth
                label={t('general.cancel.button')}
                id="cancel-button"
                variant={EButtonVariants.outlined}
                onClick={() => navigate(ERouteLinks.SystemSettings)}
              />
            </Grid>
          </Grid>
        </form>
      </TableFormWrapper>

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export default DateTimeSetupPage;
