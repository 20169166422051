import { useState } from 'react';
import { IPaginator } from 'models/paginator';
import { EPerPages, ESortDirectionTypes, ISortOptions } from 'models/table';
import { IFirearm, ISingleFRT } from 'models/firearm';
import {
  createFirearmComment,
  getFirearmAttachments,
  getFirearmComments,
  getFirearms,
  getFirearmsAvailableForGroup,
  getFirearmsForMap,
  getFirearmTransactions,
  getFRT,
} from 'requests/firearm';
import {
  IFirearmCommentData,
  IRegisteredFirearmAttachment,
  IRegisteredFirearmTransaction,
} from 'models/registeredFirearm';
import { ISearchTransactionFormValues } from 'models/form';
import { useDictionary } from 'models/dictionaryContext';
import { ERouteLinks } from 'models/route';
import { ActionTypes } from 'state/actions/alert';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { useAlert } from 'models/alertContext';
import { deleteComment } from 'requests/comment';
import { ICommentFormValues } from 'components/organisms/CommentForm';
import { addAttachment, deleteAttachment, previewAttachment } from 'requests/attachment';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes } from 'models/dictionary';
import {
  getFirearmLastTransaction,
  isGroupTransactionDiscardable,
} from 'requests/transaction';

export const useFirearms = () => {
  const [firearms, setFirearms] = useState<IFirearm[]>([]);
  const [paginator, setPaginator] = useState<IPaginator | null>(null);
  const [FRT, setFRT] = useState<ISingleFRT[]>([]);
  const [paginatorFRT, setPaginatorFRT] = useState<IPaginator | null>(null);
  const [paginatorFT, setPaginatorFT] = useState<IPaginator | null>(null);
  const [paginatorFA, setPaginatorFA] = useState<IPaginator | null>(null);
  const { t } = useTranslations();
  const [firearmTransactions, setFirearmTransactions] = useState<
    IRegisteredFirearmTransaction[]
  >([]);
  const [discardableFirearmTransactionId, setDiscardableFirearmTransactionId] = useState<
    string | null
  >(null);
  const [firearmComments, setFirearmComments] = useState<IFirearmCommentData[]>([]);
  const [firearmAttachments, setFirearmAttachments] = useState<IRegisteredFirearmAttachment[]>(
    [],
  );
  const [paginatorFComments, setPaginatorFComments] = useState<IPaginator | null>(null);
  const {
    state,
    getState,
    legality,
    getLegality,
    make,
    getMake,
    model,
    getModel,
    manufacturer,
    getManufacturer,
    caliber,
    getCaliber,
    firearmAction,
    getFirearmAction,
    country,
    getCountry,
    firearmType,
    getFirearmType,
    transactionType,
    getTransactionType,
  } = useDictionary();
  const { setAlert } = useAlert();
  const navigate = useNavigate();
  const query = useQuery();
  const expand: any = query.get('expand');
  const { id: fUniqueId = '' } = useParams<{ id: string }>();

  const getFirearmsData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    firearmsQuery: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const firearmsData = await getFirearms(currentPage, perPage, firearmsQuery, sort);
    if (firearmsData) {
      const { content, ...paginatorData } = firearmsData;
      setFirearms(content);
      setPaginator(paginatorData);
    } else {
      setFirearms([]);
      setPaginator(null);
    }
  };

  const getFirearmsDataForMap = async (firearmsQuery: Partial<any> = {}) => {
    const firearmsData = await getFirearmsForMap(firearmsQuery);
    if (firearmsData) {
      setFirearms(firearmsData);
    } else {
      setFirearms([]);
    }
  };

  const getFRTData = async (
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    frtQuery: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const FRTData = await getFRT(currentPage, perPage, frtQuery, {
      property: 'make',
      direction: ESortDirectionTypes.ASC,
      ...sort,
    });

    if (FRTData) {
      const { content, ...paginatorData } = FRTData;
      setFRT(content);
      setPaginatorFRT(paginatorData);
    } else {
      setFRT([]);
      setPaginatorFRT(null);
    }
  };

  const getFirearmsAvailableForGroupData = async (
    leId: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    fafgquery: Partial<any> = {},
    sort?: ISortOptions | null,
  ) => {
    const firearmsData = await getFirearmsAvailableForGroup(
      leId,
      currentPage,
      perPage,
      fafgquery,
      sort,
    );

    if (firearmsData) {
      const { content, ...paginatorData } = firearmsData;
      setFirearms(content);
      setPaginator(paginatorData);
    } else {
      setFirearms([]);
      setPaginator(null);
    }
  };

  const getDiscardableFirearmTransactionId = async (firearmId: string) => {
    getFirearmLastTransaction(firearmId, false).then((transaction) => {
      if (transaction && transaction.groupUniqueId !== null) {
        isGroupTransactionDiscardable(transaction.uniqueId).then((isDiscardable) => {
          if (isDiscardable) {
            setDiscardableFirearmTransactionId(transaction.uniqueId);
          } else {
            setDiscardableFirearmTransactionId(null);
          }
        });
      } else if (transaction) {
        setDiscardableFirearmTransactionId(transaction.uniqueId);
      } else {
        setDiscardableFirearmTransactionId(null);
      }
    });
  };

  const getFirearmCommentsData = async (
    id: string,
    queryParams: {
      withHidden: boolean;
      currentPage: number;
      perPage: EPerPages;
    },
  ) => {
    const firearmCommentsData = await getFirearmComments(id, queryParams);
    if (firearmCommentsData) {
      const { content, ...paginatorData } = firearmCommentsData;

      setFirearmComments(content);
      setPaginatorFComments(paginatorData);
    } else {
      setFirearmComments([]);
      setPaginatorFComments(null);
    }
  };

  const handleCreateComment = async (data: ICommentFormValues) => {
    const response = await createFirearmComment(data);

    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('addComment.commentAdded.text'),
      });
    }

    return response;
  };

  const getFirearmTransactionsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    transactionQuery: Partial<ISearchTransactionFormValues> = {},
    transactionSort?: ISortOptions | null,
  ) => {
    const firearmTransactionsData = await getFirearmTransactions(
      id,
      currentPage,
      perPage,
      transactionQuery,
      transactionSort,
    );
    if (firearmTransactionsData) {
      const { content, ...paginatorData } = firearmTransactionsData;
      setFirearmTransactions(content);
      setPaginatorFT(paginatorData);
    } else {
      setFirearmTransactions([]);
      setPaginatorFT(null);
    }
  };

  const getFirearmAttachmentsData = async (
    id: string,
    currentPage: number = 1,
    perPage: EPerPages = EPerPages.perPage25,
    attachmentSort?: ISortOptions | null,
  ) => {
    const firearmAttachmentsData = await getFirearmAttachments(
      id,
      currentPage,
      perPage,
      attachmentSort,
    );
    if (firearmAttachmentsData) {
      const { content, ...paginatorData } = firearmAttachmentsData;
      setFirearmAttachments(content);
      setPaginatorFA(paginatorData);
    } else {
      setFirearmAttachments([]);
      setPaginatorFA(null);
    }
  };

  const loadDictionaries = async (types: EDictionaryTypes[]) => {
    if (types.includes(EDictionaryTypes.State)) {
      getState();
    }
    if (types.includes(EDictionaryTypes.TransactionType)) {
      getTransactionType();
    }
  };

  const navigateToDetails = () => {
    navigate(
      `${ERouteLinks.ViewRegisteredFirearm.replace(':id', fUniqueId)}?expand=${expand}`,
    );
  };

  const handleDeleteComment = async (id: string, isHidden: any) => {
    const response = await deleteComment(id, isHidden);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: isHidden
          ? t('enableComment.commentEnabled.text')
          : t('disableComment.commentDisabled.text'),
      });
    }
    return response;
  };

  const handleDeleteAttachment = async (firearmId: string, attachmentId: string) => {
    const response = await deleteAttachment(firearmId, attachmentId);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('deleteAttchment.attachmentRemoved.text'),
      });
    }
    return response;
  };

  const handlePreviewAttachment = async (firearmId: string, attachmentId: string) =>
    previewAttachment(firearmId, attachmentId);

  const handleAddAttachment = async (firearmId: string, data: any) => {
    const response = await addAttachment(firearmId, data);
    if (response) {
      await setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
        text: t('addAttachment.attachmentAdded.text'),
      });
    }
    return response;
  };

  const loadDictionariesForRegisteredFirearms = async (types: EDictionaryTypes[]) => {
    if (types.includes(EDictionaryTypes.State)) {
      getState();
    }
    if (types.includes(EDictionaryTypes.Legality)) {
      getLegality();
    }
    if (types.includes(EDictionaryTypes.Make)) {
      getMake();
    }
    if (types.includes(EDictionaryTypes.Model)) {
      getModel();
    }
    if (types.includes(EDictionaryTypes.State)) {
      getState();
    }
    if (types.includes(EDictionaryTypes.Caliber)) {
      getCaliber();
    }
    if (types.includes(EDictionaryTypes.Manufacturer)) {
      getManufacturer();
    }
    if (types.includes(EDictionaryTypes.FirearmAction)) {
      getFirearmAction();
    }
    if (types.includes(EDictionaryTypes.Country)) {
      getCountry();
    }
    if (types.includes(EDictionaryTypes.FirearmType)) {
      getFirearmType();
    }
  };

  return {
    loadDictionaries,
    alert,
    firearms,
    paginator,
    getFirearmsData,
    getFirearmsAvailableForGroupData,
    getDiscardableFirearmTransactionId,
    discardableFirearmTransactionId,
    getFRTData,
    FRT,
    paginatorFRT,
    firearmTransactions,
    paginatorFT,
    getFirearmTransactionsData,
    firearmComments,
    getFirearmCommentsData,
    paginatorFComments,
    navigateToDetails,
    handleCreateComment,
    handleDeleteComment,
    firearmAttachments,
    getFirearmAttachmentsData,
    paginatorFA,
    handleAddAttachment,
    handleDeleteAttachment,
    loadDictionariesForRegisteredFirearms,
    handlePreviewAttachment,
    getFirearmsDataForMap,
    state,
    legality,
    make,
    model,
    manufacturer,
    caliber,
    firearmAction,
    country,
    firearmType,
    transactionType,
  };
};
