import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { StyledPageTitle } from 'theme/styles';
import { useFilterPresets } from 'hooks/useFilterPresets';
import { ESearchFilterTypes } from 'constants/SearchFilterTypes';
import { ISearchFilter } from 'models/searchFilter';
import { Box, Grid } from '@mui/material';
import { PresetItem } from 'pages/MyFiltersPage/PresetItem';
import { ESearchFormLabels as ESearchFormLabelsILE } from 'pages/LegalEntitiesPage/IndividualSearchForm';
import { ESearchFormLabels as ESearchFormLabelsOLE } from 'pages/LegalEntitiesPage/OrganisationSearchForm';
import { useDictionary } from 'models/dictionaryContext';
import { Alert } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Accordion } from 'components/molecules/Accordion';
import { useTranslations } from 'hooks/useTranslations';
import { ESearchFormFirearmLabels } from 'pages/Firearms/RegisteredFirearmsSearchForm';
import { ESearchFormTransactionLabels } from 'pages/TransactionsPage/TransactionSearchForm';
import { useLang } from 'models/langContext';
import { usePermission } from 'hooks/usePermission';
import { EPermission } from 'models/permissions';
import { EAncillaryLabels } from 'pages/AncillariesPage/AncillariesReferenceTablePage/DetailAncillariesReference';
import { NoFiltersDefined } from './NoFiltersDefined';

type ISortedPresets = {
  [key in ESearchFilterTypes]?: ISearchFilter[];
};

const MyFiltersPage = () => {
  const { getFilterPresets, presets } = useFilterPresets();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sortedPresets, setSortedPresets] = useState<ISortedPresets>({});
  const { samePageAlert, clearAlert } = useAlert();
  const { selectedLanguage } = useLang();
  const { hasPermissions } = usePermission();
  const { t } = useTranslations();
  const {
    gender,
    getGender,
    legalVisibility,
    getLegalVisibility,
    organizationType,
    getOrganizationType,
    country,
    getCountry,
    legality,
    getLegality,
    state,
    getState,
    getFirearmType,
    transactionType,
    getTransactionType,
    artAncillaryOptions,
    getArtAncillaryOptions,
    artAccessoryType,
    getArtAccessoryType,
    artAccessoryManufacturerFlashEliminator,
    getArtAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    getArtAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    getArtAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    getArtAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    getArtAccessoryManufacturerSuppressor,
    artAccessoryCalibre,
    getArtAccessoryCalibre,
    artAccessoryComposition,
    getArtAccessoryComposition,
    artAccessoryHostType,
    getArtAccessoryHostType,
    artAccessoryLightColour,
    getArtAccessoryLightColour,
    artAccessoryMagnification,
    getArtAccessoryMagnification,
    artAccessoryReticle,
    getArtAccessoryReticle,
    artAccessoryUtility,
    getArtAccessoryUtility,
    artAccessoryProofHouse,
    getArtAccessoryProofHouse,
    artAmmunitionType,
    artAmmunitionManufacturer,
    artAmmunitionFactory,
    artAmmunitionCalibreGauge,
    artAmmunitionFunctionalType,
    artAmmunitionCartridgeComposition,
    artAmmunitionColourOfPrimerSealant,
    artAmmunitionProjectileShape,
    artAmmunitionShotSize,
    artAmmunitionMunitionType,
    artAmmunitionMunitionVelocity,
    getArtAmmunitionType,
    getArtAmmunitionManufacturer,
    getArtAmmunitionFactory,
    getArtAmmunitionCalibreGauge,
    getArtAmmunitionFunctionalType,
    getArtAmmunitionCartridgeComposition,
    getArtAmmunitionColourOfPrimerSealant,
    getArtAmmunitionProjectileShape,
    getArtAmmunitionShotSize,
    getArtAmmunitionMunitionType,
    getArtAmmunitionMunitionVelocity,
    artComponentCalibre,
    artComponentCapacity,
    artComposition,
    artComponentManufacturer,
    artComponentType,
    artComponentProofHouse,
    getArtComponentCalibre,
    getArtComponentCapacity,
    getArtComposition,
    getArtComponentManufacturer,
    getArtComponentType,
    getArtComponentProofHouse,
  } = useDictionary();

  useEffect(() => {
    (async function initFilterPresets() {
      await getFilterPresets();
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function initDictionaries() {
      getGender();
      getLegalVisibility();
      getOrganizationType();
      getCountry();
      getLegality();
      getState();
      getFirearmType();
      getTransactionType();
      getArtAncillaryOptions();
      getArtAccessoryType();
      getArtAccessoryManufacturerFlashEliminator();
      getArtAccessoryManufacturerLaserLightModule();
      getArtAccessoryManufacturerMagazine();
      getArtAccessoryManufacturerOpticalSight();
      getArtAccessoryManufacturerSuppressor();
      getArtAccessoryCalibre();
      getArtAccessoryComposition();
      getArtAccessoryHostType();
      getArtAccessoryLightColour();
      getArtAccessoryMagnification();
      getArtAccessoryReticle();
      getArtAccessoryUtility();
      getArtAccessoryProofHouse();
      getArtAmmunitionType();
      getArtAmmunitionManufacturer();
      getArtAmmunitionFactory();
      getArtAmmunitionCalibreGauge();
      getArtAmmunitionFunctionalType();
      getArtAmmunitionCartridgeComposition();
      getArtAmmunitionColourOfPrimerSealant();
      getArtAmmunitionProjectileShape();
      getArtAmmunitionShotSize();
      getArtAmmunitionMunitionType();
      getArtAmmunitionMunitionVelocity();
      getArtComponentCalibre();
      getArtComponentCapacity();
      getArtComposition();
      getArtComponentManufacturer();
      getArtComponentType();
      getArtComponentProofHouse();
    })();
  }, [selectedLanguage]);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (presets && presets.length) {
      const sorted: ISortedPresets = {
        [ESearchFilterTypes.individual]: [],
        [ESearchFilterTypes.organisation]: [],
        [ESearchFilterTypes.firearm]: [],
        [ESearchFilterTypes.firearmInGroup]: [],
        [ESearchFilterTypes.legalFirearm]: [],
        [ESearchFilterTypes.transaction]: [],
        [ESearchFilterTypes.ancillaries]: [],
      };
      presets.map((preset) => {
        if (sorted[preset.type]) {
          sorted[preset.type]?.push(preset);
        }
        return false;
      });
      setSortedPresets(sorted);
    }
  }, [presets]);

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('userSettings.header'), route: ERouteLinks.Account },
          t('myFilters.header'),
        ]}
      />
      <StyledPageTitle mb={2} variant="h4">
        {t('myFilters.header')}
      </StyledPageTitle>

      {samePageAlert && (
        <Box mt={2} mb={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}

      <div>
        <Grid item xs={12}>
          {organizationType &&
            gender &&
            legalVisibility &&
            hasPermissions([EPermission.VIEW_LE, EPermission.VIEW_ALL_LE], true) && (
              <Accordion title={t('legalEntities.header')} expanded={true}>
                {!isLoading &&
                  !sortedPresets[ESearchFilterTypes.individual]?.length &&
                  !sortedPresets[ESearchFilterTypes.organisation]?.length && (
                    <NoFiltersDefined />
                  )}
                <div>
                  {!isLoading &&
                    sortedPresets[ESearchFilterTypes.individual]?.map((preset) => (
                      <PresetItem
                        key={preset.uniqueId}
                        preset={preset}
                        dictionaries={{ gender, legalVisibility, organizationType }}
                        labels={ESearchFormLabelsILE}
                      />
                    ))}
                  {!isLoading &&
                    sortedPresets[ESearchFilterTypes.organisation]?.map((preset) => (
                      <PresetItem
                        key={preset.uniqueId}
                        preset={preset}
                        dictionaries={{ gender, legalVisibility, organizationType }}
                        labels={ESearchFormLabelsOLE}
                      />
                    ))}
                </div>
              </Accordion>
            )}
        </Grid>
      </div>

      <div>
        <Grid item xs={12}>
          {hasPermissions(
            [EPermission.VIEW_ALL_FIREARMS, EPermission.VIEW_FIREARMS],
            true,
          ) && (
            <Accordion title={t('module.firearm.name')} expanded={true}>
              {!isLoading && !sortedPresets[ESearchFilterTypes.firearm]?.length && (
                <NoFiltersDefined />
              )}
              <div>
                {!isLoading &&
                  sortedPresets[ESearchFilterTypes.firearm]?.map((preset) => (
                    <PresetItem
                      key={preset.uniqueId}
                      preset={preset}
                      labels={ESearchFormFirearmLabels}
                      dictionaries={{
                        country,
                        legality,
                        state,
                      }}
                    />
                  ))}
              </div>
            </Accordion>
          )}
        </Grid>
      </div>

      <div>
        <Grid item xs={12}>
          {hasPermissions(
            [EPermission.VIEW_ALL_TRANSACTIONS, EPermission.VIEW_TRANSACTIONS],
            true,
          ) && (
            <Accordion title={t('module.transactions.name')} expanded={true}>
              {!isLoading && !sortedPresets[ESearchFilterTypes.transaction]?.length && (
                <NoFiltersDefined />
              )}
              <div>
                {!isLoading &&
                  sortedPresets[ESearchFilterTypes.transaction]?.map((preset) => (
                    <PresetItem
                      key={preset.uniqueId}
                      preset={preset}
                      labels={ESearchFormTransactionLabels}
                      dictionaries={{
                        legality,
                        transactionType,
                      }}
                    />
                  ))}
              </div>
            </Accordion>
          )}
        </Grid>
      </div>
      <div>
        <Accordion title={t('module.ancillaries.name')} expanded={true}>
          {!isLoading && !sortedPresets[ESearchFilterTypes.ancillaries]?.length && (
            <NoFiltersDefined />
          )}
          <div>
            {!isLoading &&
              sortedPresets[ESearchFilterTypes.ancillaries]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((preset) => (
                  <PresetItem
                    key={preset.uniqueId}
                    preset={preset}
                    labels={EAncillaryLabels}
                    dictionaries={{
                      state,
                      legality,
                      artAncillaryOptions,
                      artAccessoryType,
                      artAccessoryManufacturerFlashEliminator,
                      artAccessoryManufacturerLaserLightModule,
                      artAccessoryManufacturerMagazine,
                      artAccessoryManufacturerOpticalSight,
                      artAccessoryManufacturerSuppressor,
                      artAccessoryCalibre,
                      artAccessoryComposition,
                      artAccessoryHostType,
                      artAccessoryLightColour,
                      artAccessoryMagnification,
                      artAccessoryReticle,
                      artAccessoryUtility,
                      artAccessoryProofHouse,
                      artAmmunitionType,
                      artAmmunitionManufacturer,
                      artAmmunitionFactory,
                      artAmmunitionCalibreGauge,
                      artAmmunitionFunctionalType,
                      artAmmunitionCartridgeComposition,
                      artAmmunitionColourOfPrimerSealant,
                      artAmmunitionProjectileShape,
                      artAmmunitionShotSize,
                      artAmmunitionMunitionType,
                      artAmmunitionMunitionVelocity,
                      artComponentCalibre,
                      artComponentCapacity,
                      artComposition,
                      artComponentManufacturer,
                      artComponentType,
                      artComponentProofHouse,
                    }}
                  />
                ))}
          </div>
        </Accordion>
      </div>
    </>
  );
};

export { MyFiltersPage };
