import styled from '@emotion/styled';
import { css, Grid, Typography } from '@mui/material';
import { colors } from 'theme/colors';

interface IStyledFormElementWrapper {
  disabled?: boolean;
}
export const StyledFormElementWrapper = styled.div<IStyledFormElementWrapper>`
  display: flex;
  flex-direction: column;
  ${(props: IStyledFormElementWrapper) =>
    props.disabled &&
    css`
      .MuiOutlinedInput-root {
        background: ${colors.gray100} !important;
      }
      input {
        color: ${colors.gray300};
        background: ${colors.gray100} !important;
      }
    `}
`;

export const StyledPageTitle = styled(Typography)`
  color: ${colors.primary400};
  text-transform: capitalize;
  margin-bottom: 16px;
`;

export const StyledPageSubTitle = styled(Typography)`
  color: ${colors.primary400};
`;

export const StyledSearchForm = styled(Grid)`
  margin-top: 0px;
  & .MuiGrid-item {
    padding-top: 0px;
  }
`;

export const StyledForm = styled(Grid)`
  & .MuiGrid-item {
    padding-top: 0px;
  }
`;
