import * as React from 'react';
import { PropsWithChildren, useMemo } from 'react';
import { initialState, reducer } from 'state/reducers/password';
import { PasswordContext } from 'models/passwordContext';
import { IForgotPasswordBodyRequest, IGeneratePasswordBodyRequest } from 'models/password';
import { changePassword, forgotPassword, generatePassword } from 'requests/password';
import { IAuthResponse } from 'models/auth';

export const PasswordProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = useMemo(
    () => ({
      ...state,
      changePassword: (
        body: any,
        query: any,
        successCallback: (arg: IAuthResponse) => void,
        failureCallback: (arg: string) => void,
      ) =>
        changePassword(
          dispatch,
          body,
          query,
          (res) => successCallback(res),
          (res) => failureCallback(res),
        ),
      forgotPassword: (
        body: IForgotPasswordBodyRequest,
        successCallback?: () => void,
        failureCallback?: () => void,
      ) =>
        forgotPassword(
          dispatch,
          body,
          () => successCallback && successCallback(),
          () => failureCallback && failureCallback(),
        ),
      generatePassword: (body: IGeneratePasswordBodyRequest, successCallback: () => void) =>
        generatePassword(dispatch, body, () => successCallback()),
    }),
    [state],
  );

  return <PasswordContext.Provider value={value}>{children}</PasswordContext.Provider>;
};
