import { apiClient } from 'utils/apiClient';
import { ApiEndpoints } from 'models/apiEndpoints';
import { isResponseError } from 'models/apiClient';
import {
  EDashboardSortType,
  EGlobalSearchCategories,
  IDashboardChartData,
} from 'models/dashboard';
import { queryStringWithSort } from 'helpers/request';
import { EPerPages, ISortOptions } from 'models/table';

const sortParam = 'timeFilter';

const getFirearmsLegality = async (
  sortType: EDashboardSortType,
): Promise<IDashboardChartData | null> => {
  const { data } = await apiClient.get(
    `${ApiEndpoints.DASHBOARD_FIREARMS_LEGALITY()}?${sortParam}=${sortType}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getAncillariesTypeData = async (
  sortType: EDashboardSortType,
): Promise<IDashboardChartData | null> => {
  const { data } = await apiClient.get(
    `${ApiEndpoints.DASHBOARD_ANCILLARIES_TYPES()}?${sortParam}=${sortType}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getLegalEntities = async (
  sortType: EDashboardSortType,
): Promise<IDashboardChartData | null> => {
  const { data } = await apiClient.get(
    `${ApiEndpoints.DASHBOARD_LEGAL_ENTITIES()}?${sortParam}=${sortType}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getFirearmsStates = async (
  sortType: EDashboardSortType,
): Promise<IDashboardChartData | null> => {
  const { data } = await apiClient.get(
    `${ApiEndpoints.DASHBOARD_FIREARMS_STATES()}?${sortParam}=${sortType}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export const getTransactionsTypes = async (
  sortType: EDashboardSortType,
): Promise<IDashboardChartData | null> => {
  const { data } = await apiClient.get(
    `${ApiEndpoints.DASHBOARD_TRANSACTIONS_TYPES()}?${sortParam}=${sortType}`,
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getGlobalSearchResults = async (
  query: string,
  category: EGlobalSearchCategories,
  currentPage: number,
  perPage: EPerPages,
  sort?: ISortOptions | null,
) => {
  const urlQuery = queryStringWithSort(
    {
      phrase: encodeURIComponent(query),
      page: currentPage - 1,
      size: perPage,
    },
    sort,
  );

  try {
    const { data } = await apiClient.get(
      `${ApiEndpoints.DASHBOARD_GLOBAL_SEARCH(category)}?${urlQuery}`,
    );

    if (isResponseError(data)) {
      return [];
    }

    return data;
  } catch (e) {
    return [];
  }
};

export {
  getFirearmsLegality,
  getLegalEntities,
  getGlobalSearchResults,
  getFirearmsStates,
  getAncillariesTypeData,
};
