import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { PDFDocumentWrapper } from 'pages/Firearms/ViewRegisteredFirearmAttachment/styles';
import { Loader } from 'components/atoms/Loader';
import { Box } from '@mui/system';
import { PdfjsWorkerPath } from 'constants/PdfjsWorkerPath';

pdfjs.GlobalWorkerOptions.workerSrc = PdfjsWorkerPath;

export const ComponentsAttachmentViewPage = () => {
  const navigate = useNavigate();
  const { handlePreviewAttachment } = useAncillaries();
  const { id = '', ancillaryId = '' } = useParams<{ id: string; ancillaryId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [attachmentUrl, setAttachmentUrl] = useState<any>(null);
  const [pagesNumber, setPagesNumber] = useState<number | null>(null);

  useEffect(() => {
    (async function () {
      if (id && ancillaryId) {
        setAttachmentUrl(await handlePreviewAttachment(ancillaryId, id));
        setIsLoading(false);
      } else navigate(-1);
    })();
  }, []);

  return isLoading ? (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Loader isLoading={true} />
    </Box>
  ) : (
    <PDFDocumentWrapper>
      {attachmentUrl && (
        <Document
          file={attachmentUrl}
          onLoadSuccess={({ numPages }) => setPagesNumber(numPages)}
          loading={null}
        >
          {/* eslint-disable-next-line prefer-spread */}
          {Array.apply(null, Array(pagesNumber))
            .map((_, i) => i + 1)
            .map((page) => (
              <Page pageNumber={page} scale={1.5} loading={null} />
            ))}
          <Page pageNumber={1} scale={1.5} />
        </Document>
      )}
    </PDFDocumentWrapper>
  );
};
